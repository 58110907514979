import { Box, Button, Menu, MenuItem, Switch, Typography } from '@mui/material';
import { User } from 'constants/user';
import { doc, DocumentReference, onSnapshot } from 'firebase/firestore';
import useAuth from 'hooks/useAuth';
import { useFirestoreByPath } from 'hooks/useFirestoreByPath';
import { useEffect, useState } from 'react';
import { useFirestore } from 'reactfire';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const MENU_ITEMS = ['Guided Tours'];

export default function LearnPopover() {
  const firestore = useFirestore();
  const { user } = useAuth();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [tourAnchorEl, setTourAnchorEl] = useState(null);
  const tourOpen = Boolean(tourAnchorEl);

  const { update: updateUser } = useFirestoreByPath<User>('users');
  const [currentUser, setCurrentUser] = useState<User>();

  const snapshotUser = () => {
    const userDoc = doc(firestore, `users/${user?.uid}`) as DocumentReference<User>;
    onSnapshot(userDoc, (doc: any) => {
      const userObj = { ...doc.data(), id: doc.id } as User;
      setCurrentUser(userObj);
    });
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted && !currentUser) {
      snapshotUser();
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTourChange = async (tourName: string, event: React.ChangeEvent<HTMLInputElement>) => {
    await updateUser(user?.uid as string, { [tourName]: event.target.checked });
    handleClose();
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setTourAnchorEl(null);
  };

  const handleSubMenu = (event: any) => {
    setTourAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Button variant="text" onClick={handleClick}>
        LEARN
      </Button>
      <Menu
        id="learn-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          zIndex: 9999
        }}
      >
        <Box sx={{ padding: '10px' }}>
          <Button
            variant="text"
            target="_blank"
            rel="noopener"
            href="https://39717762.hs-sites.com/getting-started"
          >
            All Videos
          </Button>
        </Box>
        {MENU_ITEMS.map((item, index) => (
          <MenuItem
            key={index}
            sx={{
              fontSize: '14px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
            onClick={(e) => handleSubMenu(e)}
          >
            <Typography>{item}</Typography>
            <ArrowForwardIosIcon fontSize="small" sx={{ marginLeft: '20px' }} />
          </MenuItem>
        ))}
      </Menu>
      <Menu
        id="tours-menu"
        anchorEl={tourAnchorEl}
        open={tourOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={{
          zIndex: 9999
        }}
      >
        <MenuItem
          sx={{
            fontSize: '14px',
            minWidth: '300px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <Typography>Dashboard</Typography>
          <Switch
            checked={currentUser?.showDashboardTour}
            onChange={(e) => handleTourChange('showDashboardTour', e)}
          />
        </MenuItem>
        <Typography sx={{ fontSize: '10px', padding: '6px 16px' }}>BUY</Typography>
        <MenuItem
          sx={{
            fontSize: '14px',
            minWidth: '300px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <Typography>Pre-Auction</Typography>
          <Switch
            checked={currentUser?.showPreAuctionBuyTour}
            onChange={(e) => handleTourChange('showPreAuctionBuyTour', e)}
          />
        </MenuItem>
        <MenuItem
          sx={{
            fontSize: '14px',
            minWidth: '300px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <Typography>Active Auction</Typography>
          <Switch
            checked={currentUser?.showActiveAuctionBuyTour}
            onChange={(e) => handleTourChange('showActiveAuctionBuyTour', e)}
          />
        </MenuItem>
        <MenuItem
          sx={{
            fontSize: '14px',
            minWidth: '300px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <Typography>Post-Auction</Typography>
          <Switch
            checked={currentUser?.showPostAuctionBuyTour}
            onChange={(e) => handleTourChange('showPostAuctionBuyTour', e)}
          />
        </MenuItem>
        <Typography sx={{ fontSize: '10px', padding: '6px 16px' }}>SELL</Typography>
        <MenuItem
          sx={{
            fontSize: '14px',
            minWidth: '300px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <Typography>Pre-Auction</Typography>
          <Switch
            checked={currentUser?.showPreAuctionSellTour}
            onChange={(e) => handleTourChange('showPreAuctionSellTour', e)}
          />
        </MenuItem>
        <MenuItem
          sx={{
            fontSize: '14px',
            minWidth: '300px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <Typography>Active Auction</Typography>
          <Switch
            checked={currentUser?.showActiveAuctionSellTour}
            onChange={(e) => handleTourChange('showActiveAuctionSellTour', e)}
          />
        </MenuItem>
        <MenuItem
          sx={{
            fontSize: '14px',
            minWidth: '300px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <Typography>Post-Auction</Typography>
          <Switch
            checked={currentUser?.showPostAuctionSellTour}
            onChange={(e) => handleTourChange('showPostAuctionSellTour', e)}
          />
        </MenuItem>
        <Typography sx={{ fontSize: '10px', padding: '6px 16px' }}>
          DEALERGAVEL COMMUNITY
        </Typography>
        <MenuItem
          sx={{
            fontSize: '14px',
            minWidth: '300px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <Typography>Buying Network</Typography>
          <Switch
            checked={currentUser?.showBuyingNetworkTour}
            onChange={(e) => handleTourChange('showBuyingNetworkTour', e)}
          />
        </MenuItem>
        <MenuItem
          sx={{
            fontSize: '14px',
            minWidth: '300px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <Typography>Selling Network</Typography>
          <Switch
            checked={currentUser?.showSellingNetworkTour}
            onChange={(e) => handleTourChange('showSellingNetworkTour', e)}
          />
        </MenuItem>
        <MenuItem
          sx={{
            fontSize: '14px',
            minWidth: '300px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <Typography>Find a Dealership</Typography>
          <Switch
            checked={currentUser?.showFindDealershipTour}
            onChange={(e) => handleTourChange('showFindDealershipTour', e)}
          />
        </MenuItem>
        <Typography sx={{ fontSize: '10px', padding: '6px 16px' }}>ADMIN</Typography>
        <MenuItem
          sx={{
            fontSize: '14px',
            minWidth: '300px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <Typography>Manage Team</Typography>
          <Switch
            checked={currentUser?.showManageTeamTour}
            onChange={(e) => handleTourChange('showManageTeamTour', e)}
          />
        </MenuItem>
      </Menu>
    </>
  );
}
