// material
import { useTheme } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

export default function Logo({ sx }: BoxProps) {
  const theme = useTheme();
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const GREY_100 = theme.palette.grey[100];
  const GREY_300 = theme.palette.grey[300];
  const GREY_800 = theme.palette.grey[800];

  return (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 54 63"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M23.2736 0.0641865C15.4554 0.484809 8.90509 1.53637 0.875623 4.0601C0.453019 4.27041 0.241717 4.69104 0.241717 5.11166C0.241717 5.9529 0.241717 7.21477 0.0304156 9.5282C-0.60349 30.5593 8.69379 51.1698 25.8092 62.737C26.2318 62.9473 26.6544 62.9473 27.077 62.737C46.0942 50.5389 54.5463 26.984 52.8559 5.11166C52.8559 4.69104 52.4333 4.27041 52.0107 4.0601C42.7134 1.32605 31.3031 -0.356436 23.2736 0.0641865Z"
          fill={GREY_800}
        />
        <path
          d="M23.2736 4.90123C16.9346 5.32185 11.652 6.1631 5.10168 8.26621C4.67907 8.26621 4.46777 8.47652 4.46777 8.89714C4.46777 9.52808 4.46777 10.7899 4.46777 12.4724C3.83387 29.9283 11.4407 46.7532 25.3867 56.4275C25.598 56.4275 25.598 56.6378 25.8093 56.6378C26.0206 56.6378 26.2319 56.6378 26.2319 56.4275C41.6569 46.5429 48.6299 26.9839 47.362 8.89714C47.362 8.47652 47.1507 8.26621 46.7281 8.0559C40.1778 6.1631 32.1483 4.69092 26.0206 4.69092C24.9641 4.69092 24.1188 4.69092 23.2736 4.90123Z"
          fill={PRIMARY_MAIN}
        />
        <path
          d="M25.8093 56.6378C26.0206 56.6378 26.2319 56.6378 26.2319 56.4275C41.6569 46.5429 48.6299 26.9839 47.3621 8.89714C47.3621 8.47652 47.1507 8.26621 46.7281 8.0559C40.1778 6.1631 32.1483 4.69092 26.0206 4.69092L25.8093 56.6378Z"
          fill={PRIMARY_DARK}
        />
        <path
          d="M27.9223 44.2295C28.3449 39.3923 27.9223 30.5593 27.4997 22.3571C28.1336 22.3571 28.5562 21.3056 28.1336 20.8849C28.7675 20.6746 28.9788 20.254 28.9788 19.6231H30.0353C30.0353 19.6231 30.2466 20.4643 30.6692 20.4643C31.0918 20.4643 31.0918 19.8334 31.0918 19.8334V12.4725C31.0918 12.4725 31.0918 11.8416 30.6692 11.8416C30.2466 11.8416 30.0353 12.4725 30.0353 12.4725H25.8092H21.1606C21.1606 12.4725 20.9493 11.8416 20.5267 11.8416C20.1041 11.8416 20.3154 12.4725 20.3154 12.4725V20.0437C20.3154 20.0437 20.3154 20.6746 20.738 20.6746C21.1606 20.6746 21.3719 19.8334 21.3719 19.8334H22.4284C22.4284 20.4643 22.6397 20.8849 23.2736 21.0953C22.851 21.5159 23.2736 22.5674 23.9075 22.5674C23.4849 30.7696 23.0623 39.6027 23.4849 44.4398C23.4849 45.912 24.3301 46.7532 25.5979 46.7532C27.0771 46.5429 27.711 45.7017 27.9223 44.2295Z"
          fill={GREY_100}
        />
        <path
          d="M25.8092 46.5429C26.8657 46.5429 27.7109 45.4914 27.9222 44.2295C28.3448 39.3923 27.9222 30.5593 27.4996 22.3571C28.1335 22.3571 28.5561 21.3056 28.1335 20.8849C28.7674 20.6746 28.9787 20.254 28.9787 19.6231H30.0352C30.0352 19.6231 30.2465 20.4643 30.6691 20.4643C31.0918 20.4643 31.0918 19.8334 31.0918 19.8334V12.4725C31.0918 12.4725 31.0918 11.8416 30.6691 11.8416C30.2465 11.8416 30.0352 12.4725 30.0352 12.4725H25.8092V46.5429Z"
          fill={GREY_300}
        />
        <path d="M36.3742 10.79H32.3595V21.5159H36.3742V10.79Z" fill={GREY_300} />
        <path d="M19.2589 10.79H15.2441V21.5159H19.2589V10.79Z" fill={GREY_100} />
      </svg>
    </Box>
  );
}
