import {
  Box,
  Button,
  Divider,
  Typography,
  RadioGroup,
  FormControlLabel,
  TextField,
  FormHelperText,
  Checkbox
} from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
// import { boxStyle } from './vehicleCaptureStyles';
import { useState, useEffect, useRef } from 'react';
import { VehicleChipRadioButton, VehicleDropBox, VehicleNumberBox } from './CommonComponents';
import { VehicleDetailsType } from 'types/vehicleInfo';
import { useNavigate } from 'react-router-dom';
import { PATH_SELLER } from 'routes/paths';
import { useTheme } from '@mui/material/styles';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { loadModelDetails } from 'utils/loadModelDetails';
import { loadModelOptions } from 'utils/loadModelOptions';
import LoadingScreen from 'components/LoadingScreen';
import MoreItemsIndicator from './MoreItemsIndicator';

const arrBodyStyle = ['Sedan', 'Suv', 'Truck', 'Van', 'Wagon', 'Coupe'];
const arrTransmission = ['manual', 'automatic'];
const arrDriveTrain = ['rwd', '4wd', 'awd', 'fwd'];
const arrDoors = ['2', '3', '4', '5'];
const arrCylinders = ['3 Cyl', '4 Cyl', '6 Cyl', '8 Cyl', '10 Cyl', '12 Cyl', 'EV'];
const arrFuelType = ['gas', 'flex fuel', 'diesel', 'hybrid', 'electric'];
const arrPassengers = [1, 2, 3, 4, 5, 6, 7, 8];

type VehicleColorRadioType = {
  propertyVal: string;
  colorArray: string[];
  codeArray: string[];
  updateProperty: (value: string) => void;
  showCustomField: (value: boolean) => void;
};

function VehicleColorRadio({
  propertyVal,
  updateProperty,
  showCustomField,
  colorArray,
  codeArray
}: VehicleColorRadioType) {
  const theme = useTheme();
  const [, setSelectedColorBox] = useState(false);
  const [selColorBox, setSelColorBox] = useState(0);

  useEffect(() => {
    const isCustomColor = !colorArray.includes(propertyVal);
    if (isCustomColor) {
      showCustomField(true);
      // setSelColorBox(7);
      setSelColorBox(colorArray.length - 1);
    } else {
      showCustomField(false);
      setSelColorBox(colorArray.indexOf(propertyVal) || 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyVal]);

  const handleColorClick = (index: number, val: any) => {
    setSelColorBox(index);
    setSelectedColorBox((s) => !s);
    updateProperty(val);
  };

  return (
    <RadioGroup sx={{ mt: 1, ml: 1.5, display: 'flex', flexDirection: 'row' }}>
      {colorArray.map((cColor, index) => (
        <FormControlLabel
          sx={{ display: 'block', marginBottom: '5px' }}
          key={index}
          label={''}
          control={
            <Box key={cColor} sx={{ width: '90px' }}>
              <Box
                sx={{
                  mr: 1,
                  backgroundColor: `rgb(${codeArray[index]})`,
                  height: '35px',
                  width: '35px',
                  border:
                    selColorBox === index
                      ? `4px solid ${theme.palette.primary.main}`
                      : '1px solid grey'
                }}
                onClick={() => {
                  handleColorClick(index, cColor);
                }}
              ></Box>
              <Typography
                sx={{
                  ml: 0.5,
                  fontSize: '10px',
                  textAlign: 'start',
                  textTransform: 'capitalize'
                }}
              >
                {cColor}
              </Typography>
            </Box>
          }
        />
      ))}
    </RadioGroup>
  );
}

type Props = {
  vehicleDetails: VehicleDetailsType;
  dataFound: boolean;
  vehicleId: string;
  updVehicleDet: (vehicleDetails: VehicleDetailsType, flag: number) => void;
};

export function VehicleDetails({ vehicleDetails, updVehicleDet, dataFound, vehicleId }: Props) {
  const navigate = useNavigate();
  const theme = useTheme();
  const [vehicleDetailsInfo, setVehicleDetailsInfo] = useState(
    vehicleDetails || ({} as VehicleDetailsType)
  );
  const [yearVal, setYearVal] = useState(vehicleDetails?.year);
  const [makeVal, setMakeVal] = useState(vehicleDetails?.make);
  const [modelVal, setModelVal] = useState(vehicleDetails?.model);
  const [bodyStyle, setBodyStyle] = useState<string>(vehicleDetails?.bodyStyle);
  const [trimVal, setTrimVal] = useState(vehicleDetails?.trim);
  const [packageVal, setPackageVal] = useState(vehicleDetails?.package);
  const [transmission, setTransmission] = useState<string>(vehicleDetails?.transmission);
  const [drivetrain, setDrivetrain] = useState<string>(vehicleDetails?.driveTrain);
  const [displacementVal, setDisplacementVal] = useState(vehicleDetails?.displacement);
  const [dataDisplacementVal] = useState(vehicleDetails?.displacement);
  const [cylinders, setCylinders] = useState<string>(vehicleDetails?.cylinders);
  const [fuelType, setFuelType] = useState<string>(vehicleDetails?.fuelType);
  const [doors, setDoors] = useState<string>(vehicleDetails?.doors);
  const [extColor, setExtColor] = useState<string>(vehicleDetails?.extColor);
  const [intColor, setIntColor] = useState<string>(vehicleDetails?.intColor);
  const [mileageVal, setMileageVal] = useState(vehicleDetails?.mileage);
  const [mileageType, setMileageType] = useState(vehicleDetails?.mileageType);

  const [isDisabledNext, setIsDisabledNext] = useState<boolean>(true);
  const [showValidateError, setShowValidateError] = useState<boolean>(false);

  const [bodyStyleArray, setBodyStyleArray] = useState<string[]>();
  const [transmissionArray, setTransmissionArray] = useState<string[]>(arrTransmission);
  const [driveTrainArray, setDriveTrainArray] = useState<string[]>(arrDriveTrain);
  const [cylinderArray, setCylinderArray] = useState<string[]>();
  const [fuelTypeArray, setFuelTypeArray] = useState<string[]>(arrFuelType);
  const [doorsArray, setDoorsArray] = useState<string[]>();
  const [passengerArray, setPassengerArray] = useState<number[]>();
  const [trimArray, setTrimArray] = useState<string[]>();

  const [extColorArray, setExtColorArray] = useState<string[]>();
  const [extColorCodeArray, setExtColorCodeArray] = useState<string[]>();
  const [intColorArray, setIntColorArray] = useState<any[]>();
  const [intColorCodeArray, setIntColorCodeArray] = useState<string[]>();

  const [showCustomExt, setShowCustomExt] = useState<boolean>(false);
  const [showCustomInt, setShowCustomInt] = useState<boolean>(false);
  const [customExt, setCustomExt] = useState<string>();
  const [customInt, setCustomInt] = useState<string>();
  const [pageLoading, setPageLoading] = useState<boolean>(true);

  const boxStyle = {
    textAlign: 'start',
    width: 400,
    height: 60,
    backgroundColor: theme.palette.mode !== 'dark' ? theme.palette.common.white : 'unset',
    border: `1px ${theme.palette.divider} solid`,
    borderRadius: '10px',
    fontWeight: 300,
    padding: 0,
    mb: 1,
    overflow: 'hidden'
  };

  const getVehicleOptions = async (vehicle: any) => {
    const vehicleData = await loadModelDetails(vehicle);
    const vehicleOptions = await loadModelOptions(vehicle);
    // BODY STYLE
    if (vehicleOptions.data.data) {
      let tempBodies: string[] = [];
      vehicleOptions.data.data.forEach((item: any) => {
        tempBodies.push(item.type);
      });
      tempBodies = tempBodies.filter((item, index) => tempBodies.indexOf(item) === index);
      // prettier-ignore
      if (vehicleDetailsInfo.bodyStyle && tempBodies.includes(vehicleDetailsInfo.bodyStyle)) {
        setBodyStyleArray(tempBodies);
      } else {
        if (vehicleDetailsInfo.bodyStyle !== '') {
          setBodyStyleArray([...tempBodies, vehicleDetailsInfo.bodyStyle]);
        } else {
          setBodyStyleArray(tempBodies);
        }
      }
    }
    // TRIM
    if (vehicleData.data && vehicleData.data.trims && vehicleData.data.trims.length > 0) {
      if (vehicleDetailsInfo.trim && vehicleData.data.trims.includes(vehicleDetailsInfo.trim)) {
        setTrimArray(vehicleData.data.trims);
        // const tmp = { ...vehicleDetailsInfo };
        // tmp.trim = vehicleDetailsInfo.trim as string;
        // setVehicleDetailsInfo(tmp);
        // setTrimVal(tmp.trim);
      } else {
        if (vehicleDetailsInfo.trim !== '') {
          setTrimArray([...vehicleData.data.trims, vehicleDetailsInfo.trim]);
          // const tmp = { ...vehicleDetailsInfo };
          // tmp.trim = vehicleDetailsInfo.trim as string;
          // setVehicleDetailsInfo(tmp);
          // setTrimVal(tmp.trim);
        } else {
          setTrimArray(vehicleData.data.trims);
          // const tmp = { ...vehicleDetailsInfo };
          // tmp.trim = vehicleData.data.trims[0] as string;
          // setVehicleDetailsInfo(tmp);
          // setTrimVal(tmp.trim);
        }
      }
    }
    // TRANSMISSION
    // prettier-ignore
    if (vehicleDetailsInfo.transmission && arrTransmission.includes(vehicleDetailsInfo.transmission.toLowerCase())) {
      setTransmissionArray(arrTransmission);
    } else {
      if (vehicleDetailsInfo.transmission && vehicleDetailsInfo.transmission !== '') {
        setTransmissionArray([...arrTransmission, vehicleDetailsInfo.transmission.toLowerCase()]);
      } else {
        setTransmissionArray(arrTransmission);
      }
    }
    // DRIVETRAIN
    // prettier-ignore
    if (vehicleDetailsInfo.driveTrain && arrDriveTrain.includes(vehicleDetailsInfo.driveTrain.toLowerCase())) {
      setDriveTrainArray(arrDriveTrain);
    } else {
      if (vehicleDetailsInfo.driveTrain !== '') {
        setDriveTrainArray([...arrDriveTrain, vehicleDetailsInfo.driveTrain.toLowerCase()]);
      } else {
        setDriveTrainArray(arrDriveTrain);
      }
    }
    // CYLINDERS
    if (vehicleDetailsInfo.cylinders && arrCylinders.includes(vehicleDetailsInfo.cylinders)) {
      setCylinderArray(arrCylinders);
    } else {
      if (vehicleDetailsInfo.cylinders !== '') {
        setCylinderArray([...arrCylinders, vehicleDetailsInfo.cylinders]);
      } else {
        setCylinderArray(arrCylinders);
      }
    }
    // FUEL TYPE
    // prettier-ignore
    if (vehicleDetailsInfo.fuelType && arrFuelType.includes(vehicleDetailsInfo.fuelType.toLowerCase())) {
      setFuelTypeArray(arrFuelType);
    } else {
      if (vehicleDetailsInfo.fuelType !== '') {
        setFuelTypeArray([...arrFuelType, vehicleDetailsInfo.fuelType.toLowerCase()]);
      } else {
        setFuelTypeArray(arrFuelType);
      }
    }
    // DOORS
    if (vehicleDetailsInfo.doors && arrDoors.includes(vehicleDetailsInfo.doors)) {
      setDoorsArray(arrDoors);
      setDoors(vehicleDetailsInfo.doors);
    } else {
      if (vehicleDetailsInfo.doors && vehicleDetailsInfo.doors !== '') {
        setDoorsArray([...arrDoors, vehicleDetailsInfo.doors]);
        setDoors(vehicleDetailsInfo.doors);
      } else {
        setDoorsArray(arrDoors);
      }
    }
    // PASSENGERS
    if (vehicleData.data.passengers && vehicleData.data.passengers.length > 0) {
      const tempPassengers: number[] = [];
      vehicleData.data.passengers.forEach((passenger: string) => {
        tempPassengers.push(parseInt(passenger, 10));
      });
      setPassengerArray(tempPassengers);
    } else {
      setPassengerArray(arrPassengers);
    }
    // EXT COLOURS
    if (vehicleData.data.exteriorColors && vehicleData.data.exteriorColors.length > 0) {
      const tempColors: string[] = [];
      const tempCodes: string[] = [];
      vehicleData.data.exteriorColors.forEach((color: any) => {
        tempColors.push(color.name);
        tempCodes.push(color.rgb);
      });
      tempColors.push('Custom');
      tempCodes.push('52,53,70');
      setExtColorArray(tempColors);
      setExtColorCodeArray(tempCodes);
      if (extColor) {
        const isCustomColor = !tempColors.includes(extColor);
        if (isCustomColor) {
          setCustomExt(extColor);
        }
      }
    }
    // INT COLOURS
    if (vehicleData.data.interiorColors && vehicleData.data.interiorColors.length > 0) {
      const tempColors: string[] = [];
      const tempCodes: string[] = [];
      vehicleData.data.interiorColors.forEach((color: any) => {
        tempColors.push(color.name);
        tempCodes.push(color.rgb);
      });
      tempColors.push('Custom');
      tempCodes.push('52,53,70');
      setIntColorArray(tempColors);
      setIntColorCodeArray(tempCodes);
      if (intColor) {
        const isCustomColor = !tempColors.includes(intColor);
        if (isCustomColor) {
          setCustomInt(intColor);
        }
      }
    }
    // MILEAGE TYPE
    if (!vehicleDetails.mileageType || vehicleDetails.mileageType == '') {
      setMileageType('KM');
      const tmp = { ...vehicleDetailsInfo };
      tmp.mileageType = 'KM';
      setVehicleDetailsInfo(tmp);
    }
    setPageLoading(false);
  };

  // useEffect(() => {
  //   if (!doors && doorsArray) {
  //     setDoors(doorsArray[0]);
  //   }
  // }, [doorsArray, doors]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const vehicle = {
      details: {
        year: vehicleDetails.year,
        make: vehicleDetails.make,
        model: vehicleDetails.model
      },
      id: vehicleId
    };
    getVehicleOptions(vehicle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    validateAllInputs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    yearVal,
    makeVal,
    modelVal,
    trimVal,
    displacementVal,
    transmission,
    drivetrain,
    cylinders,
    fuelType,
    doors,
    extColor,
    intColor,
    mileageVal,
    mileageType
  ]);

  const validateAllInputs = () => {
    if (
      yearVal &&
      makeVal &&
      modelVal &&
      bodyStyle &&
      trimVal &&
      displacementVal &&
      transmission &&
      drivetrain &&
      cylinders &&
      fuelType &&
      doors &&
      extColor &&
      intColor &&
      mileageVal &&
      mileageType
    ) {
      setIsDisabledNext(false);
    } else {
      setIsDisabledNext(true);
    }
  };

  const [isChecked, setIsChecked] = useState<boolean>(false);
  const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  const handleDisplacement = (e: any) => {
    setDisplacementVal(e.target.value);
    const tmp = { ...vehicleDetailsInfo };
    tmp.displacement = e.target.value;
    setVehicleDetailsInfo(tmp);
  };

  const handleYear = (e: any) => {
    setYearVal(e.target.value);
    const tmp = { ...vehicleDetailsInfo };
    tmp.year = e.target.value;
    setVehicleDetailsInfo(tmp);
  };

  const handleMake = (e: any) => {
    setMakeVal(e.target.value);
    const tmp = { ...vehicleDetailsInfo };
    tmp.make = e.target.value;
    setVehicleDetailsInfo(tmp);
  };

  const handleModel = (e: any) => {
    setModelVal(e.target.value);
    const tmp = { ...vehicleDetailsInfo };
    tmp.model = e.target.value;
    setVehicleDetailsInfo(tmp);
  };

  const handleTrim = (e: any) => {
    setTrimVal(e.target.value);
    const tmp = { ...vehicleDetailsInfo };
    tmp.trim = e.target.value;
    setVehicleDetailsInfo(tmp);
  };

  const handlePackage = (e: any) => {
    setPackageVal(e.target.value);
    const tmp = { ...vehicleDetailsInfo };
    tmp.package = e.target.value;
    setVehicleDetailsInfo(tmp);
  };

  const handleMileage = (e: any) => {
    setMileageVal(e.target.value);
    const tmp = { ...vehicleDetailsInfo };
    tmp.mileage = e.target.value;
    setVehicleDetailsInfo(tmp);
  };

  const handleMileageType = (event: any, value: string) => {
    setMileageType(value);
    const tmp = { ...vehicleDetailsInfo };
    tmp.mileageType = value;
    setVehicleDetailsInfo(tmp);
  };

  const handleCustomExt = (event: any) => {
    setCustomExt(event.target.value);
  };

  const handleCustomExtBlur = (event: any) => {
    const tmp = { ...vehicleDetailsInfo };
    tmp.extColor = event.target.value;
    setVehicleDetailsInfo(tmp);
    setExtColor(event.target.value);
  };

  const handleExtColor = (tmp: any, color: string) => {
    if (color.toLowerCase() === 'custom') {
      setShowCustomExt(true);
      setExtColor('');
    } else {
      setShowCustomExt(false);
      setCustomExt('');
      setVehicleDetailsInfo(tmp);
      setExtColor(color);
    }
  };

  const handleCustomInt = (event: any) => {
    setCustomInt(event.target.value);
  };

  const handleCustomIntBlur = (event: any) => {
    const tmp = { ...vehicleDetailsInfo };
    tmp.intColor = event.target.value;
    setVehicleDetailsInfo(tmp);
    setIntColor(event.target.value);
  };

  const handleIntColor = (tmp: any, color: string) => {
    if (color.toLowerCase() === 'custom') {
      setShowCustomInt(true);
      setIntColor('');
    } else {
      setShowCustomInt(false);
      setCustomInt('');
      setVehicleDetailsInfo(tmp);
      setIntColor(color);
    }
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="outlined"
          onClick={() => {
            updVehicleDet(vehicleDetailsInfo, 1);
            navigate(PATH_SELLER.vehicleCapture);
          }}
        >
          Save and Exit
        </Button>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          backgroundColor:
            theme.palette.mode === 'dark'
              ? theme.palette.background.neutral
              : theme.palette.grey[50],
          borderRadius: '10px',
          maxWidth: '850px',
          width: '100%',
          border: '1px solid rgba(145, 158, 171, 0.24)',
          margin: 'auto',
          marginTop: '20px',
          paddingBottom: '20px'
        }}
      >
        <Box>
          <Box sx={{ mt: 3, width: 400 }}>
            <Typography
              variant="h3"
              sx={{ textAlign: 'center', color: theme.palette.text.primary }}
            >
              Vehicle Details
            </Typography>
          </Box>
          {pageLoading ? (
            <Box
              sx={{
                height: '400px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'row'
              }}
            >
              <LoadingScreen sx={{ backgroundColor: 'transparent !important' }} />
            </Box>
          ) : (
            <Box sx={{ mt: 3, width: 400 }}>
              <Box sx={boxStyle}>
                <Typography sx={{ mt: 1, ml: 2, fontSize: '12px', color: 'grey' }}>VIN</Typography>
                <Typography sx={{ ml: 2, fontSize: '12px', fontWeight: '600' }}>
                  {vehicleDetailsInfo?.vin || ''}
                </Typography>
              </Box>
              <Box sx={boxStyle}>
                <TextField
                  sx={{
                    mt: 1,
                    ml: 2,
                    color: 'black',
                    width: '85%'
                  }}
                  inputProps={{
                    style: { fontSize: '12px', fontWeight: '600' },
                    readOnly: yearVal && dataFound ? true : false
                  }}
                  InputLabelProps={{ style: { fontSize: '12px' } }}
                  variant="standard"
                  id="outlined-required"
                  label="Year"
                  onChange={handleYear}
                  defaultValue={yearVal}
                />
              </Box>
              {(!yearVal || yearVal.toString() === '') && showValidateError && (
                <FormHelperText
                  sx={{ ml: 1, mb: 2 }}
                  error={Boolean(!yearVal || yearVal.toString() === '')}
                >
                  You must enter a year.
                </FormHelperText>
              )}

              <Box sx={boxStyle}>
                <TextField
                  sx={{
                    mt: 1,
                    ml: 2,
                    color: 'black',
                    width: '85%'
                  }}
                  inputProps={{
                    style: { fontSize: '12px', fontWeight: '600' },
                    readOnly: makeVal && dataFound ? true : false
                  }}
                  InputLabelProps={{ style: { fontSize: '12px' } }}
                  variant="standard"
                  id="outlined-required"
                  label="Make"
                  onChange={handleMake}
                  defaultValue={makeVal}
                />
              </Box>
              {(!makeVal || makeVal === '') && showValidateError && (
                <FormHelperText sx={{ ml: 1, mb: 2 }} error={Boolean(!makeVal || makeVal === '')}>
                  You must enter a make.
                </FormHelperText>
              )}

              <Box sx={boxStyle}>
                <TextField
                  sx={{
                    mt: 1,
                    ml: 2,
                    color: 'black',
                    width: '85%'
                  }}
                  inputProps={{
                    style: { fontSize: '12px', fontWeight: '600' },
                    readOnly: modelVal && dataFound ? true : false
                  }}
                  InputLabelProps={{ style: { fontSize: '12px' } }}
                  variant="standard"
                  id="outlined-required"
                  label="Model"
                  onChange={handleModel}
                  defaultValue={modelVal}
                />
              </Box>
              {(!modelVal || modelVal === '') && showValidateError && (
                <FormHelperText sx={{ ml: 1, mb: 2 }} error={Boolean(!modelVal || modelVal === '')}>
                  You must enter a model.
                </FormHelperText>
              )}

              <Box sx={{ mt: 3, width: 400 }}>
                <Typography variant="subtitle1" sx={{ fontWeight: '700' }}>
                  Body Style
                </Typography>
                {bodyStyleArray && (
                  <VehicleChipRadioButton
                    propertyVal={vehicleDetailsInfo.bodyStyle}
                    arrayOfItems={bodyStyleArray as string[]}
                    updateProperty={(v) => {
                      const tmp = { ...vehicleDetailsInfo };
                      tmp.bodyStyle = v;
                      setVehicleDetailsInfo(tmp);
                      setBodyStyle(tmp.bodyStyle);
                    }}
                    chipWidth={'unset'}
                  />
                )}
              </Box>
              {(!bodyStyle || bodyStyle === '') && showValidateError && (
                <FormHelperText
                  sx={{ ml: 1, mb: 2 }}
                  error={Boolean(!bodyStyle || bodyStyle === '')}
                >
                  You must enter a body style.
                </FormHelperText>
              )}

              {/* <VehicleNumberBox
              dropBoxValue={vehicleDetailsInfo.passengers}
              caption={'Number of Passengers'}
              arrayOfItems={passengerArray as number[]}
              updateDropValue={(v) => {
                if (!v) return;
                const tmp = { ...vehicleDetailsInfo };
                tmp.passengers = v as number;
                setVehicleDetailsInfo(tmp);
              }}
            /> */}

              <FormControlLabel
                sx={{ mt: 1, mb: 1, textAlign: 'left' }}
                control={<Checkbox checked={isChecked} onChange={handleCheckChange} />}
                label={<Typography sx={{ fontSize: '12px' }}>Custom Trim</Typography>}
              />

              {trimArray && trimArray.length > 0 && !isChecked ? (
                <Box sx={boxStyle}>
                  <VehicleDropBox
                    dropBoxValue={trimVal}
                    caption={'Trim'}
                    arrayOfItems={trimArray as string[]}
                    updateDropValue={(v) => {
                      if (!v) return;
                      const tmp = { ...vehicleDetailsInfo };
                      tmp.trim = v as string;
                      setVehicleDetailsInfo(tmp);
                      setTrimVal(v);
                    }}
                  />
                </Box>
              ) : (
                <Box sx={boxStyle}>
                  <TextField
                    sx={{
                      mt: 1,
                      ml: 2,
                      color: 'black',
                      width: '85%'
                    }}
                    inputProps={{
                      style: { fontSize: '12px', fontWeight: '600' }
                    }}
                    InputLabelProps={{ style: { fontSize: '12px' } }}
                    variant="standard"
                    id="outlined-required"
                    label="Trim"
                    onChange={handleTrim}
                    defaultValue={trimVal}
                  />
                </Box>
              )}
              {(!trimVal || trimVal === '') && showValidateError && (
                <FormHelperText sx={{ ml: 1, mb: 2 }} error={Boolean(!trimVal || trimVal === '')}>
                  You must enter a trim.
                </FormHelperText>
              )}

              <Box sx={boxStyle}>
                <TextField
                  sx={{
                    mt: 1,
                    ml: 2,
                    color: 'black',
                    width: '85%'
                  }}
                  inputProps={{
                    style: { fontSize: '12px', fontWeight: '600' }
                  }}
                  InputLabelProps={{ style: { fontSize: '12px' } }}
                  variant="standard"
                  id="outlined-required"
                  label="Package"
                  onChange={handlePackage}
                  defaultValue={packageVal}
                />
              </Box>

              <Box sx={{ mt: 3, width: 400 }}>
                <Typography variant="subtitle1" sx={{ fontWeight: '700' }}>
                  Transmission
                </Typography>
                <VehicleChipRadioButton
                  propertyVal={
                    vehicleDetailsInfo.transmission
                      ? vehicleDetailsInfo.transmission.toLowerCase()
                      : ''
                  }
                  arrayOfItems={transmissionArray as string[]}
                  updateProperty={(v) => {
                    const tmp = { ...vehicleDetailsInfo };
                    tmp.transmission = v;
                    setVehicleDetailsInfo(tmp);
                    setTransmission(tmp.transmission);
                  }}
                  chipWidth={'unset'}
                />
              </Box>
              {(!transmission || transmission === '') && showValidateError && (
                <FormHelperText
                  sx={{ ml: 1, mb: 2 }}
                  error={Boolean(!transmission || transmission === '')}
                >
                  You must enter a transmission.
                </FormHelperText>
              )}

              <Box sx={{ mt: 3, width: 400 }}>
                <Typography variant="subtitle1" sx={{ fontWeight: '700' }}>
                  Drive Train
                </Typography>
                <VehicleChipRadioButton
                  propertyVal={vehicleDetailsInfo.driveTrain.toLowerCase()}
                  arrayOfItems={driveTrainArray as string[]}
                  updateProperty={(v) => {
                    const tmp = { ...vehicleDetailsInfo };
                    tmp.driveTrain = v;
                    setVehicleDetailsInfo(tmp);
                    setDrivetrain(tmp.driveTrain);
                  }}
                  chipWidth={'85px'}
                />
              </Box>
              {(!drivetrain || drivetrain === '') && showValidateError && (
                <FormHelperText
                  sx={{ ml: 1, mb: 2 }}
                  error={Boolean(!drivetrain || drivetrain === '')}
                >
                  You must enter a drivetrain.
                </FormHelperText>
              )}

              <Box sx={boxStyle}>
                <TextField
                  sx={{
                    mt: 1,
                    ml: 2,
                    color: 'black',
                    width: '85%'
                  }}
                  inputProps={{
                    style: { fontSize: '12px', fontWeight: '600' },
                    readOnly: false // dataDisplacementVal ? true : false
                  }}
                  InputLabelProps={{ style: { fontSize: '12px' } }}
                  variant="standard"
                  id="outlined-required"
                  label="Displacement"
                  onChange={handleDisplacement}
                  defaultValue={dataDisplacementVal ? dataDisplacementVal : displacementVal}
                />
              </Box>
              {(!displacementVal || displacementVal === '') && showValidateError && (
                <FormHelperText
                  sx={{ ml: 1, mb: 2 }}
                  error={Boolean(!displacementVal || displacementVal === '')}
                >
                  You must enter a displacement.
                </FormHelperText>
              )}

              <Box sx={{ mt: 3, width: 400 }}>
                <Typography variant="subtitle1" sx={{ fontWeight: '700' }}>
                  Cylinders
                </Typography>
                <VehicleChipRadioButton
                  propertyVal={vehicleDetailsInfo.cylinders}
                  arrayOfItems={cylinderArray as string[]}
                  updateProperty={(v) => {
                    const tmp = { ...vehicleDetailsInfo };
                    tmp.cylinders = v;
                    setVehicleDetailsInfo(tmp);
                    setCylinders(tmp.cylinders);
                  }}
                  chipWidth={'85px'}
                />
                {(!cylinders || cylinders === '') && showValidateError && (
                  <FormHelperText
                    sx={{ ml: 1, mb: 2 }}
                    error={Boolean(!cylinders || cylinders === '')}
                  >
                    You must enter cylinders.
                  </FormHelperText>
                )}
              </Box>
              <Box sx={{ mt: 3, width: 400 }}>
                <Typography variant="subtitle1" sx={{ fontWeight: '700' }}>
                  Fuel Type
                </Typography>
                <VehicleChipRadioButton
                  propertyVal={vehicleDetailsInfo.fuelType.toLowerCase()}
                  arrayOfItems={fuelTypeArray as string[]}
                  updateProperty={(v) => {
                    const tmp = { ...vehicleDetailsInfo };
                    tmp.fuelType = v;
                    setVehicleDetailsInfo(tmp);
                    setFuelType(tmp.fuelType);
                  }}
                  chipWidth={'unset'}
                />
              </Box>
              {(!fuelType || fuelType === '') && showValidateError && (
                <FormHelperText sx={{ ml: 1, mb: 2 }} error={Boolean(!fuelType || fuelType === '')}>
                  You must enter a fuel type.
                </FormHelperText>
              )}

              <Box sx={{ mt: 3, width: 400 }}>
                <Typography variant="subtitle1" sx={{ fontWeight: '700' }}>
                  Doors
                </Typography>
                {doorsArray && (
                  <VehicleChipRadioButton
                    propertyVal={doors}
                    arrayOfItems={doorsArray as string[]}
                    updateProperty={(v) => {
                      const tmp = { ...vehicleDetailsInfo };
                      tmp.doors = v;
                      setVehicleDetailsInfo(tmp);
                      setDoors(tmp.doors);
                    }}
                    chipWidth={'85px'}
                  />
                )}
              </Box>
              {(!doors || doors === '') && showValidateError && (
                <FormHelperText sx={{ ml: 1, mb: 2 }} error={Boolean(!doors || doors === '')}>
                  You must enter doors.
                </FormHelperText>
              )}

              <Box sx={boxStyle}>
                {passengerArray && (
                  <VehicleNumberBox
                    dropBoxValue={vehicleDetailsInfo.passengers}
                    caption={'Number of Passengers'}
                    arrayOfItems={passengerArray as number[]}
                    updateDropValue={(v) => {
                      if (!v) return;
                      const tmp = { ...vehicleDetailsInfo };
                      tmp.passengers = v as number;
                      setVehicleDetailsInfo(tmp);
                    }}
                  />
                )}
              </Box>

              <Box sx={{ mt: 3, width: 400 }}>
                <Typography variant="subtitle1" sx={{ fontWeight: '700' }}>
                  Exterior Colour
                </Typography>
                {extColorArray && extColorCodeArray && (
                  <VehicleColorRadio
                    propertyVal={vehicleDetailsInfo.extColor}
                    colorArray={extColorArray as string[]}
                    codeArray={extColorCodeArray as string[]}
                    showCustomField={(v) => {
                      setShowCustomExt(v);
                    }}
                    updateProperty={(v) => {
                      const tmp = { ...vehicleDetailsInfo };
                      tmp.extColor = v;
                      handleExtColor(tmp, tmp.extColor);
                    }}
                  />
                )}
              </Box>
              {(!extColor || extColor === '') && !showCustomExt && showValidateError && (
                <FormHelperText sx={{ ml: 1, mb: 2 }} error={Boolean(!extColor || extColor === '')}>
                  You must enter exterior colour.
                </FormHelperText>
              )}

              {showCustomExt && (
                <>
                  <Box sx={boxStyle} style={{ marginTop: '10px' }}>
                    <TextField
                      sx={{
                        mt: 1,
                        ml: 2,
                        // color: 'black',
                        width: '85%'
                      }}
                      inputProps={{ style: { fontSize: '12px', fontWeight: '600' } }}
                      InputLabelProps={{ style: { fontSize: '12px' } }}
                      variant="standard"
                      id="outlined-required"
                      label="Custom Colour"
                      onChange={handleCustomExt}
                      onBlur={handleCustomExtBlur}
                      defaultValue={customExt}
                    />
                  </Box>
                  {(!extColor || extColor === '') && showValidateError && (
                    <FormHelperText
                      sx={{ ml: 1, mb: 2 }}
                      error={Boolean(!extColor || extColor === '')}
                    >
                      You must enter exterior colour.
                    </FormHelperText>
                  )}
                </>
              )}

              <Box sx={{ mt: 3, width: 400 }}>
                <Typography variant="subtitle1" sx={{ fontWeight: '700' }}>
                  Interior Colour
                </Typography>
                {intColorArray && intColorCodeArray && (
                  <VehicleColorRadio
                    colorArray={intColorArray as string[]}
                    codeArray={intColorCodeArray as string[]}
                    propertyVal={vehicleDetailsInfo.intColor}
                    showCustomField={(v) => {
                      setShowCustomInt(v);
                    }}
                    updateProperty={(v) => {
                      const tmp = { ...vehicleDetailsInfo };
                      tmp.intColor = v;
                      handleIntColor(tmp, tmp.intColor);
                    }}
                  />
                )}
              </Box>
              {(!intColor || intColor === '') && !showCustomInt && showValidateError && (
                <FormHelperText sx={{ ml: 1, mb: 2 }} error={Boolean(!intColor || intColor === '')}>
                  You must enter interior colour.
                </FormHelperText>
              )}

              {showCustomInt && (
                <>
                  <Box sx={boxStyle} style={{ marginTop: '10px' }}>
                    <TextField
                      sx={{
                        mt: 1,
                        ml: 2,
                        // color: 'black',
                        width: '85%'
                      }}
                      inputProps={{ style: { fontSize: '12px', fontWeight: '600' } }}
                      InputLabelProps={{ style: { fontSize: '12px' } }}
                      variant="standard"
                      id="outlined-required"
                      label="Custom Colour"
                      onChange={handleCustomInt}
                      onBlur={handleCustomIntBlur}
                      defaultValue={customInt}
                    />
                  </Box>
                  {(!intColor || intColor === '') && showValidateError && (
                    <FormHelperText
                      sx={{ ml: 1, mb: 2 }}
                      error={Boolean(!intColor || intColor === '')}
                    >
                      You must enter interior colour.
                    </FormHelperText>
                  )}
                </>
              )}

              <Box sx={{ mt: 3, width: 400 }}>
                <Typography variant="subtitle1" sx={{ fontWeight: '700' }}>
                  Odometer
                </Typography>
                <Box
                  sx={{
                    width: 370,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                  }}
                >
                  <Box sx={boxStyle}>
                    <TextField
                      sx={{
                        mt: 1,
                        ml: 2,
                        // color: 'black',
                        width: '85%'
                      }}
                      inputProps={{ style: { fontSize: '12px', fontWeight: '600' } }}
                      InputLabelProps={{ style: { fontSize: '12px' } }}
                      variant="standard"
                      id="outlined-required"
                      label="Odometer"
                      onChange={handleMileage}
                      defaultValue={mileageVal}
                    />
                  </Box>
                  <ToggleButtonGroup
                    sx={{ height: '40px', ml: '20px' }}
                    color="primary"
                    value={mileageType}
                    exclusive
                    onChange={handleMileageType}
                  >
                    <ToggleButton value="MI">MI</ToggleButton>
                    <ToggleButton value="KM">KM</ToggleButton>
                  </ToggleButtonGroup>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  justifyContent: 'space-between'
                }}
              >
                {(!mileageVal || mileageVal === '') && showValidateError && (
                  <FormHelperText
                    sx={{ ml: 1, mb: 2 }}
                    error={Boolean(!mileageVal || mileageVal === '')}
                  >
                    You must enter mileage.
                  </FormHelperText>
                )}
                {(!mileageType || mileageType === '') && showValidateError && (
                  <FormHelperText
                    sx={{ ml: 1, mb: 2 }}
                    error={Boolean(!mileageType || mileageType === '')}
                  >
                    You must enter mileage type.
                  </FormHelperText>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      {(!yearVal || yearVal.toString() === '') && showValidateError && (
        <Box
          sx={{
            background: theme.palette.error.lighter,
            padding: '5px 10px',
            width: '100%',
            maxWidth: '850px',
            margin: 'auto',
            borderRadius: '10px',
            border: `1px solid ${theme.palette.divider}`,
            marginTop: '10px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <WarningAmberOutlinedIcon sx={{ color: theme.palette.error.main }} />
          <FormHelperText
            sx={{ ml: 1, mb: 1, color: `${theme.palette.common.black} !important` }}
            error={Boolean(!yearVal || yearVal.toString() === '')}
          >
            You must enter a year.
          </FormHelperText>
        </Box>
      )}
      {(!makeVal || makeVal === '') && showValidateError && (
        <Box
          sx={{
            background: theme.palette.error.lighter,
            padding: '5px 10px',
            width: '100%',
            maxWidth: '850px',
            margin: 'auto',
            borderRadius: '10px',
            border: `1px solid ${theme.palette.divider}`,
            marginTop: '10px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <WarningAmberOutlinedIcon sx={{ color: theme.palette.error.main }} />
          <FormHelperText
            sx={{ ml: 1, mb: 1, color: `${theme.palette.common.black} !important` }}
            error={Boolean(!makeVal || makeVal === '')}
          >
            You must enter a make.
          </FormHelperText>
        </Box>
      )}
      {(!modelVal || modelVal === '') && showValidateError && (
        <Box
          sx={{
            background: theme.palette.error.lighter,
            padding: '5px 10px',
            width: '100%',
            maxWidth: '850px',
            margin: 'auto',
            borderRadius: '10px',
            border: `1px solid ${theme.palette.divider}`,
            marginTop: '10px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <WarningAmberOutlinedIcon sx={{ color: theme.palette.error.main }} />
          <FormHelperText
            sx={{ ml: 1, mb: 1, color: `${theme.palette.common.black} !important` }}
            error={Boolean(!modelVal || modelVal === '')}
          >
            You must enter a model.
          </FormHelperText>
        </Box>
      )}
      {(!bodyStyle || bodyStyle === '') && showValidateError && (
        <Box
          sx={{
            background: theme.palette.error.lighter,
            padding: '5px 10px',
            width: '100%',
            maxWidth: '850px',
            margin: 'auto',
            borderRadius: '10px',
            border: `1px solid ${theme.palette.divider}`,
            marginTop: '10px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <WarningAmberOutlinedIcon sx={{ color: theme.palette.error.main }} />
          <FormHelperText
            sx={{ ml: 1, mb: 1, color: `${theme.palette.common.black} !important` }}
            error={Boolean(!bodyStyle || bodyStyle === '')}
          >
            You must enter a body style.
          </FormHelperText>
        </Box>
      )}
      {(!trimVal || trimVal === '') && showValidateError && (
        <Box
          sx={{
            background: theme.palette.error.lighter,
            padding: '5px 10px',
            width: '100%',
            maxWidth: '850px',
            margin: 'auto',
            borderRadius: '10px',
            border: `1px solid ${theme.palette.divider}`,
            marginTop: '10px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <WarningAmberOutlinedIcon sx={{ color: theme.palette.error.main }} />
          <FormHelperText
            sx={{ ml: 1, mb: 1, color: `${theme.palette.common.black} !important` }}
            error={Boolean(!trimVal || trimVal === '')}
          >
            You must enter a trim.
          </FormHelperText>
        </Box>
      )}
      {(!transmission || transmission === '') && showValidateError && (
        <Box
          sx={{
            background: theme.palette.error.lighter,
            padding: '5px 10px',
            width: '100%',
            maxWidth: '850px',
            margin: 'auto',
            borderRadius: '10px',
            border: `1px solid ${theme.palette.divider}`,
            marginTop: '10px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <WarningAmberOutlinedIcon sx={{ color: theme.palette.error.main }} />
          <FormHelperText
            sx={{ ml: 1, mb: 1, color: `${theme.palette.common.black} !important` }}
            error={Boolean(!transmission || transmission === '')}
          >
            You must enter a transmission.
          </FormHelperText>
        </Box>
      )}
      {(!drivetrain || drivetrain === '') && showValidateError && (
        <Box
          sx={{
            background: theme.palette.error.lighter,
            padding: '5px 10px',
            width: '100%',
            maxWidth: '850px',
            margin: 'auto',
            borderRadius: '10px',
            border: `1px solid ${theme.palette.divider}`,
            marginTop: '10px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <WarningAmberOutlinedIcon sx={{ color: theme.palette.error.main }} />
          <FormHelperText
            sx={{ ml: 1, mb: 1, color: `${theme.palette.common.black} !important` }}
            error={Boolean(!drivetrain || drivetrain === '')}
          >
            You must enter a drivetrain.
          </FormHelperText>
        </Box>
      )}
      {(!displacementVal || displacementVal === '') && showValidateError && (
        <Box
          sx={{
            background: theme.palette.error.lighter,
            padding: '5px 10px',
            width: '100%',
            maxWidth: '850px',
            margin: 'auto',
            borderRadius: '10px',
            border: `1px solid ${theme.palette.divider}`,
            marginTop: '10px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <WarningAmberOutlinedIcon sx={{ color: theme.palette.error.main }} />
          <FormHelperText
            sx={{ ml: 1, mb: 1, color: `${theme.palette.common.black} !important` }}
            error={Boolean(!displacementVal || displacementVal === '')}
          >
            You must enter a displacement.
          </FormHelperText>
        </Box>
      )}
      {(!cylinders || cylinders === '') && showValidateError && (
        <Box
          sx={{
            background: theme.palette.error.lighter,
            padding: '5px 10px',
            width: '100%',
            maxWidth: '850px',
            margin: 'auto',
            borderRadius: '10px',
            border: `1px solid ${theme.palette.divider}`,
            marginTop: '10px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <WarningAmberOutlinedIcon sx={{ color: theme.palette.error.main }} />
          <FormHelperText
            sx={{ ml: 1, mb: 1, color: `${theme.palette.common.black} !important` }}
            error={Boolean(!cylinders || cylinders === '')}
          >
            You must enter cylinders.
          </FormHelperText>
        </Box>
      )}
      {(!fuelType || fuelType === '') && showValidateError && (
        <Box
          sx={{
            background: theme.palette.error.lighter,
            padding: '5px 10px',
            width: '100%',
            maxWidth: '850px',
            margin: 'auto',
            borderRadius: '10px',
            border: `1px solid ${theme.palette.divider}`,
            marginTop: '10px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <WarningAmberOutlinedIcon sx={{ color: theme.palette.error.main }} />
          <FormHelperText
            sx={{ ml: 1, mb: 1, color: `${theme.palette.common.black} !important` }}
            error={Boolean(!fuelType || fuelType === '')}
          >
            You must enter a fuel type.
          </FormHelperText>
        </Box>
      )}
      {(!doors || doors === '') && showValidateError && (
        <Box
          sx={{
            background: theme.palette.error.lighter,
            padding: '5px 10px',
            width: '100%',
            maxWidth: '850px',
            margin: 'auto',
            borderRadius: '10px',
            border: `1px solid ${theme.palette.divider}`,
            marginTop: '10px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <WarningAmberOutlinedIcon sx={{ color: theme.palette.error.main }} />
          <FormHelperText
            sx={{ ml: 1, mb: 1, color: `${theme.palette.common.black} !important` }}
            error={Boolean(!doors || doors === '')}
          >
            You must enter doors.
          </FormHelperText>
        </Box>
      )}
      {(!extColor || extColor === '') && showValidateError && (
        <Box
          sx={{
            background: theme.palette.error.lighter,
            padding: '5px 10px',
            width: '100%',
            maxWidth: '850px',
            margin: 'auto',
            borderRadius: '10px',
            border: `1px solid ${theme.palette.divider}`,
            marginTop: '10px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <WarningAmberOutlinedIcon sx={{ color: theme.palette.error.main }} />
          <FormHelperText
            sx={{ ml: 1, mb: 1, color: `${theme.palette.common.black} !important` }}
            error={Boolean(!extColor || extColor === '')}
          >
            You must enter exterior colour.
          </FormHelperText>
        </Box>
      )}
      {(!intColor || intColor === '') && showValidateError && (
        <Box
          sx={{
            background: theme.palette.error.lighter,
            padding: '5px 10px',
            width: '100%',
            maxWidth: '850px',
            margin: 'auto',
            borderRadius: '10px',
            border: `1px solid ${theme.palette.divider}`,
            marginTop: '10px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <WarningAmberOutlinedIcon sx={{ color: theme.palette.error.main }} />
          <FormHelperText
            sx={{ ml: 1, mb: 1, color: `${theme.palette.common.black} !important` }}
            error={Boolean(!intColor || intColor === '')}
          >
            You must enter interior colour.
          </FormHelperText>
        </Box>
      )}
      {(!mileageVal || mileageVal === '') && showValidateError && (
        <Box
          sx={{
            background: theme.palette.error.lighter,
            padding: '5px 10px',
            width: '100%',
            maxWidth: '850px',
            margin: 'auto',
            borderRadius: '10px',
            border: `1px solid ${theme.palette.divider}`,
            marginTop: '10px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <WarningAmberOutlinedIcon sx={{ color: theme.palette.error.main }} />
          <FormHelperText
            sx={{ ml: 1, mb: 1, color: `${theme.palette.common.black} !important` }}
            error={Boolean(!mileageVal || mileageVal === '')}
          >
            You must enter mileage.
          </FormHelperText>
        </Box>
      )}
      {(!mileageType || mileageType === '') && showValidateError && (
        <Box
          sx={{
            background: theme.palette.error.lighter,
            padding: '5px 10px',
            width: '100%',
            maxWidth: '850px',
            margin: 'auto',
            borderRadius: '10px',
            border: `1px solid ${theme.palette.divider}`,
            marginTop: '10px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <WarningAmberOutlinedIcon sx={{ color: theme.palette.error.main }} />
          <FormHelperText
            sx={{ ml: 1, mb: 1, color: `${theme.palette.common.black} !important` }}
            error={Boolean(!mileageType || mileageType === '')}
          >
            You must enter mileage type.
          </FormHelperText>
        </Box>
      )}
      <Divider
        sx={{
          mt: 2,
          borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
        }}
      />
      <Box sx={{ m: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Button variant="text" color="inherit" onClick={() => updVehicleDet(vehicleDetailsInfo, 0)}>
          Back
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            if (isDisabledNext) {
              setShowValidateError(true);
            } else {
              updVehicleDet(vehicleDetailsInfo, 2);
            }
          }}
        >
          Next: Photos
        </Button>
      </Box>
      <MoreItemsIndicator />
    </>
  );
}
