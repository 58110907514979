export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

export const googleAnalyticsConfig = process.env.REACT_APP_GA_MEASUREMENT_ID;

// matt test mode!
// export const STRIPE_PUBLISHABLE_KEY =
//   'pk_test_51MgW2TK3FAqiDmfk8s8qK7chxW6O0frDrqKsD4MUEiubwgwxtMfUgtU39ZBptj4Sn3xAR1hKcIkqafSAjIruwS3000LUxUqdME';

// marc test mode
// export const STRIPE_PUBLISHABLE_KEY =
//   'pk_test_51NpYfZG4ucvq8FH6Korrl1vJOEemjwxFHorWfditamdddJa0HvJXOwpYQTaHvkbFYGzMyKJCeA8iLAP3kFCwWfsQ00D7elcho7';

// marc live mode
export const STRIPE_PUBLISHABLE_KEY =
  'pk_live_51NpYfZG4ucvq8FH6EX5CqkFmA60lEwpTtQgJJ6h1f2R3WqXk45DO69xlCpPAqiOszBnRGxnPcNgQtlhrVz1IQBcT00KCbnjGwh';
