import 'simplebar/src/simplebar.css';
import ReactDOM from 'react-dom';
import { StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { SettingsProvider } from './contexts/SettingsContext';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './contexts/FirebaseContext';
import { FirebaseAppProvider } from 'reactfire';
import { firebaseConfig } from './config';
import { initializeApp } from '@firebase/app';
import { LocalizationProvider } from '@mui/lab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import * as Sentry from '@sentry/react';
import { getDatabase } from 'firebase/database';

// import { getAuth } from 'firebase/auth';
// import { connectAuthEmulator, getAuth } from '@firebase/auth';
// import { connectFirestoreEmulator, getFirestore } from '@firebase/firestore';
// import { connectFunctionsEmulator, getFunctions } from '@firebase/functions';
// import { connectStorageEmulator, getStorage } from '@firebase/storage';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';

// import { Elements } from '@stripe/react-stripe-js';
// import { loadStripe } from '@stripe/stripe-js';
// const stripePromise = loadStripe(
//   'pk_test_51MgW2TK3FAqiDmfk8s8qK7chxW6O0frDrqKsD4MUEiubwgwxtMfUgtU39ZBptj4Sn3xAR1hKcIkqafSAjIruwS3000LUxUqdME'
// );

export const app = initializeApp(firebaseConfig);
export const database = getDatabase(app);

// const auth = getAuth(app);

// emulator for use in dev: firebase emulators:start
// if (process.env.NODE_ENV === 'development') {
//   connectAuthEmulator(getAuth(app), 'http://localhost:9099');
//   connectFirestoreEmulator(getFirestore(app), 'localhost', 8087);
//   connectStorageEmulator(getStorage(app), 'localhost', 9199);
//   connectFunctionsEmulator(getFunctions(app), 'localhost', 5000);
// }

// const options = {
//   clientSecret: 'pi_1EUmy5285d61s2cIUDDd7XEQ_secret_ZWIYZAGWyZlhayYSw6KncDR8K'
// };
const path2server = /^https:\/\/dealer-gavel-19afd\.firebaseapp\.com/;

Sentry.init({
  dsn: 'https://62b440a6e3d3467aa1587bf3524a881d@o4505250784083968.ingest.sentry.io/4505483021320192',
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', path2server]
    }),
    new Sentry.Replay()
  ],
  // Performance Monitoring
  tracesSampleRate: 0.1, // Capture 100% put 1.0
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%.
  replaysOnErrorSampleRate: 0.1 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.render(
  <FirebaseAppProvider firebaseConfig={firebaseConfig}>
    <StrictMode>
      <HelmetProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <SettingsProvider>
            <CollapseDrawerProvider>
              <BrowserRouter>
                <AuthProvider>
                  {/* <Elements stripe={stripePromise} options={options}> */}
                  <App />
                  {/* </Elements> */}
                </AuthProvider>
              </BrowserRouter>
            </CollapseDrawerProvider>
          </SettingsProvider>
        </LocalizationProvider>
      </HelmetProvider>
    </StrictMode>
  </FirebaseAppProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
