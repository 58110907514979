import { Typography, Box, useTheme } from '@mui/material';
// import DrawerMap from './DrawerMap';
// import { withGoogleMap, withScriptjs } from 'react-google-maps';
import { VehicleInfo } from 'types/vehicleInfo';
import { Dealership } from 'types/dealership';
import { useEffect, useState } from 'react';
import { useFirestore } from 'reactfire';
import { doc, getDoc } from 'firebase/firestore';
// import { doc, getDoc } from 'firebase/firestore';
// import { useFirestore } from 'reactfire';

type Props = {
  vehicle: VehicleInfo;
  // dealership: Dealership;
};

export default function SellerTab({ vehicle }: Props) {
  const theme = useTheme();
  const firestore = useFirestore();
  const [dealership, setDealership] = useState<Dealership>();

  const getDealership = async () => {
    const docRef = doc(firestore, `dealerships/${vehicle.dealershipId}`);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const currentDealership = { id: docSnap.id, ...docSnap.data() } as Dealership;
      setDealership(currentDealership);
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted && !dealership) {
      getDealership();
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Typography
        component="p"
        sx={{
          fontSize: '17px',
          fontWeight: '700',
          color: theme.palette.text.secondary,
          margin: '5px 0px'
        }}
      >
        Seller
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
          width: '100%'
        }}
      >
        {dealership && dealership.logoUrl ? (
          <Box
            component="img"
            alt="dealership logo"
            src={dealership?.logoUrl}
            sx={{ width: 'auto', height: '40px', marginRight: '15px' }}
          ></Box>
        ) : (
          <Box></Box>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Typography component="p" variant="h5" sx={{ fontSize: '15px !important' }}>
            {dealership && dealership.dealershipName ? dealership.dealershipName : ''}
          </Typography>
          {/* <Typography
            component="p"
            sx={{
              fontSize: '13px',
              fontWeight: '100'
            }}
          >
            {dealership && dealership.primaryContact ? dealership.primaryContact : ''}
          </Typography> */}
          <Typography
            component="p"
            sx={{
              fontSize: '13px',
              fontWeight: '100'
            }}
          >
            {dealership && dealership.phone ? dealership.phone : ''}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ marginTop: '25px' }}>
        <Typography
          component="p"
          variant="h5"
          sx={{
            fontSize: '15px !important'
          }}
        >
          Dealership Representative
        </Typography>
        {vehicle.auction.dealerRep?.name && (
          <Typography
            component="p"
            sx={{
              fontSize: '13px',
              fontWeight: '100'
            }}
          >
            {vehicle.auction.dealerRep.name}
          </Typography>
        )}
        {vehicle.auction.dealerRep?.email && vehicle.auction.dealerRep?.phone && (
          <Typography
            component="p"
            sx={{
              fontSize: '13px',
              fontWeight: '100'
            }}
          >
            {vehicle.auction.dealerRep.email} | {vehicle.auction.dealerRep.phone}
          </Typography>
        )}
      </Box>
      <Box sx={{ marginTop: '25px' }}>
        <Typography
          component="p"
          variant="h5"
          sx={{
            fontSize: '15px !important'
          }}
        >
          Pickup Location
        </Typography>
        {vehicle.auction.pickUpLocation && (
          <Typography
            component="p"
            sx={{
              fontSize: '13px',
              fontWeight: '100'
            }}
          >
            {vehicle.auction.pickUpLocation.locationName}
          </Typography>
        )}
        {vehicle.auction.pickUpLocation && (
          <Typography
            component="p"
            sx={{
              fontSize: '13px',
              fontWeight: '100'
            }}
          >
            {vehicle.auction.pickUpLocation.locationAddress}
          </Typography>
        )}
        {vehicle.auction.pickUpLocation && (
          <Typography
            component="p"
            sx={{
              fontSize: '13px',
              fontWeight: '100'
            }}
          >
            {vehicle.auction.pickUpLocation.locationCity}
          </Typography>
        )}
        {vehicle.auction.pickUpLocation && (
          <Typography
            component="p"
            sx={{
              fontSize: '13px',
              fontWeight: '100'
            }}
          >
            {vehicle.auction.pickUpLocation.locationProvince}
          </Typography>
        )}
        {vehicle.auction.pickUpLocation && (
          <Typography
            component="p"
            sx={{
              fontSize: '13px',
              fontWeight: '100'
            }}
          >
            {vehicle.auction.pickUpLocation.locationCountry}
          </Typography>
        )}
        {vehicle.auction.pickUpLocation && (
          <Typography
            component="p"
            sx={{
              fontSize: '13px',
              fontWeight: '100'
            }}
          >
            {vehicle.auction.pickUpLocation.locationPostalCode}
          </Typography>
        )}
        {vehicle.auction.pickUpLocation && (
          <Typography
            component="p"
            sx={{
              fontSize: '13px',
              fontWeight: '100'
            }}
          >
            {vehicle.auction.pickUpLocation.locationPhone}
          </Typography>
        )}
        {vehicle.auction.pickUpLocation && (
          <Typography
            component="p"
            sx={{
              fontSize: '13px',
              fontWeight: '100'
            }}
          >
            {vehicle.auction.pickUpLocation.locationNotes}
          </Typography>
        )}
      </Box>
      {/* <MapWrapper
        googleMapURL={`https://maps.googleapis.com/maps/api/js?libraries=geometry,drawing,places&key=AIzaSyBq6ZGtuVF7_0qdG2G2O041KifAh8tkcyo`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: '300px', marginTop: '20px' }} />}
        mapElement={<div style={{ height: `100%` }} />}
      /> */}
      {/* {vehicle.disclosures.notes && vehicle.disclosures.notes !== ' ' && (
        <Box sx={{ marginTop: '25px' }}>
          <Typography
            component="p"
            variant="h5"
            sx={{
              fontSize: '15px !important'
            }}
          >
            Dealership Notes
          </Typography>
          <Typography
            component="p"
            sx={{
              fontSize: '13px',
              fontWeight: '100'
            }}
          >
            {vehicle.disclosures.notes}
          </Typography>
        </Box>
      )} */}
    </>
  );
}
