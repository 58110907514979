import {
  Chip,
  RadioGroup,
  FormControlLabel,
  TextField,
  Typography,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Grid,
  Divider,
  styled,
  FormGroup,
  FormControl,
  InputLabel,
  Tooltip,
  TextFieldProps
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import moment from 'moment';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { makeStyles } from '@mui/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useState, useEffect, FocusEventHandler } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { useTheme } from '@mui/material/styles';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { DesktopDatePicker } from '@mui/lab';
/// -----------------------------------------------------------------------

type VehicleChipRadio = {
  propertyVal: string;
  arrayOfItems: string[];
  updateProperty: (value: string) => void;
  chipWidth: string;
  hasVin?: boolean;
};

export function VehicleChipRadioButton({
  propertyVal,
  arrayOfItems,
  updateProperty,
  chipWidth,
  hasVin
}: VehicleChipRadio) {
  const theme = useTheme();
  const [selChip, setSelChip] = useState(0);

  useEffect(() => {
    setSelChip(arrayOfItems.indexOf(propertyVal) || 0);
  }, [arrayOfItems, propertyVal]);

  const handleClick = (index: number, val: string) => {
    setSelChip(index);
    updateProperty(val);
  };
  return (
    <RadioGroup sx={{ ml: 0.5, mb: 1, display: 'flex', flexDirection: 'row' }}>
      {arrayOfItems.map((item, index) => {
        return (
          <FormControlLabel
            key={index}
            label={''}
            control={
              <Chip
                sx={{
                  backgroundColor:
                    selChip === index
                      ? theme.palette.primary.main
                      : theme.palette.mode === 'dark'
                      ? 'unset'
                      : theme.palette.common.white,
                  color: selChip === index ? theme.palette.common.white : 'grey',
                  border: '1px solid lightBlue',
                  fontWeight: '700',
                  margin: '5px',
                  width: chipWidth,
                  mr: 0.5
                }}
                key={index}
                label={item.toUpperCase()}
                onClick={() => handleClick(index, item)}
                disabled={propertyVal && hasVin ? true : false}
              />
            }
          />
        );
      })}
    </RadioGroup>
  );
}

/// -----------------------------------------------------------------------

type VehicleChipCheckboxType = {
  propertyVal: string[];
  arrayOfItems: string[];
  updateProperty: (value: string[]) => void;
  chipWidth: string;
};

export function VehicleChipCheckbox({
  propertyVal,
  arrayOfItems,
  updateProperty,
  chipWidth
}: VehicleChipCheckboxType) {
  const theme = useTheme();
  const [selChip, setSelChip] = useState(new Array(arrayOfItems.length).fill(-1));

  useEffect(() => {
    let pvKey: any = {};
    let tmpSel = new Array(arrayOfItems.length).fill(-1);
    if (propertyVal) {
      propertyVal.forEach((pv) => (pvKey[pv] = pv));
      arrayOfItems.forEach((item, index) => {
        if (item === pvKey[item]) {
          tmpSel[index] = index;
        } else {
          tmpSel[index] = -1;
        }
      });
      setSelChip(tmpSel);
    }
  }, [arrayOfItems, propertyVal]);

  const handleClick = (index: number, val: string) => {
    const tmpSel = [...selChip];
    tmpSel[index] = tmpSel[index] === index ? -1 : index;
    setSelChip(tmpSel);
    let tmpVal = [] as string[];
    if (propertyVal) {
      tmpVal = [...propertyVal];
    }
    if (tmpSel[index] > -1) {
      tmpVal.push(val);
    } else {
      tmpVal.splice(
        tmpVal.findIndex((o) => o === val),
        1
      );
    }
    updateProperty(tmpVal);
  };
  return (
    <FormGroup sx={{ ml: 0.5, mb: 1, display: 'flex', flexDirection: 'row' }}>
      {arrayOfItems.map((item: any, index: number) => {
        return (
          <FormControlLabel
            key={index}
            label={''}
            control={
              <Chip
                sx={{
                  backgroundColor:
                    selChip[index] !== -1
                      ? theme.palette.primary.main
                      : theme.palette.mode === 'dark'
                      ? 'unset'
                      : theme.palette.common.white,
                  color: selChip[index] !== -1 ? theme.palette.common.white : 'grey',
                  // backgroundColor: selChip[index] !== -1 ? theme.palette.primary.main : 'white',
                  // color: selChip[index] !== -1 ? theme.palette.common.white : 'grey',
                  border: '1px solid lightBlue',
                  fontWeight: '700',
                  margin: '5px',
                  width: chipWidth,
                  mr: 0.5
                }}
                label={item}
                onClick={() => handleClick(index, item)}
              />
            }
          />
        );
      })}
    </FormGroup>
  );
}

/// -----------------------------------------------------------------------

type VehicleDropBoxProps = {
  dropBoxValue: any;
  caption: string;
  arrayOfItems: string[];
  disabled?: boolean;
  updateDropValue: (value: string) => void;
  handleBlur?: FocusEventHandler<HTMLDivElement>;
};

export function VehicleDropBox({
  dropBoxValue,
  caption,
  arrayOfItems,
  updateDropValue,
  handleBlur,
  disabled
}: VehicleDropBoxProps) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e: any) => {
    const val = e.target.innerText;
    updateDropValue(val);
    setAnchorEl(null);
  };

  return (
    <>
      <Typography sx={{ mt: 1, ml: 2, fontSize: '12px', color: 'grey' }}>{caption}</Typography>
      <Box sx={{ mt: -1.5, display: 'flex', justifyContent: 'space-between' }}>
        <Typography
          sx={{
            ml: 2,
            mt: 1.5,
            fontSize: '12px',
            fontWeight: '600',
            color: theme.palette.text.primary,
            textTransform: 'capitalize'
          }}
        >
          {dropBoxValue}
        </Typography>
        {!disabled && (
          <IconButton
            id="icon-button"
            color="inherit"
            aria-controls={open ? 'icon-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <KeyboardArrowDownIcon />
          </IconButton>
        )}
        <Menu
          id="icon-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onBlur={handleBlur}
          MenuListProps={{
            'aria-labelledby': 'basic-button'
          }}
          sx={{ zIndex: 9999 }}
        >
          {arrayOfItems.map((ye, index) => (
            <MenuItem sx={{ fontSize: '12px' }} key={index} value={ye} onClick={handleClose}>
              {ye}
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </>
  );
}

type VehicleNumberBoxProps = {
  dropBoxValue: any;
  caption: string;
  arrayOfItems: number[];
  disabled?: boolean;
  updateDropValue: (value: number) => void;
};

export function VehicleNumberBox({
  dropBoxValue,
  caption,
  arrayOfItems,
  updateDropValue,
  disabled
}: VehicleNumberBoxProps) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e: any) => {
    const val = e.target.value;
    updateDropValue(val);
    setAnchorEl(null);
  };

  return (
    <>
      <Typography sx={{ mt: 1, ml: 2, fontSize: '12px', color: theme.palette.text.secondary }}>
        {caption}
      </Typography>
      <Box sx={{ mt: -1.5, display: 'flex', justifyContent: 'space-between' }}>
        <Typography
          sx={{
            ml: 2,
            mt: 1.5,
            fontSize: '12px',
            fontWeight: '600',
            color: theme.palette.text.primary
          }}
        >
          {dropBoxValue}
        </Typography>
        {!disabled && (
          <IconButton
            id="icon-button"
            color="inherit"
            aria-controls={open ? 'icon-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <KeyboardArrowDownIcon />
          </IconButton>
        )}
        <Menu
          id="icon-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button'
          }}
          sx={{ zIndex: 9999 }}
        >
          {arrayOfItems.map((ye, index) => (
            <MenuItem sx={{ fontSize: '12px' }} key={index} value={ye} onClick={handleClose}>
              {ye}
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </>
  );
}

/// -----------------------------------------------------------------------

type VehicleSubDropBoxProps = {
  dropBoxValue: any;
  caption: string;
  topArray: string[];
  subArrayOne: string[];
  subArrayTwo?: string[];
  subArrayThree?: string[];
  subArrayFour?: string[];
  subArrayFive?: string[];
  disabled?: boolean;
  isExtDmg?: boolean;
  isIntDmg?: boolean;
  updateDropValue: (value: string) => void;
};

export function VehicleSubDropBox({
  dropBoxValue,
  caption,
  topArray,
  subArrayOne,
  subArrayTwo,
  subArrayThree,
  subArrayFour,
  subArrayFive,
  updateDropValue,
  disabled,
  isExtDmg,
  isIntDmg
}: VehicleSubDropBoxProps) {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState(null);
  const [subAnchorElOne, setSubAnchorElOne] = useState(null);
  const [subAnchorElTwo, setSubAnchorElTwo] = useState(null);
  const [subAnchorElThree, setSubAnchorElThree] = useState(null);
  const [subAnchorElFour, setSubAnchorElFour] = useState(null);
  const [subAnchorElFive, setSubAnchorElFive] = useState(null);

  const [menuIndex, setMenuIndex] = useState<number>();

  const open = Boolean(anchorEl);
  const subOpenOne = Boolean(subAnchorElOne);
  const subOpenTwo = Boolean(subAnchorElTwo);
  const subOpenThree = Boolean(subAnchorElThree);
  const subOpenFour = Boolean(subAnchorElFour);
  const subOpenFive = Boolean(subAnchorElFive);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e: any) => {
    if (isExtDmg) {
      const val = e.target.innerText;
      if (menuIndex === 6) {
        updateDropValue(val);
        setSubAnchorElOne(null);
      } else if (menuIndex === 7) {
        updateDropValue(val);
        setSubAnchorElTwo(null);
      } else if (menuIndex === 8) {
        updateDropValue(val);
        setSubAnchorElThree(null);
      } else if (menuIndex === 9) {
        updateDropValue(val);
        setSubAnchorElFour(null);
      }
      if (val || (!subAnchorElOne && !subAnchorElTwo && !subAnchorElThree && !subAnchorElFour)) {
        setAnchorEl(null);
      }
    } else if (isIntDmg) {
      const val = e.target.innerText;
      if (menuIndex === 4) {
        updateDropValue(val);
        setSubAnchorElOne(null);
      }
      if (val || !subAnchorElOne) {
        setAnchorEl(null);
      }
    } else {
      const val = e.target.innerText;
      updateDropValue(val);
      if (menuIndex === 0) {
        setSubAnchorElOne(null);
      } else if (menuIndex === 1) {
        setSubAnchorElTwo(null);
      } else if (menuIndex === 2) {
        setSubAnchorElThree(null);
      } else if (menuIndex === 3) {
        setSubAnchorElFour(null);
      } else {
        setSubAnchorElFive(null);
      }
      if (
        val ||
        (!subAnchorElOne &&
          !subAnchorElTwo &&
          !subAnchorElThree &&
          !subAnchorElFour &&
          !subAnchorElFive)
      ) {
        setAnchorEl(null);
      }
    }
  };

  const handleSubMenu = (e: any, index: number) => {
    setMenuIndex(index);
    if (isExtDmg) {
      if (index === 6) {
        setSubAnchorElOne(anchorEl);
      } else if (index === 7) {
        setSubAnchorElTwo(anchorEl);
      } else if (index === 8) {
        setSubAnchorElThree(anchorEl);
      } else if (index === 9) {
        setSubAnchorElFour(anchorEl);
      } else {
        const val = e.target.innerText;
        updateDropValue(val);
      }
    } else if (isIntDmg) {
      if (index === 4) {
        setSubAnchorElOne(anchorEl);
      } else {
        const val = e.target.innerText;
        updateDropValue(val);
      }
    } else {
      if (index === 0) {
        setSubAnchorElOne(anchorEl);
      } else if (index === 1) {
        setSubAnchorElTwo(anchorEl);
      } else if (index === 2) {
        setSubAnchorElThree(anchorEl);
      } else if (index === 3) {
        setSubAnchorElFour(anchorEl);
      } else {
        setSubAnchorElFive(anchorEl);
      }
    }
  };

  return (
    <>
      <Typography sx={{ mt: 1, ml: 2, fontSize: '12px', color: 'grey' }}>{caption}</Typography>
      <Box sx={{ mt: -1.5, display: 'flex', justifyContent: 'space-between' }}>
        <Typography
          sx={{
            ml: 2,
            mt: 1.5,
            fontSize: '12px',
            fontWeight: '600',
            color: theme.palette.text.primary
          }}
        >
          {dropBoxValue}
        </Typography>
        {!disabled && (
          <IconButton
            id="icon-button"
            color="inherit"
            aria-controls={open ? 'icon-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <KeyboardArrowDownIcon />
          </IconButton>
        )}
        <Menu
          id="icon-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button'
          }}
          sx={{ zIndex: 9999 }}
        >
          {topArray.map((item, index) => (
            <MenuItem
              sx={{ fontSize: '12px' }}
              key={index}
              value={item}
              onClick={(e) => handleSubMenu(e, index)}
            >
              {item} <KeyboardArrowRightIcon fontSize="small" />
            </MenuItem>
          ))}
        </Menu>
        <Menu
          id="icon-sub-menu"
          anchorEl={subAnchorElOne}
          open={subOpenOne}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button'
          }}
          sx={{ zIndex: 9999 }}
        >
          {subArrayOne.map((item, index) => (
            <MenuItem sx={{ fontSize: '12px' }} key={index} value={item} onClick={handleClose}>
              {item}
            </MenuItem>
          ))}
        </Menu>
        {subArrayTwo && (
          <Menu
            id="icon-sub-menu"
            anchorEl={subAnchorElTwo}
            open={subOpenTwo}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
            sx={{ zIndex: 9999 }}
          >
            {subArrayTwo.map((item, index) => (
              <MenuItem sx={{ fontSize: '12px' }} key={index} value={item} onClick={handleClose}>
                {item}
              </MenuItem>
            ))}
          </Menu>
        )}
        {subArrayThree && (
          <Menu
            id="icon-sub-menu"
            anchorEl={subAnchorElThree}
            open={subOpenThree}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
            sx={{ zIndex: 9999 }}
          >
            {subArrayThree.map((item, index) => (
              <MenuItem sx={{ fontSize: '12px' }} key={index} value={item} onClick={handleClose}>
                {item}
              </MenuItem>
            ))}
          </Menu>
        )}
        {subArrayFour && (
          <Menu
            id="icon-sub-menu"
            anchorEl={subAnchorElFour}
            open={subOpenFour}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
            sx={{ zIndex: 9999 }}
          >
            {subArrayFour.map((item, index) => (
              <MenuItem sx={{ fontSize: '12px' }} key={index} value={item} onClick={handleClose}>
                {item}
              </MenuItem>
            ))}
          </Menu>
        )}
        {subArrayFive && (
          <Menu
            id="icon-sub-menu"
            anchorEl={subAnchorElFive}
            open={subOpenFive}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
            sx={{ zIndex: 9999 }}
          >
            {subArrayFive.map((item, index) => (
              <MenuItem sx={{ fontSize: '12px' }} key={index} value={item} onClick={handleClose}>
                {item}
              </MenuItem>
            ))}
          </Menu>
        )}
      </Box>
    </>
  );
}

/// -----------------------------------------------------------------------

type VehicleCalendarProps = {
  dropBoxValue: any;
  caption: string;
  auctionMinDate?: Date | undefined;
  auctionMaxDate?: Date | undefined;
  updateDropValue: (value: Date | null) => void;
  allowPast?: boolean;
};

export function VehicleCalendar({
  dropBoxValue,
  caption,
  updateDropValue,
  auctionMinDate,
  auctionMaxDate,
  allowPast
}: VehicleCalendarProps) {
  const [value, setValue] = useState(dropBoxValue || null);
  const theme = useTheme();

  useEffect(() => {
    if (!dropBoxValue) {
      setValue('');
      updateDropValue(null);
    }
  }, [dropBoxValue]);

  return (
    <>
      {/* <Typography sx={{ ml: 2, mt: 1.5, fontSize: '12px', color: 'grey' }}>{caption}</Typography> */}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          label={caption}
          value={value !== '' ? value : null}
          disablePast={!allowPast ? true : false}
          minDate={auctionMinDate}
          maxDate={auctionMaxDate}
          onChange={(newValue) => {
            setValue(newValue);
            // if (newValue !== null) updateDropValue(newValue);
            updateDropValue(newValue);
          }}
          // renderInput={({ inputRef, inputProps, InputProps }) => (
          //   <Box
          //     sx={{
          //       ml: 2,
          //       mt: 0.5,
          //       width: '90%',
          //       display: 'flex',
          //       alignItems: 'center',
          //       justifyContent: 'space-between'
          //     }}
          //   >
          //     <input
          //       style={{
          //         border: 0,
          //         outline: 0,
          //         color: theme.palette.text.primary,
          //         backgroundColor: 'transparent',
          //         fontWeight: 600,
          //         fontSize: '12px'
          //       }}
          //       ref={inputRef}
          //       {...inputProps}
          //     />

          //     {InputProps?.endAdornment}
          //   </Box>
          // )}
          renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
            <TextField
              {...params}
              sx={{
                width: '100%',
                borderRadius: '10px',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: theme.palette.divider
                  }
                }
              }}
            />
          )}
        />
      </LocalizationProvider>
    </>
  );
}

/// -----------------------------------------------------------------------
function CustomTextField(params: TextFieldProps, customWidth: any) {
  return (
    <Tooltip title="Click To Edit Date. Format Is mm/dd/yyyy">
      <Box
        sx={{
          mt: 0.5,
          width: '175px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start'
        }}
      >
        <TextField {...params} />
      </Box>
    </Tooltip>
  );
}

type ListItemDatePickerProps = {
  fieldValue: any;
  label: string;
  isBold?: boolean;
  customWidth?: string;
  updateValue: (value: Date) => void;
};

export function ListItemDatePicker({
  fieldValue,
  label,
  updateValue,
  customWidth
}: ListItemDatePickerProps) {
  const [value, setValue] = useState(fieldValue || null);
  const theme = useTheme();

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label={label}
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
            updateValue(newValue);
            // if (newValue !== null) updateValue(newValue);
          }}
          slots={{
            textField: CustomTextField
          }}
          // renderInput={({ inputRef, inputProps, InputProps }) => (
          //   <Tooltip title="Click To Edit Date. Format Is mm/dd/yyyy">
          //     <Box
          //       sx={{
          //         mt: 0.5,
          //         width: customWidth ? customWidth : '90%',
          //         display: 'flex',
          //         alignItems: 'center',
          //         justifyContent: 'flex-start'
          //       }}
          //     >
          //       <input
          //         style={{
          //           border: 0,
          //           outline: 0,
          //           color: theme.palette.text.primary,
          //           backgroundColor: 'transparent',
          //           fontWeight: 300,
          //           fontSize: '16px'
          //         }}
          //         ref={inputRef}
          //         {...inputProps}
          //       />
          //     </Box>
          //   </Tooltip>
          // )}
        />
      </LocalizationProvider>
    </>
  );
}
/// -----------------------------------------------------------------------

type VehicleTimeProps = {
  dropBoxValue: any;
  caption: string;
  auctionMinTime: Date | undefined;
  isDisabled?: boolean;
  updateDropValue: (value: Date | null) => void;
};

export function VehicleTime({
  dropBoxValue,
  caption,
  updateDropValue,
  auctionMinTime,
  isDisabled
}: VehicleTimeProps) {
  const [value, setValue] = useState(dropBoxValue || null);
  const theme = useTheme();

  useEffect(() => {
    if (!dropBoxValue) {
      setValue('');
      updateDropValue(null);
    }
  }, [dropBoxValue]);

  return (
    <>
      {/* <Typography sx={{ ml: 2, mt: 1.5, fontSize: '12px', color: 'grey' }}>{caption}</Typography> */}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <TimePicker
          label={caption}
          disabled={isDisabled}
          // disableOpenPicker={true}
          value={value !== '' ? value : null}
          format="hh:mm a"
          // disableMaskedInput={false}
          minTime={auctionMinTime}
          onChange={(newValue) => {
            setValue(newValue);
            updateDropValue(newValue);
          }}
          // renderInput={({ inputRef, inputProps, InputProps }) => (
          //   <Box
          //     sx={{
          //       ml: 2,
          //       mt: 0.5,
          //       width: '90%',
          //       display: 'flex',
          //       alignItems: 'center',
          //       justifyContent: 'space-between'
          //     }}
          //   >
          //     <input
          //       style={{
          //         border: 0,
          //         outline: 0,
          //         color: theme.palette.text.primary,
          //         backgroundColor: 'transparent',
          //         fontWeight: 600,
          //         fontSize: '12px'
          //       }}
          //       ref={inputRef}
          //       {...inputProps}
          //     />

          //     {InputProps?.endAdornment}
          //   </Box>
          // )}
          // renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
          //   <TextField
          //     {...params}
          //     sx={{
          //       width: '100%',
          //       borderRadius: '10px',
          //       '& .MuiOutlinedInput-root': {
          //         '& fieldset': {
          //           borderColor: theme.palette.divider
          //         }
          //       }
          //     }}
          //   />
          // )}
          slotProps={{
            textField: {
              variant: 'outlined',
              sx: {
                width: '100%',
                borderRadius: '10px',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: theme.palette.divider
                  }
                }
              }
            }
          }}
        />
      </LocalizationProvider>
    </>
  );
}

/// -----------------------------------------------------------------------

type VehicleTimePickerProps = {
  timeValue: any;
  caption: string;
  updateTimeValue: (value: Date) => void;
};

export function VehicleTimePicker({ timeValue, caption, updateTimeValue }: VehicleTimePickerProps) {
  const [value, setValue] = useState(timeValue || null);
  const theme = useTheme();

  const useStyles = makeStyles({
    timeInput: {
      width: '400px',
      height: '50px',
      border: 'none',
      marginTop: '10px',
      padding: '0px 17px',
      '&:focus': {
        outline: 'none'
      },
      '&::-webkit-calendar-picker-indicator': {
        width: '25px',
        height: '25px',
        filter: `invert(47%) sepia(9%) saturate(473%) hue-rotate(171deg) brightness(94%) contrast(91%)`
      }
    }
  });

  const styles = useStyles();

  return (
    <FormControl variant="standard">
      <InputLabel
        sx={{
          fontFamily: 'Public Sans,sans-serif',
          fontWeight: '400',
          marginLeft: '16px',
          marginTop: '-12px',
          fontSize: '12px',
          color: theme.palette.info.main
        }}
      >
        {caption}
      </InputLabel>
      <input
        className={styles.timeInput}
        type="time"
        min="01:00"
        max="12:00"
        value={moment(value).format('hh:mm a')}
        onChange={(e: any) => {
          const time = e.target.value.split(':');
          const momentObj = moment(new Date());
          momentObj.set({ hours: time[0], minutes: time[1] });
          const date = momentObj.toDate();
          setValue(date);
          updateTimeValue(date);
          // tmp.launchTime = Timestamp.fromDate(date);
          // setAuctionInfo(tmp);
          // setLaunchTimeVal(tmp.launchTime);
        }}
      />
    </FormControl>
  );
}

/// -----------------------------------------------------------------------

export const StyledTextField = styled(TextField)`
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    width: 400
  },
  //style for font size
  resize:{
    fontSize:12
  },
`;

/// -----------------------------------------------------------------------
const StyledToggleButtonRoof = styled(ToggleButton)`
  font-size: 12px;
  background-color: #ffffff;
  height: 30px;
`;

type ToggleGroupRoofProps = {
  dropBoxValue: any;
  arrayOfItems: string[];
  updateDropValue: (value: string) => void;
};

export function VehicleToggleGroupRoof({
  dropBoxValue,
  arrayOfItems,
  updateDropValue
}: ToggleGroupRoofProps) {
  const [currentVal, setCurrentVal] = useState(dropBoxValue);
  const handleChange = (event: any, value: string) => {
    if (value) {
      setCurrentVal(value);
      updateDropValue(value);
    }
  };

  return (
    <ToggleButtonGroup
      sx={{ textTransform: 'capitalize' }}
      color="primary"
      value={currentVal}
      exclusive
      onChange={handleChange}
      orientation="vertical"
    >
      <StyledToggleButtonRoof value={arrayOfItems[0]}>
        {arrayOfItems[0] || ''}
      </StyledToggleButtonRoof>
      <StyledToggleButtonRoof value={arrayOfItems[1]}>
        {arrayOfItems[1] || ''}
      </StyledToggleButtonRoof>
      <StyledToggleButtonRoof value={arrayOfItems[2]}>
        {arrayOfItems[2] || ''}
      </StyledToggleButtonRoof>
      <StyledToggleButtonRoof value={arrayOfItems[3]}>
        {arrayOfItems[3] || ''}
      </StyledToggleButtonRoof>
    </ToggleButtonGroup>
  );
}
/// -----------------------------------------------------------------------

const StyledToggleButton = styled(ToggleButton)`
  font-size: 12px;
  background-color: #ffffff;
`;

type ToggleGroupProps = {
  dropBoxValue: any;
  arrayOfItems: string[];
  updateDropValue: (value: string) => void;
};

export function VehicleToggleGroup({
  dropBoxValue,
  arrayOfItems,
  updateDropValue
}: ToggleGroupProps) {
  const [currentVal, setCurrentVal] = useState(dropBoxValue);
  const handleChange = (event: any, value: string) => {
    if (value) {
      setCurrentVal(value);
      updateDropValue(value);
    }
  };

  return (
    <ToggleButtonGroup
      sx={{
        height: '30px',
        textTransform: 'capitalize'
      }}
      color="primary"
      value={currentVal}
      exclusive
      onChange={handleChange}
    >
      <StyledToggleButton value={arrayOfItems[0]}>{arrayOfItems[0] || ''}</StyledToggleButton>
      <StyledToggleButton value={arrayOfItems[1]}>{arrayOfItems[1] || ''}</StyledToggleButton>
    </ToggleButtonGroup>
  );
}

/// -----------------------------------------------------------------------
type GridElementRoofProps = {
  caption: string;
  dropBoxValue: any;
  arrayOfItems: string[];
  updateDropValue: (value: string) => void;
};

export function GridElementRoof({
  caption,
  dropBoxValue,
  arrayOfItems,
  updateDropValue
}: GridElementRoofProps) {
  const theme = useTheme();
  return (
    <>
      <Grid
        sx={{
          mt: 2,
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around'
        }}
      >
        <Grid item xs={4}>
          <Typography sx={{ mt: 0, fontSize: '14px' }}>{caption}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Box sx={{ width: '100%', textAlign: 'right' }}>
            <VehicleToggleGroupRoof
              dropBoxValue={dropBoxValue}
              arrayOfItems={arrayOfItems}
              updateDropValue={updateDropValue}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
/// -----------------------------------------------------------------------

type GridElementProps = {
  caption: string;
  dropBoxValue: any;
  arrayOfItems: string[];
  isDateExpected?: boolean;
  updateDropValue: (value: string) => void;
};

export function GridElement({
  caption,
  dropBoxValue,
  arrayOfItems,
  isDateExpected,
  updateDropValue
}: GridElementProps) {
  const theme = useTheme();
  return (
    <>
      <Grid
        sx={{
          mt: 2,
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around'
        }}
      >
        <Grid item xs={6}>
          <Typography sx={{ mt: 0, fontSize: '14px' }}>{caption}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ width: '100%', textAlign: 'right' }}>
            <VehicleToggleGroup
              dropBoxValue={dropBoxValue}
              arrayOfItems={arrayOfItems}
              updateDropValue={updateDropValue}
            />
          </Box>
        </Grid>
      </Grid>
      {!isDateExpected && (
        <Divider
          sx={{
            mt: 0.5,
            mb: 0.5,
            borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
          }}
        />
      )}
    </>
  );
}

/// -----------------------------------------------------------------------

type VehicleCheckboxType = {
  propertyVal: string[];
  arrayOfItems: string[];
  updateProperty: (value: string[]) => void;
};

export function VehicleCheckbox({
  propertyVal,
  arrayOfItems,
  updateProperty
}: VehicleCheckboxType) {
  const [selCheckbox, setSelCheckbox] = useState(new Array(arrayOfItems.length).fill(-1));

  useEffect(() => {
    let pvKey: any = {};
    let tmpSel = new Array(arrayOfItems.length).fill(-1);
    if (propertyVal) {
      propertyVal.forEach((pv) => (pvKey[pv] = pv));
      arrayOfItems.forEach((item, index) => {
        if (item === pvKey[item]) {
          tmpSel[index] = index;
        } else {
          tmpSel[index] = -1;
        }
      });
      setSelCheckbox(tmpSel);
    }
  }, [arrayOfItems, propertyVal]);

  const handleChange = (index: number, val: string) => {
    const tmpSel = [...selCheckbox];
    tmpSel[index] = tmpSel[index] === index ? -1 : index;
    setSelCheckbox(tmpSel);
    let tmpVal = [] as string[];
    if (propertyVal) {
      tmpVal = [...propertyVal];
    }
    if (tmpSel[index] > -1) {
      tmpVal.push(val);
    } else {
      tmpVal.splice(
        tmpVal.findIndex((o) => o === val),
        1
      );
    }
    updateProperty(tmpVal);
  };
  return (
    <FormGroup sx={{ ml: 0.5, mb: 1, display: 'flex', flexDirection: 'column' }}>
      {arrayOfItems.map((item: any, index: number) => {
        return (
          <FormControlLabel
            key={index}
            label={<Typography sx={{ fontSize: '12px' }}>{item}</Typography>}
            control={
              <Checkbox
                size="small"
                checked={selCheckbox[index] !== -1 ? true : false}
                //label={item}
                onChange={() => handleChange(index, item)}
              />
            }
          />
        );
      })}
    </FormGroup>
  );
}
