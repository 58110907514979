import { useState } from 'react';
import { Typography, Box, Chip, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  ConditionOfTiresType,
  PaintedPanelsType,
  VehicleInfo,
  VehiclePhotos
} from 'types/vehicleInfo';
import { CondensedAccordion } from './CondensedAccordion';

type Props = {
  vehicle: VehicleInfo;
};

export default function DamagesTab({ vehicle }: Props) {
  const theme = useTheme();
  const [expandedDamage, setExpandedDamage] = useState<boolean>(true);
  const [expandedTires, setExpandedTires] = useState<boolean>(true);
  const [expandedPanels, setExpandedPanels] = useState<boolean>(true);
  const [expandedNotes, setExpandedNotes] = useState<boolean>(true);

  const handleChangeDamage = () => {
    setExpandedDamage(!expandedDamage);
  };

  const handleChangeTires = () => {
    setExpandedTires(!expandedTires);
  };

  const handleChangePanels = () => {
    setExpandedPanels(!expandedPanels);
  };

  const handleChangeNotes = () => {
    setExpandedNotes(!expandedNotes);
  };

  const useStyles = makeStyles({
    carfaxContainer: {
      margin: '5px',
      padding: '5px 5px',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.palette.divider,
      borderRadius: '5px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start'
    },
    bidsText: {
      lineHeight: 1.5,
      marginLeft: '3px',
      fontSize: '14px',
      color: theme.palette.info.lighter
    },
    panelText: {
      lineHeight: 1.5,
      marginLeft: '3px',
      fontSize: '14px'
    },
    tableRow: {
      '&:nth-child(odd)': {
        backgroundColor: theme.palette.background.paper
      },
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      padding: '1px 10px'
    },
    contentChip: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      fontWeight: '700',
      margin: '5px'
    }
  });

  const styles = useStyles();

  // -----------------------------------------------------
  const overallCondition = +vehicle.conditions.overall || 0;
  const { error, warning, success } = theme.palette;
  let bgColor, bgColorLight;

  if (overallCondition >= 0 && overallCondition < 6) {
    bgColor = error.main;
    bgColorLight = error.light;
  } else if (overallCondition >= 6 && overallCondition < 7) {
    bgColor = '#D16C00';
    bgColorLight = '#FFA83D';
  } else if (overallCondition >= 7 && overallCondition < 8) {
    bgColor = warning.darker;
    bgColorLight = warning.light;
  } else {
    bgColor = success.main;
    bgColorLight = success.lighter;
  }
  // -----------------------------------------------------
  return (
    <>
      <Typography
        component="p"
        sx={{
          fontSize: '17px',
          fontWeight: '700',
          color: theme.palette.text.secondary,
          margin: '5px 0px'
        }}
      >
        Damages and Condition
      </Typography>
      <Chip
        label={`Overall Condition: ${vehicle.conditions.overall ?? 0}`}
        sx={{
          border: `2px solid ${bgColor}`,
          backgroundColor: bgColorLight, //theme.palette.success.lighter,
          color: bgColor, //theme.palette.success.main,
          borderRadius: '5px'
        }}
      ></Chip>

      <CondensedAccordion
        title={'Damage List'}
        expanded={expandedDamage}
        handleExpanded={handleChangeDamage}
      >
        {vehicle.photos &&
          vehicle.photos
            .filter(
              (photo: VehiclePhotos) => photo.categoryName && photo.categoryName === 'Damages'
            )
            .map((data: VehiclePhotos, index: number) => {
              return (
                <Box key={index}>
                  <Box
                    className={styles.carfaxContainer}
                    sx={{
                      fontSize: '14px',
                      fontWeight: 'bold'
                    }}
                  >
                    {data.url && (
                      <Box
                        component="img"
                        alt="vehicle photo"
                        src={data.url}
                        sx={{ width: 'auto', height: '40px', marginRight: '5px' }}
                      ></Box>
                    )}
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                      <Typography sx={{ ml: 1, fontSize: '12px', fontWeight: 'bold' }}>
                        {data.location} :
                      </Typography>
                      {/* prettier-ignore */}
                      <Typography className={styles.bidsText} sx={{ fontSize:  '12px !important' }}>
                        {` ${data.damageType ? data.damageType : 'No Dmg Type'} | ${data.damageSize ? data.damageSize : 'No Dmg Size'} | ${data.damageNumber ? data.damageNumber : 'No Dmg Number'}`}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              );
            })}
      </CondensedAccordion>

      <CondensedAccordion
        title={'Tires'}
        expanded={expandedTires}
        handleExpanded={handleChangeTires}
      >
        <Box>
          {vehicle.conditions.conditionOfTires &&
            vehicle.conditions.conditionOfTires.map((data: ConditionOfTiresType, index: number) => (
              <Box key={index} className={styles.tableRow}>
                <Typography component="p" sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                  {data.location}
                </Typography>
                <Typography className={styles.bidsText} component="p">
                  {` ${vehicle.equipment.sizeTires} | ${data.condition}`}
                </Typography>
              </Box>
            ))}
        </Box>
      </CondensedAccordion>
      <CondensedAccordion
        title={'Painted Panels'}
        expanded={expandedPanels}
        handleExpanded={handleChangePanels}
      >
        <Box>
          {vehicle.conditions.paintedPanels &&
            vehicle.conditions.paintedPanels.map((data: PaintedPanelsType, idx: number) => {
              return (
                <Box key={idx} className={styles.tableRow}>
                  <Typography component="p" sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                    {data.name}
                  </Typography>
                  <Typography
                    className={styles.panelText}
                    component="p"
                    sx={{
                      color:
                        data.condition === 'Requires Minor Attention/Work' ||
                        data.condition === 'Requires Major Repair/Redo'
                          ? theme.palette.error.main
                          : theme.palette.info.lighter
                    }}
                  >
                    {data.condition}
                  </Typography>
                </Box>
              );
            })}
        </Box>
      </CondensedAccordion>
      <CondensedAccordion
        title={`Seller's Notes`}
        expanded={expandedNotes}
        handleExpanded={handleChangeNotes}
      >
        {vehicle.conditions.sellersNotes && (
          <Chip
            label={vehicle.conditions.sellersNotes}
            sx={{
              border: `2px solid ${theme.palette.primary.main}`,
              backgroundColor: theme.palette.primary.lighter,
              color: theme.palette.primary.main,
              borderRadius: '5px',
              marginLeft: '5px'
            }}
          ></Chip>
        )}
      </CondensedAccordion>
    </>
  );
}
