import Icon from '@mui/material/Icon';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import CancelPresentationOutlinedIcon from '@mui/icons-material/CancelPresentationOutlined';
import { Theme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

const ICON_SIZE = { fontSize: 20 };

export default function TreeView(theme: Theme) {
  return {
    MuiTreeView: {
      defaultProps: {
        defaultCollapseIcon: <IndeterminateCheckBoxOutlinedIcon sx={{ ...ICON_SIZE }} />,
        defaultExpandIcon: <AddBoxOutlinedIcon sx={{ ...ICON_SIZE }} />,
        defaultEndIcon: (
          <Box component={Icon} sx={{ color: 'text.secondary' }}>
            <CancelPresentationOutlinedIcon sx={{ ...ICON_SIZE }} />
          </Box>
        )
      }
    },
    MuiTreeItem: {
      styleOverrides: {
        label: { ...theme.typography.body2 },
        iconContainer: { width: 'auto' }
      }
    }
  };
}
