import { Grid, BoxProps, IconButton, Popover, MenuItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ListItemDetails from '../../shared/ListItemDetails';
import ListItemBidPrice from '../../shared/ListItemPriceBid';
import ListItemPersonDate from '../../shared/ListItemPersonDate';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useEffect, useState } from 'react';
import RelaunchButton from './RelaunchButton';
import AuctionDrawer from 'components/auction/drawer/AuctionDrawer';
import SharedDrawer from '../../shared/SharedDrawer';
import HistoryDrawerContent from '../../shared/HistoryDrawerContent';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { VehicleInfo, VehiclePhotos } from 'types/vehicleInfo';
import moment from 'moment';
import { User } from 'constants/user';

interface Props extends BoxProps {
  vehicle: VehicleInfo;
  initialOpen: boolean;
  sendVehicleToParent: (index: number, vehicle: VehicleInfo) => void;
  curUser?: User;
}

const MENU_OPTIONS = [
  { label: 'Vehicle History', linkTo: '#' },
  { label: 'Bidding History', linkTo: '#' },
  { label: 'View VDP', linkTo: '#' }
];

export default function NosaleListItem({
  vehicle,
  initialOpen,
  sendVehicleToParent,
  curUser
}: Props) {
  const theme = useTheme();
  const [anchorPopup, setAnchorPopup] = useState<HTMLButtonElement | null>(null);
  const [popupOpened, setPopupOpened] = useState(false);
  const [, setAnchorEl] = useState(false);
  const [openVDPDrawer, setOpenVDPDrawer] = useState<boolean>(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openBidDrawer, setOpenBidDrawer] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  // const [vehiclePhoto, setVehiclePhoto] = useState<VehiclePhotos>();
  const [daysNextRun, setDaysNextRun] = useState<number>();

  const currencyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });

  let vehiclePhoto;
  if (vehicle.photos) {
    const photos = vehicle.photos.filter(
      (v: any) => v.url && v.location && v.location.toLowerCase().includes('3/4 shot')
    );
    if (photos[0]) {
      vehiclePhoto = photos[0];
    }
  }

  useEffect(() => {
    if (vehicle.auction.nextRunDate) {
      let days = moment(vehicle.auction.nextRunDate.toDate()).diff(moment(), 'days');
      setDaysNextRun(days);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const open = Boolean(anchorPopup);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorPopup(event.currentTarget);
    setPopupOpened(true);
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setSelectedIndex(index);
    sendVehicleToParent(index, vehicle);
    // if (index === 0) {
    //   setOpenDrawer(!openDrawer);
    // } else if (index === 1) {
    //   setOpenBidDrawer(!openBidDrawer);
    // } else {
    //   setOpenVDPDrawer(!openVDPDrawer);
    // }
  };

  const handleClose = () => {
    setAnchorPopup(null);
    setPopupOpened(false);
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const handleCloseBidDrawer = () => {
    setOpenBidDrawer(!openBidDrawer);
  };

  const useStyles = makeStyles({
    menuPopover: {
      boxShadow: '0 0 2px 0 rgba(139, 148, 156, 0.24),0 10px 20px -4px rgba(139, 148, 156, 0.24)',
      paddingTop: '20px',
      paddingBottom: '20px',
      '@global': {
        '.MuiMenuItem-root': {
          paddingBottom: '6px'
        }
      }
    }
  });

  const styles = useStyles();

  useEffect(() => {
    setAnchorEl(false);
  }, [popupOpened]);

  return (
    <Grid
      xs={12}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '10px',
        minHeight: '130px'
      }}
    >
      <Grid
        sx={{
          height: '100%',
          minHeight: '130px',
          padding: '6px !important',
          display: 'flex',
          borderTopLeftRadius: '10px',
          borderBottomLeftRadius: '10px',
          borderTop:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`,
          borderLeft:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`,
          borderBottom:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`
        }}
      >
        <ListItemDetails
          vehicle={vehicle}
          isSellerName={true}
          imageUrl={vehiclePhoto?.url}
          minWidth={'400px'}
          hasStatusPill={true}
        ></ListItemDetails>
      </Grid>
      {vehicle.sellerMarkAsSold && (
        <Grid
          item
          xs={3}
          sx={{
            height: '100%',
            minHeight: '130px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '6px 20px',
            minWidth: '155px',
            borderTop:
              theme.palette.mode === 'dark'
                ? `1px solid ${theme.palette.text.secondary}`
                : `1px solid ${theme.palette.divider}`,
            borderBottom:
              theme.palette.mode === 'dark'
                ? `1px solid ${theme.palette.text.secondary}`
                : `1px solid ${theme.palette.divider}`
          }}
        >
          <ListItemPersonDate label={'By Seller'} value={'Marked As Sold'}></ListItemPersonDate>
        </Grid>
      )}
      <Grid
        item
        xs={3}
        sx={{
          height: '100%',
          minHeight: '130px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '6px 20px',
          minWidth: '150px',
          borderTop:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`,
          borderBottom:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`
        }}
      >
        <ListItemBidPrice
          label={'previous high bid'}
          value={
            vehicle.auction.currentPrice
              ? currencyFormat.format(vehicle.auction.currentPrice)
              : 'No Bids'
          }
          color={theme.palette.primary.main}
        ></ListItemBidPrice>
      </Grid>
      <Grid
        item
        xs={3}
        sx={{
          height: '100%',
          minHeight: '130px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '6px 20px',
          minWidth: '150px',
          borderTop:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`,
          borderBottom:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`
        }}
      >
        <ListItemBidPrice
          label={'floor price'}
          value={currencyFormat.format(vehicle.auction.floorPrice)}
          color={theme.palette.text.secondary}
        ></ListItemBidPrice>
      </Grid>
      <Grid
        item
        xs={3}
        sx={{
          height: '100%',
          minHeight: '130px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '6px 20px',
          minWidth: '150px',
          borderTop:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`,
          borderBottom:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`
        }}
      >
        {vehicle.auction.timesRun >= 3 && daysNextRun && (
          <ListItemPersonDate
            label={'Days until next run'}
            value={daysNextRun > 0 ? String(daysNextRun) : '0'}
          ></ListItemPersonDate>
        )}
        {vehicle.auction.timesRun < 3 && (
          <ListItemPersonDate
            label={'Amount Run'}
            value={vehicle.auction.timesRun.toString()}
          ></ListItemPersonDate>
        )}
      </Grid>
      {vehicle.buyerReasonLost && (
        <Grid
          item
          xs={3}
          sx={{
            height: '100%',
            minHeight: '130px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '6px 20px',
            minWidth: '150px',
            borderTop:
              theme.palette.mode === 'dark'
                ? `1px solid ${theme.palette.text.secondary}`
                : `1px solid ${theme.palette.divider}`,
            borderBottom:
              theme.palette.mode === 'dark'
                ? `1px solid ${theme.palette.text.secondary}`
                : `1px solid ${theme.palette.divider}`
          }}
        >
          <ListItemPersonDate
            isBold={true}
            label={'No Sale Reason'}
            value={vehicle.buyerReasonLost}
          ></ListItemPersonDate>
        </Grid>
      )}
      <Grid
        item
        xs={3}
        sx={{
          height: '100%',
          minHeight: '130px',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          minWidth: '250px',
          padding: '6px',
          borderTopRightRadius: '10px',
          borderBottomRightRadius: '10px',
          borderTop:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`,
          borderRight:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`,
          borderBottom:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`
        }}
      >
        <RelaunchButton
          vehicleId={vehicle.id}
          curUser={curUser}
          isNoSale={true}
          vehicle={vehicle}
          daysUntilNextRun={daysNextRun}
        />
        <IconButton
          aria-describedby={id}
          onClick={handleClick}
          sx={{
            backgroundColor: theme.palette.background.paper,
            padding: '5px',
            borderRadius: '5px',
            marginLeft: '10px'
          }}
        >
          <MoreVertIcon />
        </IconButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorPopup}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          sx={{ width: 220 }}
          PaperProps={{ classes: { root: styles.menuPopover } }}
        >
          {MENU_OPTIONS.map((option, index) => (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={(event: any) => handleMenuItemClick(event, index)}
              sx={{ typography: 'body2', py: 1, px: 2.5 }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Popover>
      </Grid>
    </Grid>
  );
}
