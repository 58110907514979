import {
  Container,
  Typography,
  Grid,
  Paper,
  Box,
  MenuItem,
  Select,
  InputBase,
  SelectChangeEvent,
  TablePagination,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Alert,
  AlertTitle
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import { useTheme } from '@mui/material/styles';
import * as React from 'react';
import { useState, useEffect, useRef, useMemo } from 'react';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import { DebounceInput } from 'react-debounce-input';
import { fDateTimeSuffix } from 'components/shared/dateTimeFormat';
import { initialVechicleInfo, VehicleInfo } from 'types/vehicleInfo';
import { PATH_SELLER } from '../../../routes/paths';
import CaptureListItem from './components/CaptureListItem';
import { useNavigate } from 'react-router-dom';
import {
  collection,
  CollectionReference,
  getDoc,
  addDoc,
  query,
  where,
  setDoc,
  doc,
  DocumentReference,
  onSnapshot,
  limit,
  orderBy,
  Timestamp
} from 'firebase/firestore';
import { useFirestore } from 'reactfire';
import { Dealership } from 'types/dealership';
import useAuth from 'hooks/useAuth';
import { useFirestoreByPath } from 'hooks/useFirestoreByPath';
import EmptyStateMessage from 'components/shared/EmptyStateMessage';
import { isMobile } from 'react-device-detect';
import CloseIcon from '@mui/icons-material/Close';
import { User } from 'constants/user';
import { ViewportList } from 'react-viewport-list';
import { useVehicleBatch } from 'utils/vehicleQuery';
import SharedDrawer from 'components/shared/SharedDrawer';
import CaptureHistoryDrawerContent from 'components/seller/preAuction/CaptureHistoryDrawerContent';
import AuctionDrawer from 'components/auction/drawer/AuctionDrawer';

export default function VehicleCapturePage() {
  const { themeStretch } = useSettings();
  const { currentUser, user, logout } = useAuth();
  const firestore = useFirestore();
  const theme = useTheme();
  const [searchValue, setSearchValue] = useState('');
  const [, setAnchorEl] = useState(false);
  const [popupOpened] = useState(false);
  const [currentDealership, setCurrentDealership] = useState<Dealership>();
  const [currentVehicle, setCurrentVehicle] = useState<VehicleInfo>();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openVDPDrawer, setOpenVDPDrawer] = useState<boolean>(false);

  const handleCloseDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const SetDrawerVehicle = (index: number, vehicle: VehicleInfo) => {
    setCurrentVehicle(vehicle);
    if (vehicle.captureComplete) {
      if (index === 0) {
        setOpenDrawer(!openDrawer);
      } else if (index === 1) {
        setOpenVDPDrawer(!openVDPDrawer);
      }
    } else {
      if (index === 0) {
        setOpenDrawer(!openDrawer);
      }
    }
  };

  const viewRef = useRef(null);

  const [dialogOpen, setDialogOpen] = useState(false);
  const handleDialogChange = () => {
    setDialogOpen(!dialogOpen);
  };

  const [order, setOrder] = useState('newest');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [lastDocArray, setLastDocArray] = useState<any[]>([]);
  const [firstDocArray, setFirstDocArray] = useState<any[]>([]);
  const [backPage, setBackPage] = useState<boolean>(false);
  const [lastDataVehicleRef, setLastDataVehicleRef] = useState<DocumentReference>();

  const [collectionSize, setCollectionSize] = useState<number>(0);
  const countVehicleCollection = () => {
    const vehicleRef = collection(firestore, 'vehicles') as CollectionReference;
    const vehicleQuery = query(
      vehicleRef,
      where('dealershipId', '==', currentUser.currentDealershipId),
      where('auction.auctionStatus', 'in', ['draft', 'Draft'])
    );
    const unsubsribe = onSnapshot(vehicleQuery, (querySnapshot) => {
      setCollectionSize(querySnapshot.size);
    });
    return unsubsribe;
  };

  useEffect(() => {
    const unsubscribe = countVehicleCollection();
    return () => {
      unsubscribe();
    };
  }, []);

  const queryConstraints = [
    where('dealershipId', '==', currentUser.currentDealershipId),
    where('auction.auctionStatus', 'in', ['draft', 'Draft']),
    orderBy('auction.captureDate', 'desc'),
    limit(rowsPerPage)
  ];

  const { data } = useVehicleBatch(
    page,
    queryConstraints,
    lastDocArray[page - 1],
    backPage,
    firstDocArray[page]
  );

  const allVehicles = useMemo(() => {
    const lastDoc = data?.docs[data?.docs?.length - 1];
    if (lastDoc) {
      const lastDocRef = data?.docs[data?.docs?.length - 1].ref as DocumentReference;
      setLastDataVehicleRef(lastDocRef);
    }
    // prettier-ignore
    return data?.docs?.map((doc) => ({ ...doc.data(), id: doc.id }));
  }, [data]);

  const { remove: removeVehicle } = useFirestoreByPath<VehicleInfo>('vehicles');

  const getCurrentDealership = async () => {
    const userDealershipRef = doc(firestore, `dealerships/${currentUser.currentDealershipId}`);
    const userDealerSnap = await getDoc(userDealershipRef);
    if (userDealerSnap.exists()) {
      const data = userDealerSnap.data();
      setCurrentDealership({ ...data, id: userDealerSnap.id } as Dealership);
    }
  };

  const [userDeleted, setUserDeleted] = useState<boolean>(false);
  const [curUser, setCurUser] = useState<User>();
  const [userDisabled, setUserDisabled] = useState<boolean>(false);
  const [userSuspended, setUserSuspended] = useState<boolean>(false);
  const [buyerOnly, setBuyerOnly] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/dashboard');
  };

  const snapshotUser = () => {
    const userDoc = doc(firestore, `users/${user?.uid}`) as DocumentReference<User>;
    onSnapshot(userDoc, (doc: any) => {
      const userObj = { ...doc.data(), id: doc.id } as User;
      setCurUser(userObj);
      if (!userObj.email) {
        logout?.().then(() => {
          navigate('.');
          window.location.reload();
        });
      }
      if (userObj && userObj.status.toLowerCase() === 'suspended') {
        setUserSuspended(true);
      } else {
        setUserSuspended(false);
      }
      if (userObj && userObj.status.toLowerCase() === 'disabled') {
        setUserDisabled(true);
      } else {
        setUserDisabled(false);
      }
      if (userObj && userObj.status === 'buyerOnly') {
        setBuyerOnly(true);
      } else {
        setBuyerOnly(false);
      }
    });
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (!curUser) {
        snapshotUser();
      }
      if (!currentDealership) {
        getCurrentDealership();
      }
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addNewVehicle = async () => {
    if (isMobile) {
      handleDialogChange();
    } else {
      const newData = {
        ...initialVechicleInfo,
        dealershipId: currentUser.currentDealershipId,
        auction: {
          captureDate: Timestamp.now()
        }
      } as any;

      let ref = collection(firestore, 'vehicles');
      const newDocRef = await addDoc(ref, newData);
      const { id } = await getDoc(newDocRef).then((i) => ({ ...i.data(), id: i.id }));
      newData.id = id;
      newData.auction = {};
      newData.auction.auctionStatus = 'pre draft';

      const reff = doc(firestore, 'vehicles', id);
      await setDoc(reff, newData, { merge: true });
      navigate(`${PATH_SELLER.captureWorkflow}/${id}`, { state: { id: id } });
    }
  };

  const handleChangePage = async (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      const firstDoc = data?.docs[0];
      const firstDocRef = firstDoc.ref as DocumentReference;
      const docRef = doc(firestore, `vehicles/${firstDocRef.id}`);
      const docSnap = await getDoc(docRef);
      const tempFirstDocs = firstDocArray;
      if (docSnap.exists()) {
        tempFirstDocs.push(docSnap);
        setFirstDocArray(tempFirstDocs);
      }
      if (lastDataVehicleRef) {
        const docRef = doc(firestore, `vehicles/${lastDataVehicleRef.id}`);
        const docSnap = await getDoc(docRef);
        const tempLastDocs = lastDocArray;
        if (docSnap.exists()) {
          tempLastDocs.push(docSnap);
          setLastDocArray(tempLastDocs);
          setPage(newPage);
          setBackPage(false);
        }
      }
    } else {
      setPage(newPage);
      setBackPage(true);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setFirstDocArray([]);
    setLastDocArray([]);
    setBackPage(false);
  };

  const handleOrderByChange = (event: SelectChangeEvent) => {
    setOrder(event.target.value);
  };

  const handleDeleteVehicle = async (vehicle: VehicleInfo) => {
    await removeVehicle(vehicle.id);
  };

  const [vehicleEmptyState, setVehicleEmptyState] = useState<boolean>(false);

  useEffect(() => {
    if (!allVehicles || allVehicles.length < 1) {
      setVehicleEmptyState(true);
    } else {
      setVehicleEmptyState(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allVehicles]);

  useEffect(() => {
    setAnchorEl(false);
  }, [popupOpened]);

  const useStyles = makeStyles({
    pagination: {
      borderTopStyle: 'none',
      fontWeight: '700',
      borderTop: 'none'
    },
    inviteButton: {
      backgroundColor: theme.palette.primary.main
    },
    search: {
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.background.neutral : 'transparent !important',
      border: theme.palette.mode === 'dark' ? 'unset' : `1px solid ${theme.palette.divider}`,
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: '100%'
    },
    searchInput: {
      fontSize: '14px',
      width: '100%',
      height: '34px',
      margin: '0 10px',
      border: 'none',
      padding: '5px 10px',
      outline: 'none !important',
      marginLeft: 2,
      flex: 1,
      backgroundColor: 'transparent !important',
      color:
        theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.text.secondary,
      '&::placeholder': {
        color:
          theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.text.secondary,
        fontSize: '14px'
      }
    },
    searchIcon: {
      fill: theme.palette.primary.main,
      marginRight: '5px'
    },
    secondaryButton: {
      backgroundColor: theme.palette.grey[50],
      color: theme.palette.info.light
    },
    subtitle: {
      fontWeight: 'bold',
      color: theme.palette.secondary.darker
    },
    subtitle2: {
      color: theme.palette.info.light,
      fontWeight: 'normal'
    },
    list: {
      '& li:first-child, & li:last-child': {
        '& button': {
          border: `1px solid ${theme.palette.grey[100]}`,
          color: theme.palette.info.light,
          width: '100px'
        }
      },
      '& li': {
        paddingLeft: 3,
        paddingRight: 3,
        '& button': {
          color: theme.palette.info.main,
          minWidth: '40px'
        }
      }
    },
    forwardIcon: {
      fontSize: '12px !important'
    },
    backwardIcon: {
      fontSize: '12px !important'
    },
    divider: {
      width: '93%',
      [theme.breakpoints.down(1524)]: {
        width: '90%'
      },
      [theme.breakpoints.down(863)]: {
        width: '87%'
      }
    },
    gridAvatarTitle: {
      [theme.breakpoints.down(1486)]: {
        flexBasis: '40%',
        maxWidth: '40%'
      },
      [theme.breakpoints.down(1299)]: {
        flexBasis: '45%',
        maxWidth: '45%'
      }
    },
    gridDateTitle: {
      marginRight: '64px',
      [theme.breakpoints.down(1330)]: {
        marginRight: '24px'
      }
    },
    menuPopover: {
      boxShadow: '0 0 2px 0 rgba(139, 148, 156, 0.24),0 10px 20px -4px rgba(139, 148, 156, 0.24)',
      // backgroundColor: theme.palette.primary.contrastText,
      paddingTop: '20px',
      paddingBottom: '20px',
      '@global': {
        '.MuiMenuItem-root': {
          paddingBottom: '6px'
        },
        '.MuiMenuItem-root:last-child': {
          color: theme.palette.error.dark
        }
      }
    },
    dataGrid: {
      '@global': {
        '.MuiDataGrid-columnHeaders': {
          visibility: 'hidden'
        },
        '.MuiDataGrid-virtualScroller': {
          marginTop: '0 !important'
        },
        '.flexEndCell': {
          justifyContent: 'flex-end',
          padding: '0'
        },
        '.flexEndCellPopup': {
          justifyContent: 'flex-end',
          padding: '0',
          '& .MuiTouchRipple-root': {
            display: 'none'
          }
        },
        '.MuiDataGrid-row': {
          minHeight: '88px !important',
          maxHeight: '88px !important',
          '&:hover': {
            backgroundColor: 'transparent !important'
          }
        },
        '.MuiDataGrid-cell': {
          minHeight: '88px !important',
          maxHeight: '88px !important',
          borderBottom: '0'
        },
        '.imageCell': {
          padding: '0'
        }
      }
    },
    headerTextContainer: {
      width: '100%',
      height: 'auto',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '0px'
    },
    controlsContainer: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'flex-end'
    },
    headerText: {
      fontSize: '14px',
      marginRight: '18px',
      marginLeft: '5px'
    },
    inputText: {
      fontSize: '14px'
    },
    dialogContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      width: 450,
      position: 'relative'
    }
  });

  const styles = useStyles();

  return (
    <>
      {!userDeleted && !userSuspended && !userDisabled && !buyerOnly ? (
        <Page title="Vehicle Capture | DealerGavel">
          <Container maxWidth={themeStretch ? false : 'xl'}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ mb: 0 }}
            >
              <Typography
                variant="h4"
                component="h1"
                paragraph
                sx={{
                  fontSize: '1rem !important',
                  color:
                    theme.palette.mode === 'dark'
                      ? theme.palette.common.white
                      : theme.palette.text.primary
                }}
              >
                Vehicle Capture
              </Typography>
              <Button
                className={styles.inviteButton}
                variant="contained"
                sx={{ p: '7px 36px 7px 36px', marginTop: '-24px' }}
                onClick={() => {
                  addNewVehicle();
                }}
              >
                Add New Vehicle
              </Button>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ mb: 0 }}
            >
              <Paper component="form" className={styles.search}>
                <DebounceInput
                  className={styles.searchInput}
                  placeholder="Search Vehicles by Make, Model, Year, etc."
                  minLength={2}
                  debounceTimeout={300}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                <SearchIcon className={styles.searchIcon} sx={{ width: '20px' }} />
              </Paper>
            </Grid>
            <Box sx={{ flexGrow: 1 }}>
              <Box className={styles.headerTextContainer}>
                {allVehicles && (
                  <Typography
                    component="p"
                    className={styles.headerText}
                    sx={{
                      textTransform: 'uppercase',
                      fontWeight: '700',
                      color: theme.palette.info.light
                    }}
                  >
                    {collectionSize} total vehicles found
                  </Typography>
                )}
                <Box className={styles.controlsContainer}>
                  <Typography component="p" className={styles.headerText}>
                    Sort By:
                  </Typography>
                  <Select
                    label="Sort By"
                    value={order}
                    onChange={handleOrderByChange}
                    className={styles.inputText}
                    inputProps={{ 'aria-label': 'order vehicles' }}
                    input={<InputBase />}
                  >
                    <MenuItem value={'oldest'}>Oldest</MenuItem>
                    <MenuItem value={'newest'}>Newest</MenuItem>
                    <MenuItem value={'vehicleName'}>Name</MenuItem>
                  </Select>
                  {allVehicles && (
                    <TablePagination
                      className={styles.pagination}
                      count={collectionSize}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      rowsPerPageOptions={[5, 10, 25]}
                    />
                  )}
                </Box>
              </Box>
              <Grid
                sx={{
                  overflowX: 'auto',
                  height: '600px',
                  overflowY: 'auto'
                }}
                ref={viewRef}
              >
                {allVehicles && (
                  <ViewportList
                    viewportRef={viewRef}
                    initialPrerender={8}
                    items={allVehicles
                      .sort((a: VehicleInfo, b: VehicleInfo) => {
                        if (order === 'oldest') {
                          return a.auction.captureDate > b.auction.captureDate ? 1 : -1;
                        } else if (order === 'newest') {
                          return a.auction.captureDate > b.auction.captureDate ? -1 : 1;
                        } else {
                          return a.details.make > b.details.make ? 1 : -1;
                        }
                      })
                      .filter(
                        (v: VehicleInfo) =>
                          /* prettier-ignore */
                          (v.details.make ? v.details.make.toLowerCase().includes(searchValue.toLowerCase()) : true) ||
                          v.details.model && v.details.model.toLowerCase().includes(searchValue.toLowerCase()) ||
                          v.auction.captureDate && fDateTimeSuffix(v.auction.captureDate.toDate())
                            .toString()
                            .toLowerCase()
                            .includes(searchValue.toLowerCase()) ||
                          v.auction.floorPrice && v.auction.floorPrice
                            .toString()
                            .toLowerCase()
                            .includes(searchValue.toLowerCase()) ||
                          v.auction.captureRepName && v.auction.captureRepName.toLowerCase().includes(searchValue.toLowerCase()) ||
                          v.details.year && v.details.year?.toString().toLowerCase().includes(searchValue.toLowerCase()) ||
                          v.details.trim && v.details.trim.toLowerCase().includes(searchValue.toLowerCase())
                      )}
                  >
                    {(vehicle: VehicleInfo) => (
                      <Box sx={{ mt: '0px', mb: '0px', width: '100%' }} key={vehicle.id}>
                        <CaptureListItem
                          vehicle={vehicle}
                          sendVehicleToParent={SetDrawerVehicle}
                          deleteVehicle={(vehicle) => handleDeleteVehicle(vehicle)}
                        />
                      </Box>
                    )}
                  </ViewportList>
                )}
                {vehicleEmptyState && (
                  <EmptyStateMessage
                    boldMessage="You have not captured any vehicles."
                    message="Add a new vehicle or download the vehicle capture app to start capturing vehicles."
                  />
                )}
              </Grid>
            </Box>
          </Container>
          <Dialog open={dialogOpen} onClose={handleDialogChange}>
            <Box>
              <DialogTitle>Not Available</DialogTitle>
              <CloseIcon
                onClick={handleDialogChange}
                sx={{
                  cursor: 'pointer',
                  position: 'absolute',
                  right: '15px',
                  top: '24px'
                }}
              />
            </Box>
            <DialogContent>
              You're on a mobile device, please use the mobile application to capture your vehicle.
            </DialogContent>
          </Dialog>
          {currentVehicle && (
            <SharedDrawer
              isOpenSidebar={openDrawer}
              onCloseSidebar={handleCloseDrawer}
              drawerContent={
                <CaptureHistoryDrawerContent onClose={handleCloseDrawer} vehicle={currentVehicle} />
              }
            />
          )}
          {currentVehicle && (
            <AuctionDrawer
              vehicle={currentVehicle}
              tabIndex={0}
              isOpenSidebar={openVDPDrawer}
              onCloseSidebar={() => setOpenVDPDrawer(!openVDPDrawer)}
            />
          )}
        </Page>
      ) : (
        <>
          {userDeleted && (
            <Alert severity="error">
              <AlertTitle>Permission Denied</AlertTitle>
              Your account has been disabled.
            </Alert>
          )}
          {userSuspended && (
            <>
              <Alert severity="error">
                <AlertTitle>Thank you for joining DealerGavel</AlertTitle>
                Your account information is being reviewed by DealerGavel team, and will be
                &nbsp;authorized shortly. For now, you can return to the dashboard and await
                &nbsp;authorization.
              </Alert>
              <Box sx={{ mt: '20px' }}>
                <Button onClick={handleNavigate} variant="contained">
                  Back to Dashboard
                </Button>
              </Box>
            </>
          )}
          {userDisabled && (
            <>
              <Alert severity="error">
                <AlertTitle>Permission Denied</AlertTitle>
                Your account has been disabled.
              </Alert>
              <Box sx={{ mt: '20px' }}>
                <Button onClick={handleNavigate} variant="contained">
                  Back to Dashboard
                </Button>
              </Box>
            </>
          )}
          {buyerOnly && (
            <>
              <Alert severity="error">
                <AlertTitle>Permission Denied</AlertTitle>
                You do not have permissions for this page.
              </Alert>
              <Box sx={{ mt: '20px' }}>
                <Button onClick={handleNavigate} variant="contained">
                  Back to Dashboard
                </Button>
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
}
