import {
  Avatar,
  Box,
  Divider,
  Dialog,
  MenuItem,
  Typography,
  DialogContent,
  DialogActions,
  Button
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CloseIcon from '@mui/icons-material/Close';
import Page from 'components/Page';
import { VehicleCondition } from './components/VehicleCondition';
import { useEffect, useState } from 'react';
import { VehicleVIN } from './components/VehicleVIN';
import { VehicleDetails } from './components/VehicleDetails';
import { VehicleInfo, VehiclePhotos } from 'types/vehicleInfo';
import { VehiclePhotosM } from './components/VehiclePhotosM';
import { VehicleEquipment } from './components/VehicleEquipment';
import { VehicleDeclaration } from './components/VehicleDeclaration';
import { VehicleAuction } from './components/VehicleAuction';
import useAuth from 'hooks/useAuth';
import { LoadingButton } from '@mui/lab';
import {
  arrayUnion,
  doc,
  DocumentReference,
  getDoc,
  onSnapshot,
  setDoc,
  Timestamp,
  updateDoc
} from 'firebase/firestore';
import { useFirestore } from 'reactfire';
import { Dealership } from 'types/dealership';
import { useLocation, useNavigate } from 'react-router-dom';
import { PATH_SELLER } from 'routes/paths';
import { useTheme } from '@mui/material/styles';
import { User } from 'constants/user';

const status = ['Not Touched', 'In Progress', 'Completed'];

type Status = typeof status[number];
type TypeText = { caption: string; filled: Status };

type TypeMenu = {
  menuText: string;
  completed: boolean;
  textArray?: TypeText[];
};

const arrayOfMenu: TypeMenu[] = [
  { menuText: 'VIN', completed: false },
  { menuText: 'Vehicle Details', completed: false },
  {
    menuText: 'Photos',
    completed: false,
    textArray: [
      { caption: 'Interior', filled: 'Completed' },
      { caption: 'Exterior', filled: 'In Progress' },
      { caption: 'Damages', filled: 'Completed' },
      { caption: 'Painted Panels', filled: 'Completed' }
    ]
  },
  {
    menuText: 'Declarations & Disclosures',
    completed: false,
    textArray: [
      { caption: 'Vehicle History Report', filled: 'Completed' },
      { caption: 'Announcements', filled: 'In Progress' },
      { caption: 'Inspection Summary', filled: 'Completed' }
    ]
  },
  {
    menuText: 'Condition',
    completed: false,
    textArray: [
      { caption: 'Overall Condition', filled: 'Completed' },
      // { caption: 'Painted Panels', filled: 'Completed' },
      { caption: 'Condition of Tires', filled: 'Completed' },
      { caption: 'Seller Notes', filled: 'Completed' }
    ]
  },
  {
    menuText: 'Equipment',
    completed: false,
    textArray: [
      { caption: 'Notables', filled: 'Not Touched' },
      { caption: 'Options', filled: 'Not Touched' },
      { caption: 'Aftermarket Options', filled: 'Not Touched' },
      { caption: 'Tire Info and Size', filled: 'Not Touched' },
      { caption: 'Factory Information', filled: 'Not Touched' }
    ]
  },
  {
    menuText: 'Auction Details',
    completed: false
  }
];

type MenuProps = {
  index: number;
  text: string;
  status: boolean;
  textArray?: TypeText[];
  selectedItem: number;
  showLines: (index: number) => void;
  clickLines: (index: number) => void;
};

function MyMenuItem({
  index,
  text,
  status,
  textArray,
  showLines,
  selectedItem,
  clickLines
}: MenuProps) {
  const theme = useTheme();
  const [myStatus, setMyStatus] = useState(status ? 2 : 3);

  useEffect(() => {
    if (status) {
      setMyStatus(2);
    } else {
      setMyStatus(3);
    }
  }, [status]);

  return (
    <>
      <MenuItem
        style={{
          display: 'flex',
          flexDirection: 'row',
          textAlign: 'left',
          color: theme.palette.text.primary
        }}
        onClick={() => {
          clickLines(index);
        }}
      >
        {/* ReportProblemIcon */}
        {myStatus === 1 && <CheckCircleIcon sx={{ color: 'red' }} />}
        {myStatus === 2 && <CheckCircleIcon color="primary" sx={{ pd: 2 }} />}
        {myStatus === 3 && <CircleOutlinedIcon color="primary" />}

        <Typography
          variant="subtitle2"
          sx={{
            ml: 2,
            p: -1,
            display: 'inline-block',
            color: selectedItem === index ? theme.palette.primary.main : theme.palette.text.primary
          }}
        >
          {text}
        </Typography>
      </MenuItem>
      {index === selectedItem &&
        textArray &&
        textArray.map((text, index) => (
          <Box key={index} sx={{ ml: 3 }}>
            {/* <ReportProblemIcon
              sx={{
                color: 'red',
                fontSize: '16px',
                visibility: text.filled === 'In Progress' ? 'visible' : 'hidden'
              }}
            /> */}
            <Typography
              variant="subtitle2"
              sx={{
                ml: 2,
                // color: text.filled === 'Completed' ? ' grey' : 'black',
                color: theme.palette.text.secondary,
                display: 'inline-block',
                fontSize: '12px'
              }}
            >
              {text.caption}
            </Typography>
          </Box>
        ))}
    </>
  );
}

export default function CaptureContentPage() {
  const { currentUser, user } = useAuth();
  const firestore = useFirestore();
  const locationState = useLocation().state as { id: string };
  const vehicleId = locationState.id;
  const navigate = useNavigate();
  const theme = useTheme();

  const [visibleText, setVisibleText] = useState(0);
  const [vehicleInfo, setVehicleInfo] = useState<VehicleInfo>({} as VehicleInfo);
  const [completedDialog, setCompletedDialog] = useState(false);
  const [currentDealership, setCurrentDealership] = useState<Dealership>();
  const [currentAuctionStatus, setCurrentAuctionStatus] = useState<string>();
  const [curUser, setCurUser] = useState<User>();

  const snapshotUser = () => {
    const userDoc = doc(firestore, `users/${user?.uid}`) as DocumentReference<User>;
    onSnapshot(userDoc, (doc: any) => {
      const userObj = { ...doc.data(), id: doc.id } as User;
      setCurUser(userObj);
    });
  };

  const getCurrentVehicle = async () => {
    if (vehicleId) {
      const docRef = doc(firestore, `vehicles/${vehicleId}`);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const currentVehicle = { ...docSnap.data(), id: docSnap.id } as VehicleInfo;
        // currentVehicle.auction.isInStock = true;
        currentVehicle.auction.dateExpected = Timestamp.now();
        setVehicleInfo(currentVehicle);
        validateVehicle(currentVehicle);
      }
    }
  };

  const validateRequiredPropertiesInObject = (object: any, properties: string[]) => {
    let isValid = true;
    properties.forEach((property) => {
      // prettier-ignore
      if (object[property] === null || object[property] === '' || object[property] === ' ' || !object[property]) {
        isValid = false;
        return;
      }
    });
    return isValid;
  };

  const validateVehicle = async (vehicle: VehicleInfo) => {
    let captureProgress = 0;
    let vinValid = false;
    let detailsValid = false;
    let photoValid = false;
    let disclosuresValid = true;
    let conditionValid = false;
    let equipmentValid = false;
    // vin
    if (vehicle.details) {
      vinValid = validateRequiredPropertiesInObject(vehicle.details, ['vin']);
      if (vinValid) {
        captureProgress = 1;
      }
    }
    // details
    if (vehicle.details) {
      detailsValid = validateRequiredPropertiesInObject(vehicle.details, [
        'year',
        'make',
        'model',
        'bodyStyle',
        'trim',
        'transmission',
        'driveTrain',
        'displacement',
        'cylinders',
        'fuelType',
        'doors',
        'passengers',
        'extColor',
        'intColor',
        'mileage',
        'mileageType'
      ]);
      if (vinValid && detailsValid) {
        captureProgress = 2;
      }
    }
    // photos
    if (vehicle.photos) {
      const requiredLocations = [
        '3/4 Shot',
        'Driver Side',
        'Back',
        'Passenger Side',
        'Front',
        'Tire',
        'Drivers Seat Cross',
        'Odometer with Mileage',
        'From Back Seat: Centre Console',
        'From Back Seat: Roof with Mirror'
      ];
      const photoLocations = vehicle.photos.map((p) => p.location);
      // prettier-ignore
      const compareArrays = (arr: string | any[], target: any[]) => target.every((v) => arr.includes(v));
      photoValid = compareArrays(photoLocations, requiredLocations);
      const photoUrls = vehicle.photos.map((p) => p.url);
      photoUrls.forEach((url: string) => {
        if (!url) {
          photoValid = false;
        }
      });
      console.log('photo valid =>', photoValid);
      if (vinValid && detailsValid && photoValid) {
        captureProgress = 3;
      }
    }
    // disclosures
    if (vehicle.disclosures) {
      const requiredDisclosures = [];
      if (vehicle.disclosures.outOfProvince) {
        disclosuresValid = false;
        requiredDisclosures.push('province');
      }
      if (vehicle.disclosures.usVehicle) {
        disclosuresValid = false;
        requiredDisclosures.push('state');
      }
      if (vehicle.disclosures.odometerStatus.toLowerCase() !== 'none') {
        requiredDisclosures.push('odometerRadioChoice');
        disclosuresValid = false;
        if (vehicle.disclosures.odometerRadioChoice === 'Option 1') {
          disclosuresValid = false;
          requiredDisclosures.push('odometerReading');
          requiredDisclosures.push('odometerReadingDate');
        }
      }
      disclosuresValid = validateRequiredPropertiesInObject(
        vehicle.disclosures,
        requiredDisclosures
      );
      if (vinValid && detailsValid && photoValid && disclosuresValid) {
        captureProgress = 4;
      }
    }
    // conditions
    if (vehicle.conditions) {
      conditionValid = validateRequiredPropertiesInObject(vehicle.conditions, ['conditionOfTires']);
      if (vinValid && detailsValid && photoValid && disclosuresValid && conditionValid) {
        captureProgress = 5;
      }
    }
    // equipment
    if (vehicle.equipment) {
      equipmentValid = validateRequiredPropertiesInObject(vehicle.equipment, [
        'clothLeather',
        'roof',
        'sizeTires'
      ]);
      console.log('equip valid =>', equipmentValid);
      if (
        vinValid &&
        detailsValid &&
        photoValid &&
        disclosuresValid &&
        conditionValid &&
        equipmentValid
      ) {
        captureProgress = 6;
      }
    }
    // handleNextBack(vehicle, captureComplete);
    const ref = doc(firestore, 'vehicles', vehicle.id);
    await updateDoc(ref, { captureProgressFlag: captureProgress });
    const tempV = { ...vehicle, captureProgressFlag: captureProgress } as VehicleInfo;
    setVehicleInfo(tempV);

    if (captureProgress >= 0) {
      arrayOfMenu[visibleText].completed = true;
      showLines(captureProgress);
    }
  };

  const getCurrentDealership = async () => {
    const userDealershipRef = doc(firestore, `dealerships/${currentUser.currentDealershipId}`);
    const userDealerSnap = await getDoc(userDealershipRef);
    if (userDealerSnap.exists()) {
      const data = userDealerSnap.data();
      setCurrentDealership({ ...data, id: userDealerSnap.id } as Dealership);
    }
  };

  const showLines = (index: number) => {
    setVisibleText(index);
  };

  const clickLines = (index: number) => {
    if (vehicleInfo && index <= (vehicleInfo.captureProgressFlag as number)) {
      setVisibleText(index);
    }
  };

  const handleNextBack = async (v: VehicleInfo, flag: number) => {
    const tempV = { ...v, captureProgressFlag: flag } as VehicleInfo;
    setVehicleInfo(tempV);
    let tmpId = vehicleId as string;
    const ref = doc(firestore, 'vehicles', tmpId);

    let vehicleToUpdate;
    if (v.auction.auctionStatus.toLowerCase() === 'pre draft' && flag !== 0) {
      vehicleToUpdate = {
        ...v,
        captureProgressFlag: flag,
        createdDate: Timestamp.now(),
        auction: {
          ...v.auction,
          auctionStatus: 'draft',
          previousBids: null,
          captureRepName: `${currentUser.firstName} ${currentUser.lastName}`,
          captureDate: Timestamp.now(),
          // sellerName: currentDealership?.legalName,
          captureHistory: arrayUnion({
            captureRepName: `${currentUser.firstName} ${currentUser.lastName}`,
            date: Timestamp.now()
          })
        }
      };
    } else {
      vehicleToUpdate = {
        ...v,
        captureProgressFlag: flag,
        auction: {
          ...v.auction,
          captureHistory: arrayUnion({
            captureRepName: `${currentUser.firstName} ${currentUser.lastName}`,
            date: Timestamp.now()
          })
        }
      };
    }

    await setDoc(ref, vehicleToUpdate, { merge: true });

    if (flag >= 0) {
      arrayOfMenu[visibleText].completed = true;
      showLines(flag);
    }
    // if (flag === -2) {
    //   setCompletedDialog(true);
    // }
    if (flag === -1) {
      navigate(`${PATH_SELLER.vehicleCapture}`);
    }
  };

  const handleNextNoVin = (flag: number) => {
    if (flag >= 0) {
      arrayOfMenu[visibleText].completed = true;
      showLines(flag);
    }
    // if (flag === -2) {
    //   setCompletedDialog(true);
    // }
    if (flag === -1) {
      navigate(`${PATH_SELLER.vehicleCapture}`);
    }
  };

  const handleOpenConfrimModal = () => {
    setCompletedDialog(true);
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (vehicleId) {
        getCurrentVehicle();
      }
      if (currentUser) {
        getCurrentDealership();
      }
      snapshotUser();
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page title="Capture Workflow | DealerGavel">
      <Box sx={{ width: '100%' }}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '20rem 1fr',
            // gridGap: '4em',
            // height: '100vh'
            height: '100%'
          }}
        >
          <div
            style={{
              borderRight: '1px solid rgba(145, 158, 171, 0.24)',
              height: '100vh',
              paddingLeft: '16px',
              position: 'sticky',
              alignSelf: 'start',
              // top: '1rem',
              marginTop: '-23px'
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: '25px' }}>
              <Avatar
                sx={{ width: '30px', height: '30px' }}
                alt="My Avatar"
                src={
                  currentUser.avatarUrl
                    ? currentUser.avatarUrl
                    : '/static/mock-images/avatars/avatar_default.jpg'
                }
              />

              <Typography variant="subtitle1" sx={{ mt: 0.5, ml: 1.5 }}>
                {`${currentUser.firstName} ${currentUser.lastName}`}
              </Typography>
            </Box>
            <Divider
              sx={{
                ml: '-40px',
                mt: '23px',
                borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
              }}
            />
            <Typography variant="subtitle1" sx={{ ml: 1, mt: 2, fontWeight: '700' }}>
              OVERVIEW
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'left' }}>
              {arrayOfMenu.map((item, index) => (
                <MyMenuItem
                  key={index}
                  index={index}
                  status={
                    vehicleInfo
                      ? (vehicleInfo.captureProgressFlag as number) >= index
                      : item.completed || false
                  }
                  text={item.menuText}
                  showLines={showLines}
                  clickLines={clickLines}
                  selectedItem={visibleText}
                  textArray={item.textArray}
                />
              ))}
            </Box>
          </div>

          <div
            style={{
              // height: '100vh',
              paddingLeft: '40px',
              marginLeft: '40px !important'
            }}
          >
            {visibleText === 0 && (
              <VehicleVIN
                vehicle={vehicleInfo}
                updVehicle={(v, flag) => handleNextBack(v, flag)}
                goToNext={(flag) => handleNextNoVin(flag)}
              />
            )}
            {visibleText === 1 && (
              <VehicleDetails
                vehicleId={vehicleInfo.id}
                dataFound={vehicleInfo.dataFound ? vehicleInfo.dataFound : false}
                vehicleDetails={vehicleInfo.details}
                updVehicleDet={(details, flag) => {
                  const tmp = { ...vehicleInfo };
                  tmp.details = details;
                  handleNextBack(tmp, flag);
                }}
              />
            )}
            {visibleText === 2 && (
              <VehiclePhotosM
                vehicle={vehicleInfo}
                conditions={vehicleInfo.conditions}
                updVehicle={(v, flag) => handleNextBack(v, flag)}
              />
            )}
            {visibleText === 3 && (
              <VehicleDeclaration
                vehicle={vehicleInfo}
                disclosures={vehicleInfo.disclosures}
                updVehicleDis={(disclosures, flag) => {
                  const tmp = { ...vehicleInfo };
                  tmp.disclosures = disclosures;
                  handleNextBack(tmp, flag);
                }}
              />
            )}
            {visibleText === 4 && (
              <VehicleCondition
                conditions={vehicleInfo.conditions}
                updVehicleCon={(conditions, flag) => {
                  const tmp = { ...vehicleInfo };
                  tmp.conditions = conditions;
                  handleNextBack(tmp, flag);
                }}
              />
            )}
            {visibleText === 5 && (
              <VehicleEquipment
                equipment={vehicleInfo.equipment}
                updVehicleEq={(equipment, flag) => {
                  const tmp = { ...vehicleInfo };
                  tmp.equipment = equipment;
                  handleNextBack(tmp, flag);
                }}
              />
            )}
            {visibleText === 6 && (
              <VehicleAuction
                dealership={currentDealership as Dealership}
                curUser={curUser}
                auction={vehicleInfo.auction}
                openConfirmModal={handleOpenConfrimModal}
                updVehicleAuc={(auction, flag) => {
                  const tmp = { ...vehicleInfo };
                  tmp.auction = auction;
                  handleNextBack(tmp, flag);
                  setCurrentAuctionStatus(auction.auctionStatus);
                }}
              />
            )}
          </div>
        </div>
      </Box>
      <Dialog open={completedDialog} onClose={() => setCompletedDialog(false)}>
        <DialogContent>
          <Box
            sx={{
              width: 400,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-evenly'
            }}
          >
            <Box
              sx={{
                width: '100%',
                mb: 2,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              <div></div>
              <Typography variant="h6"> Complete Capture </Typography>
              <CloseIcon
                sx={{ fontSize: '18px', cursor: 'pointer' }}
                onClick={() => setCompletedDialog(false)}
              />
            </Box>
            <Divider
              sx={{
                width: '100%',
                borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
              }}
            />
            <Box
              sx={{
                width: '80%',
                mt: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center'
              }}
            >
              <Typography variant="h3"> Are you finished? </Typography>
              <Typography sx={{ fontSize: '14px', mt: 1 }}>
                Completing this capture will move the vehicle to {currentAuctionStatus} status.
              </Typography>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
        >
          <Button
            variant="contained"
            sx={{
              background: 'lightGrey',
              color: 'black',
              width: '40%',
              boxShadow: 'none',
              border: '1px solid lightgrey',
              '&:hover': {
                background: 'grey',
                color: 'white'
              }
            }}
            autoFocus
            onClick={() => setCompletedDialog(false)}
          >
            Cancel
          </Button>
          <LoadingButton
            type="button"
            variant="contained"
            onClick={async () => {
              const v = { ...vehicleInfo };
              let vehicle;
              if (!v.auction.launchTime && !v.auction.launchDate) {
                vehicle = {
                  ...vehicleInfo,
                  captureComplete: true,
                  auction: {
                    ...vehicleInfo.auction,
                    launchTime: Timestamp.now(),
                    launchDate: Timestamp.now()
                  }
                };
                const ref = doc(firestore, 'vehicles', v.id);
                await setDoc(
                  ref,
                  {
                    captureComplete: true,
                    auction: {
                      launchTime: Timestamp.now(),
                      launchDate: Timestamp.now()
                      // vehicleLaunchUser: {
                      //   name: currentUser.firstName + ' ' + currentUser.lastName,
                      //   email: currentUser.email,
                      //   phone: currentUser.phone
                      // }
                    }
                  },
                  { merge: true }
                );
              } else {
                vehicle = { ...vehicleInfo, captureComplete: true };
                const ref = doc(firestore, 'vehicles', v.id);
                await setDoc(
                  ref,
                  {
                    captureComplete: true
                    // auction: {
                    //   vehicleLaunchUser: {
                    //     name: currentUser.firstName + ' ' + currentUser.lastName,
                    //     email: currentUser.email,
                    //     phone: currentUser.phone
                    //   }
                    // }
                  },
                  { merge: true }
                );
              }
              setVehicleInfo(vehicle);
              setCompletedDialog(false);
              navigate(`${PATH_SELLER.vehicleCapture}`);
            }}
          >
            Yes, Complete Capture
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Page>
  );
}
