import {
  Box,
  Chip,
  IconButton,
  List,
  ListItem,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { VehicleInfo } from 'types/vehicleInfo';
import useAuth from 'hooks/useAuth';
import { FC, useEffect, useState } from 'react';
import moment from 'moment';
import React from 'react';
interface Props {
  vehicle: VehicleInfo;
  width: string;
  mediaQuery: number;
  isPreAuction: boolean;
  isExpanded: boolean;
  handleOpenDrawer: (value: number, v: VehicleInfo) => void;
}

const VehicleDetails: FC<Props> = React.memo(function VehicleDetails({
  vehicle,
  width,
  handleOpenDrawer,
  mediaQuery,
  isPreAuction,
  isExpanded
}) {
  const theme = useTheme();
  const { user } = useAuth();
  const [totalVHRDamages, setTotalVHRDamages] = useState<number>();
  const [hasVHRDescription, setHasVHRDescription] = useState<boolean>();
  const [drawerTab, setDrawerTab] = useState<number>(0);
  const [openDrawer, setOpenDrawer] = useState(false);

  const currencyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });

  useEffect(() => {
    let tempDamages = 0;
    if (vehicle.disclosures.vhrDamages) {
      vehicle.disclosures.vhrDamages.forEach((damage: any) => {
        tempDamages = parseInt(tempDamages + damage.cost);
        if (damage.description && damage.description !== '' && damage.description !== ' ') {
          setHasVHRDescription(true);
        }
      });
    }
    setTotalVHRDamages(tempDamages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleToggleDrawer = (value: number, v: VehicleInfo) => {
    setDrawerTab(value);
    // setVehicle(v);
    setOpenDrawer(!openDrawer);
    handleOpenDrawer(value, v);
  };

  const useStyles = makeStyles({
    container: {
      display: 'flex',
      flexDirection: 'row-reverse',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor:
        theme.palette.mode === 'dark' ? theme.palette.text.secondary : theme.palette.divider
    },
    buttonHeaderContainer: {
      width: '40px',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      borderWidth: '1px',
      borderLeftStyle: 'solid',
      borderColor:
        theme.palette.mode === 'dark' ? theme.palette.text.secondary : theme.palette.divider
    },
    iconButton: {
      width: '20px',
      height: '20px',
      color: theme.palette.primary.dark
    },
    contentContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      padding: '10px 10px 0px 10px',
      marginTop: '0px'
    },
    contentText: {
      marginLeft: '3px',
      fontWeight: '700'
    },
    seeAllText: {
      fontWeight: '700',
      fontSize: '13px',
      color: theme.palette.primary.main
    },
    carfaxContainer: {
      margin: '5px',
      padding: '15px 10px',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor:
        theme.palette.mode === 'dark' ? theme.palette.text.secondary : theme.palette.divider,
      borderRadius: '5px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    contentListContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      width: '100%',
      maxHeight: width === '33.3%' ? '85px' : '64px',
      height: '100%',
      overflowY: 'scroll',
      '&::-webkit-scrollbar': {
        '-webkit-appearance': 'none'
      },
      '&::-webkit-scrollbar:vertical': {
        width: '9px'
      },
      '&::-webkit-scrollbar:horizontal': {
        height: '9px'
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '8px',
        backgroundColor: 'rgba(0, 0, 0, .5)',
        border: '1px solid white'
      }
    },
    contentListItem: {
      fontSize: '14px',
      lineHeight: '1'
    },
    contentListItemText: {
      color: theme.palette.primary.main,
      fontSize: '20px',
      marginRight: '5px',
      lineHeight: '0.5'
    },
    bidsText: {
      lineHeight: 1.5,
      marginLeft: '3px',
      fontSize: '14px',
      color: theme.palette.info.lighter
    },
    contentChip: {
      backgroundColor: theme.palette.primary.main,
      textTransform: 'capitalize',
      color: theme.palette.common.white,
      fontWeight: '700',
      margin: '4px',
      fontSize: '12px'
    }
  });

  const styles = useStyles();

  return (
    <>
      <Box className={styles.container} sx={{ width: width }}>
        <Box
          className={styles.buttonHeaderContainer}
          id="activeAuction8"
          sx={{
            justifyContent: isExpanded ? 'flex-start' : 'space-between'
          }}
        >
          {!isPreAuction ? (
            <>
              <Box sx={{ margin: '0px 0px' }}>
                <Tooltip title="Announcements/Disclosures" placement="left">
                  <IconButton
                    onClick={() => handleToggleDrawer(0, vehicle)}
                    sx={{ padding: isExpanded ? '8px' : '5px' }}
                  >
                    <AssignmentLateIcon className={styles.iconButton} />
                  </IconButton>
                </Tooltip>
              </Box>

              <Box sx={{ margin: '0px 0px' }}>
                <Tooltip title="Damages/Conditions" placement="left">
                  <IconButton
                    onClick={() => handleToggleDrawer(1, vehicle)}
                    sx={{ padding: isExpanded ? '8px' : '5px' }}
                  >
                    <AssignmentIcon className={styles.iconButton} />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box sx={{ margin: '0px 0px' }}>
                <Tooltip title="Equipment" placement="left">
                  <IconButton
                    onClick={() => handleToggleDrawer(2, vehicle)}
                    sx={{ padding: isExpanded ? '8px' : '5px' }}
                  >
                    <AssignmentTurnedInIcon className={styles.iconButton} />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box sx={{ margin: '0px 0px' }}>
                <Tooltip title="Vehicle Overview" placement="left">
                  <IconButton
                    onClick={() => handleToggleDrawer(3, vehicle)}
                    sx={{ padding: isExpanded ? '8px' : '5px' }}
                  >
                    <DirectionsCarIcon className={styles.iconButton} />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box sx={{ margin: '0px 0px' }}>
                <Tooltip title="Seller’s Contact" placement="left">
                  <IconButton
                    onClick={() => handleToggleDrawer(4, vehicle)}
                    sx={{ padding: isExpanded ? '8px' : '5px' }}
                  >
                    <AssignmentIndIcon className={styles.iconButton} />
                  </IconButton>
                </Tooltip>
              </Box>
            </>
          ) : (
            <Box id="preAuctionBuy5">
              <Box sx={{ margin: '0px 0px' }}>
                <Tooltip title="Announcements/Disclosures" placement="left">
                  <IconButton
                    onClick={() => handleToggleDrawer(0, vehicle)}
                    sx={{ padding: '5px' }}
                  >
                    <AssignmentLateIcon className={styles.iconButton} />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box sx={{ margin: '0px 0px' }}>
                <Tooltip title="Damages/Conditions" placement="left">
                  <IconButton
                    onClick={() => handleToggleDrawer(1, vehicle)}
                    sx={{ padding: '5px' }}
                  >
                    <AssignmentIcon className={styles.iconButton} />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box sx={{ margin: '0px 0px' }}>
                <Tooltip title="Equipment" placement="left">
                  <IconButton
                    onClick={() => handleToggleDrawer(2, vehicle)}
                    sx={{ padding: '5px' }}
                  >
                    <AssignmentTurnedInIcon className={styles.iconButton} />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box sx={{ margin: '0px 0px' }}>
                <Tooltip title="Vehicle Overview" placement="left">
                  <IconButton
                    onClick={() => handleToggleDrawer(3, vehicle)}
                    sx={{ padding: '5px' }}
                  >
                    <DirectionsCarIcon className={styles.iconButton} />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box sx={{ margin: '0px 0px' }}>
                <Tooltip title="Seller’s Contact" placement="left">
                  <IconButton
                    onClick={() => handleToggleDrawer(4, vehicle)}
                    sx={{ padding: '5px' }}
                  >
                    <AssignmentIndIcon className={styles.iconButton} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          )}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <Box className={styles.contentContainer}>
            <Typography
              className={styles.contentText}
              sx={{
                color:
                  theme.palette.mode === 'dark'
                    ? theme.palette.common.white
                    : theme.palette.text.primary
              }}
            >
              Announcements
            </Typography>
            <Typography sx={{ fontSize: '12px', color: theme.palette.primary.light }}>
              Scroll
            </Typography>
          </Box>
          <Box className={styles.contentListContainer}>
            <List>
              {vehicle.disclosures.mtoReg && vehicle.disclosures.mtoReg.toLowerCase() !== 'none' && (
                <ListItem sx={{ paddingTop: '0px' }}>
                  <Typography className={styles.contentListItemText}>{'\u2022'}</Typography>
                  <Typography component="p" sx={{ fontSize: '13px' }}>
                    MTO Registration Brand: {vehicle.disclosures.mtoReg}
                  </Typography>
                </ListItem>
              )}
              {vehicle.disclosures && vehicle.disclosures.drivable !== true && (
                <ListItem sx={{ paddingTop: '0px' }}>
                  <Typography className={styles.contentListItemText}>{'\u2022'}</Typography>
                  <Typography component="p" sx={{ fontSize: '13px' }}>
                    Vehicle Drivable: {vehicle.disclosures.drivable ? 'Yes' : 'No'}
                  </Typography>
                </ListItem>
              )}
              {vehicle.disclosures && vehicle.disclosures.prevRepairs && (
                <ListItem sx={{ paddingTop: '0px' }}>
                  <Typography className={styles.contentListItemText}>{'\u2022'}</Typography>
                  <Typography component="p" sx={{ fontSize: '13px' }}>
                    Previous Repairs: {vehicle.disclosures.prevRepairs ? 'Yes' : 'No'}
                  </Typography>
                </ListItem>
              )}
              {vehicle.disclosures && vehicle.disclosures.badgesChanged && (
                <ListItem sx={{ paddingTop: '0px' }}>
                  <Typography className={styles.contentListItemText}>{'\u2022'}</Typography>
                  <Typography component="p" sx={{ fontSize: '13px' }}>
                    Badges Changed: {vehicle.disclosures.badgesChanged ? 'Yes' : 'No'}
                  </Typography>
                </ListItem>
              )}
              {vehicle.disclosures && vehicle.disclosures.inopABS && (
                <ListItem sx={{ paddingTop: '0px' }}>
                  <Typography className={styles.contentListItemText}>{'\u2022'}</Typography>
                  <Typography component="p" sx={{ fontSize: '13px' }}>
                    ABS Inoperable: {vehicle.disclosures.inopABS ? 'Yes' : 'No'}
                  </Typography>
                </ListItem>
              )}
              {vehicle.disclosures && vehicle.disclosures.inopAirBags && (
                <ListItem sx={{ paddingTop: '0px' }}>
                  <Typography className={styles.contentListItemText}>{'\u2022'}</Typography>
                  <Typography component="p" sx={{ fontSize: '13px' }}>
                    Air Bags Inoperable: {vehicle.disclosures.inopAirBags ? 'Yes' : 'No'}
                  </Typography>
                </ListItem>
              )}
              {vehicle.disclosures && vehicle.disclosures.inopPollution && (
                <ListItem sx={{ paddingTop: '0px' }}>
                  <Typography className={styles.contentListItemText}>{'\u2022'}</Typography>
                  <Typography component="p" sx={{ fontSize: '13px' }}>
                    Pollution Control Inoperable: {vehicle.disclosures.inopPollution ? 'Yes' : 'No'}
                  </Typography>
                </ListItem>
              )}
              {/* {vehicle.disclosures.numberOfKeys && (
                <ListItem sx={{ paddingTop: '0px' }}>
                  <Typography className={styles.contentListItemText}>{'\u2022'}</Typography>
                  <Typography component="p" sx={{ fontSize: '13px' }}>
                    Number of Keys: {vehicle.disclosures.numberOfKeys}
                  </Typography>
                </ListItem>
              )} */}
              {vehicle.disclosures && vehicle.disclosures.manuals !== true && (
                <ListItem sx={{ paddingTop: '0px' }}>
                  <Typography className={styles.contentListItemText}>{'\u2022'}</Typography>
                  <Typography component="p" sx={{ fontSize: '13px' }}>
                    Manuals Included: {vehicle.disclosures.manuals ? 'Yes' : 'No'}
                  </Typography>
                </ListItem>
              )}
              {vehicle.disclosures && vehicle.disclosures.fifthWheel && (
                <ListItem sx={{ paddingTop: '0px' }}>
                  <Typography className={styles.contentListItemText}>{'\u2022'}</Typography>
                  <Typography component="p" sx={{ fontSize: '13px' }}>
                    Fifth Wheel: {vehicle.disclosures.fifthWheel ? 'Yes' : 'No'}
                  </Typography>
                </ListItem>
              )}
              {vehicle.disclosures && vehicle.disclosures.salvage && (
                <ListItem sx={{ paddingTop: '0px' }}>
                  <Typography className={styles.contentListItemText}>{'\u2022'}</Typography>
                  <Typography component="p" sx={{ fontSize: '13px' }}>
                    Salvage: {vehicle.disclosures.salvage ? 'Yes' : 'No'}
                  </Typography>
                </ListItem>
              )}
              {vehicle.disclosures && vehicle.disclosures.structDamage && (
                <ListItem sx={{ paddingTop: '0px' }}>
                  <Typography className={styles.contentListItemText}>{'\u2022'}</Typography>
                  <Typography component="p" sx={{ fontSize: '13px' }}>
                    Structural Damage: {vehicle.disclosures.structDamage ? 'Yes' : 'No'}
                  </Typography>
                </ListItem>
              )}
              {vehicle.disclosures && vehicle.disclosures.structRepairs && (
                <ListItem sx={{ paddingTop: '0px' }}>
                  <Typography className={styles.contentListItemText}>{'\u2022'}</Typography>
                  <Typography component="p" sx={{ fontSize: '13px' }}>
                    Structural Repairs: {vehicle.disclosures.structRepairs ? 'Yes' : 'No'}
                  </Typography>
                </ListItem>
              )}
              {vehicle.disclosures.repairNotes &&
                vehicle.disclosures.repairNotes !== ' ' &&
                vehicle.disclosures.repairNotes !== '' && (
                  <ListItem sx={{ paddingTop: '0px' }}>
                    <Typography className={styles.contentListItemText}>{'\u2022'}</Typography>
                    <Typography component="p" sx={{ fontSize: '13px' }}>
                      Repair Notes: {vehicle.disclosures.repairNotes}
                    </Typography>
                  </ListItem>
                )}
              {vehicle.disclosures.notes &&
                vehicle.disclosures.notes !== ' ' &&
                vehicle.disclosures.notes !== '' && (
                  <ListItem sx={{ paddingTop: '0px' }}>
                    <Typography className={styles.contentListItemText}>{'\u2022'}</Typography>
                    <Typography component="p" sx={{ fontSize: '13px' }}>
                      Notes: {vehicle.disclosures.notes}
                    </Typography>
                  </ListItem>
                )}
              {vehicle.disclosures && vehicle.disclosures.soldAsIs && (
                <ListItem sx={{ paddingTop: '0px' }}>
                  <Typography className={styles.contentListItemText}>{'\u2022'}</Typography>
                  <Typography component="p" sx={{ fontSize: '13px' }}>
                    Sold As Is: {vehicle.disclosures.soldAsIs ? 'Yes' : 'No'}
                  </Typography>
                </ListItem>
              )}
              {vehicle.disclosures && vehicle.disclosures.outOfProvince && (
                <ListItem sx={{ paddingTop: '0px' }}>
                  <Typography className={styles.contentListItemText}>{'\u2022'}</Typography>
                  {/* prettier-ignore */}
                  <Typography component="p" sx={{ fontSize: '13px' }}>
                    Out of Province: {vehicle.disclosures.province ? vehicle.disclosures.province : 'No'}
                  </Typography>
                </ListItem>
              )}
              {vehicle.disclosures && vehicle.disclosures.usVehicle && (
                <ListItem sx={{ paddingTop: '0px' }}>
                  <Typography className={styles.contentListItemText}>{'\u2022'}</Typography>
                  <Typography component="p" sx={{ fontSize: '13px' }}>
                    USA Vehicle: {vehicle.disclosures.usVehicle ? 'Yes' : 'No'}
                  </Typography>
                </ListItem>
              )}
              {vehicle.disclosures && vehicle.disclosures.warrantyCancelled && (
                <ListItem sx={{ paddingTop: '0px' }}>
                  <Typography className={styles.contentListItemText}>{'\u2022'}</Typography>
                  {/* prettier-ignore */}
                  <Typography component="p" sx={{ fontSize: '13px' }}>
                    Manufacturer Warranty Cancelled: {vehicle.disclosures.warrantyCancelled ? 'Yes' : 'No'}
                  </Typography>
                </ListItem>
              )}
              {vehicle.disclosures.odometerStatus &&
                vehicle.disclosures.odometerStatus !== ' ' &&
                vehicle.disclosures.odometerStatus.toLowerCase() !== 'none' && (
                  <>
                    <ListItem sx={{ paddingTop: '0px' }}>
                      <Typography className={styles.contentListItemText}>{'\u2022'}</Typography>
                      <Typography component="p" sx={{ fontSize: '13px' }}>
                        Odometer Status: {vehicle.disclosures.odometerStatus}
                      </Typography>
                    </ListItem>
                    {vehicle.disclosures.odometerReadingDate && (
                      <ListItem sx={{ paddingTop: '0px' }}>
                        <Typography className={styles.contentListItemText}>{'\u2022'}</Typography>
                        {/* prettier-ignore */}
                        <Typography component="p" sx={{ fontSize: '13px' }}>
                          Odometer Reading Date: {moment(vehicle.disclosures.odometerReadingDate?.toDate()).format("YYYY-MM-DD hh:mm a")}
                        </Typography>
                      </ListItem>
                    )}
                  </>
                )}
              {vehicle.disclosures.wasVehicle &&
                vehicle.disclosures.wasVehicle !== ' ' &&
                vehicle.disclosures.wasVehicle.toLowerCase() !== 'none' && (
                  <ListItem sx={{ paddingTop: '0px' }}>
                    <Typography className={styles.contentListItemText}>{'\u2022'}</Typography>
                    <Typography component="p" sx={{ fontSize: '13px' }}>
                      This vehicle was: {vehicle.disclosures.wasVehicle}
                    </Typography>
                  </ListItem>
                )}
              {vehicle.disclosures.wasDeclared &&
                vehicle.disclosures.wasDeclared !== ' ' &&
                vehicle.disclosures.wasDeclared.toLowerCase() !== 'none' && (
                  <ListItem sx={{ paddingTop: '0px' }}>
                    <Typography className={styles.contentListItemText}>{'\u2022'}</Typography>
                    <Typography component="p" sx={{ fontSize: '13px' }}>
                      Vehicle has been declared: {vehicle.disclosures.wasDeclared}
                    </Typography>
                  </ListItem>
                )}
              {vehicle.disclosures.otherDisclosures && (
                <ListItem sx={{ paddingTop: '0px' }}>
                  <Typography className={styles.contentListItemText}>{'\u2022'}</Typography>
                  <Typography component="p" sx={{ fontSize: '13px' }}>
                    Other Disclosures: {vehicle.disclosures.otherDisclosures}
                  </Typography>
                </ListItem>
              )}
            </List>
          </Box>
          {vehicle && (
            <Box
              className={styles.carfaxContainer}
              sx={{
                backgroundColor:
                  (totalVHRDamages && totalVHRDamages > 0) || hasVHRDescription
                    ? theme.palette.error.lighter
                    : 'none'
              }}
            >
              <Box>
                <Typography
                  component="p"
                  sx={{
                    fontSize: '11px',
                    fontWeight: 'bold',
                    color:
                      (totalVHRDamages && totalVHRDamages > 0) || hasVHRDescription
                        ? theme.palette.error.main
                        : ''
                  }}
                >
                  {totalVHRDamages && totalVHRDamages > 0
                    ? 'Total VHR Damages'
                    : hasVHRDescription
                    ? 'Check Vehicle History for VHR Details'
                    : 'No VHR Report'}
                </Typography>
              </Box>
              <Typography
                component="p"
                sx={{
                  fontSize: '11px',
                  fontWeight: 'bold',
                  color: totalVHRDamages && totalVHRDamages > 0 ? theme.palette.error.main : ''
                }}
              >
                {totalVHRDamages && totalVHRDamages > 0
                  ? currencyFormat.format(totalVHRDamages as number)
                  : null}
              </Typography>
            </Box>
          )}
          {vehicle && vehicle.auction.isExpanded.includes(user?.uid) && !isPreAuction ? (
            <Box sx={{ maxHeight: '200px' }}>
              <Box className={styles.contentContainer}>
                <Typography className={styles.contentText}>Notables</Typography>
                {/* <KeyboardArrowDownIcon sx={{ color: theme.palette.primary.main }} /> */}
              </Box>
              <Box sx={{ padding: '5px', maxHeight: '94px', overflowY: 'auto' }}>
                <Chip className={styles.contentChip} label={vehicle.details.transmission}></Chip>
                <Chip className={styles.contentChip} label={vehicle.equipment.clothLeather}></Chip>
                <Chip className={styles.contentChip} label={vehicle.equipment.roof}></Chip>
                <Chip className={styles.contentChip} label={vehicle.details.driveTrain}></Chip>
                <Chip
                  className={styles.contentChip}
                  label={`Tires: ${vehicle.equipment.sizeTires}`}
                ></Chip>
                {vehicle.equipment.navigation && (
                  <Chip className={styles.contentChip} label={'Navi'}></Chip>
                )}
                <Chip className={styles.contentChip} label={vehicle.details.fuelType}></Chip>
                {vehicle.equipment.tpms && (
                  <Chip className={styles.contentChip} label={'TPMS'}></Chip>
                )}
                {!vehicle.equipment.tpms && (
                  <Chip className={styles.contentChip} label={'No TPMS'}></Chip>
                )}
                {vehicle.equipment.thirdRow && (
                  <Chip className={styles.contentChip} label={'Yes: Third Row'}></Chip>
                )}
                {vehicle.equipment.powerSeat && (
                  <Chip className={styles.contentChip} label={'Power Seats'}></Chip>
                )}
                {/* <Chip className={styles.contentChip} label={vehicle.details.displacement}></Chip> */}
              </Box>
            </Box>
          ) : (
            <Box></Box>
          )}
        </Box>
      </Box>
      {/*  {vehicle && (
        <AuctionDrawer
          vehicle={vehicle as VehicleInfo}
          tabIndex={drawerTab}
          isOpenSidebar={openDrawer}
          onCloseSidebar={() => setOpenDrawer(!openDrawer)}
        />
      )} */}
    </>
  );
});

export default VehicleDetails;
