import {
  Container,
  Typography,
  Grid,
  Paper,
  Box,
  Drawer,
  MenuItem,
  Select,
  InputBase,
  Tabs,
  Tab,
  SelectChangeEvent,
  TablePagination,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  Alert,
  AlertTitle,
  Button,
  TextField,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  stepConnectorClasses
} from '@mui/material';
import {
  arrayRemove,
  collection,
  CollectionReference,
  doc,
  DocumentReference,
  DocumentSnapshot,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  Timestamp,
  where
} from 'firebase/firestore';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import { useTheme } from '@mui/material/styles';
import { DrawerContent } from '../../components/auction';
import * as React from 'react';
import { useState, useEffect } from 'react';
import useSettings from '../../hooks/useSettings';
import Page from '../../components/Page';
import { DebounceInput } from 'react-debounce-input';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { Dealership } from 'types/dealership';
import { useFirestoreByPath } from '../../hooks/useFirestoreByPath';
import { styled } from '@mui/material/styles';
import DealershipCard from 'components/admin/DealershipCard';
import { DealerRequest } from 'types/request';
import useAuth from 'hooks/useAuth';
import { useSnackbar } from 'notistack';
import Tour from 'reactour';
import { User } from 'constants/user';
import EmptyStateMessage from 'components/shared/EmptyStateMessage';
import CloseIcon from '@mui/icons-material/Close';
import { isMobile } from 'react-device-detect';
import { Formik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { VehicleCalendar, VehicleTime } from 'pages/auction/seller/components/CommonComponents';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

type Email = {
  email: string;
  name: string;
};

export default function SellerNetworkPage() {
  const { themeStretch } = useSettings();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser, user, logout } = useAuth();

  const firestore = useFirestore();
  const [searchValue, setSearchValue] = useState('');
  const [isFiltered] = useState<boolean>(false);
  const [filterChange] = useState<boolean>(false);
  const [tabValue, setTabValue] = useState(0);
  let [filters] = useState<any>([{}]);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  const [order, setOrder] = useState('name');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [incomingOrder, setIncomingOrder] = useState('name');
  const [incomingPage, setIncomingPage] = useState(0);
  const [incomingRowsPerPage, setIncomingRowsPerPage] = useState(25);

  const [outgoingOrder, setOutgoingOrder] = useState('name');
  const [outgoingPage, setOutgoingPage] = useState(0);
  const [outgoingRowsPerPage, setOutgoingRowsPerPage] = useState(25);

  const [currentDealership, setCurrentDealership] = useState<Dealership>();
  const [myNetwork, setMyNetwork] = useState<Dealership[]>();
  const [incomingRequests, setIncomingRequests] = useState<DealerRequest[]>();
  const [outgoingRequests, setOutgoingRequests] = useState<DealerRequest[]>();

  const [auctionDate, setAuctionDate] = useState<any>();
  const [auctionTime, setAuctionTime] = useState<any>();
  const [emailButtonLoading, setEmailButtonLoading] = useState<boolean>(false);

  const dialogSteps = ['Choose Your Email', 'Send Email'];
  const [emailType, setEmailType] = useState<string>();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [skipped, setSkipped] = useState(new Set<number>());

  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`& .${stepConnectorClasses.line}`]: {
      width: '75%',
      margin: 'auto'
    }
  }));

  const isStepOptional = (step: number) => {
    return false; // step === 0;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = (type: string) => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setEmailType(type);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setAuctionDate(undefined);
    setAuctionTime(undefined);
    setNote(undefined);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const [dialogOpen, setDialogOpen] = useState(false);
  const handleDialogChange = () => {
    setDialogOpen(!dialogOpen);
  };

  const [emailDialogOpen, setEmailDialogOpen] = useState(false);
  const handleEmailDialogChange = () => {
    setEmailDialogOpen(!emailDialogOpen);
    setActiveStep(0);
    setAuctionDate(null);
    setAuctionTime(null);
    setNote(undefined);
  };

  const boxStyle = {
    textAlign: 'start',
    minWidth: '350px',
    marginTop: '15px',
    height: 60,
    backgroundColor: theme.palette.mode !== 'dark' ? theme.palette.common.white : 'unset',
    border: `1px rgb(163, 167, 187) solid`,
    borderRadius: '10px',
    fontWeight: 300,
    padding: 0,
    overflow: 'hidden'
  };

  const invitePath = `users/${user?.uid}/invites`;
  // prettier-ignore
  const {
    data: invites,
    update: updateInvite,
    remove: removeInvite
  } = useFirestoreByPath<any>(
    invitePath,
    where('inviteType', '==', 'dealership')
  );

  const requestRef = collection(firestore, 'requests') as CollectionReference<DealerRequest>;
  const requestQuery = query(requestRef);
  const { data: allRequests } = useFirestoreCollectionData(requestQuery, { idField: 'id' });
  const [, setRequests] = useState<DealerRequest[]>();

  const dealershipsRef = collection(firestore, 'dealerships') as CollectionReference<Dealership>;
  const dealershipsQuery = query(
    dealershipsRef,
    where('joinedDealerships', 'array-contains', currentUser.currentDealershipId)
  );
  const { data: allDealerships } = useFirestoreCollectionData(dealershipsQuery, { idField: 'id' });
  const { update: updateDealership } = useFirestoreByPath<Dealership>('dealerships');

  const { remove: removeRequest, update: updateRequest } =
    useFirestoreByPath<DealerRequest>('requests');

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOrderByChange = (event: SelectChangeEvent) => {
    setOrder(event.target.value);
  };

  const handleIncomingChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setIncomingPage(newPage);
  };

  const handleIncomingChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setIncomingRowsPerPage(parseInt(event.target.value, 10));
    setIncomingPage(0);
  };

  const handleIncomingOrderByChange = (event: SelectChangeEvent) => {
    setIncomingOrder(event.target.value);
  };

  const handleOutgoingChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setOutgoingPage(newPage);
  };

  const handleOutgoingChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setOutgoingRowsPerPage(parseInt(event.target.value, 10));
    setOutgoingPage(0);
  };

  const handleOutgoingOrderByChange = (event: SelectChangeEvent) => {
    setOutgoingOrder(event.target.value);
  };

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  interface StyledTabsProps {
    id?: string;
    children?: React.ReactNode;
    value: number;
    onChange: (event: React.SyntheticEvent, newValue: number) => void;
  }

  const a11yProps = (index: number) => {
    return {
      id: `dealer-network-tab-${index}`,
      'aria-controls': `dealer-network-tabpanel-${index}`
    };
  };

  const closeDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const list = () => (
    <Box sx={{ width: 400 }} role="presentation">
      {currentDealership && (
        <DrawerContent
          dealership={currentDealership as Dealership}
          logo={currentDealership ? currentDealership.logoUrl : ''}
          onClose={closeDrawer}
        />
      )}
    </Box>
  );

  const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`notifications-tabpanel-${index}`}
        aria-labelledby={`notifications-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 0 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };

  const StyledTabs = styled((props: StyledTabsProps) => (
    <Tabs
      {...props}
      sx={{
        minHeight: 'unset !important'
      }}
      variant="scrollable"
      // scrollButtons
      TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
  ))(({ theme }) => ({
    '& .MuiTab-root': {
      color:
        theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.text.secondary,
      backgroundColor:
        theme.palette.mode === 'dark'
          ? theme.palette.background.neutral
          : theme.palette.background.paper
    },
    '& .MuiTab-root:not(:last-of-type)': {
      marginRight: '10px'
    },
    '& .MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent !important',
      padding: '5px'
    },
    '& .Mui-selected': {
      backgroundColor: theme.palette.primary.lighter,
      color: `${theme.palette.primary.main} !important`
    },
    '& .MuiTabs-indicatorSpan': {
      display: 'none',
      maxWidth: 40,
      width: '100%',
      backgroundColor: theme.palette.chart.violet[2]
    }
  }));

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    sortRequestsForTabs(allRequests);
    setSearchValue('');
    setTabValue(newValue);
  };

  const handleDealerInviteItemClick = async (index: number, requestId: string) => {
    if (index === 0) {
      await updateInvite(requestId as string, {
        inviteDateSent: Timestamp.now(),
        isExpired: false
      });
      enqueueSnackbar('Invite Resent', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        }
      });
    } else {
      await removeInvite(requestId as string);
      enqueueSnackbar('Invite Deleted', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        }
      });
    }
  };

  const handleInviteItemClick = async (
    index: number,
    dealershipId: string,
    requestId?: string,
    requestType?: string
  ) => {
    if (index === 0) {
      getDealership(dealershipId);
      setDrawerOpen(!drawerOpen);
    } else if (index === 1) {
      await updateRequest(requestId as string, { requestDateSent: Timestamp.now() });
      enqueueSnackbar('Invite Resent', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        }
      });
    } else {
      await removeRequest(requestId as string);
      await updateDealership(currentUser?.currentDealershipId, {
        outgoingInvites: arrayRemove(dealershipId)
      });
      await updateDealership(dealershipId, {
        outgoingRequests: arrayRemove(currentUser?.currentDealershipId)
      });
      enqueueSnackbar('Dealership Removed', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        }
      });
    }
  };

  const handleMenuItemClick = async (
    index: number,
    dealershipId: string,
    requestId?: string,
    requestType?: string
  ) => {
    if (index === 0) {
      getDealership(dealershipId);
      setDrawerOpen(!drawerOpen);
    } else {
      if (
        currentUser.currentDealershipRole === 'admin' ||
        currentUser.currentDealershipRole === 'superAdmin'
      ) {
        if (tabValue === 0) {
          const docRef = doc(firestore, `dealerships/${dealershipId}`);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const otherDealership = { id: docSnap.id, ...docSnap.data() } as Dealership;
            const outgoingRequest = otherDealership.outgoingRequests?.find(
              (dealerId: string) => dealerId === currentUser.currentDealershipId
            );
            if (outgoingRequest) {
              await updateDealership(currentUser?.currentDealershipId, {
                networkDealerships: arrayRemove(dealershipId),
                outgoingInvites: arrayRemove(dealershipId)
              });
              await updateDealership(dealershipId, {
                joinedDealerships: arrayRemove(currentUser?.currentDealershipId),
                outgoingRequests: arrayRemove(currentUser?.currentDealershipId)
              });
            } else {
              await updateDealership(currentUser?.currentDealershipId, {
                networkDealerships: arrayRemove(dealershipId),
                outgoingInvites: arrayRemove(dealershipId)
              });
              await updateDealership(dealershipId, {
                joinedDealerships: arrayRemove(currentUser?.currentDealershipId),
                outgoingRequests: arrayRemove(currentUser?.currentDealershipId)
              });
            }
          }
        } else {
          await removeRequest(requestId as string);
          if (tabValue === 2) {
            await updateDealership(currentUser?.currentDealershipId, {
              outgoingInvites: arrayRemove(dealershipId)
            });
            await updateDealership(dealershipId, {
              outgoingRequests: arrayRemove(currentUser?.currentDealershipId)
            });
          }
        }
        enqueueSnackbar('Dealership Removed', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right'
          }
        });
      } else {
        handleDialogChange();
      }
    }
  };

  const getDealership = async (dealershipId: string) => {
    const docRef = doc(firestore, `dealerships/${dealershipId}`);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const currentDealership = { id: docSnap.id, ...docSnap.data() } as Dealership;
      setCurrentDealership(currentDealership);
    }
  };

  const handleSearchChange = (searchValue: string) => {
    if (tabValue === 0) {
      const filteredDealerships = allDealerships?.filter(
        (dealership: Dealership) =>
          dealership.dealershipName.toLowerCase().includes(searchValue.toLowerCase()) ||
          dealership.province.toString().toLowerCase().includes(searchValue.toLowerCase()) ||
          // eslint-disable-next-line array-callback-return
          dealership.brands.find((brand: { name: string }) => {
            if (brand.name.toLowerCase().includes(searchValue.toLowerCase())) {
              return true;
            }
          })
      );
      setMyNetwork(filteredDealerships);
    } else if (tabValue === 1) {
      const filteredRequests = allRequests?.filter(
        (request: DealerRequest) =>
          request.requestingDealershipName.toLowerCase().includes(searchValue.toLowerCase()) ||
          request.requestingDealershipLocation
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          // eslint-disable-next-line array-callback-return
          request.requestingDealershipBrands.find((brand: { name: string }) => {
            if (brand.name.toLowerCase().includes(searchValue.toLowerCase())) {
              return true;
            }
          })
      );
      setRequests(filteredRequests);
    } else {
      const filteredRequests = allRequests?.filter(
        (request: DealerRequest) =>
          request.receivingDealershipName.toLowerCase().includes(searchValue.toLowerCase()) ||
          request.receivingDealershipLocation
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          // eslint-disable-next-line array-callback-return
          request.receivingDealershipBrands.find((brand: { name: string }) => {
            if (brand.name.toLowerCase().includes(searchValue.toLowerCase())) {
              return true;
            }
          })
      );
      setRequests(filteredRequests);
    }
  };

  const sortRequestsForTabs = (requests: DealerRequest[]) => {
    setMyNetwork(allDealerships);
    const incomingRequests = requests.filter(
      (r: DealerRequest) =>
        r.requestingType === 'buy-from-them' &&
        r.receivingType === 'buy-from-me' &&
        r.requestStatus === 'pending' &&
        r.receivingDealershipId === currentUser.currentDealershipId
    );
    setIncomingRequests(incomingRequests);

    const outgoingRequests = requests.filter(
      (r: DealerRequest) =>
        r.requestingType === 'buy-from-me' &&
        r.receivingType === 'buy-from-them' &&
        r.requestStatus === 'pending' &&
        r.requestingDealershipId === currentUser.currentDealershipId
    );

    const outgoingDealerRequests = invites.filter(
      (i: any) => i.networkType.toLowerCase() === 'invite'
    );

    const outgoingCombined: DealerRequest[] = [];

    outgoingRequests.forEach((request: DealerRequest) => {
      outgoingCombined.push(request);
    });

    outgoingDealerRequests.forEach((request: any) => {
      request = { ...request, uiType: 'dealerRequest', requestStatus: 'pending' };
      outgoingCombined.push(request);
    });

    setOutgoingRequests(outgoingCombined as DealerRequest[]);
  };

  useEffect(() => {
    if (allRequests && allDealerships && invites) {
      sortRequestsForTabs(allRequests);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allRequests, allDealerships, invites]);

  useEffect(() => {
    if (isFiltered) {
      let filteredRequests = allRequests as DealerRequest[];
      filters.forEach((filter: any) => {
        switch (filter.name) {
          case 'brand':
            filteredRequests = filteredRequests.filter((request: DealerRequest) =>
              // eslint-disable-next-line array-callback-return
              request.brands.find((brand: { name: string }) => {
                if (brand.name.toLowerCase().includes(filter.value.toLowerCase())) {
                  return true;
                }
              })
            );
            break;
        }
      });
      setRequests(filteredRequests);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterChange]);

  const useStyles = makeStyles({
    tourContainer: {
      maxWidth: '500px !important',
      borderRadius: '10px !important'
    },
    searchContainer: {
      width: '100%',
      maxWidth: '400px',
      height: 'auto',
      padding: '5px 0px',
      marginBottom: '0px',
      marginRight: '20px'
    },
    expandIcon: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      borderRadius: '100%',
      width: '25px',
      height: '25px',
      padding: '5px'
    },
    headerText: {
      fontSize: '14px',
      marginRight: '18px',
      marginLeft: '0px'
    },
    inputText: {
      fontSize: '14px'
    },
    dialogContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      position: 'relative'
    },
    emailRow: {
      marginTop: '10px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    inviteRow: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      marginTop: '20px'
    },
    inviteButton: {
      backgroundColor: theme.palette.primary.main
    },
    buttonRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      width: '100%'
      // marginTop: '60px'
    },
    toolbar: {
      margin: 1,
      width: '200px',
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      right: '380px',
      top: '-35px'
    },
    dropdownStyle: {
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: '12px',
      lineHeight: '18px',
      color: theme.palette.info.light
    },
    search: {
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.background.neutral : 'transparent !important',
      border: theme.palette.mode === 'dark' ? 'unset' : `1px solid ${theme.palette.divider}`,
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1
    },
    searchInput: {
      fontSize: '14px',
      width: '100%',
      height: '34px',
      margin: '0 10px',
      border: 'none',
      padding: '5px 10px',
      outline: 'none !important',
      marginLeft: 2,
      flex: 1,
      backgroundColor: 'transparent !important',
      color:
        theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.text.secondary,
      '&::placeholder': {
        color:
          theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.text.secondary,
        fontSize: '14px'
      }
    },
    searchIcon: {
      fill: theme.palette.primary.main,
      marginRight: '5px'
    },
    secondaryButton: {
      backgroundColor: theme.palette.grey[50],
      color: theme.palette.info.light,
      '&:hover': {
        backgroundColor: theme.palette.grey[100]
      }
    },
    subtitle: {
      fontWeight: 'bold',
      color: theme.palette.secondary.darker
    },
    subtitle2: {
      color: theme.palette.info.light,
      fontWeight: 'normal'
    },
    list: {
      '& li:first-child, & li:last-child': {
        '& button': {
          border: `1px solid ${theme.palette.grey[100]}`,
          color: theme.palette.info.light,
          width: '100px',
          '&:hover': {
            backgroundColor: theme.palette.grey[100]
          }
        }
      },
      '& li': {
        paddingLeft: 3,
        paddingRight: 3,
        '& button': {
          color: theme.palette.info.main,
          minWidth: '40px'
        }
      }
    },
    divider: {
      width: '93%'
    },
    gridDateTitle: {
      marginRight: '64px'
    },
    menuPopover: {
      boxShadow: '0 0 2px 0 rgba(139, 148, 156, 0.24),0 10px 20px -4px rgba(139, 148, 156, 0.24)',
      paddingTop: '20px',
      paddingBottom: '20px',
      '@global': {
        '.MuiMenuItem-root': {
          paddingBottom: '6px'
        }
      }
    },
    headerTextContainer: {
      width: '100%',
      height: 'auto',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    controlsContainer: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'flex-end',
      [theme.breakpoints.down(675)]: {
        marginTop: '25px',
        justifyContent: 'center'
      }
    },
    pagination: {
      borderTopStyle: 'none',
      fontWeight: '700',
      borderTop: 'none'
    },
    dealerCardContainer: {
      padding: '10px 10px 10px 0px'
    }
  });

  const styles = useStyles();

  const [tourOpen, setTourOpen] = useState(false);
  const { update: updateUser } = useFirestoreByPath<User>('users');

  const [userDeleted, setUserDeleted] = useState<boolean>(false);
  const [curUser, setCurUser] = useState<User>();

  const [userDisabled, setUserDisabled] = useState<boolean>(false);
  const [userSuspended, setUserSuspended] = useState<boolean>(false);
  const [buyerOnly, setBuyerOnly] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/dashboard');
    // window.location.reload();
  };

  const snapshotUser = () => {
    const userDoc = doc(firestore, `users/${user?.uid}`) as DocumentReference<User>;
    onSnapshot(userDoc, (doc: any) => {
      const userObj = { ...doc.data(), id: doc.id } as User;
      setCurUser(userObj);
      if (!userObj.email) {
        logout?.().then(() => {
          navigate('.');
          window.location.reload();
        });
      }
      if (userObj.showSellingNetworkTour && !tourOpen) {
        setTourOpen(true);
      }
      if (userObj && userObj.status.toLowerCase() === 'suspended') {
        setUserSuspended(true);
      } else {
        setUserSuspended(false);
      }
      if (userObj && userObj.status.toLowerCase() === 'disabled') {
        setUserDisabled(true);
      } else {
        setUserDisabled(false);
      }
      if (userObj && userObj.status === 'buyerOnly') {
        setBuyerOnly(true);
      } else {
        setBuyerOnly(false);
      }
    });
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted && !curUser) {
      snapshotUser();
    }
    return () => {
      isMounted = false;
    };
    // if (!curUser) {
    //   snapshotUser();
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [networkEmptyState, setNetworkEmptyState] = useState<boolean>(false);
  const [incomingEmptyState, setIncomingEmptyState] = useState<boolean>(false);
  const [outgoingEmptyState, setOutgoingEmptyState] = useState<boolean>(false);

  useEffect(() => {
    if (allDealerships && (!myNetwork || myNetwork.length < 1)) {
      setNetworkEmptyState(true);
    } else {
      setNetworkEmptyState(false);
    }
    if (allRequests && (!incomingRequests || incomingRequests.length < 1)) {
      setIncomingEmptyState(true);
    } else {
      setIncomingEmptyState(false);
    }
    if (allRequests && (!outgoingRequests || outgoingRequests.length < 1)) {
      setOutgoingEmptyState(true);
    } else {
      setOutgoingEmptyState(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allDealerships, allRequests, myNetwork, incomingRequests, outgoingRequests]);

  const handleCloseTour = async () => {
    await updateUser(user?.uid as string, { showSellingNetworkTour: false });
    setTourOpen(false);
  };

  const steps = [
    {
      selector: '',
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Community: Selling Network</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
            A cornerstone of the DealerGavel model is the ability to build your own dealership's selling network. Here you can keep track and manage the dealers that you sell to in your network.
            </Typography>
          </Box>
        </Box>
      )
    },
    {
      selector: '#sellNetwork2',
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Community: Selling Network</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          {/* prettier-ignore */}
          <Typography sx={{  marginTop: '10px', color: theme.palette.text.secondary }}>
            Manage and build your selling network here!
          </Typography>
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
              <span style={{ fontWeight: '900' }}>My Network:</span> View and manage the network you have created.
            </Typography>
          </Box>
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
              <span style={{ fontWeight: '900' }}>Incoming Request:</span> Accept or decline requests from other dealerships to join your selling network. Accepting a dealership allows you to sell to that dealership.
            </Typography>
          </Box>
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
              <span style={{ fontWeight: '900' }}>Outgoing Invites:</span> These are invites that you have sent to other dealerships that you want to sell to.
            </Typography>
          </Box>
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
              <span style={{ fontWeight: '900' }}>Tip:</span> If you have any incoming requests or outgoing invites, the tab will highlight in green.
            </Typography>
          </Box>
        </Box>
      )
    },
    /*  {
      selector: '#sellNetwork3',
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Community: Selling Network</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          
          <Typography sx={{  marginTop: '10px', color: theme.palette.text.secondary }}>
            These are dealerships that are part of your network. Your vehicles will be visible in their buying active auctions. 
          </Typography>
        </Box>
      )
    }, */
    {
      selector: '#sellNetwork4',
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Community: Selling Network</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          {/* prettier-ignore */}
          <Typography sx={{ marginTop: '10px', color: theme.palette.text.secondary }}>
            This menu will allow you to view more details about the Dealership.  A core functionality for your control is the ability to remove dealerships from your auction in turn they can no longer access your network.
          </Typography>
        </Box>
      )
    }
  ];

  const usersRef = collection(firestore, 'users') as CollectionReference<User>;
  const { add: addEmail } = useFirestoreByPath('emails');

  const [note, setNote] = useState<string>();
  const handleTextChange = (text: string) => {
    setNote(text);
  };

  const sendBulkEmail = async (values: any) => {
    setEmailButtonLoading(true);
    let usersToEmail: Email[] = [];
    await Promise.all(
      allDealerships.map(async (dealership: Dealership) => {
        const usersQuery = query(usersRef, where('currentDealershipId', '==', dealership.id));
        const userDocs = (await getDocs(usersQuery)).docs;
        userDocs.forEach(async (document: DocumentSnapshot) => {
          if (document.exists()) {
            if (document.data().currentDealershipRole !== 'captureRep') {
              const user = document.data();
              usersToEmail.push({
                email: user.email,
                name: user.firstName + ' ' + user.lastName
              });
            }
          }
        });
      })
    );
    usersToEmail.push({
      email: 'drew@dealergavel.com',
      name: 'Drew Derikx'
    });
    usersToEmail.push({
      email: 'marc@dealergavel.com',
      name: 'Mark Derikx'
    });
    console.log(usersToEmail);
    if (usersToEmail.length > 0) {
      const docRef = doc(firestore, `dealerships/${currentUser.currentDealershipId}`);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const currentDealership = { id: docSnap.id, ...docSnap.data() } as Dealership;
        const email = {
          dealershipName: currentDealership.dealershipName,
          users: usersToEmail,
          auctionDate: auctionDate ? auctionDate : '',
          auctionTime: auctionTime ? auctionTime : '',
          emailNote: note ? note : ''
        };
        await addEmail(email);
        enqueueSnackbar('Email Sent!', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center'
          }
        });
        setNote('');
        setAuctionDate(undefined);
        setAuctionTime(undefined);
      }
    } else {
      enqueueSnackbar('No Recipients Found!', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center'
        }
      });
    }
    setEmailButtonLoading(false);
    handleEmailDialogChange();
  };

  return (
    <>
      {!userDeleted && !userSuspended && !userDisabled && !buyerOnly ? (
        <Page title="Selling Network | DealerGavel">
          <Container
            maxWidth={themeStretch ? false : 'xl'}
            sx={{
              width: '100%',
              marginRight: 'auto'
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ mb: 0 }}
            >
              <Box sx={{ display: 'flex', mt: '0px', flexDirection: 'column' }}>
                <Typography
                  variant="h4"
                  component="h1"
                  paragraph
                  sx={{
                    marginBottom: '0px !important',
                    fontSize: '1.125rem !important',
                    color:
                      theme.palette.mode === 'dark'
                        ? theme.palette.common.white
                        : theme.palette.text.primary
                  }}
                >
                  Selling Network
                </Typography>
                <Typography component="p" paragraph sx={{ lineHeight: 1, marginBottom: '0px' }}>
                  Dealers you sell to
                </Typography>
              </Box>
              <Button
                onClick={handleEmailDialogChange}
                className={styles.inviteButton}
                variant="contained"
                sx={{ p: '7px 36px 7px 36px', marginTop: '-24px' }}
              >
                Email Your Buyers
              </Button>
            </Grid>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexWrap: 'wrap'
              }}
            >
              <StyledTabs
                id="sellNetwork2"
                value={tabValue}
                onChange={handleTabChange}
                aria-label="Dealer Network Tabs"
              >
                <Tab
                  sx={{
                    padding: '0px 10px',
                    borderRadius: '5px',
                    minHeight: '40px',
                    height: '40px'
                  }}
                  label={
                    <Typography sx={{ fontSize: '12px' }}>
                      MY NETWORK ({myNetwork && myNetwork.length})
                    </Typography>
                  }
                  {...a11yProps(0)}
                />
                <Tab
                  sx={{
                    backgroundColor:
                      incomingRequests && incomingRequests.length > 0
                        ? `${theme.palette.success.lighter} !important`
                        : '',
                    padding: '0px 10px',
                    borderRadius: '5px',
                    minHeight: '40px',
                    height: '40px'
                  }}
                  label={
                    <Typography sx={{ fontSize: '12px' }}>
                      INCOMING REQUESTS ({incomingRequests && incomingRequests.length})
                    </Typography>
                  }
                  {...a11yProps(1)}
                />
                <Tab
                  sx={{
                    backgroundColor:
                      outgoingRequests && outgoingRequests.length > 0
                        ? `${theme.palette.success.lighter} !important`
                        : '',
                    padding: '0px 10px',
                    borderRadius: '5px',
                    minHeight: '40px',
                    height: '40px'
                  }}
                  label={
                    <Typography sx={{ fontSize: '12px' }}>
                      OUTGOING INVITES ({outgoingRequests && outgoingRequests.length})
                    </Typography>
                  }
                  {...a11yProps(2)}
                />
              </StyledTabs>
              <Box className={styles.searchContainer}>
                <Paper component="form" className={styles.search}>
                  <DebounceInput
                    className={styles.searchInput}
                    value={searchValue}
                    placeholder="Search Dealerships"
                    minLength={2}
                    debounceTimeout={300}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                      handleSearchChange(e.target.value);
                    }}
                  />
                  <SearchIcon className={styles.searchIcon} sx={{ width: '20px' }} />
                </Paper>
              </Box>
            </Box>
            {/* MY NETWORK */}
            <TabPanel value={tabValue} index={0}>
              <Box className={styles.headerTextContainer}>
                {myNetwork && (
                  <Typography
                    component="p"
                    className={styles.headerText}
                    sx={{
                      textTransform: 'uppercase',
                      fontWeight: '700',
                      color: theme.palette.info.light
                    }}
                  >
                    {myNetwork.length} dealerships found
                  </Typography>
                )}
                <Box className={styles.controlsContainer}>
                  <Typography component="p" className={styles.headerText}>
                    Sort By:
                  </Typography>
                  <Select
                    label="Sort By"
                    value={order}
                    onChange={handleOrderByChange}
                    className={styles.inputText}
                    inputProps={{ 'aria-label': 'order dealerships' }}
                    input={<InputBase />}
                  >
                    <MenuItem value={'name'}>Name</MenuItem>
                    <MenuItem value={'location'}>Location</MenuItem>
                    <MenuItem value={'brands'}>Brands</MenuItem>
                  </Select>
                  {myNetwork && (
                    <TablePagination
                      className={styles.pagination}
                      count={myNetwork.length}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      rowsPerPageOptions={[5, 10, 25]}
                    />
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  width: '100%'
                }}
              >
                {myNetwork &&
                  myNetwork
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .sort((a: Dealership, b: Dealership) => {
                      if (order === 'name') {
                        return a.dealershipName.charAt(0) > b.dealershipName.charAt(0) ? 1 : -1;
                      } else if (order === 'location') {
                        return a.province.charAt(0) > b.province.charAt(0) ? 1 : -1;
                      } else {
                        return a.brands[0].name.charAt(0) > b.brands[0].name.charAt(0) ? 1 : -1;
                      }
                    })
                    .map((dealership: Dealership, index: number) => {
                      return (
                        <Box key={index} className={styles.dealerCardContainer} id="sellNetwork3">
                          <DealershipCard
                            handleMenuItemClick={(i: number) =>
                              handleMenuItemClick(i, dealership.id)
                            }
                            name={dealership.dealershipName}
                            address={dealership.province}
                            brands={dealership.brands}
                            country={dealership.country}
                          />
                        </Box>
                      );
                    })}
                {networkEmptyState && (
                  <EmptyStateMessage
                    boldMessage="You have no buyers in your network."
                    message="Build your selling network by inviting buyers through Find a Dealership or accepting incoming requests."
                  />
                )}
              </Box>
            </TabPanel>
            {/* INCOMING REQUESTS */}
            <TabPanel value={tabValue} index={1}>
              <Box className={styles.headerTextContainer}>
                {incomingRequests && (
                  <Typography
                    component="p"
                    className={styles.headerText}
                    sx={{
                      textTransform: 'uppercase',
                      fontWeight: '700',
                      color: theme.palette.info.light
                    }}
                  >
                    {incomingRequests.length} dealerships found
                  </Typography>
                )}
                <Box className={styles.controlsContainer}>
                  <Typography component="p" className={styles.headerText}>
                    Sort By:
                  </Typography>
                  <Select
                    label="Sort By"
                    value={incomingOrder}
                    onChange={handleIncomingOrderByChange}
                    className={styles.inputText}
                    inputProps={{ 'aria-label': 'order dealerships' }}
                    input={<InputBase />}
                  >
                    <MenuItem value={'name'}>Name</MenuItem>
                    <MenuItem value={'location'}>Location</MenuItem>
                    <MenuItem value={'brands'}>Brands</MenuItem>
                  </Select>
                  {incomingRequests && (
                    <TablePagination
                      className={styles.pagination}
                      count={incomingRequests.length}
                      page={incomingPage}
                      onPageChange={handleIncomingChangePage}
                      rowsPerPage={incomingRowsPerPage}
                      onRowsPerPageChange={handleIncomingChangeRowsPerPage}
                      rowsPerPageOptions={[5, 10, 25]}
                    />
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  width: '100%'
                }}
              >
                {incomingRequests &&
                  incomingRequests.map((request: DealerRequest, index: number) => {
                    return (
                      <Box key={index} className={styles.dealerCardContainer} id="sellNetwork3">
                        <DealershipCard
                          handleMenuItemClick={(i: number) =>
                            handleMenuItemClick(
                              i,
                              request.requestingDealershipId,
                              request.id,
                              request.emailType
                            )
                          }
                          name={request.requestingDealershipName}
                          address={request.requestingDealershipLocation}
                          brands={request.requestingDealershipBrands}
                          status={request.requestStatus}
                          country={
                            request.requestingDealershipCountry
                              ? request.requestingDealershipCountry
                              : undefined
                          }
                          showButtons={true}
                          request={request}
                        />
                      </Box>
                    );
                  })}
                {incomingEmptyState && (
                  <EmptyStateMessage
                    boldMessage="You have no incoming requests."
                    message="Buyers will send requests to join your auction."
                  />
                )}
              </Box>
            </TabPanel>
            {/* OUTGOING INVITES */}
            <TabPanel value={tabValue} index={2}>
              <Box className={styles.headerTextContainer}>
                {outgoingRequests && (
                  <Typography
                    component="p"
                    className={styles.headerText}
                    sx={{
                      textTransform: 'uppercase',
                      fontWeight: '700',
                      color: theme.palette.info.light
                    }}
                  >
                    {outgoingRequests.length} dealerships found
                  </Typography>
                )}
                <Box className={styles.controlsContainer}>
                  <Typography component="p" className={styles.headerText}>
                    Sort By:
                  </Typography>
                  <Select
                    label="Sort By"
                    value={outgoingOrder}
                    onChange={handleOutgoingOrderByChange}
                    className={styles.inputText}
                    inputProps={{ 'aria-label': 'order dealerships' }}
                    input={<InputBase />}
                  >
                    <MenuItem value={'name'}>Name</MenuItem>
                    <MenuItem value={'location'}>Location</MenuItem>
                    <MenuItem value={'brands'}>Brands</MenuItem>
                  </Select>
                  {outgoingRequests && (
                    <TablePagination
                      className={styles.pagination}
                      count={outgoingRequests.length}
                      page={outgoingPage}
                      onPageChange={handleOutgoingChangePage}
                      rowsPerPage={outgoingRowsPerPage}
                      onRowsPerPageChange={handleOutgoingChangeRowsPerPage}
                      rowsPerPageOptions={[5, 10, 25]}
                    />
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  width: '100%'
                }}
              >
                {outgoingRequests &&
                  outgoingRequests.map((request: any, index: number) => {
                    return (
                      <Box key={index} className={styles.dealerCardContainer} id="sellNetwork3">
                        {request.uiType === 'dealerRequest' ? (
                          <DealershipCard
                            name={request.email}
                            address={'Join your Dealer Network upon Registration'}
                            status={'pending'}
                            showStatus={true}
                            showButtons={false}
                            hideMore={true}
                            request={request}
                            inviteButton={true}
                            newDealer={true}
                            handleDealerMenuItemClick={(i: number) =>
                              handleDealerInviteItemClick(i, request.id)
                            }
                          />
                        ) : (
                          <DealershipCard
                            handleMenuItemClick={(i: number) =>
                              handleInviteItemClick(
                                i,
                                request.receivingDealershipId,
                                request.id,
                                request.emailType
                              )
                            }
                            name={request.receivingDealershipName}
                            address={request.receivingDealershipLocation}
                            brands={request.receivingDealershipBrands}
                            status={request.requestStatus}
                            country={
                              request.receivingDealershipCountry
                                ? request.receivingDealershipCountry
                                : undefined
                            }
                            showStatus={true}
                            hideMore={true}
                            inviteButton={true}
                            request={request}
                          />
                        )}
                      </Box>
                    );
                  })}
                {outgoingEmptyState && (
                  <EmptyStateMessage
                    boldMessage="You have no outgoing invites."
                    message="Send an invite through Find a Dealership for buyers to join your Selling Network."
                  />
                )}
              </Box>
            </TabPanel>
          </Container>
          <Drawer
            anchor="right"
            open={drawerOpen}
            PaperProps={{
              sx: {
                top: isMobile ? '64px' : '83px',
                width: '400px',
                bgcolor: 'background.default',
                height: isMobile ? 'calc(100vh - 64px)' : 'calc(100vh - 83px)'
              }
            }}
            variant="persistent"
          >
            {currentDealership !== null ? list() : null}
          </Drawer>
          {tabValue === 0 && myNetwork && myNetwork.length > 0 && (
            <Tour
              steps={steps}
              isOpen={tourOpen}
              onRequestClose={() => handleCloseTour()}
              className={styles.tourContainer}
            />
          )}
          {tabValue === 1 && incomingRequests && incomingRequests.length > 0 && (
            <Tour
              steps={steps}
              isOpen={tourOpen}
              onRequestClose={() => handleCloseTour()}
              className={styles.tourContainer}
            />
          )}
          {tabValue === 2 && outgoingRequests && outgoingRequests.length > 0 && (
            <Tour
              steps={steps}
              isOpen={tourOpen}
              onRequestClose={() => handleCloseTour()}
              className={styles.tourContainer}
            />
          )}
          <Dialog open={dialogOpen} onClose={handleDialogChange}>
            <Box>
              <DialogTitle>Permissions Error</DialogTitle>
              <CloseIcon
                onClick={handleDialogChange}
                sx={{
                  cursor: 'pointer',
                  position: 'absolute',
                  right: '15px',
                  top: '24px'
                }}
              />
            </Box>
            <DialogContent>
              You must be an admin to perform this action, please reach out to your admin.
            </DialogContent>
          </Dialog>
          <Dialog open={emailDialogOpen} onClose={handleEmailDialogChange}>
            {activeStep === 0 ? (
              <Box sx={{ padding: '0px 10px 10px 10px' }}>
                <Box sx={{ textAlign: 'center' }}>
                  <DialogTitle>Send an Email to your Buyers</DialogTitle>
                  <CloseIcon
                    onClick={handleEmailDialogChange}
                    sx={{
                      cursor: 'pointer',
                      position: 'absolute',
                      right: '15px',
                      top: '24px'
                    }}
                  />
                </Box>
                {/* prettier-ignore */}
                <Stepper activeStep={activeStep} sx={{ mt: 1 }} connector={<QontoConnector/>}>
                  {dialogSteps.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: {
                      optional?: React.ReactNode;
                    } = {};
                    if (isStepOptional(index)) {
                      labelProps.optional = (
                        <Typography variant="caption">Optional</Typography>
                      );
                    }
                    if (isStepSkipped(index)) {
                      stepProps.completed = false;
                    }
                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
                <Box sx={{ padding: '20px 40px 20px 40px', textAlign: 'center' }}>
                  <Typography sx={{ fontWeight: 'bold' }} variant="h4">
                    Step 1: Choose Type of Email to Send
                  </Typography>
                  {/* prettier-ignore */}
                  <Typography sx={{ mt: 2 }}>
                    You can choose to send your buyers a simple note, or you can inform them of the date and time of your next upcoming auction.
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                      mt: 4
                    }}
                  >
                    <Button size="large" variant="contained" onClick={() => handleNext('note')}>
                      Send Simple Note
                    </Button>
                    <Button size="large" variant="contained" onClick={() => handleNext('auction')}>
                      Notify Buyers of Upcoming Auction
                    </Button>
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box sx={{ padding: '0px 10px 10px 10px', textAlign: 'center' }}>
                <Box>
                  <DialogTitle>Send an Email to your Buyers</DialogTitle>
                  <CloseIcon
                    onClick={handleEmailDialogChange}
                    sx={{
                      cursor: 'pointer',
                      position: 'absolute',
                      right: '15px',
                      top: '24px'
                    }}
                  />
                </Box>
                {/* prettier-ignore */}
                <Stepper activeStep={activeStep} sx={{ mt: 1 }} connector={<QontoConnector/>}>
                  {dialogSteps.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: {
                      optional?: React.ReactNode;
                    } = {};
                    if (isStepOptional(index)) {
                      labelProps.optional = (
                        <Typography variant="caption">Optional</Typography>
                      );
                    }
                    if (isStepSkipped(index)) {
                      stepProps.completed = false;
                    }
                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
                <DialogContent>
                  {/* prettier-ignore */}
                  <Typography sx={{ fontWeight: 'bold' }} variant="h4">
                    Step 2: Enter Information
                  </Typography>
                  <Typography sx={{ mt: 2 }}>
                    {emailType === 'auction'
                      ? `Enter a Date, Time and Note to notifiy your buyers of important upcoming events. Select Date to enable Time.`
                      : `Enter the note that you would like your buyers to receive via email.`}
                  </Typography>
                  <Formik
                    initialValues={{
                      auctionDate: '',
                      auctionTime: ''
                    }}
                    onSubmit={async (values) => {
                      sendBulkEmail(values);
                    }}
                  >
                    {({ handleSubmit, setFieldValue }): JSX.Element => (
                      <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                        <Box className={styles.emailRow}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            {emailType === 'auction' && (
                              <>
                                <Box sx={boxStyle}>
                                  <VehicleCalendar
                                    auctionMinDate={Timestamp.now().toDate()}
                                    dropBoxValue={auctionDate ? auctionDate.toDate() : undefined}
                                    caption={'Auction Start Date'}
                                    updateDropValue={(v) => {
                                      if (v) {
                                        const date = Timestamp.fromDate(v);
                                        setAuctionDate(date);
                                        setFieldValue('auctionDate', date);
                                      }
                                    }}
                                  />
                                </Box>
                                <Box sx={boxStyle}>
                                  <VehicleTime
                                    auctionMinTime={
                                      moment(auctionDate && auctionDate.toDate()).isSameOrBefore(
                                        Timestamp.now().toDate(),
                                        'day'
                                      )
                                        ? moment(Timestamp.now().toDate())
                                            .subtract(5, 'minutes')
                                            .toDate()
                                        : undefined
                                    }
                                    dropBoxValue={auctionTime ? auctionTime.toDate() : undefined}
                                    caption={'Auction Start Time'}
                                    isDisabled={!auctionDate}
                                    updateDropValue={(v) => {
                                      if (v) {
                                        const date = Timestamp.fromDate(v);
                                        setAuctionTime(date);
                                        setFieldValue('auctionTime', date);
                                      }
                                    }}
                                  />
                                </Box>
                              </>
                            )}
                          </LocalizationProvider>
                          <TextField
                            sx={{
                              mt: '15px',
                              width: '350px',
                              border: '1px rgb(163, 167, 187) solid',
                              borderRadius: '10px'
                            }}
                            fullWidth
                            multiline
                            minRows={2}
                            maxRows={4}
                            placeholder="Leave a brief note for your buyers..."
                            onChange={(e) => handleTextChange(e.target.value)}
                          />
                        </Box>
                        <Divider
                          sx={{
                            mt: 2,
                            mb: 2,
                            borderColor:
                              theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
                          }}
                        />
                        <Box
                          className={styles.buttonRow}
                          sx={{
                            justifyContent: 'space-between !important'
                          }}
                        >
                          <Button
                            color="inherit"
                            size="large"
                            variant="contained"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                          >
                            Back
                          </Button>
                          <LoadingButton
                            disabled={
                              emailType === 'auction'
                                ? !auctionDate || !auctionTime || !note || note === ''
                                : !note || note === ''
                            }
                            size="large"
                            type="submit"
                            variant="contained"
                            loading={emailButtonLoading}
                          >
                            Send Email
                          </LoadingButton>
                        </Box>
                      </form>
                    )}
                  </Formik>
                </DialogContent>
              </Box>
            )}
          </Dialog>
        </Page>
      ) : (
        <>
          {userDeleted && (
            <Alert severity="error">
              <AlertTitle>Permission Denied</AlertTitle>
              Your account has been disabled.
            </Alert>
          )}
          {userSuspended && (
            <>
              <Alert severity="error">
                <AlertTitle>Thank you for joining DealerGavel</AlertTitle>
                Your account information is being reviewed by DealerGavel team, and will be
                &nbsp;authorized shortly. For now, you can return to the dashboard and await
                &nbsp;authorization.
              </Alert>
              <Box sx={{ mt: '20px' }}>
                <Button onClick={handleNavigate} variant="contained">
                  Back to Dashboard
                </Button>
              </Box>
            </>
          )}
          {userDisabled && (
            <>
              <Alert severity="error">
                <AlertTitle>Permission Denied</AlertTitle>
                Your account has been disabled.
              </Alert>
              <Box sx={{ mt: '20px' }}>
                <Button onClick={handleNavigate} variant="contained">
                  Back to Dashboard
                </Button>
              </Box>
            </>
          )}
          {buyerOnly && (
            <>
              <Alert severity="error">
                <AlertTitle>Permission Denied</AlertTitle>
                You do not have permissions for this page.
              </Alert>
              <Box sx={{ mt: '20px' }}>
                <Button onClick={handleNavigate} variant="contained">
                  Back to Dashboard
                </Button>
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
}
