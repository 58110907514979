import { PATH_SELLER } from '../../routes/paths';
// import TimelapseIcon from '@mui/icons-material/Timelapse';
// import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import CameraAltIcon from '@mui/icons-material/CameraAlt';

const sidebarConfigCaptureRep = [
  // DASHBOARD
  /*  {
    subheader: '',
    items: [{ title: 'Dashboard', path: PATH_DASHBOARD.root, icon: <TimelapseIcon /> }]
  }, */
  // SELLER
  /* {
    subheader: 'Sell',
    items: [
      {
        title: 'Pre Auction',
        path: PATH_SELLER.preAuction,
        icon: <DirectionsCarIcon />
      }
    ]
  }, */
  {
    subheader: 'Capture',
    items: [
      {
        title: 'Vehicle Capture',
        path: PATH_SELLER.vehicleCapture,
        icon: <CameraAltIcon />
      }
    ]
  }
];

export default sidebarConfigCaptureRep;
