import { AddAPhoto } from '@mui/icons-material';
import FileDropzone from 'components/shared/FileDropzone';
import { Box, Button, Divider, useTheme } from '@mui/material';
import { VehiclePhotos } from 'types/vehicleInfo';
import { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable
} from 'firebase/storage';
import { useFirestore, useStorage } from 'reactfire';
import { uniqueSuffixId } from './suffixUID';
import { doc, setDoc } from 'firebase/firestore';

type DialogPhotoProps = {
  isDamages?: boolean;
  vehiclePhoto: VehiclePhotos;
  imageFilename?: string;
  imageUrl?: string;
  vin: string;
  vehicleId: string;
  updVehiclePhoto: (vehiclePhoto: VehiclePhotos) => void;
  removePhoto: () => void;
};

export function VHRImageDialog({
  vin,
  vehiclePhoto,
  imageFilename,
  imageUrl,
  updVehiclePhoto,
  removePhoto,
  vehicleId
}: DialogPhotoProps) {
  const firestore = useFirestore();
  const theme = useTheme();
  const storage = useStorage();
  const { enqueueSnackbar } = useSnackbar();
  const [photoPreview, setPhotoPreview] = useState({ file: '' });
  const [photoFile, setPhotoFile] = useState<any>('');
  const [vehiclePhotoInfo, setVehiclePhotoInfo] = useState(vehiclePhoto || ({} as VehiclePhotos));
  const [image, setImage] = useState<string>();
  const [hasDeleted, setHasDeleted] = useState<boolean>(false);

  // const getDealership = async () => {
  //   const docRef = doc(firestore, `dealerships/${dealership.id}`);
  //   const docSnap = await getDoc(docRef);
  //   if (docSnap.exists()) {
  //     const currentDealership = { id: docSnap.id, ...docSnap.data() } as Dealership;
  //     setCurrentDealership(currentDealership);
  //   }
  // };

  const deleteFileFromStorage = useCallback(
    async (Path2File: any) => {
      const storage = getStorage();
      const deleteRef = ref(storage, Path2File);
      setImage('');
      setPhotoFile('');
      setPhotoPreview({ file: '' });
      setHasDeleted(true);
      const v = vehiclePhotoInfo;
      v.url = '';
      v.fileName = '';
      setVehiclePhotoInfo(v);
      if (imageUrl) {
        const docRef = doc(firestore, `vehicles/${vehicleId}`);
        await setDoc(
          docRef,
          {
            disclosures: {
              VHRImageFile: '',
              VHRImageUrl: ''
            }
          },
          { merge: true }
        );
        await deleteObject(deleteRef)
          .then(async () => {
            enqueueSnackbar('Photo Deleted', {
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right'
              }
            });
            removePhoto();
          })
          .catch((error) => {
            enqueueSnackbar('Error removing file', {
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right'
              }
            });
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enqueueSnackbar]
  );

  const saveFileToStorage = async (
    file: any,
    path2File: string
  ): Promise<{ url: string; fileName: string }> => {
    const storageRef = ref(storage, path2File);
    const fileName = uniqueSuffixId(`-${file.name}`);
    const imageRef = ref(storageRef, fileName);
    const uploadImage = uploadBytesResumable(imageRef, file);
    return new Promise((resolve, reject) => {
      uploadImage.on(
        'state_changed',
        () => {},
        () => {},
        async () => {
          const url = await getDownloadURL(imageRef);
          resolve({ url: url, fileName: fileName });
        }
      );
    });
  };

  const updatePhotoUrl = async (file: any) => {
    const { url, fileName } = await saveFileToStorage(file, `cars/${vin}/vhrReport`);
    return { url, fileName };
  };

  useEffect(() => {
    if (photoPreview.file) {
      setImage(photoPreview.file);
    } else if (imageUrl && imageUrl !== ' ' && !hasDeleted) {
      setImage(imageUrl);
    } else {
      setImage(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photoPreview, imageUrl]);

  const ShowPhoto = () => {
    // if (photoPreview.file) {
    //   setImage(photoPreview.file);
    // } else if (imageUrl) {
    //   setImage(imageUrl);
    // }

    return (
      <>
        {image && (
          <Box
            component="div"
            sx={{
              width: '400px',
              height: 'auto',
              m: 'auto',
              position: 'relative'
            }}
          >
            <img style={{ width: '400px', display: 'block' }} src={image} alt="aa" />
            <Box
              component="span"
              sx={{
                backgroundColor: theme.palette.primary.main,
                borderRadius: '8px',
                width: '40px',
                height: '40px',
                float: 'right',
                cursor: 'pointer',
                position: 'absolute',
                top: '5%',
                left: '88%'
              }}
            >
              <AddAPhoto sx={{ color: 'white', ml: 1, mt: 1 }} />
            </Box>
          </Box>
        )}
      </>
    );
  };

  return (
    <>
      <Box sx={{ mt: 3, width: 450 }}>
        <FileDropzone
          accept="image/jpeg,image/jpg,image/png,image/bmp"
          onUpload={(file: any) => {
            setPhotoFile(file);
            const v = vehiclePhotoInfo;
            v.url = URL.createObjectURL(file);
            v.fileName = file.fileName;
            setVehiclePhotoInfo(v);
            setImage(v.url);
            setPhotoPreview({
              file: URL.createObjectURL(file)
            });
          }}
        >
          <Box
            sx={{
              borderRadius: '5px',
              width: '450px'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <ShowPhoto />
              {!photoPreview.file && !image && (
                <>
                  <Box
                    sx={{
                      background: theme.palette.grey[200],
                      width: '200px',
                      height: '150px',
                      backgroundClip: 'content-box',
                      padding: '4px',
                      border: `1px dashed ${theme.palette.divider}`,
                      textAlign: 'center',
                      marginBottom: '8px'
                    }}
                  >
                    <AddAPhoto sx={{ marginTop: '30px', color: theme.palette.grey[600] }} />
                  </Box>
                  <Button
                    sx={{
                      marginTop: '65px',
                      position: 'absolute'
                    }}
                    variant="contained"
                  >
                    Choose Image
                  </Button>
                </>
              )}
            </Box>
          </Box>
        </FileDropzone>
      </Box>
      <Divider
        sx={{
          mt: 2,
          borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
        }}
      />
      <Box sx={{ mt: 2, mb: 1, display: 'flex', justifyContent: 'space-between' }}>
        <Button
          variant="outlined"
          sx={{
            backgroundColor: theme.palette.error.main,
            color: theme.palette.common.white,
            '&:hover': {
              backgroundColor: theme.palette.error.dark
            }
          }}
          onClick={async () => {
            deleteFileFromStorage(`cars/${vin}/vhrReport/${imageFilename}`);
          }}
        >
          Delete
        </Button>
        <Button
          disabled={!image}
          variant="contained"
          onClick={async () => {
            const { url, fileName } = await updatePhotoUrl(photoFile);
            if (fileName !== undefined) {
              vehiclePhotoInfo.url = url;
              vehiclePhotoInfo.fileName = fileName;
            }
            updVehiclePhoto(vehiclePhotoInfo);
          }}
        >
          Add Report Image
        </Button>
      </Box>
    </>
  );
}
