export const subtractSeconds = (date: Date, seconds: number, milliSeconds: number) => {
  const tempDate = new Date(date);
  tempDate.setSeconds(date.getSeconds() - seconds);
  const finalDate = new Date(tempDate);
  finalDate.setMilliseconds(tempDate.getMilliseconds() - milliSeconds);
  return finalDate;
};

export const getSeconds = (date: Date) => {
  const tempDate = new Date(date);
  const seconds = tempDate.getSeconds();
  return seconds;
};

export const getMilliSeconds = (date: Date) => {
  const tempDate = new Date(date);
  const milliSeconds = tempDate.getMilliseconds();
  return milliSeconds;
};
