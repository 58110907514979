import {
  addDoc,
  collection,
  CollectionReference,
  deleteDoc,
  doc,
  DocumentData,
  getDoc,
  query,
  QueryConstraint,
  setDoc,
  updateDoc
} from 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

// ----------------------------------------------------------------------
export const useFirestoreByPath = <T extends DocumentData = DocumentData>(
  collectionPath: string,
  ...queryConstraints: QueryConstraint[]
) => {
  const firestore = useFirestore();
  const ref = collection(firestore, collectionPath) as CollectionReference<T>;
  const collectionQuery = queryConstraints.length ? query(ref, ...queryConstraints) : ref;
  const { data, status } = useFirestoreCollectionData<T>(collectionQuery, { idField: 'id' });

  const getRef = (id: string) => {
    return doc(firestore, `${collectionPath}/${id}`);
  };

  const pathFor = (id: string) => {
    return `${collectionPath}/${id}`;
  };

  const get = (id: string) => {
    return getDoc(getRef(id)).then((i) => ({ ...i.data(), id: i.id })) as Promise<T>;
  };

  const remove = (id: string) => {
    return deleteDoc(getRef(id));
  };

  const add = async (data: Partial<T>) => {
    const newData = { ...data } as Partial<T>;
    delete newData.id; // unset Id;
    const newDocRef = await addDoc(ref, data as T);
    return getDoc(newDocRef).then((i) => ({ ...i.data(), id: i.id }));
  };

  const update = (id: string, data: Partial<T>) => {
    const newData = { ...data } as Partial<T>;
    delete newData.id; // unset Id;
    return updateDoc(getRef(id), newData as T);
  };

  const set = (id: string, data: Partial<T>) => {
    const newData = { ...data } as Partial<T>;
    delete newData.id; // unset Id;
    return setDoc(getRef(id), newData as T, { merge: true });
  };
  return { data, get, add, remove, update, set, pathFor, status };
};
