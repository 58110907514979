import {
  CollectionReference,
  DocumentSnapshot,
  collection,
  getDocs,
  getFirestore,
  query,
  startAfter,
  startAt
} from 'firebase/firestore';
import { app } from '../index';
import { VehicleInfo } from 'types/vehicleInfo';
import { useFirestoreCollection } from 'reactfire';

export function useVehicleBatch(
  cursor: number,
  constraints: any[],
  lastDoc?: DocumentSnapshot,
  isBackPage?: boolean,
  firstDoc?: DocumentSnapshot
) {
  const db = getFirestore(app);
  const collectionRef = collection(db, 'vehicles') as CollectionReference<VehicleInfo>;

  if (cursor !== 0) {
    if (isBackPage) {
      constraints.push(startAt(firstDoc));
    } else {
      constraints.push(startAfter(lastDoc));
    }
  }

  const vehicleQuery = query(collectionRef, ...constraints);
  return useFirestoreCollection(vehicleQuery, { idField: 'id' });
}

export async function useVehicleCollection(constraints: any[]) {
  const db = getFirestore(app);
  const collectionRef = collection(db, 'vehicles') as CollectionReference<VehicleInfo>;
  const vehicleQuery = query(collectionRef, ...constraints);
  const collectionSize = (await getDocs(vehicleQuery)).size;
  return collectionSize;
}
