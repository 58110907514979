import {
  Box,
  Button,
  ButtonGroup,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent
} from '@mui/material';
import { useState, useRef, useEffect } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import LaunchSoonContent from './LaunchSoonContent';
import ActiveContent from './ActiveContent';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { useFirestore } from 'reactfire';
import { VehicleInfo } from 'types/vehicleInfo';
import useAuth from 'hooks/useAuth';
import { Dealership } from 'types/dealership';
import { User } from 'constants/user';

const options = ['Parked', 'Launching Soon', 'Active'];
interface Props {
  vehicle: VehicleInfo;
  curUser?: User;
}

export default function LaunchingButton({ vehicle, curUser }: Props) {
  const theme = useTheme();
  const { currentUser } = useAuth();
  const firestore = useFirestore();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [uiAuctionStatus, setUIAuctionStatus] = useState<string>();
  const [paymentMethods, setPaymentMethods] = useState<any[]>();
  const [adminOmvicDialogOpen, setAdminOmvicDialogOpen] = useState<boolean>(false);

  const getCurrentDealership = async () => {
    const userDealershipRef = doc(firestore, `dealerships/${currentUser.currentDealershipId}`);
    const userDealerSnap = await getDoc(userDealershipRef);
    if (userDealerSnap.exists()) {
      const data = userDealerSnap.data();
      const currentDealership = { ...data, id: userDealerSnap.id } as Dealership;
      if (currentDealership.paymentMethods && currentDealership.paymentMethods.length > 0) {
        const tempPaymentMethods: any[] = [];
        currentDealership.paymentMethods.forEach((paymentMethod: any) => {
          tempPaymentMethods.push(paymentMethod);
        });
        setPaymentMethods(tempPaymentMethods);
      }
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getCurrentDealership();
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleToggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  const handleMenuItemClick = async (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
    setOpen(false);
    setUIAuctionStatus(options[index]);
    const auctionStatus = options[index].toLowerCase();
    const docRef = doc(firestore, `vehicles/${vehicle.id}`);
    if (index !== 0) {
      handleToggleModal();
      // if (curUser && curUser.omvicNumber) {
      //   handleToggleModal();
      // } else {
      //   handleCloseAdminOmvicDialog();
      // }
    } else {
      if (paymentMethods && paymentMethods.length > 0) {
        await updateDoc(docRef, { 'auction.auctionStatus': auctionStatus });
      } else {
        handleToggleModal();
      }
    }
  };

  const handleCloseAdminOmvicDialog = () => {
    setAdminOmvicDialogOpen(!adminOmvicDialogOpen);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  const useStyles = makeStyles({
    primaryButton: {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: `${theme.palette.common.white} !important`,
      '&:disabled': {
        backgroundColor: `${theme.palette.primary.main} !important`,
        color: `${theme.palette.common.white} !important`
      }
    },
    secondaryButton: {
      backgroundColor: theme.palette.secondary.main,
      '&:disabled': {
        backgroundColor: `${theme.palette.secondary.main} !important`,
        color: `${theme.palette.common.white} !important`
      }
    }
  });

  const styles = useStyles();

  return (
    <Box>
      <ButtonGroup
        sx={{ boxShadow: 'none !Important' }}
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
      >
        <Button
          disabled={true}
          className={
            vehicle.auction.auctionStatus.toLowerCase() === 'parked'
              ? styles.primaryButton
              : styles.secondaryButton
          }
          // onClick={handleClick}
        >
          {vehicle.auction.auctionStatus}
        </Button>
        <Button
          size="small"
          className={
            vehicle.auction.auctionStatus.toLowerCase() === 'parked'
              ? styles.primaryButton
              : styles.secondaryButton
          }
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select vehicle status"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        style={{ zIndex: '9999' }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        // disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <Dialog open={isOpenModal} onClose={handleToggleModal}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '20px',
            position: 'relative',
            borderBottom: `1px solid ${theme.palette.divider}`
          }}
        >
          {/* prettier-ignore */}
          <Typography component="p" variant="h5">
            {uiAuctionStatus?.toLowerCase() === 'launching soon'
              ? 'Launching Soon'
              : (!paymentMethods || paymentMethods.length < 1)
              ? 'Payment Method Alert'
              : 'Active Auction'}
          </Typography>
          <CloseIcon
            onClick={handleToggleModal}
            sx={{
              position: 'absolute',
              right: '10px',
              cursor: 'pointer'
            }}
          />
        </Box>
        {/* prettier-ignore */}
        <Box>
          {uiAuctionStatus?.toLowerCase() === 'launching soon' && (paymentMethods && paymentMethods.length > 0) && (
            <LaunchSoonContent vehicleId={vehicle.id as string} handleClose={handleToggleModal} />
          )}
          {uiAuctionStatus?.toLowerCase() === 'active' && (paymentMethods && paymentMethods.length > 0) && (
            <ActiveContent
              vehicleId={vehicle.id as string}
              vehicle={vehicle}
              handleClose={handleToggleModal}
            />
          )}
          {(!paymentMethods || paymentMethods.length < 1) && (
            <Box sx={{ padding: '20px' }}>
              {/* prettier-ignore */}
              <Typography>You must create a payment method in order to do this. Payment methods can be created from the billing section in account management.</Typography>
            </Box>
          )}
        </Box>
      </Dialog>
      <Dialog open={adminOmvicDialogOpen} onClose={handleCloseAdminOmvicDialog}>
        <Box sx={{ textAlign: 'center' }}>
          <DialogTitle>Permission Error</DialogTitle>
          <CloseIcon
            onClick={handleCloseAdminOmvicDialog}
            sx={{
              cursor: 'pointer',
              position: 'absolute',
              right: '15px',
              top: '24px'
            }}
          />
        </Box>
        <DialogContent>
          {/* prettier-ignore */}
          <Typography>
            Your account is missing an Individual License/Registration Number (ex. OMVIC/MVDA/SAAQ etc).
            Go to Manage Account and select Account Information to update this.
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'flex-end',
              mt: '15px'
            }}
          >
            <Button size="large" variant="contained" onClick={handleCloseAdminOmvicDialog}>
              Ok, Got it!
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
