import { Grid, Avatar, Box, Typography, BoxProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FC } from 'react';
import ListItemStatusPill from './ListItemStatusPill';
import { VehicleInfo } from 'types/vehicleInfo';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

interface Props extends BoxProps {
  vehicle: VehicleInfo;
  isSellerName?: boolean;
  hasStatusPill?: boolean;
  isCaptureItem?: boolean;
  imageUrl?: string;
  minWidth?: string;
  isRating?: boolean;
}

const ListItemDetails: FC<Props> = ({
  vehicle,
  isSellerName,
  hasStatusPill,
  isCaptureItem,
  imageUrl,
  minWidth,
  isRating
}: Props) => {
  const theme = useTheme();

  return (
    <Grid
      item
      xs={5}
      sx={{
        display: 'flex',
        alignItems: 'center',
        minWidth: minWidth ? minWidth : '350px'
      }}
    >
      {imageUrl && (
        <LazyLoadImage
          alt="Vehicle"
          effect="blur"
          src={imageUrl}
          width={'120px'}
          height={'80px'}
          style={{ borderRadius: '10px', width: '120px', minWidth: '120px' }}
        />
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'center',
          padding: '0 5px'
        }}
      >
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 'bold',
            color:
              theme.palette.mode === 'dark'
                ? theme.palette.common.white
                : theme.palette.text.primary
          }}
        >
          {vehicle.details.year} {vehicle.details.make}&nbsp;
          {vehicle.details.model} {vehicle.details.trim}
        </Typography>
        {isCaptureItem ? (
          <Typography
            sx={{
              fontSize: '11px',
              fontWeight: '700',
              color: vehicle.details.vin ? theme.palette.primary.main : theme.palette.error.main,
              wordBreak: 'break-word'
            }}
          >
            VIN: {vehicle.details.vin ? vehicle.details.vin : 'No VIN'}
          </Typography>
        ) : (
          <>
            <Typography
              sx={{
                fontSize: '11px',
                fontWeight: '700',
                color: theme.palette.text.secondary,
                wordBreak: 'break-word'
              }}
            >
              VIN: {vehicle.details.vin ? vehicle.details.vin : 'No VIN'}
            </Typography>
            <Typography
              sx={{
                fontSize: '11px',
                fontWeight: '700',
                color: theme.palette.text.secondary,
                wordBreak: 'break-word',
                textTransform: 'uppercase'
              }}
            >
              COLOUR: {vehicle.details.extColor}
            </Typography>
          </>
        )}
        {!isSellerName && (
          <>
            {vehicle.details.mileage && vehicle.details.mileageType && vehicle.auction.sellerName && (
              <Typography sx={{ fontSize: '11px' }}>
                {vehicle.details.mileage} {vehicle.details.mileageType} |&nbsp;
                {vehicle.auction.sellerName}&nbsp;|&nbsp;
                {vehicle.auction.pickUpLocation
                  ? vehicle.auction.pickUpLocation.locationProvince
                  : 'Location not set'}
              </Typography>
            )}
          </>
        )}
        {isSellerName && (
          <>
            {vehicle.disclosures.odometerStatus &&
            vehicle.disclosures.odometerStatus.toLowerCase() === 'broken' ? (
              // prettier-ignore
              <Typography
                sx={{
                  fontSize: '11px',
                  fontWeight: '700',
                  color: theme.palette.text.secondary
                }}
              >
                {vehicle.disclosures.odometerReading} {vehicle.details.mileageType} | {vehicle.auction.sellerName}
              </Typography>
            ) : (
              // prettier-ignore
              <Typography
                sx={{
                  fontSize: '11px',
                  fontWeight: '700',
                  color: theme.palette.text.secondary
                }}
              >
                {vehicle.details.mileage} {vehicle.details.mileageType} | {vehicle.auction.sellerName}
              </Typography>
            )}
          </>
        )}
        {/* {isSellerName && (
          <Typography sx={{ fontSize: '11px' }}>{vehicle.auction.sellerName}</Typography>
        )} */}
        {hasStatusPill && (
          <ListItemStatusPill
            label={''}
            value={vehicle.auction.isInStock ? 'In Stock' : 'Appraisal'}
            color={vehicle.auction.isInStock ? theme.palette.success.main : '#FD7E14'}
          ></ListItemStatusPill>
        )}
      </Box>
    </Grid>
  );
};

export default ListItemDetails;
