import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { VehicleInfo } from 'types/vehicleInfo';
import moment from 'moment';

interface runTime {
  vehicle: VehicleInfo;
  sendRunTimeToParent: (runtime: any) => void;
}

const RunTimeCalc = ({ vehicle, sendRunTimeToParent }: runTime) => {
  const [runTime, setRunTime] = useState<any>();

  useEffect(() => {
    const timerId = setInterval(() => tick(), 1000);
    return () => clearInterval(timerId);
  });

  const runtimeToParent = (runtime: any) => {
    sendRunTimeToParent(runtime);
  };

  const tick = () => {
    const timezoneOffset = new Date().getTimezoneOffset();
    // prettier-ignore
    const UTCEndDateMoment = moment(vehicle.auction.endDate.toDate()).clone().add(timezoneOffset, 'minutes');
    // prettier-ignore
    const UTCEndTimeMoment = moment(vehicle.auction.endTime.toDate()).clone().add(timezoneOffset, 'minutes');
    const UTCendDate = UTCEndDateMoment.toDate();
    const UTCendTime = UTCEndTimeMoment.toDate();
    UTCendDate.setHours(UTCendTime.getHours());
    UTCendDate.setMinutes(UTCendTime.getMinutes());
    UTCendDate.setSeconds(UTCendTime.getSeconds());
    UTCendDate.setMilliseconds(UTCendTime.getMilliseconds());
    const now = moment().clone().add(timezoneOffset, 'minutes');
    const duration = moment.duration(moment(UTCendDate).diff(now));
    const daysRemaining = duration.days();
    const hoursRemaining = duration.hours();
    const minutesRemaining = duration.minutes();
    const secondsRemaining = duration.seconds().toString();
    const numberSecondsRemaining = duration.seconds();
    const runTime = `${daysRemaining}D ${hoursRemaining}H ${minutesRemaining}M ${secondsRemaining}S`;
    const timeRemaining = {
      days: daysRemaining,
      hours: hoursRemaining,
      minutes: minutesRemaining,
      seconds: numberSecondsRemaining
    };
    setRunTime(runTime);
    runtimeToParent(timeRemaining);
  };

  return (
    <Typography
      component="p"
      sx={{
        lineHeight: 1,
        color: 'white',
        fontSize: '12px',
        fontWeight: '700'
      }}
    >
      {runTime}
    </Typography>
  );
};

export default RunTimeCalc;
