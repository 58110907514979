import {
  Container,
  Typography,
  Grid,
  Button,
  Paper,
  IconButton,
  Avatar,
  Box,
  Drawer,
  MenuItem,
  Popover,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Divider,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import { useTheme } from '@mui/material/styles';
import { AuctionStatus, DrawerContent } from '../../components/auction';
import * as React from 'react';
import { useState, useEffect } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { DataGrid, GridColumns } from '@mui/x-data-grid';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { Link as RouterLink } from 'react-router-dom';
import useSettings from '../../hooks/useSettings';
import Page from '../../components/Page';
import { DebounceInput } from 'react-debounce-input';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import useAuth from 'hooks/useAuth';
import {
  collection,
  CollectionReference,
  doc,
  DocumentReference,
  DocumentSnapshot,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  query,
  startAfter,
  Timestamp,
  where
} from 'firebase/firestore';
import { fDateTimeSuffix } from 'components/shared/dateTimeFormat';
import { Dealership } from 'types/dealership';
import { useFirestoreByPath } from '../../hooks/useFirestoreByPath';
import { useSnackbar } from 'notistack';
import { User } from 'constants/user';
import { isMobile } from 'react-device-detect';
import InputMask from 'react-input-mask';

const MENU_OPTIONS = [
  { label: 'View Dealership', linkTo: '#' },
  { label: 'Accept Dealership', linkTo: '#' },
  { label: 'Disable Dealership', linkTo: '#' },
  { label: 'Disable Sell Permissions', linkTo: '#' },
  { label: 'Delete Dealership', linkTo: '#' }
];

const INVITE_OPTIONS = [
  { label: 'Resend Invite', linkTo: '#' },
  { label: 'Delete Invite', linkTo: '#' }
];

export default function ManageDealersPage() {
  const theme = useTheme();

  const useStyles = makeStyles({
    headerText: {
      fontSize: '14px',
      marginRight: '18px',
      marginLeft: '5px'
    },
    inputText: {
      fontSize: '14px'
    },
    dialogContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      position: 'relative'
    },
    emailRow: {
      marginTop: '10px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    inviteRow: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      marginTop: '20px'
    },
    inviteButton: {
      backgroundColor: theme.palette.primary.main
    },
    buttonRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      width: '100%'
    },
    toolbar: {
      margin: 1,
      width: '220px',
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      right: '380px',
      top: '-35px'
    },
    dropdownStyle: {
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: '12px',
      lineHeight: '18px',
      color: theme.palette.info.light
    },
    search: {
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.background.neutral : 'transparent !important',
      border: theme.palette.mode === 'dark' ? 'unset' : `1px solid ${theme.palette.divider}`,
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1
    },
    searchInput: {
      fontSize: '14px',
      width: '100%',
      height: '34px',
      margin: '0 10px',
      border: 'none',
      padding: '5px 10px',
      outline: 'none !important',
      marginLeft: 2,
      flex: 1,
      backgroundColor: 'transparent !important',
      color:
        theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.text.secondary,
      '&::placeholder': {
        color:
          theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.text.secondary,
        fontSize: '14px'
      }
    },
    searchIcon: {
      fill: theme.palette.primary.main,
      marginRight: '5px'
    },
    secondaryButton: {
      backgroundColor: theme.palette.grey[50],
      color: theme.palette.info.light,
      '&:hover': {
        backgroundColor: theme.palette.grey[100]
      }
    },
    subtitle: {
      fontWeight: 'bold'
    },
    subtitle2: {
      color: theme.palette.info.light,
      fontWeight: 'normal'
    },
    list: {
      '& li:first-child, & li:last-child': {
        '& button': {
          border: `1px solid ${theme.palette.grey[100]}`,
          color: theme.palette.info.light,
          width: '100px',
          '&:hover': {
            backgroundColor: theme.palette.grey[100]
          }
        }
      },
      '& li': {
        paddingLeft: 3,
        paddingRight: 3,
        '& button': {
          color: theme.palette.info.main,
          minWidth: '40px'
        }
      }
    },
    divider: {
      width: '93%'
    },
    gridDateTitle: {
      marginRight: '64px'
    },
    menuPopover: {
      boxShadow: '0 0 2px 0 rgba(139, 148, 156, 0.24),0 10px 20px -4px rgba(139, 148, 156, 0.24)',
      paddingTop: '20px',
      paddingBottom: '20px',
      '@global': {
        '.MuiMenuItem-root': {
          paddingBottom: '6px'
        },
        '.MuiMenuItem-root:last-child': {
          color: theme.palette.error.dark
        }
      }
    },
    invitePopover: {
      boxShadow: '0 0 2px 0 rgba(139, 148, 156, 0.24),0 10px 20px -4px rgba(139, 148, 156, 0.24)',
      paddingTop: '20px',
      paddingBottom: '20px',
      '@global': {
        '.MuiMenuItem-root': {
          paddingBottom: '6px'
        },
        '.MuiMenuItem-root:last-child': {
          color: theme.palette.error.dark
        }
      }
    },
    dataGrid: {
      '@global': {
        '.MuiDataGrid-selectedRowCount': {
          display: 'none'
        },
        '.MuiDataGrid-footerContainer': {
          position: 'absolute',
          top: '-53px',
          right: '0',
          borderTop: '0'
        },
        '.css-h4goux-MuiInputBase-root-MuiTablePagination-select': {
          marginRight: '0 !important'
        },
        '.MuiDataGrid-columnHeaders': {
          position: 'relative',
          color: theme.palette.info.light
        },
        '.MuiDataGrid-virtualScroller': {
          marginTop: '0 !important'
        },
        '.flexEndCell': {
          justifyContent: 'flex-end',
          padding: '0'
        },
        '.flexEndCellPopup': {
          justifyContent: 'flex-end',
          padding: '0',
          '& .MuiTouchRipple-root': {
            display: 'none'
          },
          '& .MuiButtonBase-root': {
            '&:hover': {
              backgroundColor: 'transparent !important'
            }
          }
        },
        '.MuiDataGrid-row': {
          minHeight: '65px !important',
          maxHeight: '65px !important'
        },
        '.MuiDataGrid-cell': {
          minHeight: '65px !important',
          maxHeight: '65px !important',
          borderBottom: '0'
        },
        '.imageCell': {
          padding: '0'
        }
      }
    },
    status: {
      fontWeight: '500',
      fontSize: '0.7rem',
      color: 'white',
      backgroundColor: 'grey',
      borderRadius: 8,
      padding: '3px 10px',
      display: 'inline-block'
    }
  });

  const styles = useStyles();
  const { themeStretch } = useSettings();
  const { enqueueSnackbar } = useSnackbar();
  const firestore = useFirestore();
  const { currentUser, user } = useAuth();
  const userId = user?.uid ? user.uid : '';
  const phoneRegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

  const {
    data: dealerships,
    update: updateDealership,
    remove: removeDealership
  } = useFirestoreByPath<Dealership>('dealerships');

  const { update: updateUser } = useFirestoreByPath<User>('users');
  const usersRef = collection(firestore, 'users') as CollectionReference<User>;
  const usersQuery = query(usersRef);
  const { data: allUsers } = useFirestoreCollectionData(usersQuery, { idField: 'id' });

  const invitePath = `users/${user?.uid}/invites`;
  const { data: allInvites } = useFirestoreByPath(invitePath);
  const {
    data: invites,
    add: addInvite,
    update: updateInvite,
    remove: removeInvite
  } = useFirestoreByPath<any>(invitePath, where('inviteType', '==', 'dealership'));

  const list = (data: Dealership) => (
    <Box sx={{ width: 400 }} role="presentation">
      <DrawerContent
        dealership={data}
        logo={data.logoUrl ? data.logoUrl : undefined}
        onClose={closeDrawer}
      />
    </Box>
  );

  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [rows, setRows] = useState<Dealership[]>([]);
  const [dealershipId, setDealershipId] = useState<string>();
  const [filteredRows, setFilteredRows] = useState<Dealership[]>([]);
  const [pageSize, setPageSize] = useState(25);
  const [, setSearchValue] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const [anchorPopup, setAnchorPopup] = useState<HTMLButtonElement | null>(null);
  const [popupOpened, setPopupOpened] = useState(false);
  const [userExistsDialogOpen, setUserExistsDialogOpen] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [userDealershipName, setUserDealershipName] = useState<string>();
  const [isExistingInvite, setIsExistingInvite] = useState<boolean>(false);
  const [selectedDealershipId, setSelectedDealershipId] = useState<string>(
    currentUser.currentDealershipId
  );

  const open = Boolean(anchorPopup);
  const id = open ? 'simple-popover' : undefined;

  const [, setInviteAnchorEl] = useState(false);
  const [inviteAnchorPopup, setInviteAnchorPopup] = useState<HTMLButtonElement | null>(null);
  const [invitePopupOpened, setInvitePopupOpened] = useState(false);
  const inviteOpen = Boolean(inviteAnchorPopup);
  const inviteId = inviteOpen ? 'invite-popover' : undefined;
  const [invId, setInvId] = useState<string>(); // id to resend invites

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setAnchorEl(false);
    }
    return () => {
      isMounted = false;
    };
  }, [popupOpened]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setInviteAnchorEl(false);
    }
    return () => {
      isMounted = false;
    };
  }, [invitePopupOpened]);

  const sendInvite = async (firstName: string, lastName: string, email: string, phone: string) => {
    // const userDocRef = doc(firestore, `users/${user?.uid}`) as DocumentReference<User>;
    // const userDoc = await getDoc(userDocRef);
    // if (userDoc.exists()) {
    //   const user = { ...userDoc.data(), id: userDoc.id } as User;
    //   const docRef = doc(firestore, `dealerships/${user.currentDealershipId}`);
    //   const docSnap = await getDoc(docRef);
    //   if (docSnap.exists()) {
    //     const userDealership = { id: docSnap.id, ...docSnap.data() } as Dealership;
    //     setUserDealershipName(userDealership.dealershipName);
    //     setUserExistsDialogOpen(true);
    //     setIsExistingInvite(false);
    //     enqueueSnackbar('User already exists!', {
    //       variant: 'error',
    //       anchorOrigin: {
    //         vertical: 'bottom',
    //         horizontal: 'right'
    //       }
    //     });
    //     return;
    //   }
    // }

    for (let user of allUsers as User[]) {
      if (user.email.toLowerCase() === email.toLowerCase()) {
        const docRef = doc(firestore, `dealerships/${user.currentDealershipId}`);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const userDealership = { id: docSnap.id, ...docSnap.data() } as Dealership;
          setUserDealershipName(userDealership.dealershipName);
          setUserExistsDialogOpen(true);
          setIsExistingInvite(false);
          enqueueSnackbar('User already exists!', {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right'
            }
          });
          return;
        }
      }
    }

    const userDealershipRef = doc(firestore, `dealerships/${currentUser?.currentDealershipId}`);
    const userDealerSnap = await getDoc(userDealershipRef);
    let currentDealership: Dealership = {} as Dealership;
    if (userDealerSnap.exists()) {
      const data = userDealerSnap.data();
      currentDealership = { ...data, id: userDealerSnap.id } as Dealership;
    }

    const tmpInvites = {
      id: userId,
      firstName: firstName,
      lastName: lastName,
      email: email,
      inviteDateSent: Timestamp.now(),
      inviteStatus: 'invite sent',
      inviteName:
        currentUser.currentDealershipRole.toLowerCase() === 'superAdmin'
          ? 'DealerGavel Team'
          : `${currentUser.firstName} ${currentUser.lastName}`,
      inviteRole: 'admin',
      inviteDealershipId: 'NoId',
      status: 'incomplete',
      primaryContact: email,
      dealership: 'Not Set By User',
      inviteDealershipName: currentDealership.dealershipName || 'No Dealership',
      inviteType: 'dealership',
      role: 'admin',
      sendingDealershipId: currentUser.currentDealershipId,
      type: 'sent',
      networkType: 'noType',
      phone: phone,
      isExpired: false
    };

    if (allInvites) {
      const emailNotFound = allInvites.every((invite: any) => invite.email !== email);
      if (emailNotFound) {
        const { id } = await addInvite(tmpInvites);
        const updatedInvite = { ...tmpInvites, inviteId: id };
        await updateInvite(id, updatedInvite);
      } else {
        setUserExistsDialogOpen(true);
        setIsExistingInvite(true);
        enqueueSnackbar('User already exists!', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center'
          }
        });
      }
    } else {
      const { id } = await addInvite(tmpInvites);
      const updatedInvite = { ...tmpInvites, inviteId: id };
      await updateInvite(id, updatedInvite);
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      let tempRows: Dealership[] = [];
      if (dealerships) {
        const tempDealerships = [...dealerships];
        tempDealerships.forEach((row: any) => {
          const newRow = {
            ...row,
            id: row.id,
            customStatus: {
              id: row.id,
              status: row.status
            },
            customSelect: {
              id: row.id,
              status: row.status
            },
            statusLabel: {
              id: row.id,
              status: row.status
            }
          };
          tempRows.push(newRow);
        });
      }
      const tempInvites: Dealership[] = [];
      if (invites) {
        const tmpInvitesRows = [...invites];
        tmpInvitesRows.forEach((row: any) => {
          let rowLabel = row.status;
          console.log('row', row);
          if (row.isExpired) {
            rowLabel = 'Expired';
            console.log('row label', rowLabel);
          }
          const newRow = {
            ...row,
            id: row.id,
            dealershipName: row.primaryContact,
            customStatus: {
              id: row.id,
              status: row.status
            },
            customSelect: {
              id: row.id,
              status: row.status
            },
            statusLabel: {
              id: row.id,
              status: rowLabel
            }
          };
          tempInvites.push(newRow);
        });
        tempRows = [...tempRows, ...tempInvites];
      }
      setRows(tempRows);
      setFilteredRows(tempRows);
    }
    return () => {
      isMounted = false;
    };
  }, [invites, dealerships]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, id: string, status: string) => {
    const dealership = dealerships?.find((dealership: Dealership) => dealership.id === id);
    if (dealership) {
      setSelectedRow(dealership);
      setDealershipId(id);
      setAnchorPopup(event.currentTarget);
      setPopupOpened(true);
    } else {
      setInvId(id);
      setInviteAnchorPopup(event.currentTarget);
      setInvitePopupOpened(true);
    }
  };

  const handleMenuItemClick = async (index: number) => {
    setAnchorPopup(null);
    const usersRef = collection(firestore, 'users');
    const usersQuery = query(usersRef, where('currentDealershipId', '==', dealershipId));
    if (index === 0) {
      handleDrawer();
    } else if (index === 1) {
      await updateDealership(dealershipId as string, { status: 'registered' });
      const userDocs = (await getDocs(usersQuery)).docs;
      userDocs.forEach(async (document: DocumentSnapshot) => {
        if (document.exists()) {
          await updateUser(document.id as string, { status: 'registered' });
        }
      });
    } else if (index === 2) {
      await updateDealership(dealershipId as string, { status: 'disabled' });
      const userDocs = (await getDocs(usersQuery)).docs;
      userDocs.forEach(async (document: DocumentSnapshot) => {
        if (document.exists()) {
          await updateUser(document.id as string, { status: 'disabled' });
        }
      });
    } else if (index === 3) {
      await updateDealership(dealershipId as string, { status: 'buyerOnly' });
      const userDocs = (await getDocs(usersQuery)).docs;
      userDocs.forEach(async (document: DocumentSnapshot) => {
        if (document.exists()) {
          await updateUser(document.id as string, { status: 'buyerOnly' });
        }
      });
    } else {
      handleDeleteDialogClose();
    }
  };

  const handleInviteMenuItemClick = async (index: number) => {
    setInviteAnchorPopup(null);
    if (index === 0) {
      await updateInvite(invId as string, {
        inviteDateSent: Timestamp.now(),
        isExpired: false
      });
      enqueueSnackbar('New Email Sent', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        }
      });
    } else {
      await removeInvite(invId as string);
      enqueueSnackbar('Invite Deleted', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        }
      });
    }
  };

  const handleSelectDealership = async (event: any, id: string) => {
    await updateUser(user?.uid as string, { currentDealershipId: id });
    window.location.reload();
  };

  const handleClose = () => {
    setAnchorPopup(null);
    setPopupOpened(false);
  };

  const handleInviteClose = () => {
    setInviteAnchorPopup(null);
    setInvitePopupOpened(false);
  };

  const handleDrawer = () => {
    setAnchorEl(true);
  };

  const closeDrawer = () => {
    setAnchorEl(false);
  };

  const handleSearchChange = (searchValue: string) => {
    const fRows = rows.filter(
      (row: any) =>
        row.dealershipName.toLowerCase().includes(searchValue.toLowerCase()) ||
        row.status.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredRows(fRows);
  };

  const handleDialogChange = () => {
    setDialogOpen(!dialogOpen);
  };

  const handleUserExistsDialogClose = () => {
    setUserExistsDialogOpen(!userExistsDialogOpen);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(!deleteDialogOpen);
  };

  const handleDeleteDealership = async () => {
    const usersRef = collection(firestore, 'users') as CollectionReference<User>;
    const usersQuery = query(usersRef, where('currentDealershipId', '==', dealershipId));
    const userDocs = (await getDocs(usersQuery)).docs;
    userDocs.forEach(async (document: DocumentSnapshot) => {
      if (document.exists()) {
        await updateUser(document.id as string, { status: 'deleted' });
      }
    });
    await removeDealership(dealershipId as string);
    handleDeleteDialogClose();
  };

  const handleDealershipChange = async (event: SelectChangeEvent) => {
    await updateUser(user?.uid as string, { currentDealershipId: event.target.value });
    window.location.reload();
  };

  const columns: GridColumns = [
    {
      field: 'logoUrl',
      headerName: 'Dealership Logo',
      sortable: false,
      cellClassName: 'imageCell',
      renderCell: (params) => (
        <Avatar
          alt="dealership logo"
          src={params.value}
          variant="square"
          sx={{
            width: '100px',
            height: '60px',
            borderRadius: '5px'
          }}
        />
      ),
      flex: 0.8,
      minWidth: 120
    },
    {
      field: 'dealershipName',
      headerName: 'Dealership Name',
      renderCell: (params) => (
        <Grid>
          <Typography
            variant="subtitle2"
            component="h6"
            className={styles.subtitle}
            sx={{ textTransform: 'capitalize' }}
          >
            {params.value}
          </Typography>
        </Grid>
      ),
      flex: 3,
      minWidth: 250
    },
    {
      field: 'customSelect',
      headerName: 'Select Dealership',
      sortable: false,
      renderCell: (params) => (
        <Grid>
          {params && params.value.status && params.value.status.toLowerCase() !== 'incomplete' && (
            <Button
              disabled={
                selectedDealershipId &&
                currentUser &&
                params.value.id === currentUser.currentDealershipId
                  ? true
                  : false
              }
              variant="contained"
              onClick={(event) => handleSelectDealership(event, params.value.id)}
            >
              {selectedDealershipId &&
              currentUser &&
              params.value.id === currentUser.currentDealershipId
                ? 'Selected'
                : 'Select'}
            </Button>
          )}
        </Grid>
      ),
      flex: 1,
      minWidth: 100
    },
    {
      field: 'inviteDateSent',
      headerName: 'Date Sent',
      renderCell: (params) => (
        <Grid className={styles.gridDateTitle}>
          <Typography className={styles.subtitle} variant="subtitle2" component="h6">
            {params.value ? fDateTimeSuffix(params.value.toDate()) : ''}
          </Typography>
        </Grid>
      ),
      flex: 1,
      minWidth: 180
    },
    {
      field: 'inviteDateAccepted',
      headerName: 'Date Accepted',
      renderCell: (params) => (
        <Grid>
          <Typography className={styles.subtitle} variant="subtitle2" component="h6">
            {params.value ? fDateTimeSuffix(params.value.toDate()) : 'Not Yet Accepted'}
          </Typography>
        </Grid>
      ),
      flex: 1,
      minWidth: 180
    },
    {
      field: 'statusLabel',
      headerName: 'Status',
      cellClassName: 'flexEndCell',
      headerAlign: 'right',
      renderCell: (params) => (
        <Grid>
          <AuctionStatus status={params.value.status} />
        </Grid>
      ),
      flex: 1,
      minWidth: 120
    },
    {
      field: 'customStatus',
      headerName: 'Actions',
      cellClassName: 'flexEndCellPopup',
      headerAlign: 'right',
      sortable: false,
      renderCell: (params) => (
        <Grid sx={{ marginRight: '15px' }}>
          <IconButton
            aria-describedby={id}
            onClick={(event) => handleClick(event, params.value.id, params.value.status)}
            sx={{ p: 0 }}
          >
            <MoreVertIcon />
          </IconButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorPopup}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            sx={{ width: 220 }}
            PaperProps={{ classes: { root: styles.menuPopover } }}
          >
            {MENU_OPTIONS.map((option, index) => (
              <MenuItem
                key={option.label}
                to={option.linkTo}
                component={RouterLink}
                onClick={() => handleMenuItemClick(index)}
                sx={{ typography: 'body2', py: 1, px: 2.5 }}
              >
                {option.label}
              </MenuItem>
            ))}
          </Popover>
          <Popover
            id={inviteId}
            open={inviteOpen}
            anchorEl={inviteAnchorPopup}
            onClose={handleInviteClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            sx={{ width: 220 }}
            PaperProps={{ classes: { root: styles.invitePopover } }}
          >
            {INVITE_OPTIONS.map((option, index) => (
              <MenuItem
                key={option.label}
                to={option.linkTo}
                component={RouterLink}
                onClick={() => handleInviteMenuItemClick(index)}
                sx={{ typography: 'body2', py: 1, px: 2.5 }}
              >
                {option.label}
              </MenuItem>
            ))}
          </Popover>
        </Grid>
      ),
      flex: 1
    }
  ];

  return (
    <Page title="Manage Dealerships | DealerGavel">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 2.7 }}
        >
          <Typography
            variant="h4"
            component="h1"
            paragraph
            sx={{
              fontSize: '1rem !important',
              color:
                theme.palette.mode === 'dark'
                  ? theme.palette.common.white
                  : theme.palette.text.primary
            }}
          >
            Manage Dealerships
          </Typography>
          {/* <FormControl sx={{ width: '200px' }}>
            <InputLabel id="select-dealership">Select Dealership</InputLabel>
            <Select
              labelId="select-dealership"
              label="Select Dealership"
              value={selectedDealershipId}
              onChange={handleDealershipChange}
            >
              {dealerships &&
                dealerships.map((option: any, index: number) => {
                  return (
                    <MenuItem key={index} value={option.id}>
                      {option.dealershipName}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl> */}
          <Button
            onClick={handleDialogChange}
            className={styles.inviteButton}
            variant="contained"
            sx={{ p: '7px 36px 7px 36px' }}
          >
            Send an Invite
          </Button>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 4 }}
        >
          <Paper component="form" className={styles.search}>
            <DebounceInput
              className={styles.searchInput}
              placeholder="Search Dealerships"
              minLength={2}
              debounceTimeout={300}
              onChange={(e) => {
                setSearchValue(e.target.value);
                handleSearchChange(e.target.value);
              }}
            />
            <SearchIcon className={styles.searchIcon} sx={{ width: '20px' }} />
          </Paper>
        </Grid>
        <Grid container>
          <Typography
            component="p"
            className={styles.headerText}
            sx={{
              textTransform: 'uppercase',
              fontWeight: '700',
              color: theme.palette.info.light
            }}
          >
            DEALERSHIPS ({filteredRows.length})
          </Typography>
          <Grid xs={12} sx={{ height: '60vh', marginTop: '10px' }}>
            <DataGrid
              initialState={{
                sorting: {
                  sortModel: [
                    {
                      field: 'dealershipName',
                      sort: 'asc'
                    }
                  ]
                }
              }}
              rows={filteredRows}
              columns={columns}
              pageSize={pageSize}
              pagination
              disableColumnMenu
              // onPageChange={() => nextDealerships()}
              onPageSizeChange={(newPage) => setPageSize(newPage)}
              rowsPerPageOptions={[5, 10, 25]}
              disableSelectionOnClick={true}
              className={styles.dataGrid}
              components={{
                ColumnSortedAscendingIcon: UnfoldMoreIcon,
                ColumnSortedDescendingIcon: UnfoldMoreIcon
              }}
              componentsProps={{
                pagination: {
                  // nextIconButtonProps: {
                  //   disabled: false
                  // },
                  SelectProps: {
                    MenuProps: {}
                  }
                }
              }}
            ></DataGrid>
          </Grid>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              flexDirection: 'row',
              justifyContent: 'flex-end'
            }}
          >
            <Typography sx={{ fontSize: '13px', color: theme.palette.primary.main }}>
              Scroll for More
            </Typography>
          </Box>
        </Grid>
        <Drawer
          anchor="right"
          open={anchorEl}
          PaperProps={{
            sx: {
              top: isMobile ? '64px' : '83px',
              width: '400px',
              bgcolor: 'background.default',
              height: isMobile ? 'calc(100vh - 64px)' : 'calc(100vh - 83px)'
            }
          }}
          variant="persistent"
        >
          {selectedRow !== null ? list(selectedRow as Dealership) : null}
        </Drawer>
      </Container>
      <Dialog open={dialogOpen} onClose={handleDialogChange}>
        <Box className={styles.dialogContainer}>
          <DialogTitle>Invite Dealership</DialogTitle>
          <CloseIcon
            onClick={handleDialogChange}
            sx={{
              cursor: 'pointer',
              position: 'absolute',
              right: '15px',
              top: '24px'
            }}
          />
        </Box>
        <DialogContent>
          <Formik
            initialValues={{ firstName: '', lastName: '', email: '', phone: '' }}
            validationSchema={Yup.object().shape({
              firstName: Yup.string()
                .required('First Name is required')
                .matches(/^[a-zA-Z0-9 ]+$/, 'Cannot contain special characters'),
              lastName: Yup.string()
                .required('Last Name is required')
                .matches(/^[a-zA-Z0-9 ]+$/, 'Cannot contain special characters'),
              email: Yup.string()
                .required('Email is required')
                .matches(
                  /^[a-zA-Z0-9.!$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                  'Email must be a valid email address'
                ),
              phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid')
              // .required('Phone is required')
            })}
            onSubmit={async (values, { setErrors, setSubmitting }) => {
              sendInvite(values.firstName, values.lastName, values.email, values.phone);
              handleDialogChange();
            }}
          >
            {({
              getFieldProps,
              isSubmitting,
              handleSubmit,
              handleChange,
              handleBlur,
              isValid,
              touched,
              errors,
              dirty,
              values
            }): JSX.Element => (
              <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                <Typography variant="h6">Primary Contact</Typography>
                <Box className={styles.emailRow}>
                  <TextField
                    label="Enter First Name"
                    type="text"
                    sx={{ minWidth: '350px', marginTop: '10px' }}
                    {...getFieldProps('firstName')}
                    error={Boolean(touched.firstName && errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
                  ></TextField>
                  <TextField
                    label="Enter Last Name"
                    type="text"
                    sx={{ minWidth: '350px', marginTop: '10px' }}
                    {...getFieldProps('lastName')}
                    error={Boolean(touched.lastName && errors.lastName)}
                    helperText={touched.lastName && errors.lastName}
                  ></TextField>
                  <TextField
                    label="Enter Email"
                    type="text"
                    sx={{ minWidth: '350px', marginTop: '10px' }}
                    {...getFieldProps('email')}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  ></TextField>
                  <InputMask
                    mask="(999) 999-9999"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}
                  >
                    {() => (
                      <TextField
                        sx={{ minWidth: '350px', marginTop: '10px', marginBottom: '40px' }}
                        label="Phone Number"
                        {...getFieldProps('phone')}
                        error={Boolean(touched.phone && errors.phone)}
                        helperText={touched.phone && errors.phone}
                      />
                    )}
                  </InputMask>
                </Box>
                <Box className={styles.buttonRow}>
                  <LoadingButton size="large" type="submit" variant="contained">
                    Send Invite
                  </LoadingButton>
                </Box>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      <Dialog open={userExistsDialogOpen} onClose={handleUserExistsDialogClose}>
        <DialogContent>
          <Box
            sx={{
              width: 500,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-evenly'
            }}
          >
            <Box
              sx={{
                width: '100%',
                mb: 2,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              <div></div>
              <Typography variant="h6">User Found</Typography>
              <CloseIcon
                sx={{ fontSize: '18px', cursor: 'pointer' }}
                onClick={handleUserExistsDialogClose}
              />
            </Box>
            <Divider
              sx={{
                width: '100%',
                borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
              }}
            />
            <Box
              sx={{
                width: '80%',
                mt: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center'
              }}
            >
              <Typography variant="h4"> This user already exists! </Typography>
              {isExistingInvite ? (
                <>
                  {/* prettier-ignore */}
                  <Typography sx={{ fontSize: '14px', mt: 1 }}>
                    The user you are trying to invite has already been invited to the platform.
                    If you are the one who invited them, you can <span style={{ fontWeight: 'bold' }}>Resend the Invite</span>
                    &nbsp;on the <span style={{ fontWeight: 'bold' }}>Find a Dealership</span> page.
                  </Typography>
                </>
              ) : (
                <>
                  {/* prettier-ignore */}
                  <Typography sx={{ fontSize: '14px', mt: 1 }}>
                    The user you are trying to invite belongs to the dealership <span style={{ fontWeight: 'bold' }}>{userDealershipName}</span>.
                    You can invite this dealership to your network by searching for <span style={{ fontWeight: 'bold' }}>{userDealershipName}</span>
                    &nbsp;on the <span style={{ fontWeight: 'bold' }}>Find a Dealership</span> page.
                  </Typography>
                </>
              )}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          <Button type="button" variant="contained" onClick={handleUserExistsDialogClose}>
            Ok, Got It!
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={deleteDialogOpen} onClose={handleDeleteDialogClose}>
        <DialogContent>
          <Box
            sx={{
              width: 500,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-evenly'
            }}
          >
            <Box
              sx={{
                width: '100%',
                mb: 2,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              <div></div>
              <Typography variant="h6">Warning</Typography>
              <CloseIcon
                sx={{ fontSize: '18px', cursor: 'pointer' }}
                onClick={handleDeleteDialogClose}
              />
            </Box>
            <Divider
              sx={{
                width: '100%',
                borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
              }}
            />
            <Box
              sx={{
                width: '80%',
                mt: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center'
              }}
            >
              <Typography variant="h4">You are about to delete a dealership!</Typography>
              {/* prettier-ignore */}
              <Typography sx={{ fontSize: '14px', mt: 1 }}>
                Deleting a dealership is <span style={{ fontWeight: 'bold' }}>permanent</span> and this will also permanently
                delete all <span style={{ fontWeight: 'bold' }}>users</span> and <span style={{ fontWeight: 'bold' }}>vehicles</span>
                &nbsp;that are associated with this dealership. Click Confirm Delete only if you are sure you want to do this.
              </Typography>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          <Button type="button" variant="contained" onClick={handleDeleteDealership}>
            Confirm Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
}
