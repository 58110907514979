import VehicleGallery from 'components/shared/VehicleGallery';
import VehicleDetails from 'components/shared/VehicleDetails';
import VehicleSummary from 'components/shared/VehicleSummary';
import VehicleActionsSell from 'components/seller/auctions/VehicleAuctionsSell';
import { VehicleInfo } from 'types/vehicleInfo';
import useAuth from 'hooks/useAuth';
import React, { FC, useState } from 'react';

interface Props {
  vehicle: VehicleInfo;
  handleExpand: (id: string, isExpanded: boolean) => void;
  handleFavourite: (id: string, isFavourite: boolean) => void;
  handleWatchlist: (id: string, isWatchlist: boolean) => void;
  handleOpenDrawer: (value: number, v: VehicleInfo) => void;
  mediaQuery: number;
}

const VehicleRowCommonSell: FC<Props> = React.memo(function VehicleRowCommonSell({
  vehicle,
  handleExpand,
  handleFavourite,
  handleWatchlist,
  handleOpenDrawer,
  mediaQuery
}) {
  const { user } = useAuth();
  const [hideVehicle, setHideVehicle] = useState<boolean>(false);

  const getRuntime = (runtime: any) => {
    if (
      runtime.days === 0 &&
      runtime.hours === 0 &&
      runtime.minutes === 0 &&
      runtime.seconds === 0 &&
      !vehicle.bidExtension
    ) {
      setHideVehicle(true);
    }
  };

  return (
    <>
      {!hideVehicle && (
        <>
          {vehicle.auction.isExpanded.includes(user?.uid) ? (
            <>
              <VehicleGallery
                isPreAuction={false}
                mediaQuery={mediaQuery}
                vehicle={vehicle}
                width={'33.3%'}
                handleExpand={handleExpand}
                handleFavourite={handleFavourite}
                sendRunTimeToParent={getRuntime}
              />
              <VehicleActionsSell
                vehicle={vehicle}
                mediaQuery={mediaQuery}
                handleWatchlist={handleWatchlist}
                width={'33.3%'}
              />
              <VehicleDetails
                mediaQuery={mediaQuery}
                isPreAuction={false}
                isExpanded={true}
                handleOpenDrawer={handleOpenDrawer}
                vehicle={vehicle}
                width={'33.3%'}
              />
            </>
          ) : (
            <>
              <VehicleGallery
                isPreAuction={false}
                vehicle={vehicle}
                mediaQuery={mediaQuery}
                width={'22.5%'}
                handleExpand={handleExpand}
                handleFavourite={handleFavourite}
                sendRunTimeToParent={getRuntime}
              />
              <VehicleSummary
                vehicle={vehicle}
                mediaQuery={mediaQuery}
                width={'27.5%'}
                handleWatchlist={handleWatchlist}
              />
              <VehicleActionsSell
                vehicle={vehicle}
                mediaQuery={mediaQuery}
                handleWatchlist={handleWatchlist}
                width={'22.5%'}
              />
              <VehicleDetails
                mediaQuery={mediaQuery}
                isPreAuction={false}
                isExpanded={false}
                handleOpenDrawer={handleOpenDrawer}
                vehicle={vehicle}
                width={'27.5%'}
              />
            </>
          )}
        </>
      )}
    </>
  );
});

export default VehicleRowCommonSell;
