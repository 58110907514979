import { useTheme } from '@mui/material/styles';
import { Box, Drawer } from '@mui/material';
import { MHidden } from 'components/@material-extend';

type Props = {
  isOpenSidebar: boolean;
  drawerContent: JSX.Element;
  onCloseSidebar: VoidFunction;
};

export default function SharedDrawer({ isOpenSidebar, drawerContent, onCloseSidebar }: Props) {
  const theme = useTheme();

  return (
    <Box sx={{ backgroundColor: theme.palette.common.white }}>
      <MHidden width="mdUp">
        <Drawer
          sx={{ zIndex: '999999' }}
          anchor={'right'}
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          ModalProps={{ keepMounted: true }}
          PaperProps={{ sx: { width: '80%', border: 'none' } }}
        >
          {drawerContent}
        </Drawer>
      </MHidden>
      <MHidden width="mdDown">
        <Drawer
          sx={{ zIndex: '999999' }}
          anchor={'right'}
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          ModalProps={{ keepMounted: true }}
          PaperProps={{ sx: { width: 500, border: 'none' } }}
        >
          {drawerContent}
        </Drawer>
      </MHidden>
    </Box>
  );
}
