// material
import {
  Button,
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  Drawer,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  FormHelperText,
  Divider,
  Alert,
  AlertTitle
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import useSettings from '../../hooks/useSettings';
import Page from '../../components/Page';
import { useState, useEffect } from 'react';
import { LoadingButton } from '@mui/lab';
import { DebounceInput } from 'react-debounce-input';
import { Formik } from 'formik';
import useAuth from 'hooks/useAuth';
import { Dealership } from 'types/dealership';
import {
  collection,
  CollectionReference,
  doc,
  DocumentReference,
  getDoc,
  onSnapshot,
  query,
  Timestamp,
  where
} from 'firebase/firestore';
import { useFirestore } from 'reactfire';
import * as Yup from 'yup';
import { useFirestoreByPath } from 'hooks/useFirestoreByPath';
import { User } from 'constants/user';
import Tour from 'reactour';
import { useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat';

type CurUser = {
  currentDealershipId: string;
  currentDealershipRole: string;
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  omvicNumber?: string;
  phone?: string;
  status: string;
};

const roles = [
  {
    role: 'Admin'
  },
  {
    role: 'Dealer Rep'
  },
  {
    role: 'Capture Rep'
  }
];

export default function ManageTeamsPage() {
  const { themeStretch } = useSettings();
  const theme = useTheme();

  const useStyles = makeStyles({
    tourContainer: {
      maxWidth: '500px !important',
      borderRadius: '10px !important'
    },
    drawerContainer: {
      width: '400px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: 'calc(100% - 83px)'
    },
    teamMemberRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      padding: '20px'
    },
    profileRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: '100%',
      padding: '15px',
      borderTop:
        theme.palette.mode === 'dark'
          ? `1px solid ${theme.palette.text.secondary}`
          : `1px solid ${theme.palette.divider}`,
      borderBottom:
        theme.palette.mode === 'dark'
          ? `1px solid ${theme.palette.text.secondary}`
          : `1px solid ${theme.palette.divider}`
    },
    profileInfo: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      marginLeft: '15px'
    },
    permissionRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      borderBottom:
        theme.palette.mode === 'dark'
          ? `1px solid ${theme.palette.text.secondary}`
          : `1px solid ${theme.palette.divider}`,
      padding: '30px 15px'
    },
    accountRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: '100%',
      borderTop:
        theme.palette.mode === 'dark'
          ? `1px solid ${theme.palette.text.secondary}`
          : `1px solid ${theme.palette.divider}`,
      padding: '30px 15px'
    },
    dialogContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      position: 'relative'
    },
    emailRow: {
      marginTop: '10px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    inviteRow: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      marginTop: '20px'
    },
    inviteButton: {
      backgroundColor: theme.palette.primary.main
    },
    buttonRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      width: '100%',
      marginTop: '60px'
    },
    search: {
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.background.neutral : 'transparent !important',
      border: theme.palette.mode === 'dark' ? 'unset' : `1px solid ${theme.palette.divider}`,
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: '60%'
    },
    searchInput: {
      fontSize: '14px',
      width: '100%',
      height: '34px',
      margin: '0 10px',
      border: 'none',
      padding: '5px 10px',
      outline: 'none !important',
      marginLeft: 2,
      flex: 1,
      backgroundColor: 'transparent !important',
      color:
        theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.text.secondary,
      '&::placeholder': {
        color:
          theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.text.secondary,
        fontSize: '14px'
      }
    },
    searchIcon: {
      fill: theme.palette.primary.main,
      marginRight: '5px'
    },
    secondaryButton: {
      backgroundColor: theme.palette.grey[50],
      color: theme.palette.info.light,
      '&:hover': {
        backgroundColor: theme.palette.grey[100]
      }
    },
    avatarCircle: {
      width: '85px',
      height: '85px',
      borderRadius: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '20px'
    },
    avatarInnerText: {
      fontSize: '25px',
      fontWeight: 'bold',
      color: theme.palette.common.white
    },
    status: {
      fontWeight: 'bold',
      fontSize: '0.75rem',
      // color: 'white',
      // backgroundColor: 'grey',
      borderRadius: 8,
      padding: '3px 10px',
      display: 'inline-block'
    }
  });

  const styles = useStyles();

  const CardStyle = styled('div')(({ theme }) => ({
    width: '180px',
    height: '230px',
    margin: '10px 20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    // padding: '50px 0px',
    borderRadius: '14px',
    border:
      theme.palette.mode === 'dark'
        ? `1px solid ${theme.palette.text.secondary}`
        : `1px solid ${theme.palette.divider}`,
    backgroundColor: 'transparent !important',
    cursor: 'pointer'
  }));

  const { enqueueSnackbar } = useSnackbar();
  const { currentUser, user, logout } = useAuth();
  const firestore = useFirestore();

  const [searchValue, setSearchValue] = useState('');
  const [anchorEl, setAnchorEl] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [userRole, setUserRole] = useState(roles[0].role);
  const [showContactSelect, setShowContactSelect] = useState<boolean>(false);
  const [primaryContactValue, setPrimaryContactValue] = useState<any>();

  const [inviteRole, setInviteRole] = useState('');
  const userId = user?.uid ? user.uid : '';
  const invitePath = `users/${userId}/invites`;

  const { data: allInvites } = useFirestoreByPath(invitePath);
  const { update: updateDealership } = useFirestoreByPath('dealerships');

  const {
    data: invites,
    update: updateInvites,
    add: addInvites,
    remove: removeInvite
  } = useFirestoreByPath(invitePath, where('inviteType', '==', 'team'));

  const {
    data: users,
    update: updateUser,
    remove: removeUser
  } = useFirestoreByPath<CurUser>(
    'users',
    where('currentDealershipId', '==', `${currentUser.currentDealershipId}`)
  );

  const { data: allUsers } = useFirestoreByPath<User>('users');

  const [inviteTeam, setInviteTeam] = useState<CurUser[]>([]);
  const [teamMembers, setTeamMembers] = useState<CurUser[]>([]);
  const [teamAdmins, setTeamAdmins] = useState<CurUser[]>([]);
  let [currentAvatar, setCurrentAvatar] = useState('');
  let [currentTeamMember, setCurrentTeamMember] = useState<CurUser>({} as CurUser);
  const [currentDealership, setCurrentDealership] = useState<Dealership>();
  const [currentMemberRole, setCurrentMemberRole] = useState<string>();

  const dealershipRef = collection(firestore, 'dealerships') as CollectionReference<Dealership>;

  const getDealership = () => {
    const dealershipQuery = query(
      dealershipRef,
      where(firebase.firestore.FieldPath.documentId(), '==', currentUser.currentDealershipId)
    );
    const unsubscribe = onSnapshot(dealershipQuery, (querySnapshot) => {
      const dealership = querySnapshot.docs.map(
        (doc) => ({ ...doc.data(), id: doc.id } as Dealership)
      );
      console.log('dealership =>', dealership[0]);
      setCurrentDealership(dealership[0]);
    });
    return unsubscribe;
  };

  useEffect(() => {
    const unsubscribe = getDealership();
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (users && currentTeamMember.email) {
      // prettier-ignore
      const admins = users.filter(
        (user: CurUser) => user.email !== currentTeamMember.email && user.status.toLowerCase() === 'registered' && (user.currentDealershipRole.toLowerCase() === 'admin' || user.currentDealershipRole.toLowerCase() === 'superadmin')
      );
      setTeamAdmins(admins);
    }
  }, [currentTeamMember, users]);

  useEffect(() => {
    if (invites) {
      const tmpTeamMember = invites.map((i) => {
        const t = {
          id: i.id,
          firstName: i.firstName,
          lastName: i.lastName,
          currentDealershipRole: i.inviteRole,
          currentDealershipId: i.inviteDealershipId,
          email: i.email,
          status: i.status
        } as any;
        return t;
      });
      const tt = [...tmpTeamMember];
      setInviteTeam(tt);
    }
  }, [invites]);

  useEffect(() => {
    if (!users) return;
    if (inviteTeam) {
      const team: any[] = [];
      inviteTeam.forEach((t: any) => {
        const user = users.find((u: any) => u.email.toLowerCase() === t.email.toLowerCase());
        if (!user) {
          team.push(t);
        }
      });
      if (team.length > 0) {
        const tmpVal = [...users, ...team];
        setTeamMembers(tmpVal);
      } else {
        const tmpVal = [...users];
        setTeamMembers(tmpVal);
      }
    } else {
      setTeamMembers(users);
    }
    //setCurrentTeamMember(users[0]);
  }, [inviteTeam, users]);

  const sendInvite = async (firstName: string, lastName: string, email: string, role: string) => {
    for (let tm of allUsers) {
      if (tm.email.toLowerCase() === email.toLowerCase()) {
        enqueueSnackbar('User already exists!', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center'
          }
        });
        return;
      }
    }

    const userDealershipRef = doc(firestore, `dealerships/${currentUser?.currentDealershipId}`);
    const userDealerSnap = await getDoc(userDealershipRef);
    let currentDealership: Dealership = {} as Dealership;
    if (userDealerSnap.exists()) {
      const data = userDealerSnap.data();
      currentDealership = { ...data, id: userDealerSnap.id } as Dealership;
    }
    const tmpInvites = {
      id: userId,
      firstName: firstName,
      lastName: lastName,
      email: email,
      inviteDateSent: Timestamp.now(),
      inviteStatus: 'invite sent',
      inviteName: currentUser ? `${currentUser.firstName} ${currentUser.lastName}` : ' ',
      inviteRole: role,
      status: 'incomplete',
      primaryContact: user ? user.email : '',
      dealership: currentDealership.dealershipName || 'Not Set By User',
      inviteDealershipId: currentUser.currentDealershipId,
      inviteDealershipName: currentDealership.dealershipName || 'No Dealership',
      inviteType: 'team',
      role: role,
      sendingDealershipId: 'NoId',
      type: 'sent',
      networkType: 'noType',
      isExpired: false
    };

    if (allInvites) {
      const emailNotFound = allInvites.every(
        (invite: any) => invite.email.toLowerCase() !== email.toLowerCase()
      );
      if (emailNotFound) {
        const { id } = await addInvites(tmpInvites);
        const updatedInvite = { ...tmpInvites, inviteId: id };
        await updateInvites(id, updatedInvite);
      } else {
        enqueueSnackbar('User already exists!', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center'
          }
        });
      }
    } else {
      const { id } = await addInvites(tmpInvites);
      const updatedInvite = { ...tmpInvites, inviteId: id };
      await updateInvites(id, updatedInvite);
    }
  };

  const handleDrawer = () => {
    setAnchorEl(true);
  };

  const closeDrawer = () => {
    setAnchorEl(false);
  };

  const handleDialogChange = () => {
    setDialogOpen(!dialogOpen);
  };

  const handleContactChange = (event: any) => {
    setPrimaryContactValue(event.target.value);
  };

  // const updatePrimaryContact = async (event: any) => {
  //   setUserRole(event.target.value);
  //   currentTeamMember = { ...currentTeamMember, currentDealershipRole: event.target.value };
  //   setCurrentTeamMember(currentTeamMember);
  //   await updateUser(currentTeamMember.id, { currentDealershipRole: event.target.value });
  //   closeDrawer();
  // };

  const confirmPrimaryContactChange = async () => {
    const primaryContact = users.find(
      (user: CurUser) => user.email.toLowerCase() === primaryContactValue.toLowerCase()
    );
    await updateDealership(currentUser.currentDealershipId as string, {
      primaryContact: primaryContact?.firstName + ' ' + primaryContact?.lastName,
      primaryContactEmail: primaryContact?.email,
      primaryContactOmvic: primaryContact?.omvicNumber ? primaryContact.omvicNumber : ''
    });
    await updateUser(currentTeamMember.id, { currentDealershipRole: userRole });
    closeDrawer();
    enqueueSnackbar('Primary Contact Updated!', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center'
      }
    });
  };

  const handleRoleChange = async (event: any) => {
    if (
      (currentUser.currentDealershipRole.toLowerCase() === 'admin' ||
        currentTeamMember.currentDealershipRole.toLowerCase() === 'admin') &&
      event.target.value.toLowerCase() !== 'admin'
    ) {
      let anotherAdmin;
      if (currentTeamMember.id === user?.uid) {
        anotherAdmin = users.find(
          (u: CurUser) => u.currentDealershipRole.toLowerCase() === 'admin' && u.id !== user?.uid
        );
      } else {
        anotherAdmin = users.find(
          (u: CurUser) =>
            u.currentDealershipRole.toLowerCase() === 'admin' && u.id !== currentTeamMember.id
        );
      }
      if (anotherAdmin) {
        if (currentTeamMember.email.toLowerCase() === currentDealership?.primaryContactEmail) {
          setUserRole(event.target.value);
          setShowContactSelect(true);
          setCurrentMemberRole(event.target.value);
        } else {
          setUserRole(event.target.value);
          currentTeamMember = { ...currentTeamMember, currentDealershipRole: event.target.value };
          setCurrentTeamMember(currentTeamMember);
          await updateUser(currentTeamMember.id, { currentDealershipRole: event.target.value });
          closeDrawer();
        }
      } else {
        enqueueSnackbar('You must promote another team member to Admin first', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center'
          }
        });
      }
    } else {
      setUserRole(event.target.value);
      currentTeamMember = { ...currentTeamMember, currentDealershipRole: event.target.value };
      setCurrentTeamMember(currentTeamMember);
      await updateUser(currentTeamMember.id, { currentDealershipRole: event.target.value });
      closeDrawer();
    }
  };

  const handleRemove = async (teamMember: CurUser) => {
    const userFound = users.find((u: CurUser) => u.id === teamMember.id);
    if (userFound) {
      if (userFound.currentDealershipRole.toLowerCase() === 'admin') {
        enqueueSnackbar('Cannot delete an Admin, demote user first', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center'
          }
        });
      } else {
        await removeUser(teamMember.id);
      }
    } else {
      const invite = invites.find(
        (invite: any) => invite.email.toLowerCase() === teamMember.email.toLowerCase()
      );
      if (invite) {
        await removeInvite(invite.id);
      }
    }
    closeDrawer();
    // if (userFound && userFound.currentDealershipRole.toLowerCase() === 'admin') {
    //   enqueueSnackbar('Cannot delete an Admin, demote user first', {
    //     variant: 'error',
    //     anchorOrigin: {
    //       vertical: 'bottom',
    //       horizontal: 'center'
    //     }
    //   });
    // } else {
    //   const invite = invites.find((invite: any) => invite.email === teamMember.email);
    //   if (invite) {
    //     await removeInvite(invite.id);
    //   }
    //   await removeUser(teamMember.id);
    // }
    // closeDrawer();
  };

  const handleUpdateInvite = async (currentTeamMember: any) => {
    await updateInvites(currentTeamMember.id, {
      inviteDateSent: Timestamp.now(),
      isExpired: false
    });
    enqueueSnackbar('New email sent', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right'
      }
    });
  };

  const [tourOpen, setTourOpen] = useState(false);
  const { update: updateTourUser } = useFirestoreByPath<User>('users');
  const [curUser, setCurUser] = useState<User>();
  const [userDisabled, setUserDisabled] = useState<boolean>(false);
  const [userSuspended, setUserSuspended] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/dashboard');
    // window.location.reload();
  };

  const [userDeleted, setUserDeleted] = useState<boolean>(false);
  const snapshotUser = () => {
    const userDoc = doc(firestore, `users/${user?.uid}`) as DocumentReference<User>;
    onSnapshot(userDoc, (doc: any) => {
      const userObj = { ...doc.data(), id: doc.id } as User;
      setCurUser(userObj);
      if (!userObj.email) {
        logout?.().then(() => {
          navigate('.');
          window.location.reload();
        });
      }
      if (userObj.showManageTeamTour && !tourOpen) {
        setTourOpen(true);
      }
      if (userObj && userObj.status.toLowerCase() === 'suspended') {
        setUserSuspended(true);
      } else {
        setUserSuspended(false);
      }
      if (userObj && userObj.status.toLowerCase() === 'disabled') {
        setUserDisabled(true);
      } else {
        setUserDisabled(false);
      }
    });
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted && !curUser) {
      snapshotUser();
    }
    return () => {
      isMounted = false;
    };
    // if (!curUser) {
    //   snapshotUser();
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseTour = async () => {
    await updateTourUser(user?.uid as string, { showManageTeamTour: false });
    setTourOpen(false);
  };

  const steps = [
    {
      selector: '',
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Admin: Manage Team</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
              Here is where you are able to manage your dealership team. Only admins can add and remove team members.
            </Typography>
          </Box>
        </Box>
      )
    },
    {
      selector: '#adminTeam2',
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Admin: Manage Team</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          {/* prettier-ignore */}
          <Typography sx={{ color: theme.palette.text.secondary, marginTop: '10px' }}>
            Invite team members to your dealership team and assign them a role. Currently, there are three roles within the platform that team members can be assigned.
          </Typography>
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
              <span style={{ fontWeight: '900' }}>Admin</span> role should be reserved for high level decision-makers in your dealership. They have access to the entire platform and can manage your dealership network.
            </Typography>
          </Box>
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
              <span style={{ fontWeight: '900' }}>Dealership Rep</span> role is for those who are responsible for buying and/or selling vehicles, reviewing and editing vehicle details, negotiating sales and facilitating post-sale functionalities.
            </Typography>
          </Box>
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
              <span style={{ fontWeight: '900' }}>Vehicle Capture Rep</span> have limited functionality within the platform. They can capture vehicles and put them in "Parked Status" for review by an admin or dealership rep.
            </Typography>
          </Box>
        </Box>
      )
    },
    {
      selector: '#adminTeam3',
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Admin: Manage Team</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          {/* prettier-ignore */}
          <Typography sx={{ color: theme.palette.text.secondary, marginTop: '10px' }}>
            You are able to change a team members role or remove them from the platform by clicking on the card.
          </Typography>
          {/* prettier-ignore */}
          <Typography sx={{ color: theme.palette.text.secondary, marginTop: '10px' }}>
            Now it's time to meet with your Dealership Rep and begin building out your Buying and/or Selling Networks under the Find a Dealership Tab.
          </Typography>
        </Box>
      )
    }
  ];

  return (
    <>
      {!userDeleted && !userDisabled ? (
        <Page title="Manage Teams | DealerGavel">
          <Container maxWidth={themeStretch ? false : 'xl'}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ mb: 0 }}
            >
              <Typography
                variant="h4"
                component="h1"
                paragraph
                sx={{
                  fontSize: '1rem !important',
                  color:
                    theme.palette.mode === 'dark'
                      ? theme.palette.common.white
                      : theme.palette.text.primary
                }}
              >
                Team Members
              </Typography>
              <Button
                id="adminTeam2"
                className={styles.inviteButton}
                variant="contained"
                sx={{ p: '7px 36px 7px 36px', marginTop: '-24px' }}
                onClick={handleDialogChange}
              >
                Invite New Member
              </Button>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ mb: 0 }}
            >
              <Paper component="form" className={styles.search}>
                <DebounceInput
                  className={styles.searchInput}
                  placeholder="Search Team Members"
                  minLength={2}
                  debounceTimeout={300}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                <SearchIcon className={styles.searchIcon} sx={{ width: '20px' }} />
              </Paper>
            </Grid>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
              {teamMembers &&
                teamMembers
                  .filter(
                    (teamMember) =>
                      teamMember.firstName.toLowerCase().includes(searchValue.toLowerCase()) ||
                      teamMember.lastName.toLowerCase().includes(searchValue.toLowerCase()) ||
                      teamMember.currentDealershipRole
                        .toLowerCase()
                        .includes(searchValue.toLowerCase())
                  )
                  .map((teamMember, index: number) => {
                    return (
                      <CardStyle
                        id="adminTeam3"
                        key={index}
                        onClick={() => {
                          handleDrawer();
                          setShowContactSelect(false);
                          setPrimaryContactValue(undefined);
                          setCurrentMemberRole(teamMember.currentDealershipRole);
                          setCurrentTeamMember(teamMember);
                          setCurrentAvatar(
                            teamMember.currentDealershipRole === 'admin' ||
                              teamMember.currentDealershipRole === 'superAdmin'
                              ? '#6495ED'
                              : teamMember.currentDealershipRole === 'dealerRep'
                              ? '#006400'
                              : teamMember.currentDealershipRole === 'captureRep'
                              ? '#FF7F50'
                              : '#D2691E'
                          );
                        }}
                      >
                        <Box
                          className={styles.avatarCircle}
                          sx={{
                            backgroundColor:
                              teamMember.currentDealershipRole === 'admin'
                                ? '#6495ED'
                                : teamMember.currentDealershipRole === 'dealerRep'
                                ? '#006400'
                                : teamMember.currentDealershipRole === 'captureRep'
                                ? '#FF7F50'
                                : '#D2691E'
                          }}
                        >
                          <Typography className={styles.avatarInnerText}>
                            {teamMember.firstName.charAt(0).toUpperCase()}
                            {teamMember.lastName.charAt(0).toUpperCase()}
                          </Typography>
                        </Box>
                        <Typography
                          sx={{
                            fontWeight: 'bold',
                            textTransform: 'capitalize',
                            color:
                              theme.palette.mode === 'dark'
                                ? theme.palette.common.white
                                : theme.palette.text.primary
                          }}
                        >
                          {teamMember.firstName} {teamMember.lastName}
                        </Typography>
                        <Typography sx={{ color: theme.palette.info.light, fontSize: '13px' }}>
                          {teamMember.currentDealershipRole === 'admin' &&
                          teamMember.email.toLowerCase() !==
                            currentDealership?.primaryContactEmail.toLowerCase()
                            ? 'Admin'
                            : teamMember.currentDealershipRole === 'admin' &&
                              teamMember.email.toLowerCase() ===
                                currentDealership?.primaryContactEmail.toLowerCase()
                            ? 'Admin (Primary Contact)'
                            : teamMember.currentDealershipRole === 'dealerRep'
                            ? 'Dealer Rep'
                            : teamMember.currentDealershipRole === 'captureRep'
                            ? 'Capture Rep'
                            : teamMember.currentDealershipRole === 'superAdmin' &&
                              teamMember.email.toLowerCase() !==
                                currentDealership?.primaryContactEmail.toLowerCase()
                            ? 'Super Admin'
                            : teamMember.currentDealershipRole === 'superAdmin' &&
                              teamMember.email.toLowerCase() ===
                                currentDealership?.primaryContactEmail.toLowerCase()
                            ? 'Super Admin (Primary)'
                            : teamMember.currentDealershipRole}
                        </Typography>

                        {teamMember.status && (
                          <Typography
                            className={styles.status}
                            style={{
                              marginTop: '10px',
                              textTransform: 'capitalize',
                              color:
                                teamMember.status === 'incomplete'
                                  ? '#E21912'
                                  : teamMember.status === 'suspended'
                                  ? theme.palette.primary.light
                                  : teamMember.status === 'disabled'
                                  ? '#E21912'
                                  : teamMember.status === 'sent'
                                  ? '#979797'
                                  : '#09B984',
                              backgroundColor:
                                teamMember.status === 'incomplete'
                                  ? 'rgba(253, 126, 20, 0.2)'
                                  : teamMember.status === 'suspended'
                                  ? theme.palette.primary.lighter
                                  : teamMember.status === 'disabled'
                                  ? 'rgba(226, 25, 18, 0.1)'
                                  : teamMember.status === 'sent'
                                  ? 'rgba(151, 151, 151, 0.15)'
                                  : 'rgba(9, 185, 132, 0.24)'
                            }}
                          >
                            {teamMember.status.toLowerCase() === 'suspended'
                              ? 'pending'
                              : teamMember.status.toLowerCase()}
                          </Typography>
                        )}
                      </CardStyle>
                    );
                  })}
            </Grid>

            {Object.keys(currentTeamMember).length !== 0 &&
              currentTeamMember.currentDealershipRole !== 'superAdmin' && (
                <Drawer
                  anchor="right"
                  open={anchorEl}
                  PaperProps={{
                    sx: {
                      top: '83px',
                      width: '400px',
                      bgcolor: 'background.default',
                      borderLeft:
                        theme.palette.mode === 'dark'
                          ? `1px solid ${theme.palette.text.secondary}`
                          : ''
                    }
                  }}
                  variant="persistent"
                >
                  <Box className={styles.drawerContainer} role="presentation">
                    <Box>
                      <Box className={styles.teamMemberRow}>
                        <Typography sx={{ fontWeight: '700', color: theme.palette.text.secondary }}>
                          Team Member
                        </Typography>
                        <CloseIcon onClick={closeDrawer} sx={{ cursor: 'pointer' }} />
                      </Box>
                      <Box className={styles.profileRow}>
                        <Box
                          className={styles.avatarCircle}
                          sx={{
                            backgroundColor: currentAvatar,
                            marginTop: '20px'
                          }}
                        >
                          <Typography className={styles.avatarInnerText}>
                            {currentTeamMember.firstName.charAt(0).toUpperCase()}
                            {currentTeamMember.lastName.charAt(0).toUpperCase()}
                          </Typography>
                        </Box>
                        <Box className={styles.profileInfo}>
                          <Typography sx={{ fontWeight: '700' }}>
                            {currentTeamMember.firstName} {currentTeamMember.lastName}
                          </Typography>
                          <Typography sx={{ color: theme.palette.text.secondary }}>
                            {currentTeamMember.email}
                          </Typography>
                        </Box>
                      </Box>
                      <Box className={styles.permissionRow}>
                        <Box>
                          <Typography sx={{ fontWeight: '700' }}>Permissions</Typography>
                          <Typography sx={{ color: theme.palette.text.secondary }}>Role</Typography>
                        </Box>
                        <FormControl variant="standard">
                          <InputLabel id="user-role-select-label">Role</InputLabel>
                          <Select
                            sx={{ minWidth: '275px' }}
                            labelId="user-role-select-label"
                            id="user-role-select"
                            value={currentMemberRole}
                            label="Role"
                            onChange={(e) => handleRoleChange(e)}
                          >
                            <MenuItem value={'admin'}>Admin</MenuItem>
                            <MenuItem value={'dealerRep'}>Dealership Rep</MenuItem>
                            <MenuItem value={'captureRep'}>Vehicle Capture Rep</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    </Box>

                    {showContactSelect && (
                      <Box sx={{ padding: '20px' }}>
                        <Typography variant="h4" sx={{ color: theme.palette.error.main }}>
                          Required Action
                        </Typography>
                        {/* prettier-ignore */}
                        <Typography sx={{ fontSize: '14px' }}>
                          This user is the Primary Contact for this Dealership. You must select another Admin to become Primary Contact in order to complete this process.
                        </Typography>
                        <FormControl variant="standard">
                          <InputLabel id="primary-contact-select"></InputLabel>
                          <Select
                            sx={{ minWidth: '275px' }}
                            labelId="primary-contact-select"
                            id="primary-contact-select"
                            value={primaryContactValue}
                            label="Admins"
                            onChange={(e) => handleContactChange(e)}
                          >
                            {teamAdmins.map((admin, index: number) => {
                              return (
                                <MenuItem key={index} value={admin.email}>
                                  {admin.firstName + ' ' + admin.lastName}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                        <Button
                          sx={{ marginTop: '20px' }}
                          variant="contained"
                          disabled={!primaryContactValue}
                          onClick={confirmPrimaryContactChange}
                        >
                          Confirm
                        </Button>
                      </Box>
                    )}

                    <Box className={styles.accountRow}>
                      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                        <Typography sx={{ fontWeight: '700' }}>Account</Typography>
                        <Button variant="text" onClick={() => handleRemove(currentTeamMember)}>
                          <Typography sx={{ color: theme.palette.error.main }}>
                            Delete Account
                          </Typography>
                        </Button>
                        {currentTeamMember.status === 'incomplete' && (
                          <Button
                            variant="contained"
                            sx={{ backgroundColor: theme.palette.primary.main }}
                            onClick={() => handleUpdateInvite(currentTeamMember)}
                          >
                            Resend Invite
                          </Button>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Drawer>
              )}
          </Container>
          <Dialog open={dialogOpen} onClose={handleDialogChange}>
            <Box>
              <DialogTitle>Invite Team Member</DialogTitle>
              <CloseIcon
                onClick={handleDialogChange}
                sx={{
                  cursor: 'pointer',
                  position: 'absolute',
                  right: '15px',
                  top: '24px'
                }}
              />
            </Box>
            <DialogContent>
              <Formik
                initialValues={{
                  firstName: '',
                  lastName: '',
                  email: '',
                  currentDealershipRole: ''
                }}
                validationSchema={Yup.object().shape({
                  firstName: Yup.string()
                    .required('First Name is required')
                    .matches(/^[a-zA-Z0-9 ]+$/, 'Cannot contain special characters'),
                  lastName: Yup.string()
                    .required('Last Name is required')
                    .matches(/^[a-zA-Z0-9 ]+$/, 'Cannot contain special characters'),
                  email: Yup.string()
                    .required('Email is required')
                    .matches(
                      /^[a-zA-Z0-9.!$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                      'Email must be a valid email address'
                    ),
                  currentDealershipRole: Yup.string().required('Role is required')
                })}
                onSubmit={async (values, { setErrors, setSubmitting }) => {
                  sendInvite(
                    values.firstName,
                    values.lastName,
                    values.email,
                    values.currentDealershipRole
                  );
                  setInviteRole('');
                  handleDialogChange();
                }}
              >
                {({
                  getFieldProps,
                  isSubmitting,
                  handleSubmit,
                  isValid,
                  touched,
                  errors,
                  dirty,
                  values
                }): JSX.Element => (
                  <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                    <Box className={styles.emailRow}>
                      <TextField
                        label="Enter Contact First Name"
                        type="text"
                        sx={{ minWidth: '350px', marginTop: '10px' }}
                        {...getFieldProps('firstName')}
                        error={Boolean(touched.firstName && errors.firstName)}
                        helperText={touched.firstName && errors.firstName}
                      ></TextField>
                      <TextField
                        label="Enter Contact Last Name"
                        type="text"
                        sx={{ minWidth: '350px', marginTop: '10px' }}
                        {...getFieldProps('lastName')}
                        error={Boolean(touched.lastName && errors.lastName)}
                        helperText={touched.lastName && errors.lastName}
                      ></TextField>
                      <TextField
                        label="Enter Email"
                        type="text"
                        sx={{ minWidth: '350px', marginTop: '10px' }}
                        {...getFieldProps('email')}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                      ></TextField>

                      <FormControl sx={{ mt: 1.5 }}>
                        <InputLabel id="dealership-rep-label">Role</InputLabel>
                        <Select
                          sx={{ minWidth: '350px' }}
                          labelId="dealership-rep-label"
                          id="dealership-rep"
                          value={inviteRole}
                          disableUnderline
                          label="Role"
                          /* {...getFieldProps('role')} */
                          onChange={(event) => {
                            setInviteRole(event.target.value);
                            values.currentDealershipRole = event.target.value;
                          }}
                          error={Boolean(
                            touched.currentDealershipRole && errors.currentDealershipRole
                          )}
                        >
                          <MenuItem value={'admin'}>Admin</MenuItem>
                          <MenuItem value={'dealerRep'}>Dealership Rep</MenuItem>
                          <MenuItem value={'captureRep'}>Vehicle Capture Rep</MenuItem>
                        </Select>
                        <FormHelperText sx={{ color: 'red' }}>
                          {touched.currentDealershipRole && errors.currentDealershipRole}
                        </FormHelperText>
                      </FormControl>
                    </Box>
                    <Box className={styles.buttonRow}>
                      <LoadingButton size="large" type="submit" variant="contained">
                        Send Invite
                      </LoadingButton>
                    </Box>
                  </form>
                )}
              </Formik>
            </DialogContent>
          </Dialog>
          {teamMembers && teamMembers.length > 0 && (
            <Tour
              steps={steps}
              isOpen={tourOpen}
              onRequestClose={() => handleCloseTour()}
              className={styles.tourContainer}
            />
          )}
        </Page>
      ) : (
        <>
          {userDeleted && (
            <Alert severity="error">
              <AlertTitle>Permission Denied</AlertTitle>
              Your account has been disabled.
            </Alert>
          )}
          {userSuspended && (
            <>
              <Alert severity="error">
                <AlertTitle>Thank you for joining DealerGavel</AlertTitle>
                Your account information is being reviewed by DealerGavel team, and will be
                &nbsp;authorized shortly. For now, you can return to the dashboard and await
                &nbsp;authorization.
              </Alert>
              <Box sx={{ mt: '20px' }}>
                <Button onClick={handleNavigate} variant="contained">
                  Back to Dashboard
                </Button>
              </Box>
            </>
          )}
          {userDisabled && (
            <>
              <Alert severity="error">
                <AlertTitle>Permission Denied</AlertTitle>
                Your account has been disabled.
              </Alert>
              <Box sx={{ mt: '20px' }}>
                <Button onClick={handleNavigate} variant="contained">
                  Back to Dashboard
                </Button>
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
}
