import { Box, Container, Grid, Typography, Link } from '@mui/material';
import useSettings from '../../hooks/useSettings';
import Page from '../../components/Page';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SecurityIcon from '@mui/icons-material/Security';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Link as RouterLink } from 'react-router-dom';
import { PATH_ADMIN, PATH_PROFILE } from '../../routes/paths';
import { useState } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import useAuth from 'hooks/useAuth';
import PersonIcon from '@mui/icons-material/Person';

export default function ReportsPage() {
  const { themeStretch } = useSettings();
  const theme = useTheme();
  const { currentUser } = useAuth();
  const [accountHover, setAccountHover] = useState(false);
  const [securityHover, setSecurityHover] = useState(false);
  const [notificationsHover, setNotificationsHover] = useState(false);
  const [adminHover, setAdminHover] = useState(false);
  const [billingHover, setBillingHover] = useState(false);

  const useStyles = makeStyles({
    avatarContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      position: 'absolute',
      left: '30px'
    },
    avatarCircle: {
      width: '115px',
      height: '115px',
      borderRadius: '50%',
      backgroundColor: 'white',
      backgroundImage: `url(${currentUser?.avatarUrl})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat'
    },
    iconContainer: {
      backgroundColor: theme.palette.background.paper,
      padding: '5px',
      borderRadius: '5px',
      width: '50px',
      height: '50px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    iconContainerHover: {
      backgroundColor: theme.palette.primary.lighter,
      padding: '5px',
      borderRadius: '5px',
      width: '50px',
      height: '50px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    linkContainer: {
      width: '32%',
      minWidth: '265px',
      marginRight: '2%',
      marginBottom: '20px',
      cursor: 'pointer',
      borderRadius: '8px',
      border:
        theme.palette.mode === 'dark'
          ? `1px solid ${theme.palette.text.secondary}`
          : `1px solid ${theme.palette.divider}`,
      color: theme.palette.info.lighter,
      '&:last-of-type': {
        marginRight: '0px'
      }
    },
    linkContainerHover: {
      width: '32%',
      minWidth: '265px',
      marginRight: '2%',
      marginBottom: '20px',
      cursor: 'pointer',
      borderRadius: '8px',
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      '&:last-of-type': {
        marginRight: '0px'
      }
    },
    link: {
      width: '100%',
      height: '100%',
      padding: '15px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      position: 'relative'
    }
  });

  const styles = useStyles();

  return (
    <Page title="Vehicle Reports | DealerGavel">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid
          sx={{ marginTop: '20px' }}
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Link
            onMouseEnter={() => setAccountHover(true)}
            onMouseLeave={() => setAccountHover(false)}
            className={accountHover ? styles.linkContainerHover : styles.linkContainer}
            sx={{ height: '100px' }}
            to={PATH_ADMIN.arrivedVehicles}
            component={RouterLink}
            underline="none"
          >
            <Box className={styles.link}>
              <Box sx={{ display: 'flex' }}>
                {/* <Box className={accountHover ? styles.iconContainerHover : styles.iconContainer}>
                  <AccountCircleIcon />
                </Box> */}
                <Box sx={{ marginLeft: '15px' }}>
                  <Typography
                    sx={{
                      color: theme.palette.text.primary,
                      fontWeight: '700',
                      fontSize: '16px'
                    }}
                  >
                    Arrived Vehicles
                  </Typography>
                  <Typography sx={{ color: theme.palette.info.lighter, fontSize: '14px' }}>
                    View Report on all Arrived Vehicles
                  </Typography>
                </Box>
              </Box>
              {accountHover && <ArrowForwardIcon />}
            </Box>
          </Link>
          <Link
            onMouseEnter={() => setSecurityHover(true)}
            onMouseLeave={() => setSecurityHover(false)}
            className={securityHover ? styles.linkContainerHover : styles.linkContainer}
            sx={{ height: '100px' }}
            to={PATH_ADMIN.deliveredVehicles}
            component={RouterLink}
            underline="none"
          >
            <Box className={styles.link}>
              <Box sx={{ display: 'flex' }}>
                {/* <Box className={securityHover ? styles.iconContainerHover : styles.iconContainer}>
                  <SecurityIcon />
                </Box> */}
                <Box sx={{ marginLeft: '15px' }}>
                  <Typography
                    sx={{
                      color: theme.palette.text.primary,
                      fontWeight: '700',
                      fontSize: '16px'
                    }}
                  >
                    Delivered Vehicles
                  </Typography>
                  <Typography sx={{ color: theme.palette.info.lighter, fontSize: '14px' }}>
                    View Report on all Delivered Vehicles
                  </Typography>
                </Box>
              </Box>
              {securityHover && <ArrowForwardIcon />}
            </Box>
          </Link>
        </Grid>
      </Container>
    </Page>
  );
}
