import { ReactNode } from 'react';
import { Container, Alert, AlertTitle } from '@mui/material';
import useAuth from 'hooks/useAuth';

type SuperAdminGuardProps = {
  children: ReactNode | string;
};

export default function SuperAdminGuard({ children }: SuperAdminGuardProps) {
  const { currentUser } = useAuth();

  if (currentUser.currentDealershipRole.toLowerCase() !== 'superadmin') {
    return (
      <Container>
        <Alert severity="error">
          <AlertTitle>Permission Denied</AlertTitle>
          You do not have permission to access this page
        </Alert>
      </Container>
    );
  }

  return <>{children}</>;
}
