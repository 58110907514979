import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import {
  PaintedPanelsType,
  VehicleConditionsType,
  VehicleInfo,
  VehiclePhotos
} from 'types/vehicleInfo';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import EditIcon from '@mui/icons-material/Edit';
import { AddPhotoDialog, photoLocationExt, photoLocationInt } from './AddPhotoDialog';
import { uniqueId } from './genUID';
import { useNavigate } from 'react-router-dom';
import { PATH_SELLER } from 'routes/paths';
import { useTheme } from '@mui/material/styles';
import { ListPanels } from './AllPanels';
import { Formik } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import { VehicleDropBox } from './CommonComponents';
import { LoadingButton } from '@mui/lab';
import MoreItemsIndicator from './MoreItemsIndicator';

const d_panels = [
  'Hood',
  'Front Bumper',
  'Left Front Fender',
  'Left Front Door',
  'Left Rear Door',
  'Left Rear Quarter/Bedside',
  'Trunk/Tailgate',
  'Rear Bumper',
  'Right Rear Quarter',
  'Right Rear Door',
  'Right Front Door',
  'Right Front Quarter',
  'Roof'
];

const sortPhotos = [
  '3/4 Shot',
  'Driver Side',
  'Back',
  'Passenger Side',
  'Front',
  'Tire',
  'Drivers Seat Cross',
  'Odometer with Mileage',
  'From Back Seat: Centre Console',
  'From Back Seat: Roof with Mirror'
];

const d_condition = ['Acceptable', 'Requires Minor Attention/Work', 'Requires Major Repair/Redo'];

type Props = {
  vehicle: VehicleInfo;
  updVehicle: (vehicle: VehicleInfo, flag: number) => void;
  conditions: VehicleConditionsType;
};

export function VehiclePhotosM({ vehicle, conditions, updVehicle }: Props) {
  const navigate = useNavigate();
  const theme = useTheme();
  const [showAddImage, setShowAddImage] = useState(false);
  const [vehicleInfo, setVehicleInfo] = useState<VehicleInfo>();
  const [newVehiclePhoto, setNewVehiclePhoto] = useState({} as VehiclePhotos);
  const [isDamages, setIsDamages] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [allRequiredPhotos, setAllRequiredPhotos] = useState<boolean>(false);
  const [showValidateError, setShowValidateError] = useState<boolean>(false);
  const [isNewPhoto, setIsNewPhoto] = useState<boolean>(false);
  const [category, setCategory] = useState<string>();

  const [conditionsInfo, setConditionsInfo] = useState(conditions || ({} as VehicleConditionsType));
  const [showAddPanels, setShowAddPanels] = useState(false);
  const [selectedPanel, setSelectedPanel] = useState<PaintedPanelsType>();
  const [paintedPanels, setPaintedPanels] = useState<PaintedPanelsType[]>(
    conditions?.paintedPanels || ([] as PaintedPanelsType[])
  );

  const boxStyle = {
    textAlign: 'start',
    width: 400,
    height: 60,
    backgroundColor: theme.palette.mode !== 'dark' ? theme.palette.common.white : 'unset',
    border: `1px ${theme.palette.divider} solid`,
    borderRadius: '10px',
    fontWeight: 300,
    padding: 0,
    mb: 1,
    overflow: 'hidden'
  };

  const handleCloseAddPanels = () => {
    setSelectedPanel(undefined);
    setShowAddPanels(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const t = { ...conditionsInfo };
    if (t.paintedPanels && t.paintedPanels.length > 0) {
      const tempPanels: PaintedPanelsType[] = [];
      t.paintedPanels.forEach((panel: PaintedPanelsType) => {
        tempPanels.push(panel);
      });
      setPaintedPanels(tempPanels);
    }
    // t.overall = value as any;
    setConditionsInfo(t);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Create empty cards for Interior/Exterior
  useEffect(() => {
    let tt: VehiclePhotos[] = [];
    const tmpVehicle = { ...vehicle };
    photoLocationExt.forEach((d) => {
      const t: VehiclePhotos = {} as VehiclePhotos;
      t.categoryName = 'Exterior';
      t.location = d;
      t.id = uniqueId('p-');
      tt.push(t);
    });
    photoLocationInt.forEach((d) => {
      const t: VehiclePhotos = {} as VehiclePhotos;
      t.categoryName = 'Interior';
      t.location = d;
      t.id = uniqueId('p-');
      tt.push(t);
    });

    if (tmpVehicle.photos) {
      tmpVehicle.photos.sort((a, b) => {
        const indexOfa = sortPhotos.indexOf(a.location);
        const indexOfb = sortPhotos.indexOf(b.location);
        if (indexOfa > indexOfb) return 1;
        if (indexOfa < indexOfb) return -1;
        return 0;
      });
      for (let d_t of photoLocationExt)
        // prettier-ignore
        if (tmpVehicle.photos.filter((e) => e.location && e.location.toLowerCase() === d_t.toLowerCase()).length < 1) {
          const t: VehiclePhotos = {} as VehiclePhotos;
          t.categoryName = 'Exterior';
          t.location = d_t;
          t.id = uniqueId('p-');
          tmpVehicle.photos.push(t);
        }
      for (let d_t of photoLocationInt)
        if (tmpVehicle.photos.filter((e) => e.location === d_t).length < 1) {
          const t: VehiclePhotos = {} as VehiclePhotos;
          t.categoryName = 'Interior';
          t.location = d_t;
          t.id = uniqueId('p-');
          tmpVehicle.photos.push(t);
        }
    } else {
      tmpVehicle.photos = [...tt];
    }
    if (tmpVehicle) setVehicleInfo(tmpVehicle);
  }, [vehicle]);
  // -----------------------------------------------------------------------

  type props = {
    card: VehiclePhotos;
  };

  const CarPhotoCard = ({ card }: props) => {
    return (
      <Box>
        <Box
          component="div"
          sx={{
            width: '165px',
            height: '136px',
            backgroundColor: theme.palette.grey[200],
            border: '1px solid lightGrey',
            borderRadius: '8px',
            mt: 3,
            mr: 2,
            mb: 1,
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          {!card.url ? (
            <Box sx={{ display: 'block', m: 'auto' }}>
              <DirectionsCarIcon
                sx={{ ml: '34px', color: theme.palette.grey[400], fontSize: '80px' }}
              />
            </Box>
          ) : (
            <Box
              component="img"
              sx={{
                display: 'block',
                m: 'auto',
                maxWidth: '163px',
                maxHeight: '134px',
                float: 'left',
                objectFit: 'cover'
              }}
              src={card.url}
              alt="aa"
            />
          )}

          <Box
            component="span"
            sx={{
              backgroundColor: theme.palette.common.white,
              borderRadius: '8px',
              width: '30px',
              height: '30px',
              float: 'right',
              cursor: 'pointer',
              ml: card.url ? -4 : 0,
              marginRight: '5px',
              marginTop: '5px'
            }}
            onClick={() => {
              setCategory(card.categoryName);
              setNewVehiclePhoto(card);
              setIsEdit(true);
              if (card.categoryName === 'Damages') {
                setIsDamages(true);
              } else {
                setIsDamages(false);
              }
              setIsNewPhoto(false);
              setShowAddImage(true);
            }}
          >
            <EditIcon sx={{ ml: 0.5, mt: 0.3, color: theme.palette.text.secondary }} />
          </Box>
        </Box>
        <Typography
          sx={{
            ml: 0.5,
            fontSize: '14px',
            textAlign: 'start',
            textTransform: 'capitalize'
          }}
        >
          {card.location === 'Custom Locations' ? card.otherLocation : card.location}
        </Typography>
        {card.categoryName === 'Damages' && (
          <Typography
            sx={{
              ml: 0.5,
              fontSize: '12px',
              textAlign: 'start',
              textTransform: 'capitalize',
              maxWidth: '160px'
            }}
          >
            {card.damageType} {card.damageSize}
          </Typography>
          // {card.damageNumber}
        )}
      </Box>
    );
  };

  const validatePhotos = () => {
    const nonDamagePhotos = vehicleInfo?.photos.filter(
      (photo: VehiclePhotos) => photo.categoryName.toLowerCase() !== 'damages'
    );
    const allRequiredPhotosUpdated = nonDamagePhotos?.every((photo: any) => photo.url);
    setAllRequiredPhotos(allRequiredPhotosUpdated as boolean);
  };

  useEffect(() => {
    validatePhotos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleInfo]);

  const handleCloseModal = () => {
    setShowAddImage(false);
    setNewVehiclePhoto({} as VehiclePhotos);
  };

  const handleUpdateEditPhotos = (v: VehiclePhotos, flag: number) => {
    let d = [{}] as VehiclePhotos[];
    if (vehicle.photos) {
      if (isEdit) {
        const ind = vehicle.photos.findIndex((ph) => ph.id === v.id);
        if (flag === 1) {
          vehicle.photos[ind] = v;
        } else {
          vehicle.photos.splice(ind, 1);
        }
        d = vehicle.photos;
      } else {
        v.id = uniqueId('p-');
        d = [...vehicle.photos, v];
      }
    } else {
      v.id = uniqueId('p-');
      d.push(v);
    }
    vehicle.photos = d.filter((obj) => !(obj && Object.keys(obj).length === 0));
    setVehicleInfo(vehicle);
    setShowAddImage(false);
    setNewVehiclePhoto({} as VehiclePhotos);
    validatePhotos();
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="outlined"
          onClick={() => {
            // if (allRequiredPhotos) {
            //   updVehicle(vehicle, 3);
            // } else {
            //   setShowValidateError(true);
            // }
            updVehicle(vehicle, 2);
            navigate(PATH_SELLER.vehicleCapture);
          }}
        >
          Save and Exit
        </Button>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          backgroundColor:
            theme.palette.mode === 'dark'
              ? theme.palette.background.neutral
              : theme.palette.grey[50],
          borderRadius: '10px',
          maxWidth: '850px',
          width: '100%',
          border: '1px solid rgba(145, 158, 171, 0.24)',
          margin: 'auto',
          marginTop: '20px',
          paddingBottom: '20px',
          paddingLeft: '60px',
          paddingRight: '60px'
        }}
      >
        <Box sx={{ mt: 3, width: '100%' }}>
          <Typography variant="h3" sx={{ textAlign: 'center', color: theme.palette.text.primary }}>
            Vehicle Photos
          </Typography>
        </Box>

        <Box sx={{ mt: 1, width: '100%' }}>
          {['Exterior', 'Interior', 'Damages'].map((item, index) => (
            <Box key={index}>
              <Box
                sx={{
                  width: '100%',
                  mt: 3,
                  mb: 1,
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ fontWeight: '700', textTransform: 'uppercase' }}
                >
                  {item}
                </Typography>
                <Button
                  variant="contained"
                  onClick={() => {
                    setCategory(item);
                    setIsEdit(false);
                    if (item === 'Damages') {
                      setIsDamages(true);
                    } else {
                      setIsDamages(false);
                    }
                    setIsNewPhoto(true);
                    setShowAddImage(true);
                  }}
                >
                  {item === 'Damages' ? `Add Damages` : `Add Images`}
                </Button>
              </Box>

              {/* ---------------------------------------------------------- */}
              <Box
                sx={{
                  width: '100%',
                  mt: 3,
                  mb: 1,
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap'
                }}
              >
                {vehicle.photos &&
                  vehicle.photos
                    .filter((a) => a.categoryName === item)
                    .map((vechicleCard, index) => <CarPhotoCard key={index} card={vechicleCard} />)}
              </Box>
              <Divider
                sx={{
                  borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
                }}
              />
            </Box>
          ))}
        </Box>

        <Box
          sx={{
            mt: 3,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignSelf: 'flex-start'
          }}
        >
          <Box
            sx={{
              width: '100%',
              mt: 3,
              mb: 1,
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Typography variant="subtitle2" sx={{ fontWeight: '700' }}>
              PAINTED PANELS
            </Typography>
            <Button variant="contained" sx={{ mt: -1 }} onClick={() => setShowAddPanels(true)}>
              Add
            </Button>
          </Box>
          <ListPanels
            paintedPanels={paintedPanels}
            updatePanels={(v) => {
              setPaintedPanels(v);
              const tmpC = { ...conditionsInfo };
              tmpC.paintedPanels = [...v];
              setConditionsInfo(tmpC);
              vehicle.conditions = tmpC;
            }}
            handleEdit={(v) => {
              setSelectedPanel(v);
              setShowAddPanels(true);
            }}
          />
        </Box>
      </Box>
      <Divider
        sx={{
          mt: 2,
          borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
        }}
      />
      <Box sx={{ m: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Button variant="text" color="inherit" onClick={() => updVehicle(vehicle, 1)}>
          Back
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            if (allRequiredPhotos) {
              updVehicle(vehicle, 3);
            } else {
              setShowValidateError(true);
            }
          }}
        >
          Next: Declarations & Disclosures
        </Button>
      </Box>
      <MoreItemsIndicator />
      {/* ---------------------------------------------------------- */}
      <Dialog open={showAddPanels} onClose={handleCloseAddPanels}>
        <Formik
          initialValues={
            {
              id: selectedPanel?.id || '',
              name: selectedPanel?.name || '',
              condition: selectedPanel?.condition || '',
              selected: false
            } as PaintedPanelsType
          }
          onSubmit={async (values, { resetForm, setSubmitting }): Promise<void> => {
            if (values.name !== '') {
              values.selected = true;
              const tmpC = { ...conditionsInfo };
              const tmpPanels = [...paintedPanels];
              const ind = tmpPanels.findIndex((p) => p.name === values.name);
              if (ind !== -1) {
                tmpPanels[ind].selected = true;
                tmpPanels[ind].condition = values.condition;
                tmpC.paintedPanels = tmpPanels;
                setPaintedPanels(tmpPanels);
              } else {
                values.selected = true;
                values.id = uniqueId('pp-');
                tmpC.paintedPanels = [...tmpPanels, values];
                setPaintedPanels([...tmpPanels, values]);
              }
              setConditionsInfo(tmpC);
              vehicle.conditions = tmpC;
            }
            setSelectedPanel(undefined);
            setShowAddPanels(false);
          }}
        >
          {({
            handleSubmit,
            setFieldValue,
            isSubmitting,
            isValid,
            dirty,
            getFieldProps,
            values,
            errors,
            touched
          }): JSX.Element => (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Box
                  sx={{
                    width: 400,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-evenly'
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      mb: 2,
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between'
                    }}
                  >
                    <div></div>
                    <Typography variant="h6"> Add Painted Panels </Typography>
                    <CloseIcon
                      sx={{ fontSize: '18px', cursor: 'pointer' }}
                      onClick={handleCloseAddPanels}
                    />
                  </Box>
                  <Divider
                    sx={{
                      width: '100%',
                      borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
                    }}
                  />
                  <FormControl sx={{ mt: 2 }}>
                    <Box sx={boxStyle}>
                      <VehicleDropBox
                        dropBoxValue={values.name}
                        caption={'Panel Type'}
                        arrayOfItems={d_panels}
                        updateDropValue={(v) => {
                          if (!v) return;
                          setFieldValue('name', v);
                        }}
                      />
                    </Box>
                  </FormControl>

                  <FormControl sx={{ mt: 0.5 }}>
                    <Box sx={boxStyle}>
                      <VehicleDropBox
                        dropBoxValue={values.condition}
                        caption={'Paint Condition'}
                        arrayOfItems={d_condition}
                        updateDropValue={(v) => {
                          if (!v) return;
                          setFieldValue('condition', v);
                        }}
                      />
                    </Box>
                  </FormControl>
                </Box>
              </DialogContent>

              <DialogActions
                sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
              >
                <Button
                  variant="contained"
                  color="error"
                  sx={{ width: '30%' }}
                  autoFocus
                  onClick={handleCloseAddPanels}
                >
                  Cancel
                </Button>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  sx={{ width: '30%' }}
                  disabled={!isValid}
                  loading={isSubmitting}
                >
                  {selectedPanel ? 'Update' : 'Add'}
                </LoadingButton>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
      <Dialog open={showAddImage} sx={{ zIndex: '9995' }}>
        <AddPhotoDialog
          category={category}
          isNewPhoto={isNewPhoto}
          vehiclePhoto={newVehiclePhoto}
          isDamages={isDamages}
          vin={(vehicleInfo?.details.vin as string) || (vehicleInfo?.id as string)}
          updVehiclePhoto={(v, flag) => handleUpdateEditPhotos(v, flag)}
          handleCloseModal={handleCloseModal}
        />
      </Dialog>
      <Dialog open={showValidateError} onClose={() => setShowValidateError(!showValidateError)}>
        <Alert severity="error" color="error">
          <AlertTitle>Error</AlertTitle>
          You must replace each placeholder image in order to continue.
        </Alert>
      </Dialog>
    </>
  );
}
