import {
  Grid,
  Button,
  IconButton,
  Popover,
  MenuItem,
  Dialog,
  Typography,
  Box
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ListItemDetails from '../../shared/ListItemDetails';
import ListItemBidPrice from '../../shared/ListItemPriceBid';
import ListItemPersonDate from '../../shared/ListItemPersonDate';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SharedDrawer from '../../shared/SharedDrawer';
import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Link as RouterLink } from 'react-router-dom';
import HistoryDrawerContent from '../../shared/HistoryDrawerContent';
import AuctionDrawer from 'components/auction/drawer/AuctionDrawer';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { VehicleInfo, VehiclePhotos } from 'types/vehicleInfo';
import RelaunchButton from './RelaunchButton';
import { doc, updateDoc } from 'firebase/firestore';
import { useFirestore } from 'reactfire';
import { useSnackbar } from 'notistack';
import { MIconButton } from 'components/@material-extend';
interface Props {
  vehicle: VehicleInfo;
  sendVehicleToParent: (index: number, vehicle: VehicleInfo) => void;
}

const MENU_OPTIONS = [
  { label: 'Vehicle History', linkTo: '#' },
  { label: 'Bidding History', linkTo: '#' },
  { label: 'View VDP', linkTo: '#' }
];

export default function SoldListItem({ vehicle, sendVehicleToParent }: Props) {
  const theme = useTheme();
  const firestore = useFirestore();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openBidDrawer, setOpenBidDrawer] = useState(false);
  const [anchorPopup, setAnchorPopup] = useState<HTMLButtonElement | null>(null);
  const [, setPopupOpened] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [openVDPDrawer, setOpenVDPDrawer] = useState<boolean>(false);
  // const [vehiclePhoto, setVehiclePhoto] = useState<VehiclePhotos>();

  const currencyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });

  let vehiclePhoto;
  if (vehicle.photos) {
    const photos = vehicle.photos.filter(
      (v: any) => v.url && v.location && v.location.toLowerCase().includes('3/4 shot')
    );
    if (photos[0]) {
      vehiclePhoto = photos[0];
    }
  }

  const handleCloseModal = () => {
    setOpenModal(!openModal);
  };

  const open = Boolean(anchorPopup);
  const id = open ? 'simple-popover' : undefined;

  const useStyles = makeStyles({
    menuPopover: {
      boxShadow: '0 0 2px 0 rgba(139, 148, 156, 0.24),0 10px 20px -4px rgba(139, 148, 156, 0.24)',
      // backgroundColor: theme.palette.primary.contrastText,
      paddingTop: '20px',
      paddingBottom: '20px',
      '@global': {
        '.MuiMenuItem-root': {
          paddingBottom: '6px'
        }
      }
    },
    cancelButton: {
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.common.black,
      '&:hover': {
        backgroundColor: theme.palette.grey[300]
      }
    },
    declineButton: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.error.dark
      }
    }
  });

  const styles = useStyles();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorPopup(event.currentTarget);
    setPopupOpened(true);
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setSelectedIndex(index);
    sendVehicleToParent(index, vehicle);
    // if (index === 0) {
    //   setOpenDrawer(!openDrawer);
    // } else if (index === 1) {
    //   setOpenBidDrawer(!openBidDrawer);
    // } else {
    //   setOpenVDPDrawer(!openVDPDrawer);
    // }
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const handleCloseBidDrawer = () => {
    setOpenBidDrawer(!openBidDrawer);
  };

  const handleClose = () => {
    setAnchorPopup(null);
    setPopupOpened(false);
  };

  const handleSetDate = async (date: any) => {
    const docRef = doc(firestore, `vehicles/${vehicle.id}`);
    let dateToUpdate;
    if (!date && vehicle.auction.dateArrived) {
      dateToUpdate = vehicle.auction.dateArrived;
    } else if (date) {
      dateToUpdate = date;
    }
    await updateDoc(docRef, {
      'auction.dateExpected': dateToUpdate
    });
    enqueueSnackbar('Date Updated', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right'
      },
      action: (key) => (
        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
          <CloseIcon />
        </MIconButton>
      )
    });
  };

  return (
    <Grid
      xs={12}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '10px',
        minHeight: '130px'
      }}
    >
      <Grid
        sx={{
          height: '100%',
          minHeight: '130px',
          padding: '6px !important',
          display: 'flex',
          borderTopLeftRadius: '10px',
          borderBottomLeftRadius: '10px',
          borderTop:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`,
          borderLeft:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`,
          borderBottom:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`
        }}
      >
        <ListItemDetails
          vehicle={vehicle}
          isSellerName={true}
          imageUrl={vehiclePhoto?.url}
          hasStatusPill={true}
          minWidth="400px"
        ></ListItemDetails>
      </Grid>
      {vehicle.sellerMarkAsSold && (
        <Grid
          item
          xs={2}
          sx={{
            height: '100%',
            minHeight: '130px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '6px 20px',
            minWidth: '155px',
            borderTop:
              theme.palette.mode === 'dark'
                ? `1px solid ${theme.palette.text.secondary}`
                : `1px solid ${theme.palette.divider}`,
            borderBottom:
              theme.palette.mode === 'dark'
                ? `1px solid ${theme.palette.text.secondary}`
                : `1px solid ${theme.palette.divider}`
          }}
        >
          <ListItemPersonDate label={'By Seller'} value={'Marked As Sold'}></ListItemPersonDate>
        </Grid>
      )}
      <Grid
        item
        xs={2}
        sx={{
          height: '100%',
          minHeight: '130px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '6px 20px',
          minWidth: '120px',
          borderTop:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`,
          borderBottom:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`
        }}
      >
        <ListItemBidPrice
          label={'sold price'}
          value={currencyFormat.format(vehicle.auction.currentPrice)}
          color={theme.palette.primary.main}
        ></ListItemBidPrice>
      </Grid>
      <Grid
        item
        xs={2}
        sx={{
          height: '100%',
          minHeight: '130px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '6px 20px',
          minWidth: '120px',
          borderTop:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`,
          borderBottom:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`
        }}
      >
        <ListItemBidPrice
          label={'floor price'}
          value={currencyFormat.format(vehicle.auction.floorPrice)}
          color={theme.palette.text.secondary}
        ></ListItemBidPrice>
      </Grid>
      <Grid
        item
        xs={2}
        sx={{
          height: '100%',
          minHeight: '130px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '6px 20px',
          minWidth: '150px',
          borderTop:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`,
          borderBottom:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`
        }}
      >
        {vehicle.buyerDealership && (
          <ListItemPersonDate
            label={'buyer'}
            value={vehicle.buyerDealership.dealershipName}
          ></ListItemPersonDate>
        )}
      </Grid>
      <Grid
        item
        xs={2}
        sx={{
          height: '100%',
          minHeight: '130px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '6px 20px',
          minWidth: '225px',
          borderTop:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`,
          borderBottom:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`
        }}
      >
        <ListItemPersonDate
          label={'expected arrival date'}
          isEditable={true}
          isBold={false}
          value={vehicle.auction.dateExpected}
          handleSetDate={handleSetDate}
        ></ListItemPersonDate>
      </Grid>
      <Grid
        item
        xs={2}
        sx={{
          height: '100%',
          display: 'flex',
          minHeight: '130px',
          justifyContent: 'flex-end',
          alignItems: 'center',
          minWidth: '250px',
          padding: '6px',
          borderTopRightRadius: '10px',
          borderBottomRightRadius: '10px',
          borderTop:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`,
          borderRight:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`,
          borderBottom:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`
        }}
      >
        <RelaunchButton vehicleId={vehicle.id} />
        <IconButton
          aria-describedby={'icon-button'}
          onClick={handleClick}
          sx={{
            backgroundColor: theme.palette.background.paper,
            padding: '5px',
            borderRadius: '5px',
            marginLeft: '10px'
          }}
        >
          <MoreVertIcon />
        </IconButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorPopup}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          sx={{ width: 220 }}
          PaperProps={{ classes: { root: styles.menuPopover } }}
        >
          {MENU_OPTIONS.map((option, index) => (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={(event: any) => handleMenuItemClick(event, index)}
              sx={{ typography: 'body2', py: 1, px: 2.5 }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Popover>
      </Grid>
      {/* <SharedDrawer
        isOpenSidebar={openDrawer}
        onCloseSidebar={handleCloseDrawer}
        drawerContent={
          <HistoryDrawerContent
            isSeller={true}
            onClose={handleCloseDrawer}
            vehicle={vehicle}
            index={0}
          />
        }
      /> */}
      {/* <SharedDrawer
        isOpenSidebar={openBidDrawer}
        onCloseSidebar={handleCloseBidDrawer}
        drawerContent={
          <HistoryDrawerContent
            isSeller={true}
            onClose={handleCloseBidDrawer}
            vehicle={vehicle}
            index={1}
          />
        }
      /> */}
      {/* {vehicle && (
        <AuctionDrawer
          vehicle={vehicle}
          tabIndex={0}
          isOpenSidebar={openVDPDrawer}
          onCloseSidebar={() => setOpenVDPDrawer(!openVDPDrawer)}
        />
      )} */}
      <Dialog open={openModal} onClose={handleCloseModal} sx={{ zIndex: '9999999' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '20px',
            position: 'relative',
            minWidth: '500px',
            borderBottom: `1px solid ${theme.palette.divider}`
          }}
        >
          <Typography component="p" variant="h5">
            Cancel Sale
          </Typography>
          <CloseIcon
            onClick={handleCloseModal}
            sx={{
              position: 'absolute',
              right: '10px',
              cursor: 'pointer'
            }}
          />
        </Box>
        <Box sx={{ padding: '40px' }}>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: '10px'
            }}
          ></Box>
          <Box sx={{ textAlign: 'center' }}>
            <Typography>Are you sure you want to CANCEL this sale?</Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0px 40px 20px 40px'
          }}
        >
          <Button
            size="large"
            variant="contained"
            className={styles.cancelButton}
            onClick={handleCloseModal}
          >
            Cancel
          </Button>
          <LoadingButton
            size="large"
            type="submit"
            variant="contained"
            className={styles.declineButton}
          >
            Confirm
          </LoadingButton>
        </Box>
      </Dialog>
    </Grid>
  );
}
