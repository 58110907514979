import { Box, Container, Grid, Typography, Link } from '@mui/material';
import useSettings from '../../hooks/useSettings';
import Page from '../../components/Page';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SecurityIcon from '@mui/icons-material/Security';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Link as RouterLink } from 'react-router-dom';
import { PATH_PROFILE } from '../../routes/paths';
import { useState } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import useAuth from 'hooks/useAuth';
import PersonIcon from '@mui/icons-material/Person';

export default function ProfilePage() {
  const { themeStretch } = useSettings();
  const theme = useTheme();
  const { currentUser } = useAuth();
  const [accountHover, setAccountHover] = useState(false);
  const [securityHover, setSecurityHover] = useState(false);
  const [notificationsHover, setNotificationsHover] = useState(false);
  const [adminHover, setAdminHover] = useState(false);
  const [billingHover, setBillingHover] = useState(false);
  // const [repHover, setRepHover] = useState(false);
  // const [open, setOpen] = useState<HTMLElement | null>(null);

  const useStyles = makeStyles({
    // bannerRow: {
    //   display: 'flex',
    //   flexDirection: 'column',
    //   alignItems: 'center',
    //   justifyContent: 'center',
    //   position: 'relative',
    //   borderRadius: '8px',
    //   width: '100%',
    //   height: '245px',
    //   backgroundColor: theme.palette.text.secondary,
    //   backgroundImage: `url(${currentUser?.bannerUrl})`,
    //   backgroundPosition: 'center',
    //   backgroundSize: 'cover',
    //   backgroundRepeat: 'no-repeat'
    // },
    avatarContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      position: 'absolute',
      left: '30px'
    },
    avatarCircle: {
      width: '115px',
      height: '115px',
      borderRadius: '50%',
      backgroundColor: 'white',
      backgroundImage: `url(${currentUser?.avatarUrl})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat'
    },
    iconContainer: {
      backgroundColor: theme.palette.background.paper,
      padding: '5px',
      borderRadius: '5px',
      width: '50px',
      height: '50px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    iconContainerHover: {
      backgroundColor: theme.palette.primary.lighter,
      padding: '5px',
      borderRadius: '5px',
      width: '50px',
      height: '50px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    linkContainer: {
      width: '32%',
      minWidth: '265px',
      marginRight: '2%',
      marginBottom: '20px',
      cursor: 'pointer',
      borderRadius: '8px',
      border:
        theme.palette.mode === 'dark'
          ? `1px solid ${theme.palette.text.secondary}`
          : `1px solid ${theme.palette.divider}`,
      color: theme.palette.info.lighter,
      '&:last-of-type': {
        marginRight: '0px'
      }
    },
    linkContainerHover: {
      width: '32%',
      minWidth: '265px',
      marginRight: '2%',
      marginBottom: '20px',
      cursor: 'pointer',
      borderRadius: '8px',
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      '&:last-of-type': {
        marginRight: '0px'
      }
    },
    link: {
      width: '100%',
      height: '100%',
      padding: '15px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      position: 'relative'
    }
  });

  const styles = useStyles();

  // const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
  //   setOpen(event.currentTarget);
  //   event.preventDefault();
  // };

  // const handleClose = () => {
  //   setOpen(null);
  // };

  return (
    <Page title="User Profile | DealerGavel">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        {/* <Box className={styles.bannerRow}>
          <Box className={styles.avatarContainer}>
            {currentUser?.avatarUrl ? (
              <Avatar
                sx={{ width: '50%', height: '50%' }}
                alt="My Avatar"
                src={currentUser?.avatarUrl}
              />
            ) : (
              <Avatar
                sx={{ width: '50%', height: '50%' }}
                alt="My Avatar"
                src="/static/mock-images/avatars/avatar_default.jpg"
              />
            )}
            <Typography sx={{ marginTop: '5px', color: theme.palette.common.white }} variant="h6">
              {currentUser.firstName} {currentUser.lastName}
            </Typography>
            <Typography paragraph sx={{ color: theme.palette.common.white }}>
              {currentUser.email}
            </Typography>
          </Box>
        </Box> */}
        <Grid
          sx={{ marginTop: '20px' }}
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Link
            onMouseEnter={() => setAccountHover(true)}
            onMouseLeave={() => setAccountHover(false)}
            className={accountHover ? styles.linkContainerHover : styles.linkContainer}
            sx={{ height: '100px' }}
            to={PATH_PROFILE.accountInformation}
            component={RouterLink}
            underline="none"
          >
            <Box className={styles.link}>
              <Box sx={{ display: 'flex' }}>
                <Box className={accountHover ? styles.iconContainerHover : styles.iconContainer}>
                  <AccountCircleIcon />
                </Box>
                <Box sx={{ marginLeft: '15px' }}>
                  <Typography
                    sx={{
                      color: theme.palette.text.primary,
                      fontWeight: '700',
                      fontSize: '16px'
                    }}
                  >
                    Account Information
                  </Typography>
                  <Typography sx={{ color: theme.palette.info.lighter, fontSize: '14px' }}>
                    Profile picture and Name
                  </Typography>
                </Box>
              </Box>
              {accountHover && <ArrowForwardIcon />}
            </Box>
          </Link>
          <Link
            onMouseEnter={() => setSecurityHover(true)}
            onMouseLeave={() => setSecurityHover(false)}
            className={securityHover ? styles.linkContainerHover : styles.linkContainer}
            sx={{ height: '100px' }}
            to={PATH_PROFILE.security}
            component={RouterLink}
            underline="none"
          >
            <Box className={styles.link}>
              <Box sx={{ display: 'flex' }}>
                <Box className={securityHover ? styles.iconContainerHover : styles.iconContainer}>
                  <SecurityIcon />
                </Box>
                <Box sx={{ marginLeft: '15px' }}>
                  <Typography
                    sx={{
                      color: theme.palette.text.primary,
                      fontWeight: '700',
                      fontSize: '16px'
                    }}
                  >
                    Security
                  </Typography>
                  <Typography sx={{ color: theme.palette.info.lighter, fontSize: '14px' }}>
                    Password and security
                  </Typography>
                </Box>
              </Box>
              {securityHover && <ArrowForwardIcon />}
            </Box>
          </Link>
          <Link
            onMouseEnter={() => setNotificationsHover(true)}
            onMouseLeave={() => setNotificationsHover(false)}
            className={notificationsHover ? styles.linkContainerHover : styles.linkContainer}
            sx={{ height: '100px' }}
            to={PATH_PROFILE.notifications}
            component={RouterLink}
            underline="none"
          >
            <Box className={styles.link}>
              <Box sx={{ display: 'flex' }}>
                <Box
                  className={notificationsHover ? styles.iconContainerHover : styles.iconContainer}
                >
                  <NotificationsIcon />
                </Box>
                <Box sx={{ marginLeft: '15px' }}>
                  <Typography
                    sx={{
                      color: theme.palette.text.primary,
                      fontWeight: '700',
                      fontSize: '16px'
                    }}
                  >
                    Notifications
                  </Typography>
                  <Typography sx={{ color: theme.palette.info.lighter, fontSize: '14px' }}>
                    Your email notifications
                  </Typography>
                </Box>
              </Box>
              {notificationsHover && <ArrowForwardIcon />}
            </Box>
          </Link>
        </Grid>
        <Grid
          sx={{ marginTop: '20px' }}
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          {(currentUser.currentDealershipRole.toLowerCase() === 'admin' ||
            currentUser.currentDealershipRole.toLowerCase() === 'superadmin') && (
            <>
              <Link
                onMouseEnter={() => setAdminHover(true)}
                onMouseLeave={() => setAdminHover(false)}
                sx={{ height: '100px' }}
                className={adminHover ? styles.linkContainerHover : styles.linkContainer}
                to={PATH_PROFILE.manageAccount}
                state={{ from: 'admin', billing: false }}
                component={RouterLink}
                underline="none"
              >
                <Box className={styles.link}>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex' }}>
                      <Box
                        className={adminHover ? styles.iconContainerHover : styles.iconContainer}
                      >
                        <AccountCircleIcon />
                      </Box>
                      <Box sx={{ marginLeft: '15px' }}>
                        <Typography
                          sx={{
                            color: theme.palette.text.primary,
                            fontWeight: '700',
                            fontSize: '16px'
                          }}
                        >
                          Dealership Profile
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <PersonIcon />
                          <Typography
                            sx={{
                              color: theme.palette.info.lighter,
                              fontSize: '14px'
                            }}
                          >
                            Dealership Information
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  {adminHover && <ArrowForwardIcon />}
                </Box>
              </Link>
              <Link
                onMouseEnter={() => setBillingHover(true)}
                onMouseLeave={() => setBillingHover(false)}
                sx={{ height: '100px' }}
                className={billingHover ? styles.linkContainerHover : styles.linkContainer}
                to={PATH_PROFILE.manageAccount}
                state={{ from: 'admin', billing: true }}
                component={RouterLink}
                underline="none"
              >
                <Box className={styles.link}>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex' }}>
                      <Box
                        className={billingHover ? styles.iconContainerHover : styles.iconContainer}
                      >
                        <SecurityIcon />
                      </Box>
                      <Box sx={{ marginLeft: '15px' }}>
                        <Typography
                          sx={{
                            color: theme.palette.text.primary,
                            fontWeight: '700',
                            fontSize: '16px'
                          }}
                        >
                          Billing Profile
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <PersonIcon />
                          <Typography sx={{ color: theme.palette.info.lighter, fontSize: '14px' }}>
                            Billing Information
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  {billingHover && <ArrowForwardIcon />}
                </Box>
              </Link>
            </>
          )}
        </Grid>
      </Container>
    </Page>
  );
}
