import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Popover,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Link as RouterLink } from 'react-router-dom';
import { DealerRequest } from 'types/request';
import { useFirestoreByPath } from 'hooks/useFirestoreByPath';
import { arrayRemove, arrayUnion, doc, getDoc } from 'firebase/firestore';
import { useFirestore } from 'reactfire';
import { Dealership } from 'types/dealership';
import { useSnackbar } from 'notistack';
import useAuth from 'hooks/useAuth';
import CloseIcon from '@mui/icons-material/Close';
import canFlag from '../../assets/CA.svg';
import usaFlag from '../../assets/US.svg';

interface Props {
  name?: string;
  address?: string;
  brands?: any[];
  status?: string;
  handleMenuItemClick?: any;
  handleDealerMenuItemClick?: any;
  showButtons?: boolean;
  showStatus?: boolean;
  request?: DealerRequest;
  hideMore?: boolean;
  inviteButton?: boolean;
  isBuyer?: boolean;
  newDealer?: boolean;
  country?: string;
}

export default function DealershipCard({
  handleMenuItemClick,
  handleDealerMenuItemClick,
  address,
  name,
  status,
  brands,
  showButtons,
  showStatus,
  request,
  hideMore,
  inviteButton,
  newDealer,
  isBuyer,
  country
}: Props) {
  const firestore = useFirestore();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [sortedBrands, setSortedBrands] = useState<string[]>();
  const { currentUser } = useAuth();

  const [dialogOpen, setDialogOpen] = useState(false);
  const handleDialogChange = () => {
    setDialogOpen(!dialogOpen);
  };

  const { update: updateRequest, remove: removeRequest } =
    useFirestoreByPath<DealerRequest>('requests');

  const { update: updateDealership } = useFirestoreByPath<Dealership>('dealerships');

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const MENU_OPTIONS = [
    { label: 'View Dealership', linkTo: '#' },
    { label: 'Remove Dealership', linkTo: '#' }
  ];

  const SIMPLE_OPTIONS = [{ label: 'View Dealership', linkTo: '#' }];

  const NEW_DEALER_OPTIONS = [
    { label: isBuyer ? 'Resend Request' : 'Resend Invite', linkTo: '#' },
    { label: isBuyer ? 'Remove Request' : 'Remove Invite', linkTo: '#' }
  ];

  const INVITE_OPTIONS = [
    { label: 'View Dealership', linkTo: '#' },
    { label: isBuyer ? 'Resend Request' : 'Resend Invite', linkTo: '#' },
    { label: isBuyer ? 'Remove Request' : 'Remove Invite', linkTo: '#' }
  ];

  const handleDeclineRequest = async () => {
    if (
      currentUser.currentDealershipRole === 'admin' ||
      currentUser.currentDealershipRole === 'superAdmin'
    ) {
      await removeRequest(request?.id as string);
      if (request?.emailType === 'invite') {
        await updateDealership(request?.requestingDealershipId as string, {
          outgoingInvites: arrayRemove(request?.receivingDealershipId)
        });
        await updateDealership(request?.receivingDealershipId as string, {
          outgoingRequests: arrayRemove(request?.requestingDealershipId)
        });
      } else {
        await updateDealership(request?.requestingDealershipId as string, {
          outgoingRequests: arrayRemove(request?.receivingDealershipId)
        });
        await updateDealership(request?.receivingDealershipId as string, {
          outgoingInvites: arrayRemove(request?.requestingDealershipId)
        });
      }
      enqueueSnackbar('Dealership Removed', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        }
      });
    } else {
      handleDialogChange();
    }
  };

  const handleAcceptRequest = async () => {
    if (
      currentUser.currentDealershipRole === 'admin' ||
      currentUser.currentDealershipRole === 'superAdmin'
    ) {
      await updateRequest(request?.id as string, { requestStatus: 'accepted' });
      const requestingRef = doc(firestore, `dealerships/${request?.requestingDealershipId}`);
      const requestingSnap = await getDoc(requestingRef);
      if (requestingSnap.exists()) {
        if (request?.requestingType === 'buy-from-me') {
          await updateDealership(requestingSnap.id, {
            networkDealerships: arrayUnion(request?.receivingDealershipId)
          });
        } else {
          await updateDealership(requestingSnap.id, {
            joinedDealerships: arrayUnion(request?.receivingDealershipId)
          });
        }
      }
      const receivingRef = doc(firestore, `dealerships/${request?.receivingDealershipId}`);
      const receivingSnap = await getDoc(receivingRef);
      if (receivingSnap.exists()) {
        if (request?.requestingType === 'buy-from-me') {
          await updateDealership(receivingSnap.id, {
            joinedDealerships: arrayUnion(request?.requestingDealershipId)
          });
        } else {
          await updateDealership(receivingSnap.id, {
            networkDealerships: arrayUnion(request?.requestingDealershipId)
          });
        }
      }
    } else {
      handleDialogChange();
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const useStyles = makeStyles({
    menuPopover: {
      boxShadow: '0 0 2px 0 rgba(139, 148, 156, 0.24),0 10px 20px -4px rgba(139, 148, 156, 0.24)',
      paddingTop: '20px',
      paddingBottom: '20px',
      '@global': {
        '.MuiMenuItem-root': {
          paddingBottom: '6px'
        },
        '.MuiMenuItem-root:last-child': {
          color: theme.palette.error.dark
        }
      }
    },
    simplePopover: {
      boxShadow: '0 0 2px 0 rgba(139, 148, 156, 0.24),0 10px 20px -4px rgba(139, 148, 156, 0.24)',
      paddingTop: '20px',
      paddingBottom: '20px',
      '@global': {
        '.MuiMenuItem-root': {
          paddingBottom: '6px'
        }
      }
    }
  });

  const styles = useStyles();

  useEffect(() => {
    if (brands) {
      const tempBrands: string[] = [];
      brands.forEach((brand: { name: string }, index: number) => {
        if (index === 0) {
          tempBrands.push(brand.name);
        } else {
          tempBrands.push(`, ${brand.name}`);
        }
      });
      setSortedBrands(tempBrands);
    }
  }, [brands]);

  return (
    <>
      <Box
        sx={{
          borderRadius: '10px',
          border:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`,
          padding: '20px',
          width: '350px',
          minHeight: '180px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'space-between'
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
            {country && country.toLowerCase() === 'canada' && (
              <img src={canFlag} style={{ width: '30px', marginTop: '7px' }} />
            )}
            {country && country.toLowerCase() === 'usa' && (
              <img src={usaFlag} style={{ width: '30px', marginTop: '7px' }} />
            )}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                marginLeft: '5px'
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  color:
                    theme.palette.mode === 'dark'
                      ? theme.palette.common.white
                      : theme.palette.text.primary
                }}
              >
                {name}
              </Typography>
              <Typography>{address}</Typography>
            </Box>
          </Box>
          {inviteButton && (
            <>
              <IconButton
                aria-describedby={id}
                onClick={handleClick}
                id="sellNetwork4"
                sx={{
                  p: 1,
                  backgroundColor:
                    theme.palette.mode === 'dark' ? '#2D2F3D' : theme.palette.background.paper
                }}
              >
                <MoreVertIcon sx={{ color: theme.palette.mode === 'dark' ? '#989DB1' : '' }} />
              </IconButton>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                sx={{ width: 220 }}
                PaperProps={{
                  classes: { root: showButtons ? styles.simplePopover : styles.menuPopover }
                }}
              >
                {newDealer ? (
                  <>
                    {NEW_DEALER_OPTIONS.map((option, index) => (
                      <MenuItem
                        key={option.label}
                        to={option.linkTo}
                        component={RouterLink}
                        onClick={() => handleDealerMenuItemClick(index)}
                        sx={{ typography: 'body2', py: 1, px: 2.5 }}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </>
                ) : (
                  <>
                    {INVITE_OPTIONS.map((option, index) => (
                      <MenuItem
                        key={option.label}
                        to={option.linkTo}
                        component={RouterLink}
                        onClick={() => handleMenuItemClick(index)}
                        sx={{ typography: 'body2', py: 1, px: 2.5 }}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </>
                )}
                {/* {INVITE_OPTIONS.map((option, index) => (
                  <MenuItem
                    key={option.label}
                    to={option.linkTo}
                    component={RouterLink}
                    onClick={() => handleMenuItemClick(index)}
                    sx={{ typography: 'body2', py: 1, px: 2.5 }}
                  >
                    {option.label}
                  </MenuItem>
                ))} */}
              </Popover>
            </>
          )}
          {hideMore ? (
            <></>
          ) : (
            <>
              <IconButton
                aria-describedby={id}
                onClick={handleClick}
                id="sellNetwork4"
                sx={{
                  p: 1,
                  backgroundColor:
                    theme.palette.mode === 'dark' ? '#2D2F3D' : theme.palette.background.paper
                }}
              >
                <MoreVertIcon sx={{ color: theme.palette.mode === 'dark' ? '#989DB1' : '' }} />
              </IconButton>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                sx={{ width: 220 }}
                PaperProps={{
                  classes: { root: showButtons ? styles.simplePopover : styles.menuPopover }
                }}
              >
                {showButtons ? (
                  <>
                    {SIMPLE_OPTIONS.map((option, index) => (
                      <MenuItem
                        key={option.label}
                        to={option.linkTo}
                        component={RouterLink}
                        onClick={() => handleMenuItemClick(index)}
                        sx={{ typography: 'body2', py: 1, px: 2.5 }}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </>
                ) : (
                  <>
                    {MENU_OPTIONS.map((option, index) => (
                      <MenuItem
                        key={option.label}
                        to={option.linkTo}
                        component={RouterLink}
                        onClick={() => handleMenuItemClick(index)}
                        sx={{ typography: 'body2', py: 1, px: 2.5 }}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </>
                )}
              </Popover>
            </>
          )}
        </Box>
        <Box sx={{ margin: '10px 0px' }}>
          {sortedBrands && <Typography>{sortedBrands}</Typography>}
        </Box>
        {showStatus && (
          <Box>
            <Box
              sx={{
                backgroundColor:
                  request?.requestStatus === 'pending'
                    ? theme.palette.error.light
                    : theme.palette.success.light,
                padding: '0px 15px',
                width: 'fit-content',
                borderRadius: '15px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Typography
                sx={{
                  fontWeight: '700',
                  fontSize: '12px',
                  color:
                    request?.requestStatus === 'pending'
                      ? theme.palette.error.main
                      : theme.palette.success.main,
                  textTransform: 'capitalize'
                }}
              >
                {status}
              </Typography>
            </Box>
          </Box>
        )}
        {showButtons && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              margin: '10px 0px'
            }}
          >
            <Button variant="outlined" size="medium" onClick={handleDeclineRequest}>
              Decline
            </Button>
            <Button variant="contained" size="medium" onClick={handleAcceptRequest}>
              Accept
            </Button>
          </Box>
        )}
      </Box>
      <Dialog open={dialogOpen} onClose={handleDialogChange}>
        <Box>
          <DialogTitle>Permissions Error</DialogTitle>
          <CloseIcon
            onClick={handleDialogChange}
            sx={{
              cursor: 'pointer',
              position: 'absolute',
              right: '15px',
              top: '24px'
            }}
          />
        </Box>
        <DialogContent>
          You must be an admin to perform this action, please reach out to your admin.
        </DialogContent>
      </Dialog>
    </>
  );
}
