import { VehicleInfo } from 'types/vehicleInfo';
import { VehicleDetailsType } from 'types/vehicleInfo';

export const loadVinDataAPI = async (vehicle: VehicleInfo) => {
  const tmpVehicle = { ...vehicle };
  const tmpVehDetails: VehicleDetailsType = tmpVehicle.details;
  const axios = require('axios');

  const url = 'https://vin-decoder19.p.rapidapi.com/vin_decoder_extended';
  const apiKey = 'JYRYXYXqk1mshQKx3K8mhgya9XS4p13mO03jsnLLt7XiGjEAL5'; // '862bdfe7c9mshed5118d4dd15e27p1b9008jsn1a93cc484d9b';

  const options = {
    method: 'GET',
    url: url,
    params: { vin: tmpVehDetails.vin },
    headers: { 'X-RapidApi-Key': apiKey },
    returnBody: true,
    encodeBodyUtf8: false,
    decodeUtf8: false,
    cache: false
  };

  let response: any;
  try {
    response = await axios.request(options);
  } catch (error) {
    return false;
  }

  if (response.data.Status !== 'SUCCESS') return false;

  const tmpSpec = response.data;
  tmpVehicle.dataFound = true;

  // YEAR
  tmpVehDetails.year = tmpSpec.Model_Year ? tmpSpec.Model_Year.value : '';

  // MAKE
  tmpVehDetails.make = tmpSpec.Make.value ? tmpSpec.Make.value : '';

  // MODEL
  tmpVehDetails.model = tmpSpec.Model.value ? tmpSpec.Model.value : '';

  // BODY STYLE
  // prettier-ignore
  tmpVehDetails.bodyStyle = tmpSpec.Body_Style.value && tmpSpec.Body_Style.value.toLowerCase() !== 'no data' ? tmpSpec.Body_Style.value : '';

  // TRANSMISSION
  // prettier-ignore
  // tmpVehDetails.transmission = tmpSpec['Transmission-long'].value && tmpSpec['Transmission-long'].value.toLowerCase() !== 'no data' ? tmpSpec['Transmission-long'].value : '';

  // DRIVETRAIN
  // prettier-ignore
  tmpVehDetails.driveTrain = tmpSpec.Driveline.value && tmpSpec.Driveline.value.toLowerCase() !== 'no data' ? tmpSpec.Driveline.value : '';

  // DISPLACEMENT
  // prettier-ignore
  tmpVehDetails.displacement = tmpSpec.Engine_Displacement.value && tmpSpec.Engine_Displacement.value.toLowerCase() !== 'no data' ? tmpSpec.Engine_Displacement.value : '';

  // FUEL TYPE
  // prettier-ignore
  tmpVehDetails.fuelType = tmpSpec.Fuel_Type.value && tmpSpec.Fuel_Type.value.toLowerCase() !== 'no data' ? tmpSpec.Fuel_Type.value : '';

  // ENGINE
  // tmpVehDetails.engine = tmpSpec.Engine_Type.value ? tmpSpec.Engine_Type.value : '';

  return tmpVehicle;
};
