import { VehicleInfo } from 'types/vehicleInfo';

export const loadModelOptions = async (vehicle: VehicleInfo) => {
  const tempVehicle = { ...vehicle };
  const axios = require('axios');
  const baseUrl = 'https://car-api2.p.rapidapi.com/api';

  const options = {
    method: 'GET',
    url: `${baseUrl}/bodies`,
    params: {
      year: tempVehicle.details.year,
      make: tempVehicle.details.make,
      model: tempVehicle.details.model
    },
    headers: {
      'X-RapidAPI-Key': 'JYRYXYXqk1mshQKx3K8mhgya9XS4p13mO03jsnLLt7XiGjEAL5' // '862bdfe7c9mshed5118d4dd15e27p1b9008jsn1a93cc484d9b'
    },
    returnBody: true,
    encodeBodyUtf8: false,
    decodeUtf8: false,
    cache: false
  };

  const fallbackBodies = {
    data: {
      data: [
        {
          type: 'Sedan'
        },
        {
          type: 'Suv'
        },
        {
          type: 'Truck'
        },
        {
          type: 'Van'
        },
        {
          type: 'Wagon'
        },
        {
          type: 'Coupe'
        }
      ]
    }
  };

  let response: any;
  try {
    response = await axios.request(options);
  } catch (error) {
    return fallbackBodies;
  }

  if (response.data.Status !== 'SUCCESS') return fallbackBodies;
  const tempOptions = response.data;
  if (tempOptions.length > 0) {
    return tempOptions;
  } else {
    return fallbackBodies;
  }
};
