import { Box, IconButton, List, ListItem, Tooltip, Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { VehicleInfo } from 'types/vehicleInfo';
import useAuth from 'hooks/useAuth';
import { FC } from 'react';
import React from 'react';

interface Props {
  vehicle: VehicleInfo;
  width: string;
  handleWatchlist: any;
  mediaQuery: number;
  isPreAuction?: boolean;
}
const VehicleSummary: FC<Props> = React.memo(function VehicleSummary({
  vehicle,
  width,
  handleWatchlist,
  isPreAuction,
  mediaQuery
}) {
  const theme = useTheme();
  const { user } = useAuth();

  const useStyles = makeStyles({
    container: {
      position: 'relative',
      borderWidth: '1px',
      borderTopStyle: 'solid',
      borderBottomStyle: 'solid',
      borderRightStyle: 'solid',
      borderColor:
        theme.palette.mode === 'dark' ? theme.palette.text.secondary : theme.palette.divider
    },
    infoContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '10px',
      position: 'relative'
    },
    iconButton: {
      position: 'absolute',
      right: '5px',
      top: '5px',
      width: '30px',
      height: '30px',
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.primary.main,
      borderRadius: '3px'
    },
    icon: {
      width: '20px',
      height: '20px'
    },
    vehicleNameText: {
      lineHeight: 1,
      width: 'calc(100% - 30px)',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineClamp: 2,
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical'
      // whiteSpace: 'nowrap',
      // maxWidth: '225px',
    },
    vinNumber: {
      // lineHeight: 1.5,
      margin: '0px',
      color: theme.palette.primary.main,
      fontSize: '12px',
      fontWeight: '700',
      wordWrap: 'break-word',
      textTransform: 'uppercase'
    },
    color: {
      // lineHeight: 1.5,
      margin: '0px',
      // margin: '0px 0px 15px 0px',
      color: theme.palette.primary.main,
      fontSize: '12px',
      fontWeight: '700',
      wordWrap: 'break-word',
      textTransform: 'uppercase',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: '225px'
    },
    vehicleStats: {
      lineHeight: '1.25',
      fontSize: '12px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: '225px'
    },
    bidListContainer: {
      maxHeight: '55px',
      width: '100%',
      position: 'absolute',
      bottom: '0px',
      borderTopWidth: '1px',
      borderTopStyle: 'solid',
      borderTopColor:
        theme.palette.mode === 'dark' ? theme.palette.text.secondary : theme.palette.divider,
      flex: 1,
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        '-webkit-appearance': 'none'
      },
      '&::-webkit-scrollbar:vertical': {
        width: '9px'
      },
      '&::-webkit-scrollbar:horizontal': {
        height: '9px'
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '8px',
        backgroundColor: 'rgba(0, 0, 0, .5)',
        border: '1px solid white'
      }
    },
    bidListItem: {
      lineHeight: '1',
      fontSize: '13px',
      paddingTop: '2px',
      paddingBottom: '2px'
    }
  });

  const styles = useStyles();

  return (
    <Box className={styles.container} sx={{ width: width }}>
      <Box className={styles.infoContainer}>
        <Tooltip title="Watchlist">
          <IconButton
            id="preAuctionBuy3"
            className={styles.iconButton}
            onClick={() => {
              handleWatchlist(vehicle.id, vehicle.auction.isWatchlist.includes(user?.uid));
            }}
          >
            {vehicle && vehicle.auction.isWatchlist.includes(user?.uid) ? (
              <BookmarkIcon className={styles.icon} />
            ) : (
              <BookmarkBorderIcon className={styles.icon} />
            )}
          </IconButton>
        </Tooltip>
        <Typography
          component="p"
          // variant="h5"
          className={styles.vehicleNameText}
          sx={{
            fontSize: '1.1rem',
            fontWeight: 'bold',
            color:
              theme.palette.mode === 'dark'
                ? theme.palette.common.white
                : theme.palette.text.primary
          }}
        >
          {vehicle.details.year} {vehicle.details.make}&nbsp;
          {vehicle.details.model} {vehicle.details.trim}
        </Typography>
        <Typography component="p" className={styles.vinNumber}>
          VIN: {vehicle.details.vin ? vehicle.details.vin : 'No VIN'}
        </Typography>
        <Typography component="p" className={styles.color}>
          Colour: {vehicle.details.extColor}
        </Typography>
        {vehicle.disclosures.odometerStatus &&
        vehicle.disclosures.odometerStatus.toLowerCase() === 'broken' ? (
          // prettier-ignore
          <Typography component="p" className={styles.vehicleStats}>
            {vehicle.disclosures.odometerReading} {vehicle.details.mileageType} | {vehicle.dealershipName ? vehicle.dealershipName : vehicle.auction.sellerName} | {vehicle.auction.pickUpLocation.locationProvince}
          </Typography>
        ) : (
          // prettier-ignore
          <Typography component="p" className={styles.vehicleStats}>
            {vehicle.details.mileage} {vehicle.details.mileageType} | {vehicle.dealershipName ? vehicle.dealershipName : vehicle.auction.sellerName} | {vehicle.auction.pickUpLocation ? vehicle.auction.pickUpLocation.locationProvince : 'No Pickup Location Set'}
          </Typography>
        )}
      </Box>
      {!isPreAuction &&
      vehicle &&
      vehicle.auction.previousBids &&
      vehicle.auction.previousBids.length > 0 &&
      vehicle.auction.previousBids !== null ? (
        <Box className={styles.bidListContainer}>
          <List sx={{ paddingTop: '5px !important', paddingBottom: '5px !important' }}>
            {vehicle.auction.previousBids &&
              vehicle.auction.previousBids !== null &&
              vehicle.auction.previousBids
                .map((bid: any, index: number) => (
                  <ListItem
                    className={styles.bidListItem}
                    key={index}
                    sx={{
                      color:
                        bid.bidderId === user?.uid
                          ? theme.palette.success.main
                          : theme.palette.text.primary
                    }}
                  >
                    ${bid.price}: {bid.bidderName}
                  </ListItem>
                ))
                .reverse()}
          </List>
        </Box>
      ) : (
        <Box></Box>
      )}
    </Box>
  );
});

export default VehicleSummary;
