import {
  Container,
  Typography,
  Grid,
  Paper,
  Box,
  Drawer,
  MenuItem,
  Select,
  InputBase,
  Tabs,
  Tab,
  SelectChangeEvent,
  TablePagination,
  Divider,
  AlertTitle,
  Alert,
  Button
} from '@mui/material';
import {
  collection,
  CollectionReference,
  doc,
  getDoc,
  query,
  where,
  Timestamp,
  DocumentReference,
  onSnapshot,
  arrayRemove
} from 'firebase/firestore';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import { useTheme } from '@mui/material/styles';
import { DrawerContent } from '../../components/auction';
import * as React from 'react';
import { useState, useEffect } from 'react';
import useSettings from '../../hooks/useSettings';
import Page from '../../components/Page';
import { DebounceInput } from 'react-debounce-input';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { Dealership } from 'types/dealership';
import { styled } from '@mui/material/styles';
import DealershipCard from 'components/admin/DealershipCard';
import { DealerRequest } from 'types/request';
import useAuth from 'hooks/useAuth';
import { useFirestoreByPath } from 'hooks/useFirestoreByPath';
import { useSnackbar } from 'notistack';
import Tour from 'reactour';
import { User } from 'constants/user';
import EmptyStateMessage from 'components/shared/EmptyStateMessage';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';

export default function BuyerNetworkPage() {
  const { themeStretch } = useSettings();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const { currentUser, user, logout } = useAuth();
  const firestore = useFirestore();
  const [searchValue, setSearchValue] = useState('');
  const [isFiltered] = useState<boolean>(false);
  const [filterChange] = useState<boolean>(false);
  const [tabValue, setTabValue] = useState(0);
  let [filters] = useState<any>([{}]);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  const [order, setOrder] = useState('name');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [incomingOrder, setIncomingOrder] = useState('name');
  const [incomingPage, setIncomingPage] = useState(0);
  const [incomingRowsPerPage, setIncomingRowsPerPage] = useState(25);

  const [outgoingOrder, setOutgoingOrder] = useState('name');
  const [outgoingPage, setOutgoingPage] = useState(0);
  const [outgoingRowsPerPage, setOutgoingRowsPerPage] = useState(25);

  const [currentDealership, setCurrentDealership] = useState<Dealership>();
  const [, setMyDealership] = useState<Dealership>();

  const [joinedAuctions, setJoinedAuctions] = useState<Dealership[]>();
  const [incomingRequests, setIncomingRequests] = useState<DealerRequest[]>();
  const [outgoingRequests, setOutgoingRequests] = useState<DealerRequest[]>();

  const invitePath = `users/${user?.uid}/invites`;
  // prettier-ignore
  const {
    data: invites,
    update: updateInvite,
    remove: removeInvite
  } = useFirestoreByPath<any>(
    invitePath,
    where('inviteType', '==', 'dealership')
  );

  const requestRef = collection(firestore, 'requests') as CollectionReference<DealerRequest>;
  const requestQuery = query(requestRef);
  const { data: allRequests } = useFirestoreCollectionData(requestQuery, { idField: 'id' });
  const [, setRequests] = useState<DealerRequest[]>();

  const dealershipsRef = collection(firestore, 'dealerships') as CollectionReference<Dealership>;
  // const userDealershipRef = doc(firestore, `dealerships/${currentUser?.currentDealershipId}`);
  const dealershipsQuery = query(
    dealershipsRef,
    where('networkDealerships', 'array-contains', currentUser.currentDealershipId)
  );
  const { data: allDealerships } = useFirestoreCollectionData(dealershipsQuery, { idField: 'id' });
  const { update: updateDealership } = useFirestoreByPath<Dealership>('dealerships');
  const { remove: removeRequest, update: updateRequest } =
    useFirestoreByPath<DealerRequest>('requests');

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOrderByChange = (event: SelectChangeEvent) => {
    setOrder(event.target.value);
  };

  const handleIncomingChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setIncomingPage(newPage);
  };

  const handleIncomingChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setIncomingRowsPerPage(parseInt(event.target.value, 10));
    setIncomingPage(0);
  };

  const handleIncomingOrderByChange = (event: SelectChangeEvent) => {
    setIncomingOrder(event.target.value);
  };

  const handleOutgoingChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setOutgoingPage(newPage);
  };

  const handleOutgoingChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setOutgoingRowsPerPage(parseInt(event.target.value, 10));
    setOutgoingPage(0);
  };

  const handleOutgoingOrderByChange = (event: SelectChangeEvent) => {
    setOutgoingOrder(event.target.value);
  };

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  interface StyledTabsProps {
    id?: string;
    children?: React.ReactNode;
    value: number;
    onChange: (event: React.SyntheticEvent, newValue: number) => void;
  }

  const a11yProps = (index: number) => {
    return {
      id: `dealer-network-tab-${index}`,
      'aria-controls': `dealer-network-tabpanel-${index}`
    };
  };

  const closeDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const list = () => (
    <Box sx={{ width: 400 }} role="presentation">
      {currentDealership && (
        <DrawerContent
          dealership={currentDealership as Dealership}
          logo={currentDealership ? currentDealership.logoUrl : ''}
          onClose={closeDrawer}
        />
      )}
    </Box>
  );

  const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`notifications-tabpanel-${index}`}
        aria-labelledby={`notifications-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 0 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };

  const StyledTabs = styled((props: StyledTabsProps) => (
    <Tabs
      {...props}
      variant="scrollable"
      sx={{
        minHeight: 'unset !important'
      }}
      // scrollButtons
      TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
  ))(({ theme }) => ({
    '& .MuiTab-root': {
      color:
        theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.text.secondary,
      backgroundColor:
        theme.palette.mode === 'dark'
          ? theme.palette.background.neutral
          : theme.palette.background.paper
    },
    '& .MuiTab-root:not(:last-of-type)': {
      marginRight: '10px'
    },
    '& .MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent !important',
      padding: '5px'
    },
    '& .Mui-selected': {
      backgroundColor: theme.palette.primary.lighter,
      color: `${theme.palette.primary.main} !important`
    },
    '& .MuiTabs-indicatorSpan': {
      display: 'none',
      maxWidth: 40,
      width: '100%',
      backgroundColor: theme.palette.chart.violet[2]
    }
  }));

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    sortRequestsForTabs(allRequests);
    setSearchValue('');
    setTabValue(newValue);
  };

  const handleDealerInviteItemClick = async (index: number, requestId: string) => {
    if (index === 0) {
      await updateInvite(requestId as string, {
        inviteDateSent: Timestamp.now(),
        isExpired: false
      });
      enqueueSnackbar('Invite Resent', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        }
      });
    } else {
      await removeInvite(requestId as string);
      enqueueSnackbar('Invite Deleted', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        }
      });
    }
  };

  const handleInviteItemClick = async (
    index: number,
    dealershipId: string,
    requestId?: string,
    requestType?: string
  ) => {
    if (index === 0) {
      getDealership(dealershipId);
      setDrawerOpen(!drawerOpen);
    } else if (index === 1) {
      await updateRequest(requestId as string, { requestDateSent: Timestamp.now() });
      enqueueSnackbar('Request Resent', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        }
      });
    } else {
      await removeRequest(requestId as string);
      await updateDealership(currentUser?.currentDealershipId, {
        outgoingRequests: arrayRemove(dealershipId)
      });
      await updateDealership(dealershipId, {
        outgoingInvites: arrayRemove(currentUser?.currentDealershipId)
      });
      enqueueSnackbar('Dealership Removed', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        }
      });
    }
  };

  const handleMenuItemClick = async (index: number, dealershipId: string, requestId?: string) => {
    if (index === 0) {
      getDealership(dealershipId);
      setDrawerOpen(!drawerOpen);
    } else {
      if (tabValue === 0) {
        const docRef = doc(firestore, `dealerships/${currentUser?.currentDealershipId}`);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const currentDealership = { id: docSnap.id, ...docSnap.data() } as Dealership;
          const outgoingRequest = currentDealership.outgoingRequests?.find(
            (dealerId: string) => dealerId === dealershipId
          );
          if (outgoingRequest) {
            await updateDealership(dealershipId, {
              networkDealerships: arrayRemove(currentUser?.currentDealershipId),
              outgoingInvites: arrayRemove(currentUser?.currentDealershipId)
            });
            await updateDealership(currentUser?.currentDealershipId, {
              joinedDealerships: arrayRemove(dealershipId),
              outgoingRequests: arrayRemove(dealershipId)
            });
          } else {
            await updateDealership(dealershipId, {
              networkDealerships: arrayRemove(currentUser?.currentDealershipId),
              outgoingInvites: arrayRemove(currentUser?.currentDealershipId)
            });
            await updateDealership(currentUser?.currentDealershipId, {
              joinedDealerships: arrayRemove(dealershipId),
              outgoingRequests: arrayRemove(dealershipId)
            });
          }
        }
      } else {
        await removeRequest(requestId as string);
        if (tabValue === 2) {
          await updateDealership(currentUser?.currentDealershipId, {
            outgoingRequests: arrayRemove(dealershipId)
          });
          await updateDealership(dealershipId, {
            outgoingInvites: arrayRemove(currentUser?.currentDealershipId)
          });
        }
      }
      enqueueSnackbar('Dealership Removed', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        }
      });
    }
  };

  // get users dealership for joined auctions
  const setDealership = async (dealershipId: string) => {
    const docRef = doc(firestore, `dealerships/${dealershipId}`);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const currentDealership = { id: docSnap.id, ...docSnap.data() } as Dealership;
      setMyDealership(currentDealership);
    }
  };

  // get dealership for drawer data
  const getDealership = async (dealershipId: string) => {
    const docRef = doc(firestore, `dealerships/${dealershipId}`);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const currentDealership = { id: docSnap.id, ...docSnap.data() } as Dealership;
      setCurrentDealership(currentDealership);
    }
  };

  const handleSearchChange = (searchValue: string) => {
    if (tabValue === 0) {
      const filteredDealerships = allDealerships?.filter(
        (dealership: Dealership) =>
          dealership.dealershipName.toLowerCase().includes(searchValue.toLowerCase()) ||
          dealership.province.toString().toLowerCase().includes(searchValue.toLowerCase()) ||
          // eslint-disable-next-line array-callback-return
          dealership.brands.find((brand: { name: string }) => {
            if (brand.name.toLowerCase().includes(searchValue.toLowerCase())) {
              return true;
            }
          })
      );
      setJoinedAuctions(filteredDealerships);
    } else if (tabValue === 1) {
      const filteredRequests = allRequests?.filter(
        (request: DealerRequest) =>
          request.requestingDealershipName.toLowerCase().includes(searchValue.toLowerCase()) ||
          request.requestingDealershipLocation
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          // eslint-disable-next-line array-callback-return
          request.requestingDealershipBrands.find((brand: { name: string }) => {
            if (brand.name.toLowerCase().includes(searchValue.toLowerCase())) {
              return true;
            }
          })
      );
      setRequests(filteredRequests);
    } else {
      const filteredRequests = allRequests?.filter(
        (request: DealerRequest) =>
          request.receivingDealershipName.toLowerCase().includes(searchValue.toLowerCase()) ||
          request.receivingDealershipLocation
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          // eslint-disable-next-line array-callback-return
          request.receivingDealershipBrands.find((brand: { name: string }) => {
            if (brand.name.toLowerCase().includes(searchValue.toLowerCase())) {
              return true;
            }
          })
      );
      setRequests(filteredRequests);
    }
  };

  const sortRequestsForTabs = (requests: DealerRequest[]) => {
    setJoinedAuctions(allDealerships);

    const incomingRequests = requests.filter(
      (r: DealerRequest) =>
        r.requestingType === 'buy-from-me' &&
        r.receivingType === 'buy-from-them' &&
        r.requestStatus === 'pending' &&
        r.receivingDealershipId === currentUser.currentDealershipId
    );
    setIncomingRequests(incomingRequests);

    const outgoingRequests = requests.filter(
      (r: DealerRequest) =>
        r.requestingType === 'buy-from-them' &&
        r.receivingType === 'buy-from-me' &&
        r.requestStatus === 'pending' &&
        r.requestingDealershipId === currentUser.currentDealershipId
    );
    const outgoingDealerRequests = invites.filter(
      (i: any) => i.networkType.toLowerCase() === 'request'
    );

    const outgoingCombined: DealerRequest[] = [];

    outgoingRequests.forEach((request: DealerRequest) => {
      outgoingCombined.push(request);
    });

    outgoingDealerRequests.forEach((request: any) => {
      request = { ...request, uiType: 'dealerRequest', requestStatus: 'pending' };
      outgoingCombined.push(request);
    });

    setOutgoingRequests(outgoingCombined as DealerRequest[]);
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted && currentUser) {
      setDealership(currentUser.currentDealershipId);
    }
    return () => {
      isMounted = false;
    };
    // if (currentUser) {
    //   setDealership(currentUser.currentDealershipId);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  useEffect(() => {
    if (allRequests && allDealerships && invites) {
      sortRequestsForTabs(allRequests);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allRequests, allDealerships, invites]);

  useEffect(() => {
    if (isFiltered) {
      let filteredRequests = allRequests as DealerRequest[];
      filters.forEach((filter: any) => {
        switch (filter.name) {
          case 'brand':
            filteredRequests = filteredRequests.filter((request: DealerRequest) =>
              // eslint-disable-next-line array-callback-return
              request.brands.find((brand: { name: string }) => {
                if (brand.name.toLowerCase().includes(filter.value.toLowerCase())) {
                  return true;
                }
              })
            );
            break;
        }
      });
      setRequests(filteredRequests);
      // setDealerships(filteredDealerships);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterChange]);

  const useStyles = makeStyles({
    tourContainer: {
      maxWidth: '500px !important',
      borderRadius: '10px !important'
    },
    expandIcon: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      borderRadius: '100%',
      width: '25px',
      height: '25px',
      padding: '5px'
    },
    headerText: {
      fontSize: '14px',
      marginRight: '18px',
      marginLeft: '0px'
    },
    searchContainer: {
      width: '100%',
      maxWidth: '400px',
      height: 'auto',
      padding: '5px 0px',
      // marginBottom: '23px',
      // marginRight: '40px'
      marginBottom: '0px',
      marginRight: '20px'
    },
    inputText: {
      fontSize: '14px'
    },
    dialogContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      position: 'relative'
    },
    emailRow: {
      marginTop: '10px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    inviteRow: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      marginTop: '20px'
    },
    inviteButton: {
      backgroundColor: theme.palette.primary.main
    },
    buttonRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      width: '100%',
      marginTop: '60px'
    },
    toolbar: {
      margin: 1,
      width: '200px',
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      right: '380px',
      top: '-35px'
    },
    dropdownStyle: {
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: '12px',
      lineHeight: '18px',
      color: theme.palette.info.light
    },
    search: {
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.background.neutral : 'transparent !important',
      border: theme.palette.mode === 'dark' ? 'unset' : `1px solid ${theme.palette.divider}`,
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1
    },
    searchInput: {
      fontSize: '14px',
      width: '100%',
      height: '34px',
      margin: '0 10px',
      border: 'none',
      padding: '5px 10px',
      outline: 'none !important',
      marginLeft: 2,
      flex: 1,
      backgroundColor: 'transparent !important',
      color:
        theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.text.secondary,
      '&::placeholder': {
        color:
          theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.text.secondary,
        fontSize: '14px'
      }
    },
    searchIcon: {
      fill: theme.palette.primary.main,
      marginRight: '5px'
    },
    secondaryButton: {
      backgroundColor: theme.palette.grey[50],
      color: theme.palette.info.light,
      '&:hover': {
        backgroundColor: theme.palette.grey[100]
      }
    },
    subtitle: {
      fontWeight: 'bold',
      color: theme.palette.secondary.darker
    },
    subtitle2: {
      color: theme.palette.info.light,
      fontWeight: 'normal'
    },
    list: {
      '& li:first-child, & li:last-child': {
        '& button': {
          border: `1px solid ${theme.palette.grey[50]}`,
          color: theme.palette.info.light,
          width: '100px',
          '&:hover': {
            backgroundColor: theme.palette.grey[100]
          }
        }
      },
      '& li': {
        paddingLeft: 3,
        paddingRight: 3,
        '& button': {
          color: theme.palette.info.main,
          minWidth: '40px'
        }
      }
    },
    divider: {
      width: '93%'
    },
    gridDateTitle: {
      marginRight: '64px'
    },
    menuPopover: {
      boxShadow: '0 0 2px 0 rgba(139, 148, 156, 0.24),0 10px 20px -4px rgba(139, 148, 156, 0.24)',
      // backgroundColor: theme.palette.primary.contrastText,
      paddingTop: '20px',
      paddingBottom: '20px',
      '@global': {
        '.MuiMenuItem-root': {
          paddingBottom: '6px'
        },
        '.MuiMenuItem-root:last-child': {
          // color: theme.palette.error.dark
        }
      }
    },
    headerTextContainer: {
      width: '100%',
      height: 'auto',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    controlsContainer: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'flex-end',
      [theme.breakpoints.down(675)]: {
        marginTop: '25px',
        justifyContent: 'center'
      }
    },
    pagination: {
      borderTopStyle: 'none',
      fontWeight: '700',
      borderTop: 'none'
    },
    dealerCardContainer: {
      padding: '10px 10px 10px 0px'
      // paddingLeft: '0px',
      // '&:not(first-of-type)': {
      //   paddingLeft: '10px !important'
      // }
    }
  });

  const styles = useStyles();

  const [tourOpen, setTourOpen] = useState(false);
  const { update: updateUser } = useFirestoreByPath<User>('users');
  const [curUser, setCurUser] = useState<User>();

  const [userDisabled, setUserDisabled] = useState<boolean>(false);
  const [userSuspended, setUserSuspended] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/dashboard');
    // window.location.reload();
  };

  const [userDeleted, setUserDeleted] = useState<boolean>(false);
  const snapshotUser = () => {
    const userDoc = doc(firestore, `users/${user?.uid}`) as DocumentReference<User>;
    onSnapshot(userDoc, (doc: any) => {
      const userObj = { ...doc.data(), id: doc.id } as User;
      setCurUser(userObj);
      if (!userObj.email) {
        logout?.().then(() => {
          navigate('.');
          window.location.reload();
        });
      }
      if (userObj.showBuyingNetworkTour && !tourOpen) {
        setTourOpen(true);
      }
      if (userObj && userObj.status.toLowerCase() === 'suspended') {
        setUserSuspended(true);
      } else {
        setUserSuspended(false);
      }
      if (userObj && userObj.status.toLowerCase() === 'disabled') {
        setUserDisabled(true);
      } else {
        setUserDisabled(false);
      }
    });
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted && !curUser) {
      snapshotUser();
    }
    return () => {
      isMounted = false;
    };
    // if (!curUser) {
    //   snapshotUser();
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseTour = async () => {
    setTourOpen(false);
    await updateUser(user?.uid as string, { showBuyingNetworkTour: false });
  };

  const [networkEmptyState, setNetworkEmptyState] = useState<boolean>(false);
  const [incomingEmptyState, setIncomingEmptyState] = useState<boolean>(false);
  const [outgoingEmptyState, setOutgoingEmptyState] = useState<boolean>(false);

  useEffect(() => {
    if (allDealerships && (!joinedAuctions || joinedAuctions.length < 1)) {
      setNetworkEmptyState(true);
    } else {
      setNetworkEmptyState(false);
    }
    if (allRequests && (!incomingRequests || incomingRequests.length < 1)) {
      setIncomingEmptyState(true);
    } else {
      setIncomingEmptyState(false);
    }
    if (allRequests && (!outgoingRequests || outgoingRequests.length < 1)) {
      setOutgoingEmptyState(true);
    } else {
      setOutgoingEmptyState(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allDealerships, allRequests, joinedAuctions, incomingRequests, outgoingRequests]);

  const steps = [
    {
      selector: '',
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Community: Buying Network</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
            A benefit to the DealerGavel model is the ability to build out who you buy from. Here you can keep track and manage the dealers whose network you participate in.
            </Typography>
          </Box>
        </Box>
      )
    },
    {
      selector: '#buyNetwork2',
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Community: Buying Network</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          {/* prettier-ignore */}
          <Typography sx={{  marginTop: '10px', color: theme.palette.text.secondary }}>
            Manage and build your buying network here!
          </Typography>
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
              <span style={{ fontWeight: '900' }}>Joined Auctions:</span> Manage what dealerships you want to buy from. 
            </Typography>
          </Box>
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
              <span style={{ fontWeight: '900' }}>Incoming Invites:</span> Accept or decline requests from other dealerships to join their network. Accepting a dealership allows you to buy from their dealership.
            </Typography>
          </Box>
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
              <span style={{ fontWeight: '900' }}>Outgoing Requests:</span> Manage requests that you have sent to other dealerships to request to join and buy from their dealership.
            </Typography>
          </Box>
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
              <span style={{ fontWeight: '900' }}>Tip:</span>  If you have any incoming invites or outgoing requests, the tab will highlight in green.
            </Typography>
          </Box>
        </Box>
      )
    },
    /* {
      selector: '#buyNetwork3',
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Community: Buying Network</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
         
          <Typography sx={{  marginTop: '10px', color: theme.palette.text.secondary }}>
            These are the dealership networks that you belong to. Your dealership can buy their vehicles in the Buy Active Auction.
          </Typography>
        </Box>
      )
    }, */
    {
      selector: '#sellNetwork4',
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Community: Buying Network</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          {/* prettier-ignore */}
          <Typography sx={{ marginTop: '10px', color: theme.palette.text.secondary }}>
            This menu will allow you to view more details about the Dealership. A core functionality for your control is the ability to remove your dealership from their network.
          </Typography>
        </Box>
      )
    }
  ];

  return (
    <>
      {!userDeleted && !userSuspended && !userDisabled ? (
        <Page title="Buying Network | DealerGavel">
          <Container
            maxWidth={themeStretch ? false : 'xl'}
            sx={{
              width: '100%',
              marginRight: 'auto'
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ mb: 0 }}
            >
              <Box sx={{ display: 'flex', mt: '0px', flexDirection: 'column' }}>
                <Typography
                  variant="h4"
                  component="h1"
                  paragraph
                  sx={{
                    marginBottom: '0px !important',
                    fontSize: '1.125rem !important',
                    color:
                      theme.palette.mode === 'dark'
                        ? theme.palette.common.white
                        : theme.palette.text.primary
                  }}
                >
                  Buying Network
                </Typography>
                <Typography component="p" paragraph sx={{ marginBottom: '0px', lineHeight: 1 }}>
                  Dealers you buy from
                </Typography>
              </Box>
            </Grid>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexWrap: 'wrap'
              }}
            >
              <StyledTabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="Dealer Network Tabs"
                id="buyNetwork2"
              >
                <Tab
                  sx={{
                    padding: '0px 10px',
                    borderRadius: '5px',
                    minHeight: '40px',
                    height: '40px'
                  }}
                  label={
                    <Typography sx={{ fontSize: '12px' }}>
                      JOINED AUCTIONS ({joinedAuctions && joinedAuctions.length})
                    </Typography>
                  }
                  {...a11yProps(0)}
                />
                <Tab
                  sx={{
                    backgroundColor:
                      incomingRequests && incomingRequests.length > 0
                        ? `${theme.palette.success.lighter} !important`
                        : '',
                    padding: '0px 10px',
                    borderRadius: '5px',
                    minHeight: '40px',
                    height: '40px'
                  }}
                  label={
                    <Typography sx={{ fontSize: '12px' }}>
                      INCOMING INVITES ({incomingRequests && incomingRequests.length})
                    </Typography>
                  }
                  {...a11yProps(1)}
                />
                <Tab
                  sx={{
                    backgroundColor:
                      outgoingRequests && outgoingRequests.length > 0
                        ? `${theme.palette.success.lighter} !important`
                        : '',
                    padding: '0px 10px',
                    borderRadius: '5px',
                    minHeight: '40px',
                    height: '40px'
                  }}
                  label={
                    <Typography sx={{ fontSize: '12px' }}>
                      OUTGOING REQUESTS ({outgoingRequests && outgoingRequests.length})
                    </Typography>
                  }
                  {...a11yProps(2)}
                />
              </StyledTabs>
              <Box className={styles.searchContainer}>
                <Paper component="form" className={styles.search}>
                  <DebounceInput
                    value={searchValue}
                    className={styles.searchInput}
                    placeholder="Search Dealerships"
                    minLength={2}
                    debounceTimeout={300}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                      handleSearchChange(e.target.value);
                    }}
                  />
                  <SearchIcon className={styles.searchIcon} sx={{ width: '20px' }} />
                </Paper>
              </Box>
            </Box>
            <TabPanel value={tabValue} index={0}>
              <Box className={styles.headerTextContainer}>
                {joinedAuctions && (
                  <Typography
                    component="p"
                    className={styles.headerText}
                    sx={{
                      textTransform: 'uppercase',
                      fontWeight: '700',
                      color: theme.palette.info.light
                    }}
                  >
                    {joinedAuctions.length} dealerships found
                  </Typography>
                )}
                <Box className={styles.controlsContainer}>
                  <Typography component="p" className={styles.headerText}>
                    Sort By:
                  </Typography>
                  <Select
                    label="Sort By"
                    value={order}
                    onChange={handleOrderByChange}
                    className={styles.inputText}
                    inputProps={{ 'aria-label': 'order dealerships' }}
                    input={<InputBase />}
                  >
                    <MenuItem value={'name'}>Name</MenuItem>
                    <MenuItem value={'location'}>Location</MenuItem>
                    <MenuItem value={'brands'}>Brands</MenuItem>
                  </Select>
                  {joinedAuctions && (
                    <TablePagination
                      className={styles.pagination}
                      count={joinedAuctions.length}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      rowsPerPageOptions={[5, 10, 25]}
                    />
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  width: '100%'
                }}
              >
                {joinedAuctions &&
                  joinedAuctions
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .sort((a: Dealership, b: Dealership) => {
                      if (order === 'name') {
                        return a.dealershipName.charAt(0) > b.dealershipName.charAt(0) ? 1 : -1;
                      } else if (order === 'location') {
                        return a.province.charAt(0) > b.province.charAt(0) ? 1 : -1;
                      } else {
                        return a.brands[0].name.charAt(0) > b.brands[0].name.charAt(0) ? 1 : -1;
                      }
                    })
                    .map((dealership: Dealership, index: number) => {
                      return (
                        <Box key={index} className={styles.dealerCardContainer} id="buyNetwork3">
                          <DealershipCard
                            handleMenuItemClick={(i: number) =>
                              handleMenuItemClick(i, dealership.id)
                            }
                            name={dealership.dealershipName}
                            address={dealership.province}
                            brands={dealership.brands}
                            country={dealership.country}
                          />
                        </Box>
                      );
                    })}
                {networkEmptyState && (
                  <EmptyStateMessage
                    boldMessage="You have not joined any auctions."
                    message="Build your buying network by joining other selling networks through Find a Dealership."
                  />
                )}
              </Box>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <Box className={styles.headerTextContainer}>
                {incomingRequests && (
                  <Typography
                    component="p"
                    className={styles.headerText}
                    sx={{
                      textTransform: 'uppercase',
                      fontWeight: '700',
                      color: theme.palette.info.light
                    }}
                  >
                    {incomingRequests.length} dealerships found
                  </Typography>
                )}
                <Box className={styles.controlsContainer}>
                  <Typography component="p" className={styles.headerText}>
                    Sort By:
                  </Typography>
                  <Select
                    label="Sort By"
                    value={incomingOrder}
                    onChange={handleIncomingOrderByChange}
                    className={styles.inputText}
                    inputProps={{ 'aria-label': 'order dealerships' }}
                    input={<InputBase />}
                  >
                    <MenuItem value={'name'}>Name</MenuItem>
                    <MenuItem value={'location'}>Location</MenuItem>
                    <MenuItem value={'brands'}>Brands</MenuItem>
                  </Select>
                  {incomingRequests && (
                    <TablePagination
                      className={styles.pagination}
                      count={incomingRequests.length}
                      page={incomingPage}
                      onPageChange={handleIncomingChangePage}
                      rowsPerPage={incomingRowsPerPage}
                      onRowsPerPageChange={handleIncomingChangeRowsPerPage}
                      rowsPerPageOptions={[5, 10, 25]}
                    />
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  width: '100%'
                }}
              >
                {incomingRequests &&
                  incomingRequests.map((request: DealerRequest, index: number) => {
                    return (
                      <Box key={index} className={styles.dealerCardContainer}>
                        <DealershipCard
                          handleMenuItemClick={(i: number) =>
                            handleMenuItemClick(i, request.requestingDealershipId, request.id)
                          }
                          name={request.requestingDealershipName}
                          address={request.requestingDealershipLocation}
                          brands={request.requestingDealershipBrands}
                          status={request.requestStatus}
                          country={
                            request.requestingDealershipCountry
                              ? request.requestingDealershipCountry
                              : undefined
                          }
                          showButtons={true}
                          request={request}
                        />
                      </Box>
                    );
                  })}
                {incomingEmptyState && (
                  <EmptyStateMessage
                    boldMessage="You have no incoming invites."
                    message="Sellers will send invites when they want you to join their auction as a Buyer."
                  />
                )}
              </Box>
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <Box className={styles.headerTextContainer}>
                {outgoingRequests && (
                  <Typography
                    component="p"
                    className={styles.headerText}
                    sx={{
                      textTransform: 'uppercase',
                      fontWeight: '700',
                      color: theme.palette.info.light
                    }}
                  >
                    {outgoingRequests.length} dealerships found
                  </Typography>
                )}
                <Box className={styles.controlsContainer}>
                  <Typography component="p" className={styles.headerText}>
                    Sort By:
                  </Typography>
                  <Select
                    label="Sort By"
                    value={outgoingOrder}
                    onChange={handleOutgoingOrderByChange}
                    className={styles.inputText}
                    inputProps={{ 'aria-label': 'order dealerships' }}
                    input={<InputBase />}
                  >
                    <MenuItem value={'name'}>Name</MenuItem>
                    <MenuItem value={'location'}>Location</MenuItem>
                    <MenuItem value={'brands'}>Brands</MenuItem>
                  </Select>
                  {outgoingRequests && (
                    <TablePagination
                      className={styles.pagination}
                      count={outgoingRequests.length}
                      page={outgoingPage}
                      onPageChange={handleOutgoingChangePage}
                      rowsPerPage={outgoingRowsPerPage}
                      onRowsPerPageChange={handleOutgoingChangeRowsPerPage}
                      rowsPerPageOptions={[5, 10, 25]}
                    />
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  width: '100%'
                }}
              >
                {outgoingRequests &&
                  outgoingRequests.map((request: any, index: number) => {
                    return (
                      <Box key={index} className={styles.dealerCardContainer}>
                        {request.uiType === 'dealerRequest' ? (
                          <DealershipCard
                            name={request.email}
                            address={'Join Dealer Network upon Registration'}
                            status={'pending'}
                            showStatus={true}
                            request={request}
                            showButtons={false}
                            hideMore={true}
                            inviteButton={true}
                            newDealer={true}
                            handleDealerMenuItemClick={(i: number) =>
                              handleDealerInviteItemClick(i, request.id)
                            }
                          />
                        ) : (
                          <DealershipCard
                            handleMenuItemClick={(i: number) =>
                              handleInviteItemClick(i, request.receivingDealershipId, request.id)
                            }
                            name={request.receivingDealershipName}
                            address={request.receivingDealershipLocation}
                            brands={request.receivingDealershipBrands}
                            status={request.requestStatus}
                            country={
                              request.receivingDealershipCountry
                                ? request.receivingDealershipCountry
                                : undefined
                            }
                            showStatus={true}
                            inviteButton={true}
                            isBuyer={true}
                            hideMore={true}
                            request={request}
                          />
                        )}
                      </Box>
                    );
                  })}
                {outgoingEmptyState && (
                  <EmptyStateMessage
                    boldMessage="You have no outgoing requests."
                    message="Send a request to join a seller's auction through Find a Dealership to get access to buy their inventory."
                  />
                )}
              </Box>
            </TabPanel>
          </Container>
          <Drawer
            anchor="right"
            open={drawerOpen}
            PaperProps={{
              sx: {
                top: isMobile ? '64px' : '83px',
                width: '400px',
                bgcolor: 'background.default',
                height: isMobile ? 'calc(100vh - 64px)' : 'calc(100vh - 83px)'
              }
            }}
            variant="persistent"
          >
            {currentDealership !== null ? list() : null}
          </Drawer>
          {tabValue === 0 && joinedAuctions && joinedAuctions.length > 0 && (
            <Tour
              steps={steps}
              isOpen={tourOpen}
              onRequestClose={() => handleCloseTour()}
              className={styles.tourContainer}
            />
          )}
          {tabValue === 1 && incomingRequests && incomingRequests.length > 0 && (
            <Tour
              steps={steps}
              isOpen={tourOpen}
              onRequestClose={() => handleCloseTour()}
              className={styles.tourContainer}
            />
          )}
          {tabValue === 2 && outgoingRequests && outgoingRequests.length > 0 && (
            <Tour
              steps={steps}
              isOpen={tourOpen}
              onRequestClose={() => handleCloseTour()}
              className={styles.tourContainer}
            />
          )}
        </Page>
      ) : (
        <>
          {userDeleted && (
            <Alert severity="error">
              <AlertTitle>Permission Denied</AlertTitle>
              Your account has been disabled.
            </Alert>
          )}
          {userSuspended && (
            <>
              <Alert severity="error">
                <AlertTitle>Thank you for joining DealerGavel</AlertTitle>
                Your account information is being reviewed by DealerGavel team, and will be
                &nbsp;authorized shortly. For now, you can return to the dashboard and await
                &nbsp;authorization.
              </Alert>
              <Box sx={{ mt: '20px' }}>
                <Button onClick={handleNavigate} variant="contained">
                  Back to Dashboard
                </Button>
              </Box>
            </>
          )}
          {userDisabled && (
            <>
              <Alert severity="error">
                <AlertTitle>Permission Denied</AlertTitle>
                Your account has been disabled.
              </Alert>
              <Box sx={{ mt: '20px' }}>
                <Button onClick={handleNavigate} variant="contained">
                  Back to Dashboard
                </Button>
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
}
