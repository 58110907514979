import { Grid, BoxProps, IconButton, Popover, MenuItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ListItemDetails from '../../shared/ListItemDetails';
import ListItemBidPrice from '../../shared/ListItemPriceBid';
import ListItemPersonDate from '../../shared/ListItemPersonDate';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useEffect, useState } from 'react';
import SharedDrawer from '../../shared/SharedDrawer';
import HistoryDrawerContent from '../../shared/HistoryDrawerContent';
import { VehicleInfo, VehiclePhotos } from 'types/vehicleInfo';
import { doc, updateDoc } from 'firebase/firestore';
import { useFirestore } from 'reactfire';
import { useSnackbar } from 'notistack';
import { MIconButton } from 'components/@material-extend';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import { Link as RouterLink } from 'react-router-dom';
import AuctionDrawer from 'components/auction/drawer/AuctionDrawer';

interface Props extends BoxProps {
  vehicle: VehicleInfo;
  initialOpen: boolean;
  sendVehicleToParent: (index: number, vehicle: VehicleInfo) => void;
}

const MENU_OPTIONS = [
  { label: 'Vehicle History', linkTo: '#' },
  { label: 'Bidding History', linkTo: '#' },
  { label: 'View VDP', linkTo: '#' },
  { label: 'Bill of Sale', linkTo: '#' }
];

export default function DeliveredListItem({ vehicle, initialOpen, sendVehicleToParent }: Props) {
  const theme = useTheme();
  const firestore = useFirestore();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openBidDrawer, setOpenBidDrawer] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  // const [vehiclePhoto, setVehiclePhoto] = useState<VehiclePhotos>();
  const [anchorPopup, setAnchorPopup] = useState<HTMLButtonElement | null>(null);
  const [popupOpened, setPopupOpened] = useState(false);
  const [, setAnchorEl] = useState(false);
  const [openVDPDrawer, setOpenVDPDrawer] = useState<boolean>(false);

  const open = Boolean(anchorPopup);
  const id = open ? 'simple-popover' : undefined;

  const useStyles = makeStyles({
    menuPopover: {
      boxShadow: '0 0 2px 0 rgba(139, 148, 156, 0.24),0 10px 20px -4px rgba(139, 148, 156, 0.24)',
      paddingTop: '20px',
      paddingBottom: '20px',
      '@global': {
        '.MuiMenuItem-root': {
          paddingBottom: '6px'
        }
      }
    }
  });

  const styles = useStyles();

  const currencyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });

  let vehiclePhoto;
  if (vehicle.photos) {
    const photos = vehicle.photos.filter(
      (v: any) => v.url && v.location && v.location.toLowerCase().includes('3/4 shot')
    );
    if (photos[0]) {
      vehiclePhoto = photos[0];
    }
  }

  // const handleSetDatePaid = async (date: any) => {
  //   let dateToUpdate;
  //   if (!date && vehicle.auction.datePaid) {
  //     dateToUpdate = vehicle.auction.datePaid;
  //   } else if (date) {
  //     dateToUpdate = date;
  //   }
  //   const docRef = doc(firestore, `vehicles/${vehicle.id}`);
  //   await updateDoc(docRef, {
  //     'auction.datePaid': dateToUpdate
  //   });
  //   enqueueSnackbar('Date Paid Updated', {
  //     variant: 'success',
  //     anchorOrigin: {
  //       vertical: 'bottom',
  //       horizontal: 'right'
  //     },
  //     action: (key) => (
  //       <MIconButton size="small" onClick={() => closeSnackbar(key)}>
  //         <CloseIcon />
  //       </MIconButton>
  //     )
  //   });
  // };

  const handleSetDateDelivered = async (date: any) => {
    let dateToUpdate;
    if (!date && vehicle.auction.dateDelivered) {
      dateToUpdate = vehicle.auction.dateDelivered;
    } else if (date) {
      dateToUpdate = date;
    }
    const docRef = doc(firestore, `vehicles/${vehicle.id}`);
    await updateDoc(docRef, {
      'auction.dateDelivered': dateToUpdate
    });
    enqueueSnackbar('Date Delivered Updated', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right'
      },
      action: (key) => (
        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
          <CloseIcon />
        </MIconButton>
      )
    });
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorPopup(event.currentTarget);
    setPopupOpened(true);
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    sendVehicleToParent(index, vehicle);
    // if (index === 0) {
    //   setOpenDrawer(!openDrawer);
    // } else if (index === 1) {
    //   setOpenBidDrawer(!openBidDrawer);
    // } else {
    //   setOpenVDPDrawer(!openVDPDrawer);
    // }
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const handleCloseBidDrawer = () => {
    setOpenBidDrawer(!openBidDrawer);
  };

  const handleClose = () => {
    setAnchorPopup(null);
    setPopupOpened(false);
  };

  useEffect(() => {
    setAnchorEl(false);
  }, [popupOpened]);

  return (
    <Grid
      xs={12}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '10px',
        minHeight: '130px'
      }}
    >
      <Grid
        sx={{
          height: '100%',
          minHeight: '130px',
          padding: '6px !important',
          display: 'flex',
          borderTopLeftRadius: '10px',
          borderBottomLeftRadius: '10px',
          borderTop:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`,
          borderLeft:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`,
          borderBottom:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`
        }}
      >
        <ListItemDetails
          vehicle={vehicle}
          isSellerName={true}
          imageUrl={vehiclePhoto?.url}
          minWidth={'400px'}
          hasStatusPill={true}
        ></ListItemDetails>
      </Grid>
      {vehicle.sellerMarkAsSold && (
        <Grid
          item
          xs={2}
          sx={{
            height: '100%',
            minHeight: '130px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '6px 20px',
            minWidth: '155px',
            borderTop:
              theme.palette.mode === 'dark'
                ? `1px solid ${theme.palette.text.secondary}`
                : `1px solid ${theme.palette.divider}`,
            borderBottom:
              theme.palette.mode === 'dark'
                ? `1px solid ${theme.palette.text.secondary}`
                : `1px solid ${theme.palette.divider}`
          }}
        >
          <ListItemPersonDate label={'By Seller'} value={'Marked As Sold'}></ListItemPersonDate>
        </Grid>
      )}
      <Grid
        item
        xs={2}
        sx={{
          height: '100%',
          minHeight: '130px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '6px 20px',
          minWidth: '120px',
          borderTop:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`,
          borderBottom:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`
        }}
      >
        <ListItemBidPrice
          label={'sold price'}
          value={currencyFormat.format(vehicle.auction.currentPrice)}
          color={theme.palette.primary.main}
        ></ListItemBidPrice>
      </Grid>
      <Grid
        item
        xs={2}
        sx={{
          height: '100%',
          minHeight: '130px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '6px 20px',
          minWidth: '120px',
          borderTop:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`,
          borderBottom:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`
        }}
      >
        <ListItemBidPrice
          label={'floor price'}
          value={currencyFormat.format(vehicle.auction.floorPrice)}
          color={theme.palette.text.secondary}
        ></ListItemBidPrice>
      </Grid>
      <Grid
        item
        xs={2}
        sx={{
          height: '100%',
          minHeight: '130px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '6px 20px',
          minWidth: '150px',
          borderTop:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`,
          borderBottom:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`
        }}
      >
        {vehicle.buyerDealership && (
          <ListItemPersonDate
            label={'buyer'}
            value={vehicle.buyerDealership.dealershipName}
          ></ListItemPersonDate>
        )}
      </Grid>
      <Grid
        item
        xs={2}
        sx={{
          height: '100%',
          minHeight: '130px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '6px 20px',
          minWidth: '150px',
          borderTop:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`,
          borderBottom:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`
        }}
      >
        <ListItemPersonDate
          label={'date delivered'}
          isEditable={true}
          value={vehicle.auction.dateDelivered}
          handleSetDate={handleSetDateDelivered}
        ></ListItemPersonDate>
      </Grid>
      <Grid
        item
        xs={2}
        sx={{
          height: '100%',
          minHeight: '130px',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          minWidth: '150px',
          padding: '6px',
          borderTopRightRadius: '10px',
          borderBottomRightRadius: '10px',
          borderTop:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`,
          borderRight:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`,
          borderBottom:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`
        }}
      >
        <IconButton
          onClick={handleClick}
          sx={{
            backgroundColor: theme.palette.background.paper,
            padding: '5px',
            borderRadius: '5px',
            marginLeft: '10px'
          }}
        >
          <MoreVertIcon />
        </IconButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorPopup}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          sx={{ width: 220 }}
          PaperProps={{ classes: { root: styles.menuPopover } }}
        >
          {MENU_OPTIONS.map((option, index) => (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={(event: any) => handleMenuItemClick(event, index)}
              sx={{ typography: 'body2', py: 1, px: 2.5 }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Popover>
      </Grid>
      {/* <SharedDrawer
        isOpenSidebar={openDrawer}
        onCloseSidebar={handleCloseDrawer}
        drawerContent={
          <HistoryDrawerContent
            isSeller={true}
            onClose={handleCloseDrawer}
            vehicle={vehicle}
            index={0}
          />
        }
      /> */}
      {/* <SharedDrawer
        isOpenSidebar={openBidDrawer}
        onCloseSidebar={handleCloseBidDrawer}
        drawerContent={
          <HistoryDrawerContent
            isSeller={true}
            onClose={handleCloseBidDrawer}
            vehicle={vehicle}
            index={1}
          />
        }
      /> */}
      {/* {vehicle && (
        <AuctionDrawer
          vehicle={vehicle}
          tabIndex={0}
          isOpenSidebar={openVDPDrawer}
          onCloseSidebar={() => setOpenVDPDrawer(!openVDPDrawer)}
        />
      )} */}
    </Grid>
  );
}
