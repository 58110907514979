import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------
// Has to change, otherwise toggle will be greyd out
// Not sure if this will affect anything else

export default function ToggleButton(theme: Theme) {
  //const isLight = theme.palette.mode === 'light';

  return {
    MuiToggleButton: {
      styleOverrides: {
        root: {
          color: theme.palette.grey[500],
          //border: `solid 1px ${theme.palette.grey[500_32]}`,
          border: `solid 1px ${theme.palette.primary.main}`,
          '&.Mui-selected': {
            color: 'white',
            backgroundColor: `${theme.palette.primary.main}`
          },
          // Has to change, otherwise toggle will be greyd out
          // Not sure if this will affect anything else

          /* '&.Mui-selected': {
            //color: theme.palette.grey[isLight ? 600 : 0],
            //backgroundColor: theme.palette.action.selected
          }, */
          '&.Mui-disabled': {
            color: theme.palette.grey[500_48]
          }
        }
      }
    }
  };
}
