import { Container, Typography, Link, Stack, Switch, Box, Divider, Tab, Tabs } from '@mui/material';
import useSettings from '../../hooks/useSettings';
import Page from '../../components/Page';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import { PATH_PROFILE } from '../../routes/paths';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Formik } from 'formik';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { setDoc, doc, getDoc } from 'firebase/firestore';
import { useFirestore } from 'reactfire';
import CloseIcon from '@mui/icons-material/Close';
import useAuth from '../../hooks/useAuth';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
import { User } from 'constants/user';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`notifications-tabpanel-${index}`}
      aria-labelledby={`notifications-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0, mt: '10px' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `notifications-tab-${index}`,
    'aria-controls': `notifications-tabpanel-${index}`
  };
};

export default function NotificationsPage() {
  const { themeStretch } = useSettings();
  const theme = useTheme();
  const isMountedRef = useIsMountedRef();
  const firestore = useFirestore();
  const { user } = useAuth();
  const [currentUser, setCurrentUser] = useState<User>();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [tabValue, setTabValue] = useState(0);

  const getCurrentUser = async () => {
    const docRef = doc(firestore, `users/${user?.uid}`);
    const currentUser = await getDoc(docRef).then((u: any) => ({ ...u.data(), id: u.id } as User));
    setCurrentUser(currentUser);
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getCurrentUser();
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  interface StyledTabsProps {
    children?: React.ReactNode;
    value: number;
    onChange: (event: React.SyntheticEvent, newValue: number) => void;
  }

  const StyledTabs = styled((props: StyledTabsProps) => (
    <Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />
  ))(({ theme }) => ({
    '& .MuiTab-root': {
      color:
        theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.text.secondary,
      backgroundColor:
        theme.palette.mode === 'dark'
          ? theme.palette.background.neutral
          : theme.palette.background.paper
    },
    '& .MuiTab-root:not(:last-of-type)': {
      marginRight: '10px'
    },
    '& .MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent !important',
      padding: '5px'
    },
    '& .Mui-selected': {
      backgroundColor: theme.palette.primary.lighter,
      color: `${theme.palette.primary.main} !important`
    },
    '& .MuiTabs-indicatorSpan': {
      display: 'none',
      maxWidth: 40,
      width: '100%',
      backgroundColor: theme.palette.chart.violet[2]
    }
  }));

  const useStyles = makeStyles({
    backLink: {
      display: 'flex',
      marginBottom: '10px',
      width: '165px'
    },
    deactivateButton: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.text.secondary,
      border: `1px solid ${theme.palette.text.secondary}`,
      '&:hover': {
        backgroundColor: theme.palette.grey[100]
      }
    },
    active: {
      color: theme.palette.text.primary
    },
    disabled: {
      color: theme.palette.info.lighter
    },
    tab: {
      backgroundColor: theme.palette.background.paper,
      padding: '0px 40px',
      borderRadius: '5px',
      textTransform: 'uppercase'
    },
    notificationContainer: {
      maxWidth: '550px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    notificationInnerContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start'
    },
    fullWidthContainer: {
      maxWidth: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center'
    }
  });

  const styles = useStyles();

  return (
    <Page title="Notifications | DealerGavel">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Link className={styles.backLink} to={PATH_PROFILE.root} component={RouterLink}>
          <ArrowBackIosIcon fontSize="small" />
          Back to all settings
        </Link>
        <Typography variant="h4" component="h1" sx={{ fontSize: '1rem !important' }}>
          Notifications
        </Typography>
        <Typography paragraph sx={{ marginBottom: '20px' }}>
          Set your notification preferences
        </Typography>
        <Box sx={{ width: '100%' }}>
          <Box>
            <StyledTabs value={tabValue} onChange={handleChange} aria-label="Notifications Tabs">
              <Tab className={styles.tab} label="auctions" {...a11yProps(0)} />
              <Tab className={styles.tab} label="account" {...a11yProps(1)} />
            </StyledTabs>
          </Box>
          {currentUser && (
            <Formik
              initialValues={{
                activeStatus:
                  currentUser.auctionNotifications &&
                  currentUser.auctionNotifications.includes('activeStatus')
                    ? true
                    : false,
                // floorPrice:
                //   currentUser.auctionNotifications &&
                //   currentUser.auctionNotifications.includes('floorPrice')
                //     ? true
                //     : false,
                outbid:
                  currentUser.auctionNotifications &&
                  currentUser.auctionNotifications.includes('outbid')
                    ? true
                    : false,
                // auctionClosing:
                //  currentUser.auctionNotifications &&
                //  currentUser.auctionNotifications.includes('auctionClosing')
                //   ? true
                //   : false,
                // closingOutbid:
                //   currentUser.auctionNotifications &&
                //   currentUser.auctionNotifications.includes('closingOutbid')
                //     ? true
                //     : false,
                negotiationStatus:
                  currentUser.auctionNotifications &&
                  currentUser.auctionNotifications.includes('negotiationStatus')
                    ? true
                    : false
                // vehicleWon:
                //   currentUser.auctionNotifications &&
                //   currentUser.auctionNotifications.includes('vehicleWon')
                //     ? true
                //     : false
              }}
              enableReinitialize
              onSubmit={async (values, { setSubmitting, setErrors }): Promise<void> => {
                try {
                  const notificationValues: string[] = [];
                  let key: keyof typeof values;
                  for (key in values) {
                    const v = values[key];
                    if (v === true) {
                      notificationValues.push(key);
                    }
                  }
                  const ref = doc(firestore, 'users', user?.uid);
                  await setDoc(ref, { auctionNotifications: notificationValues }, { merge: true });
                  await user?.reload();
                  enqueueSnackbar('User Updated', {
                    variant: 'success',
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'right'
                    },
                    action: (key) => <CloseIcon onClick={() => closeSnackbar(key)} />
                  });
                  if (isMountedRef.current) {
                    setSubmitting(false);
                  }
                } catch (error) {
                  if (isMountedRef.current) {
                    if (error instanceof Error) {
                      setSubmitting(false);
                    }
                  }
                }
              }}
            >
              {({ errors, getFieldProps, isSubmitting, handleSubmit, values }): JSX.Element => (
                <form onSubmit={handleSubmit}>
                  <TabPanel value={tabValue} index={0}>
                    <Stack spacing={2}>
                      <Box className={styles.notificationContainer}>
                        <Box className={styles.notificationInnerContainer}>
                          <Typography sx={{ color: theme.palette.info.lighter, fontSize: '12px' }}>
                            Auction Update: Vehicle Active
                          </Typography>
                          <Typography
                            className={values.activeStatus ? styles.active : styles.disabled}
                            sx={{
                              fontWeight: '700',
                              fontSize: '14px'
                            }}
                          >
                            When a vehicle is moved to active status, ready for bidding
                          </Typography>
                        </Box>
                        <Switch {...getFieldProps('activeStatus')} checked={values.activeStatus} />
                      </Box>
                      <Divider
                        sx={{
                          maxWidth: '550px',
                          borderColor:
                            theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
                        }}
                      />
                      {/* <Box className={styles.notificationContainer}>
                        <Box className={styles.notificationInnerContainer}>
                          <Typography sx={{ color: theme.palette.info.lighter, fontSize: '12px' }}>
                            Auction Update: Floor Price Met
                          </Typography>
                          <Typography
                            className={values.floorPrice ? styles.active : styles.disabled}
                            sx={{
                              fontWeight: '700',
                              fontSize: '14px'
                            }}
                          >
                            When a vehicle has the floor price met during bidding
                          </Typography>
                        </Box>
                        <Switch {...getFieldProps('floorPrice')} checked={values.floorPrice} />
                      </Box>
                      <Divider
                        sx={{
                          maxWidth: '550px',
                          borderColor:
                            theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
                        }}
                      /> */}
                      {/* <Box className={styles.notificationContainer}>
                        <Box className={styles.notificationInnerContainer}>
                          <Typography sx={{ color: theme.palette.info.lighter, fontSize: '12px' }}>
                            Auction Update: Outbid
                          </Typography>
                          <Typography
                            className={values.outbid ? styles.active : styles.disabled}
                            sx={{
                              fontWeight: '700',
                              fontSize: '14px'
                            }}
                          >
                            When your bid on a vehicle has been outbid
                          </Typography>
                        </Box>
                        <Switch {...getFieldProps('outbid')} checked={values.outbid} />
                      </Box>
                      <Divider
                        sx={{
                          maxWidth: '550px',
                          borderColor:
                            theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
                        }}
                      /> */}
                      {/* <Box className={styles.notificationContainer}>
                        <Box className={styles.notificationInnerContainer}>
                          <Typography sx={{ color: theme.palette.info.lighter, fontSize: '12px' }}>
                            Auction Update: Closing Period Extended, You have Been Outbid
                          </Typography>
                          <Typography
                            className={values.closingOutbid ? styles.active : styles.disabled}
                            sx={{
                              fontWeight: '700',
                              fontSize: '14px'
                            }}
                          >
                            When a vehicle has the closing period extended and you have been outbid
                          </Typography>
                        </Box>
                        <Switch
                          {...getFieldProps('closingOutbid')}
                          checked={values.closingOutbid}
                        />
                      </Box>
                      <Divider
                        sx={{
                          maxWidth: '550px',
                          borderColor:
                            theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
                        }}
                      /> */}
                      {/* <Box className={styles.notificationContainer}>
                        <Box className={styles.notificationInnerContainer}>
                          <Typography sx={{ color: theme.palette.info.lighter, fontSize: '12px' }}>
                            Auction Update: Auction Closing Soon
                          </Typography>
                          <Typography
                            className={values.auctionClosing ? styles.active : styles.disabled}
                            sx={{
                              fontWeight: '700',
                              fontSize: '14px'
                            }}
                          >
                            When a vehicle you have bid on is closing in 3 minutes
                          </Typography>
                        </Box>
                        <Switch
                          {...getFieldProps('auctionClosing')}
                          checked={values.auctionClosing}
                        />
                      </Box>
                      <Divider
                        sx={{
                          maxWidth: '550px',
                          borderColor:
                            theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
                        }}
                      /> */}
                      <Box className={styles.notificationContainer}>
                        <Box className={styles.notificationInnerContainer}>
                          <Typography sx={{ color: theme.palette.info.lighter, fontSize: '12px' }}>
                            Account Update: Negotiation Response Status
                          </Typography>
                          <Typography
                            className={values.negotiationStatus ? styles.active : styles.disabled}
                            sx={{
                              fontWeight: '700',
                              fontSize: '14px'
                            }}
                          >
                            When there is a required negotiation response for vehicle
                          </Typography>
                        </Box>
                        <Switch
                          {...getFieldProps('negotiationStatus')}
                          checked={values.negotiationStatus}
                        />
                      </Box>
                      <Divider
                        sx={{
                          maxWidth: '550px',
                          borderColor:
                            theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
                        }}
                      />
                      {/* <Box className={styles.notificationContainer}>
                        <Box className={styles.notificationInnerContainer}>
                          <Typography sx={{ color: theme.palette.info.lighter, fontSize: '12px' }}>
                            Auction Update: You have Won This Vehicle
                          </Typography>
                          <Typography
                            className={values.vehicleWon ? styles.active : styles.disabled}
                            sx={{
                              fontWeight: '700',
                              fontSize: '14px'
                            }}
                          >
                            When a vehicle you have bid on is won by you
                          </Typography>
                        </Box>
                        <Switch {...getFieldProps('vehicleWon')} checked={values.vehicleWon} />
                      </Box>
                      <Divider
                        sx={{
                          maxWidth: '100%',
                          borderColor:
                            theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
                        }}
                      /> */}
                      <Box className={styles.fullWidthContainer}>
                        <LoadingButton
                          size="large"
                          type="submit"
                          variant="contained"
                          loading={isSubmitting}
                        >
                          Save Changes
                        </LoadingButton>
                      </Box>
                    </Stack>
                  </TabPanel>
                </form>
              )}
            </Formik>
          )}
          {currentUser && (
            <Formik
              initialValues={{
                // parkedStatus:
                //   currentUser.accountNotifications &&
                //   currentUser.accountNotifications.includes('parkedStatus')
                //     ? true
                //     : false,
                // launchingsoonStatus:
                //   currentUser.accountNotifications &&
                //   currentUser.accountNotifications.includes('launchingsoonStatus')
                //     ? true
                //     : false,
                pendingStatus:
                  currentUser.accountNotifications &&
                  currentUser.accountNotifications.includes('pendingStatus')
                    ? true
                    : false,
                soldStatus:
                  currentUser.accountNotifications &&
                  currentUser.accountNotifications.includes('soldStatus')
                    ? true
                    : false,
                arrivedStatus:
                  currentUser.accountNotifications &&
                  currentUser.accountNotifications.includes('arrivedStatus')
                    ? true
                    : false,
                deliveredStatus:
                  currentUser.accountNotifications &&
                  currentUser.accountNotifications.includes('deliveredStatus')
                    ? true
                    : false,
                // nosaleStatus:
                //   currentUser.accountNotifications &&
                //   currentUser.accountNotifications.includes('nosaleStatus')
                //     ? true
                //     : false,
                networkRequest:
                  currentUser.accountNotifications &&
                  currentUser.accountNotifications.includes('networkRequest')
                    ? true
                    : false
              }}
              enableReinitialize
              onSubmit={async (values, { setSubmitting, setErrors }): Promise<void> => {
                try {
                  const notificationValues: string[] = [];
                  let key: keyof typeof values;
                  for (key in values) {
                    const v = values[key];
                    if (v === true) {
                      notificationValues.push(key);
                    }
                  }
                  const ref = doc(firestore, 'users', user?.uid);
                  await setDoc(ref, { accountNotifications: notificationValues }, { merge: true });
                  enqueueSnackbar('User Updated', {
                    variant: 'success',
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'right'
                    },
                    action: (key) => <CloseIcon onClick={() => closeSnackbar(key)} />
                  });
                  if (isMountedRef.current) {
                    setSubmitting(false);
                  }
                } catch (error) {
                  if (isMountedRef.current) {
                    if (error instanceof Error) {
                      setSubmitting(false);
                    }
                  }
                }
              }}
            >
              {({ errors, getFieldProps, isSubmitting, handleSubmit, values }): JSX.Element => (
                <form onSubmit={handleSubmit}>
                  <TabPanel value={tabValue} index={1}>
                    <Stack spacing={2}>
                      {/* <Box className={styles.notificationContainer}>
                        <Box className={styles.notificationInnerContainer}>
                          <Typography sx={{ color: theme.palette.info.lighter, fontSize: '12px' }}>
                            Account Update: Parked Status
                          </Typography>
                          <Typography
                            className={values.parkedStatus ? styles.active : styles.disabled}
                            sx={{
                              fontWeight: '700',
                              fontSize: '14px'
                            }}
                          >
                            When a vehicle is moved to parked status
                          </Typography>
                        </Box>
                        <Switch {...getFieldProps('parkedStatus')} checked={values.parkedStatus} />
                      </Box>
                      <Divider
                        sx={{
                          maxWidth: '550px',
                          borderColor:
                            theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
                        }}
                      /> */}
                      {/* <Box className={styles.notificationContainer}>
                        <Box className={styles.notificationInnerContainer}>
                          <Typography sx={{ color: theme.palette.info.lighter, fontSize: '12px' }}>
                            Account Update: Launching Soon Status
                          </Typography>
                          <Typography
                            className={values.launchingsoonStatus ? styles.active : styles.disabled}
                            sx={{
                              fontWeight: '700',
                              fontSize: '14px'
                            }}
                          >
                            When a vehicle is moved to launching soon status
                          </Typography>
                        </Box>
                        <Switch
                          {...getFieldProps('launchingsoonStatus')}
                          checked={values.launchingsoonStatus}
                        />
                      </Box>
                      <Divider
                        sx={{
                          maxWidth: '550px',
                          borderColor:
                            theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
                        }}
                      /> */}
                      <Box className={styles.notificationContainer}>
                        <Box className={styles.notificationInnerContainer}>
                          <Typography sx={{ color: theme.palette.info.lighter, fontSize: '12px' }}>
                            Account Update: Pending Status
                          </Typography>
                          <Typography
                            className={values.pendingStatus ? styles.active : styles.disabled}
                            sx={{
                              fontWeight: '700',
                              fontSize: '14px'
                            }}
                          >
                            When a vehicle is moved to pending status
                          </Typography>
                        </Box>
                        <Switch
                          {...getFieldProps('pendingStatus')}
                          checked={values.pendingStatus}
                        />
                      </Box>
                      <Divider
                        sx={{
                          maxWidth: '550px',
                          borderColor:
                            theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
                        }}
                      />
                      {/* <Box className={styles.notificationContainer}>
                        <Box className={styles.notificationInnerContainer}>
                          <Typography sx={{ color: theme.palette.info.lighter, fontSize: '12px' }}>
                            Account Update: Sold Status
                          </Typography>
                          <Typography
                            className={values.soldStatus ? styles.active : styles.disabled}
                            sx={{
                              fontWeight: '700',
                              fontSize: '14px'
                            }}
                          >
                            When a vehicle has been moved to sold status
                          </Typography>
                        </Box>
                        <Switch {...getFieldProps('soldStatus')} checked={values.soldStatus} />
                      </Box>
                      <Divider
                        sx={{
                          maxWidth: '550px',
                          borderColor:
                            theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
                        }}
                      /> */}
                      <Box className={styles.notificationContainer}>
                        <Box className={styles.notificationInnerContainer}>
                          <Typography sx={{ color: theme.palette.info.lighter, fontSize: '12px' }}>
                            Account Update: Arrived Status
                          </Typography>
                          <Typography
                            className={values.arrivedStatus ? styles.active : styles.disabled}
                            sx={{
                              fontWeight: '700',
                              fontSize: '14px'
                            }}
                          >
                            When a vehicle has been moved to arrived status
                          </Typography>
                        </Box>
                        <Switch
                          {...getFieldProps('arrivedStatus')}
                          checked={values.arrivedStatus}
                        />
                      </Box>
                      <Divider
                        sx={{
                          maxWidth: '550px',
                          borderColor:
                            theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
                        }}
                      />
                      <Box className={styles.notificationContainer}>
                        <Box className={styles.notificationInnerContainer}>
                          <Typography sx={{ color: theme.palette.info.lighter, fontSize: '12px' }}>
                            Account Update: Delivered Status
                          </Typography>
                          <Typography
                            className={values.deliveredStatus ? styles.active : styles.disabled}
                            sx={{
                              fontWeight: '700',
                              fontSize: '14px'
                            }}
                          >
                            When a vehicle has been moved to delivered status
                          </Typography>
                        </Box>
                        <Switch
                          {...getFieldProps('deliveredStatus')}
                          checked={values.deliveredStatus}
                        />
                      </Box>
                      <Divider
                        sx={{
                          maxWidth: '550px',
                          borderColor:
                            theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
                        }}
                      />
                      {/* <Box className={styles.notificationContainer}>
                        <Box className={styles.notificationInnerContainer}>
                          <Typography sx={{ color: theme.palette.info.lighter, fontSize: '12px' }}>
                            Account Update: No Sale Status
                          </Typography>
                          <Typography
                            className={values.nosaleStatus ? styles.active : styles.disabled}
                            sx={{
                              fontWeight: '700',
                              fontSize: '14px'
                            }}
                          >
                            When a vehicle has been moved to no sale status
                          </Typography>
                        </Box>
                        <Switch {...getFieldProps('nosaleStatus')} checked={values.nosaleStatus} />
                      </Box>
                      <Divider
                        sx={{
                          maxWidth: '550px',
                          borderColor:
                            theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
                        }}
                      /> */}
                      <Box className={styles.notificationContainer}>
                        <Box className={styles.notificationInnerContainer}>
                          <Typography sx={{ color: theme.palette.info.lighter, fontSize: '12px' }}>
                            Account Update: Network Requests
                          </Typography>
                          <Typography
                            className={values.networkRequest ? styles.active : styles.disabled}
                            sx={{
                              fontWeight: '700',
                              fontSize: '14px'
                            }}
                          >
                            When your Dealership has a Pending Invite or Request to Join
                          </Typography>
                        </Box>
                        <Switch
                          {...getFieldProps('networkRequest')}
                          checked={values.networkRequest}
                        />
                      </Box>
                      <Divider
                        sx={{
                          width: '100%',
                          borderColor:
                            theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
                        }}
                      />
                      <Box className={styles.fullWidthContainer}>
                        <LoadingButton
                          size="large"
                          type="submit"
                          variant="contained"
                          loading={isSubmitting}
                        >
                          Save Changes
                        </LoadingButton>
                      </Box>
                    </Stack>
                  </TabPanel>
                </form>
              )}
            </Formik>
          )}
        </Box>
      </Container>
    </Page>
  );
}
