import { VehicleInfo } from 'types/vehicleInfo';
import { VehicleDetailsType } from 'types/vehicleInfo';

export const loadAutodevApi = async (vehicle: VehicleInfo) => {
  const tmpVehicle = { ...vehicle };
  const tmpVehDetails: VehicleDetailsType = tmpVehicle.details;
  const axios = require('axios');

  const apiKey = 'ZrQEPSkKbWF0dEBkZXZsaWZ0Lmlv';
  const url = `https://auto.dev/api/vin/${tmpVehDetails.vin}?apikey=${apiKey}`;

  const options = {
    method: 'GET',
    url: url,
    returnBody: true,
    encodeBodyUtf8: false,
    decodeUtf8: false,
    cache: false
  };

  let response: any;

  try {
    response = await axios.request(options);
  } catch (error) {
    return false;
  }

  // prettier-ignore
  if (response.status !== 200 || response.data.status === 'BAD_REQUEST' || response.data.status === 'NOT_FOUND') return false;

  const tmpSpec = response.data;
  tmpVehicle.dataFound = true;

  // YEAR
  tmpVehDetails.year = tmpSpec.years[0].year ? tmpSpec.years[0].year : '';

  // MAKE
  tmpVehDetails.make = tmpSpec.make.name ? tmpSpec.make.name : '';

  // MODEL
  tmpVehDetails.model = tmpSpec.model.name ? tmpSpec.model.name : '';

  // BODY STYLE
  // prettier-ignore
  let bodyStyle = tmpSpec.years[0].styles[0].submodel.body ? tmpSpec.years[0].styles[0].submodel.body : '';
  if (bodyStyle !== '') {
    bodyStyle = bodyStyle.toLowerCase();
    bodyStyle = bodyStyle.charAt(0).toUpperCase() + bodyStyle.slice(1);
  }
  tmpVehDetails.bodyStyle = bodyStyle;

  // TRANSMISSION
  // prettier-ignore
  // tmpVehDetails.transmission = tmpSpec.transmission.transmissionType ? tmpSpec.transmission.transmissionType : '';

  // DRIVETRAIN
  // prettier-ignore
  tmpVehDetails.driveTrain = tmpSpec.drivenWheels ? tmpSpec.drivenWheels : '';

  // CYLINDERS
  // prettier-ignore
  tmpVehDetails.cylinders = tmpSpec.engine.cylinder ? tmpSpec.engine.cylinder.toString() : '';

  // DISPLACEMENT
  // prettier-ignore
  tmpVehDetails.displacement = tmpSpec.engine.displacement ? tmpSpec.engine.displacement : '';

  // FUEL TYPE
  // prettier-ignore
  tmpVehDetails.fuelType = tmpSpec.engine.type ? tmpSpec.engine.type : '';

  return tmpVehicle;
};
