import { List, IconButton, Typography, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { useEffect, useState } from 'react';
// import { boxStyle } from './vehicleCaptureStyles';
import { PaintedPanelsType, ConditionOfTiresType } from 'types/vehicleInfo';
import { VehicleDropBox } from './CommonComponents';
import { useTheme } from '@mui/material/styles';

export const dt_condition = ['Like New', 'Good', 'Worn', 'Needs Replacement', 'Flat'];

type Props = {
  paintedPanels: PaintedPanelsType[];
  updatePanels: (v: PaintedPanelsType[]) => void;
  handleEdit: (item: PaintedPanelsType) => void;
};
export function ListPanels({ paintedPanels, updatePanels, handleEdit }: Props) {
  const theme = useTheme();
  const [listPanel, setListPanel] = useState(paintedPanels);

  const boxStyle = {
    textAlign: 'start',
    width: 400,
    height: 60,
    backgroundColor: theme.palette.mode !== 'dark' ? theme.palette.common.white : 'unset',
    border: `1px ${theme.palette.divider} solid`,
    borderRadius: '10px',
    fontWeight: 300,
    padding: 0,
    mb: 1,
    overflow: 'hidden'
  };

  useEffect(() => {
    setListPanel(paintedPanels);
  }, [paintedPanels]);

  function handleRemove(id: string) {
    const newList = paintedPanels.filter((item) => item.id !== id);
    setListPanel(newList);
    updatePanels(newList);
  }

  return (
    <Box
      sx={{
        backgroundColor: 'transparent'
      }}
    >
      {listPanel &&
        listPanel.length > 0 &&
        listPanel.map((item, index) => (
          <Box key={index}>
            {item.selected && (
              <Box sx={boxStyle} key={index}>
                <Typography
                  sx={{
                    mt: 1,
                    ml: 2,
                    fontSize: '12px',
                    color: theme.palette.text.secondary
                  }}
                >
                  {item.name}
                </Typography>
                <Box sx={{ mt: -1.5, display: 'flex', justifyContent: 'space-between' }}>
                  <Typography
                    sx={{
                      ml: 2,
                      mt: 1.5,
                      fontSize: '12px',
                      fontWeight: '600',
                      color: theme.palette.text.primary
                    }}
                  >
                    {item.condition}
                  </Typography>
                  <Box sx={{ width: 80 }}>
                    <IconButton
                      //sx={{ mr: 2 }}
                      color="inherit"
                      title="Edit Panel"
                      onClick={() => {
                        if (handleEdit) handleEdit(item);
                        //item.selected = false;
                      }}
                    >
                      <EditIcon sx={{ fontSize: '18px' }} />
                    </IconButton>
                    <IconButton
                      //sx={{ mr: 2 }}
                      color="inherit"
                      title="Remove Panel"
                      onClick={() => {
                        handleRemove(item.id);
                        item.selected = false;
                      }}
                    >
                      <CloseIcon sx={{ fontSize: '18px' }} />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        ))}
    </Box>
  );
}

type Prop = {
  tires: ConditionOfTiresType[];
  updateTires: (v: ConditionOfTiresType[]) => void;
};
export function ListTires({ tires, updateTires }: Prop) {
  const [listTires, setListTires] = useState(tires);
  const theme = useTheme();

  const boxStyle = {
    textAlign: 'start',
    width: 400,
    height: 60,
    backgroundColor: theme.palette.mode !== 'dark' ? theme.palette.common.white : 'unset',
    border: `1px ${theme.palette.divider} solid`,
    borderRadius: '10px',
    fontWeight: 300,
    padding: 0,
    mb: 1,
    overflow: 'hidden'
  };

  useEffect(() => {
    setListTires(tires);
  }, [tires]);

  // function handleRemove(id: string) {
  //   const newList = tires.filter((item) => item.id !== id);
  //   setListTires(newList);
  //   updateTires(newList);
  // }

  function handleUpdate(item: ConditionOfTiresType) {
    const newList = [...listTires];
    const i = newList.findIndex((i) => i.location === item.location);
    if (i !== -1) newList[i].condition = item.condition;
    setListTires(newList);
    updateTires(newList);
  }
  return (
    <List sx={{ backgroundColor: 'transparent' }}>
      {listTires &&
        listTires.length > 0 &&
        listTires.map((item, index) => (
          <>
            {item.selected && (
              <Box key={index}>
                <Box sx={boxStyle}>
                  <VehicleDropBox
                    dropBoxValue={item.condition}
                    caption={item.location}
                    arrayOfItems={dt_condition}
                    updateDropValue={(v) => {
                      if (!v) return;
                      item.condition = v as string;
                      handleUpdate(item);
                    }}
                  />
                </Box>
              </Box>
            )}
          </>
        ))}
    </List>
  );
}
