// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// hooks
import useAuth from './hooks/useAuth';
// components
import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import LoadingScreen, { ProgressBarStyle } from './components/LoadingScreen';
import NotistackProvider from 'components/NotistackProvider';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { FirestoreProvider, useFirebaseApp, StorageProvider } from 'reactfire';
import { Toaster } from 'react-hot-toast';
import initializeStripe from 'utils/initStripe';
// ----------------------------------------------------------------------

export default function App() {
  const { isInitialized } = useAuth();
  const app = useFirebaseApp();
  initializeStripe();
  const firestoreInstance = getFirestore(useFirebaseApp());
  const storage = getStorage(app);
  const { currentUser } = useAuth();
  return (
    <FirestoreProvider sdk={firestoreInstance}>
      <StorageProvider sdk={storage}>
        <ThemeConfig>
          <ThemePrimaryColor>
            <RtlLayout>
              <NotistackProvider>
                <GlobalStyles />
                <ProgressBarStyle />
                {currentUser && <Settings />}
                {/* <Settings /> */}
                <ScrollToTop />
                {isInitialized ? <Router /> : <LoadingScreen />}
                <Toaster />
              </NotistackProvider>
            </RtlLayout>
          </ThemePrimaryColor>
        </ThemeConfig>
      </StorageProvider>
    </FirestoreProvider>
  );
}
