import { Box, BoxProps, Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface Props extends BoxProps {
  boldMessage?: string;
  message?: string;
}

const EmptyStateMessage: FC<Props> = ({ boldMessage, message }: Props) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: '100%',
        height: '100px',
        minHeight: '50px',
        backgroundColor: theme.palette.primary.lighter,
        border: `1px solid ${theme.palette.primary.light}`,
        borderRadius: '10px',
        padding: '5px 10px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
      }}
    >
      <InfoOutlinedIcon sx={{ marginRight: '10px', color: theme.palette.primary.main }} />
      {/* prettier-ignore */}
      <Typography>
        <span style={{ fontWeight: 'bold' }}>{boldMessage}</span> {message}
      </Typography>
    </Box>
  );
};

export default EmptyStateMessage;
