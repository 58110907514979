import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { VehicleInfo } from 'types/vehicleInfo';

interface ICountdown {
  vehicle: VehicleInfo;
}

const CountDownTimer = ({ vehicle }: ICountdown) => {
  const [runTime, setRunTime] = useState<any>();

  useEffect(() => {
    const timerId = setInterval(() => tick(), 1000);
    return () => clearInterval(timerId);
  });

  const tick = () => {
    const timezoneOffset = new Date().getTimezoneOffset();
    // prettier-ignore
    const UTCEndDateMoment = moment(vehicle.auction.launchDate.toDate()).clone().add(timezoneOffset, 'minutes');
    // prettier-ignore
    const UTCEndTimeMoment = moment(vehicle.auction.launchTime.toDate()).clone().add(timezoneOffset, 'minutes');
    const UTCendDate = UTCEndDateMoment.toDate();
    const UTCendTime = UTCEndTimeMoment.toDate();
    UTCendDate.setHours(UTCendTime.getHours());
    UTCendDate.setMinutes(UTCendTime.getMinutes());
    UTCendDate.setSeconds(UTCendTime.getSeconds());
    UTCendDate.setMilliseconds(UTCendTime.getMilliseconds());
    const now = moment().clone().add(timezoneOffset, 'minutes');
    const duration = moment.duration(moment(UTCendDate).diff(now));
    const daysRemaining = duration.days();
    const hoursRemaining = duration.hours();
    const minutesRemaining = duration.minutes();
    const secondsRemaining = duration.seconds();
    const runTime = `${daysRemaining}D ${hoursRemaining}H ${minutesRemaining}M ${secondsRemaining}S`;
    setRunTime(runTime);
  };

  return (
    <Typography
      component="p"
      sx={{
        lineHeight: 1,
        color: 'white',
        fontSize: '13px',
        fontWeight: '700'
      }}
    >
      {runTime}
    </Typography>
  );
};

export default CountDownTimer;
