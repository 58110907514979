import { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { alpha } from '@mui/material/styles';
import { Avatar, Button, Box, Typography } from '@mui/material';
import { MIconButton } from '../../components/@material-extend';
import MenuPopover from '../../components/MenuPopover';
import useAuth from 'hooks/useAuth';
import { useSnackbar } from 'notistack';
// import { useTheme } from '@mui/material/styles';

export default function AccountPopover() {
  const navigate = useNavigate();
  const anchorRef = useRef(null);
  // const theme = useTheme();
  const { user, logout, currentUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      await logout?.().then(() => {
        navigate('.');
        window.location.reload();
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout', { variant: 'error' });
    }
  };

  return (
    <Box>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        {currentUser?.avatarUrl ? (
          <Avatar alt="My Avatar" src={currentUser?.avatarUrl} />
        ) : (
          <Avatar alt="My Avatar" src="/static/mock-images/avatars/avatar_default.jpg" />
        )}
      </MIconButton>
      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 350 }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '20px',
            backgroundColor: 'transparent' // theme.palette.common.white
          }}
        >
          {currentUser?.avatarUrl ? (
            <Avatar
              sx={{ width: '160px', height: '160px' }}
              alt="My Avatar"
              src={currentUser?.avatarUrl}
            />
          ) : (
            <Avatar
              sx={{ width: '160px', height: '160px' }}
              alt="My Avatar"
              src="/static/mock-images/avatars/avatar_default.jpg"
            />
          )}
          <Typography variant="subtitle1" noWrap sx={{ marginTop: '10px' }}>
            {currentUser?.firstName} {currentUser?.lastName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
          <Button
            sx={{ marginTop: '10px', width: '185px' }}
            color="inherit"
            variant="outlined"
            component={Link}
            to={'/profile'}
            onClick={handleClose}
          >
            Manage Account
          </Button>

          <Button
            color="inherit"
            variant="outlined"
            onClick={handleLogout}
            sx={{ marginTop: '10px', width: '185px' }}
          >
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </Box>
  );
}
