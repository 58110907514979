import {
  Box,
  Button,
  Divider,
  Typography,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  Alert,
  FormHelperText,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton
} from '@mui/material';
import { useEffect, useState } from 'react';
import { VehicleDisclosuresType } from '../../../../types/vehicleInfo';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  GridElement,
  VehicleDropBox,
  VehicleCheckbox,
  VehicleNumberBox,
  VehicleCalendar
} from './CommonComponents';
import CloseIcon from '@mui/icons-material/Close';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { arrayRemove, Timestamp } from '@firebase/firestore';
import { format } from 'date-fns';
import { useTheme } from '@mui/material/styles';
import { VHRImageDialog } from './VHRImageDialog';
import { VehicleInfo, VehiclePhotos } from 'types/vehicleInfo';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { collection, doc, query, updateDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { PATH_SELLER } from 'routes/paths';
import { NumericFormat } from 'react-number-format';
import MoreItemsIndicator from './MoreItemsIndicator';

type Props = {
  disclosures: VehicleDisclosuresType;
  updVehicleDis: (equipment: VehicleDisclosuresType, flag: number) => void;
  vehicle: VehicleInfo;
};

type ReportedDamage = {
  cost: number;
  description: string;
  date?: Timestamp | undefined;
  afterSubmit?: string;
};

export function VehicleDeclaration({ disclosures, updVehicleDis, vehicle }: Props) {
  const theme = useTheme();
  const navigate = useNavigate();
  const firestore = useFirestore();
  const [disclosuresInfo, setDisclosuresInfo] = useState(
    disclosures || ({} as VehicleDisclosuresType)
  );
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [reportedDamages, setReportedDamages] = useState<ReportedDamage[]>([]);
  const [VHRImage, setVHRImage] = useState<string>('');
  const [showDamageDialog, setShowDamageDialog] = useState<boolean>(false);
  const [editDamage, setEditDamage] = useState(false);
  const [currentDamageIndex, setCurrentDamageIndex] = useState<number>();
  const [vhrDate, setVhrDate] = useState<any>();
  const [showImageDialog, setShowImageDialog] = useState<boolean>(false);
  const [otherNotes, setOtherNotes] = useState(disclosures?.notes ? disclosures.notes : '');
  const [repairNotes, setRepairNotes] = useState(
    disclosures?.repairNotes ? disclosures.repairNotes : ''
  );
  const [newVehiclePhoto] = useState({} as VehiclePhotos);
  const [isDamages] = useState(false);
  const [mtoVal, setMtoVal] = useState<string>(disclosures?.mtoReg);
  const [provinceVal, setProvinceVal] = useState<string>(disclosures.province);
  const [stateVal, setStateVal] = useState<string>(disclosures.state);
  const [showValidateError, setShowValidateError] = useState<boolean>(false);
  const [oopVal, setOopVal] = useState<boolean>(disclosures.outOfProvince);
  const [usVal, setUsVal] = useState<boolean>(disclosures.usVehicle);
  const [odometerReading, setOdometerReading] = useState<string>(
    disclosures?.odometerReading ? disclosures.odometerReading : ''
  );
  const [odometerDate, setOdometerDate] = useState<any>(
    (disclosures.odometerReadingDate as Timestamp) ? disclosures.odometerReadingDate : null
  );

  const [isDisabledProvince, setIsDisabledProvince] = useState<boolean>(true);
  const [isDisabledState, setIsDisabledState] = useState<boolean>(true);
  const [isDisabledOdometer, setIsDisabledOdometer] = useState<boolean>(true);
  const [isDisabledOdometerDate, setIsDisabledOdometerDate] = useState<boolean>(true);

  const boxStyle = {
    textAlign: 'start',
    width: 400,
    height: 60,
    backgroundColor: theme.palette.mode !== 'dark' ? theme.palette.common.white : 'unset',
    border: `1px ${theme.palette.divider} solid`,
    borderRadius: '10px',
    fontWeight: 300,
    padding: 0,
    mb: 1,
    overflow: 'hidden'
  };

  const currencyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });

  const handleUpdateEditPhotos = (v: any) => {
    vehicle.disclosures.VHRImageUrl = v.url;
    vehicle.disclosures.VHRImageFile = v.fileName;
    setShowImageDialog(false);
    setVHRImage(v.url);
  };

  const [odometerRadioValue, setOdometerRadioValue] = useState<string>(
    disclosures?.odometerRadioChoice ? disclosures.odometerRadioChoice : ''
  );

  const handleOdometerRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOdometerRadioValue((event.target as HTMLInputElement).value);
    const tmp = { ...disclosuresInfo };
    tmp.odometerRadioChoice = (event.target as HTMLInputElement).value;
    setDisclosuresInfo(tmp);
  };

  const statesRef = collection(firestore, 'states');
  const statesQuery = query(statesRef);
  const { data: states } = useFirestoreCollectionData(statesQuery, { idField: 'id' });
  const [statesArray, setStatesArray] = useState<string[]>();

  useEffect(() => {
    if (states) {
      const tempStates: any[] = [];
      states.map((state: any) => {
        return tempStates.push(state.value);
      });
      setStatesArray(tempStates);
    }
  }, [states]);

  useEffect(() => {
    validateInputs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    oopVal,
    usVal,
    provinceVal,
    stateVal,
    disclosuresInfo.odometerStatus,
    odometerRadioValue,
    odometerReading,
    odometerDate
  ]);

  const validateInputs = () => {
    if (disclosuresInfo.outOfProvince) {
      if (provinceVal && provinceVal !== ' ') {
        setIsDisabledProvince(false);
      } else {
        setIsDisabledProvince(true);
      }
    } else {
      setIsDisabledProvince(false);
    }

    if (disclosuresInfo.usVehicle) {
      if (stateVal && stateVal !== ' ') {
        setIsDisabledState(false);
      } else {
        setIsDisabledState(true);
      }
    } else {
      setIsDisabledState(false);
    }

    if (
      disclosuresInfo.odometerStatus &&
      disclosuresInfo.odometerStatus.toLowerCase() === 'broken' &&
      odometerRadioValue === 'Option 1'
    ) {
      if (odometerReading) {
        setIsDisabledOdometer(false);
      } else {
        setIsDisabledOdometer(true);
      }

      if (odometerDate) {
        setIsDisabledOdometerDate(false);
      } else {
        setIsDisabledOdometerDate(true);
      }
    } else {
      setIsDisabledOdometer(false);
      setIsDisabledOdometerDate(false);
    }
  };

  const handleOdometerChange = (e: any) => {
    const tmp = { ...disclosuresInfo };
    tmp.odometerReading = e.target.value as string;
    setDisclosuresInfo(tmp);
    setOdometerReading(tmp.odometerReading);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (vehicle.disclosures.VHRImageUrl !== ' ') {
      setVHRImage(vehicle.disclosures.VHRImageUrl);
    }
    if (vehicle.disclosures.vhrDamages && vehicle.disclosures.vhrDamages.length > 0) {
      setReportedDamages(vehicle.disclosures.vhrDamages);
    }
    if (disclosures.odometerRadioChoice) {
      setOdometerRadioValue(disclosures.odometerRadioChoice);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removePhoto = () => {
    setVHRImage('');
    vehicle.disclosures.VHRImageUrl = '';
  };

  const handleRemoveDamage = async (item: any, index: number) => {
    const docRef = doc(firestore, `vehicles/${vehicle.id}`);
    await updateDoc(docRef, { 'disclosures.vhrDamages': arrayRemove(item) });
    const tempDamages: any[] = [];
    reportedDamages.forEach((damage: any, i: number) => {
      if (index !== i) {
        tempDamages.push(damage);
      }
    });
    setReportedDamages(tempDamages);
  };

  const handleEditDamage = (item: any, index: number) => {
    setEditDamage(true);
    setCurrentDamageIndex(index);
    setShowDamageDialog(true);
    formik.initialValues.cost = item.cost;
    formik.initialValues.description = item.description;
    formik.initialValues.date = item.date;
    setVhrDate(item.date);
  };

  const damageSchema = Yup.object().shape({
    cost: Yup.string().required('Cost is required'),
    description: Yup.string().required('Description is required')
    // date: Yup.date().required('Date is required')
  });

  const formik = useFormik<ReportedDamage>({
    initialValues: {
      cost: 0,
      description: '',
      date: undefined
    },
    validationSchema: damageSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      setSubmitting(true);
      try {
        if (!formik.values.date) {
          values = { ...values, date: Timestamp.now() };
        }

        const tempCost = values.cost.toString().replace(/[$,]/g, '');

        values.cost = parseInt(tempCost);

        const tempDamages = reportedDamages;

        if (editDamage && currentDamageIndex !== undefined) {
          tempDamages[currentDamageIndex] = values;
        } else {
          tempDamages.push(values);
        }
        setReportedDamages(tempDamages);

        vehicle.disclosures.vhrDamages = [...tempDamages];
        setSubmitting(false);
        setShowDamageDialog(false);
        resetForm();
        setVhrDate(undefined);
        enqueueSnackbar(editDamage ? 'Damage Edited' : 'Damage Added', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right'
          },
          action: (key) => <CloseIcon onClick={() => closeSnackbar(key)} />
        });
      } catch (error) {
        if (error instanceof Error) {
          setErrors({ afterSubmit: error.message });
          setSubmitting(false);
          resetForm();
          enqueueSnackbar('Error', {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right'
            }
          });
        }
      }
    }
  });

  const { errors, touched, handleSubmit, getFieldProps, setFieldValue, isValid, isSubmitting } =
    formik;

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="outlined"
          onClick={() => {
            updVehicleDis(disclosuresInfo, 3);
            navigate(PATH_SELLER.vehicleCapture);
          }}
        >
          Save and Exit
        </Button>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          backgroundColor:
            theme.palette.mode === 'dark'
              ? theme.palette.background.neutral
              : theme.palette.grey[50],
          borderRadius: '10px',
          maxWidth: '850px',
          width: '100%',
          border: '1px solid rgba(145, 158, 171, 0.24)',
          margin: 'auto',
          marginTop: '20px',
          paddingBottom: '20px'
        }}
      >
        <Box
          sx={{ margin: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        >
          <Box sx={{ mt: 3, width: 600 }}>
            <Typography
              variant="h3"
              sx={{ textAlign: 'center', color: theme.palette.text.primary }}
            >
              Declarations and Disclosures
            </Typography>
          </Box>
          <Box sx={{ mt: 5, mb: 2, width: 400 }}>
            <Typography variant="subtitle2" sx={{ mt: 2, mb: 2, fontWeight: '700' }}>
              VEHICLE HISTORY REPORT DISCLOSURE(S)
            </Typography>
            <Typography variant="subtitle2" sx={{ fontWeight: '500' }}>
              Add vehicle history report disclosures manually with $ value and description.
              Additionally you can upload an image of a history report you have.
            </Typography>
          </Box>

          <Box
            sx={{
              mt: 2,
              mb: 2,
              width: 400,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Typography variant="subtitle2" sx={{ mt: 2, mb: 2, fontWeight: '700' }}>
              ADD VHR DAMAGES
            </Typography>
            <Button
              sx={{ height: 36 }}
              variant="contained"
              onClick={() => {
                setEditDamage(false);
                formik.initialValues.cost = 0;
                formik.initialValues.description = '';
                formik.initialValues.date = undefined;
                setVhrDate(undefined);
                setShowDamageDialog(true);
              }}
            >
              Add Details
            </Button>
          </Box>

          {reportedDamages && reportedDamages.length > 0 ? (
            <>
              {reportedDamages.map((item: ReportedDamage, index: number) => {
                return (
                  <Box key={index} sx={{ display: 'flex' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        position: 'relative',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: 366,
                        backgroundColor:
                          theme.palette.mode === 'dark' ? 'unset' : theme.palette.common.white,
                        border: `1px ${theme.palette.divider} solid`,
                        borderRadius: '10px',
                        padding: 2,
                        mb: 1
                      }}
                    >
                      <Box sx={{ mr: 1 }}>
                        <Typography
                          sx={{
                            fontSize: '13px',
                            fontWeight: 'bold'
                          }}
                        >
                          {item.description ? item.description : 'No Description Provided'}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: '11px',
                            color: theme.palette.text.secondary
                          }}
                        >
                          {item.date
                            ? format(item.date?.toDate() as Date, 'dd MMM yyyy')
                            : 'No Date Provided'}
                        </Typography>
                      </Box>
                      <Typography
                        sx={{
                          fontSize: '14px',
                          fontWeight: 'bold'
                        }}
                      >
                        {currencyFormat.format(item.cost)}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        mb: 1
                      }}
                    >
                      {/* <IconButton size="small" onClick={() => handleEditDamage(item, index)}>
                        <EditIcon />
                      </IconButton> */}
                      <IconButton
                        size="small"
                        onClick={() => {
                          handleRemoveDamage(item, index);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>

                      <IconButton
                        size="small"
                        onClick={() => {
                          handleEditDamage(item, index);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Box>
                  </Box>
                );
              })}
            </>
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 400,
                height: 60,
                backgroundColor:
                  theme.palette.mode === 'dark' ? 'unset' : theme.palette.common.white,
                border: `1px ${theme.palette.divider} solid`,
                borderRadius: '10px',
                padding: 0,
                mb: 1
              }}
            >
              <Typography sx={{ fontSize: '12px' }}>You have no reported damages</Typography>
            </Box>
          )}

          {/* {reportedDamages.length > 0 ? (
            <>
              {reportedDamages &&
                reportedDamages.map((item: ReportedDamage, index: number) => {
                  return (
                    <Box key={index} sx={{ display: 'flex' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          position: 'relative',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          width: 366,
                          backgroundColor:
                            theme.palette.mode === 'dark' ? 'unset' : theme.palette.common.white,
                          border: `1px ${theme.palette.divider} solid`,
                          borderRadius: '10px',
                          padding: 2,
                          mb: 1
                        }}
                      >
                        <Box sx={{ mr: 1 }}>
                          <Typography
                            sx={{
                              fontSize: '13px',
                              fontWeight: 'bold'
                            }}
                          >
                            {item.description}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: '11px',
                              color: theme.palette.text.secondary
                            }}
                          >
                            {format(item.date?.toDate() as Date, 'dd MMM yyyy')}
                          </Typography>
                        </Box>
                        <Typography
                          sx={{
                            fontSize: '14px',
                            fontWeight: 'bold'
                          }}
                        >
                          {currencyFormat.format(item.cost)}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          mb: 1
                        }}
                      >
                        <IconButton size="small">
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={() => {
                            handleRemoveDamage(item, index);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  );
                })}
            </>
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 400,
                height: 60,
                backgroundColor:
                  theme.palette.mode === 'dark' ? 'unset' : theme.palette.common.white,
                border: `1px ${theme.palette.divider} solid`,
                borderRadius: '10px',
                padding: 0,
                mb: 1
              }}
            >
              <Typography sx={{ fontSize: '12px' }}>You have no reported damages</Typography>
            </Box>
          )} */}

          <Box
            sx={{
              mt: 2,
              mb: 2,
              width: 400,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Typography variant="subtitle2" sx={{ mt: 2, mb: 2, fontWeight: '700' }}>
              ADD VHR IMAGE (optional)
            </Typography>
            <Button
              sx={{ height: 36 }}
              variant="contained"
              onClick={() => {
                setShowImageDialog(true);
              }}
            >
              {VHRImage ? 'Edit/Delete Image' : 'Upload Image'}
            </Button>
          </Box>

          {VHRImage ? (
            <Box
              sx={{
                display: 'flex',
                width: 400
              }}
            >
              <Box
                component="img"
                sx={{
                  display: 'block',
                  m: 'auto',
                  float: 'left',
                  objectFit: 'cover',
                  borderRadius: '10px'
                }}
                src={VHRImage}
                alt="aa"
              />
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 400,
                height: 60,
                backgroundColor:
                  theme.palette.mode === 'dark' ? 'unset' : theme.palette.common.white,
                border: `1px ${theme.palette.divider} solid`,
                borderRadius: '10px',
                padding: 0,
                mb: 1
              }}
            >
              <Typography sx={{ fontSize: '12px' }}>You have no report image uploaded</Typography>
            </Box>
          )}

          <Box sx={{ mt: 3, width: 400 }}>
            <Typography variant="subtitle2" sx={{ mt: 2, mb: 2, fontWeight: '700' }}>
              ANNOUCEMENTS
            </Typography>
            <Box sx={boxStyle}>
              <VehicleDropBox
                dropBoxValue={disclosuresInfo.mtoReg}
                caption={'MTO Registration Brand'}
                arrayOfItems={['None', 'Irreparable', 'Rebuilt', 'Salvage']}
                updateDropValue={(v) => {
                  if (!v) return;
                  const tmp = { ...disclosuresInfo };
                  tmp.mtoReg = v as string;
                  setDisclosuresInfo(tmp);
                  setMtoVal(tmp.mtoReg);
                }}
              />
            </Box>
            {(!mtoVal || mtoVal === ' ') && showValidateError && (
              <FormHelperText sx={{ ml: 1, mb: 2 }} error={Boolean(!mtoVal || mtoVal === ' ')}>
                You must enter MTO registration.
              </FormHelperText>
            )}
            {/* <GridElement
              caption={'Do any other disclosures need to be made for this vehicle?'}
              dropBoxValue={disclosuresInfo.otherDisclosures ? 'Yes' : 'No'}
              arrayOfItems={['Yes', 'No']}
              updateDropValue={(value) => {
                const tmp = { ...disclosuresInfo };
                tmp.otherDisclosures = value === 'Yes' ? true : false;
                setDisclosuresInfo(tmp);
              }}
            /> */}
            <GridElement
              caption={'Is this vehicle being sold as-is?'}
              dropBoxValue={disclosuresInfo.soldAsIs ? 'Yes' : 'No'}
              arrayOfItems={['Yes', 'No']}
              updateDropValue={(value) => {
                const tmp = { ...disclosuresInfo };
                tmp.soldAsIs = value === 'Yes' ? true : false;
                setDisclosuresInfo(tmp);
              }}
            />
            <GridElement
              caption={'Has the manufacturer warranty been cancelled?'}
              dropBoxValue={disclosuresInfo.warrantyCancelled ? 'Yes' : 'No'}
              arrayOfItems={['Yes', 'No']}
              updateDropValue={(value) => {
                const tmp = { ...disclosuresInfo };
                tmp.warrantyCancelled = value === 'Yes' ? true : false;
                setDisclosuresInfo(tmp);
              }}
            />
            <Typography variant="subtitle2" sx={{ mt: 2, mb: 2, fontWeight: '700' }}>
              Within the last 7 years was this:
            </Typography>
            <GridElement
              caption={'an Out-of-Province vehicle?'}
              dropBoxValue={disclosuresInfo.outOfProvince ? 'Yes' : 'No'}
              arrayOfItems={['Yes', 'No']}
              updateDropValue={(value) => {
                const tmp = { ...disclosuresInfo };
                tmp.outOfProvince = value === 'Yes' ? true : false;
                setOopVal(tmp.outOfProvince);
                if (!tmp.outOfProvince) {
                  tmp.province = ' ';
                  setProvinceVal(tmp.province);
                }
                setDisclosuresInfo(tmp);
              }}
            />
            {disclosuresInfo.outOfProvince && (
              <Box sx={boxStyle}>
                <VehicleDropBox
                  dropBoxValue={disclosuresInfo.province}
                  caption={'Choose province or territory'}
                  arrayOfItems={[
                    'Alberta',
                    'British Columbia',
                    'Manitoba',
                    'New Brunswick',
                    'Newfoundland Labrador',
                    'Northwest Territories',
                    'Nova Scotia',
                    'Nunavut',
                    'Ontario',
                    'Prince Edward Island',
                    'Quebec',
                    'Saskatchewan',
                    'Yukon Territory'
                  ]}
                  updateDropValue={(v) => {
                    if (!v) return;
                    const tmp = { ...disclosuresInfo };
                    tmp.province = v as string;
                    setDisclosuresInfo(tmp);
                    setProvinceVal(tmp.province);
                  }}
                />
              </Box>
            )}
            {(!provinceVal || provinceVal === ' ') &&
              showValidateError &&
              disclosuresInfo.outOfProvince && (
                <FormHelperText
                  sx={{ ml: 1, mb: 2 }}
                  error={Boolean(!provinceVal || provinceVal === ' ')}
                >
                  You must select a province or territory.
                </FormHelperText>
              )}
            <GridElement
              caption={'a U.S. vehicle?'}
              dropBoxValue={disclosuresInfo.usVehicle ? 'Yes' : 'No'}
              arrayOfItems={['Yes', 'No']}
              updateDropValue={(value) => {
                const tmp = { ...disclosuresInfo };
                tmp.usVehicle = value === 'Yes' ? true : false;
                setUsVal(tmp.usVehicle);
                if (!tmp.usVehicle) {
                  tmp.state = ' ';
                  setStateVal(tmp.state);
                }
                setDisclosuresInfo(tmp);
              }}
            />
            {disclosuresInfo.usVehicle && statesArray && (
              <Box sx={boxStyle}>
                <VehicleDropBox
                  dropBoxValue={disclosuresInfo.state}
                  caption={'Choose State'}
                  arrayOfItems={statesArray as string[]}
                  updateDropValue={(v) => {
                    if (!v) return;
                    const tmp = { ...disclosuresInfo };
                    tmp.state = v as string;
                    setDisclosuresInfo(tmp);
                    setStateVal(tmp.state);
                  }}
                />
              </Box>
            )}
            {(!stateVal || stateVal === ' ') && showValidateError && disclosuresInfo.usVehicle && (
              <FormHelperText sx={{ ml: 1, mb: 2 }} error={Boolean(!stateVal || stateVal === ' ')}>
                You must select a state.
              </FormHelperText>
            )}
            <Typography variant="subtitle2" sx={{ mt: 2, mb: 2, fontWeight: '700' }}>
              Distance Travelled
            </Typography>
            <Box sx={boxStyle}>
              <VehicleDropBox
                dropBoxValue={disclosuresInfo.odometerStatus}
                caption={'Odometer is'}
                arrayOfItems={['None', 'Faulty', 'Broken', 'Replaced', 'Rolled Back']}
                updateDropValue={(v) => {
                  if (!v) return;
                  const tmp = { ...disclosuresInfo };
                  tmp.odometerStatus = v as string;
                  setDisclosuresInfo(tmp);
                }}
              />
            </Box>
            {disclosuresInfo.odometerStatus &&
              disclosuresInfo.odometerStatus !== ' ' &&
              disclosuresInfo.odometerStatus.toLowerCase() !== 'none' && (
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={odometerRadioValue}
                    onChange={handleOdometerRadioChange}
                  >
                    {/* prettier-ignore */}
                    <FormControlLabel
                      value="Option 1"
                      control={<Radio />}
                      label={
                        <Typography sx={{ fontSize: '14px' }}>
                          The dealer can not determine the total distance
                          that the vehicle has been driven but can
                          determine the distance that the vehicle has
                          been driven as of some past date. The total
                          distance that the vehicle has been driven is
                          believed to be higher than the previously
                          recorded distance.
                        </Typography>
                      }
                    />
                    {odometerRadioValue === 'Option 1' && (
                      <>
                        <Box sx={boxStyle}>
                          <TextField
                            sx={{
                              mt: 1,
                              ml: 2,
                              color: 'black',
                              width: '85%'
                            }}
                            inputProps={{
                              style: { fontSize: '12px', fontWeight: '600' }
                            }}
                            InputLabelProps={{ style: { fontSize: '12px' } }}
                            variant="standard"
                            id="outlined-required"
                            label="Odometer Reading"
                            onChange={handleOdometerChange}
                            defaultValue={odometerReading}
                          />
                        </Box>
                        {(!odometerReading || odometerReading === '') && showValidateError && (
                          <FormHelperText
                            sx={{ ml: 1, mb: 2 }}
                            error={Boolean(!odometerReading || odometerReading === '')}
                          >
                            You must enter an odometer reading.
                          </FormHelperText>
                        )}
                        <Box sx={boxStyle}>
                          <VehicleCalendar
                            dropBoxValue={odometerDate ? odometerDate.toDate() : null}
                            caption={'Odometer Reading Date'}
                            updateDropValue={(v) => {
                              if (!v) {
                                const tmp = { ...disclosuresInfo };
                                tmp.odometerReadingDate = null;
                                setDisclosuresInfo(tmp);
                                setOdometerDate(null);
                              } else {
                                const tmp = { ...disclosuresInfo };
                                tmp.odometerReadingDate = Timestamp.fromDate(v);
                                setDisclosuresInfo(tmp);
                                setOdometerDate(tmp.odometerReadingDate);
                              }
                            }}
                          />
                        </Box>
                        {(!odometerDate || odometerDate !== null) && showValidateError && (
                          <FormHelperText
                            sx={{ ml: 1, mb: 2 }}
                            error={Boolean(!odometerDate || odometerDate === null)}
                          >
                            You must enter an odometer reading date.
                          </FormHelperText>
                        )}
                      </>
                    )}
                    {/* prettier-ignore */}
                    <FormControlLabel
                      sx={{
                        marginTop: '15px'
                      }}
                      value="Option 2"
                      control={<Radio />}
                      label={
                        <Typography sx={{ fontSize: '14px' }}>
                          <span style={{ fontWeight: 'bold' }}>True Kilometers/Mileage Unknown:</span><br></br>
                          The dealer can determine neither the total
                          distance that the vehicle has been driven, nor the
                          distance that the vehicle has been driven as of
                          some past date. The total distance that the
                          vehicle has been driven is unknown and may be
                          substantially higher that the reading shown on the
                          odometer.
                        </Typography>}
                    />
                  </RadioGroup>
                </FormControl>
              )}
            <Typography variant="subtitle2" sx={{ mt: 2, mb: 2, fontWeight: '700' }}>
              Was The Vehicle:
            </Typography>
            <Box sx={boxStyle}>
              <VehicleDropBox
                dropBoxValue={disclosuresInfo.wasVehicle}
                caption={'Vehicle is:'}
                arrayOfItems={[
                  'None',
                  'Daily Rental',
                  'Police Cruiser',
                  'Emerg. Service Vehicle',
                  'Taxi or Limo',
                  'Water Damage',
                  'Fire Damage'
                ]}
                updateDropValue={(v) => {
                  if (!v) return;
                  const tmp = { ...disclosuresInfo };
                  tmp.wasVehicle = v as string;
                  setDisclosuresInfo(tmp);
                }}
              />
            </Box>
            <Typography variant="subtitle2" sx={{ mt: 2, mb: 2, fontWeight: '700' }}>
              Has Vehicle Ever Been Declared:
            </Typography>
            <Box sx={boxStyle}>
              <VehicleDropBox
                dropBoxValue={disclosuresInfo.wasDeclared}
                caption={'Vehicle Declared:'}
                arrayOfItems={[
                  'None',
                  'Total Loss',
                  'Theft Recovery',
                  // `Manufacturer's Warranty Cancelled`,
                  'Any Body Panels Painted or Replaced',
                  '2 or More Adjacent Panels Replaced'
                ]}
                updateDropValue={(v) => {
                  if (!v) return;
                  const tmp = { ...disclosuresInfo };
                  tmp.wasDeclared = v as string;
                  setDisclosuresInfo(tmp);
                }}
              />
            </Box>

            <Typography variant="subtitle2" sx={{ fontWeight: '700' }}>
              Other Disclosures and Notes
            </Typography>

            <TextField
              sx={{
                fontSize: '12px',
                width: 400,
                mt: 1,
                borderRadius: '10px',
                border: `1px ${theme.palette.divider} solid`,
                backgroundColor:
                  theme.palette.mode === 'dark' ? 'unset' : theme.palette.common.white
              }}
              inputProps={{ style: { fontSize: 12 } }}
              InputLabelProps={{ style: { fontSize: 12, color: 'grey' } }}
              id="outlined-multiline-flexible"
              multiline
              rows={5}
              value={otherNotes}
              onChange={(event) => {
                setOtherNotes(event.target.value);
                const tmp = { ...disclosuresInfo };
                tmp.notes = event.target.value;
                setDisclosuresInfo(tmp);
              }}
              margin="normal"
              variant="outlined"
            />
            <Typography variant="subtitle2" sx={{ mt: 2, mb: 2, fontWeight: '700' }}>
              INSPECTION SUMMARY
            </Typography>
            <GridElement
              caption={'Is this vehicle drivable?'}
              dropBoxValue={disclosuresInfo.drivable ? 'Yes' : 'No'}
              arrayOfItems={['Yes', 'No']}
              updateDropValue={(value) => {
                const tmp = { ...disclosuresInfo };
                tmp.drivable = value === 'Yes' ? true : false;
                setDisclosuresInfo(tmp);
              }}
            />
            <GridElement
              caption={'Is there structural damage?'}
              dropBoxValue={disclosuresInfo.structDamage ? 'Yes' : 'No'}
              arrayOfItems={['Yes', 'No']}
              updateDropValue={(value) => {
                const tmp = { ...disclosuresInfo };
                tmp.structDamage = value === 'Yes' ? true : false;
                setDisclosuresInfo(tmp);
              }}
            />
            <GridElement
              caption={
                'Has this vehicle had repairs, replacements or alterations done to the structure?'
              }
              dropBoxValue={disclosuresInfo.structRepairs ? 'Yes' : 'No'}
              arrayOfItems={['Yes', 'No']}
              updateDropValue={(value) => {
                const tmp = { ...disclosuresInfo };
                tmp.structRepairs = value === 'Yes' ? true : false;
                setDisclosuresInfo(tmp);
              }}
            />
            {disclosuresInfo.structRepairs && (
              <>
                <Typography variant="subtitle2" sx={{ fontWeight: '700', marginTop: '10px' }}>
                  Repair Notes
                </Typography>
                <TextField
                  sx={{
                    fontSize: '12px',
                    width: 400,
                    mt: 1,
                    borderRadius: '10px',
                    border: `1px ${theme.palette.divider} solid`,
                    backgroundColor:
                      theme.palette.mode === 'dark' ? 'unset' : theme.palette.common.white
                  }}
                  inputProps={{ style: { fontSize: 12 } }}
                  InputLabelProps={{ style: { fontSize: 12, color: 'grey' } }}
                  id="outlined-multiline-flexible"
                  multiline
                  rows={5}
                  value={repairNotes}
                  onChange={(event) => {
                    setRepairNotes(event.target.value);
                    const tmp = { ...disclosuresInfo };
                    tmp.repairNotes = event.target.value;
                    setDisclosuresInfo(tmp);
                  }}
                  margin="normal"
                  variant="outlined"
                />
              </>
            )}
            <GridElement
              caption={'Any previous damage repaired exceeding $3000?'}
              dropBoxValue={disclosuresInfo.prevRepairs ? 'Yes' : 'No'}
              arrayOfItems={['Yes', 'No']}
              updateDropValue={(value) => {
                const tmp = { ...disclosuresInfo };
                tmp.prevRepairs = value === 'Yes' ? true : false;
                setDisclosuresInfo(tmp);
              }}
            />
            <GridElement
              caption={
                'Have the manufacturers badges or decals been changed or have the original production specifications been changed?'
              }
              dropBoxValue={disclosuresInfo.badgesChanged ? 'Yes' : 'No'}
              arrayOfItems={['Yes', 'No']}
              updateDropValue={(value) => {
                const tmp = { ...disclosuresInfo };
                tmp.badgesChanged = value === 'Yes' ? true : false;
                setDisclosuresInfo(tmp);
              }}
            />
            <GridElement
              caption={'Anti-Lock Brakes inoperable?'}
              dropBoxValue={disclosuresInfo.inopABS ? 'Yes' : 'No'}
              arrayOfItems={['Yes', 'No']}
              updateDropValue={(value) => {
                const tmp = { ...disclosuresInfo };
                tmp.inopABS = value === 'Yes' ? true : false;
                setDisclosuresInfo(tmp);
              }}
            />
            <GridElement
              caption={'Air bags missing/inoperable?'}
              dropBoxValue={disclosuresInfo.inopAirBags ? 'Yes' : 'No'}
              arrayOfItems={['Yes', 'No']}
              updateDropValue={(value) => {
                const tmp = { ...disclosuresInfo };
                tmp.inopAirBags = value === 'Yes' ? true : false;
                setDisclosuresInfo(tmp);
              }}
            />
            <GridElement
              caption={'Pollution control inoperable?'}
              dropBoxValue={disclosuresInfo.inopPollution ? 'Yes' : 'No'}
              arrayOfItems={['Yes', 'No']}
              updateDropValue={(value) => {
                const tmp = { ...disclosuresInfo };
                tmp.inopPollution = value === 'Yes' ? true : false;
                setDisclosuresInfo(tmp);
              }}
            />
            <Box sx={boxStyle} style={{ marginTop: '10px' }}>
              <VehicleNumberBox
                dropBoxValue={parseInt(disclosuresInfo.numberOfKeys.toString())}
                caption={'Number of keys'}
                arrayOfItems={[1, 2, 3, 4]}
                updateDropValue={(v) => {
                  if (!v) return;
                  const tmp = { ...disclosuresInfo };
                  tmp.numberOfKeys = v.toString();
                  setDisclosuresInfo(tmp);
                }}
              />
            </Box>
            <GridElement
              caption={'Are the original manuals included?'}
              dropBoxValue={disclosuresInfo.manuals ? 'Yes' : 'No'}
              arrayOfItems={['Yes', 'No']}
              updateDropValue={(value) => {
                const tmp = { ...disclosuresInfo };
                tmp.manuals = value === 'Yes' ? true : false;
                setDisclosuresInfo(tmp);
              }}
            />
            <GridElement
              caption={'5th wheel?'}
              dropBoxValue={disclosuresInfo.fifthWheel ? 'Yes' : 'No'}
              arrayOfItems={['Yes', 'No']}
              updateDropValue={(value) => {
                const tmp = { ...disclosuresInfo };
                tmp.fifthWheel = value === 'Yes' ? true : false;
                setDisclosuresInfo(tmp);
              }}
            />
            <GridElement
              caption={'Salvage?'}
              dropBoxValue={disclosuresInfo.salvage ? 'Yes' : 'No'}
              arrayOfItems={['Yes', 'No']}
              updateDropValue={(value) => {
                const tmp = { ...disclosuresInfo };
                tmp.salvage = value === 'Yes' ? true : false;
                setDisclosuresInfo(tmp);
              }}
            />

            <Typography variant="subtitle2" sx={{ fontWeight: '700' }}>
              Do any of these four mechanical items need repair, if so select which:
            </Typography>
            <VehicleCheckbox
              propertyVal={disclosuresInfo.mechNeedRepairs}
              arrayOfItems={['Engine', 'Transmission', 'Fuel System/Hybrid Battery', 'Power Train']}
              updateProperty={(value) => {
                const tmp = { ...disclosuresInfo };
                tmp.mechNeedRepairs = value;
                setDisclosuresInfo(tmp);
              }}
            />
            {/* prettier-ignore */}
            <Divider
              sx={{
                mt: 0.5,
                mb: 0.5,
                borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
              }}
            />
            <Typography variant="subtitle2" sx={{ fontWeight: '700' }}>
              Do any of these four need repair, if so select which:
            </Typography>
            <VehicleCheckbox
              propertyVal={disclosuresInfo.electricNeedRepairs}
              arrayOfItems={[
                'Computer',
                'Electrical System',
                'Air Conditioning',
                'Subframe/Suspension'
              ]}
              updateProperty={(value) => {
                const tmp = { ...disclosuresInfo };
                tmp.electricNeedRepairs = value;
                setDisclosuresInfo(tmp);
              }}
            />
            {/* prettier-ignore */}
            <Divider
              sx={{
                mt: 0.5,
                mb: 0.5,
                borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
              }}
            />
          </Box>
        </Box>
      </Box>
      {/* prettier-ignore */}
      <Divider
        sx={{
          mt: 2,
          borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
        }}
      />
      <Box sx={{ m: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Button variant="text" color="inherit" onClick={() => updVehicleDis(disclosuresInfo, 2)}>
          Back
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            if (
              isDisabledProvince ||
              isDisabledState ||
              isDisabledOdometer ||
              isDisabledOdometerDate
            ) {
              setShowValidateError(true);
            } else {
              updVehicleDis(disclosuresInfo, 4);
            }
          }}
        >
          Next: Condition
        </Button>
      </Box>
      <MoreItemsIndicator />
      <Dialog open={showDamageDialog} onClose={() => setShowDamageDialog(false)}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            width: 450,
            position: 'relative'
          }}
        >
          <DialogTitle>{editDamage ? 'Edit' : 'Add'} Damage Details</DialogTitle>
          <CloseIcon
            onClick={() => setShowDamageDialog(false)}
            sx={{
              cursor: 'pointer',
              position: 'absolute',
              right: '15px',
              top: '24px'
            }}
          />
        </Box>
        <DialogContent>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}
              <Stack>
                <NumericFormat
                  prefix="$"
                  decimalScale={0}
                  thousandSeparator={true}
                  allowNegative={false}
                  label="Total Cost"
                  {...getFieldProps('cost')}
                  error={Boolean(touched.cost && errors.cost)}
                  helperText={touched.cost && errors.cost}
                  fullWidth
                  customInput={TextField}
                  onChange={(event) => {
                    let inputValue = event.target.value;
                    inputValue = inputValue.replace(/^\$0+/, ''); // Remove leading zeros
                    setFieldValue('cost', inputValue);
                  }}
                />
                <Box sx={{ mt: 2, ...boxStyle }}>
                  <VehicleCalendar
                    allowPast={true}
                    // auctionMinDate={Timestamp.now().toDate()}
                    dropBoxValue={vhrDate ? vhrDate.toDate() : undefined}
                    caption={'Enter Date'}
                    // error={Boolean(touched.date && errors.date)}
                    // helperText={touched.date && errors.date}
                    updateDropValue={(v) => {
                      if (v) {
                        const date = Timestamp.fromDate(v);
                        setVhrDate(date);
                        setFieldValue('date', date);
                      }
                    }}
                  />
                </Box>
                <TextField
                  minRows={4}
                  multiline
                  sx={{ mt: 2 }}
                  label="Description"
                  type="text"
                  {...getFieldProps('description')}
                  error={Boolean(touched.description && errors.description)}
                  helperText={touched.description && errors.description}
                ></TextField>
              </Stack>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: '100%',
                  mt: 3
                }}
              >
                <Button variant="outlined" onClick={() => setShowDamageDialog(false)}>
                  Cancel
                </Button>
                <LoadingButton
                  size="large"
                  type="submit"
                  variant="contained"
                  // disabled={!isValid}
                  loading={isSubmitting}
                >
                  {editDamage ? 'Save Damage' : 'Add Damage'}
                </LoadingButton>
              </Box>
            </Form>
          </FormikProvider>
        </DialogContent>
      </Dialog>
      <Dialog open={showImageDialog} onClose={() => setShowImageDialog(false)}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            // width: 450,
            position: 'relative'
          }}
        >
          <DialogTitle>Add Vehicle History Report Image</DialogTitle>
          <CloseIcon
            onClick={() => setShowImageDialog(false)}
            sx={{
              cursor: 'pointer',
              position: 'absolute',
              right: '24px',
              top: '24px'
            }}
          />
        </Box>
        <DialogContent>
          <VHRImageDialog
            vin={vehicle.details.vin}
            vehicleId={vehicle.id}
            vehiclePhoto={newVehiclePhoto}
            imageUrl={vehicle.disclosures.VHRImageUrl}
            imageFilename={vehicle.disclosures.VHRImageFile}
            isDamages={isDamages}
            removePhoto={() => removePhoto()}
            updVehiclePhoto={(v) => handleUpdateEditPhotos(v)}
          />
        </DialogContent>
      </Dialog>
      {/* <Dialog open={showValidateError} onClose={() => setShowValidateError(!showValidateError)}>
        <Alert severity="error" color="error">
          <AlertTitle>Error</AlertTitle>
          You must complete this page before moving on or saving and quitting
        </Alert>
      </Dialog> */}
    </>
  );
}
