import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Drawer, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// components
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
//
import { MHidden } from '../../components/@material-extend';
import sidebarConfig from './SidebarConfig';
import sidebarConfigAdmin from './SidebarConfigAdmin';
import LogoText from 'components/LogoText';
import useAuth from 'hooks/useAuth';
import sidebarConfigSuperAdmin from './SidebarConfigSuperAdmin';
import sidebarConfigCaptureRep from './SidebarConfigCaptureRep';
import { useTheme } from '@mui/material/styles';
import sidebarConfigBuyerOnly from './SidebarConfigBuyerOnly';
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 0;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.complex
    })
  }
}));

const LogoIconStyle = styled('div')(({ theme }) => ({
  width: '95%',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'space-between'
}));

// ----------------------------------------------------------------------

type DashboardSidebarProps = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }: DashboardSidebarProps) {
  const { pathname } = useLocation();
  const { isCollapse } = useCollapseDrawer();
  const { currentUser } = useAuth();
  const theme = useTheme();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': { height: '975px', display: 'flex', flexDirection: 'column' }
      }}
    >
      <MHidden width="lgUp">
        <LogoIconStyle>
          <Box component={RouterLink} to="/" sx={{ p: 2, display: 'inline-flex' }}>
            <LogoText />
          </Box>
          <IconButton onClick={onCloseSidebar} sx={{ color: 'text.primary' }}>
            <MenuIcon />
          </IconButton>
        </LogoIconStyle>
      </MHidden>
      {currentUser && currentUser.currentDealershipRole.toLowerCase() === 'superadmin' && (
        <NavSection navConfig={sidebarConfigSuperAdmin} isShow={!isCollapse} />
      )}
      {currentUser && currentUser.currentDealershipRole.toLowerCase() === 'admin' && (
        <NavSection navConfig={sidebarConfigAdmin} isShow={!isCollapse} />
      )}
      {currentUser && currentUser.currentDealershipRole.toLowerCase() === 'capturerep' && (
        <NavSection navConfig={sidebarConfigCaptureRep} isShow={!isCollapse} />
      )}
      {currentUser &&
        currentUser.currentDealershipRole.toLowerCase() !== 'admin' &&
        currentUser.currentDealershipRole.toLowerCase() !== 'capturerep' &&
        currentUser.currentDealershipRole.toLowerCase() !== 'superadmin' && (
          <NavSection navConfig={sidebarConfig} isShow={!isCollapse} />
        )}
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        height: `clac(100% - 80px)`,
        width: {
          lg: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH
        },
        position: 'absolute'
      }}
    >
      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              top: '83px',
              width: DRAWER_WIDTH,
              borderRight:
                theme.palette.mode === 'dark'
                  ? 'unset !important'
                  : `1px ${theme.palette.grey[100]} solid`,
              // bgcolor: 'background.default',
              backgroundColor:
                theme.palette.mode === 'dark'
                  ? theme.palette.background.paper
                  : 'transparent !important',
              ...(isCollapse && {
                width: COLLAPSE_WIDTH
              })
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
