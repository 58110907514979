import { Grid, Typography, Button, TextField, Box, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { MIconButton } from 'components/@material-extend';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Dealership } from 'types/dealership';
import CloseIcon from '@mui/icons-material/Close';
import { useFirestore } from 'reactfire';
import { doc, updateDoc, Timestamp, getDoc, arrayRemove, arrayUnion } from 'firebase/firestore';
import { fDateTimeSuffix } from 'components/shared/dateTimeFormat';

type Props = {
  dealership: Dealership;
};

const DrawerNotes = ({ dealership }: Props) => {
  const theme = useTheme();
  const firestore = useFirestore();
  const [note, setNote] = useState<string>();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [currentDealership, setCurrentDealership] = useState<Dealership>();

  const handleTextChange = (text: string) => {
    setNote(text);
  };

  const getDealership = async () => {
    const docRef = doc(firestore, `dealerships/${dealership.id}`);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const currentDealership = { id: docSnap.id, ...docSnap.data() } as Dealership;
      setCurrentDealership(currentDealership);
    }
  };

  const handleAddNote = async () => {
    if (note) {
      const docRef = doc(firestore, `dealerships/${dealership.id}`);
      await updateDoc(docRef, {
        notes: arrayUnion({
          text: note,
          dateTime: Timestamp.now()
        })
      });
      getDealership();
      enqueueSnackbar('Note Added', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        },
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <CloseIcon />
          </MIconButton>
        )
      });
    } else {
      enqueueSnackbar('Type a note first', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        },
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <CloseIcon />
          </MIconButton>
        )
      });
    }
  };

  const handleRemoveNote = async (item: any) => {
    const docRef = doc(firestore, `dealerships/${dealership.id}`);
    await updateDoc(docRef, { notes: arrayRemove(item) });
    getDealership();
    enqueueSnackbar('Note Removed', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right'
      },
      action: (key) => (
        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
          <CloseIcon />
        </MIconButton>
      )
    });
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getDealership();
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealership]);

  return (
    <Grid sx={{ color: theme.palette.secondary.darker, m: '19px' }} xs={12}>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid>
          <Typography
            variant="subtitle2"
            component="h2"
            paragraph
            sx={{
              fontWeight: 700,
              mb: 0,
              color:
                theme.palette.mode === 'dark'
                  ? theme.palette.common.white
                  : theme.palette.text.primary
            }}
          >
            Dealership Notes
          </Typography>
        </Grid>
        <TextField
          fullWidth
          multiline
          minRows={2}
          maxRows={8}
          placeholder="Type a message"
          onChange={(e) => handleTextChange(e.target.value)}
        />
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
            marginTop: '20px'
          }}
        >
          <Button
            variant="contained"
            sx={{ backgroundColor: theme.palette.primary.main }}
            onClick={handleAddNote}
          >
            Add Note
          </Button>
        </Grid>
        {currentDealership && currentDealership.notes && (
          <Box>
            <Grid>
              <Typography
                variant="subtitle2"
                component="h2"
                paragraph
                sx={{
                  fontWeight: 700,
                  mb: 0,
                  color:
                    theme.palette.mode === 'dark'
                      ? theme.palette.common.white
                      : theme.palette.text.primary
                }}
              >
                {dealership.notes && dealership.notes?.length > 0
                  ? `Past Notes (${dealership.notes?.length})`
                  : `Past Notes (0)`}
              </Typography>
            </Grid>
            {currentDealership &&
              currentDealership.notes.map((item, index) => {
                return (
                  <Grid key={index}>
                    <Typography
                      sx={{
                        fontSize: '14px',
                        marginTop: '5px',
                        color:
                          theme.palette.mode === 'dark'
                            ? theme.palette.common.white
                            : theme.palette.text.primary
                      }}
                    >
                      {item.text}
                    </Typography>
                    <Box sx={{ display: 'flex', marginTop: '5px' }}>
                      <Typography sx={{ fontSize: '12px', color: theme.palette.text.secondary }}>
                        {fDateTimeSuffix(item.dateTime.toDate())} |
                      </Typography>
                      <Button
                        variant="text"
                        onClick={() => {
                          handleRemoveNote(item);
                        }}
                        sx={{
                          padding: 0,
                          marginTop: '-3px',
                          color: theme.palette.error.main
                        }}
                      >
                        Delete
                      </Button>
                    </Box>
                    <Divider
                      sx={{
                        marginTop: '5px',
                        borderColor:
                          theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
                      }}
                    />
                  </Grid>
                );
              })}
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default DrawerNotes;
