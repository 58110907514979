import {
  Container,
  Typography,
  Grid,
  Paper,
  Box,
  MenuItem,
  Tabs,
  Tab,
  Select,
  InputBase,
  SelectChangeEvent,
  TablePagination,
  ButtonGroup,
  Button,
  Popper,
  Grow,
  MenuList,
  Checkbox,
  Dialog,
  Divider,
  Alert,
  AlertTitle,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import { useTheme } from '@mui/material/styles';
import * as React from 'react';
import { useState, useEffect, useRef, useMemo } from 'react';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import { DebounceInput } from 'react-debounce-input';
import PreAuctionListItem from 'components/seller/preAuction/PreAuctionListItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { VehicleInfo } from 'types/vehicleInfo';
import { useFirestore } from 'reactfire';
import {
  collection,
  CollectionReference,
  doc,
  DocumentReference,
  getDoc,
  limit,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  where
} from 'firebase/firestore';
import useAuth from 'hooks/useAuth';
import CloseIcon from '@mui/icons-material/Close';
import LaunchSoonContent from 'components/seller/preAuction/LaunchSoonContent';
import ActiveContent from 'components/seller/preAuction/ActiveContent';
import Tour from 'reactour';
import { User } from 'constants/user';
import { useFirestoreByPath } from 'hooks/useFirestoreByPath';
import EmptyStateMessage from 'components/shared/EmptyStateMessage';
import { Dealership } from 'types/dealership';
import { sortPreAuctionVehiclesArray } from 'utils/searchHelper';
import { ViewportList } from 'react-viewport-list';
import AuctionDrawer from 'components/auction/drawer/AuctionDrawer';
import { LoadingButton } from '@mui/lab';
import { DialogAnimate } from 'components/animate';
import CaptureHistoryDrawerContent from 'components/seller/preAuction/CaptureHistoryDrawerContent';
import SharedDrawer from 'components/shared/SharedDrawer';
import { PATH_SELLER } from 'routes/paths';
import { useNavigate } from 'react-router-dom';
import { batchUpdateAddFront } from 'utils/batchSet';
import { useVehicleBatch } from 'utils/vehicleQuery';
import { NumericFormat } from 'react-number-format';
import { useSnackbar } from 'notistack';
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`notifications-tabpanel-${index}`}
      aria-labelledby={`notifications-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `pre-auction-tab-${index}`,
    'aria-controls': `pre-auction-tabpanel-${index}`
  };
};

export default function PreAuctionPage() {
  const { themeStretch } = useSettings();
  const theme = useTheme();
  const navigate = useNavigate();
  const { currentUser, user, logout } = useAuth();
  const firestore = useFirestore();
  const [searchValue, setSearchValue] = useState('');
  const [, setAnchorEl] = useState(false);
  const [popupOpened] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [checkedVehicles, setCheckedVehicles] = useState<string[]>([]);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isActiveModal, setIsActiveModal] = useState<boolean>(false);
  const options = ['Parked', 'Launching Soon', 'Active'];
  const [paymentMethods, setPaymentMethods] = useState<any[]>();
  const [floorPriceId, setFloorPriceId] = useState<string>();
  const { update } = useFirestoreByPath('vehicles');
  const [adminOmvicDialogOpen, setAdminOmvicDialogOpen] = useState<boolean>(false);
  const [curUser, setCurUser] = useState<User>();

  const handleCloseAdminOmvicDialog = () => {
    setAdminOmvicDialogOpen(!adminOmvicDialogOpen);
  };

  let [bidValue, setBidValue] = useState<number>(0);
  const [floorPriceDisabled, setFloorPriceDisabled] = useState<boolean>(
    !bidValue?.toString().endsWith('00')
  );

  const { enqueueSnackbar } = useSnackbar();

  const submitFloorPrice = async () => {
    if (floorPriceId) {
      await update(floorPriceId, { 'auction.floorPrice': parseInt(bidValue.toString(), 10) });
      enqueueSnackbar('Floor Price Updated', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        }
      });
      setFloorPriceModalOpen(false);
    }
  };

  const vehiclesViewRef = useRef(null);
  const parkedViewRef = useRef(null);
  const launchingSoonViewRef = useRef(null);

  const numberOfRows = 25;

  const [floorPriceModalOpen, setFloorPriceModalOpen] = useState<boolean>(false);
  const toggleFloorPriceModal = async (vehicleId: string) => {
    setFloorPriceId(vehicleId);
    setFloorPriceModalOpen(!floorPriceModalOpen);
  };

  const getCurrentDealership = async () => {
    const userDealershipRef = doc(firestore, `dealerships/${currentUser.currentDealershipId}`);
    const userDealerSnap = await getDoc(userDealershipRef);
    if (userDealerSnap.exists()) {
      const data = userDealerSnap.data();
      const currentDealership = { ...data, id: userDealerSnap.id } as Dealership;
      if (currentDealership.paymentMethods && currentDealership.paymentMethods.length > 0) {
        const tempPaymentMethods: any[] = [];
        currentDealership.paymentMethods.forEach((paymentMethod: any) => {
          tempPaymentMethods.push(paymentMethod);
        });
        setPaymentMethods(tempPaymentMethods);
      }
    }
  };

  const [collectionSize, setCollectionSize] = useState<number>(0);
  const countVehicleCollection = () => {
    const vehicleRef = collection(firestore, 'vehicles') as CollectionReference;
    const vehicleQuery = query(
      vehicleRef,
      where('dealershipId', '==', currentUser.currentDealershipId),
      where('auction.auctionStatus', 'in', [
        'parked',
        'launching soon',
        'Parked',
        'Launching Soon'
      ]),
      where('captureComplete', '==', true)
    );
    const unsubsribe = onSnapshot(vehicleQuery, (querySnapshot) => {
      setCollectionSize(querySnapshot.size);
    });
    return unsubsribe;
  };

  const [parkedCollectionSize, setParkedCollectionSize] = useState<number>(0);
  const countParkedVehicleCollection = () => {
    const vehicleRef = collection(firestore, 'vehicles') as CollectionReference;
    const vehicleQuery = query(
      vehicleRef,
      where('dealershipId', '==', currentUser.currentDealershipId),
      where('auction.auctionStatus', 'in', ['parked', 'Parked']),
      where('captureComplete', '==', true)
    );
    const unsubsribeParked = onSnapshot(vehicleQuery, (querySnapshot) => {
      setParkedCollectionSize(querySnapshot.size);
    });
    return unsubsribeParked;
  };

  const [launchingCollectionSize, setLaunchingCollectionSize] = useState<number>(0);
  const countLaunchingVehicleCollection = () => {
    const vehicleRef = collection(firestore, 'vehicles') as CollectionReference;
    const vehicleQuery = query(
      vehicleRef,
      where('dealershipId', '==', currentUser.currentDealershipId),
      where('auction.auctionStatus', 'in', ['launching soon', 'Launching Soon']),
      where('captureComplete', '==', true)
    );
    const unsubsribeLaunching = onSnapshot(vehicleQuery, (querySnapshot) => {
      setLaunchingCollectionSize(querySnapshot.size);
    });
    return unsubsribeLaunching;
  };

  useEffect(() => {
    const unsubscribe = countVehicleCollection();
    const unsubscribeParked = countParkedVehicleCollection();
    const unsubscribeLaunching = countLaunchingVehicleCollection();
    return () => {
      unsubscribe();
      unsubscribeParked();
      unsubscribeLaunching();
    };
  }, []);

  const [order, setOrder] = useState('createdDate');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(numberOfRows);
  const [lastDocArray, setLastDocArray] = useState<any[]>([]);
  const [firstDocArray, setFirstDocArray] = useState<any[]>([]);
  const [backPage, setBackPage] = useState<boolean>(false);
  const [lastDataVehicleRef, setLastDataVehicleRef] = useState<DocumentReference>();

  const queryConstraints = [
    where('dealershipId', '==', currentUser.currentDealershipId),
    where('auction.auctionStatus', 'in', ['parked', 'launching soon', 'Parked', 'Launching Soon']),
    where('captureComplete', '==', true),
    orderBy('createdDate', 'desc'),
    limit(rowsPerPage)
  ];

  const { data } = useVehicleBatch(
    page,
    queryConstraints,
    lastDocArray[page - 1],
    backPage,
    firstDocArray[page]
  );

  const allVehicles = useMemo(() => {
    const lastDoc = data?.docs[data?.docs?.length - 1];
    if (lastDoc) {
      const lastDocRef = data?.docs[data?.docs?.length - 1].ref as DocumentReference;
      setLastDataVehicleRef(lastDocRef);
    }
    // prettier-ignore
    return data?.docs?.map((doc) => ({ ...doc.data(), id: doc.id }));
  }, [data]);

  const [orderPark, setOrderPark] = useState('timeAsc');
  const [pagePark, setPagePark] = useState(0);
  const [rowsPerPagePark, setRowsPerPagePark] = useState(numberOfRows);
  const [lastDocParkArray, setLastDocParkArray] = useState<any[]>([]);
  const [firstDocParkArray, setFirstDocParkArray] = useState<any[]>([]);
  const [backPagePark, setBackPagePark] = useState<boolean>(false);
  const [lastDataParkVehicleRef, setLastDataParkVehicleRef] = useState<DocumentReference>();

  const queryConstraintsParked = [
    where('dealershipId', '==', currentUser.currentDealershipId),
    where('auction.auctionStatus', 'in', ['parked', 'Parked']),
    where('captureComplete', '==', true),
    orderBy('auction.captureDate', 'desc'),
    limit(rowsPerPagePark)
  ];

  const parkedData = useVehicleBatch(
    pagePark,
    queryConstraintsParked,
    lastDocParkArray[pagePark - 1],
    backPagePark,
    firstDocParkArray[pagePark]
  );

  const parkedVehicles = useMemo(() => {
    const lastDoc = parkedData?.data?.docs[parkedData?.data?.docs?.length - 1];
    if (lastDoc) {
      // prettier-ignore
      const lastDocRef = parkedData.data?.docs[parkedData.data?.docs?.length - 1].ref as DocumentReference;
      setLastDataParkVehicleRef(lastDocRef);
    }
    return parkedData.data?.docs?.map((doc) => ({ ...doc.data(), id: doc.id }));
  }, [parkedData.data]);

  const [orderLaunch, setOrderLaunch] = useState('timeAsc');
  const [pageLaunch, setPageLaunch] = useState(0);
  const [rowsPerPageLaunch, setRowsPerPageLaunch] = useState(numberOfRows);
  const [lastDocLaunchArray, setLastDocLaunchArray] = useState<any[]>([]);
  const [firstDocLaunchArray, setFirstDocLaunchArray] = useState<any[]>([]);
  const [backPageLaunch, setBackPageLaunch] = useState<boolean>(false);
  const [lastDataLaunchVehicleRef, setLastDataLaunchVehicleRef] = useState<DocumentReference>();

  const queryConstraintsLaunching = [
    where('dealershipId', '==', currentUser.currentDealershipId),
    where('auction.auctionStatus', 'in', ['launching soon', 'Launching Soon']),
    where('captureComplete', '==', true),
    orderBy('auction.launchDate', 'desc'),
    limit(rowsPerPageLaunch)
  ];

  const launchData = useVehicleBatch(
    pageLaunch,
    queryConstraintsLaunching,
    lastDocLaunchArray[pageLaunch - 1],
    backPageLaunch,
    firstDocLaunchArray[pageLaunch]
  );

  const launchingVehicles = useMemo(() => {
    const lastDoc = launchData?.data?.docs[launchData?.data?.docs?.length - 1];
    if (lastDoc) {
      // prettier-ignore
      const lastDocRef = launchData.data?.docs[launchData.data?.docs?.length - 1].ref as DocumentReference;
      setLastDataLaunchVehicleRef(lastDocRef);
    }
    return launchData.data?.docs?.map((doc) => ({ ...doc.data(), id: doc.id }));
  }, [launchData.data]);

  const [vehicles, setVehicles] = useState<VehicleInfo[]>();

  const handleChangePage = async (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      const firstDoc = data?.docs[0];
      const firstDocRef = firstDoc.ref as DocumentReference;
      const docRef = doc(firestore, `vehicles/${firstDocRef.id}`);
      const docSnap = await getDoc(docRef);
      const tempFirstDocs = firstDocArray;
      if (docSnap.exists()) {
        tempFirstDocs.push(docSnap);
        setFirstDocArray(tempFirstDocs);
      }
      if (lastDataVehicleRef) {
        const docRef = doc(firestore, `vehicles/${lastDataVehicleRef.id}`);
        const docSnap = await getDoc(docRef);
        const tempLastDocs = lastDocArray;
        if (docSnap.exists()) {
          tempLastDocs.push(docSnap);
          setLastDocArray(tempLastDocs);
          setPage(newPage);
          setBackPage(false);
        }
      }
    } else {
      setPage(newPage);
      setBackPage(true);
    }
  };

  const handleChangePagePark = async (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > pagePark) {
      const firstDoc = parkedData?.data?.docs[0];
      const firstDocRef = firstDoc.ref as DocumentReference;
      const docRef = doc(firestore, `vehicles/${firstDocRef.id}`);
      const docSnap = await getDoc(docRef);
      const tempFirstDocs = firstDocParkArray;
      if (docSnap.exists()) {
        tempFirstDocs.push(docSnap);
        setFirstDocParkArray(tempFirstDocs);
      }
      if (lastDataParkVehicleRef) {
        const docRef = doc(firestore, `vehicles/${lastDataParkVehicleRef.id}`);
        const docSnap = await getDoc(docRef);
        const tempLastDocs = lastDocParkArray;
        if (docSnap.exists()) {
          tempLastDocs.push(docSnap);
          setLastDocParkArray(tempLastDocs);
          setPagePark(newPage);
          setBackPagePark(false);
        }
      }
    } else {
      setPagePark(newPage);
      setBackPagePark(true);
    }
  };

  const handleChangePageLaunch = async (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > pageLaunch) {
      const firstDoc = launchData?.data?.docs[0];
      const firstDocRef = firstDoc.ref as DocumentReference;
      const docRef = doc(firestore, `vehicles/${firstDocRef.id}`);
      const docSnap = await getDoc(docRef);
      const tempFirstDocs = firstDocLaunchArray;
      if (docSnap.exists()) {
        tempFirstDocs.push(docSnap);
        setFirstDocLaunchArray(tempFirstDocs);
      }
      if (lastDataLaunchVehicleRef) {
        const docRef = doc(firestore, `vehicles/${lastDataLaunchVehicleRef.id}`);
        const docSnap = await getDoc(docRef);
        const tempLastDocs = lastDocLaunchArray;
        if (docSnap.exists()) {
          tempLastDocs.push(docSnap);
          setLastDocLaunchArray(tempLastDocs);
          setPageLaunch(newPage);
          setBackPageLaunch(false);
        }
      }
    } else {
      setPageLaunch(newPage);
      setBackPageLaunch(true);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setFirstDocArray([]);
    setLastDocArray([]);
    setBackPage(false);
  };

  const handleChangeRowsPerPagePark = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPagePark(parseInt(event.target.value, 10));
    setPagePark(0);
    setFirstDocParkArray([]);
    setLastDocParkArray([]);
    setBackPagePark(false);
  };

  const handleChangeRowsPerPageLaunch = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPageLaunch(parseInt(event.target.value, 10));
    setPageLaunch(0);
    setFirstDocLaunchArray([]);
    setLastDocLaunchArray([]);
    setBackPageLaunch(false);
  };

  const handleOrderByChange = (event: SelectChangeEvent) => {
    setOrder(event.target.value);
  };

  const handleOrderByChangePark = (event: SelectChangeEvent) => {
    setOrderPark(event.target.value);
  };

  const handleOrderByChangeLaunch = (event: SelectChangeEvent) => {
    setOrderLaunch(event.target.value);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    setIsCheck([]);
    setIsCheckAll(false);
    setIsCheckParked([]);
    setIsCheckAllParked(false);
    setIsCheckLaunching([]);
    setIsCheckAllLaunching(false);
    setCheckedVehicles([]);
  };

  const [openVDPDrawer, setOpenVDPDrawer] = useState<boolean>(false);
  const [currentVehicle, setCurrentVehicle] = useState<VehicleInfo>();
  const [openEditDrawer, setOpenEditDrawer] = useState(false);
  const [openHistoryDrawer, setOpenHistoryDrawer] = useState(false);

  const handleCloseHistoryDrawer = () => {
    setOpenHistoryDrawer(!openHistoryDrawer);
  };

  const handleCloseEditDrawer = () => {
    setOpenEditDrawer(!openEditDrawer);
  };

  const handleCloseVDPDrawer = () => {
    setOpenVDPDrawer(!openVDPDrawer);
  };

  const SetDrawerVehicle = (index: number, vehicle: VehicleInfo) => {
    setCurrentVehicle(vehicle);
    if (index === 0) {
      setOpenHistoryDrawer(!openHistoryDrawer);
    } else if (index === 1) {
      setOpenEditDrawer(!openEditDrawer);
    } else if (index === 2) {
      setOpenVDPDrawer(!openVDPDrawer);
    }
  };

  const handleMoveToParked = async (vehicleIds: string[]) => {
    if (vehicleIds.length === 0) return;
    const arrayOfData = vehicleIds.map((id) => ({
      auction: {
        auctionStatus: 'parked'
      }
    }));
    await batchUpdateAddFront('vehicles', vehicleIds, arrayOfData);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
    setOpen(false);
    if (index === 0) {
      if (checkedVehicles.length > 0) {
        if (tabValue === 0) {
          handleMoveToParked(isCheck);
          setIsCheck([]);
          setIsCheckAll(false);
        } else if (tabValue === 1) {
          handleMoveToParked(isCheckParked);
          setIsCheckParked([]);
          setIsCheckAllParked(false);
        } else if (tabValue === 2) {
          handleMoveToParked(isCheckLaunching);
          setIsCheckLaunching([]);
          setIsCheckAllLaunching(false);
        }
      } else {
        setIsOpenModal(true);
      }
    } else if (index === 1) {
      setIsOpenModal(true);
      if (paymentMethods && paymentMethods.length > 0) {
        setIsActiveModal(false);
      }
      // if (curUser && curUser.omvicNumber) {
      //   setIsOpenModal(true);
      //   if (paymentMethods && paymentMethods.length > 0) {
      //     setIsActiveModal(false);
      //   }
      // } else {
      //   handleCloseAdminOmvicDialog();
      // }
    } else {
      setIsOpenModal(true);
      if (paymentMethods && paymentMethods.length > 0) {
        setIsActiveModal(true);
      }
      // if (curUser && curUser.omvicNumber) {
      //   setIsOpenModal(true);
      //   if (paymentMethods && paymentMethods.length > 0) {
      //     setIsActiveModal(true);
      //   }
      // } else {
      //   handleCloseAdminOmvicDialog();
      // }
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  // ----------------------------- all vehicles

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState<string[]>([]);

  const handleSelectAll = (e: any) => {
    if (vehicles) {
      setIsCheckAll(!isCheckAll);
      const currentVehicles = vehicles.map((item) => item.id);
      setIsCheck(currentVehicles);
      setCheckedVehicles(currentVehicles);
      if (isCheckAll) {
        setIsCheck([]);
        setCheckedVehicles([]);
      }
    }
  };

  const handleCheck = (checked: boolean, id: string) => {
    setIsCheck([...isCheck, id]);
    setCheckedVehicles([...checkedVehicles, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
      setCheckedVehicles(checkedVehicles.filter((item) => item !== id));
    }
  };

  // ----------------------------- parked vehicles

  const [isCheckAllParked, setIsCheckAllParked] = useState(false);
  const [isCheckParked, setIsCheckParked] = useState<string[]>([]);

  const handleSelectAllParked = (e: any) => {
    if (parkedVehicles) {
      setIsCheckAllParked(!isCheckAllParked);
      const currentParkedVehicles = parkedVehicles.map((item) => item.id);
      setIsCheckParked(currentParkedVehicles);
      setCheckedVehicles(currentParkedVehicles);
      if (isCheckAllParked) {
        setIsCheckParked([]);
        setCheckedVehicles([]);
      }
    }
  };

  const handleCheckParked = (checked: boolean, id: string) => {
    setIsCheckParked([...isCheckParked, id]);
    setCheckedVehicles([...checkedVehicles, id]);
    if (!checked) {
      setIsCheckParked(isCheckParked.filter((item) => item !== id));
      setCheckedVehicles(checkedVehicles.filter((item) => item !== id));
    }
  };

  // ----------------------------- launching soon vehicles

  const [isCheckAllLaunching, setIsCheckAllLaunching] = useState(false);
  const [isCheckLaunching, setIsCheckLaunching] = useState<string[]>([]);

  const handleSelectAllLaunching = (e: any) => {
    if (launchingVehicles) {
      setIsCheckAllLaunching(!isCheckAllLaunching);
      const currentLaunchingVehicle = launchingVehicles.map((item) => item.id);
      setIsCheckLaunching(currentLaunchingVehicle);
      setCheckedVehicles(currentLaunchingVehicle);
      if (isCheckAllLaunching) {
        setIsCheckLaunching([]);
        setCheckedVehicles([]);
      }
    }
  };

  const handleCheckLaunching = (checked: boolean, id: string) => {
    setIsCheckLaunching([...isCheckLaunching, id]);
    setCheckedVehicles([...checkedVehicles, id]);
    if (!checked) {
      setIsCheckLaunching(isCheckLaunching.filter((item) => item !== id));
      setCheckedVehicles(checkedVehicles.filter((item) => item !== id));
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setAnchorEl(false);
      if (allVehicles) {
        setVehicles(allVehicles);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [popupOpened, allVehicles]);

  const useStyles = makeStyles({
    tourContainer: {
      maxWidth: '500px !important',
      borderRadius: '10px !important'
    },
    pagination: {
      borderTopStyle: 'none',
      fontWeight: '700',
      borderTop: 'none !important'
    },
    inviteButton: {
      backgroundColor: theme.palette.primary.main
    },
    searchContainer: {
      width: '100%',
      maxWidth: '400px',
      height: 'auto',
      padding: '5px 0px',
      marginBottom: '0px',
      marginRight: '20px'
    },
    search: {
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.background.neutral : 'transparent !important',
      border: theme.palette.mode === 'dark' ? 'unset' : `1px solid ${theme.palette.divider}`,
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: '100%'
    },
    searchInput: {
      fontSize: '14px',
      width: '100%',
      height: '34px',
      margin: '0 10px',
      border: 'none',
      padding: '5px 10px',
      outline: 'none !important',
      marginLeft: 2,
      flex: 1,
      backgroundColor: 'transparent !important',
      color:
        theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.text.secondary,
      '&::placeholder': {
        color:
          theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.text.secondary,
        fontSize: '14px'
      }
    },
    searchIcon: {
      fill: theme.palette.primary.main,
      marginRight: '5px'
    },
    secondaryButton: {
      backgroundColor: theme.palette.grey[50],
      color: theme.palette.info.light
    },
    subtitle: {
      fontWeight: 'bold',
      color: theme.palette.secondary.darker
    },
    subtitle2: {
      color: theme.palette.info.light,
      fontWeight: 'normal'
    },
    list: {
      '& li:first-child, & li:last-child': {
        '& button': {
          border: `1px solid ${theme.palette.grey[100]}`,
          color: theme.palette.info.light,
          width: '100px'
        }
      },
      '& li': {
        paddingLeft: 3,
        paddingRight: 3,
        '& button': {
          color: theme.palette.info.main,
          minWidth: '40px'
        }
      }
    },
    forwardIcon: {
      fontSize: '12px !important'
    },
    backwardIcon: {
      fontSize: '12px !important'
    },
    divider: {
      width: '93%',
      [theme.breakpoints.down(1524)]: {
        width: '90%'
      },
      [theme.breakpoints.down(863)]: {
        width: '87%'
      }
    },
    gridAvatarTitle: {
      [theme.breakpoints.down(1486)]: {
        flexBasis: '40%',
        maxWidth: '40%'
      },
      [theme.breakpoints.down(1299)]: {
        flexBasis: '45%',
        maxWidth: '45%'
      }
    },
    gridDateTitle: {
      marginRight: '64px',
      [theme.breakpoints.down(1330)]: {
        marginRight: '24px'
      }
    },
    menuPopover: {
      boxShadow: '0 0 2px 0 rgba(139, 148, 156, 0.24),0 10px 20px -4px rgba(139, 148, 156, 0.24)',
      // backgroundColor: theme.palette.primary.contrastText,
      paddingTop: '20px',
      paddingBottom: '20px',
      '@global': {
        '.MuiMenuItem-root': {
          paddingBottom: '6px'
        },
        '.MuiMenuItem-root:last-child': {
          color: theme.palette.error.dark
        }
      }
    },
    dataGrid: {
      '@global': {
        '.MuiDataGrid-columnHeaders': {
          visibility: 'hidden'
        },
        '.MuiDataGrid-virtualScroller': {
          marginTop: '0 !important'
        },
        '.flexEndCell': {
          justifyContent: 'flex-end',
          padding: '0'
        },
        '.flexEndCellPopup': {
          justifyContent: 'flex-end',
          padding: '0',
          '& .MuiTouchRipple-root': {
            display: 'none'
          }
        },
        '.MuiDataGrid-row': {
          minHeight: '88px !important',
          maxHeight: '88px !important',
          '&:hover': {
            backgroundColor: 'transparent !important'
          }
        },
        '.MuiDataGrid-cell': {
          minHeight: '88px !important',
          maxHeight: '88px !important',
          borderBottom: '0'
        },
        '.imageCell': {
          padding: '0'
        }
      }
    },
    headerTextContainer: {
      width: '100%',
      minHeight: '65px',
      height: 'auto',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '0px'
    },
    controlsContainer: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'flex-end'
    },
    headerText: {
      fontSize: '14px',
      marginRight: '18px',
      marginLeft: '5px'
    },
    inputText: {
      fontSize: '14px'
    }
  });

  const styles = useStyles();

  const [tourOpen, setTourOpen] = useState(false);
  const { update: updateUser } = useFirestoreByPath<User>('users');

  const [userDeleted, setUserDeleted] = useState<boolean>(false);
  const [buyerOnly, setBuyerOnly] = useState<boolean>(false);

  const snapshotUser = () => {
    const userDoc = doc(firestore, `users/${user?.uid}`) as DocumentReference<User>;
    onSnapshot(userDoc, (doc: any) => {
      const userObj = { ...doc.data(), id: doc.id } as User;
      setCurUser(userObj);
      if (!userObj.email) {
        logout?.().then(() => {
          navigate('.');
          window.location.reload();
        });
      }
      if (userObj.showPreAuctionSellTour && !tourOpen) {
        setTourOpen(true);
      }
      if (userObj && userObj.status.toLowerCase() === 'suspended') {
        setUserSuspended(true);
      } else {
        setUserSuspended(false);
      }
      if (userObj && userObj.status.toLowerCase() === 'disabled') {
        setUserDisabled(true);
      } else {
        setUserDisabled(false);
      }
      if (userObj && userObj.status === 'buyerOnly') {
        setBuyerOnly(true);
      } else {
        setBuyerOnly(false);
      }
    });
  };

  const [userDisabled, setUserDisabled] = useState<boolean>(false);
  const [userSuspended, setUserSuspended] = useState<boolean>(false);

  const handleNavigate = () => {
    navigate('/dashboard');
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      snapshotUser();
      getCurrentDealership();
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [vehicleEmptyState, setVehicleEmptyState] = useState<boolean>(false);
  const [parkedEmptyState, setParkedEmptyState] = useState<boolean>(false);
  const [launchingSoonEmptyState, setLaunchingSoonEmptyState] = useState<boolean>(false);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (allVehicles && (!vehicles || vehicles.length < 1)) {
        setVehicleEmptyState(true);
      } else {
        setVehicleEmptyState(false);
      }
      if (allVehicles && (!parkedVehicles || parkedVehicles.length < 1)) {
        setParkedEmptyState(true);
      } else {
        setParkedEmptyState(false);
      }
      if (allVehicles && (!launchingVehicles || launchingVehicles.length < 1)) {
        setLaunchingSoonEmptyState(true);
      } else {
        setLaunchingSoonEmptyState(false);
      }
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicles, parkedVehicles, launchingVehicles]);

  const handleCloseCalendarModal = () => {
    setIsOpenModal(!isOpenModal);
    setIsCheck([]);
    setIsCheckAll(false);
    setCheckedVehicles([]);
  };

  const handleCloseTour = async () => {
    await updateUser(user?.uid as string, { showPreAuctionSellTour: false });
    setTourOpen(false);
  };

  const steps = [
    {
      selector: '',
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Sell: Pre-Auction</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
              Pre-Auction allows sellers to manage all vehicles in a parked or launching soon status.
            </Typography>
          </Box>
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
              <span style={{ fontWeight: '900' }}>Parked</span> can be edited and reviewed to ensure the vehicle information is accurate. This is where sellers will decide the beginning and end times of their sale in either Launching soon or Active Auction. Parked vehicles are not visible to buyers.
            </Typography>
          </Box>
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
              <span style={{ fontWeight: '900' }}>Launching Soon</span> is where vehicles go right before Active Auction. Here, buyers are able to view, appraise and place autobids on the upcoming vehicles.
            </Typography>
          </Box>
        </Box>
      )
    },
    {
      selector: '#preAuctionSell2',
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Sell: Pre-Auction</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
              Tabs provide quick sorting options to keep the vehicles organized by their status.
            </Typography>
          </Box>
        </Box>
      )
    },
    {
      selector: '#preAuctionSell3',
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Sell: Pre-Auction</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
              Quickly see some information regarding when the vehicle will be launching and for how long.
            </Typography>
          </Box>
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
              You can also see the the set floor price (a buyer does not see this price) and who was the last person to edit the vehicle.
            </Typography>
          </Box>
        </Box>
      )
    },
    {
      selector: '#preAuctionSell4',
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Sell: Pre-Auction</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
              The action button lets you know what the status of the vehicle is (launching soon or parked) and allows you to change the vehicles status from parked, launching soon to active.
            </Typography>
          </Box>
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
              In the menu button you can also view capture history, edit the vehicle (which will move it back to draft) and view the VDP.
            </Typography>
          </Box>
        </Box>
      )
    }
  ];

  interface StyledTabsProps {
    id?: string;
    children?: React.ReactNode;
    value: number;
    onChange: (event: React.SyntheticEvent, newValue: number) => void;
  }

  const StyledTabs = styled((props: StyledTabsProps) => (
    <Tabs
      {...props}
      sx={{
        minHeight: 'unset !important'
      }}
      variant="scrollable"
      TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
  ))(({ theme }) => ({
    '& .MuiTab-root': {
      color:
        theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.text.secondary,
      backgroundColor:
        theme.palette.mode === 'dark'
          ? theme.palette.background.neutral
          : theme.palette.background.paper
    },
    '& .MuiTab-root:not(:last-of-type)': {
      marginRight: '10px'
    },
    '& .MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent !important',
      padding: '5px'
    },
    '& .Mui-selected': {
      backgroundColor: theme.palette.primary.lighter,
      color: `${theme.palette.primary.main} !important`
    },
    '& .MuiTabs-indicatorSpan': {
      display: 'none',
      maxWidth: 40,
      width: '100%',
      backgroundColor: theme.palette.chart.violet[2]
    }
  }));

  return (
    <>
      {!userDeleted && !userSuspended && !userDisabled && !buyerOnly ? (
        <Page title="Pre Auction | DealerGavel">
          <Container maxWidth={themeStretch ? false : 'xl'}>
            <Box className={styles.headerTextContainer}>
              <Box>
                <Typography
                  variant="h4"
                  component="h1"
                  paragraph
                  sx={{
                    marginTop: '0px',
                    marginBottom: '0px',
                    fontSize: '1rem !important',
                    color:
                      theme.palette.mode === 'dark'
                        ? theme.palette.common.white
                        : theme.palette.text.primary
                  }}
                >
                  Pre Auction: Selling
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexWrap: 'wrap'
              }}
            >
              <StyledTabs
                value={tabValue}
                onChange={handleChange}
                aria-label="Seller Pre-Auction Tabs"
                id="preAuctionSell2"
              >
                {vehicles && (
                  <Tab
                    sx={{
                      padding: '0px 10px',
                      borderRadius: '5px',
                      minHeight: '40px',
                      height: '40px'
                    }}
                    label={
                      <Typography sx={{ fontSize: '12px' }}>ALL ({collectionSize})</Typography>
                    }
                    {...a11yProps(0)}
                  />
                )}
                {parkedVehicles && (
                  <Tab
                    sx={{
                      padding: '0px 10px',
                      borderRadius: '5px',
                      minHeight: '40px',
                      height: '40px'
                    }}
                    label={
                      <Typography sx={{ fontSize: '12px' }}>
                        PARKED ({parkedCollectionSize})
                      </Typography>
                    }
                    {...a11yProps(1)}
                  />
                )}
                {launchingVehicles && (
                  <Tab
                    sx={{
                      padding: '0px 10px',
                      borderRadius: '5px',
                      minHeight: '40px',
                      height: '40px'
                    }}
                    label={
                      <Typography sx={{ fontSize: '12px' }}>
                        LAUNCHING SOON ({launchingCollectionSize})
                      </Typography>
                    }
                    {...a11yProps(2)}
                  />
                )}
              </StyledTabs>
              <Box className={styles.searchContainer}>
                <Paper component="form" className={styles.search}>
                  <DebounceInput
                    className={styles.searchInput}
                    placeholder="Search Vehicles by Make, Model, Year, etc."
                    minLength={2}
                    debounceTimeout={300}
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                  <SearchIcon className={styles.searchIcon} sx={{ width: '20px' }} />
                </Paper>
              </Box>
            </Box>
            {/* all vehicles */}
            <TabPanel value={tabValue} index={0}>
              <Box sx={{ flexGrow: 1 }}>
                {vehicles && (
                  <Typography
                    component="p"
                    className={styles.headerText}
                    sx={{
                      textTransform: 'uppercase',
                      fontWeight: '700',
                      color: theme.palette.info.light
                    }}
                  >
                    {collectionSize} total vehicles found
                  </Typography>
                )}
                <Box className={styles.headerTextContainer}>
                  {vehicles && vehicles.length > 0 && (
                    <Box sx={{ marginTop: '10px' }}>
                      <Checkbox
                        checked={isCheckAll}
                        onChange={(event, checked) => {
                          handleSelectAll(event);
                        }}
                      />
                      <ButtonGroup
                        variant="contained"
                        ref={anchorRef}
                        aria-label="split button"
                        sx={{
                          boxShadow: 'none !important',
                          marginBottom: '20px',
                          marginLeft: '10px'
                        }}
                      >
                        <Button
                          disabled={true}
                          sx={{
                            backgroundColor: `${theme.palette.primary.main} !important`,
                            color: `${theme.palette.common.white} !important`
                          }}
                        >
                          Move To
                        </Button>
                        <Button
                          size="small"
                          aria-controls={open ? 'split-button-menu' : undefined}
                          aria-expanded={open ? 'true' : undefined}
                          aria-label="Move To"
                          aria-haspopup="menu"
                          onClick={handleToggle}
                        >
                          <ArrowDropDownIcon />
                        </Button>
                      </ButtonGroup>
                      <Popper
                        style={{ zIndex: '99999' }}
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                      >
                        {({ TransitionProps, placement }) => (
                          <Grow
                            {...TransitionProps}
                            style={{
                              transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom'
                            }}
                          >
                            <Paper>
                              <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                  {options.map((option, index) => (
                                    <MenuItem
                                      key={option}
                                      selected={index === selectedIndex}
                                      onClick={(event) => handleMenuItemClick(event, index)}
                                    >
                                      {option}
                                    </MenuItem>
                                  ))}
                                </MenuList>
                              </ClickAwayListener>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                    </Box>
                  )}
                  {vehicles && (
                    <Box className={styles.controlsContainer}>
                      <Typography component="p" className={styles.headerText}>
                        Sort By:
                      </Typography>
                      <Select
                        label="Sort By"
                        value={order}
                        onChange={handleOrderByChange}
                        className={styles.inputText}
                        inputProps={{ 'aria-label': 'order vehicles' }}
                        input={<InputBase />}
                      >
                        <MenuItem value={'createdDate'}>Recently Created (default)</MenuItem>
                        <MenuItem value={'timeAsc'}>Newest Capture/Launch Date</MenuItem>
                        <MenuItem value={'timeDesc'}>Oldest Capture/Launch Date</MenuItem>
                        <MenuItem value={'vehiclePrice'}>Floor Price</MenuItem>
                        <MenuItem value={'vehicleName'}>Name</MenuItem>
                      </Select>
                      <TablePagination
                        className={styles.pagination}
                        count={collectionSize}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        rowsPerPageOptions={[5, 10, 25]}
                      />
                    </Box>
                  )}
                </Box>
                <Grid
                  rowSpacing={1}
                  sx={{
                    overflowX: 'auto',
                    height: vehicles && vehicles.length > 0 ? '800px' : '',
                    overflowY: 'auto',
                    willChange: 'transform'
                  }}
                  ref={vehiclesViewRef}
                >
                  {vehicles && (
                    <ViewportList
                      viewportRef={vehiclesViewRef}
                      initialPrerender={10}
                      items={sortPreAuctionVehiclesArray(
                        vehicles,
                        order,
                        searchValue,
                        page,
                        rowsPerPage,
                        'sell'
                      )}
                    >
                      {(vehicle: VehicleInfo) => (
                        <PreAuctionListItem
                          key={vehicle.id}
                          vehicle={vehicle}
                          curUser={curUser}
                          initialOpen={false}
                          sendVehicleToParent={SetDrawerVehicle}
                          handleChecked={handleCheck}
                          isChecked={isCheck.includes(vehicle.id)}
                          toggleFloorPriceModal={(vehicleId) => toggleFloorPriceModal(vehicleId)}
                        />
                      )}
                    </ViewportList>
                  )}
                  {vehicleEmptyState && (
                    <EmptyStateMessage
                      boldMessage="You have no vehicles in parked or launching soon."
                      message="Add vehicles to pre-auction by moving them from your vehicle capture."
                    />
                  )}
                </Grid>
              </Box>
            </TabPanel>
            {/* parked vehicles */}
            <TabPanel value={tabValue} index={1}>
              <Box sx={{ flexGrow: 1 }}>
                {parkedVehicles && (
                  <Typography
                    component="p"
                    className={styles.headerText}
                    sx={{
                      textTransform: 'uppercase',
                      fontWeight: '700',
                      color: theme.palette.info.light
                    }}
                  >
                    {parkedCollectionSize} total vehicles found
                  </Typography>
                )}
                <Box className={styles.headerTextContainer}>
                  {parkedVehicles && parkedVehicles.length > 0 && (
                    <Box sx={{ marginTop: '10px' }}>
                      <Checkbox
                        checked={isCheckAllParked}
                        onChange={(event, checked) => {
                          handleSelectAllParked(event);
                        }}
                      />
                      <ButtonGroup
                        variant="contained"
                        ref={anchorRef}
                        aria-label="split button"
                        sx={{
                          boxShadow: 'none !important',
                          marginBottom: '20px',
                          marginLeft: '10px'
                        }}
                      >
                        <Button
                          disabled={true}
                          sx={{
                            backgroundColor: `${theme.palette.primary.main} !important`,
                            color: `${theme.palette.common.white} !important`
                          }}
                        >
                          Move To
                        </Button>
                        <Button
                          size="small"
                          aria-controls={open ? 'split-button-menu' : undefined}
                          aria-expanded={open ? 'true' : undefined}
                          aria-label="Move To"
                          aria-haspopup="menu"
                          onClick={handleToggle}
                        >
                          <ArrowDropDownIcon />
                        </Button>
                      </ButtonGroup>
                      <Popper
                        style={{ zIndex: '99999' }}
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                      >
                        {({ TransitionProps, placement }) => (
                          <Grow
                            {...TransitionProps}
                            style={{
                              transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom'
                            }}
                          >
                            <Paper>
                              <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                  {options.map((option, index) => (
                                    <MenuItem
                                      key={option}
                                      selected={index === selectedIndex}
                                      onClick={(event) => handleMenuItemClick(event, index)}
                                    >
                                      {option}
                                    </MenuItem>
                                  ))}
                                </MenuList>
                              </ClickAwayListener>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                    </Box>
                  )}
                  {parkedVehicles && (
                    <Box className={styles.controlsContainer}>
                      <Typography component="p" className={styles.headerText}>
                        Sort By:
                      </Typography>
                      <Select
                        label="Sort By"
                        value={orderPark}
                        onChange={handleOrderByChangePark}
                        className={styles.inputText}
                        inputProps={{ 'aria-label': 'order vehicles' }}
                        input={<InputBase />}
                      >
                        <MenuItem value={'timeAsc'}>Newest Capture Date</MenuItem>
                        <MenuItem value={'timeDesc'}>Oldest Capture Date</MenuItem>
                        <MenuItem value={'vehicleName'}>Name</MenuItem>
                      </Select>
                      <TablePagination
                        className={styles.pagination}
                        count={parkedCollectionSize}
                        page={pagePark}
                        onPageChange={handleChangePagePark}
                        rowsPerPage={rowsPerPagePark}
                        onRowsPerPageChange={handleChangeRowsPerPagePark}
                        rowsPerPageOptions={[5, 10, 25]}
                      />
                    </Box>
                  )}
                </Box>
                <Grid
                  rowSpacing={1}
                  ref={parkedViewRef}
                  sx={{
                    overflowX: 'auto',
                    height: parkedVehicles && parkedVehicles.length > 0 ? '800px' : '',
                    overflowY: 'auto',
                    willChange: 'transform'
                  }}
                >
                  {parkedVehicles && (
                    <ViewportList
                      viewportRef={parkedViewRef}
                      initialPrerender={10}
                      items={sortPreAuctionVehiclesArray(
                        parkedVehicles,
                        orderPark,
                        searchValue,
                        pagePark,
                        rowsPerPagePark,
                        'sell'
                      )}
                    >
                      {(vehicle: VehicleInfo) => (
                        <PreAuctionListItem
                          key={vehicle.id}
                          vehicle={vehicle}
                          curUser={curUser}
                          initialOpen={false}
                          sendVehicleToParent={SetDrawerVehicle}
                          handleChecked={handleCheckParked}
                          isChecked={isCheckParked.includes(vehicle.id)}
                          toggleFloorPriceModal={(vehicleId) => toggleFloorPriceModal(vehicleId)}
                        />
                      )}
                    </ViewportList>
                  )}
                  {parkedEmptyState && (
                    <EmptyStateMessage boldMessage="You have no parked vehicles." message="" />
                  )}
                </Grid>
              </Box>
            </TabPanel>
            {/* launching soon vehicles */}
            <TabPanel value={tabValue} index={2}>
              <Box sx={{ flexGrow: 1 }}>
                {launchingVehicles && (
                  <Typography
                    component="p"
                    className={styles.headerText}
                    sx={{
                      textTransform: 'uppercase',
                      fontWeight: '700',
                      color: theme.palette.info.light
                    }}
                  >
                    {launchingCollectionSize} total vehicles found
                  </Typography>
                )}
                <Box className={styles.headerTextContainer}>
                  {launchingVehicles && launchingVehicles.length > 0 && (
                    <Box sx={{ marginTop: '10px' }}>
                      <Checkbox
                        checked={isCheckAllLaunching}
                        onChange={(event, checked) => {
                          handleSelectAllLaunching(event);
                        }}
                      />
                      <ButtonGroup
                        variant="contained"
                        ref={anchorRef}
                        aria-label="split button"
                        sx={{
                          boxShadow: 'none !important',
                          marginBottom: '20px',
                          marginLeft: '10px'
                        }}
                      >
                        <Button
                          disabled={true}
                          sx={{
                            backgroundColor: `${theme.palette.primary.main} !important`,
                            color: `${theme.palette.common.white} !important`
                          }}
                        >
                          Move To
                        </Button>
                        <Button
                          size="small"
                          aria-controls={open ? 'split-button-menu' : undefined}
                          aria-expanded={open ? 'true' : undefined}
                          aria-label="Move To"
                          aria-haspopup="menu"
                          onClick={handleToggle}
                        >
                          <ArrowDropDownIcon />
                        </Button>
                      </ButtonGroup>
                      <Popper
                        style={{ zIndex: '99999' }}
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                      >
                        {({ TransitionProps, placement }) => (
                          <Grow
                            {...TransitionProps}
                            style={{
                              transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom'
                            }}
                          >
                            <Paper>
                              <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                  {options.map((option, index) => (
                                    <MenuItem
                                      key={option}
                                      selected={index === selectedIndex}
                                      onClick={(event) => handleMenuItemClick(event, index)}
                                    >
                                      {option}
                                    </MenuItem>
                                  ))}
                                </MenuList>
                              </ClickAwayListener>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                    </Box>
                  )}
                  {launchingVehicles && (
                    <Box className={styles.controlsContainer}>
                      <Typography component="p" className={styles.headerText}>
                        Sort By:
                      </Typography>
                      <Select
                        label="Sort By"
                        value={orderLaunch}
                        onChange={handleOrderByChangeLaunch}
                        className={styles.inputText}
                        inputProps={{ 'aria-label': 'order vehicles' }}
                        input={<InputBase />}
                      >
                        <MenuItem value={'timeDesc'}>Newest Launch Date</MenuItem>
                        <MenuItem value={'timeAsc'}>Oldest Launch Date</MenuItem>
                        <MenuItem value={'vehicleName'}>Name</MenuItem>
                      </Select>
                      <TablePagination
                        className={styles.pagination}
                        count={launchingCollectionSize}
                        page={pageLaunch}
                        onPageChange={handleChangePageLaunch}
                        rowsPerPage={rowsPerPageLaunch}
                        onRowsPerPageChange={handleChangeRowsPerPageLaunch}
                        rowsPerPageOptions={[5, 10, 25]}
                      />
                    </Box>
                  )}
                </Box>
                <Grid
                  rowSpacing={1}
                  ref={launchingSoonViewRef}
                  sx={{
                    overflowX: 'auto',
                    height: launchingVehicles && launchingVehicles.length > 0 ? '800px' : '',
                    overflowY: 'auto',
                    willChange: 'transform'
                  }}
                >
                  {launchingVehicles && (
                    <ViewportList
                      initialPrerender={10}
                      viewportRef={launchingSoonViewRef}
                      items={sortPreAuctionVehiclesArray(
                        launchingVehicles,
                        orderLaunch,
                        searchValue,
                        pageLaunch,
                        rowsPerPageLaunch,
                        'sell'
                      )}
                    >
                      {(vehicle: VehicleInfo) => (
                        <PreAuctionListItem
                          key={vehicle.id}
                          vehicle={vehicle}
                          curUser={curUser}
                          initialOpen={false}
                          sendVehicleToParent={SetDrawerVehicle}
                          handleChecked={handleCheckLaunching}
                          isChecked={isCheckLaunching.includes(vehicle.id)}
                          toggleFloorPriceModal={(vehicleId) => toggleFloorPriceModal(vehicleId)}
                        />
                      )}
                    </ViewportList>
                  )}
                  {launchingSoonEmptyState && (
                    <EmptyStateMessage
                      boldMessage="You have no launching soon vehicles."
                      message=""
                    />
                  )}
                </Grid>
              </Box>
            </TabPanel>
          </Container>
          <Dialog open={isOpenModal} onClose={() => setIsOpenModal(false)}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '20px',
                position: 'relative',
                borderBottom: `1px solid ${theme.palette.divider}`
              }}
            >
              {/* prettier-ignore */}
              <Typography component="p" variant="h5">
                {isActiveModal
                  ? 'Active Auction'
                  : (!paymentMethods || paymentMethods.length < 1) && checkedVehicles.length > 0
                  ? 'Payment Method Alert'
                  : checkedVehicles.length < 1
                  ? 'Select A Vehicle'
                  : 'Launching Soon'}
              </Typography>
              <CloseIcon
                onClick={() => setIsOpenModal(false)}
                sx={{
                  position: 'absolute',
                  right: '10px',
                  cursor: 'pointer'
                }}
              />
            </Box>
            {/* prettier-ignore */}
            <Box>
              {!isActiveModal && (paymentMethods && paymentMethods.length > 0) && checkedVehicles.length > 0 && (
                <LaunchSoonContent
                  vehicleIds={checkedVehicles}
                  // handleClose={() => setIsOpenModal(false)}
                  handleClose={() => handleCloseCalendarModal()}
                />
              )}
              {/* prettier-ignore */}
              {isActiveModal && (paymentMethods && paymentMethods.length > 0) && checkedVehicles.length > 0 && (
                <ActiveContent vehicleIds={checkedVehicles} handleClose={() => handleCloseCalendarModal()} />
              )}
              {/* prettier-ignore */}
              {(!paymentMethods || paymentMethods.length < 1) && checkedVehicles.length > 0 && (
                <Box sx={{ padding: '20px' }}>
                  {/* prettier-ignore */}
                  <Typography>You must create a payment method in order to do this. Payment methods can be created from the billing section in account management.</Typography>
                </Box>
              )}
              {checkedVehicles.length < 1 && (
                <Box sx={{ padding: '20px' }}>
                  {/* prettier-ignore */}
                  <Typography>You must check at least one vehicle to do this.</Typography>
                </Box>
              )}
            </Box>
          </Dialog>
          <Dialog open={floorPriceModalOpen} onClose={() => setFloorPriceModalOpen(false)}>
            <Box
              sx={{
                minWidth: '300px',
                display: 'flex',
                alignItems: 'space-between',
                justifyContent: 'center'
              }}
            >
              <DialogTitle>Update Floor Price</DialogTitle>
              <CloseIcon
                onClick={() => setFloorPriceModalOpen(false)}
                sx={{
                  cursor: 'pointer',
                  position: 'absolute',
                  right: '15px',
                  top: '24px'
                }}
              />
            </Box>
            <DialogContent sx={{ textAlign: 'center' }}>Enter a new floor price.</DialogContent>
            <NumericFormat
              sx={{ width: '200px', margin: 'auto', paddingBottom: '20px' }}
              prefix="$"
              decimalScale={0}
              allowNegative={false}
              thousandSeparator={true}
              customInput={TextField}
              label="Floor Price (must end with 00)"
              // fullWidth
              value={bidValue}
              onValueChange={(values) => {
                if (values.floatValue?.toString().endsWith('00')) {
                  setFloorPriceDisabled(false);
                } else {
                  setFloorPriceDisabled(true);
                }
                if (values.floatValue) {
                  setBidValue(values.floatValue);
                }
              }}
            />
            <DialogActions
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end'
              }}
            >
              <Button
                type="button"
                variant="contained"
                onClick={submitFloorPrice}
                disabled={floorPriceDisabled}
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
          {vehicles && vehicles.length > 0 && (
            <Tour
              steps={steps}
              isOpen={tourOpen}
              onRequestClose={() => handleCloseTour()}
              className={styles.tourContainer}
            />
          )}
        </Page>
      ) : (
        <>
          {userDeleted && (
            <Alert severity="error">
              <AlertTitle>Permission Denied</AlertTitle>
              Your account has been disabled.
            </Alert>
          )}
          {userSuspended && (
            <>
              <Alert severity="error">
                <AlertTitle>Thank you for joining DealerGavel</AlertTitle>
                Your account information is being reviewed by DealerGavel team, and will be
                &nbsp;authorized shortly. For now, you can return to the dashboard and await
                &nbsp;authorization.
              </Alert>
              <Box sx={{ mt: '20px' }}>
                <Button onClick={handleNavigate} variant="contained">
                  Back to Dashboard
                </Button>
              </Box>
            </>
          )}
          {userDisabled && (
            <>
              <Alert severity="error">
                <AlertTitle>Permission Denied</AlertTitle>
                Your account has been disabled.
              </Alert>
              <Box sx={{ mt: '20px' }}>
                <Button onClick={handleNavigate} variant="contained">
                  Back to Dashboard
                </Button>
              </Box>
            </>
          )}
          {buyerOnly && (
            <>
              <Alert severity="error">
                <AlertTitle>Permission Denied</AlertTitle>
                You do not have permissions for this page.
              </Alert>
              <Box sx={{ mt: '20px' }}>
                <Button onClick={handleNavigate} variant="contained">
                  Back to Dashboard
                </Button>
              </Box>
            </>
          )}
        </>
      )}
      {currentVehicle && (
        <AuctionDrawer
          isOpenSidebar={openVDPDrawer}
          onCloseSidebar={handleCloseVDPDrawer}
          tabIndex={0}
          vehicle={currentVehicle}
        />
      )}
      {currentVehicle && (
        <SharedDrawer
          isOpenSidebar={openHistoryDrawer}
          onCloseSidebar={handleCloseHistoryDrawer}
          drawerContent={
            <CaptureHistoryDrawerContent
              vehicle={currentVehicle}
              onClose={handleCloseHistoryDrawer}
            />
          }
        />
      )}
      {currentVehicle && (
        <DialogAnimate open={openEditDrawer} onClose={handleCloseEditDrawer}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '20px',
              position: 'relative',
              borderBottom: `1px solid ${theme.palette.divider}`
            }}
          >
            <Typography component="p" variant="h5">
              Edit
            </Typography>
            <CloseIcon
              onClick={handleCloseEditDrawer}
              sx={{
                position: 'absolute',
                right: '10px',
                cursor: 'pointer'
              }}
            />
          </Box>
          <Box sx={{ padding: '40px' }}>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: '10px'
              }}
            >
              <Typography variant="h4">Edit a vehicle</Typography>
            </Box>

            <Typography sx={{ color: theme.palette.text.secondary }}>
              Editing a vehicle will move the vehicle back to a{' '}
              <span style={{ fontWeight: 'bold' }}>Draft Status.</span> When you are finished
              editing, you can move it back to Launching Soon or Active.
            </Typography>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              padding: '0px 40px 20px 40px'
            }}
          >
            <Button
              size="large"
              variant="contained"
              sx={{
                backgroundColor: theme.palette.divider,
                color: theme.palette.info.light,
                '&:hover': {
                  color: theme.palette.common.white
                }
              }}
              onClick={handleCloseEditDrawer}
            >
              Cancel
            </Button>
            <LoadingButton
              size="large"
              type="submit"
              variant="contained"
              onClick={async () => {
                handleCloseEditDrawer();
                const docRef = doc(firestore, `vehicles/${currentVehicle.id}`);
                await updateDoc(docRef, {
                  'auction.auctionStatus': 'draft'
                });
                navigate(`${PATH_SELLER.captureWorkflow}/${currentVehicle.id}`, {
                  state: { id: currentVehicle.id }
                });
              }}
            >
              Edit
            </LoadingButton>
          </Box>
        </DialogAnimate>
      )}
      <Dialog open={adminOmvicDialogOpen} onClose={handleCloseAdminOmvicDialog}>
        <Box sx={{ textAlign: 'center' }}>
          <DialogTitle>Permission Error</DialogTitle>
          <CloseIcon
            onClick={handleCloseAdminOmvicDialog}
            sx={{
              cursor: 'pointer',
              position: 'absolute',
              right: '15px',
              top: '24px'
            }}
          />
        </Box>
        <DialogContent>
          {/* prettier-ignore */}
          <Typography>
            Your account is missing an Individual License/Registration Number (ex. OMVIC/MVDA/SAAQ etc).
            Go to Manage Account and select Account Information to update this.
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'flex-end',
              mt: '15px'
            }}
          >
            <Button size="large" variant="contained" onClick={handleCloseAdminOmvicDialog}>
              Ok, Got it!
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
