import { useState } from 'react';
import { Typography, Box, useTheme, List, ListItem, Chip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { VehicleInfo } from 'types/vehicleInfo';
import { CondensedAccordion } from './CondensedAccordion';

type Props = {
  vehicle: VehicleInfo;
};

export default function EquipmentTab({ vehicle }: Props) {
  const theme = useTheme();
  const [expandedOptions, setExpandedOptions] = useState<boolean>(true);
  const [expandedAfterMarket, setExpandedAfterMarket] = useState<boolean>(true);
  const [expandedInfo, setExpandedInfo] = useState<boolean>(true);
  const [expandedFeatures, setExpandedFeatures] = useState<boolean>(true);

  const handleChangeOptions = () => {
    setExpandedOptions(!expandedOptions);
  };

  const handleChangeAfterMarket = () => {
    setExpandedAfterMarket(!expandedAfterMarket);
  };

  const handleChangeInfo = () => {
    setExpandedInfo(!expandedInfo);
  };

  const handleChangeFeatures = () => {
    setExpandedFeatures(!expandedFeatures);
  };

  const useStyles = makeStyles({
    contentListItemText: {
      color: theme.palette.primary.main,
      fontSize: '20px',
      marginRight: '5px',
      lineHeight: '0.5'
    },
    contentChip: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      fontWeight: '700',
      margin: '5px'
    },
    tableRow: {
      '&:nth-child(odd)': {
        backgroundColor: theme.palette.background.paper
      },
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      padding: '1px 10px'
    }
  });

  const styles = useStyles();

  return (
    <>
      <Typography
        component="p"
        sx={{
          fontSize: '17px',
          fontWeight: '700',
          color: theme.palette.text.secondary,
          margin: '5px 0px'
        }}
      >
        Equipment
      </Typography>
      <CondensedAccordion
        title={'Options'}
        expanded={expandedOptions}
        handleExpanded={handleChangeOptions}
      >
        {vehicle &&
          vehicle.equipment.options &&
          vehicle.equipment.options.length > 0 &&
          vehicle.equipment.options.map((item) => {
            return <Chip key={item} className={styles.contentChip} label={item}></Chip>;
          })}
        {vehicle && vehicle.equipment.thirdRow && (
          <Chip key="thirdRow" className={styles.contentChip} label="Third Row"></Chip>
        )}
      </CondensedAccordion>
      <CondensedAccordion
        title={'After Market Options'}
        expanded={expandedAfterMarket}
        handleExpanded={handleChangeAfterMarket}
      >
        {vehicle &&
          vehicle.equipment.afterMarketOptions &&
          vehicle.equipment.afterMarketOptions.length > 0 &&
          vehicle.equipment.afterMarketOptions.map((item) => {
            return <Chip key={item} className={styles.contentChip} label={item}></Chip>;
          })}
      </CondensedAccordion>
      <CondensedAccordion
        title={'Tire Info And Size'}
        expanded={expandedInfo}
        handleExpanded={handleChangeInfo}
      >
        <Box className={styles.tableRow}>
          <Typography sx={{ fontSize: '13px' }}>TPMS:</Typography>
          {vehicle.equipment.tpms && (
            <Typography sx={{ fontSize: '13px', fontWeight: '700' }}>Yes</Typography>
          )}
          {!vehicle.equipment.tpms && (
            <Typography sx={{ fontSize: '13px', fontWeight: '700' }}>No</Typography>
          )}
        </Box>
        <Box className={styles.tableRow}>
          <Typography sx={{ fontSize: '13px' }}>Aftermarket Wheels:</Typography>
          {vehicle.equipment.aftermarketWheels && (
            <Typography sx={{ fontSize: '13px', fontWeight: '700' }}>Yes</Typography>
          )}
          {!vehicle.equipment.aftermarketWheels && (
            <Typography sx={{ fontSize: '13px', fontWeight: '700' }}>No</Typography>
          )}
        </Box>
        <Box className={styles.tableRow}>
          <Typography sx={{ fontSize: '13px' }}>2nd Set of Tires:</Typography>
          {vehicle.equipment.secondSetOfTires && (
            <Typography sx={{ fontSize: '13px', fontWeight: '700' }}>Yes</Typography>
          )}
          {!vehicle.equipment.secondSetOfTires && (
            <Typography sx={{ fontSize: '13px', fontWeight: '700' }}>No</Typography>
          )}
        </Box>
        <Box className={styles.tableRow}>
          <Typography sx={{ fontSize: '13px' }}>Winter Tires:</Typography>
          {vehicle.equipment.winterTires && (
            <Typography sx={{ fontSize: '13px', fontWeight: '700' }}>Yes</Typography>
          )}
          {!vehicle.equipment.winterTires && (
            <Typography sx={{ fontSize: '13px', fontWeight: '700' }}>No</Typography>
          )}
        </Box>
        <Box className={styles.tableRow}>
          <Typography sx={{ fontSize: '13px' }}>Size:</Typography>
          <Typography sx={{ fontSize: '13px', fontWeight: '700' }}>
            {vehicle.equipment.sizeTires}
          </Typography>
        </Box>
      </CondensedAccordion>
      <Typography
        component="p"
        sx={{
          fontSize: '17px',
          fontWeight: '700',
          color: theme.palette.info.light,
          margin: '5px 0px'
        }}
      >
        Factory Information
      </Typography>
      <CondensedAccordion
        title={'Features'}
        expanded={expandedFeatures}
        handleExpanded={handleChangeFeatures}
      >
        <List>
          {vehicle &&
            vehicle.equipment.factoryInfo &&
            vehicle.equipment.factoryInfo.length > 0 &&
            vehicle.equipment.factoryInfo.map((data: any, index: number) => {
              return (
                <ListItem key={index}>
                  <Typography className={styles.contentListItemText}>{'\u2022'}</Typography>
                  <Typography component="p" sx={{ fontSize: '13px' }}>
                    {data}
                  </Typography>
                </ListItem>
              );
            })}
        </List>
      </CondensedAccordion>
    </>
  );
}
