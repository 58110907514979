import Stack from '@mui/material/Stack';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useFirestore } from 'reactfire';
import { doc, updateDoc } from 'firebase/firestore';
import { VehicleInfo } from 'types/vehicleInfo';
import useAuth from 'hooks/useAuth';
import { batchUpdateAddFront } from 'utils/batchSet';
import { getMilliSeconds, getSeconds, subtractSeconds } from 'utils/formatAuctionEndTime';
import { StaticTimePicker } from '@mui/x-date-pickers/StaticTimePicker';
interface Props {
  vehicle?: VehicleInfo;
  vehicleId?: string;
  vehicleIds?: string[];
  handleClose: any;
  isNoSale?: boolean;
}

export default function ActiveContent({
  vehicleId,
  handleClose,
  vehicleIds,
  isNoSale,
  vehicle
}: Props) {
  const theme = useTheme();
  const firestore = useFirestore();
  const { currentUser, user } = useAuth();
  const [isValid, setIsValid] = useState<boolean>(true);
  const [endDateValue, setEndDateValue] = useState<Date | null>(new Date());
  const [endTimeValue, setEndTimeValue] = useState<Date | null>(
    new Date(new Date().getTime() + 60 * 60 * 1000)
  );

  const handleDateChange = (newValue: Date | null) => {
    setEndDateValue(newValue);
  };

  const handleTimeChange = (newValue: Date | null) => {
    setEndTimeValue(newValue);
  };

  const sameDay = (date: Date, nextDate: Date) => {
    /* prettier-ignore */
    return date.getDate() === nextDate.getDate() &&
      date.getMonth() === nextDate.getMonth() &&
      date.getFullYear() === nextDate.getFullYear();
  };

  useEffect(() => {
    // prettier-ignore
    if (endDateValue && sameDay(endDateValue, new Date()) && endTimeValue && endTimeValue.getTime() >= new Date(new Date().getTime() + 18 * 60 * 1000).getTime()) {
      setIsValid(true);
    } else if (endDateValue && !sameDay(endDateValue, new Date())) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [endTimeValue, endDateValue]);

  const moveToActive = async () => {
    const startDateValue = new Date();
    const startTimeValue = new Date();
    if (vehicleId) {
      const docRef = doc(firestore, `vehicles/${vehicleId}`);
      if (isNoSale) {
        await updateDoc(docRef, {
          billOfSalelink: null,
          buyerVehicleHistory: [],
          sellerMarkAsSold: false,
          bidExtension: false,
          bidExtensionTime: null,
          'auction.auctionStatus': 'active',
          'auction.launchDate': startDateValue,
          'auction.launchTime': subtractSeconds(
            startTimeValue as Date,
            getSeconds(startTimeValue as Date),
            getMilliSeconds(startTimeValue as Date)
          ),
          'auction.endDate': endDateValue,
          'auction.endTime': subtractSeconds(
            endTimeValue as Date,
            getSeconds(endTimeValue as Date),
            getMilliSeconds(endTimeValue as Date)
          ),
          'auction.timesRun': 0,
          'auction.isAutobid': [],
          'auction.isWatchlist': [],
          'auction.isFavourite': [],
          'auction.isExpanded': [],
          'auction.autobids': [],
          'auction.postAuctionStatus': null,
          'auction.negotiationStatusSeller': 'action required',
          'auction.negotiationStatusBuyer': 'awaiting response',
          'auction.buyerName': null,
          'auction.currentPrice': null,
          'auction.negotiationPrice': null,
          'auction.bidLeaderId': null,
          'auction.runTime': null,
          buyerDealership: null,
          sellerDealership: null,
          buyerUser: null,
          'auction.negotiationHistory': [],
          'auction.previousBids': [],
          'auction.previousBidders': [],
          'auction.dealerRep': {
            name: currentUser.firstName + ' ' + currentUser.lastName,
            id: user?.uid,
            email: currentUser.email,
            phone: currentUser.phone,
            signatureUrl: currentUser.signatureUrl,
            registration: currentUser.registration
          },
          'auction.sellerName': currentUser.firstName + ' ' + currentUser.lastName
          // 'auction.vehicleLaunchUser': {
          //   name: currentUser.firstName + ' ' + currentUser.lastName,
          //   email: currentUser.email,
          //   phone: currentUser.phone
          // }
        });
      } else {
        await updateDoc(docRef, {
          'auction.auctionStatus': 'active',
          'auction.launchDate': startDateValue,
          'auction.launchTime': subtractSeconds(
            startTimeValue as Date,
            getSeconds(startTimeValue as Date),
            getMilliSeconds(startTimeValue as Date)
          ),
          'auction.endDate': endDateValue,
          'auction.endTime': subtractSeconds(
            endTimeValue as Date,
            getSeconds(endTimeValue as Date),
            getMilliSeconds(endTimeValue as Date)
          ),
          'auction.dealerRep': {
            name: currentUser.firstName + ' ' + currentUser.lastName,
            id: user?.uid,
            email: currentUser.email,
            phone: currentUser.phone,
            signatureUrl: currentUser.signatureUrl,
            registration: currentUser.registration
          },
          'auction.sellerName': currentUser.firstName + ' ' + currentUser.lastName
          // 'auction.vehicleLaunchUser': {
          //   name: currentUser.firstName + ' ' + currentUser.lastName,
          //   email: currentUser.email,
          //   phone: currentUser.phone
          // }
        });
      }
    }
    if (vehicleIds) {
      if (isNoSale) {
        const vehiclesToUpdate = vehicleIds.map((id) => ({
          billOfSalelink: null,
          buyerVehicleHistory: [],
          sellerMarkAsSold: false,
          bidExtension: false,
          bidExtensionTime: null,
          buyerDealership: null,
          sellerDealership: null,
          buyerUser: null,
          auction: {
            runTime: null,
            auctionStatus: 'active',
            launchDate: startDateValue,
            launchTime: subtractSeconds(
              startTimeValue as Date,
              getSeconds(startTimeValue as Date),
              getMilliSeconds(startTimeValue as Date)
            ),
            endDate: endDateValue,
            endTime: subtractSeconds(
              endTimeValue as Date,
              getSeconds(endTimeValue as Date),
              getMilliSeconds(endTimeValue as Date)
            ),
            timesRun: 0,
            isAutobid: [],
            isWatchlist: [],
            isFavourite: [],
            isExpanded: [],
            autobids: [],
            postAuctionStatus: null,
            negotiationStatusSeller: 'action required',
            negotiationStatusBuyer: 'awaiting response',
            buyerName: null,
            currentPrice: null,
            negotiationPrice: null,
            bidLeaderId: null,
            negotiationHistory: [],
            previousBids: [],
            previousBidders: [],
            dealerRep: {
              name: currentUser.firstName + ' ' + currentUser.lastName,
              id: user?.uid,
              email: currentUser.email,
              phone: currentUser.phone,
              signatureUrl: currentUser.signatureUrl,
              registration: currentUser.registration
            }
            // vehicleLaunchUser: {
            //   name: currentUser.firstName + ' ' + currentUser.lastName,
            //   email: currentUser.email,
            //   phone: currentUser.phone
            // }
          }
        }));
        await batchUpdateAddFront('vehicles', vehicleIds, vehiclesToUpdate);
      } else {
        const vehiclesToUpdate = vehicleIds.map((id) => ({
          auction: {
            auctionStatus: 'active',
            launchDate: startDateValue,
            launchTime: subtractSeconds(
              startTimeValue as Date,
              getSeconds(startTimeValue as Date),
              getMilliSeconds(startTimeValue as Date)
            ),
            endDate: endDateValue,
            endTime: subtractSeconds(
              endTimeValue as Date,
              getSeconds(endTimeValue as Date),
              getMilliSeconds(endTimeValue as Date)
            ),
            dealerRep: {
              name: currentUser.firstName + ' ' + currentUser.lastName,
              id: user?.uid,
              email: currentUser.email,
              phone: currentUser.phone,
              signatureUrl: currentUser.signatureUrl,
              registration: currentUser.registration
            }
            // vehicleLaunchUser: {
            //   name: currentUser.firstName + ' ' + currentUser.lastName,
            //   email: currentUser.email,
            //   phone: currentUser.phone
            // }
          }
        }));
        await batchUpdateAddFront('vehicles', vehicleIds, vehiclesToUpdate);
      }
    }
    handleClose();
  };

  const useStyles = makeStyles({
    cancelButton: {
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.common.black,
      '&:hover': {
        backgroundColor: theme.palette.grey[300]
      }
    }
  });

  const styles = useStyles();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack spacing={3} sx={{ padding: '30px' }}>
        <Typography sx={{ fontWeight: 'bold' }}>End Date</Typography>
        <DesktopDatePicker
          label="End Date"
          format="MM/dd/yyyy"
          value={endDateValue}
          disablePast
          onChange={handleDateChange}
          // renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
          //   <TextField {...params} />
          // )}
          slotProps={{ textField: { variant: 'outlined' } }}
        />
        <TimePicker
          label="End Time"
          // disableOpenPicker={true}
          value={endTimeValue}
          onChange={handleTimeChange}
          minTime={
            endDateValue && sameDay(endDateValue, new Date())
              ? new Date(new Date().getTime() + 20 * 60 * 1000)
              : undefined
          }
          slotProps={{ textField: { variant: 'outlined' } }}
          // renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
          //   <TextField {...params} />
          // )}
        />
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          <Button
            // size="large"
            variant="contained"
            className={styles.cancelButton}
            onClick={() => handleClose()}
          >
            Cancel
          </Button>
          <LoadingButton
            // size="large"
            disabled={!isValid}
            type="submit"
            variant="contained"
            sx={{ marginLeft: '10px' }}
            onClick={() => moveToActive()}
          >
            Move: Active
          </LoadingButton>
        </Box>
      </Stack>
    </LocalizationProvider>
  );
}
