import { Grid, AppBar, Toolbar, IconButton } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SpokeIcon from '@mui/icons-material/Spoke';
import clsx from 'clsx';
import { DrawerDealerInformation, DrawerChecklist, DrawerNetwork } from '../auction/index';
import FeedIcon from '@mui/icons-material/Feed';
import DrawerNotes from './DrawerNotes';
import { Dealership } from 'types/dealership';
import ReviewsIcon from '@mui/icons-material/Reviews';
import DrawerDealerRatings from './DrawerDealerRatings';

type DrawerProps = {
  logo?: string | string[];
  onClose?: any;
  dealership: Dealership;
  isFindDealer?: boolean;
};

const DrawerContent: FC<DrawerProps> = (props: DrawerProps) => {
  const theme = useTheme();
  const [component, setComponent] = useState('information');

  const useStyles = makeStyles({
    toolbar: {
      background: theme.palette.background.paper,
      minHeight: '45px',
      paddingLeft: 0
    },
    networkIcon: {
      width: '20px',
      backgroundColor: theme.palette.info.light,
      borderRadius: '50%',
      paddingRight: '2px',
      paddingLeft: '2px',
      height: '20px',
      color: 'white'
    },
    iconButton: {
      width: '45px',
      height: '45px',
      borderRadius: 0,
      '&:hover': {
        borderRadius: 0,
        opacity: 1,
        backgroundColor: theme.palette.secondary.main,
        '@global': {
          '.MuiSvgIcon-root': {
            fill: theme.palette.primary.contrastText
          },
          '.network-icon': {
            backgroundColor: theme.palette.primary.contrastText,
            '&.MuiSvgIcon-root': {
              fill: theme.palette.secondary.main
            }
          }
        }
      }
    },
    subtitle: {
      fontWeight: 400,
      color: theme.palette.text.secondary
    },
    tableCell: {
      padding: '10px !important',
      color: theme.palette.info.light,
      fontSize: '14px'
    },
    tableCellSmall: {
      fontSize: '12px',
      fontWeight: 700,
      textAlign: 'right'
    },
    checklist: {
      padding: '14px',
      border: `1px solid ${theme.palette.grey[100]}`,
      borderRadius: '5px',
      marginBottom: '10px',
      cursor: 'pointer'
    }
  });

  const styles = useStyles();
  const { logo, onClose, dealership, isFindDealer } = props;

  useEffect(() => {
    setComponent('information');
  }, [dealership]);

  return (
    <Grid container sx={{ paddingRight: '5px' }}>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        sx={{ m: '12px' }}
      >
        <CloseIcon onClick={onClose} sx={{ cursor: 'pointer' }} />
      </Grid>
      {logo && (
        <Grid
          sx={{
            backgroundImage: `url(${logo})`,
            width: '100%',
            height: '227px',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat'
          }}
        ></Grid>
      )}
      <AppBar position="static">
        <Toolbar className={styles.toolbar}>
          <IconButton className={styles.iconButton} onClick={() => setComponent('information')}>
            <AssignmentIndIcon />
          </IconButton>
          <IconButton className={styles.iconButton} onClick={() => setComponent('checklist')}>
            <CheckCircleIcon />
          </IconButton>
          {!isFindDealer && (
            <IconButton className={styles.iconButton} onClick={() => setComponent('network')}>
              <SpokeIcon className={clsx(styles.networkIcon, 'network-icon')} />
            </IconButton>
          )}
          <IconButton className={styles.iconButton} onClick={() => setComponent('ratings')}>
            <ReviewsIcon />
          </IconButton>
          <IconButton className={styles.iconButton} onClick={() => setComponent('notes')}>
            <FeedIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {component === 'information' ? (
        <DrawerDealerInformation dealership={dealership} />
      ) : component === 'checklist' ? (
        <DrawerChecklist dealership={dealership} />
      ) : component === 'network' ? (
        <DrawerNetwork dealership={dealership} />
      ) : component === 'ratings' ? (
        <DrawerDealerRatings dealership={dealership} />
      ) : (
        <DrawerNotes dealership={dealership} />
      )}
    </Grid>
  );
};

export default DrawerContent;
