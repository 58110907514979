import {
  Container,
  Typography,
  Link,
  Alert,
  Stack,
  TextField,
  Box,
  Divider,
  IconButton,
  InputAdornment
} from '@mui/material';
import useSettings from '../../hooks/useSettings';
import Page from '../../components/Page';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import { PATH_PROFILE } from '../../routes/paths';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useFormik, FormikProvider, Form } from 'formik';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import * as Yup from 'yup';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import * as firebase from 'firebase/auth';
// import useAuth from 'hooks/useAuth';
import { useSnackbar } from 'notistack';

type InitialValues = {
  newPassword: string;
  confirmPassword: string;
  afterSubmit?: string;
};

export default function SecurityPage() {
  const { themeStretch } = useSettings();
  const theme = useTheme();
  // const { user } = useAuth();
  const isMountedRef = useIsMountedRef();
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const passwordSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required('New password is required')
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters')
      .matches(/[a-z]+/, 'Must contain one lowercase character')
      .matches(/[A-Z]+/, 'Must contain one uppercase character')
      .matches(/[@$!%*#?&]+/, 'Must contain one special character')
      .matches(/\d+/, 'Must contain one number'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
      .required('Confirm Password is Required')
  });

  const formik = useFormik<InitialValues>({
    initialValues: {
      newPassword: '',
      confirmPassword: ''
    },
    validationSchema: passwordSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        const currentUser = firebase.getAuth().currentUser;
        if (currentUser) {
          await firebase.updatePassword(currentUser, values.newPassword);
          setSubmitting(false);
          enqueueSnackbar('Password Updated', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right'
            }
          });
        }
      } catch (error) {
        if (isMountedRef.current) {
          if (error instanceof Error) {
            if (error.message === 'Firebase: Error (auth/requires-recent-login).') {
              const customErrorMessage =
                'It has been too long since your last login, please log out and back in again in order to change your password.';
              setErrors({ afterSubmit: customErrorMessage });
            } else {
              setErrors({ afterSubmit: error.message });
            }
            setSubmitting(false);
            enqueueSnackbar('Error', {
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right'
              }
            });
          }
        }
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  const useStyles = makeStyles({
    backLink: {
      display: 'flex',
      // marginBottom: '60px',
      marginBottom: '10px',
      width: '165px'
    },
    deactivateButton: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.text.secondary,
      border: `1px solid ${theme.palette.text.secondary}`,
      '&:hover': {
        backgroundColor: theme.palette.grey[100]
      }
    }
  });

  const styles = useStyles();

  return (
    <Page title="Security Information | DealerGavel">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Link className={styles.backLink} to={PATH_PROFILE.root} component={RouterLink}>
          <ArrowBackIosIcon fontSize="small" />
          Back to all settings
        </Link>
        <Typography variant="h4" component="h1" sx={{ fontSize: '1rem !important' }}>
          Security
        </Typography>
        <Typography paragraph sx={{ marginBottom: '20px' }}>
          Update security credentials
        </Typography>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Typography
              variant="h6"
              component="h1"
              sx={{
                marginBottom: '15px',
                fontSize: '1rem !important'
              }}
            >
              Change Password
            </Typography>
            <Stack spacing={2}>
              {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}
              <TextField
                sx={{ maxWidth: '550px' }}
                label="New Password"
                type={showNewPassword ? 'text' : 'password'}
                {...getFieldProps('newPassword')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" onClick={() => setShowNewPassword((prev) => !prev)}>
                        {showNewPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                error={Boolean(touched.newPassword && errors.newPassword)}
                helperText={touched.newPassword && errors.newPassword}
              />
              <TextField
                sx={{ maxWidth: '550px' }}
                label="Confirm Password"
                type={showConfirmPassword ? 'text' : 'password'}
                {...getFieldProps('confirmPassword')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={() => setShowConfirmPassword((prev) => !prev)}
                      >
                        {showConfirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                helperText={touched.confirmPassword && errors.confirmPassword}
              />
              <Divider
                sx={{
                  width: '100%',
                  borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
                }}
              />
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  alignItems: 'center'
                }}
              >
                <LoadingButton
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  Save Changes
                </LoadingButton>
              </Box>
            </Stack>
          </Form>
        </FormikProvider>
      </Container>
    </Page>
  );
}
