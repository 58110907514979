import {
  Grid,
  BoxProps,
  IconButton,
  Popover,
  MenuItem,
  Chip,
  Dialog,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  TextField,
  Button,
  Rating,
  styled
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import ListItemDetails from '../../shared/ListItemDetails';
import ListItemBidPrice from '../../shared/ListItemPriceBid';
import ListItemPersonDate from '../../shared/ListItemPersonDate';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useEffect, useState } from 'react';
import { fDateTimeSuffix } from 'components/shared/dateTimeFormat';
import { Link as RouterLink } from 'react-router-dom';
import { VehicleInfo } from 'types/vehicleInfo';
import { LoadingButton } from '@mui/lab';
import { Timestamp, addDoc, arrayUnion, collection, doc, getDoc } from 'firebase/firestore';
import { useFirestore } from 'reactfire';
import useAuth from 'hooks/useAuth';
import { v4 as uuid } from 'uuid';
import { Dealership } from 'types/dealership';
import { useFirestoreByPath } from 'hooks/useFirestoreByPath';
import CloseIcon from '@mui/icons-material/Close';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DirectionsCarFilledOutlinedIcon from '@mui/icons-material/DirectionsCarFilledOutlined';

interface Props extends BoxProps {
  vehicle: VehicleInfo;
  stripeLoading: boolean;
  initialOpen: boolean;
  sendVehicleToParent: (index: number, vehicle: VehicleInfo) => void;
  payForVehicle: (vehicleId: string, checkoutSession: string) => void;
}

const SIMPLE_OPTIONS = [
  { label: 'Vehicle History', linkTo: '#' },
  { label: 'Bidding History', linkTo: '#' },
  { label: 'View VDP', linkTo: '#' },
  { label: 'Bill of Sale', linkTo: '#' }
];

export default function DeliveredListItemBuy({
  vehicle,
  initialOpen,
  stripeLoading,
  sendVehicleToParent,
  payForVehicle
}: Props) {
  const theme = useTheme();
  const firestore = useFirestore();
  const { currentUser, user } = useAuth();
  const [anchorPopup, setAnchorPopup] = useState<HTMLButtonElement | null>(null);
  const [popupOpened, setPopupOpened] = useState(false);
  const [, setAnchorEl] = useState(false);
  const [, setSelectedIndex] = useState(0);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [conditionStars, setConditionStars] = useState<number>(0);
  const [ownershipStars, setOwnershipStars] = useState<number>(0);
  const [salesStars, setSalesStars] = useState<number>(0);
  const [hasRatedCondition, setHasRatedCondition] = useState<boolean>(false);
  const [hasRatedOwnership, setHasRatedOwnership] = useState<boolean>(false);
  const [hasRatedSales, setHasRatedSales] = useState<boolean>(false);
  const [ratingComments, setRatingComments] = useState<string>();
  const [openRatingModal, setOpenRatingModal] = useState(false);
  const [isChecked, setIsChecked] = useState<boolean>(true);
  const [sellerDealership, setSellerDealership] = useState<Dealership>();
  const [buyerDealership, setBuyerDealership] = useState<Dealership>();

  const { update: updateDealership } = useFirestoreByPath<Dealership>('dealerships');
  const { update: updateVehicle } = useFirestoreByPath<VehicleInfo>('vehicles');

  const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
      color: theme.palette.success.main
    },
    '& .MuiRating-iconHover': {
      color: theme.palette.success.main
    }
  });

  const currencyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });

  let vehiclePhoto;
  if (vehicle.photos) {
    const photos = vehicle.photos.filter(
      (v: any) => v.url && v.location && v.location.toLowerCase().includes('3/4 shot')
    );
    if (photos[0]) {
      vehiclePhoto = photos[0];
    }
  }

  const open = Boolean(anchorPopup);
  const id = open ? 'simple-popover' : undefined;

  const useStyles = makeStyles({
    menuPopover: {
      boxShadow: '0 0 2px 0 rgba(139, 148, 156, 0.24),0 10px 20px -4px rgba(139, 148, 156, 0.24)',
      paddingTop: '20px',
      paddingBottom: '20px',
      '@global': {
        '.MuiMenuItem-root': {
          paddingBottom: '6px'
        }
      }
    },
    cancelButton: {
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.common.black,
      '&:hover': {
        backgroundColor: theme.palette.grey[300]
      }
    },
    declineButton: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.error.dark
      }
    }
  });

  const styles = useStyles();

  const getSellerDealership = async () => {
    const docRef = doc(firestore, `dealerships/${vehicle.dealershipId}`);
    const docSnap = await getDoc(docRef);
    const sellerDealership = { id: docSnap.id, ...docSnap.data() } as Dealership;
    vehicle.sellerDealership = sellerDealership;
    setSellerDealership(sellerDealership);
  };

  const getBuyerDealership = async () => {
    const buyingDealerRef = doc(firestore, `dealerships/${currentUser.currentDealershipId}`);
    const buyingDealerSnap = await getDoc(buyingDealerRef);
    const buyerDealership = { id: buyingDealerSnap.id, ...buyingDealerSnap.data() } as Dealership;
    vehicle.buyerDealership = buyerDealership;
    setBuyerDealership(buyerDealership);
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getBuyerDealership();
      getSellerDealership();
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseRatingModal = () => {
    setOpenRatingModal(!openRatingModal);
    setConditionStars(0);
    setOwnershipStars(0);
    setSalesStars(0);
    setHasRatedSales(false);
    setHasRatedCondition(false);
    setHasRatedOwnership(false);
    setRatingComments(undefined);
  };

  const handleRating = async () => {
    setSubmitLoading(true);
    const ratingRef = collection(firestore, 'ratings');
    let overallRating = 0;
    if (conditionStars || ownershipStars || salesStars) {
      overallRating = (conditionStars + ownershipStars + salesStars) / 3;
    }
    const uniqueId = uuid();
    const ratingObj = {
      paymentRating: null,
      pickupRating: null,
      conditionRating: conditionStars,
      ownershipRating: ownershipStars,
      salesRating: salesStars,
      overallRating: overallRating?.toFixed(2),
      comments: ratingComments ? ratingComments : 'No comments left by the reviewer.',
      dealershipId: sellerDealership?.id,
      dealershipName: sellerDealership?.dealershipName,
      reviewerDealershipName: buyerDealership?.dealershipName,
      vehicleId: vehicle.id,
      vehicleName: vehicle.details.year + ' ' + vehicle.details.make + ' ' + vehicle.details.model,
      date: Timestamp.now(),
      ratingType: 'seller',
      userId: user?.uid,
      reviewerEmail: currentUser.email,
      emailToSend: sellerDealership?.primaryContactEmail,
      uniqueId: uniqueId,
      // prettier-ignore
      sendEmail: 
      (conditionStars < 3 || ownershipStars < 3 || salesStars < 3) && isChecked
        ? true
        : false
    };
    const ratingString = uniqueId + '+' + overallRating.toFixed(2);
    await addDoc(ratingRef, ratingObj);
    await updateDealership(sellerDealership?.id as string, {
      sellerRatings: arrayUnion(ratingString)
    });
    await updateVehicle(vehicle.id as string, {
      hasBuyerRated: true
    });
    setSubmitLoading(false);
    handleCloseRatingModal();
  };

  const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorPopup(event.currentTarget);
    setPopupOpened(true);
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setSelectedIndex(index);
    sendVehicleToParent(index, vehicle);
  };

  const handleClose = () => {
    setAnchorPopup(null);
    setPopupOpened(false);
  };

  useEffect(() => {
    setAnchorEl(false);
  }, [popupOpened]);

  return (
    <Grid
      xs={12}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '10px',
        minHeight: '130px'
      }}
    >
      <Grid
        sx={{
          height: '100%',
          minHeight: '130px',
          padding: '6px !important',
          display: 'flex',
          borderTopLeftRadius: '10px',
          borderBottomLeftRadius: '10px',
          borderTop:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`,
          borderLeft:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`,
          borderBottom:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`
        }}
      >
        <ListItemDetails
          vehicle={vehicle}
          isSellerName={true}
          imageUrl={vehiclePhoto?.url}
          hasStatusPill={true}
          minWidth={'400px'}
        ></ListItemDetails>
      </Grid>
      {vehicle.sellerMarkAsSold && (
        <Grid
          item
          xs={3}
          sx={{
            height: '100%',
            minHeight: '130px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '6px 20px',
            minWidth: '155px',
            borderTop:
              theme.palette.mode === 'dark'
                ? `1px solid ${theme.palette.text.secondary}`
                : `1px solid ${theme.palette.divider}`,
            borderBottom:
              theme.palette.mode === 'dark'
                ? `1px solid ${theme.palette.text.secondary}`
                : `1px solid ${theme.palette.divider}`
          }}
        >
          <ListItemPersonDate label={'By Seller'} value={'Marked As Sold'}></ListItemPersonDate>
        </Grid>
      )}
      <Grid
        item
        xs={3}
        sx={{
          height: '100%',
          minHeight: '130px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '6px 20px',
          minWidth: '120px',
          borderTop:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`,
          borderBottom:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`
        }}
      >
        <ListItemBidPrice
          label={'sold price'}
          value={currencyFormat.format(vehicle.auction.currentPrice)}
          color={theme.palette.primary.main}
        ></ListItemBidPrice>
      </Grid>
      {/* <Grid
        item
        xs={3}
        sx={{
          height: '100%',
          minHeight: '130px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '6px 20px',
          minWidth: '150px',
          borderTop:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`,
          borderBottom:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`
        }}
      >
        <ListItemPersonDate
          isBold={true}
          label={'date paid'}
          value={
            vehicle.auction.datePaid
              ? fDateTimeSuffix(vehicle.auction.datePaid.toDate())
              : 'No Date Set'
          }
        ></ListItemPersonDate>
      </Grid> */}
      <Grid
        item
        xs={3}
        sx={{
          height: '100%',
          minHeight: '130px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '6px 20px',
          minWidth: '150px',
          borderTop:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`,
          borderBottom:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`
        }}
      >
        <ListItemPersonDate
          isBold={true}
          label={'date delivered'}
          value={
            vehicle.auction.dateDelivered
              ? fDateTimeSuffix(vehicle.auction.dateDelivered.toDate())
              : 'No Date Set'
          }
        ></ListItemPersonDate>
      </Grid>
      <Grid
        item
        xs={3}
        sx={{
          height: '100%',
          minHeight: '130px',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          minWidth: '150px',
          padding: '6px',
          borderTopRightRadius: '10px',
          borderBottomRightRadius: '10px',
          borderTop:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`,
          borderRight:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`,
          borderBottom:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`
        }}
      >
        <Chip
          label={vehicle.hasBuyerRated ? 'Already Rated' : 'Rate Experience'}
          disabled={vehicle.hasBuyerRated}
          sx={{ fontWeight: 'bold' }}
          color="primary"
          onClick={() => handleCloseRatingModal()}
        />
        <IconButton
          aria-describedby={id}
          onClick={handleClick}
          sx={{
            backgroundColor: theme.palette.background.paper,
            padding: '5px',
            borderRadius: '5px',
            marginLeft: '10px'
          }}
        >
          <MoreVertIcon />
        </IconButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorPopup}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          sx={{ width: 220 }}
          PaperProps={{ classes: { root: styles.menuPopover } }}
        >
          {SIMPLE_OPTIONS.map((option, index) => (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={(event: any) => handleMenuItemClick(event, index)}
              sx={{ typography: 'body2', py: 1, px: 2.5 }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Popover>
      </Grid>
      <Dialog open={openRatingModal} onClose={handleCloseRatingModal} sx={{ zIndex: '9999999' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '20px',
            position: 'relative',
            minWidth: '500px',
            borderBottom: `1px solid ${theme.palette.divider}`
          }}
        >
          <Typography component="p" variant="h5">
            Your Input Is Valuable
          </Typography>
          <CloseIcon
            onClick={handleCloseRatingModal}
            sx={{
              position: 'absolute',
              right: '10px',
              cursor: 'pointer'
            }}
          />
        </Box>
        <Box sx={{ padding: '20px 40px 20px 40px', textAlign: 'center' }}>
          <Typography>Rate your experience with this seller.</Typography>
          <Box sx={{ mt: 2 }}>
            {/* prettier-ignore */}
            <Typography>
              1. Were the vehicle conditions, declarations and vehicle history accurately described? (required)
            </Typography>
            <StyledRating
              name="condition-rating"
              defaultValue={conditionStars}
              size="large"
              precision={0.5}
              icon={<DirectionsCarIcon fontSize="inherit" />}
              emptyIcon={<DirectionsCarFilledOutlinedIcon fontSize="inherit" />}
              onChange={(event, newValue) => {
                if (!newValue) newValue = 0;
                setConditionStars(newValue as number);
                setHasRatedCondition(true);
              }}
            />
          </Box>
          <Box sx={{ mt: 1 }}>
            <Typography>
              2. Were the ownership and vehicle available as expected? (required)
            </Typography>
            <StyledRating
              name="ownership-rating"
              defaultValue={ownershipStars}
              size="large"
              precision={0.5}
              icon={<DirectionsCarIcon fontSize="inherit" />}
              emptyIcon={<DirectionsCarFilledOutlinedIcon fontSize="inherit" />}
              onChange={(event, newValue) => {
                if (!newValue) newValue = 0;
                setOwnershipStars(newValue as number);
                setHasRatedOwnership(true);
              }}
            />
          </Box>
          <Box sx={{ mt: 1 }}>
            <Typography>
              3. Was post auction sales progression completed in a timely manner? (required)
            </Typography>
            <StyledRating
              name="sales-rating"
              defaultValue={salesStars}
              size="large"
              precision={0.5}
              icon={<DirectionsCarIcon fontSize="inherit" />}
              emptyIcon={<DirectionsCarFilledOutlinedIcon fontSize="inherit" />}
              onChange={(event, newValue) => {
                if (!newValue) newValue = 0;
                setSalesStars(newValue as number);
                setHasRatedSales(true);
              }}
            />
          </Box>
          {/* prettier-ignore */}
          {(conditionStars < 3 || ownershipStars < 3 || salesStars < 3) &&
            hasRatedCondition &&
            hasRatedOwnership &&
            hasRatedSales && (
              <>
                {/* prettier-ignore */}
                <FormControlLabel
                  sx={{ mt: 1, mb: 1, textAlign: 'left' }}
                  control={<Checkbox checked={isChecked} onChange={handleCheckChange} />}
                  label={
                    <Typography>
                      This is currently a below average rating, may we <span style={{ fontWeight: 'bold' }}>send them your email</span> to contact you to resolve this issue?
                    </Typography>
                  }
                />
              </>
            )}
          <Box sx={{ mt: 1 }}>
            {/* prettier-ignore */}
            <Typography>
              3. Provide additional details. These details will be visible to other users and will be given to the reviewed party to help facilitate a resolution, if necessary.
            </Typography>
            <TextField
              sx={{
                fontSize: '12px',
                width: 400,
                mt: 1,
                borderRadius: '10px',
                backgroundColor:
                  theme.palette.mode === 'dark' ? 'unset' : theme.palette.common.white
              }}
              inputProps={{ maxLength: 150, style: { fontSize: 12 } }}
              InputLabelProps={{ style: { fontSize: 12, color: 'grey' } }}
              label={'Enter Comment'}
              multiline
              rows={3}
              value={ratingComments}
              onChange={(event) => {
                setRatingComments(event.target.value);
              }}
              margin="normal"
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0px 40px 20px 40px'
          }}
        >
          <Button
            size="large"
            variant="contained"
            className={styles.cancelButton}
            onClick={handleCloseRatingModal}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={submitLoading}
            size="large"
            variant="contained"
            disabled={!hasRatedCondition || !hasRatedOwnership || !hasRatedSales}
            onClick={() => handleRating()}
          >
            Submit
          </LoadingButton>
        </Box>
      </Dialog>
    </Grid>
  );
}
