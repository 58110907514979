import { FC, ReactNode } from 'react';
import { useDropzone } from 'react-dropzone';
import type { DropzoneOptions } from 'react-dropzone';
import { Box } from '@mui/material';

interface FileDropzoneProps extends DropzoneOptions {
  onUpload: (file: any) => void;
  accept: string;
  children: ReactNode;
  imageUrl?: string;
}

const FileDropzone: FC<FileDropzoneProps> = (props) => {
  const { onUpload, accept, children } = props;

  const onDrop = (acceptedFiles: any) => {
    const file = acceptedFiles[0];
    onUpload(file);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    onDrop
  });

  return (
    <>
      <Box
        sx={{
          width: '300px',
          ...(isDragActive && {
            backgroundColor: 'action.active',
            opacity: 0.5
          }),
          '&:hover': {
            // backgroundColor: 'action.hover',
            cursor: 'pointer'
            // opacity: 0.5
          }
        }}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {children}
      </Box>
    </>
  );
};

export default FileDropzone;
