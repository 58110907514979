import {
  Button,
  Select,
  MenuItem,
  SelectChangeEvent,
  Typography,
  Box,
  FormControl,
  IconButton
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px' // Gap between items (optional)
  },
  formControl: {
    minWidth: 60,
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none' // Remove the border
      },
      '&:hover fieldset': {
        borderColor: 'transparent' // Remove the border color on hover
      },
      '&.Mui-focused fieldset': {
        borderColor: 'transparent' // Remove the border color on focus
      }
    }
  }
});

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  rowsPerPageOptions: number[];
  rowsPerPage: number;
  onNextPage: () => void;
  onPrevPage: () => void;
  onChangeRowsPerPage: (rowsPerPage: number) => void;
}

const PaginationComponent: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  rowsPerPageOptions,
  rowsPerPage,
  onNextPage,
  onPrevPage,
  onChangeRowsPerPage
}) => {
  const classes = useStyles();

  const handleChangeRowsPerPage = (event: SelectChangeEvent<number>) => {
    const newRowsPerPage = event.target.value;
    onChangeRowsPerPage(+newRowsPerPage);
  };

  return (
    <Box sx={{ display: 'flex' }} className={classes.container}>
      <Typography>Rows per page</Typography>
      <FormControl variant="outlined" className={classes.formControl}>
        <Select
          value={rowsPerPage}
          onChange={handleChangeRowsPerPage}
          disableUnderline // This will remove the underline completely
        >
          {rowsPerPageOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <IconButton onClick={onPrevPage} disabled={currentPage === 1}>
        <KeyboardArrowLeftIcon />
      </IconButton>
      <Typography>
        {currentPage} of {totalPages}
      </Typography>
      <IconButton onClick={onNextPage} disabled={currentPage === totalPages}>
        <KeyboardArrowRightIcon />
      </IconButton>
    </Box>
  );
};

export default PaginationComponent;
