import { noCase } from 'change-case';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Icon from '@mui/material/Icon';
import NotificationsIcon from '@mui/icons-material/Notifications';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { formatDistanceToNow } from 'date-fns';
import {
  Box,
  List,
  Badge,
  Avatar,
  Tooltip,
  Divider,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
  Dialog,
  Tabs,
  Tab,
  Paper,
  Button,
  Pagination,
  Link
} from '@mui/material';
import { MIconButton } from '../../components/@material-extend';
import { CSSProperties } from '@mui/styles/withStyles';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import useAuth from 'hooks/useAuth';
import {
  collection,
  CollectionReference,
  DocumentSnapshot,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  Timestamp,
  where
} from 'firebase/firestore';
import { useFirestoreByPath } from 'hooks/useFirestoreByPath';
import { useTheme } from '@mui/material/styles';
import { PATH_BUYER, PATH_COMMUNITY, PATH_SELLER } from '../../routes/paths';
import { useNavigate } from 'react-router-dom';

type Notification = {
  id: string;
  vehicleName: string;
  vehicleId: string;
  dealershipId?: string;
  date: Timestamp;
  networkDealerships?: string[];
  watchlistIds: string[];
  bidLeaderId: string;
  autobids: any[];
  previousBids: any[];
  negotiationStatusSeller: string;
  negotiationStatusBuyer: string;
  status: string;
  message: string;
  altMessage?: string;
  type: string;
  notificationName: string;
  category: string;
  avatar: string | null;
  networkType?: string;
};

function renderContent(notification: Notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.vehicleName}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {noCase(notification.message)}
      </Typography>
    </Typography>
  );

  if (notification.type === 'order_placed') {
    return {
      avatar: (
        <img alt={notification.vehicleName} src="/static/icons/ic_notification_package.svg" />
      ),
      title
    };
  }
  if (notification.type === 'order_shipped') {
    return {
      avatar: (
        <img alt={notification.vehicleName} src="/static/icons/ic_notification_shipping.svg" />
      ),
      title
    };
  }
  if (notification.type === 'mail') {
    return {
      avatar: <img alt={notification.vehicleName} src="/static/icons/ic_notification_mail.svg" />,
      title
    };
  }
  if (notification.type === 'chat_message') {
    return {
      avatar: <img alt={notification.vehicleName} src="/static/icons/ic_notification_chat.svg" />,
      title
    };
  }
  return {
    avatar: notification.avatar ? (
      <img alt={notification.vehicleName} src={notification.avatar} />
    ) : null,
    title
  };
}

function NotificationItem({ notification }: { notification: Notification }) {
  const { avatar, title } = renderContent(notification);

  return (
    <ListItemButton
      to="#"
      component={RouterLink}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.status.toLowerCase() === 'unread' && {
          bgcolor: 'action.selected'
        })
      }}
    >
      {/* <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar> */}
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled'
            }}
          >
            <Box component={Icon} sx={{ mr: 0.5 }}>
              <WatchLaterIcon sx={{ fontSize: 17 }} />
            </Box>
            {formatDistanceToNow(notification.date.toDate())}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

export default function NotificationsPopover() {
  const theme = useTheme();
  const firestore = useFirestore();
  const { currentUser } = useAuth();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  // const [tabValue, setTabValue] = useState(0);
  // const [auctionNotes, setAuctionNotes] = useState<Notification[]>();
  // const [accountNotes, setAccountNotes] = useState<Notification[]>();
  // const [sellNotifications, setSellNotifications] = useState<Notification[]>();
  // const [buyNotifications, setBuyNotifications] = useState<Notification[]>();
  // const [lastBuyDoc, setLastBuyDoc] = useState<any>();
  // const [lastSellDoc, setLastSellDoc] = useState<any>();

  const [notifications, setNotifications] = useState<Notification[]>();
  const [lastDoc, setLastDoc] = useState<any>();
  const [totalUnread, setTotalUnread] = useState<number>(0);

  const navigate = useNavigate();

  const { update: updateNotification, remove: deleteNotification } =
    useFirestoreByPath<Notification>('notifications');

  const notificationsRef = collection(
    firestore,
    'notifications'
  ) as CollectionReference<Notification>;

  const countNotifications = () => {
    const notificationCountQuery = query(
      notificationsRef,
      where('dealershipId', '==', currentUser.currentDealershipId),
      where('status', '==', 'unread')
    );

    const unsubsribe = onSnapshot(notificationCountQuery, (querySnapshot) => {
      setTotalUnread(querySnapshot.size);
    });
    return unsubsribe;
  };

  useEffect(() => {
    const unsubscribe = countNotifications();
    return () => {
      unsubscribe();
    };
  }, []);

  const getAllNotifications = async () => {
    const notificationQuery = query(
      notificationsRef,
      where('dealershipId', '==', currentUser.currentDealershipId),
      where('status', '==', 'unread'),
      orderBy('date', 'desc'),
      limit(10)
    );

    onSnapshot(notificationQuery, (querySnapshot) => {
      const notifications = querySnapshot.docs.map(
        (doc) => ({ ...doc.data(), id: doc.id } as Notification)
      );
      setNotifications(notifications);

      const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
      setLastDoc(lastDoc);
    });
  };

  // const getNotifications = async () => {
  //   const sellQuery = query(
  //     notificationsRef,
  //     where('dealershipId', '==', currentUser.currentDealershipId),
  //     where('category', 'in', ['seller', 'buyerSeller']),
  //     orderBy('date', 'desc'),
  //     limit(10)
  //   );

  //   onSnapshot(sellQuery, (querySnapshot) => {
  //     const sellNotifications = querySnapshot.docs.map(
  //       (doc) => ({ ...doc.data(), id: doc.id } as Notification)
  //     );
  //     setSellNotifications(sellNotifications);
  //     const lastSellDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
  //     setLastSellDoc(lastSellDoc);
  //   });

  //   const buyQuery = query(
  //     notificationsRef,
  //     where('networkDealerships', 'array-contains', currentUser.currentDealershipId),
  //     where('category', 'in', ['buyer', 'buyerSeller']),
  //     orderBy('date', 'desc'),
  //     limit(10)
  //   );

  //   onSnapshot(buyQuery, (querySnapshot) => {
  //     const buyNotifications = querySnapshot.docs.map(
  //       (doc) => ({ ...doc.data(), id: doc.id } as Notification)
  //     );
  //     setBuyNotifications(buyNotifications);
  //     const lastBuyDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
  //     setLastBuyDoc(lastBuyDoc);
  //   });
  // };

  const nextAllNotifications = async () => {
    if (lastDoc) {
      const nextNotesQuery = query(
        notificationsRef,
        where('dealershipId', '==', currentUser.currentDealershipId),
        orderBy('date', 'desc'),
        startAfter(lastDoc),
        limit(10)
      );
      const nextNoteDocs = await getDocs(nextNotesQuery);
      const nextNotes = nextNoteDocs.docs.map(
        (doc) => ({ ...doc.data(), id: doc.id } as Notification)
      );

      const tempNotes = notifications as Notification[];
      nextNotes.forEach((note: Notification) => {
        tempNotes.push(note);
      });

      setNotifications(tempNotes);

      const nextLastDoc = nextNoteDocs.docs[nextNoteDocs.docs.length - 1];
      setLastDoc(nextLastDoc);
    }
  };

  // const nextNotifications = async () => {
  //   if (lastSellDoc) {
  //     const sellQuery = query(
  //       notificationsRef,
  //       where('dealershipId', '==', currentUser.currentDealershipId),
  //       where('category', 'in', ['seller', 'buyerSeller']),
  //       orderBy('date', 'desc'),
  //       startAfter(lastSellDoc),
  //       limit(10)
  //     );
  //     const sellDocs = await getDocs(sellQuery);
  //     const sellNotes = sellDocs.docs.map((doc) => ({ ...doc.data(), id: doc.id } as Notification));

  //     // prettier-ignore
  //     const tempSellNotes = sellNotifications as Notification[];
  //     sellNotes.forEach((note: Notification) => {
  //       tempSellNotes.push(note);
  //     });

  //     setSellNotifications(tempSellNotes);
  //     const lastDocSell = sellDocs.docs[sellDocs.docs.length - 1];
  //     setLastSellDoc(lastDocSell);
  //   }

  //   if (lastBuyDoc) {
  //     const buyQuery = query(
  //       notificationsRef,
  //       where('networkDealerships', 'array-contains', currentUser.currentDealershipId),
  //       where('category', 'in', ['buyer', 'buyerSeller']),
  //       orderBy('date', 'desc'),
  //       startAfter(lastBuyDoc),
  //       limit(10)
  //     );
  //     const buyDocs = await getDocs(buyQuery);
  //     const buyNotes = buyDocs.docs.map((doc) => ({ ...doc.data(), id: doc.id } as Notification));

  //     // prettier-ignore
  //     const tempBuyNotes = buyNotifications as Notification[];
  //     buyNotes.forEach((note: Notification) => {
  //       tempBuyNotes.push(note);
  //     });
  //     setBuyNotifications(tempBuyNotes);
  //     const lastDocBuy = buyDocs.docs[buyDocs.docs.length - 1];
  //     setLastBuyDoc(lastDocBuy);
  //   }

  //   sortNotifications();
  // };

  useEffect(() => {
    let isMounted = true;
    if (isMounted && !notifications) {
      // getNotifications();
      getAllNotifications();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  // const sortNotifications = () => {
  //   if (buyNotifications && sellNotifications && currentUser) {
  //     let tempNotifications: Notification[] = [];
  //     let userNotifications: string[] = [];

  //     if (currentUser.accountNotifications && currentUser.auctionNotifications) {
  //       userNotifications = [
  //         ...currentUser.accountNotifications,
  //         ...currentUser.auctionNotifications
  //       ];
  //     } else if (currentUser.accountNotifications && !currentUser.auctionNotifications) {
  //       userNotifications = [...currentUser.accountNotifications];
  //     } else if (currentUser.auctionNotifications && !currentUser.accountNotifications) {
  //       userNotifications = [...currentUser.auctionNotifications];
  //     }

  //     buyNotifications.forEach((n: Notification) => {
  //       if (userNotifications.includes(n.notificationName)) {
  //         if (n.notificationName === 'pendingStatus' && n.category === 'buyer') {
  //           if (n.bidLeaderId === user?.uid) {
  //             tempNotifications.push(n);
  //           }
  //         } else if (n.notificationName === 'negotiationStatus' && n.category === 'buyer') {
  //           if (n.bidLeaderId === user?.uid) {
  //             tempNotifications.push(n);
  //           }
  //         } else {
  //           tempNotifications.push(n);
  //         }
  //       }
  //     });

  //     sellNotifications.forEach((n: Notification) => {
  //       if (userNotifications.includes(n.notificationName)) {
  //         tempNotifications.push(n);
  //       }
  //     });

  //     tempNotifications = tempNotifications.sort((a, b) =>
  //       a.date.toDate() > b.date.toDate() ? -1 : 1
  //     );
  //     setNotifications(tempNotifications);

  //     const auctionNotes = tempNotifications.filter(
  //       (note: Notification) => note.type.toLowerCase() === 'auction'
  //     );
  //     setAuctionNotes(auctionNotes);

  //     const accountNotes = tempNotifications.filter(
  //       (note: Notification) => note.type.toLowerCase() === 'account'
  //     );
  //     setAccountNotes(accountNotes);

  //     const tempUnread = tempNotifications?.filter((item) => item.status.toLowerCase() === 'unread')
  //       .length as number;
  //     setTotalUnread(tempUnread);
  //   }
  // };

  // useEffect(() => {
  //   sortNotifications();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [buyNotifications, sellNotifications]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMarkAsRead = async (note: Notification) => {
    await deleteNotification(note.id as string);
    // if (note.notificationName === 'activeStatus') {
    //   if (note.dealershipId === currentUser.currentDealershipId) {
    //     navigate(`${PATH_SELLER.activeAuctions}`);
    //     handleClose();
    //   } else {
    //     navigate(`${PATH_BUYER.activeAuctions}`);
    //     handleClose();
    //   }
    // }
    // if (note.notificationName === 'pendingStatus') {
    //   if (note.category === 'buyer') {
    //     navigate(`${PATH_BUYER.postAuction}`, { state: { tabValue: 0 } });
    //     handleClose();
    //   } else {
    //     navigate(`${PATH_SELLER.postAuction}`, { state: { tabValue: 0 } });
    //     handleClose();
    //   }
    // }
    // if (note.notificationName === 'negotiationStatus') {
    //   if (note.category === 'buyer') {
    //     navigate(`${PATH_BUYER.postAuction}`, { state: { tabValue: 1 } });
    //     handleClose();
    //   } else {
    //     navigate(`${PATH_SELLER.postAuction}`, { state: { tabValue: 1 } });
    //     handleClose();
    //   }
    // }
    // if (note.notificationName === 'arrivedStatus') {
    //   if (note.dealershipId === currentUser.currentDealershipId) {
    //     navigate(`${PATH_SELLER.postAuction}`, { state: { tabValue: 3 } });
    //     handleClose();
    //   } else {
    //     navigate(`${PATH_BUYER.postAuction}`, { state: { tabValue: 3 } });
    //     handleClose();
    //   }
    // }
    // if (note.notificationName === 'deliveredStatus') {
    //   if (note.dealershipId === currentUser.currentDealershipId) {
    //     navigate(`${PATH_SELLER.postAuction}`, { state: { tabValue: 4 } });
    //     handleClose();
    //   } else {
    //     navigate(`${PATH_BUYER.postAuction}`, { state: { tabValue: 4 } });
    //     handleClose();
    //   }
    // }
    // if (note.notificationName === 'networkRequest') {
    //   if (note.networkType === 'request') {
    //     navigate(`${PATH_COMMUNITY.sellerNetwork}`);
    //     handleClose();
    //   } else {
    //     navigate(`${PATH_COMMUNITY.buyerNetwork}`);
    //     handleClose();
    //   }
    // }
  };

  const handleMarkAllAsRead = async () => {
    if (notifications) {
      // prettier-ignore
      await Promise.all(notifications.map(async (note: Notification) => {
        await deleteNotification(note.id as string);
      }));
    }
    // if (tabValue === 0) {
    //   if (notifications) {
    //     // prettier-ignore
    //     await Promise.all(notifications.map(async (note: Notification) => {
    //       if (note.type.toLowerCase() === 'auction' && note.status.toLowerCase() === 'unread') {
    //         await updateNotification(note.id as string, { status: 'read' });
    //         note.status = 'read';
    //         sortNotifications();
    //       }
    //     }));
    //   }
    // } else {
    //   if (notifications) {
    //     // prettier-ignore
    //     await Promise.all(notifications.map(async (note: Notification) => {
    //       if (note.type.toLowerCase() === 'account' && note.status.toLowerCase() === 'unread') {
    //         await updateNotification(note.id as string, { status: 'read' });
    //         note.status = 'read';
    //         sortNotifications();
    //       }
    //     }));
    //   }
    // }
  };

  // interface TabPanelProps {
  //   children?: ReactNode;
  //   index: number;
  //   value: number;
  //   style: CSSProperties;
  // }

  // function TabPanel(props: TabPanelProps) {
  //   const { children, value, index, style, ...other } = props;

  //   return (
  //     <div
  //       role="tabpanel"
  //       hidden={value !== index}
  //       id={`simple-tabpanel-${index}`}
  //       aria-labelledby={`simple-tab-${index}`}
  //       style={style}
  //       {...other}
  //     >
  //       {value === index && (
  //         <Box>
  //           <Typography>{children}</Typography>
  //         </Box>
  //       )}
  //     </div>
  //   );
  // }

  // const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
  //   setTabValue(newValue);
  // };

  // function a11yProps(index: number) {
  //   return {
  //     id: `simple-tab-${index}`,
  //     'aria-controls': `simple-tabpanel-${index}`
  //   };
  // }

  return (
    <>
      <MIconButton
        ref={anchorRef}
        size="large"
        sx={{ mt: 1 }}
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
      >
        <Badge badgeContent={totalUnread} color="error">
          <NotificationsIcon sx={{ fontSize: 20 }} />
        </Badge>
      </MIconButton>

      <Dialog open={open} onClose={handleClose}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',
            py: 2,
            px: 2.5,
            minWidth: '500px'
          }}
        >
          <Box sx={{ width: '100%' }}>
            <Typography variant="subtitle1">Your Notifications</Typography>
          </Box>

          {totalUnread > 0 && (
            <Tooltip title="Mark all as read">
              <MIconButton color="primary" onClick={handleMarkAllAsRead}>
                <DoneAllIcon sx={{ fontSize: 20 }} />
              </MIconButton>
            </Tooltip>
          )}
        </Box>

        <Divider
          sx={{
            borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
          }}
        />

        {/* <Tabs
          value={tabValue}
          onChange={handleChangeTab}
          sx={{ ml: 2, display: 'flex', justifyContent: 'space-around' }}
        >
          <Tab label="Auctions" {...a11yProps(0)} />
          <Tab label="Account" {...a11yProps(1)} />
        </Tabs> */}

        {/* <TabPanel
          value={tabValue}
          index={0}
          style={{ width: '100%', height: '365px', overflowY: 'hidden' }}
        >
          <Paper sx={{ height: { xs: 340 }, overflowY: 'auto' }}>
            <List disablePadding>
              {auctionNotes &&
                auctionNotes.map((notification: Notification) => {
                  return (
                    <Box key={notification.id} onClick={() => handleMarkAsRead(notification)}>
                      <NotificationItem notification={notification} />
                    </Box>
                  );
                })}
            </List>
          </Paper>

          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
        </TabPanel> */}

        {/* <TabPanel
          value={tabValue}
          index={1}
          style={{ width: '100%', height: '365px', overflowY: 'hidden' }}
        >
          <Paper sx={{ height: { xs: 340 }, overflowY: 'auto' }}>
            <List disablePadding>
              {accountNotes &&
                accountNotes.map((notification) => {
                  return (
                    <Box key={notification.id} onClick={() => handleMarkAsRead(notification)}>
                      <NotificationItem notification={notification} />
                    </Box>
                  );
                })}
            </List>
          </Paper>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
        </TabPanel> */}

        <Paper sx={{ height: { xs: 340 }, overflowY: 'auto' }}>
          <List disablePadding>
            {notifications &&
              notifications.map((notification: Notification) => {
                return (
                  <Box key={notification.id} onClick={() => handleMarkAsRead(notification)}>
                    <NotificationItem notification={notification} />
                  </Box>
                );
              })}
          </List>
        </Paper>

        <Divider
          sx={{
            borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
          }}
        />

        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '20px',
            marginTop: '20px'
          }}
        >
          <Button onClick={() => nextAllNotifications()}>Load More</Button>
        </Box>
      </Dialog>
    </>
  );
}
