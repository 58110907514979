import {
  Grid,
  BoxProps,
  IconButton,
  Button,
  Popover,
  MenuItem,
  Dialog,
  Box,
  Typography,
  ButtonGroup,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  Tooltip,
  styled,
  TextField,
  FormControlLabel,
  Checkbox,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  stepConnectorClasses
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import ListItemDetails from '../../shared/ListItemDetails';
import ListItemBidPrice from '../../shared/ListItemPriceBid';
import ListItemPersonDate from '../../shared/ListItemPersonDate';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useEffect, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { VehicleInfo, VehiclePhotos } from 'types/vehicleInfo';
import { useFirestore, useStorage } from 'reactfire';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import {
  addDoc,
  arrayUnion,
  collection,
  doc,
  getDoc,
  Timestamp,
  updateDoc
} from 'firebase/firestore';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';
import { MIconButton } from 'components/@material-extend';
import BillofSaleDoc from 'components/admin/BillofSaleDoc';
import { pdf } from '@react-pdf/renderer';
import { Dealership } from 'types/dealership';
import useAuth from 'hooks/useAuth';
import { useFirestoreByPath } from 'hooks/useFirestoreByPath';
import { User } from 'constants/user';
import moment from 'moment';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Rating from '@mui/material/Rating';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DirectionsCarFilledOutlinedIcon from '@mui/icons-material/DirectionsCarFilledOutlined';
import { v4 as uuid } from 'uuid';
import VehicleReleaseDoc from 'components/admin/VehicleReleaseDoc';

interface Props extends BoxProps {
  vehicle: VehicleInfo;
  initialOpen: boolean;
  sendVehicleToParent: (index: number, vehicle: VehicleInfo, isArrivedItem?: boolean) => void;
  openBillOfSale: (vehicle: VehicleInfo) => void;
}

const MENU_OPTIONS = [
  { label: 'Vehicle History', linkTo: '#' },
  { label: 'Bidding History', linkTo: '#' },
  { label: 'View VDP', linkTo: '#' },
  // { label: 'Bill of Sale', linkTo: '#' },
  { label: 'Cancel Sale', linkTo: '#' }
];

export default function ArrivedListItem({
  vehicle,
  initialOpen,
  sendVehicleToParent,
  openBillOfSale
}: Props) {
  const theme = useTheme();
  const firestore = useFirestore();
  const storage = useStorage();
  const { currentUser, user } = useAuth();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [anchorPopup, setAnchorPopup] = useState<HTMLButtonElement | null>(null);
  const [popupOpened, setPopupOpened] = useState(false);
  const [, setAnchorEl] = useState(false);
  const [openFinalizeModal, setOpenFinalizeModal] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [openVDPDrawer, setOpenVDPDrawer] = useState<boolean>(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openBidDrawer, setOpenBidDrawer] = useState(false);
  // const [vehiclePhoto, setVehiclePhoto] = useState<VehiclePhotos>();
  const [openBillofSale, setOpenBillofSale] = useState(false);
  const [buyerDealership, setBuyerDealership] = useState<Dealership>();
  const [sellerDealership, setSellerDealership] = useState<Dealership>();
  const [announcements, setAnnouncements] = useState<any[]>();
  const [disclosures, setDisclosures] = useState<any[]>();
  const [buyingUser, setBuyingUser] = useState<any>();
  const [buyerUser, setBuyerUser] = useState<User>();
  const { update: updateCustomer } = useFirestoreByPath<any>('customers');
  const [dealerRepRegistration, setDealerRepRegistration] = useState<any>();
  const [buyerRegistration, setBuyerRegistration] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [buyerFee, setBuyerFee] = useState<number>();
  const [totalPrice, setTotalPrice] = useState<number>();
  const [captureFee, setCaptureFee] = useState<number>(49);
  const [subTotalPrice, setSubTotalPrice] = useState<number>(0);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [odometerData, setOdometerData] = useState<any[]>();
  const [repairs, setRepairs] = useState<any[]>();
  const [vhrDamages, setVhrDamages] = useState<any[]>();
  const options = [
    'View/Download Bill of Sale',
    vehicle.billOfSaleLink ? 'Bill of Sale Sent' : 'Send Bill of Sale by Email',
    vehicle.releaseLink
      ? 'Vehicle Release Sent'
      : 'Send Vehicle Release by Email (After Bill of Sale)'
  ];
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [paymentStars, setPaymentStars] = useState<number>(0);
  const [pickupStars, setPickupStars] = useState<number>(0);
  const [hasRatedPayment, setHasRatedPayment] = useState<boolean>(false);
  const [hasRatedPickup, setHasRatedPickup] = useState<boolean>(false);
  const [ratingComments, setRatingComments] = useState<string>();
  const [openRatingModal, setOpenRatingModal] = useState(false);
  const [isChecked, setIsChecked] = useState<boolean>(true);

  const { update: updateDealership } = useFirestoreByPath<Dealership>('dealerships');

  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`& .${stepConnectorClasses.line}`]: {
      width: '75%',
      margin: 'auto'
    }
  }));

  const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
      color: theme.palette.success.main
    },
    '& .MuiRating-iconHover': {
      color: theme.palette.success.main
    }
  });

  const announcementKeys = [
    'Mto Reg',
    'Drivable',
    'Prev Repairs',
    'Badges Changed',
    'InOp ABS',
    'InOp Air Bags',
    'InOp Pollution',
    'Manuals',
    'Fifth Wheel',
    'Salvage',
    'Struct Damage',
    'Struct Repairs',
    'Repair Notes',
    'Sold As Is',
    'Out Of Province',
    'US Vehicle',
    'Warranty Cancelled',
    'Was Vehicle',
    'Was Declared',
    'Notes',
    'Odometer Status',
    'Odometer Reading',
    'Odometer Reading Date',
    'Odometer Radio Choice'
  ];

  const disclosureKeys = [
    'Drivable',
    'Struct Damage',
    'Prev Repairs',
    'Badges Changed',
    'InOp ABS',
    'InOp Air Bags',
    'InOp Pollution',
    'Number of Keys',
    'Manuals',
    'Fifth Wheel',
    'Salvage',
    'Sold As Is',
    'Electric Needs Repair'
  ];

  const [openButtonGroup, setOpenButtonGroup] = useState<boolean>(false);

  const handleCloseButtonGroup = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpenButtonGroup(false);
  };

  const handleToggle = () => {
    setOpenButtonGroup((prevOpen) => !prevOpen);
  };

  const handleButtonGroupClick = async (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    if (index === 0) {
      openBillOfSale(vehicle);
    } else if (index === 1) {
      await saveFileToStorage();
    } else {
      await saveAndSendVehicleRelease();
    }
    setOpenButtonGroup(false);
  };

  const getDamages = async () => {
    const tempDamages: any[] = [];
    const damages = vehicle.disclosures.vhrDamages ? vehicle.disclosures.vhrDamages : null;
    if (damages) {
      damages.forEach((damage: any) => {
        tempDamages.push({
          label: 'VHR Damage',
          cost: damage.cost,
          date: moment(damage.date.toDate()).format('MM-DD-YYYY'),
          desc: damage.description
        });
      });
      setVhrDamages(tempDamages);
    }
  };

  const getRepairs = async () => {
    const tempRepairs: any[] = [];
    // prettier-ignore
    const mechRepairs = vehicle.disclosures.mechNeedRepairs ? vehicle.disclosures.mechNeedRepairs : null;
    if (mechRepairs) {
      mechRepairs.forEach((repair: string) => {
        tempRepairs.push('Needs Repair' + ': ' + repair);
      });
    }
    // prettier-ignore
    const elecRepairs = vehicle.disclosures.electricNeedRepairs ? vehicle.disclosures.electricNeedRepairs : null;
    if (elecRepairs) {
      elecRepairs.forEach((repair: string) => {
        tempRepairs.push('Needs Repair' + ': ' + repair);
      });
    }
    setRepairs(tempRepairs);
  };

  const getAnnouncements = async () => {
    const tempAnnouncements: any[] = [];
    const tempDisclosures: any[] = [];
    const tempOdometerData: any[] = [];
    // eslint-disable-next-line array-callback-return
    Object.entries(vehicle.disclosures).map(([key, value]) => {
      announcementKeys.forEach((announcementKey: string) => {
        if (
          announcementKey.replace(/\s/g, '').toLowerCase() === key.toString().toLowerCase() &&
          (announcementKey === 'Drivable' || announcementKey === 'Manuals')
        ) {
          if (value?.toString().toLowerCase() === 'false') {
            tempAnnouncements.push(announcementKey + ': ' + 'No');
          }
        }
        if (
          announcementKey.replace(/\s/g, '').toLowerCase() === key.toString().toLowerCase() &&
          (announcementKey === 'Repair Notes' || announcementKey === 'Notes') &&
          value &&
          value !== '' &&
          value !== ' '
        ) {
          tempAnnouncements.push(announcementKey + ': ' + value?.toString());
        }
        if (
          announcementKey.replace(/\s/g, '').toLowerCase() === key.toString().toLowerCase() &&
          (announcementKey === 'Out Of Province' || announcementKey === 'US Vehicle')
        ) {
          let displayValue;
          if (value?.toString().toLowerCase() === 'true') {
            if (announcementKey === 'Out Of Province') {
              displayValue = vehicle.disclosures.province;
            } else {
              displayValue = vehicle.disclosures.state;
            }
            tempAnnouncements.push(announcementKey + ': ' + displayValue?.toString());
          }
        }
        if (
          announcementKey.replace(/\s/g, '').toLowerCase() === key.toString().toLowerCase() &&
          value !== false &&
          value?.toString().toLowerCase() !== 'none'
        ) {
          if (
            announcementKey !== 'Odometer Reading' &&
            announcementKey !== 'Odometer Reading Date' &&
            announcementKey !== 'Odometer Radio Choice' &&
            announcementKey !== 'US Vehicle' &&
            announcementKey !== 'Out Of Province' &&
            announcementKey !== 'Drivable' &&
            announcementKey !== 'Manuals' &&
            announcementKey !== 'Repair Notes' &&
            announcementKey !== 'Notes'
          ) {
            let displayValue;
            if (value?.toString().toLowerCase() === 'false') {
              displayValue = 'No';
            } else if (value?.toString().toLowerCase() === 'true') {
              displayValue = 'Yes';
            } else {
              displayValue = value?.toString();
            }
            let announceKey;
            if (announcementKey === 'Struct Repairs') {
              announceKey = 'Structural Repairs/Replacements/Alterations';
            } else if (announcementKey === 'Prev Repairs') {
              announceKey = 'Damages Exceeding $3,000';
            } else if (announcementKey === 'Struct Damage') {
              announceKey = 'Structual Damage';
            } else {
              announceKey = announcementKey;
            }
            tempAnnouncements.push(announceKey + ': ' + displayValue?.toString());
          }
          if (
            announcementKey === 'Odometer Reading Date' &&
            vehicle.disclosures.odometerReadingDate &&
            vehicle.disclosures.odometerReadingDate !== undefined
          ) {
            let val = value as Timestamp;
            tempOdometerData.push(
              announcementKey + ': ' + moment(val?.toDate()).format('MM-DD-YYYY')
            );
          } else if (announcementKey === 'Odometer Reading') {
            if (vehicle.disclosures.odometerReading !== '') {
              tempOdometerData.push(announcementKey + ': ' + value?.toString() + ' KM');
            }
          } else if (announcementKey === 'Odometer Radio Choice') {
            if (vehicle.disclosures.odometerRadioChoice) {
              if (vehicle.disclosures.odometerRadioChoice === 'Option 1') {
                tempOdometerData.push(
                  'Odometer Note' +
                    ': ' +
                    `The dealer can not determine the total distance that the vehicle has been driven but can determine the distance that the vehicle has been driven as of some past date. The total distance that the vehicle has been driven is believed to be higher than the previously recorded distance.`
                );
              } else {
                tempOdometerData.push(
                  'Odometer Note' +
                    ': ' +
                    `True Kilometers/Mileage Unknown: The dealer can determine neither the total distance that the vehicle has been driven, nor the distance that the vehicle has been driven as of some past date. The total distance that the vehicle has been driven is unknown and may be substantially higher that the reading shown on the odometer.`
                );
              }
            }
          }
        }
      });
      disclosureKeys.forEach((disclosureKey: string) => {
        if (disclosureKey.replace(/\s/g, '').toLowerCase() === key.toString().toLowerCase()) {
          let displayValue;
          if (value?.toString().toLowerCase() === 'false') {
            displayValue = 'No';
          } else if (value?.toString().toLowerCase() === 'true') {
            displayValue = 'Yes';
          } else {
            displayValue = value?.toString();
          }
          let discloseKey;
          if (disclosureKey === 'Prev Repairs') {
            discloseKey = 'Damages Exceeding $3,000';
          } else if (disclosureKey === 'Struct Damage') {
            discloseKey = 'Structural Damage';
          } else if (disclosureKey === 'Struct Repairs') {
            discloseKey = 'Structural Repairs/Replacements/Alterations';
          } else {
            discloseKey = disclosureKey;
          }
          tempDisclosures.push({ label: discloseKey, value: displayValue });
        }
      });
    });
    setOdometerData(tempOdometerData);
    setDisclosures(tempDisclosures);
    setAnnouncements(tempAnnouncements);
  };

  const calculateTotal = (dealership: Dealership) => {
    let subPrice = 0;
    let totalPrice;
    let buyerFee;
    const currentPrice = parseInt(vehicle.auction.currentPrice.toString(), 10);
    // add scaling buyer fee
    if (currentPrice >= 0 && currentPrice < 10000) {
      buyerFee = 99;
    } else if (currentPrice >= 10000 && currentPrice < 20000) {
      buyerFee = 139;
    } else if (currentPrice >= 20000 && currentPrice < 30000) {
      buyerFee = 179;
    } else if (currentPrice >= 30000 && currentPrice < 40000) {
      buyerFee = 209;
    } else if (currentPrice >= 40000 && currentPrice < 50000) {
      buyerFee = 239;
    } else if (currentPrice >= 50000 && currentPrice < 60000) {
      buyerFee = 269;
    } else if (currentPrice >= 60000 && currentPrice < 70000) {
      buyerFee = 299;
    } else if (currentPrice >= 70000 && currentPrice < 80000) {
      buyerFee = 329;
    } else if (currentPrice >= 80000 && currentPrice < 90000) {
      buyerFee = 359;
    } else if (currentPrice >= 90000) {
      buyerFee = 389;
    }

    setBuyerFee(buyerFee);

    if (dealership.captureFee && buyerFee) {
      subPrice = currentPrice + captureFee + buyerFee;
    } else if (buyerFee) {
      subPrice = currentPrice + buyerFee;
    }

    setSubTotalPrice(subPrice as number);

    // calc taxes based on province
    if (
      dealership.province === 'Newfoundland Labrador' ||
      dealership.province === 'New Brunswick' ||
      dealership.province === 'Nova Scotia' ||
      dealership.province === 'Prince Edward Island'
    ) {
      totalPrice = subPrice + subPrice * 0.15;
      setTotalPrice(totalPrice);
    } else if (dealership.province === 'Ontario') {
      totalPrice = subPrice * 0.13 + subPrice;
      setTotalPrice(totalPrice);
    } else if (
      dealership.province === 'Alberta' ||
      dealership.province === 'Northwest Territories' ||
      dealership.province === 'Yukon Territory' ||
      dealership.province === 'Nunavut'
    ) {
      totalPrice = subPrice + subPrice * 0.05;
      setTotalPrice(totalPrice);
    } else if (
      dealership.province === 'British Columbia' ||
      dealership.province === 'Manitoba' ||
      dealership.province === 'Saskatchewan'
    ) {
      const gstPrice = subPrice * 0.05;
      const pstPrice = subPrice * 0.07;
      totalPrice = gstPrice + pstPrice + subPrice;
    } else if (dealership.province === 'Quebec') {
      const gstPrice = subPrice * 0.05;
      const pstPrice = subPrice * 0.09975;
      totalPrice = gstPrice + pstPrice + subPrice;
    }

    setTotalPrice(totalPrice as number);
  };

  const getSellerDealership = async () => {
    const docRef = doc(firestore, `dealerships/${vehicle.dealershipId}`);
    const docSnap = await getDoc(docRef);
    const sellerDealership = { id: docSnap.id, ...docSnap.data() } as Dealership;
    setSellerDealership(sellerDealership);
    vehicle.sellerDealership = sellerDealership;
    calculateTotal(sellerDealership);
  };

  const getBuyerDealership = async () => {
    const userDocRef = doc(firestore, `users/${vehicle.auction.bidLeaderId}`);
    const userDocSnap = await getDoc(userDocRef);
    const buyingUser = { id: userDocSnap.id, ...userDocSnap.data() } as any;
    setBuyingUser(buyingUser);
    const buyingDealerRef = doc(firestore, `dealerships/${buyingUser.currentDealershipId}`);
    const buyingDealerSnap = await getDoc(buyingDealerRef);
    const buyerDealership = { id: buyingDealerSnap.id, ...buyingDealerSnap.data() } as Dealership;
    setBuyerDealership(buyerDealership);
    vehicle.buyerDealership = buyerDealership;
  };

  const setRegistrations = () => {
    if (vehicle.auction.dealerRep) {
      setDealerRepRegistration(
        vehicle.auction.dealerRep.registration ? vehicle.auction.dealerRep.registration : null
      );
    }
    if (vehicle.buyerUser) {
      setBuyerRegistration(vehicle.buyerUser.registration ? vehicle.buyerUser.registration : null);
    }
  };

  const currencyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });

  let vehiclePhoto;
  if (vehicle.photos) {
    const photos = vehicle.photos.filter(
      (v: any) => v.url && v.location && v.location.toLowerCase().includes('3/4 shot')
    );
    if (photos[0]) {
      vehiclePhoto = photos[0];
    }
  }

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getBuyerDealership();
      getSellerDealership();
      getAnnouncements();
      setRegistrations();
      getRepairs();
      getDamages();
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const open = Boolean(anchorPopup);
  const id = open ? 'simple-popover' : undefined;

  const useStyles = makeStyles({
    menuPopover: {
      boxShadow: '0 0 2px 0 rgba(139, 148, 156, 0.24),0 10px 20px -4px rgba(139, 148, 156, 0.24)',
      paddingTop: '20px',
      paddingBottom: '20px',
      '@global': {
        '.MuiMenuItem-root': {
          paddingBottom: '6px'
        }
      }
    },
    cancelButton: {
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.common.black,
      '&:hover': {
        backgroundColor: theme.palette.grey[300]
      }
    },
    declineButton: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.error.dark
      }
    },
    paper: {
      minWidth: '800px'
    }
  });

  const styles = useStyles();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorPopup(event.currentTarget);
    setPopupOpened(true);
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setSelectedIndex(index);
    sendVehicleToParent(index, vehicle, true);
    if (index === 3) {
      setOpenCancelModal(true);
    }
  };

  const handleCancelSale = async () => {
    const docRef = doc(firestore, `vehicles/${vehicle.id}`);
    await updateDoc(docRef, {
      'auction.postAuctionStatus': 'no sale',
      'auction.reasonLost': 'Sale Cancelled'
    });
    enqueueSnackbar('Sale Cancelled', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right'
      },
      action: (key) => (
        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
          <CloseIcon />
        </MIconButton>
      )
    });
  };

  const handleCloseFinalizeModal = () => {
    setOpenFinalizeModal(!openFinalizeModal);
  };

  const handleCloseRatingModal = () => {
    setOpenRatingModal(!openRatingModal);
    setPaymentStars(0);
    setPickupStars(0);
    setHasRatedPayment(false);
    setHasRatedPickup(false);
    setRatingComments(undefined);
    setIsChecked(true);
    setActiveStep(0);
  };

  const handleCloseCancelModal = () => {
    setOpenCancelModal(!openCancelModal);
  };

  const handleClose = () => {
    setAnchorPopup(null);
    setPopupOpened(false);
  };

  const saveAndSendVehicleRelease = async (): Promise<{ url: string; fileName: string }> => {
    const blob = await pdf(
      VehicleReleaseDoc({
        vehicle: vehicle,
        buyerDealership: buyerDealership as Dealership,
        sellerDealership: sellerDealership as Dealership,
        sellerName: currentUser.firstName + ' ' + currentUser.lastName,
        sellerEmail: currentUser.email,
        sellerPhone: currentUser.phone,
        buyerName: buyingUser.firstName + ' ' + buyingUser.lastName,
        buyerEmail: buyingUser.email,
        buyerPhone: buyingUser.phone
      })
    ).toBlob();
    const file = new Blob([blob], { type: 'application/pdf' });
    const path2File = `vehicleRelease/${vehicle.id}/`;
    const storageRef = ref(storage, path2File);
    const fileName = `${vehicle.id}-vehicleRelease`;
    const pdfRef = ref(storageRef, fileName);
    const uploadPDF = uploadBytesResumable(pdfRef, file);
    return new Promise((resolve, reject) => {
      uploadPDF.on(
        'state_changed',
        () => {},
        () => {},
        async () => {
          const url = await getDownloadURL(pdfRef);
          const docRef = doc(firestore, `vehicles/${vehicle.id}`);
          await updateDoc(docRef, { releaseLink: url });
          enqueueSnackbar('Vehicle Release Sent', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right'
            }
          });
          resolve({ url: url, fileName: fileName });
        }
      );
    });
  };

  const saveFileToStorage = async (): Promise<{ url: string; fileName: string }> => {
    const blob = await pdf(
      BillofSaleDoc({
        captureFee: captureFee as number,
        subTotalPrice: subTotalPrice as number,
        buyerFee: buyerFee as number,
        totalPrice: totalPrice as number,
        vehicle: vehicle,
        buyerRegistration,
        dealerRepRegistration,
        buyerUser: buyingUser,
        buyerDealership: buyerDealership as Dealership,
        sellerDealership: sellerDealership as Dealership,
        announcements: announcements,
        disclosures: disclosures,
        repairs: repairs,
        odometerData: odometerData,
        vhrDamages: vhrDamages
      })
    ).toBlob();
    const file = new Blob([blob], { type: 'application/pdf' });
    const path2File = `billsOfSale/${vehicle.id}/`;
    const storageRef = ref(storage, path2File);
    const fileName = `${vehicle.id}-billOfSale`;
    const pdfRef = ref(storageRef, fileName);
    const uploadPDF = uploadBytesResumable(pdfRef, file);
    return new Promise((resolve, reject) => {
      uploadPDF.on(
        'state_changed',
        () => {},
        () => {},
        async () => {
          const url = await getDownloadURL(pdfRef);
          const docRef = doc(firestore, `vehicles/${vehicle.id}`);
          await updateDoc(docRef, {
            billOfSaleLink: url,
            buyerEmail: buyingUser.email,
            sellerEmail: currentUser.email,
            'auction.buyerName': buyingUser.firstName + ' ' + buyingUser.lastName,
            // sellerName: currentUser.firstName + ' ' + currentUser.lastName,
            buyerDealership: buyerDealership,
            sellerDealership: sellerDealership
          });
          enqueueSnackbar('Bill of Sale Sent', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right'
            }
          });
          resolve({ url: url, fileName: fileName });
        }
      );
    });
  };

  const handleRating = async () => {
    setIsLoading(true);
    const ratingRef = collection(firestore, 'ratings');
    let overallRating = 0;
    if (paymentStars || pickupStars) {
      overallRating = (paymentStars + pickupStars) / 2;
    }
    const uniqueId = uuid();
    const ratingObj = {
      paymentRating: paymentStars,
      pickupRating: pickupStars,
      conditionRating: null,
      ownershipRating: null,
      salesRating: null,
      overallRating: overallRating?.toFixed(2),
      comments: ratingComments ? ratingComments : 'No comments were left by the reviewer.',
      dealershipId: buyerDealership?.id,
      dealershipName: buyerDealership?.dealershipName,
      reviewerDealershipName: sellerDealership?.dealershipName,
      vehicleId: vehicle.id,
      vehicleName: vehicle.details.year + ' ' + vehicle.details.make + ' ' + vehicle.details.model,
      date: Timestamp.now(),
      ratingType: 'buyer',
      userId: user?.uid,
      reviewerEmail: currentUser.email,
      emailToSend: buyerDealership?.primaryContactEmail,
      // prettier-ignore
      sendEmail: (paymentStars < 3 || pickupStars < 3) && isChecked ? true : false,
      uniqueId: uniqueId
    };
    const ratingString = uniqueId + '+' + overallRating.toFixed(2);
    await addDoc(ratingRef, ratingObj);
    await updateDealership(buyerDealership?.id as string, {
      buyerRatings: arrayUnion(ratingString)
    });
    setIsLoading(false);
    // handleCloseRatingModal();
  };

  const handleFinalizeSale = async () => {
    setIsLoading(true);
    const docRef = doc(firestore, `vehicles/${vehicle.id}`);
    await updateDoc(docRef, {
      'auction.postAuctionStatus': 'delivered',
      'auction.dateDelivered': Timestamp.now()
    });
    setIsLoading(false);
    // handleCloseFinalizeModal();
    handleCloseRatingModal();
  };

  const steps = ['Rate Your Experience', 'Move To Delivered'];
  const [activeStep, setActiveStep] = useState<number>(0);
  const [skipped, setSkipped] = useState(new Set<number>());

  const isStepOptional = (step: number) => {
    return false; // step === 0;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);

    if (activeStep === 0) {
      handleRating();
    } else {
      handleFinalizeSale();
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleSetDatePaid = async (date: any) => {
    let dateToUpdate;
    if (!date && vehicle.auction.datePaid) {
      dateToUpdate = vehicle.auction.datePaid;
    } else if (date) {
      dateToUpdate = date;
    }
    const docRef = doc(firestore, `vehicles/${vehicle.id}`);
    await updateDoc(docRef, {
      'auction.datePaid': dateToUpdate
    });
    enqueueSnackbar('Date Paid Updated', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right'
      },
      action: (key) => (
        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
          <CloseIcon />
        </MIconButton>
      )
    });
  };

  const handleSetDateSold = async (date: any) => {
    let dateToUpdate;
    if (!date && vehicle.auction.dateSold) {
      dateToUpdate = vehicle.auction.dateSold;
    } else if (date) {
      dateToUpdate = date;
    }
    const docRef = doc(firestore, `vehicles/${vehicle.id}`);
    await updateDoc(docRef, {
      'auction.dateSold': dateToUpdate
    });
    enqueueSnackbar('Date Sold Updated', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right'
      },
      action: (key) => (
        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
          <CloseIcon />
        </MIconButton>
      )
    });
  };

  const handleSetDateArrived = async (date: any) => {
    let dateToUpdate;
    if (!date && vehicle.auction.dateArrived) {
      dateToUpdate = vehicle.auction.dateArrived;
    } else if (date) {
      dateToUpdate = date;
    }
    const docRef = doc(firestore, `vehicles/${vehicle.id}`);
    await updateDoc(docRef, {
      'auction.dateArrived': dateToUpdate
    });
    enqueueSnackbar('Date Arrived Updated', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right'
      },
      action: (key) => (
        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
          <CloseIcon />
        </MIconButton>
      )
    });
  };

  const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  useEffect(() => {
    setAnchorEl(false);
  }, [popupOpened]);

  return (
    <Grid
      xs={12}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '10px',
        minHeight: '130px'
      }}
    >
      <Grid
        sx={{
          height: '100%',
          minHeight: '130px',
          padding: '6px !important',
          display: 'flex',
          borderTopLeftRadius: '10px',
          borderBottomLeftRadius: '10px',
          borderTop:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`,
          borderLeft:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`,
          borderBottom:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`
        }}
      >
        <ListItemDetails
          vehicle={vehicle}
          isSellerName={true}
          imageUrl={vehiclePhoto?.url}
          minWidth={'400px'}
          hasStatusPill={true}
        ></ListItemDetails>
      </Grid>
      {vehicle.sellerMarkAsSold && (
        <Grid
          item
          xs={2}
          sx={{
            height: '100%',
            minHeight: '130px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '6px 20px',
            minWidth: '155px',
            borderTop:
              theme.palette.mode === 'dark'
                ? `1px solid ${theme.palette.text.secondary}`
                : `1px solid ${theme.palette.divider}`,
            borderBottom:
              theme.palette.mode === 'dark'
                ? `1px solid ${theme.palette.text.secondary}`
                : `1px solid ${theme.palette.divider}`
          }}
        >
          <ListItemPersonDate label={'By Seller'} value={'Marked As Sold'}></ListItemPersonDate>
        </Grid>
      )}
      <Grid
        item
        xs={2}
        sx={{
          display: 'flex',
          minHeight: '130px',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '6px 20px',
          height: '100%',
          minWidth: '120px',
          borderTop:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`,
          borderBottom:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`
        }}
      >
        <ListItemBidPrice
          label={'sold price'}
          value={currencyFormat.format(vehicle.auction.currentPrice)}
          color={theme.palette.primary.main}
        ></ListItemBidPrice>
      </Grid>
      <Grid
        item
        xs={2}
        sx={{
          height: '100%',
          minHeight: '130px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '6px 20px',
          minWidth: '120px',
          borderTop:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`,
          borderBottom:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`
        }}
      >
        <ListItemBidPrice
          label={'floor price'}
          value={currencyFormat.format(vehicle.auction.floorPrice)}
          color={theme.palette.text.secondary}
        ></ListItemBidPrice>
      </Grid>
      <Grid
        item
        xs={2}
        sx={{
          height: '100%',
          minHeight: '130px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '6px 20px',
          minWidth: '150px',
          borderTop:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`,
          borderBottom:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`
        }}
      >
        {vehicle.buyerDealership && (
          <ListItemPersonDate
            label={'buyer'}
            value={vehicle.buyerDealership.dealershipName}
          ></ListItemPersonDate>
        )}
      </Grid>
      <Grid
        item
        xs={2}
        sx={{
          height: '100%',
          minHeight: '130px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '6px 20px',
          minWidth: '225px',
          borderTop:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`,
          borderBottom:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`
        }}
      >
        <ListItemPersonDate
          label={'date sold'}
          isEditable={true}
          value={vehicle.auction.dateSold ? vehicle.auction.dateSold : ''}
          handleSetDate={handleSetDateSold}
        ></ListItemPersonDate>
      </Grid>
      <Grid
        item
        xs={2}
        sx={{
          height: '100%',
          minHeight: '130px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '6px 20px',
          minWidth: '225px',
          borderTop:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`,
          borderBottom:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`
        }}
      >
        <ListItemPersonDate
          label={'date paid'}
          isEditable={true}
          value={vehicle.auction.datePaid}
          handleSetDate={handleSetDatePaid}
        ></ListItemPersonDate>
      </Grid>
      <Grid
        item
        xs={2}
        sx={{
          height: '100%',
          minHeight: '130px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '6px 20px',
          minWidth: '250px',
          borderTop:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`,
          borderBottom:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`
        }}
      >
        {vehicle.auction.dateArrived && (
          <ListItemPersonDate
            label={'date arrived'}
            isEditable={true}
            value={vehicle.auction.dateArrived}
            handleSetDate={handleSetDateArrived}
          ></ListItemPersonDate>
        )}
      </Grid>
      <Grid
        item
        xs={2}
        sx={{
          height: '100%',
          minHeight: '130px',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          minWidth: '285px',
          padding: '6px',
          borderTop:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`,
          borderBottom:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`
        }}
      >
        <Tooltip
          title="View/Send Bil of Sale to Buyer (Send Bill of Sale to enable Move to Delivered)"
          placement="right"
        >
          <ButtonGroup
            variant="contained"
            ref={anchorRef}
            aria-label="split button"
            sx={{ boxShadow: 'none !important' }}
          >
            <Button
              disabled={true}
              sx={{
                backgroundColor: `${theme.palette.primary.main} !important`,
                color: `${theme.palette.common.white} !important`
              }}
            >
              {/* {vehicle.billOfSaleLink ? 'Bill of Sale (Sent)' : 'Bill of Sale'} */}
              Bill of Sale / Vehicle Release
            </Button>
            <Button
              size="small"
              aria-controls={openButtonGroup ? 'split-button-menu' : undefined}
              aria-expanded={openButtonGroup ? 'true' : undefined}
              aria-label="Bill of Sale"
              aria-haspopup="menu"
              onClick={handleToggle}
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
        </Tooltip>
        <Popper
          style={{ zIndex: '99999' }}
          open={openButtonGroup}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseButtonGroup}>
                  <MenuList id="split-button-menu" autoFocusItem>
                    {options.map((option, index) => (
                      <MenuItem
                        key={option}
                        disabled={
                          option === 'Bill of Sale Sent' ||
                          option === 'Vehicle Release Sent' ||
                          (option === 'Send Vehicle Release by Email (After Bill of Sale)' &&
                            !vehicle.billOfSaleLink)
                        }
                        selected={index === selectedIndex}
                        onClick={(event) => handleButtonGroupClick(event, index)}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
      <Grid
        item
        xs={3}
        sx={{
          height: '100%',
          minHeight: '130px',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          minWidth: '225px',
          padding: '6px',
          borderTopRightRadius: '10px',
          borderBottomRightRadius: '10px',
          borderTop:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`,
          borderRight:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`,
          borderBottom:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`
        }}
      >
        <Button
          variant="contained"
          onClick={() => setOpenRatingModal(true)}
          disabled={!vehicle.billOfSaleLink}
        >
          Move To Delivered
        </Button>
        <IconButton
          aria-describedby={id}
          onClick={handleClick}
          sx={{
            backgroundColor: theme.palette.background.paper,
            padding: '5px',
            borderRadius: '5px',
            marginLeft: '10px'
          }}
        >
          <MoreVertIcon />
        </IconButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorPopup}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          sx={{ width: 220 }}
          PaperProps={{ classes: { root: styles.menuPopover } }}
        >
          {MENU_OPTIONS.map((option, index) => (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={(event: any) => handleMenuItemClick(event, index)}
              sx={{ typography: 'body2', py: 1, px: 2.5 }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Popover>
      </Grid>
      {/* prettier-ignore */}
      <Dialog
        open={openFinalizeModal}
        onClose={handleCloseFinalizeModal}
        sx={{ zIndex: '9999999' }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '20px',
            position: 'relative',
            minWidth: '500px',
            borderBottom: `1px solid ${theme.palette.divider}`
          }}
        >
          <Typography component="p" variant="h5">
            Finalize Sale & Move To Delivered
          </Typography>
          <CloseIcon
            onClick={handleCloseFinalizeModal}
            sx={{
              position: 'absolute',
              right: '10px',
              cursor: 'pointer'
            }}
          />
        </Box>
        <Box sx={{ padding: '10px 40px 40px 40px', textAlign: 'center' }}>
          <Typography>
            Are you sure you want to FINALIZE this sale? Finalizing the Vehicle will move it to Delivered Status.
          </Typography>
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0px 40px 20px 40px'
          }}
        >
          <Button
            size="large"
            variant="contained"
            className={styles.cancelButton}
            onClick={handleCloseFinalizeModal}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={isLoading}
            size="large"
            variant="contained"
            onClick={() => handleFinalizeSale()}
          >
            Confirm
          </LoadingButton>
        </Box>
      </Dialog>
      {/* prettier-ignore */}
      <Dialog
        open={openRatingModal}
        onClose={handleCloseRatingModal}
        sx={{ zIndex: '9999999' }}
      >
        <Box sx={{ width: '100%', padding: '20px' }}>
          {activeStep === 0 ? (
            <>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '20px',
                  position: 'relative',
                  minWidth: '500px',
                  borderBottom: `1px solid ${theme.palette.divider}`
                }}
              >
                <Typography component="p" variant="h5">
                  Review and Deliver
                </Typography>
                <CloseIcon
                  onClick={handleCloseRatingModal}
                  sx={{
                    position: 'absolute',
                    right: '10px',
                    cursor: 'pointer'
                  }}
                />
              </Box>
              <Stepper activeStep={activeStep} sx={{ mt: 1 }} connector={<QontoConnector/>}>
                {steps.map((label, index) => {
                  const stepProps: { completed?: boolean } = {};
                  const labelProps: {
                    optional?: React.ReactNode;
                  } = {};
                  if (isStepOptional(index)) {
                    labelProps.optional = (
                      <Typography variant="caption">Optional</Typography>
                    );
                  }
                  if (isStepSkipped(index)) {
                    stepProps.completed = false;
                  }
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              <Box sx={{ padding: '20px 40px 20px 40px', textAlign: 'center' }}>
                <Typography sx={{ fontWeight: 'bold' }} variant="h4">
                  Step 1: Rate Your Experience
                </Typography>
                <Typography>
                  Please help set positive community behaviours, by reviewing your selling experience with this dealer.
                </Typography> 
                <Box sx={{ mt: 4 }}>
                  <Typography>
                    1. Was the payment made in a timely manner? (required)
                  </Typography>
                  <StyledRating
                    name="payment-rating"
                    defaultValue={paymentStars}
                    size="large"
                    precision={0.5}
                    icon={<DirectionsCarIcon fontSize="inherit" />}
                    emptyIcon={<DirectionsCarFilledOutlinedIcon fontSize="inherit" />}
                    onChange={(event, newValue) => {
                      if (!newValue) newValue = 0;
                      setPaymentStars(newValue as number);
                      setHasRatedPayment(true);
                    }}
                  />
                </Box>
                <Box sx={{ mt: 1 }}>
                  <Typography>
                    2. Was pick-up done in a timely manner? (required)
                  </Typography>
                  <StyledRating
                    name="pickup-rating"
                    defaultValue={pickupStars}
                    size="large"
                    precision={0.5}
                    icon={<DirectionsCarIcon fontSize="inherit" />}
                    emptyIcon={<DirectionsCarFilledOutlinedIcon fontSize="inherit" />}
                    onChange={(event, newValue) => {
                      if (!newValue) newValue = 0;
                      setPickupStars(newValue as number);
                      setHasRatedPickup(true);
                    }}
                  />
                </Box>
                {(paymentStars < 3 || pickupStars < 3) && hasRatedPayment && hasRatedPickup && (
                  <FormControlLabel
                    sx={{ mt: 1, mb: 1, textAlign: 'left' }}
                    control={
                      <Checkbox checked={isChecked} onChange={handleCheckChange} />
                    }
                    label={
                    <Typography>
                       This is currently a below average rating, may we <span style={{ fontWeight: 'bold' }}>send them your email</span> to contact you to resolve this issue?
                    </Typography>
                  }
                  />
                )}
                <Box sx={{ mt: 1 }}>
                  <Typography>
                    3. Provide additional details. These details will be visible to other users and will be given to the reviewed party to help facilitate a resolution, if necessary.
                  </Typography>
                  <TextField
                    sx={{
                      fontSize: '12px',
                      width: 400,
                      mt: 1,
                      borderRadius: '10px',
                      backgroundColor:
                        theme.palette.mode === 'dark' ? 'unset' : theme.palette.common.white
                    }}
                    inputProps={{ maxLength: 150, style: { fontSize: 12 } }}
                    InputLabelProps={{ style: { fontSize: 12, color: 'grey' } }}
                    label={'Enter Comment'}
                    multiline
                    rows={3}
                    value={ratingComments}
                    onChange={(event) => {
                      setRatingComments(event.target.value);
                    }}
                    margin="normal"
                  />
                </Box>
              </Box>
            </>
          ) : (
            <>
              <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '20px',
              position: 'relative',
              minWidth: '500px',
              borderBottom: `1px solid ${theme.palette.divider}`
            }}
          >
            <Typography component="p" variant="h5">
              Review and Deliver
            </Typography>
            <CloseIcon
              onClick={handleCloseRatingModal}
              sx={{
                position: 'absolute',
                right: '10px',
                cursor: 'pointer'
              }}
            />
              </Box>
              <Stepper activeStep={activeStep} sx={{ mt: 1 }} connector={<QontoConnector/>}>
                {steps.map((label, index) => {
                  const stepProps: { completed?: boolean } = {};
                  const labelProps: {
                    optional?: React.ReactNode;
                  } = {};
                  if (isStepOptional(index)) {
                    labelProps.optional = (
                      <Typography variant="caption">Optional</Typography>
                    );
                  }
                  if (isStepSkipped(index)) {
                    stepProps.completed = false;
                  }
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              <Box sx={{ padding: '20px 40px 20px 40px', textAlign: 'center' }}>
                <Typography sx={{ fontWeight: 'bold' }} variant='h4'>
                  Step 2: Move To Delivered
                </Typography>
                <Box sx={{ padding: '20px 40px 20px 40px', textAlign: 'center' }}>
                  <Typography>
                    Are you sure you want to FINALIZE this sale? Finalizing the Vehicle will move it to Delivered Status.
                  </Typography>
                </Box>
              </Box>
            </>
          )}
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            {/* <Button
              color="inherit"
              variant="contained"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button> */}
            <Box sx={{ flex: '1 1 auto' }} />
            {isStepOptional(activeStep) && (
              <Button variant="contained" color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )}
            <LoadingButton
              variant="contained"
              onClick={handleNext}
              loading={isLoading}
              disabled={activeStep === 0 && (!hasRatedPayment || !hasRatedPickup)}
            >
              {activeStep === steps.length - 1 ? 'Move To Delivered' : 'Submit Rating'}
            </LoadingButton>
          </Box>
        </Box>
      </Dialog>
      {/* prettier-ignore */}
      <Dialog open={openCancelModal} onClose={handleCloseCancelModal} sx={{ zIndex: '9999999' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '20px',
            position: 'relative',
            minWidth: '500px',
            borderBottom: `1px solid ${theme.palette.divider}`
          }}
        >
          <Typography component="p" variant="h5">
            Cancel Sale
          </Typography>
          <CloseIcon
            onClick={handleCloseCancelModal}
            sx={{
              position: 'absolute',
              right: '10px',
              cursor: 'pointer'
            }}
          />
        </Box>
        <Box sx={{ padding: '40px' }}>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: '10px'
            }}
          ></Box>
          <Box sx={{ textAlign: 'center' }}>
            <Typography>Are you sure you want to CANCEL this sale?</Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0px 40px 20px 40px'
          }}
        >
          <Button
            size="large"
            variant="contained"
            className={styles.cancelButton}
            onClick={handleCloseCancelModal}
          >
            Cancel
          </Button>
          <Button
            size="large"
            variant="contained"
            className={styles.declineButton}
            onClick={handleCancelSale}
          >
            Confirm
          </Button>
        </Box>
      </Dialog>
    </Grid>
  );
}
