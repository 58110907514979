import { FC, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Box, Typography, IconButton, Divider } from '@mui/material';
import Scrollbar from 'components/Scrollbar';
import CloseIcon from '@mui/icons-material/Close';
import { VehicleInfo } from 'types/vehicleInfo';
import { fDateTimeSuffix } from 'components/shared/dateTimeFormat';

type Props = {
  onClose: any;
  vehicle: VehicleInfo;
};

const CaptureHistoryDrawerContent: FC<Props> = (props: Props) => {
  const theme = useTheme();
  const { onClose, vehicle } = props;
  // const [vehiclePhoto, setVehiclePhoto] = useState<any>();

  let vehiclePhoto;
  if (vehicle.photos) {
    const photos = vehicle.photos.filter(
      (v: any) => v.url && v.location && v.location.toLowerCase().includes('3/4 shot')
    );
    if (photos[0]) {
      vehiclePhoto = photos[0];
    }
  }

  const useStyles = makeStyles({
    scrollBar: {
      height: '100%'
      // backgroundColor: theme.palette.common.white
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: 'auto'
    },
    innerContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      padding: '10px'
    },
    imageContainer: {
      width: '100%',
      borderRadius: '5px',
      margin: '20px auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: '15px 5px',
      backgroundColor: theme.palette.background.neutral
    },
    headerText: {
      fontWeight: '700',
      fontSize: '16px',
      marginBottom: '20px',
      color: theme.palette.text.secondary,
      textTransform: 'uppercase'
    },
    infoRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      margin: '5px 0px'
    },
    rowText: {
      textTransform: 'capitalize'
    }
  });

  const styles = useStyles();

  return (
    <Scrollbar className={styles.scrollBar}>
      <Box className={styles.container}>
        <Box className={styles.innerContainer}>
          <Typography component="p" variant="h5">
            {vehicle?.details.year} {vehicle?.details.make}&nbsp;
            {vehicle?.details.model} {vehicle?.details.trim}
          </Typography>
          <IconButton onClick={onClose} sx={{ display: 'flex' }}>
            <CloseIcon />
          </IconButton>
        </Box>
        {vehiclePhoto && <Box component="img" alt="vehicle photo" src={vehiclePhoto.url} />}
        <Box sx={{ padding: '0 20px' }}>
          <Typography className={styles.headerText} sx={{ marginTop: '20px' }}>
            Capture History
          </Typography>
          <Box className={styles.infoRow}>
            <Typography className={styles.rowText}>Captured By:</Typography>
            <Typography className={styles.rowText} sx={{ color: theme.palette.text.secondary }}>
              {vehicle.auction.captureRepName}
            </Typography>
          </Box>
          <Box className={styles.infoRow}>
            <Typography className={styles.rowText}>Capture Date:</Typography>
            <Typography className={styles.rowText} sx={{ color: theme.palette.text.secondary }}>
              {vehicle.auction.captureDate
                ? fDateTimeSuffix(vehicle.auction.captureDate.toDate())
                : ''}
            </Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          <Typography className={styles.headerText} sx={{ marginTop: '20px' }}>
            Edit History
          </Typography>
          {vehicle.auction.captureHistory &&
            vehicle.auction.captureHistory
              .map((item: any, index: number) => {
                return (
                  <Box className={styles.infoRow} key={index}>
                    <Typography className={styles.rowText}>
                      {item.captureRepName}: Update
                    </Typography>
                    <Typography
                      className={styles.rowText}
                      sx={{ color: theme.palette.text.secondary }}
                    >
                      {fDateTimeSuffix(item.date.toDate())}
                    </Typography>
                  </Box>
                );
              })
              .reverse()}
        </Box>
      </Box>
    </Scrollbar>
  );
};

export default CaptureHistoryDrawerContent;
