import { useEffect, useState } from 'react';
import { Typography, Box, Chip, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { VehicleInfo } from 'types/vehicleInfo';
import { format } from 'date-fns';
import { CondensedAccordion } from './CondensedAccordion';
import moment from 'moment';

type Props = {
  vehicle: VehicleInfo;
};

export default function DeclarationsTab({ vehicle }: Props) {
  const theme = useTheme();
  const [totalVHRDamages, setTotalVHRDamages] = useState<number>(0);
  const [expandedAnnouncements, setExpandedAnnouncements] = useState<boolean>(true);
  const [expandedHistory, setExpandedHistory] = useState<boolean>(true);
  const [expandedSummary, setExpandedSummary] = useState<boolean>(true);
  const [expandedNotables, setExpandedNotables] = useState<boolean>(true);

  const currencyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });

  const handleChangeAnnouncements = () => {
    setExpandedAnnouncements(!expandedAnnouncements);
  };

  const handleChangeHistory = () => {
    setExpandedHistory(!expandedHistory);
  };

  const handleChangeSummary = () => {
    setExpandedSummary(!expandedSummary);
  };

  const handleChangeNotables = () => {
    setExpandedNotables(!expandedNotables);
  };

  useEffect(() => {
    let tempDamages = 0;
    if (vehicle.disclosures && vehicle.disclosures.vhrDamages) {
      vehicle.disclosures.vhrDamages.forEach((damage: any) => {
        tempDamages = tempDamages + damage.cost;
      });
    }
    if (!totalVHRDamages) {
      setTotalVHRDamages(tempDamages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const useStyles = makeStyles({
    accordion: {
      boxShadow: 'none !important',
      backgroundColor: 'transparent', // theme.palette.common.white,
      border: 'none',
      '&:not(:last-child)': {
        borderBottom: 0
      },
      '&:before': {
        display: 'none'
      }
    },
    carfaxContainer: {
      margin: '5px',
      padding: '10px 5px',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.palette.divider,
      borderRadius: '5px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    bidsText: {
      lineHeight: 1.5,
      marginLeft: '3px',
      fontSize: '12px',
      color: theme.palette.info.lighter
    },
    tableRow: {
      '&:nth-child(odd)': {
        backgroundColor: theme.palette.background.paper
      },
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      padding: '1px 10px'
    },
    contentChip: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      fontWeight: '700',
      margin: '4px',
      fontSize: '12px',
      textTransform: 'capitalize'
    }
  });

  const styles = useStyles();

  return (
    <>
      <Typography
        component="p"
        sx={{
          fontSize: '17px',
          fontWeight: '700',
          color: theme.palette.text.secondary,
          margin: '5px 0px'
        }}
      >
        Declarations and Disclosures
      </Typography>
      <CondensedAccordion
        title={'Announcements'}
        expanded={expandedAnnouncements}
        handleExpanded={handleChangeAnnouncements}
      >
        {vehicle.disclosures &&
          vehicle.disclosures.mtoReg &&
          vehicle.disclosures.mtoReg.toLowerCase() !== 'none' && (
            <Chip
              label={`MTO: ${vehicle.disclosures.mtoReg}`}
              sx={{
                border: `2px solid ${theme.palette.error.main}`,
                backgroundColor: theme.palette.error.lighter,
                color: theme.palette.error.main,
                borderRadius: '5px',
                margin: '5px'
              }}
            ></Chip>
          )}
        {/* prettier-ignore */}
        {vehicle.disclosures && vehicle.disclosures.drivable !== true && (
          <Chip
            label={vehicle.disclosures.drivable ? 'Vehicle Drivable: Yes' : 'Vehicle Drivable: No'}
            sx={{
              border: vehicle.disclosures.drivable
                ? `2px solid ${theme.palette.success.main}`
                : `2px solid ${theme.palette.error.main}`,
              backgroundColor: vehicle.disclosures.drivable
                ? theme.palette.success.lighter
                : theme.palette.error.lighter,
              color: vehicle.disclosures.drivable
                ? theme.palette.success.main
                : theme.palette.error.main,
              borderRadius: '5px',
              margin: '5px'
            }}
          ></Chip>
        )}
        {/* prettier-ignore */}
        {vehicle.disclosures && vehicle.disclosures.prevRepairs && (
          <Chip
            label={`Damages Exceeding $3000`}
            sx={{
              border: !vehicle.disclosures.prevRepairs
                ? `2px solid ${theme.palette.success.main}`
                : `2px solid ${theme.palette.error.main}`,
              backgroundColor: !vehicle.disclosures.prevRepairs
                ? theme.palette.success.lighter
                : theme.palette.error.lighter,
              color: !vehicle.disclosures.prevRepairs
                ? theme.palette.success.main
                : theme.palette.error.main,
              borderRadius: '5px',
              margin: '5px'
            }}
          ></Chip>
        )}
        {/* prettier-ignore */}
        {vehicle.disclosures && vehicle.disclosures.badgesChanged && (
          <Chip
            label={vehicle.disclosures.badgesChanged ? 'Badges Changed: Yes' : ''}
            sx={{
              border: !vehicle.disclosures.badgesChanged
                ? `2px solid ${theme.palette.success.main}`
                : `2px solid ${theme.palette.error.main}`,
              backgroundColor: !vehicle.disclosures.badgesChanged
                ? theme.palette.success.lighter
                : theme.palette.error.lighter,
              color: !vehicle.disclosures.badgesChanged
                ? theme.palette.success.main
                : theme.palette.error.main,
              borderRadius: '5px',
              margin: '5px'
            }}
          ></Chip>
        )}
        {/* prettier-ignore */}
        {vehicle.disclosures && vehicle.disclosures.inopABS && (
          <Chip
            label={vehicle.disclosures.inopABS ? 'Inoperable ABS: Yes' : ''}
            sx={{
              border: !vehicle.disclosures.inopABS
                ? `2px solid ${theme.palette.success.main}`
                : `2px solid ${theme.palette.error.main}`,
              backgroundColor: !vehicle.disclosures.inopABS
                ? theme.palette.success.lighter
                : theme.palette.error.lighter,
              color: !vehicle.disclosures.inopABS
                ? theme.palette.success.main
                : theme.palette.error.main,
              borderRadius: '5px',
              margin: '5px'
            }}
          ></Chip>
        )}
        {/* prettier-ignore */}
        {vehicle.disclosures && vehicle.disclosures.inopAirBags && (
          <Chip
            label={vehicle.disclosures.inopAirBags ? `Inoperable Airbags: Yes` : ''}
            sx={{
              border: !vehicle.disclosures.inopAirBags
                ? `2px solid ${theme.palette.success.main}`
                : `2px solid ${theme.palette.error.main}`,
              backgroundColor: !vehicle.disclosures.inopAirBags
                ? theme.palette.success.lighter
                : theme.palette.error.lighter,
              color: !vehicle.disclosures.inopAirBags
                ? theme.palette.success.main
                : theme.palette.error.main,
              borderRadius: '5px',
              margin: '5px'
            }}
          ></Chip>
        )}
        {/* prettier-ignore */}
        {vehicle.disclosures && vehicle.disclosures.inopPollution && (
          <Chip
            label={vehicle.disclosures.inopPollution ? `Inoperable Pollution Control: Yes` : ''}
            sx={{
              border: !vehicle.disclosures.inopPollution
                ? `2px solid ${theme.palette.success.main}`
                : `2px solid ${theme.palette.error.main}`,
              backgroundColor: !vehicle.disclosures.inopPollution
                ? theme.palette.success.lighter
                : theme.palette.error.lighter,
              color: !vehicle.disclosures.inopPollution
                ? theme.palette.success.main
                : theme.palette.error.main,
              borderRadius: '5px',
              margin: '5px'
            }}
          ></Chip>
        )}
        {/* prettier-ignore */}
        {vehicle.disclosures && vehicle.disclosures.manuals !== true && (
          <Chip
            label={vehicle.disclosures.manuals ? `Manuals Included: Yes` : `Manuals Included: No`}
            sx={{
              border: vehicle.disclosures.manuals
                ? `2px solid ${theme.palette.success.main}`
                : `2px solid ${theme.palette.error.main}`,
              backgroundColor: vehicle.disclosures.manuals
                ? theme.palette.success.lighter
                : theme.palette.error.lighter,
              color: vehicle.disclosures.manuals
                ? theme.palette.success.main
                : theme.palette.error.main,
              borderRadius: '5px',
              margin: '5px'
            }}
          ></Chip>
        )}
        {/* prettier-ignore */}
        {vehicle.disclosures &&
          vehicle.disclosures.mechNeedRepairs &&
          vehicle.disclosures.mechNeedRepairs.length > 0 && (
            <>
              {vehicle.disclosures.mechNeedRepairs.map((item, index) => {
                return (
                  <Chip
                    key={index}
                    label={`Needs Repair: ${item}`}
                    sx={{
                      border: `2px solid ${theme.palette.error.main}`,
                      backgroundColor: theme.palette.error.lighter,
                      color: theme.palette.error.main,
                      borderRadius: '5px',
                      margin: '5px'
                    }}
                  ></Chip>
                );
              })}
            </>
          )}
        {/* prettier-ignore */}
        {vehicle.disclosures &&
          vehicle.disclosures.electricNeedRepairs &&
          vehicle.disclosures.electricNeedRepairs.length > 0 && (
            <>
              {vehicle.disclosures.electricNeedRepairs.map((item, index) => {
                return (
                  <Chip
                    key={index}
                    label={`Needs Repair: ${item}`}
                    sx={{
                      border: `2px solid ${theme.palette.error.main}`,
                      backgroundColor: theme.palette.error.lighter,
                      color: theme.palette.error.main,
                      borderRadius: '5px',
                      margin: '5px'
                    }}
                  ></Chip>
                );
              })}
            </>
          )}
        {/* prettier-ignore */}
        {vehicle.disclosures && vehicle.disclosures.fifthWheel && (
          <Chip
            label={`Fifth Wheel Included: Yes`}
            sx={{
              border: vehicle.disclosures.fifthWheel
                ? `2px solid ${theme.palette.success.main}`
                : `2px solid ${theme.palette.error.main}`,
              backgroundColor: vehicle.disclosures.fifthWheel
                ? theme.palette.success.lighter
                : theme.palette.error.lighter,
              color: vehicle.disclosures.fifthWheel
                ? theme.palette.success.main
                : theme.palette.error.main,
              borderRadius: '5px',
              margin: '5px'
            }}
          ></Chip>
        )}
        {/* prettier-ignore */}
        {vehicle.disclosures && vehicle.disclosures.salvage && (
          <Chip
            label={vehicle.disclosures.salvage ? `Salvage Vehicle: Yes` : ''}
            sx={{
              border: !vehicle.disclosures.salvage
                ? `2px solid ${theme.palette.success.main}`
                : `2px solid ${theme.palette.error.main}`,
              backgroundColor: !vehicle.disclosures.salvage
                ? theme.palette.success.lighter
                : theme.palette.error.lighter,
              color: !vehicle.disclosures.salvage
                ? theme.palette.success.main
                : theme.palette.error.main,
              borderRadius: '5px',
              margin: '5px'
            }}
          ></Chip>
        )}
        {/* prettier-ignore */}
        {vehicle.disclosures && vehicle.disclosures.structDamage && (
          <Chip
            label={vehicle.disclosures.structDamage ? `Structural Damage: Yes` : ''}
            sx={{
              border: !vehicle.disclosures.structDamage
                ? `2px solid ${theme.palette.success.main}`
                : `2px solid ${theme.palette.error.main}`,
              backgroundColor: !vehicle.disclosures.structDamage
                ? theme.palette.success.lighter
                : theme.palette.error.lighter,
              color: !vehicle.disclosures.structDamage
                ? theme.palette.success.main
                : theme.palette.error.main,
              borderRadius: '5px',
              margin: '5px'
            }}
          ></Chip>
        )}
        {/* prettier-ignore */}
        {vehicle.disclosures && vehicle.disclosures.structRepairs && (
          <Chip
            label={
              vehicle.disclosures.structRepairs
                ? `Structural Repairs/Replacements/Alterations: Yes`
                : ''
            }
            sx={{
              border: !vehicle.disclosures.structRepairs
                ? `2px solid ${theme.palette.success.main}`
                : `2px solid ${theme.palette.error.main}`,
              backgroundColor: !vehicle.disclosures.structRepairs
                ? theme.palette.success.lighter
                : theme.palette.error.lighter,
              color: !vehicle.disclosures.structRepairs
                ? theme.palette.success.main
                : theme.palette.error.main,
              borderRadius: '5px',
              margin: '5px'
            }}
          ></Chip>
        )}
        {/* prettier-ignore */}
        {vehicle.disclosures &&
          vehicle.disclosures.repairNotes &&
          vehicle.disclosures.repairNotes !== ' ' &&
          vehicle.disclosures.repairNotes !== '' && (
            <Box
              sx={{
                border: `2px solid ${theme.palette.error.main}`,
                backgroundColor: theme.palette.error.lighter,
                color: theme.palette.error.main,
                maxWidth: '250px',
                borderRadius: '5px',
                padding: '4px 12px',
                margin: '5px',
                fontSize: '0.8125rem'
              }}
            >
              Repair Notes: {vehicle.disclosures.repairNotes}
            </Box>
          )}
        {/* prettier-ignore */}
        {vehicle.disclosures && vehicle.disclosures.soldAsIs && (
          <Chip
            label={vehicle.disclosures.soldAsIs ? `Sold As Is: Yes` : ''}
            sx={{
              border: !vehicle.disclosures.soldAsIs
                ? `2px solid ${theme.palette.success.main}`
                : `2px solid ${theme.palette.error.main}`,
              backgroundColor: !vehicle.disclosures.soldAsIs
                ? theme.palette.success.lighter
                : theme.palette.error.lighter,
              color: !vehicle.disclosures.soldAsIs
                ? theme.palette.success.main
                : theme.palette.error.main,
              borderRadius: '5px',
              margin: '5px'
            }}
          ></Chip>
        )}
        {/* prettier-ignore */}
        {vehicle.disclosures && vehicle.disclosures.outOfProvince && (
          <Chip
            label={
              vehicle.disclosures.outOfProvince
                ? `Out of Province: ${vehicle.disclosures.province}`
                : ''
            }
            sx={{
              border: !vehicle.disclosures.outOfProvince
                ? `2px solid ${theme.palette.success.main}`
                : `2px solid ${theme.palette.error.main}`,
              backgroundColor: !vehicle.disclosures.outOfProvince
                ? theme.palette.success.lighter
                : theme.palette.error.lighter,
              color: !vehicle.disclosures.outOfProvince
                ? theme.palette.success.main
                : theme.palette.error.main,
              borderRadius: '5px',
              margin: '5px'
            }}
          ></Chip>
        )}
        {/* prettier-ignore */}
        {vehicle.disclosures && vehicle.disclosures.usVehicle && (
          <Chip
            label={vehicle.disclosures.usVehicle ? `U.S.A. Vehicle: Yes` : ''}
            sx={{
              border: !vehicle.disclosures.usVehicle
                ? `2px solid ${theme.palette.success.main}`
                : `2px solid ${theme.palette.error.main}`,
              backgroundColor: !vehicle.disclosures.usVehicle
                ? theme.palette.success.lighter
                : theme.palette.error.lighter,
              color: !vehicle.disclosures.usVehicle
                ? theme.palette.success.main
                : theme.palette.error.main,
              borderRadius: '5px',
              margin: '5px'
            }}
          ></Chip>
        )}
        {/* prettier-ignore */}
        {vehicle.disclosures && vehicle.disclosures.warrantyCancelled && (
          <Chip
            label={
              vehicle.disclosures.warrantyCancelled ? `Manufacturer Warranty Cancelled: Yes` : ''
            }
            sx={{
              border: !vehicle.disclosures.warrantyCancelled
                ? `2px solid ${theme.palette.success.main}`
                : `2px solid ${theme.palette.error.main}`,
              backgroundColor: !vehicle.disclosures.warrantyCancelled
                ? theme.palette.success.lighter
                : theme.palette.error.lighter,
              color: !vehicle.disclosures.warrantyCancelled
                ? theme.palette.success.main
                : theme.palette.error.main,
              borderRadius: '5px',
              margin: '5px'
            }}
          ></Chip>
        )}
        {/* prettier-ignore */}
        {vehicle.disclosures &&
          vehicle.disclosures.odometerStatus &&
          vehicle.disclosures.odometerStatus !== ' ' &&
          vehicle.disclosures.odometerStatus.toLowerCase() !== 'none' && (
            <Chip
              label={`Odometer Status: ${vehicle.disclosures.odometerStatus}`}
              sx={{
                border: `2px solid ${theme.palette.error.main}`,
                backgroundColor: theme.palette.error.lighter,
                color: theme.palette.error.main,
                borderRadius: '5px',
                margin: '5px'
              }}
            ></Chip>
          )}
        {/* prettier-ignore */}
        {vehicle.disclosures &&
          vehicle.disclosures.wasVehicle &&
          vehicle.disclosures.wasVehicle !== ' ' &&
          vehicle.disclosures.wasVehicle.toLowerCase() !== 'none' && (
            <Chip
              label={`${vehicle.disclosures.wasVehicle}`}
              sx={{
                border: `2px solid ${theme.palette.error.main}`,
                backgroundColor: theme.palette.error.lighter,
                color: theme.palette.error.main,
                borderRadius: '5px',
                margin: '5px'
              }}
            ></Chip>
          )}
        {/* prettier-ignore */}
        {vehicle.disclosures &&
          vehicle.disclosures.wasDeclared &&
          vehicle.disclosures.wasDeclared !== ' ' &&
          vehicle.disclosures.wasDeclared.toLowerCase() !== 'none' && (
            <Chip
              label={`${vehicle.disclosures.wasDeclared}`}
              sx={{
                border: `2px solid ${theme.palette.error.main}`,
                backgroundColor: theme.palette.error.lighter,
                color: theme.palette.error.main,
                borderRadius: '5px',
                margin: '5px'
              }}
            ></Chip>
          )}
        {vehicle.disclosures &&
          vehicle.disclosures.notes &&
          vehicle.disclosures.notes !== ' ' &&
          vehicle.disclosures.notes !== '' && (
            <Box
              // label={`Notes: ${vehicle.disclosures.notes}`}
              sx={{
                border: `2px solid ${theme.palette.error.main}`,
                backgroundColor: theme.palette.error.lighter,
                color: theme.palette.error.main,
                maxWidth: '250px',
                borderRadius: '5px',
                padding: '4px 12px',
                margin: '5px',
                fontSize: '0.8125rem'
              }}
            >
              Notes: {vehicle.disclosures.notes}
            </Box>
          )}
      </CondensedAccordion>
      <CondensedAccordion
        title={'Vehicle History'}
        expanded={expandedHistory}
        handleExpanded={handleChangeHistory}
      >
        <Box
          className={styles.carfaxContainer}
          sx={{ backgroundColor: theme.palette.error.lighter }}
        >
          <Box>
            <Typography
              component="p"
              sx={{
                fontSize: '14px',
                fontWeight: 'bold',
                color: theme.palette.common.black
              }}
            >
              {vehicle.disclosures &&
              vehicle.disclosures.vhrDamages &&
              vehicle.disclosures.vhrDamages.length > 0
                ? 'Total VHR Damages'
                : vehicle.disclosures &&
                  vehicle.disclosures.VHRImageFile &&
                  vehicle.disclosures.VHRImageFile !== ' ' &&
                  vehicle.disclosures.VHRImageUrl &&
                  vehicle.disclosures.VHRImageUrl !== ' '
                ? 'VHR Image Added'
                : 'No VHR Report'}
            </Typography>
          </Box>
          <Typography
            component="p"
            sx={{
              fontSize: '14px',
              fontWeight: 'bold',
              color: theme.palette.common.black
            }}
          >
            {vehicle.disclosures &&
            vehicle.disclosures.vhrDamages &&
            vehicle.disclosures.vhrDamages.length > 0
              ? currencyFormat.format(totalVHRDamages)
              : ''}
          </Typography>
        </Box>
        {vehicle.disclosures &&
          vehicle.disclosures.vhrDamages &&
          vehicle.disclosures.vhrDamages.map((item) => {
            return (
              <Box className={styles.carfaxContainer} key={item.cost}>
                <Box>
                  <Typography
                    component="p"
                    sx={{
                      fontSize: '14px',
                      fontWeight: 'bold',
                      color: theme.palette.text.primary
                    }}
                  >
                    {item.description ? item.description : 'No Description Provided'}
                  </Typography>
                  {item.date && (
                    <Typography className={styles.bidsText} sx={{ fontSize: '12px' }} component="p">
                      {item.date ? format(item.date.toDate(), 'dd MMM yyyy') : 'No Date Provided'}
                    </Typography>
                  )}
                </Box>
                <Typography component="p" sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                  {currencyFormat.format(item.cost as number)}
                </Typography>
              </Box>
            );
          })}
        {vehicle.disclosures &&
          vehicle.disclosures.VHRImageUrl &&
          vehicle.disclosures.VHRImageUrl !== ' ' && (
            <Box
              component="img"
              sx={{
                display: 'block',
                m: 'auto',
                float: 'left',
                objectFit: 'cover',
                borderRadius: '10px'
              }}
              src={vehicle.disclosures.VHRImageUrl}
              alt="aa"
            />
          )}
      </CondensedAccordion>
      <CondensedAccordion
        title={'Inspection Summary'}
        expanded={expandedSummary}
        handleExpanded={handleChangeSummary}
      >
        <Box className={styles.tableRow}>
          <Typography sx={{ fontSize: '13px' }}>Drivable:</Typography>
          {vehicle.disclosures && vehicle.disclosures.drivable && (
            <Typography sx={{ fontSize: '13px', fontWeight: '700' }}>Yes</Typography>
          )}
          {vehicle.disclosures && !vehicle.disclosures.drivable && (
            <Typography
              sx={{ fontSize: '13px', fontWeight: '700', color: theme.palette.error.main }}
            >
              No
            </Typography>
          )}
        </Box>
        <Box className={styles.tableRow}>
          <Typography sx={{ fontSize: '13px' }}>Sold As Is:</Typography>
          {vehicle.disclosures && !vehicle.disclosures.soldAsIs && (
            <Typography sx={{ fontSize: '13px', fontWeight: '700' }}>No</Typography>
          )}
          {vehicle.disclosures && vehicle.disclosures.soldAsIs && (
            <Typography
              sx={{ fontSize: '13px', fontWeight: '700', color: theme.palette.error.main }}
            >
              Yes
            </Typography>
          )}
        </Box>
        <Box className={styles.tableRow}>
          <Typography sx={{ fontSize: '13px' }}>Structural Damage:</Typography>
          {vehicle.disclosures && vehicle.disclosures.structDamage && (
            <Typography
              sx={{ fontSize: '13px', fontWeight: '700', color: theme.palette.error.main }}
            >
              Yes
            </Typography>
          )}
          {vehicle.disclosures && !vehicle.disclosures.structDamage && (
            <Typography sx={{ fontSize: '13px', fontWeight: '700' }}>No</Typography>
          )}
        </Box>
        <Box className={styles.tableRow}>
          <Typography sx={{ fontSize: '13px' }}>Damages Exceeding $3000:</Typography>
          {vehicle.disclosures && vehicle.disclosures.prevRepairs && (
            <Typography
              sx={{ fontSize: '13px', fontWeight: '700', color: theme.palette.error.main }}
            >
              Yes
            </Typography>
          )}
          {vehicle.disclosures && !vehicle.disclosures.prevRepairs && (
            <Typography sx={{ fontSize: '13px', fontWeight: '700' }}>No</Typography>
          )}
        </Box>
        {/* <Box className={styles.tableRow}>
          <Typography sx={{ fontSize: '13px' }}>Damages Exceeding $3000:</Typography>
          {totalVHRDamages && totalVHRDamages > 3000 ? (
            <Typography
              sx={{ fontSize: '13px', fontWeight: '700', color: theme.palette.error.main }}
            >
              Yes
            </Typography>
          ) : (
            <Typography sx={{ fontSize: '13px', fontWeight: '700' }}>No</Typography>
          )}
        </Box> */}
        <Box className={styles.tableRow}>
          <Typography sx={{ fontSize: '13px' }}>
            Changes to Badges / Decals / Specifications?
          </Typography>
          {vehicle.disclosures && vehicle.disclosures.badgesChanged && (
            <Typography
              sx={{ fontSize: '13px', fontWeight: '700', color: theme.palette.error.main }}
            >
              Yes
            </Typography>
          )}
          {vehicle.disclosures && !vehicle.disclosures.badgesChanged && (
            <Typography sx={{ fontSize: '13px', fontWeight: '700' }}>No</Typography>
          )}
        </Box>
        <Box className={styles.tableRow}>
          <Typography sx={{ fontSize: '13px' }}>Anti-Lock Brakes Inoperable?</Typography>
          {vehicle.disclosures && vehicle.disclosures.inopABS && (
            <Typography
              sx={{ fontSize: '13px', fontWeight: '700', color: theme.palette.error.main }}
            >
              Yes
            </Typography>
          )}
          {vehicle.disclosures && !vehicle.disclosures.inopABS && (
            <Typography sx={{ fontSize: '13px', fontWeight: '700' }}>No</Typography>
          )}
        </Box>
        <Box className={styles.tableRow}>
          <Typography sx={{ fontSize: '13px' }}>Air Bags Missing / Inoperable?</Typography>
          {vehicle.disclosures && vehicle.disclosures.inopAirBags && (
            <Typography
              sx={{ fontSize: '13px', fontWeight: '700', color: theme.palette.error.main }}
            >
              Yes
            </Typography>
          )}
          {vehicle.disclosures && !vehicle.disclosures.inopAirBags && (
            <Typography sx={{ fontSize: '13px', fontWeight: '700' }}>No</Typography>
          )}
        </Box>
        <Box className={styles.tableRow}>
          <Typography sx={{ fontSize: '13px' }}>Pollution Control Inoperable?</Typography>
          {vehicle.disclosures && vehicle.disclosures.inopPollution && (
            <Typography
              sx={{ fontSize: '13px', fontWeight: '700', color: theme.palette.error.main }}
            >
              Yes
            </Typography>
          )}
          {vehicle.disclosures && !vehicle.disclosures.inopPollution && (
            <Typography sx={{ fontSize: '13px', fontWeight: '700' }}>No</Typography>
          )}
        </Box>
        <Box className={styles.tableRow}>
          <Typography sx={{ fontSize: '13px' }}>Number of Keys:</Typography>
          <Typography sx={{ fontSize: '13px', fontWeight: '700' }}>
            {vehicle.disclosures && vehicle.disclosures.numberOfKeys}
          </Typography>
        </Box>
        <Box className={styles.tableRow}>
          <Typography sx={{ fontSize: '13px' }}>Original Manuals:</Typography>
          {vehicle.disclosures && vehicle.disclosures.manuals && (
            <Typography sx={{ fontSize: '13px', fontWeight: '700' }}>Yes</Typography>
          )}
          {vehicle.disclosures && !vehicle.disclosures.manuals && (
            <Typography
              sx={{ fontSize: '13px', fontWeight: '700', color: theme.palette.error.main }}
            >
              No
            </Typography>
          )}
        </Box>
        {/* // -------------------------------------- */}
        <Box className={styles.tableRow}>
          <Typography sx={{ fontSize: '13px' }}>5th Wheel:</Typography>
          {vehicle.disclosures && vehicle.disclosures.fifthWheel && (
            <Typography
              sx={{ fontSize: '13px', fontWeight: '700' /* , color: theme.palette.error.main */ }}
            >
              Yes
            </Typography>
          )}
          {vehicle.disclosures && !vehicle.disclosures.fifthWheel && (
            <Typography sx={{ fontSize: '13px', fontWeight: '700' }}>No</Typography>
          )}
        </Box>
        {/* // - Moved to equioment tab ------ */}
        {/* <Box className={styles.tableRow}>
          <Typography sx={{ fontSize: '13px' }}>Third Row:</Typography>
          {vehicle.equipment && vehicle.equipment.thirdRow && (
            <Typography
              sx={{ fontSize: '13px', fontWeight: '700', color: theme.palette.error.main }}
            >
              Yes
            </Typography>
          )}
          {vehicle.equipment && !vehicle.equipment.thirdRow && (
            <Typography sx={{ fontSize: '13px', fontWeight: '700' }}>No</Typography>
          )}
        </Box> */}
        {/* // ----------------------------------------------------------------- */}
        <Box className={styles.tableRow}>
          <Typography sx={{ fontSize: '13px' }}>Salvage:</Typography>
          {vehicle.disclosures && vehicle.disclosures.salvage && (
            <Typography
              sx={{ fontSize: '13px', fontWeight: '700', color: theme.palette.error.main }}
            >
              Yes
            </Typography>
          )}
          {vehicle.disclosures && !vehicle.disclosures.salvage && (
            <Typography sx={{ fontSize: '13px', fontWeight: '700' }}>No</Typography>
          )}
        </Box>

        {/* prettier-ignore */}
        {vehicle.disclosures &&
          vehicle.disclosures.odometerReading &&
          vehicle.disclosures.odometerReading !== '' &&
          vehicle.disclosures.odometerReading !== ' ' && (
            <Box className={styles.tableRow}>
              <Typography sx={{ fontSize: '13px' }}>Odometer Reading:</Typography>
              <Typography sx={{ fontSize: '13px', fontWeight: '700' }}>
                {vehicle.disclosures.odometerReading}&nbsp;{vehicle.details.mileageType}
              </Typography>
            </Box>
          )}

        {/* prettier-ignore */}
        {vehicle.disclosures &&
          vehicle.disclosures.odometerStatus &&
          vehicle.disclosures.odometerStatus !== ' ' &&
          vehicle.disclosures.odometerStatus.toLowerCase() !== 'none' && (
            <Box className={styles.tableRow}>
              <Typography sx={{ fontSize: '13px' }}>Odometer Reading:</Typography>
              {vehicle.disclosures.odometerRadioChoice &&
                vehicle.disclosures.odometerRadioChoice !== '' && (
                  <Typography
                    sx={{
                      fontSize: '13px',
                      fontWeight: '700',
                      maxWidth: '275px',
                      textAlign: 'end'
                    }}
                  >
                    {vehicle.disclosures.odometerRadioChoice === 'Option 1'
                      ? `The dealer can not determine the total distance 
                      that the vehicle has been driven but can
                      determine the distance that the vehicle has
                      been driven as of some past date. The total
                      distance that the vehicle has been driven is
                      believed to be higher than the previously
                      recorded distance.`
                      : `True Kilometers/Mileage Unknown: The dealer can determine neither the total
                      distance that the vehicle has been driven, nor the
                      distance that the vehicle has been driven as of
                      some past date. The total distance that the
                      vehicle has been driven is unknown and may be
                      substantially higher that the reading shown on the
                      odometer.`}
                  </Typography>
                )}
            </Box>
          )}

        {vehicle.disclosures && vehicle.disclosures.odometerReadingDate && (
          <Box className={styles.tableRow}>
            <Typography sx={{ fontSize: '13px' }}>Odometer Reading Date:</Typography>
            <Typography sx={{ fontSize: '13px', fontWeight: '700' }}>
              {moment(vehicle.disclosures.odometerReadingDate?.toDate()).format(
                'YYYY-MM-DD hh:mm a'
              )}
            </Typography>
          </Box>
        )}

        <Box className={styles.tableRow}>
          {vehicle.disclosures &&
            vehicle.disclosures.mechNeedRepairs &&
            vehicle.disclosures.mechNeedRepairs.length > 0 && (
              <Typography sx={{ fontSize: '13px' }}>Mechanical Items Needing Repair:</Typography>
            )}
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            {vehicle.disclosures &&
              vehicle.disclosures.mechNeedRepairs &&
              vehicle.disclosures.mechNeedRepairs.length > 0 &&
              vehicle.disclosures.mechNeedRepairs.map((item) => {
                return (
                  <Typography key={item} sx={{ fontSize: '13px', fontWeight: '700' }}>
                    {item}
                  </Typography>
                );
              })}
          </Box>
        </Box>
        <Box className={styles.tableRow}>
          {vehicle.disclosures &&
            vehicle.disclosures.electricNeedRepairs &&
            vehicle.disclosures.electricNeedRepairs.length > 0 && (
              <Typography sx={{ fontSize: '13px' }}>Electrical Items Needing Repair:</Typography>
            )}
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            {vehicle.disclosures &&
              vehicle.disclosures.electricNeedRepairs &&
              vehicle.disclosures.electricNeedRepairs.length > 0 &&
              vehicle.disclosures.electricNeedRepairs.map((item) => {
                return (
                  <Typography key={item} sx={{ fontSize: '13px', fontWeight: '700' }}>
                    {item}
                  </Typography>
                );
              })}
          </Box>
        </Box>
      </CondensedAccordion>

      <CondensedAccordion
        title={'Notables'}
        expanded={expandedNotables}
        handleExpanded={handleChangeNotables}
      >
        <Chip
          className={styles.contentChip}
          label={
            vehicle.details && vehicle.details.transmission ? vehicle.details.transmission : ''
          }
        ></Chip>
        <Chip
          className={styles.contentChip}
          label={
            vehicle.equipment && vehicle.equipment.clothLeather
              ? vehicle.equipment.clothLeather
              : ''
          }
        ></Chip>
        <Chip
          className={styles.contentChip}
          label={vehicle.equipment && vehicle.equipment.roof ? vehicle.equipment.roof : ''}
        ></Chip>
        <Chip
          className={styles.contentChip}
          label={vehicle.details && vehicle.details.driveTrain ? vehicle.details.driveTrain : ''}
        ></Chip>
        {/* prettier-ignore */}
        <Chip
            className={styles.contentChip}
            label={`Tires: ${
                vehicle.equipment && vehicle.equipment.sizeTires
                ? vehicle.equipment.sizeTires
                : ''}`
            }
          ></Chip>
        {vehicle.equipment && vehicle.equipment.navigation && (
          <Chip className={styles.contentChip} label={'Navi'}></Chip>
        )}
        {vehicle.equipment && vehicle.equipment.thirdRow && (
          <Chip className={styles.contentChip} label={'Third Row'}></Chip>
        )}
        {/* prettier-ignore */}
        <Chip className={styles.contentChip} label={vehicle.details.fuelType}></Chip>
        {/* prettier-ignore */}
        {vehicle.equipment && vehicle.equipment.tpms && (
          <Chip className={styles.contentChip} label={'TPMS'}></Chip>
        )}
        {vehicle.equipment && !vehicle.equipment.tpms && (
          <Chip className={styles.contentChip} label={'No TPMS'}></Chip>
        )}
        {vehicle.equipment.powerSeat && (
          <Chip className={styles.contentChip} label={'Power Seats'}></Chip>
        )}
      </CondensedAccordion>
    </>
  );
}
