import { useState, useEffect, FC } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import {
  Box,
  Card,
  Input,
  Button,
  Typography,
  CardHeader,
  InputProps,
  Slider as MuiSlider,
  Stack
} from '@mui/material';
// utils
import { doc, updateDoc } from 'firebase/firestore';
import { useFirestore, useFirestoreDocData } from 'reactfire';
import { fCurrency } from 'utils/formatNumber';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  backgroundColor: theme.palette.background.neutral
}));

// ----------------------------------------------------------------------

type Props = {
  auctionId: string;
};

const CurrentAuction: FC<Props> = (props: Props) => {
  const [autoWidth, setAutoWidth] = useState(24);
  const [amount, setAmount] = useState(0);
  const { auctionId } = props;

  const auctionsQuery = doc(useFirestore(), `auctions/${auctionId}`);

  const { data: auction } = useFirestoreDocData(auctionsQuery, {
    idField: 'id'
  });

  useEffect(() => {
    if (amount) {
      handleAutoWidth();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount]);

  const firestore = useFirestore();

  const handleBid = async () => {
    const ref = doc(firestore, 'auctions', auction.id);
    await updateDoc(ref, { currentBid: amount });
    setAmount(0);
  };

  const handleAutoWidth = () => {
    const getNumberLength = amount.toString().length;
    setAutoWidth(getNumberLength * 22);
  };

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setAmount(+newValue);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(+event.target.value);
  };

  return (
    <>
      {auction && (
        <RootStyle>
          <CardHeader title={`Current bid ${fCurrency(auction.currentBid)}`} />
          <Box sx={{ p: 3 }}>
            <Stack spacing={3}>
              <InputAmount
                bidIncrements={auction.bidIncrements}
                onChange={handleInputChange}
                autoWidth={autoWidth}
                amount={amount}
              />

              <MuiSlider
                value={amount}
                valueLabelDisplay="auto"
                step={auction.bidIncrements}
                marks
                min={auction.currentBid}
                max={auction.maxBid + auction.bidIncrements}
                onChange={handleSliderChange}
              />

              <Button
                variant="contained"
                size="large"
                disabled={amount <= auction.currentBid}
                onClick={handleBid}
              >
                Bid
              </Button>
            </Stack>
          </Box>
        </RootStyle>
      )}
    </>
  );
};
export default CurrentAuction;
// ----------------------------------------------------------------------

type AmountProps = number | string | Array<number | string>;

interface InputAmountProps extends InputProps {
  autoWidth: number;
  bidIncrements: number;
  amount: AmountProps;
}

const InputAmount = (props: InputAmountProps) => {
  const { bidIncrements, autoWidth, amount, onBlur, onChange, sx, ...other } = props;

  return (
    <Stack direction="row" justifyContent="center" spacing={1} sx={sx}>
      {amount > 0 ? (
        <>
          <Typography variant="h5">$</Typography>
          <Input
            disableUnderline
            size="small"
            value={amount}
            onChange={onChange}
            onBlur={onBlur}
            inputProps={{ step: bidIncrements, type: 'number' }}
            sx={{
              typography: 'h3',
              '& input': {
                p: 0,
                textAlign: 'center',
                width: autoWidth
              }
            }}
            {...other}
          />
        </>
      ) : (
        <Typography variant="h5">Select your bid</Typography>
      )}
    </Stack>
  );
};
