import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { Dealership } from 'types/dealership';
import { VehicleInfo } from 'types/vehicleInfo';
import Logo from '../../assets/LogoText-FullColour.png';

interface Props {
  vehicle: VehicleInfo;
  buyerDealership: Dealership;
  sellerDealership: Dealership;
  sellerName: string;
  sellerEmail: string;
  sellerPhone: string;
  buyerName: string;
  buyerEmail: string;
  buyerPhone: string;
}

const styles = StyleSheet.create({
  image: {
    width: 200
  },
  signature: {
    width: 50,
    height: 'auto',
    marginTop: 10,
    marginLeft: '30px'
  },
  viewer: {
    width: '800px',
    height: 'auto',
    minHeight: '100vh'
  },
  page: {
    backgroundColor: 'white',
    color: 'black',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: 20
  },
  title: {
    marginTop: 15,
    marginBottom: 10,
    fontSize: '16px',
    fontWeight: 'ultrabold',
    color: 'black'
  },
  subtitle: {
    marginTop: 15,
    marginLeft: '10px',
    fontSize: '14px',
    fontWeight: 'ultrabold',
    color: 'black'
  },
  container: {
    flexDirection: 'column',
    width: '100%',
    border: '1px solid black'
  },
  row: {
    flexDirection: 'row',
    width: '100%'
  },
  topBorderRow: {
    flexDirection: 'row',
    width: '100%',
    borderTop: '1px solid black'
  },
  columnLeft: {
    flexDirection: 'column',
    width: '33.3%',
    borderRight: '1px solid black',
    paddingBottom: '5px'
  },
  columnCenter: {
    flexDirection: 'column',
    width: '33.3%',
    borderRight: '1px solid black',
    paddingBottom: '5px'
  },
  columnRight: {
    flexDirection: 'column',
    width: '33.3%',
    paddingBottom: '5px'
  },
  header: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    borderBottom: '1px solid black',
    padding: '2px'
  },
  vhrHeader: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    borderBottom: '1px solid black',
    borderTop: '1px solid black',
    padding: '2px'
  },
  headerText: {
    fontSize: '12px',
    fontWeight: 'ultrabold'
  },
  bodyText: {
    fontSize: '10px',
    fontWeight: 'ultralight',
    marginLeft: '10px',
    marginRight: '10px',
    marginTop: '5px'
  },
  sellerBuyerText: {
    fontSize: '10px',
    fontWeight: 'ultralight',
    marginLeft: '10px',
    marginRight: '10px',
    marginTop: '5px',
    textTransform: 'capitalize'
  },
  repText: {
    marginTop: '15px',
    fontSize: '12px',
    fontWeight: 'ultrabold',
    marginLeft: '10px'
  },
  signatureBox: {
    width: '90%',
    height: '50px',
    borderBottom: '3px solid black',
    marginLeft: '10px'
  },
  underSignature: {
    marginLeft: '10px',
    fontSize: '10px',
    fontWeight: 'ultrabold',
    marginTop: '5px'
  },
  innerTitle: {
    borderTop: '1px solid black',
    borderBottom: '1px solid black',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '5px',
    marginBottom: '5px'
  },
  listItem: {
    width: '95%',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  boldBodyText: {
    fontSize: '10px',
    fontWeight: 900,
    marginLeft: '10px',
    marginTop: '25px'
  },
  spaceBox: {
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  bottomBox: {
    position: 'absolute',
    bottom: '5px'
  },
  paragraphRow: {
    marginBottom: '10px'
  },
  logoHeader: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  addressContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end'
  }
});

export default function VehicleReleaseDoc({
  vehicle,
  buyerDealership,
  sellerDealership,
  sellerName,
  sellerEmail,
  sellerPhone,
  buyerName,
  buyerEmail,
  buyerPhone
}: Props) {
  const currencyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });

  return (
    <Document>
      <Page wrap style={styles.page}>
        <View style={styles.logoHeader}>
          <Image style={styles.image} src={Logo} />
        </View>
        <Text style={styles.title}>VEHICLE RELEASE FORM</Text>
        <View style={styles.container}>
          {/* body row */}
          <View style={styles.row}>
            <View style={styles.columnLeft}>
              <View style={styles.header}>
                <Text style={styles.headerText}>SELLER DETAILS</Text>
              </View>
              {/* prettier-ignore */}
              <Text style={styles.sellerBuyerText}>O/A Company Name: {sellerDealership.dealershipName}</Text>
              <Text style={styles.sellerBuyerText}>Address: {sellerDealership.streetAddress}</Text>
              <Text style={styles.sellerBuyerText}>City: {sellerDealership.city}</Text>
              {/* prettier-ignore */}
              <Text style={styles.sellerBuyerText}>Postal/ZIP Code: {sellerDealership.postalCode}</Text>
              {/* prettier-ignore */}
              <Text style={styles.sellerBuyerText}>Province/State: {sellerDealership.province}</Text>
              <Text style={styles.sellerBuyerText}>Country: {sellerDealership.country}</Text>
              <Text style={styles.sellerBuyerText}>Dealership Phone: {sellerDealership.phone}</Text>
              <Text style={styles.sellerBuyerText}>Seller Name: {sellerName}</Text>
              <Text style={styles.sellerBuyerText}>Seller Email: {sellerEmail}</Text>
              <Text style={styles.sellerBuyerText}>Seller Phone: {sellerPhone}</Text>
              <Text style={styles.repText}>PICKUP LOCATION</Text>
              {/* prettier-ignore */}
              <Text style={styles.sellerBuyerText}>Location Name: {vehicle.auction.pickUpLocation.locationName}</Text>
              {/* prettier-ignore */}
              <Text style={styles.sellerBuyerText}>Address: {vehicle.auction.pickUpLocation.locationAddress}</Text>
              {/* prettier-ignore */}
              <Text style={styles.sellerBuyerText}>City: {vehicle.auction.pickUpLocation.locationCity}</Text>
              {/* prettier-ignore */}
              <Text style={styles.sellerBuyerText}>Postal/ZIP Code: {vehicle.auction.pickUpLocation.locationPostalCode}</Text>
              {/* prettier-ignore */}
              <Text style={styles.sellerBuyerText}>Province/State: {vehicle.auction.pickUpLocation.locationProvince}</Text>
              {/* prettier-ignore */}
              <Text style={styles.sellerBuyerText}>Country: {vehicle.auction.pickUpLocation.locationCountry}</Text>
              {/* prettier-ignore */}
              <Text style={styles.sellerBuyerText}>Phone: {vehicle.auction.pickUpLocation.locationPhone}</Text>
              {/* prettier-ignore */}
              <Text style={styles.sellerBuyerText}>
                Notes: {vehicle.auction.pickUpLocation.locationNotes ? vehicle.auction.pickUpLocation.locationNotes : 'No Notes'}
              </Text>
            </View>
            <View style={styles.columnCenter}>
              <View style={styles.header}>
                <Text style={styles.headerText}>BUYER DETAILS</Text>
              </View>
              {/* prettier-ignore */}
              <Text style={styles.sellerBuyerText}>O/A Company Name: {buyerDealership.dealershipName}</Text>
              <Text style={styles.sellerBuyerText}>Address: {buyerDealership.streetAddress}</Text>
              <Text style={styles.sellerBuyerText}>City: {buyerDealership.city}</Text>
              {/* prettier-ignore */}
              <Text style={styles.sellerBuyerText}>Postal/ZIP Code: {buyerDealership.postalCode}</Text>
              {/* prettier-ignore */}
              <Text style={styles.sellerBuyerText}>Province/State: {buyerDealership.province}</Text>
              <Text style={styles.sellerBuyerText}>Country: {buyerDealership.country}</Text>
              <Text style={styles.sellerBuyerText}>Dealership Phone: {buyerDealership.phone}</Text>
              <Text style={styles.sellerBuyerText}>Buyer Name: {buyerName}</Text>
              <Text style={styles.sellerBuyerText}>Buyer Email: {buyerEmail}</Text>
              <Text style={styles.sellerBuyerText}>Buyer Phone: {buyerPhone}</Text>
            </View>
            <View style={styles.columnRight}>
              <View style={styles.header}>
                <Text style={styles.headerText}>VEHICLE INFORMATION</Text>
              </View>
              {/* prettier-ignore */}
              <Text style={styles.sellerBuyerText}>Year: {vehicle.details.year}</Text>
              {/* prettier-ignore */}
              <Text style={styles.sellerBuyerText}>Make: {vehicle.details.make}</Text>
              {/* prettier-ignore */}
              <Text style={styles.sellerBuyerText}>Model: {vehicle.details.model}</Text>
              {/* prettier-ignore */}
              <Text style={styles.sellerBuyerText}>VIN: {vehicle.details.vin}</Text>
              {/* prettier-ignore */}
              <Text style={styles.sellerBuyerText}>Mileage: {vehicle.details.mileage}&nbsp;{vehicle.details.mileageType}</Text>
              {/* prettier-ignore */}
              <Text style={styles.sellerBuyerText}>Colour: {vehicle.details.extColor}</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
}
