import { Chip, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState, useEffect } from 'react';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const MoreItemsIndicator: React.FC = () => {
  const theme = useTheme();
  const [showArrowUp, setShowArrowUp] = useState<boolean>(false);

  const handleScroll = () => {
    const isScrolledToBottom =
      window.innerHeight + window.scrollY >= document.body.scrollHeight - 20;
    setShowArrowUp(isScrolledToBottom);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const useStyles = makeStyles({
    filterChip: {
      margin: '5px 10px',
      color:
        theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.info.lighter,
      fontWeight: '700',
      '&.active': {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.lighter
      }
    }
  });
  const styles = useStyles();

  return (
    <div style={{ position: 'fixed', bottom: 0, width: '100%', textAlign: 'center' }}>
      <Chip
        label={showArrowUp ? 'Items Above' : 'More Items'}
        className={styles.filterChip}
        sx={{
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.error.main,
          cursor: 'pointer'
        }}
        icon={showArrowUp ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
      />
    </div>
  );
};

export default MoreItemsIndicator;
