import {
  Grid,
  BoxProps,
  Button,
  IconButton,
  Box,
  Typography,
  MenuItem,
  TextField,
  Dialog,
  Popover
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ListItemDetails from '../../shared/ListItemDetails';
import ListItemBidPrice from '../../shared/ListItemPriceBid';
import ListItemPersonDate from '../../shared/ListItemPersonDate';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ChangeEvent, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { makeStyles } from '@mui/styles';
import SharedDrawer from '../../shared/SharedDrawer';
import NegotiationDrawerContent from 'components/shared/NegotiationDrawerContent';
import { VehicleInfo, VehiclePhotos } from 'types/vehicleInfo';
import { arrayUnion, doc, Timestamp, updateDoc } from 'firebase/firestore';
import { useFirestore } from 'reactfire';
import useAuth from 'hooks/useAuth';
import { NumericFormat } from 'react-number-format';
import { Link as RouterLink } from 'react-router-dom';
import { Dealership } from 'types/dealership';

interface Props extends BoxProps {
  vehicle: VehicleInfo;
  dealershipName: string;
  initialOpen: boolean;
  sendVehicleToParent: (index: number, vehicle: VehicleInfo) => void;
}

const MENU_OPTIONS = [
  { label: 'Vehicle History', linkTo: '#' },
  { label: 'Bidding History', linkTo: '#' },
  { label: 'View VDP', linkTo: '#' }
];

export default function PendingListItemBuy({
  vehicle,
  initialOpen,
  dealershipName,
  sendVehicleToParent
}: Props) {
  const theme = useTheme();
  const firestore = useFirestore();
  const { currentUser } = useAuth();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openNegotiationDrawer, setOpenNegotiationDrawer] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedAction, setSelectedAction] = useState('');
  const [counterOfferAmount, setCounterOfferAmount] = useState<number>();
  const [formattedCounterOfferAmount, setFormattedCounterOfferAmount] = useState<number | string>();
  // const [vehiclePhoto, setVehiclePhoto] = useState<VehiclePhotos>();
  const [anchorPopup, setAnchorPopup] = useState<HTMLButtonElement | null>(null);
  const [popupOpened, setPopupOpened] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [openBidHistory, setOpenBidHistory] = useState(false);
  const [openVehicleHistory, setOpenVehicleHistory] = useState(false);

  const open = Boolean(anchorPopup);
  const id = open ? 'simple-popover' : undefined;

  const currencyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });

  let vehiclePhoto;
  if (vehicle.photos) {
    const photos = vehicle.photos.filter(
      (v: any) => v.url && v.location && v.location.toLowerCase().includes('3/4 shot')
    );
    if (photos[0]) {
      vehiclePhoto = photos[0];
    }
  }

  const handleClose = () => {
    setAnchorPopup(null);
    setPopupOpened(false);
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setSelectedIndex(index);
    sendVehicleToParent(index, vehicle);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorPopup(event.currentTarget);
    setPopupOpened(true);
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const handleCloseBidHistory = () => {
    setOpenBidHistory(!openBidHistory);
  };

  const handleCloseVehicleHistory = () => {
    setOpenVehicleHistory(!openVehicleHistory);
  };

  const handleCloseModal = () => {
    setOpenModal(!openModal);
  };

  const handleNegotiationClick = () => {
    setOpenNegotiationDrawer(!openNegotiationDrawer);
  };

  const handleSelectedAction = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedAction(event.target.value);
  };

  const handleAcceptAction = async () => {
    const docRef = doc(firestore, `vehicles/${vehicle.id}`);
    if (selectedAction.toLowerCase() === 'accept bid') {
      await updateDoc(docRef, {
        'auction.postAuctionStatus': vehicle.auction.isInStock ? 'arrived' : 'sold',
        'auction.currentPrice': vehicle.auction.negotiationPrice
          ? vehicle.auction.negotiationPrice
          : vehicle.auction.currentPrice,
        'auction.dateSold': Timestamp.now()
      });
      setOpenModal(false);
    } else if (selectedAction.toLowerCase() === 'counter offer') {
      await updateDoc(docRef, {
        'auction.negotiationStatusBuyer': 'awaiting response',
        'auction.negotiationStatusSeller': 'response required',
        'auction.negotiationPrice': counterOfferAmount,
        'auction.negotiationHistory': arrayUnion({
          user: `${currentUser.firstName} ${currentUser.lastName}`,
          dealership: dealershipName,
          price: counterOfferAmount,
          dateTime: Timestamp.now(),
          type: 'Counter'
        })
      });
      setOpenModal(false);
    } else {
      await updateDoc(docRef, {
        'auction.postAuctionStatus': 'no sale',
        'auction.reasonLost': 'You Declined'
      });
      setOpenModal(false);
    }
  };

  const useStyles = makeStyles({
    menuPopover: {
      boxShadow: '0 0 2px 0 rgba(139, 148, 156, 0.24),0 10px 20px -4px rgba(139, 148, 156, 0.24)',
      paddingTop: '20px',
      paddingBottom: '20px',
      '@global': {
        '.MuiMenuItem-root': {
          paddingBottom: '6px'
        }
      }
    },
    cancelButton: {
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.common.black,
      '&:hover': {
        backgroundColor: theme.palette.grey[300]
      }
    }
  });

  const styles = useStyles();

  return (
    <Grid
      xs={12}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '10px',
        minHeight: '130px'
      }}
    >
      <Grid
        sx={{
          height: '100%',
          minHeight: '130px',
          padding: '6px !important',
          display: 'flex',
          borderTopLeftRadius: '10px',
          borderBottomLeftRadius: '10px',
          borderTop:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`,
          borderLeft:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`,
          borderBottom:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`
        }}
      >
        <ListItemDetails
          vehicle={vehicle}
          isSellerName={true}
          hasStatusPill={true}
          imageUrl={vehiclePhoto?.url}
          minWidth="400px"
        ></ListItemDetails>
      </Grid>
      {vehicle.sellerMarkAsSold && (
        <Grid
          item
          xs={3}
          sx={{
            height: '100%',
            minHeight: '130px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '6px 20px',
            minWidth: '155px',
            borderTop:
              theme.palette.mode === 'dark'
                ? `1px solid ${theme.palette.text.secondary}`
                : `1px solid ${theme.palette.divider}`,
            borderBottom:
              theme.palette.mode === 'dark'
                ? `1px solid ${theme.palette.text.secondary}`
                : `1px solid ${theme.palette.divider}`
          }}
        >
          <ListItemPersonDate label={'By Seller'} value={'Marked As Sold'}></ListItemPersonDate>
        </Grid>
      )}
      <Grid
        item
        xs={3}
        sx={{
          display: 'flex',
          minHeight: '130px',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '6px 20px',
          height: '100%',
          minWidth: '150px',
          borderTop:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`,
          borderBottom:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`
        }}
      >
        <ListItemBidPrice
          label={'closing bid'}
          value={
            vehicle.auction.currentPrice
              ? currencyFormat.format(vehicle.auction.currentPrice)
              : 'None'
          }
          color={theme.palette.primary.light}
        ></ListItemBidPrice>
      </Grid>
      <Grid
        item
        xs={3}
        sx={{
          height: '100%',
          minHeight: '130px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '6px 20px',
          minWidth: '150px',
          borderTop:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`,
          borderBottom:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`
        }}
      >
        {vehicle.auction.negotiationPrice ? (
          <ListItemBidPrice
            label={`Offer: ${
              vehicle.auction.negotiationStatusBuyer &&
              vehicle.auction.negotiationStatusBuyer.toLowerCase() === 'awaiting response'
                ? 'You'
                : vehicle.auction.sellerName
            }`}
            value={currencyFormat.format(vehicle.auction.negotiationPrice)}
            color={theme.palette.chart.violet[2]}
          ></ListItemBidPrice>
        ) : (
          <ListItemBidPrice
            label={`Offer: ${
              vehicle.auction.negotiationStatusBuyer &&
              vehicle.auction.negotiationStatusBuyer.toLowerCase() === 'awaiting response'
                ? 'You'
                : vehicle.auction.sellerName
            }`}
            value={
              vehicle.auction.currentPrice
                ? currencyFormat.format(vehicle.auction.currentPrice)
                : 'None'
            }
            color={theme.palette.chart.violet[2]}
          ></ListItemBidPrice>
        )}
      </Grid>
      <Grid
        item
        xs={3}
        sx={{
          height: '100%',
          minHeight: '130px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '6px 20px',
          minWidth: '150px',
          borderTop:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`,
          borderBottom:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`
        }}
      >
        <ListItemPersonDate
          label={'Days Pending'}
          value={
            vehicle.auction.daysPending ? vehicle.auction.daysPending.toString() : '0 Days Pending'
          }
        ></ListItemPersonDate>
      </Grid>
      <Grid
        id="postAuctionBuy3"
        item
        xs={3}
        sx={{
          height: '100%',
          minHeight: '130px',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          minWidth: '250px',
          padding: '6px',
          borderTopRightRadius: '10px',
          borderBottomRightRadius: '10px',
          borderTop:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`,
          borderRight:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`,
          borderBottom:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`
        }}
      >
        {vehicle.auction.negotiationStatusBuyer &&
          vehicle.auction.negotiationStatusBuyer.toLowerCase() === 'action required' && (
            <Button
              onClick={() => setOpenModal(!openModal)}
              variant="contained"
              sx={{
                backgroundColor: theme.palette.error.main,
                '&:hover': {
                  backgroundColor: theme.palette.error.dark
                }
              }}
            >
              Action Required
            </Button>
          )}
        {vehicle.auction.negotiationStatusBuyer &&
          vehicle.auction.negotiationStatusBuyer.toLowerCase() === 'awaiting response' && (
            <>
              {vehicle.auction.negotiationHistory &&
              vehicle.auction.negotiationHistory.length > 0 &&
              vehicle.auction.negotiationHistory[0].type !== 'Closing Bid' ? (
                <Button
                  onClick={handleNegotiationClick}
                  // disabled
                  variant="contained"
                  sx={{
                    backgroundColor: theme.palette.chart.violet[2]
                  }}
                >
                  Awaiting Response
                </Button>
              ) : (
                <Typography sx={{ fontSize: '14px', color: theme.palette.error.main }}>
                  Waiting for Seller to Review Offer
                </Typography>
              )}
            </>
          )}
        {vehicle.auction.negotiationStatusBuyer &&
          vehicle.auction.negotiationStatusBuyer.toLowerCase() === 'response required' && (
            <Button
              onClick={handleNegotiationClick}
              variant="contained"
              sx={{
                backgroundColor: theme.palette.chart.violet[3]
              }}
            >
              Response Required
            </Button>
          )}
        <IconButton
          aria-describedby={'icon-button'}
          onClick={handleClick}
          sx={{
            backgroundColor: theme.palette.background.paper,
            padding: '5px',
            borderRadius: '5px',
            marginLeft: '10px'
          }}
        >
          <MoreVertIcon />
        </IconButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorPopup}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          sx={{ width: 220 }}
          PaperProps={{ classes: { root: styles.menuPopover } }}
        >
          {MENU_OPTIONS.map((option, index) => (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={(event: any) => handleMenuItemClick(event, index)}
              sx={{ typography: 'body2', py: 1, px: 2.5 }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Popover>
      </Grid>
      {/* {vehicle && (
        <AuctionDrawer
          tabIndex={0}
          isOpenSidebar={openDrawer}
          onCloseSidebar={handleCloseDrawer}
          vehicle={vehicle}
        />
      )} */}
      {/* <SharedDrawer
        isOpenSidebar={openVehicleHistory}
        onCloseSidebar={handleCloseVehicleHistory}
        drawerContent={
          <HistoryDrawerContent
            isSeller={false}
            onClose={handleCloseVehicleHistory}
            vehicle={vehicle}
            index={0}
          />
        }
      /> */}
      {/* <SharedDrawer
        isOpenSidebar={openBidHistory}
        onCloseSidebar={handleCloseBidHistory}
        drawerContent={
          <HistoryDrawerContent
            isSeller={false}
            onClose={handleCloseBidHistory}
            vehicle={vehicle}
            index={1}
          />
        }
      /> */}
      <SharedDrawer
        isOpenSidebar={openNegotiationDrawer}
        onCloseSidebar={handleNegotiationClick}
        drawerContent={
          <NegotiationDrawerContent
            isBuyer={true}
            onClose={handleNegotiationClick}
            vehicle={vehicle}
          />
        }
      />
      <Dialog open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '20px',
            position: 'relative',
            minWidth: '500px',
            borderBottom: `1px solid ${theme.palette.divider}`
          }}
        >
          <Typography component="p" variant="h5">
            Action Required
          </Typography>
          <CloseIcon
            onClick={handleCloseModal}
            sx={{
              position: 'absolute',
              right: '10px',
              cursor: 'pointer'
            }}
          />
        </Box>
        <Box sx={{ padding: '40px' }}>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: '10px'
            }}
          >
            <TextField
              id="select-action"
              select
              fullWidth
              label="I want to"
              value={selectedAction}
              onChange={handleSelectedAction}
            >
              <MenuItem value={'Accept Bid'}>Accept Bid</MenuItem>
              <MenuItem value={'Counter Offer'}>Counter Offer</MenuItem>
              <MenuItem value={'Decline Bid'}>Decline Bid (No Sale)</MenuItem>
            </TextField>
          </Box>
          {selectedAction.toLowerCase() === 'accept bid' && (
            <Box sx={{ textAlign: 'center' }}>
              {vehicle.auction.negotiationPrice ? (
                <>
                  {/* prettier-ignore */}
                  <Typography>
                    Are you sure you want to ACCEPT {currencyFormat.format(vehicle.auction.negotiationPrice)} from {vehicle.auction.sellerName}?
                  </Typography>
                </>
              ) : (
                <>
                  {/* prettier-ignore */}
                  <Typography>
                    Are you sure you want to ACCEPT {currencyFormat.format(vehicle.auction.currentPrice)} from {vehicle.auction.sellerName}?
                  </Typography>
                </>
              )}
              <Typography>
                Accepting an offer will move this vehicle to Won or Arrived if it is in stock.
              </Typography>
            </Box>
          )}
          {selectedAction.toLowerCase() === 'counter offer' && (
            <Box>
              <TextField
                sx={{ mt: 1, mb: 1 }}
                label="Current offer"
                fullWidth
                disabled
                defaultValue={
                  vehicle.auction.negotiationPrice
                    ? currencyFormat.format(vehicle.auction.negotiationPrice)
                    : currencyFormat.format(vehicle.auction.currentPrice)
                }
              />
              <NumericFormat
                value={formattedCounterOfferAmount}
                allowNegative={false}
                prefix="$"
                decimalScale={0}
                thousandSeparator={true}
                label="Counter Offer Amount"
                fullWidth
                customInput={TextField}
                onValueChange={(values: any) => {
                  setFormattedCounterOfferAmount(values.formattedValue);
                  setCounterOfferAmount(values.value);
                }}
              />
            </Box>
          )}
          {selectedAction.toLowerCase() === 'decline bid' && (
            <Box sx={{ textAlign: 'center' }}>
              {vehicle.auction.negotiationPrice ? (
                <>
                  {/* prettier-ignore */}
                  <Typography>
                    Are you sure you want to DECLINE {currencyFormat.format(vehicle.auction.negotiationPrice)} from {vehicle.auction.sellerName}?
                  </Typography>
                </>
              ) : (
                <>
                  {/* prettier-ignore */}
                  <Typography>
                    Are you sure you want to DECLINE {currencyFormat.format(vehicle.auction.currentPrice)} from {vehicle.auction.sellerName}?
                  </Typography>
                </>
              )}
              <Typography>Declining an offer will move this vehicle to No Sale.</Typography>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0px 40px 20px 40px'
          }}
        >
          <Button
            size="large"
            variant="contained"
            className={styles.cancelButton}
            onClick={handleCloseModal}
          >
            Cancel
          </Button>
          {selectedAction.toLowerCase() === 'accept bid' && (
            <LoadingButton
              size="large"
              type="submit"
              variant="contained"
              onClick={handleAcceptAction}
            >
              Accept Bid
            </LoadingButton>
          )}
          {selectedAction.toLowerCase() === 'counter offer' && (
            <LoadingButton
              size="large"
              type="submit"
              variant="contained"
              onClick={handleAcceptAction}
            >
              Send Counter Offer
            </LoadingButton>
          )}
          {selectedAction.toLowerCase() === 'decline bid' && (
            <LoadingButton
              size="large"
              type="submit"
              variant="contained"
              sx={{
                backgroundColor: theme.palette.error.main,
                '&:hover': {
                  backgroundColor: theme.palette.error.dark
                }
              }}
              onClick={handleAcceptAction}
            >
              Decline Bid
            </LoadingButton>
          )}
        </Box>
      </Dialog>
    </Grid>
  );
}
