import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import clsx from 'clsx';

type AuctionStatusProps = {
  status: string;
};

const AuctionStatus = (props: AuctionStatusProps) => {
  const { status } = props;
  const theme = useTheme();

  const useStyles = makeStyles({
    commonStyles: {
      lineHeight: 1,
      padding: '6px 15px',
      fontSize: '13px',
      width: '95px'
    },
    unopened: {
      color: '#979797',
      backgroundColor: 'rgba(151, 151, 151, 0.15)',
      '&:hover': {
        backgroundColor: 'rgba(151, 151, 151, 0.15)'
      }
    },
    opened: {
      color: '#FD7E14',
      backgroundColor: 'rgba(253, 126, 20, 0.2)',
      '&:hover': {
        backgroundColor: 'rgba(253, 126, 20, 0.2)'
      }
    },
    incomplete: {
      color: '#E21912',
      backgroundColor: 'rgba(226, 25, 18, 0.1)',
      '&:hover': {
        backgroundColor: 'rgba(226, 25, 18, 0.1)'
      }
    },
    suspended: {
      color:
        theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.palette.primary.darker,
      backgroundColor:
        theme.palette.mode === 'dark'
          ? theme.palette.primary.darker
          : theme.palette.primary.lighter,
      '&:hover': {
        backgroundColor:
          theme.palette.mode === 'dark'
            ? theme.palette.primary.darker
            : theme.palette.primary.lighter
      }
    },
    registered: {
      color: '#09B984',
      backgroundColor: 'rgba(9, 185, 132, 0.24)',
      '&:hover': {
        backgroundColor: 'rgba(9, 185, 132, 0.24)'
      }
    },
    buyerOnly: {
      color: '#fde917',
      backgroundColor: 'rgba(253, 233, 23, 0.24)',
      '&:hover': {
        backgroundColor: 'rgba(253, 233, 23, 0.24)'
      }
    }
  });

  const styles = useStyles();

  // incomplete = 'rgba(253, 126, 20, 0.2)'
  // suspended (pending) = theme.palette.primary.lighter
  // disabled = 'rgba(226, 25, 18, 0.1)'
  // sent = 'rgba(151, 151, 151, 0.15)'
  // registered = 'rgba(9, 185, 132, 0.24)'

  return (
    <>
      {status === 'incomplete' ? (
        <Button className={clsx(styles.opened, styles.commonStyles)}>Incomplete</Button> // orange
      ) : status === 'suspended' ? (
        <Button className={clsx(styles.suspended, styles.commonStyles)}>Pending</Button> // blue
      ) : status === 'registered' ? (
        <Button className={clsx(styles.registered, styles.commonStyles)}>Registered</Button> // green
      ) : status === 'disabled' ? (
        <Button className={clsx(styles.incomplete, styles.commonStyles)}>Disabled</Button> // red
      ) : status === 'buyerOnly' ? (
        <Button className={clsx(styles.buyerOnly, styles.commonStyles)}>Buyer Only</Button>
      ) : status === 'Expired' ? (
        <Button className={clsx(styles.incomplete, styles.commonStyles)}>Expired</Button>
      ) : null}
    </>
  );
};

export default AuctionStatus;
