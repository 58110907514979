import { Grid, Typography, Avatar } from '@mui/material';
import AuctionStatus from '../auction/AuctionStatus';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { Dealership } from 'types/dealership';
import { collection, CollectionReference, query, where } from 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

type Props = {
  dealership: Dealership;
};

const DrawerNetwork = ({ dealership }: Props) => {
  const theme = useTheme();
  const firestore = useFirestore();

  const dealershipsRef = collection(firestore, 'dealerships') as CollectionReference<Dealership>;
  const dealershipsQuery = query(
    dealershipsRef,
    where('joinedDealerships', 'array-contains', dealership.id)
  );
  const { data: dealerships } = useFirestoreCollectionData(dealershipsQuery, { idField: 'id' });

  const useStyles = makeStyles({
    typography: {
      color: theme.palette.text.secondary
    }
  });

  const styles = useStyles();
  return (
    <Grid sx={{ color: theme.palette.secondary.darker, m: '19px' }} xs={12}>
      <Typography
        variant="subtitle2"
        component="h2"
        paragraph
        sx={{
          fontWeight: 700,
          mb: '15px',
          color:
            theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.text.primary
        }}
      >
        Dealer Network
      </Typography>
      {dealerships &&
        dealerships.map((dealership: Dealership, index: number) => {
          return (
            <Grid
              key={index}
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              sx={{
                border: `1px solid ${theme.palette.grey[100]}`,
                borderRadius: '5px',
                padding: '10px',
                cursor: 'pointer',
                marginBottom: '10px'
              }}
            >
              {dealership.logoUrl && (
                <Grid>
                  <Avatar
                    alt="logo"
                    src={dealership.logoUrl}
                    sx={{ width: '58px', height: '58px', borderRadius: '10px', mr: '11px' }}
                  />
                </Grid>
              )}
              <Grid>
                <Typography
                  variant="subtitle2"
                  component="h4"
                  sx={{
                    color:
                      theme.palette.mode === 'dark'
                        ? theme.palette.common.white
                        : theme.palette.text.primary
                  }}
                >
                  {dealership.dealershipName}
                </Typography>
                <Typography
                  className={styles.typography}
                  variant="subtitle2"
                  component="h5"
                  sx={{ fontWeight: 400, color: theme.palette.info.lighter, mb: '2px' }}
                >
                  {dealership.primaryContact}
                </Typography>
                <AuctionStatus status={dealership.inviteStatus} />
              </Grid>
            </Grid>
          );
        })}
    </Grid>
  );
};

export default DrawerNetwork;
