import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import {
  Box,
  Grid,
  Radio,
  Paper,
  RadioGroup,
  CardActionArea,
  FormControlLabel
} from '@mui/material';
import { User } from 'constants/user';
import useAuth from 'hooks/useAuth';
import { useFirestoreByPath } from 'hooks/useFirestoreByPath';
import { useEffect, useState } from 'react';
// import useSettings from '../../hooks/useSettings';

export default function SettingMode() {
  // const { themeMode, onChangeMode } = useSettings();
  const { user, currentUser } = useAuth();
  const { update: updateUser } = useFirestoreByPath<User>('users');
  const [themeMode, setThemeMode] = useState<string>();

  const changeTheme = async () => {
    // let willBeDarkMode;
    // if (currentUser.isDarkMode) {
    //   willBeDarkMode = false;
    // } else {
    //   willBeDarkMode = true;
    // }
    let willBeLightMode;
    if (currentUser.isLightMode) {
      willBeLightMode = false;
    } else {
      willBeLightMode = true;
    }
    await updateUser(user?.uid as string, { isLightMode: willBeLightMode });
    window.location.reload();
  };

  useEffect(() => {
    if (currentUser.isLightMode) {
      setThemeMode('light');
    } else {
      setThemeMode('dark');
    }
  }, [currentUser]);

  return (
    <RadioGroup name="themeMode" value={themeMode} onChange={changeTheme}>
      <Grid container spacing={2.5} dir="ltr">
        {['light', 'dark'].map((mode, index) => (
          <Grid item xs={6} key={mode}>
            <Paper
              sx={{
                width: 1,
                zIndex: 0,
                overflow: 'hidden',
                position: 'relative',
                bgcolor: mode === 'dark' ? 'grey.900' : 'common.white',
                ...(themeMode === mode && {
                  boxShadow: (theme) => theme.customShadows.z12
                })
              }}
            >
              <CardActionArea sx={{ color: 'primary.main' }}>
                <Box
                  sx={{
                    py: 4,
                    display: 'flex',
                    color: 'text.disabled',
                    justifyContent: 'center',
                    ...(themeMode === mode && {
                      color: 'primary.main'
                    })
                  }}
                >
                  {index === 0 ? (
                    <LightModeIcon sx={{ fontSize: 24 }} />
                  ) : (
                    <DarkModeIcon sx={{ fontSize: 24 }} />
                  )}
                </Box>

                <FormControlLabel
                  label=""
                  value={mode}
                  control={<Radio sx={{ display: 'none' }} />}
                  sx={{
                    top: 0,
                    margin: 0,
                    width: '100%',
                    height: '100%',
                    position: 'absolute'
                  }}
                />
              </CardActionArea>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </RadioGroup>
  );
}
