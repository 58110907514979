import { Stripe, loadStripe } from '@stripe/stripe-js';
import { STRIPE_PUBLISHABLE_KEY } from '../config';

let stripePromise: Stripe | null;

const initializeStripe = async () => {
  if (!stripePromise) {
    stripePromise = await loadStripe(STRIPE_PUBLISHABLE_KEY as string);
  }
  return stripePromise;
};

export default initializeStripe;
