import { Typography, Box, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { VehicleInfo } from 'types/vehicleInfo';

type Props = {
  vehicle: VehicleInfo;
};

export default function OverviewTab({ vehicle }: Props) {
  const theme = useTheme();

  const useStyles = makeStyles({
    tableRow: {
      '&:nth-child(odd)': {
        backgroundColor: theme.palette.background.paper
      }
    }
  });

  const styles = useStyles();

  return (
    <>
      <Typography
        component="p"
        sx={{
          fontSize: '17px',
          fontWeight: '700',
          color: theme.palette.info.light,
          margin: '5px 0px'
        }}
      >
        Vehicle Overview
      </Typography>
      <Box sx={{ marginTop: '20px' }}>
        <Box
          className={styles.tableRow}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            padding: '10px'
          }}
        >
          <Typography sx={{ fontSize: '13px' }}>VIN:</Typography>
          <Typography sx={{ fontSize: '13px', fontWeight: '700' }}>
            {vehicle.details.vin}
          </Typography>
        </Box>
        <Box
          className={styles.tableRow}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            padding: '10px'
          }}
        >
          <Typography sx={{ fontSize: '13px' }}>Odometer:</Typography>
          <Typography sx={{ fontSize: '13px', fontWeight: '700' }}>
            {vehicle.details.mileage} {vehicle.details.mileageType}
          </Typography>
        </Box>
        <Box
          className={styles.tableRow}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            padding: '10px'
          }}
        >
          <Typography sx={{ fontSize: '13px' }}>Year:</Typography>
          <Typography sx={{ fontSize: '13px', fontWeight: '700' }}>
            {vehicle.details.year}
          </Typography>
        </Box>
        <Box
          className={styles.tableRow}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            padding: '10px'
          }}
        >
          <Typography sx={{ fontSize: '13px' }}>Make:</Typography>
          <Typography sx={{ fontSize: '13px', fontWeight: '700' }}>
            {vehicle.details.make}
          </Typography>
        </Box>
        <Box
          className={styles.tableRow}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            padding: '10px'
          }}
        >
          <Typography sx={{ fontSize: '13px' }}>Model:</Typography>
          <Typography sx={{ fontSize: '13px', fontWeight: '700' }}>
            {vehicle.details.model}
          </Typography>
        </Box>
        <Box
          className={styles.tableRow}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            padding: '10px'
          }}
        >
          <Typography sx={{ fontSize: '13px' }}>Body Style:</Typography>
          <Typography sx={{ fontSize: '13px', fontWeight: '700' }}>
            {vehicle.details.bodyStyle}
          </Typography>
        </Box>
        <Box
          className={styles.tableRow}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            padding: '10px'
          }}
        >
          <Typography sx={{ fontSize: '13px' }}>Trim:</Typography>
          <Typography sx={{ fontSize: '13px', fontWeight: '700' }}>
            {vehicle.details.trim}
          </Typography>
        </Box>
        {vehicle.details.package && (
          <Box
            className={styles.tableRow}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              padding: '10px'
            }}
          >
            <Typography sx={{ fontSize: '13px' }}>Package:</Typography>
            <Typography sx={{ fontSize: '13px', fontWeight: '700' }}>
              {vehicle.details.package}
            </Typography>
          </Box>
        )}
        <Box
          className={styles.tableRow}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            padding: '10px'
          }}
        >
          <Typography sx={{ fontSize: '13px' }}>Transmission:</Typography>
          <Typography sx={{ fontSize: '13px', fontWeight: '700', textTransform: 'capitalize' }}>
            {vehicle.details.transmission}
          </Typography>
        </Box>
        <Box
          className={styles.tableRow}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            padding: '10px'
          }}
        >
          <Typography sx={{ fontSize: '13px' }}>Drive Train:</Typography>
          <Typography sx={{ fontSize: '13px', fontWeight: '700' }}>
            {vehicle.details.driveTrain}
          </Typography>
        </Box>
        {vehicle.details.engine && (
          <Box
            className={styles.tableRow}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              padding: '10px'
            }}
          >
            <Typography sx={{ fontSize: '13px' }}>Engine:</Typography>
            <Typography sx={{ fontSize: '13px', fontWeight: '700' }}>
              {vehicle.details.engine}
            </Typography>
          </Box>
        )}
        <Box
          className={styles.tableRow}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            padding: '10px'
          }}
        >
          <Typography sx={{ fontSize: '13px' }}>Displacement:</Typography>
          <Typography sx={{ fontSize: '13px', fontWeight: '700' }}>
            {vehicle.details.displacement}
          </Typography>
        </Box>
        <Box
          className={styles.tableRow}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            padding: '10px'
          }}
        >
          <Typography sx={{ fontSize: '13px' }}>Cylinders:</Typography>
          <Typography sx={{ fontSize: '13px', fontWeight: '700' }}>
            {vehicle.details.cylinders}
          </Typography>
        </Box>
        <Box
          className={styles.tableRow}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            padding: '10px'
          }}
        >
          <Typography sx={{ fontSize: '13px' }}>Fuel Type:</Typography>
          <Typography sx={{ fontSize: '13px', fontWeight: '700' }}>
            {vehicle.details.fuelType}
          </Typography>
        </Box>
        <Box
          className={styles.tableRow}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            padding: '10px'
          }}
        >
          <Typography sx={{ fontSize: '13px' }}>Doors:</Typography>
          <Typography sx={{ fontSize: '13px', fontWeight: '700' }}>
            {vehicle.details.doors}
          </Typography>
        </Box>
        {/* <Box
          className={styles.tableRow}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            padding: '10px'
          }}
        >
          <Typography sx={{ fontSize: '13px' }}>Fuel Type:</Typography>
          <Typography sx={{ fontSize: '13px', fontWeight: '700' }}>
            {vehicle.details.fuelType}
          </Typography>
        </Box> */}
        <Box
          className={styles.tableRow}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            padding: '10px'
          }}
        >
          <Typography sx={{ fontSize: '13px' }}>Passengers:</Typography>
          <Typography sx={{ fontSize: '13px', fontWeight: '700' }}>
            {vehicle.details.passengers}
          </Typography>
        </Box>
        <Box
          className={styles.tableRow}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            padding: '10px'
          }}
        >
          <Typography sx={{ fontSize: '13px' }}>Ext. Colour:</Typography>
          <Typography sx={{ fontSize: '13px', fontWeight: '700' }}>
            {vehicle.details.extColor}
          </Typography>
        </Box>
        <Box
          className={styles.tableRow}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            padding: '10px'
          }}
        >
          <Typography sx={{ fontSize: '13px' }}>Int. Colour:</Typography>
          <Typography sx={{ fontSize: '13px', fontWeight: '700' }}>
            {vehicle.details.intColor}
          </Typography>
        </Box>
      </Box>
    </>
  );
}
