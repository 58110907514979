import { Box, Button, Collapse, Divider, FormHelperText, Typography } from '@mui/material';
import { VehicleEquipmentType } from '../../../../types/vehicleInfo';
import { useEffect, useState } from 'react';
import {
  GridElement,
  GridElementRoof,
  VehicleChipCheckbox,
  VehicleDropBox
} from './CommonComponents';
// import { boxStyle } from './vehicleCaptureStyles';
import { useNavigate } from 'react-router-dom';
import { PATH_SELLER } from 'routes/paths';
import { useTheme } from '@mui/material/styles';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import MoreItemsIndicator from './MoreItemsIndicator';

const vehicleOptions = [
  'Adaptive Cruise Control',
  'Blind Spot Monitor',
  'Bluetooth',
  'Cooled Seats',
  'PWR Gate',
  'PWR Door',
  'Heads Up Display',
  'Heated Seats',
  'Heated Steering Wheel',
  'Auto Start',
  'Lane Departure',
  'Satellite Radio',
  'Memory Seat',
  // 'PWR Seats',
  'Backup Camera',
  'Surround Camera',
  'Backup Sensors',
  'LED Lights',
  'PWR Running Boards',
  'DVD System',
  'Heated Mirrors',
  'Fog Lights',
  'Adjustable Pedals',
  'Left Air Bag',
  'Right Air Bag',
  'Side Air Bag',
  'Tilt Steering Wheel',
  'Traction Control',
  'USB Inputs',
  // 'Hard Top',
  'Soft Top',
  'Leather Steering Wheel',
  'Remote Trunk Release',
  'Trip Computer',
  'Bucket Seats'
];

const aftermarketOptions = [
  'Upgraded Rims',
  'Upgraded Tires',
  'Lift',
  'Wrap',
  'Steps',
  'Front Bumper',
  'Rear Bumper',
  'Tonneau Cover',
  'Cap',
  'Light Bar',
  'Graphics Package'
];

type Props = {
  equipment: VehicleEquipmentType;
  updVehicleEq: (equipment: VehicleEquipmentType, flag: number) => void;
};

export function VehicleEquipment({ equipment, updVehicleEq }: Props) {
  const navigate = useNavigate();
  const theme = useTheme();
  const [equipmentInfo, setEquipmentInfo] = useState(equipment || ({} as VehicleEquipmentType));
  const [factoryInfo, setFactoryInfo] = useState(['']);
  const [seeMore, setSeeMore] = useState(false);
  const [isDisabledNext, setIsDisabledNext] = useState<boolean>(true);
  const [showValidateError, setShowValidateError] = useState<boolean>(false);
  const [clothLeatherVal, setClothLeatherVal] = useState<string>(
    equipment.clothLeather ? equipment.clothLeather.toLowerCase() : ''
  );
  const [roofVal, setRoofVal] = useState<string>(
    equipment.roof ? equipment.roof.toLowerCase() : ''
  );
  const [tireSizeVal, setTireSizeVal] = useState<string>(
    equipment.sizeTires ? equipment.sizeTires : ''
  );

  const boxStyle = {
    textAlign: 'start',
    width: 400,
    height: 60,
    backgroundColor: theme.palette.mode !== 'dark' ? theme.palette.common.white : 'unset',
    border: `1px ${theme.palette.divider} solid`,
    borderRadius: '10px',
    fontWeight: 300,
    padding: 0,
    mb: 1,
    overflow: 'hidden'
  };

  const validateInputs = () => {
    if (
      clothLeatherVal &&
      clothLeatherVal !== ' ' &&
      roofVal &&
      roofVal !== ' ' &&
      tireSizeVal &&
      tireSizeVal !== ' '
    ) {
      setIsDisabledNext(false);
    } else {
      setIsDisabledNext(true);
    }
  };

  useEffect(() => {
    validateInputs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clothLeatherVal, roofVal, tireSizeVal]);

  useEffect(() => {
    validateInputs();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const tmpSt =
      equipmentInfo && equipmentInfo.factoryInfo && equipmentInfo.factoryInfo.length > 0
        ? equipmentInfo.factoryInfo
        : [''];

    const tempClothLeather =
      equipmentInfo.clothLeather && equipmentInfo.clothLeather !== ''
        ? equipmentInfo.clothLeather
        : 'cloth';

    equipmentInfo.clothLeather = tempClothLeather;

    setFactoryInfo(tmpSt);
  }, [equipmentInfo, equipmentInfo.factoryInfo]);

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="outlined"
          onClick={() => {
            updVehicleEq(equipmentInfo, 5);
            navigate(PATH_SELLER.vehicleCapture);
          }}
        >
          Save and Exit
        </Button>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          backgroundColor:
            theme.palette.mode === 'dark'
              ? theme.palette.background.neutral
              : theme.palette.grey[50],
          borderRadius: '10px',
          maxWidth: '850px',
          width: '100%',
          border: '1px solid rgba(145, 158, 171, 0.24)',
          margin: 'auto',
          marginTop: '20px',
          paddingBottom: '20px'
        }}
      >
        <Box>
          <Box sx={{ mt: 3, width: 400 }}>
            <Typography
              variant="h3"
              sx={{ textAlign: 'center', color: theme.palette.text.primary }}
            >
              Equipment
            </Typography>
          </Box>
        </Box>
        <Box sx={{ mt: 3, width: 400 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: '700', textTransform: 'uppercase' }}>
            Notables
          </Typography>

          <GridElement
            caption={'Does this vehicle have Cloth or Leather?'}
            dropBoxValue={
              equipmentInfo.clothLeather ? equipmentInfo.clothLeather.toLowerCase() : null
            }
            arrayOfItems={['cloth', 'leather']}
            updateDropValue={(value) => {
              const tmp = { ...equipmentInfo };
              tmp.clothLeather = value;
              setEquipmentInfo(tmp);
              setClothLeatherVal(tmp.clothLeather);
            }}
          />
          {(!clothLeatherVal || clothLeatherVal === ' ') && showValidateError && (
            <FormHelperText
              sx={{ ml: 1, mb: 2 }}
              error={Boolean(!clothLeatherVal || clothLeatherVal === ' ')}
            >
              You must choose cloth or leather.
            </FormHelperText>
          )}
          {/* <GridElement
            caption={'Does the roof have a hard top or sunroof?'}
            dropBoxValue={equipmentInfo.roof ? equipmentInfo.roof.toLowerCase() : null}
            arrayOfItems={['hard top', 'sun roof']}
            updateDropValue={(value) => {
              const tmp = { ...equipmentInfo };
              tmp.roof = value;
              setEquipmentInfo(tmp);
              setRoofVal(tmp.roof);
            }}
          /> */}
          <GridElementRoof
            caption={'Select the Roof Type for this Vehicle'}
            dropBoxValue={equipmentInfo.roof ? equipmentInfo.roof.toLowerCase() : null}
            arrayOfItems={['hard top', 'sun roof', 'convertible', 'removable']}
            updateDropValue={(value) => {
              const tmp = { ...equipmentInfo };
              tmp.roof = value;
              setEquipmentInfo(tmp);
              setRoofVal(tmp.roof);
            }}
          />
          {(!roofVal || roofVal === ' ') && showValidateError && (
            <FormHelperText sx={{ ml: 1, mb: 2 }} error={Boolean(!roofVal || roofVal === ' ')}>
              You must choose a roof type.
            </FormHelperText>
          )}
          <GridElement
            caption={'Navigation System'}
            dropBoxValue={equipmentInfo.navigation ? 'Yes' : 'No'}
            arrayOfItems={['Yes', 'No']}
            updateDropValue={(value) => {
              const tmp = { ...equipmentInfo };
              tmp.navigation = value === 'Yes' ? true : false;
              setEquipmentInfo(tmp);
            }}
          />
          <GridElement
            caption={'3rd Row'}
            dropBoxValue={equipmentInfo.thirdRow ? 'Yes' : 'No'}
            arrayOfItems={['Yes', 'No']}
            updateDropValue={(value) => {
              const tmp = { ...equipmentInfo };
              tmp.thirdRow = value === 'Yes' ? true : false;
              setEquipmentInfo(tmp);
            }}
          />
          <GridElement
            caption={'Power Seats'}
            dropBoxValue={equipmentInfo.powerSeat ? 'Yes' : 'No'}
            arrayOfItems={['Yes', 'No']}
            updateDropValue={(value) => {
              const tmp = { ...equipmentInfo };
              tmp.powerSeat = value === 'Yes' ? true : false;
              setEquipmentInfo(tmp);
            }}
          />

          <Typography
            variant="subtitle1"
            sx={{ mt: 2, mb: 2, fontWeight: '700', textTransform: 'uppercase' }}
          >
            Options
          </Typography>
          <VehicleChipCheckbox
            propertyVal={equipmentInfo.options}
            arrayOfItems={vehicleOptions.sort((a, b) => a.localeCompare(b))}
            updateProperty={(value) => {
              const tmp = { ...equipmentInfo };
              tmp.options = [...value];
              setEquipmentInfo(tmp);
            }}
            chipWidth={'auto'}
          />
          {/* prettier-ignore */}
          <Divider
            sx={{
              mt: 0.5,
              mb: 0.5,
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }} 
          />
          <Typography
            variant="subtitle1"
            sx={{ mt: 2, mb: 2, fontWeight: '700', textTransform: 'uppercase' }}
          >
            Aftermarket Options
          </Typography>
          <VehicleChipCheckbox
            propertyVal={equipmentInfo.afterMarketOptions}
            arrayOfItems={aftermarketOptions.sort((a, b) => a.localeCompare(b))}
            updateProperty={(value) => {
              const tmp = { ...equipmentInfo };
              tmp.afterMarketOptions = value;
              setEquipmentInfo(tmp);
            }}
            chipWidth={'auto'}
          />
          {/* prettier-ignore */}
          <Divider
            sx={{
              mt: 0.5,
              mb: 0.5,
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }} 
          />
          <Typography
            variant="subtitle1"
            sx={{ mt: 2, mb: 2, fontWeight: '700', textTransform: 'uppercase' }}
          >
            Tire Info and Size
          </Typography>

          <Box sx={boxStyle}>
            <VehicleDropBox
              dropBoxValue={equipmentInfo.sizeTires}
              caption={'Size'}
              arrayOfItems={[
                '13 inch',
                '14 inch',
                '15 inch',
                '16 inch',
                '17 inch',
                '18 inch',
                '19 inch',
                '20 inch',
                '21 inch',
                '22 inch',
                '23 inch',
                '24 inch',
                '26 inch',
                '28 inch',
                '30 inch',
                '31 inch',
                '32 inch',
                '33 inch',
                '34 inch',
                '35 inch'
              ]}
              updateDropValue={(v) => {
                if (!v) return;
                const tmp = { ...equipmentInfo };
                tmp.sizeTires = v as string;
                setEquipmentInfo(tmp);
                setTireSizeVal(tmp.sizeTires);
              }}
            />
          </Box>
          {(!tireSizeVal || tireSizeVal === ' ') && showValidateError && (
            <FormHelperText
              sx={{ ml: 1, mb: 2 }}
              error={Boolean(!tireSizeVal || tireSizeVal === ' ')}
            >
              You must choose a tire size.
            </FormHelperText>
          )}

          <GridElement
            caption={'TPMS'}
            dropBoxValue={equipmentInfo.tpms ? 'Yes' : 'No'}
            arrayOfItems={['Yes', 'No']}
            updateDropValue={(value) => {
              const tmp = { ...equipmentInfo };
              tmp.tpms = value === 'Yes' ? true : false;
              setEquipmentInfo(tmp);
            }}
          />
          <GridElement
            caption={'Aftermarket Wheels'}
            dropBoxValue={equipmentInfo.aftermarketWheels ? 'Yes' : 'No'}
            arrayOfItems={['Yes', 'No']}
            updateDropValue={(value) => {
              const tmp = { ...equipmentInfo };
              tmp.aftermarketWheels = value === 'Yes' ? true : false;
              setEquipmentInfo(tmp);
            }}
          />
          <GridElement
            caption={'2nd set of Tires'}
            dropBoxValue={equipmentInfo.secondSetOfTires ? 'Yes' : 'No'}
            arrayOfItems={['Yes', 'No']}
            updateDropValue={(value) => {
              const tmp = { ...equipmentInfo };
              tmp.secondSetOfTires = value === 'Yes' ? true : false;
              setEquipmentInfo(tmp);
            }}
          />
          <GridElement
            caption={'Winter Tires'}
            dropBoxValue={equipmentInfo.winterTires ? 'Yes' : 'No'}
            arrayOfItems={['Yes', 'No']}
            updateDropValue={(value) => {
              const tmp = { ...equipmentInfo };
              tmp.winterTires = value === 'Yes' ? true : false;
              setEquipmentInfo(tmp);
            }}
          />
          {factoryInfo && factoryInfo.length > 0 && (
            <>
              <Typography
                variant="subtitle1"
                sx={{ mt: 2, mb: 2, fontWeight: '700', textTransform: 'uppercase' }}
              >
                Factory Information
              </Typography>

              <Box sx={{ width: 400 }}>
                {factoryInfo &&
                  factoryInfo.slice(0, 3).map((item, index) => (
                    <Typography
                      key={index}
                      sx={{ display: 'list-item', mt: 1, ml: 2, fontSize: '12px', color: 'grey' }}
                    >
                      {item}
                    </Typography>
                  ))}

                <Collapse in={seeMore}>
                  {factoryInfo &&
                    factoryInfo.slice(3, factoryInfo.length).map((item, index) => (
                      <Typography
                        key={index}
                        sx={{ display: 'list-item', mt: 1, ml: 2, fontSize: '12px', color: 'grey' }}
                      >
                        {item}
                      </Typography>
                    ))}
                </Collapse>
                <Button
                  variant="text"
                  sx={{ fontSize: '12px' }}
                  onClick={() => setSeeMore(!seeMore)}
                >
                  {seeMore ? 'See Less' : 'See All'}
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Box>

      {(!clothLeatherVal || clothLeatherVal === ' ') && showValidateError && (
        <Box
          sx={{
            background: theme.palette.error.lighter,
            padding: '5px 10px',
            width: '100%',
            maxWidth: '850px',
            margin: 'auto',
            borderRadius: '10px',
            border: `1px solid ${theme.palette.divider}`,
            marginTop: '10px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <WarningAmberOutlinedIcon sx={{ color: theme.palette.error.main }} />
          <FormHelperText
            sx={{ ml: 1, mb: 1, color: `${theme.palette.common.black} !important` }}
            error={Boolean(!clothLeatherVal || clothLeatherVal === ' ')}
          >
            You must choose cloth or leather.
          </FormHelperText>
        </Box>
      )}
      {(!roofVal || roofVal === ' ') && showValidateError && (
        <Box
          sx={{
            background: theme.palette.error.lighter,
            padding: '5px 10px',
            width: '100%',
            maxWidth: '850px',
            margin: 'auto',
            borderRadius: '10px',
            border: `1px solid ${theme.palette.divider}`,
            marginTop: '10px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <WarningAmberOutlinedIcon sx={{ color: theme.palette.error.main }} />
          <FormHelperText
            sx={{ ml: 1, mb: 1, color: `${theme.palette.common.black} !important` }}
            error={Boolean(!roofVal || roofVal === ' ')}
          >
            You must choose a roof type.
          </FormHelperText>
        </Box>
      )}
      {(!tireSizeVal || tireSizeVal === ' ') && showValidateError && (
        <Box
          sx={{
            background: theme.palette.error.lighter,
            padding: '5px 10px',
            width: '100%',
            maxWidth: '850px',
            margin: 'auto',
            borderRadius: '10px',
            border: `1px solid ${theme.palette.divider}`,
            marginTop: '10px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <WarningAmberOutlinedIcon sx={{ color: theme.palette.error.main }} />
          <FormHelperText
            sx={{ ml: 1, mb: 1, color: `${theme.palette.common.black} !important` }}
            error={Boolean(!tireSizeVal || tireSizeVal === ' ')}
          >
            You must choose a tire size.
          </FormHelperText>
        </Box>
      )}
      {/* prettier-ignore */}
      <Divider
        sx={{
          mt: 2,
          borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
        }} 
      />
      <Box sx={{ m: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Button variant="text" color="inherit" onClick={() => updVehicleEq(equipmentInfo, 4)}>
          Back
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            if (isDisabledNext) {
              setShowValidateError(true);
            } else {
              updVehicleEq(equipmentInfo, 6);
            }
          }}
        >
          Next: Auction Details
        </Button>
      </Box>
      <MoreItemsIndicator />
    </>
  );
}
