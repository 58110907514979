import {
  Grid,
  Button,
  IconButton,
  Popover,
  MenuItem,
  Dialog,
  Typography,
  Box,
  DialogContent,
  DialogActions,
  Divider,
  Chip,
  DialogTitle,
  TextField,
  Tooltip
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { fDateTimeSuffix } from 'components/shared/dateTimeFormat';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import AuctionDrawer from 'components/auction/drawer/AuctionDrawer';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { VehicleInfo } from 'types/vehicleInfo';
import ListItemDetails from 'components/shared/ListItemDetails';
import ListItemBidPrice from 'components/shared/ListItemPriceBid';
import ListItemPersonDate from 'components/shared/ListItemPersonDate';
import SharedDrawer from 'components/shared/SharedDrawer';
import { Link as RouterLink } from 'react-router-dom';
import { PATH_SELLER } from 'routes/paths';
import CaptureHistoryDrawerContent from 'components/seller/preAuction/CaptureHistoryDrawerContent';
import { useNavigate } from 'react-router-dom';
import ListItemNoPrice from 'components/shared/ListItemNoPrice';
import LaunchSoonContent from 'components/seller/preAuction/LaunchSoonContent';
import ActiveContent from 'components/seller/preAuction/ActiveContent';
import { useFirestore } from 'reactfire';
import { doc, updateDoc } from 'firebase/firestore';
import useAuth from 'hooks/useAuth';
import { isMobile } from 'react-device-detect';
import { NumericFormat } from 'react-number-format';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

interface Props {
  vehicle: VehicleInfo;
  deleteVehicle: (vehicle: VehicleInfo) => void;
  sendVehicleToParent: (index: number, vehicle: VehicleInfo) => void;
}

const MENU_OPTIONS = [
  { label: 'Capture Information', linkTo: '#' },
  { label: 'Delete Vehicle', linkTo: '#' }
];

const SUB_MENU_OPTIONS = [
  { label: 'Parked', linkTo: '#' },
  { label: 'Launching Soon', linkTo: '#' },
  { label: 'Active', linkTo: '#' }
];

const ALL_MENU_OPTIONS = [
  { label: 'Capture Information', linkTo: '#' },
  { label: 'Vehicle VDP', linkTo: '#' },
  { label: 'Move to: Parked', linkTo: '#' },
  { label: 'Move to: Launching Soon', linkTo: '#' },
  { label: 'Move to: Active', linkTo: '#' },
  { label: 'Delete Vehicle', linkTo: '#' }
];

export default function CaptureListItem({ vehicle, deleteVehicle, sendVehicleToParent }: Props) {
  const theme = useTheme();
  const { currentUser } = useAuth();
  const firestore = useFirestore();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [anchorPopup, setAnchorPopup] = useState<HTMLButtonElement | null>(null);
  const [anchorPopupSub, setAnchorPopupSub] = useState<HTMLButtonElement | null>(null);
  const [, setPopupOpened] = useState(false);
  const [, setPopupOpenedSub] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [, setSelectedIndexSub] = useState(0);
  const [openVDPDrawer, setOpenVDPDrawer] = useState<boolean>(false);
  const navigate = useNavigate();
  // const [logoImage, setLogoImage] = useState<string>();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [floorPrice, setFloorPrice] = useState<Number>();
  const [floorPriceDisabled, setFloorPriceDisabled] = useState<boolean>(true);

  const handleDialogChange = () => {
    setDialogOpen(!dialogOpen);
  };

  const currencyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });

  const handleToggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  const handleCloseModal = () => {
    setOpenModal(!openModal);
  };

  const open = Boolean(anchorPopup);
  const id = open ? 'simple-popover' : undefined;

  const openSub = Boolean(anchorPopupSub);
  const idSub = open ? 'simple-popover-sub' : undefined;

  const useStyles = makeStyles({
    menuPopover: {
      boxShadow: '0 0 2px 0 rgba(139, 148, 156, 0.24),0 10px 20px -4px rgba(139, 148, 156, 0.24)',
      // backgroundColor: theme.palette.primary.contrastText,
      paddingTop: '20px',
      paddingBottom: '20px',
      '@global': {
        '.MuiMenuItem-root': {
          paddingBottom: '6px'
        },
        '.MuiMenuItem-root:last-child': {
          color: theme.palette.error.dark
        }
      }
    },
    cancelButton: {
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.common.black,
      '&:hover': {
        backgroundColor: theme.palette.grey[300]
      }
    },
    declineButton: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.error.dark
      }
    }
  });

  const styles = useStyles();

  let logoImage;
  if (vehicle.photos) {
    const photos = vehicle.photos.filter(
      (v: any) => v.url && v.location && v.location.toLowerCase().includes('3/4 shot')
    );
    if (photos[0]) {
      logoImage = photos[0].url;
    }
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorPopup(event.currentTarget);
    setPopupOpened(true);
  };

  const handleMenuItemClick = async (event: React.MouseEvent<HTMLButtonElement>, index: number) => {
    setSelectedIndex(index);
    sendVehicleToParent(index, vehicle);
    if (vehicle.captureComplete) {
      if (index === 0) {
        // setOpenDrawer(!openDrawer);
        // handleClose();
      } else if (index === 5) {
        handleDeleteDialogClose();
        handleClose();
      } else if (index === 3 || index === 4) {
        handleToggleModal();
      } else if (index === 1) {
        // setOpenVDPDrawer(!openVDPDrawer);
        // handleClose();
      } else {
        const docRef = doc(firestore, `vehicles/${vehicle.id}`);
        await updateDoc(docRef, { 'auction.auctionStatus': 'parked' });
      }
    } else {
      if (index === 0) {
        // setOpenDrawer(!openDrawer);
        // handleClose();
      } else if (index === 1) {
        handleDeleteDialogClose();
        handleClose();
      }
    }
  };

  const handleSubMenuItemClick = (event: React.MouseEvent<HTMLButtonElement>, index: number) => {
    setSelectedIndexSub(index);
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const handleClose = () => {
    setAnchorPopup(null);
    setPopupOpened(false);
  };

  const handleCloseSub = () => {
    setAnchorPopup(null);
    setPopupOpened(false);
    setAnchorPopupSub(null);
    setPopupOpenedSub(false);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(!deleteDialogOpen);
  };

  const handleSetFloorPrice = async () => {
    const docRef = doc(firestore, `vehicles/${vehicle.id}`);
    await updateDoc(docRef, {
      'auction.floorPrice': floorPrice,
      floorPriceMissing: false,
      captureComplete: true,
      'auction.auctionStatus': 'parked'
    });
  };

  return (
    <>
      <Grid
        xs={12}
        sx={{
          minWidth: '1375px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          border:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`,
          borderRadius: '10px',
          padding: '6px !important',
          '&:not(first-of-type)': {
            marginTop: '5px'
          }
        }}
      >
        {/* prettier-ignore */}
        <Grid>
          <ListItemDetails
            vehicle={vehicle}
            isSellerName={false}
            isCaptureItem={true}
            imageUrl={logoImage}
            minWidth={vehicle.floorPriceMissing ? '420px' : '550px'}
          ></ListItemDetails>
        </Grid>
        <Grid
          item
          xs={2}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '6px 20px',
            minWidth: vehicle.floorPriceMissing ? '280px' : '150px'
          }}
        >
          {vehicle.auction.floorPrice ? (
            <ListItemBidPrice
              label={'floor price'}
              value={currencyFormat.format(vehicle.auction.floorPrice)}
              color={theme.palette.primary.main}
            ></ListItemBidPrice>
          ) : (
            <>
              {vehicle.floorPriceMissing ? (
                <>
                  <NumericFormat
                    sx={{
                      color: 'black',
                      width: '85%'
                    }}
                    inputProps={{ style: { fontSize: '12px', fontWeight: '600' } }}
                    InputLabelProps={{ style: { fontSize: '12px', color: 'grey' } }}
                    allowNegative={false}
                    variant="standard"
                    id="outlined-required"
                    label="Enter Floor Price (must end with 00)"
                    prefix="$"
                    decimalScale={0}
                    thousandSeparator={true}
                    fullWidth
                    customInput={TextField}
                    onValueChange={(values) => {
                      if (values.floatValue?.toString().endsWith('00')) {
                        setFloorPriceDisabled(false);
                      } else {
                        setFloorPriceDisabled(true);
                      }
                      setFloorPrice(values.floatValue);
                    }}
                  />
                  <Tooltip title="Click To Set Floor Price (must end with 00)">
                    <IconButton
                      onClick={handleSetFloorPrice}
                      disabled={floorPriceDisabled}
                      sx={{
                        backgroundColor: 'transparent',
                        color: !floorPriceDisabled
                          ? theme.palette.success.main
                          : theme.palette.text.secondary,
                        padding: '5px',
                        borderRadius: '5px',
                        marginLeft: '2px'
                      }}
                    >
                      <CheckCircleOutlineIcon />
                    </IconButton>
                  </Tooltip>
                </>
              ) : (
                <ListItemNoPrice
                  label={'floor price'}
                  value={'No Value Set'}
                  color={theme.palette.error.main}
                ></ListItemNoPrice>
              )}
            </>
          )}
        </Grid>
        <Grid
          item
          xs={3}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '6px 20px',
            minWidth: '200px'
          }}
        >
          <ListItemPersonDate
            label={'last edited'}
            value={
              vehicle.auction.captureHistory && vehicle.auction.captureHistory.length
                ? fDateTimeSuffix(
                    vehicle.auction.captureHistory[
                      vehicle.auction.captureHistory.length - 1
                    ].date.toDate()
                  )
                : ''
            }
          ></ListItemPersonDate>
        </Grid>
        <Grid
          item
          xs={3}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '6px 20px',
            minWidth: '200px'
          }}
        >
          <ListItemPersonDate
            label={'edited by'}
            value={
              vehicle.auction.captureHistory && vehicle.auction.captureHistory.length
                ? vehicle.auction.captureHistory[vehicle.auction.captureHistory.length - 1]
                    .captureRepName
                : ''
            }
          ></ListItemPersonDate>
        </Grid>
        <Grid
          item
          xs={2}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '6px 20px',
            minWidth: '100px'
          }}
        >
          {vehicle && vehicle.captureComplete ? (
            <Chip
              sx={{
                backgroundColor: 'rgba(9, 185, 132, 0.24)',
                color: '#09B984'
              }}
              label={'Draft Complete'}
            ></Chip>
          ) : (
            <Chip
              sx={{
                backgroundColor: !vehicle.floorPriceMissing
                  ? 'rgba(226, 25, 18, 0.1)'
                  : 'rgba(253, 126, 20, 0.2)',
                color: !vehicle.floorPriceMissing ? '#E21912' : '#FD7E14'
              }}
              label={
                vehicle.floorPriceMissing ? 'Complete: Floor Price Missing' : 'Draft Incomplete'
              }
            ></Chip>
          )}
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            minWidth: '150px',
            padding: '6px'
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              if (isMobile) {
                handleDialogChange();
              } else {
                navigate(`${PATH_SELLER.captureWorkflow}/${vehicle.id}`, {
                  state: { id: vehicle.id }
                });
              }
            }}
          >
            Edit
          </Button>
          <IconButton
            aria-describedby={'icon-button'}
            onClick={handleClick}
            sx={{
              backgroundColor:
                theme.palette.mode === 'dark' ? '#2D2F3D' : theme.palette.background.paper,
              padding: '5px',
              borderRadius: '5px',
              marginLeft: '10px'
            }}
          >
            <MoreVertIcon sx={{ color: theme.palette.mode === 'dark' ? '#989DB1' : '' }} />
          </IconButton>
          {vehicle.captureComplete &&
          currentUser.currentDealershipRole.toLowerCase() !== 'capturerep' ? (
            <Popover
              id={id}
              open={open}
              anchorEl={anchorPopup}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              sx={{ width: 240 }}
              PaperProps={{ classes: { root: styles.menuPopover } }}
            >
              {ALL_MENU_OPTIONS.map((option, index) => (
                <MenuItem
                  key={option.label}
                  to={option.linkTo}
                  component={RouterLink}
                  onClick={(event: any) => handleMenuItemClick(event, index)}
                  sx={{ typography: 'body2', py: 1, px: 2.5 }}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Popover>
          ) : (
            <Popover
              id={id}
              open={open}
              anchorEl={anchorPopup}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              sx={{ width: 220 }}
              PaperProps={{ classes: { root: styles.menuPopover } }}
            >
              {MENU_OPTIONS.map((option, index) => (
                <MenuItem
                  key={option.label}
                  to={option.linkTo}
                  component={RouterLink}
                  onClick={(event: any) => handleMenuItemClick(event, index)}
                  sx={{ typography: 'body2', py: 1, px: 2.5 }}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Popover>
          )}
          <Popover
            id={idSub}
            open={openSub}
            anchorEl={anchorPopupSub}
            onClose={handleCloseSub}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            sx={{ width: 220 }}
            PaperProps={{ classes: { root: styles.menuPopover } }}
          >
            {SUB_MENU_OPTIONS.map((option, index) => (
              <MenuItem
                key={option.label}
                to={option.linkTo}
                component={RouterLink}
                onClick={(event: any) => handleSubMenuItemClick(event, index)}
                sx={{ typography: 'body2', py: 1, px: 2.5 }}
              >
                {option.label}
              </MenuItem>
            ))}
          </Popover>
        </Grid>
        {/* {vehicle && (
          <SharedDrawer
            isOpenSidebar={openDrawer}
            onCloseSidebar={handleCloseDrawer}
            drawerContent={
              <CaptureHistoryDrawerContent onClose={handleCloseDrawer} vehicle={vehicle} />
            }
          />
        )}
        {vehicle && (
          <AuctionDrawer
            vehicle={vehicle}
            tabIndex={0}
            isOpenSidebar={openVDPDrawer}
            onCloseSidebar={() => setOpenVDPDrawer(!openVDPDrawer)}
          />
        )} */}
        <Dialog open={openModal} onClose={handleCloseModal} sx={{ zIndex: '9999999' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '20px',
              position: 'relative',
              minWidth: '500px',
              borderBottom: `1px solid ${theme.palette.divider}`
            }}
          >
            <Typography component="p" variant="h5">
              Cancel Sale
            </Typography>
            <CloseIcon
              onClick={handleCloseModal}
              sx={{
                position: 'absolute',
                right: '10px',
                cursor: 'pointer'
              }}
            />
          </Box>
          <Box sx={{ padding: '40px' }}>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: '10px'
              }}
            ></Box>
            <Box sx={{ textAlign: 'center' }}>
              <Typography>Are you sure you want to CANCEL this sale?</Typography>
            </Box>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              padding: '0px 40px 20px 40px'
            }}
          >
            <Button
              size="large"
              variant="contained"
              className={styles.cancelButton}
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
            <LoadingButton
              size="large"
              type="submit"
              variant="contained"
              className={styles.declineButton}
            >
              Confirm
            </LoadingButton>
          </Box>
        </Dialog>
      </Grid>
      <Dialog open={deleteDialogOpen} onClose={handleDeleteDialogClose}>
        <DialogContent>
          <Box
            sx={{
              width: 500,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-evenly'
            }}
          >
            <Box
              sx={{
                width: '100%',
                mb: 2,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              <div></div>
              <Typography variant="h6">Warning</Typography>
              <CloseIcon
                sx={{ fontSize: '18px', cursor: 'pointer' }}
                onClick={handleDeleteDialogClose}
              />
            </Box>
            <Divider
              sx={{
                width: '100%',
                borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
              }}
            />
            <Box
              sx={{
                width: '80%',
                mt: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center'
              }}
            >
              <Typography variant="h4">You are about to delete a vehicle!</Typography>
              {/* prettier-ignore */}
              <Typography sx={{ fontSize: '14px', mt: 1 }}>
                Deleting a vehicle is <span style={{ fontWeight: 'bold' }}>permanent</span>.
                &nbsp;Click Confirm Delete only if you are sure you want to do this.
              </Typography>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          <Button
            type="button"
            variant="contained"
            onClick={async () => {
              await deleteVehicle(vehicle);
              handleDeleteDialogClose();
            }}
          >
            Confirm Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isOpenModal} onClose={handleToggleModal}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '20px',
            position: 'relative',
            borderBottom: `1px solid ${theme.palette.divider}`
          }}
        >
          <Typography component="p" variant="h5">
            {ALL_MENU_OPTIONS[selectedIndex].label.toLowerCase() === 'move to: launching soon'
              ? 'Launching Soon'
              : 'Active Auction'}
          </Typography>
          <CloseIcon
            onClick={handleToggleModal}
            sx={{
              position: 'absolute',
              right: '10px',
              cursor: 'pointer'
            }}
          />
        </Box>
        <Box>
          {ALL_MENU_OPTIONS[selectedIndex].label.toLowerCase() === 'move to: launching soon' && (
            <LaunchSoonContent vehicleId={vehicle.id} handleClose={handleToggleModal} />
          )}
          {ALL_MENU_OPTIONS[selectedIndex].label.toLowerCase() === 'move to: active' && (
            <ActiveContent
              vehicleId={vehicle.id}
              vehicle={vehicle}
              handleClose={handleToggleModal}
            />
          )}
        </Box>
      </Dialog>
      <Dialog open={dialogOpen} onClose={handleDialogChange}>
        <Box>
          <DialogTitle>Not Available</DialogTitle>
          <CloseIcon
            onClick={handleDialogChange}
            sx={{
              cursor: 'pointer',
              position: 'absolute',
              right: '15px',
              top: '24px'
            }}
          />
        </Box>
        <DialogContent>
          You're on a mobile device, please use the mobile application to capture your vehicle.
        </DialogContent>
      </Dialog>
    </>
  );
}
