import { Box, Button, Chip, IconButton, Tooltip, Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { FC, useEffect, useRef, useState } from 'react';
import '../buyer/auctions/ImageCarousel.css';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import PushPinIcon from '@mui/icons-material/PushPin';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { VehicleInfo } from 'types/vehicleInfo';
import useAuth from 'hooks/useAuth';
import moment from 'moment';
import { useFirestore } from 'reactfire';
import { doc, getDoc, runTransaction, Timestamp } from 'firebase/firestore';
import CountDownTimer from 'utils/CountDownTimer';
import RunTimeCalc from 'utils/RuntimeCalc';
import PercentageCalc from 'utils/PercentageCalc';
import { Dealership } from 'types/dealership';
import { ImageCarousel } from './ImageCarousel';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import PreAuctionPercentageCalc from 'utils/preAuctionPercentageCalc';
import InfoIcon from '@mui/icons-material/Info';
import firebase from 'firebase/compat';
import { database } from 'index';
import { onValue, ref, set } from 'firebase/database';

interface Props {
  vehicle: VehicleInfo;
  handleExpand: any;
  handleFavourite: any;
  width: string;
  mediaQuery: number;
  isPreAuction: boolean;
  sendRunTimeToParent: (runtime: any) => void;
}

const imageFilters = [
  {
    label: `Damages`,
    isSelected: false
  },
  {
    label: `Exterior`,
    isSelected: false
  },
  {
    label: `Interior`,
    isSelected: false
  },
  {
    label: 'All',
    isSelected: true
  }
];

let filteredImages: any[] = [];

const VehicleGallery: FC<Props> = React.memo(function VehicleGallery({
  vehicle,
  width,
  handleExpand,
  handleFavourite,
  mediaQuery,
  isPreAuction,
  sendRunTimeToParent
}) {
  const theme = useTheme();
  const { user } = useAuth();
  const firestore = useFirestore();
  const indexRef = useRef<number>(0);

  const useStyles = makeStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      minWidth: isPreAuction
        ? '275px'
        : vehicle.auction.isExpanded.includes(user?.uid) && !isPreAuction
        ? '400px'
        : '',
      border:
        theme.palette.mode === 'dark'
          ? `1px solid ${theme.palette.text.secondary}`
          : `1px solid ${theme.palette.divider}`
    },
    buttonHeaderContainer: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '30px',
      position: 'relative',
      zIndex: 999
    },
    buttonHeaderLeft: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start'
    },
    buttonHeaderRight: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
      marginRight: '10px'
    },
    iconButtonContainer: {
      marginLeft: '2px'
    },
    iconButton: {
      width: '20px',
      height: '20px',
      color: 'white'
    },
    timeRemaining: {
      lineHeight: 1,
      color: 'white',
      fontSize: '13px',
      fontWeight: '700'
    },
    stockChip: {
      position: 'absolute',
      bottom: '10px',
      // top: '40px',
      right: '10px',
      fontWeight: '700',
      color: 'white',
      zIndex: '999',
      fontSize: '11px'
    },
    reserveChip: {
      position: 'absolute',
      top: '40px',
      right: '10px',
      fontWeight: '700',
      color: 'white',
      zIndex: '999',
      fontSize: '11px'
    },
    imageBox: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start'
    },
    filterChipContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '45px 0px 0px 0px',
      width: '100%'
    },
    filterChip: {
      margin: '5px',
      color:
        theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.info.lighter,
      fontWeight: '700',
      '&.active': {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.lighter
      }
    },
    slider: {
      display: 'flex',
      flexDirection: 'row',
      position: 'absolute',
      bottom: '-45px',
      width: 'calc(100% - 80px)',
      // overflowX: 'scroll',
      overflow: 'hidden',
      scrollBehavior: 'smooth'
    },
    carouselButtons: {
      position: 'absolute',
      bottom: '-33px',
      padding: '5px !important',
      zIndex: '999',
      backgroundColor: `${theme.palette.primary.main} !important`
    },
    carouselPointer: {
      color: 'white',
      width: '20px',
      height: '20px'
    },
    carouselImages: {
      margin: '0',
      width: '100%',
      // height: '208px',
      height: '200px',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat'
    },
    carImage: {
      height: '130px',
      width: '100%',
      objectFit: 'cover',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    containerStyles: {
      backgroundColor: theme.palette.primary.dark,
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '30px',
      position: 'absolute',
      zIndex: 0
    }
  });

  const styles = useStyles();
  // prettier-ignore
  let allImages = vehicle.photos && vehicle.photos.length > 0 ? vehicle.photos.filter((photo) => Object.keys(photo).includes('url') && photo.url !== '') : null;
  if (allImages && allImages.length > 0) {
    allImages?.forEach((image: any, index: number) => {
      if (image.categoryName.toLowerCase() === 'damages') {
        allImages?.splice(index, 1);
        allImages?.unshift(image);
      }
    });
    // prettier-ignore
    let initialIndex = allImages.findIndex((image: any) => image.location.toLowerCase().includes('3/4 shot'));
    if (initialIndex && initialIndex >= 0) {
      indexRef.current = initialIndex;
    } else {
      indexRef.current = 0;
    }
  }

  const [, setHours] = useState<any>();
  const [, setMinutes] = useState<any>();
  const [, setSeconds] = useState<any>();

  let [filters, setFilters] = useState(imageFilters);
  let [images, setImages] = useState(allImages);
  const timerRef = useRef<any>(null);
  const [extCompleted, setExtCompleted] = useState<number>(100);
  const [countdownSet, setCountdownSet] = useState<boolean>(false);
  const [extRunTime, setExtRunTime] = useState<string>();
  const [previousCurrentPrice, setPreviousCurrentPrice] = useState<number>(
    vehicle.auction.currentPrice ? vehicle.auction.currentPrice : 0
  );

  useEffect(() => {
    if (vehicle && vehicle.auction.launchDate && vehicle.auction.launchTime) {
      const timezoneOffset = new Date().getTimezoneOffset();
      // prettier-ignore
      const UTCEndDateMoment = moment(vehicle.auction.launchDate.toDate()).clone().add(timezoneOffset, 'minutes');
      // prettier-ignore
      const UTCEndTimeMoment = moment(vehicle.auction.launchTime.toDate()).clone().add(timezoneOffset, 'minutes');
      const UTCendDate = UTCEndDateMoment.toDate();
      const UTCendTime = UTCEndTimeMoment.toDate();
      UTCendDate.setHours(UTCendTime.getHours());
      UTCendDate.setMinutes(UTCendTime.getMinutes());
      UTCendDate.setSeconds(UTCendTime.getSeconds());
      UTCendDate.setMilliseconds(UTCendTime.getMilliseconds());
      const now = moment().clone().add(timezoneOffset, 'minutes');
      const duration = moment.duration(moment(UTCendDate).diff(now));
      let hoursRemaining = moment(UTCendDate).diff(moment(), 'hours');
      setHours(hoursRemaining);
      const minutesRemaining = duration.minutes();
      setMinutes(minutesRemaining);
      const secondsRemaining = duration.seconds();
      setSeconds(secondsRemaining);
      setCountdownSet(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicle]);

  let vehiclePhoto;
  if (vehicle.photos) {
    const photos = vehicle.photos.filter(
      (v: any) => v.url && v.location && v.location.toLowerCase().includes('3/4 shot')
    );
    if (photos[0]) {
      vehiclePhoto = photos[0];
    }
  }

  let dmgNum: number;
  let intNum: number;
  let extNum: number;
  let allNum: number;

  if (allImages) {
    const intImages = allImages.filter((img: any) => img.categoryName === 'Interior');
    const extImages = allImages.filter((img: any) => img.categoryName === 'Exterior');
    const dmgImages = allImages.filter(
      (img: any) => img.categoryName === 'Damages' && img.fileName
    );
    dmgNum = dmgImages.length;
    intNum = intImages.length;
    extNum = extImages.length;
    allNum = dmgImages.length + intImages.length + extImages.length;
  }

  const db = database;
  const [serverOffset, setServerOffset] = useState<number>();
  const [countdownSeconds, setCountdownSeconds] = useState<number>();
  const [countdownStartAt, setCountdownStartAt] = useState<any>();

  const getServerOffset = () => {
    const serverTimeRef = ref(db, '.info/serverTimeOffset');
    onValue(
      serverTimeRef,
      (snapshot) => {
        setServerOffset(snapshot.val());
      },
      { onlyOnce: true }
    );
  };

  const getCountdown = () => {
    const countdownRef = ref(db, `countdown/${vehicle.id}`);
    set(countdownRef, {
      startAt: firebase.database.ServerValue.TIMESTAMP,
      seconds: 15
    });
    onValue(
      countdownRef,
      (snapshot) => {
        const seconds = snapshot.val().seconds;
        const startAt = snapshot.val().startAt;
        setCountdownSeconds(seconds);
        setCountdownStartAt(startAt);
      },
      { onlyOnce: true }
    );
  };

  useEffect(() => {
    if (serverOffset && countdownSeconds && countdownStartAt) {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
      timerRef.current = setInterval(() => {
        const timeLeft = countdownSeconds * 1000 - (Date.now() - countdownStartAt - serverOffset);
        const extRunTime = `${Math.ceil(timeLeft / 1000)}S Left!`;
        setExtRunTime(extRunTime);
        let percentage = Math.round((Math.ceil(timeLeft / 1000) / countdownSeconds) * 100);
        setExtCompleted(percentage);
        if (timeLeft <= 0) {
          clearInterval(timerRef.current);
          SubmitVehicle();
        }
      }, 1000);
      return () => {
        clearInterval(timerRef.current);
      };
    }
  }, [serverOffset, countdownSeconds, countdownStartAt]);

  useEffect(() => {
    if (vehicle.bidExtension && previousCurrentPrice <= vehicle.auction.currentPrice) {
      setPreviousCurrentPrice(vehicle.auction.currentPrice);
      getServerOffset();
      getCountdown();
      // let secondsRemaining = 15;
      // let totalSecondsRemaining = 15;
      // timerRef.current = setInterval(() => {
      //   secondsRemaining = secondsRemaining - 1;
      //   const extRunTime = `${secondsRemaining}S Left!`;
      //   setExtRunTime(extRunTime);
      //   let percentage = Math.round((secondsRemaining / totalSecondsRemaining) * 100);
      //   setExtCompleted(percentage);
      //   if (secondsRemaining <= 0) {
      //     clearInterval(timerRef.current);
      //     SubmitVehicle();
      //     console.log('vehicle submitted');
      //   }
      // }, 1000);
      // return () => {
      //   if (secondsRemaining <= 0) {
      //     clearInterval(timerRef.current);
      //   }
      // };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    vehicle.auction.currentPrice,
    vehicle.bidExtension,
    previousCurrentPrice,
    vehicle.bidExtensionTime
  ]);

  const SubmitVehicle = async () => {
    const vehicleDocRef = doc(firestore, `vehicles/${vehicle.id}`);
    const docRef = doc(firestore, `dealerships/${vehicle.dealershipId}`);
    const docSnap = await getDoc(docRef);
    const sellerDealership = { id: docSnap.id, ...docSnap.data() } as Dealership;
    const userDocRef = doc(firestore, `users/${vehicle.auction.bidLeaderId}`);
    const userDocSnap = await getDoc(userDocRef);
    const buyingUser = { id: userDocSnap.id, ...userDocSnap.data() } as any;
    const buyingDealerRef = doc(firestore, `dealerships/${buyingUser.currentDealershipId}`);
    const buyingDealerSnap = await getDoc(buyingDealerRef);
    const buyerDealership = { id: buyingDealerSnap.id, ...buyingDealerSnap.data() } as Dealership;
    const endDate = vehicle.auction.endDate.toDate() as Date;
    const nextRunValue = endDate.setDate(endDate.getDate() + 21);
    const nextRunDate = new Date(nextRunValue);
    let nextRunTimestamp: Timestamp | null;
    let timesRan: number;
    let postAuctionStatus: string;
    if (vehicle.sellerMarkAsSold) {
      postAuctionStatus = 'sold';
    } else if (
      vehicle.auction.currentPrice >= vehicle.auction.floorPrice &&
      vehicle.auction.isInStock &&
      !vehicle.sellerMarkAsSold
    ) {
      postAuctionStatus = 'arrived';
    } else if (
      vehicle.auction.currentPrice >= vehicle.auction.floorPrice &&
      !vehicle.auction.isInStock
    ) {
      postAuctionStatus = 'sold';
    } else {
      postAuctionStatus = 'pending';
    }
    if (!vehicle.auction.timesRun || vehicle.auction.timesRun === 0) {
      timesRan = 1;
      nextRunTimestamp = null;
    } else if (vehicle.auction.timesRun && vehicle.auction.timesRun < 3) {
      timesRan = vehicle.auction.timesRun + 1;
      nextRunTimestamp = null;
      if (timesRan === 3) {
        nextRunTimestamp = Timestamp.fromDate(nextRunDate);
        timesRan = 0;
      }
    }

    await runTransaction(firestore, async (transaction) => {
      const vehicleDoc = await transaction.get(vehicleDocRef);
      if (vehicleDoc.exists()) {
        const vehicle = { ...vehicleDoc.data(), id: vehicleDoc.id } as VehicleInfo;
        if (vehicle.auction.auctionStatus !== 'post auction') {
          transaction.set(
            vehicleDocRef,
            {
              buyerDealership: buyerDealership,
              sellerDealership: sellerDealership,
              buyerUser: buyingUser,
              auction: {
                auctionStatus: 'post auction',
                daysPending: 0,
                timesRun: timesRan,
                nextRunDate: nextRunTimestamp,
                negotiationStatusBuyer: 'awaiting response',
                negotiationStatusSeller: 'action required',
                postAuctionStatus: postAuctionStatus,
                dateSold: Timestamp.now(),
                dateArrived: vehicle.auction.isInStock ? Timestamp.now() : ''
              }
            },
            { merge: true }
          );
        }
      }
    });
  };

  const selectFilter = (filter: any) => {
    const updatedFilters: any[] = [];
    filters.forEach((f: any) => {
      if (f.label === filter.label) {
        f.isSelected = true;
      } else {
        f.isSelected = false;
      }
      updatedFilters.push(f);
    });
    filters = updatedFilters;
    setFilters(filters);

    filteredImages = [];
    filters.forEach((updatedFilter: any) => {
      if (updatedFilter.isSelected) {
        allImages?.forEach((image: any) => {
          if (
            image.categoryName.toLowerCase() === updatedFilter.label.toLowerCase() &&
            !filteredImages.includes(image)
          ) {
            filteredImages.push(image);
          }
        });
      }
    });
    if (filteredImages.length > 0) {
      setImages(filteredImages);
    } else {
      setImages(allImages);
    }
  };

  const ProgressBar = (props: any) => {
    const { bgColor, completed } = props;

    const fillerStyles = {
      height: '100%',
      width: `${completed}%`,
      backgroundColor: bgColor,
      borderRadius: 'inherit',
      transition: 'width 1s ease-in-out'
    };

    const labelStyles = {
      padding: 5,
      color: 'white',
      fontWeight: 'bold'
    };

    return (
      <Box className={styles.containerStyles}>
        <div style={fillerStyles}>
          <span style={labelStyles}></span>
        </div>
      </Box>
    );
  };

  const sendRunTimetoRow = (runtime: any) => {
    sendRunTimeToParent(runtime);
  };

  return (
    <Box className={styles.container} sx={{ width: width }}>
      <Box sx={{ position: 'relative', width: '100%' }}>
        {vehicle.bidExtension ? (
          <ProgressBar bgColor={theme.palette.primary.main} completed={extCompleted} />
        ) : (
          <>
            {isPreAuction ? (
              <PreAuctionPercentageCalc vehicle={vehicle} />
            ) : (
              <PercentageCalc vehicle={vehicle} />
            )}
          </>
        )}
        <Box className={styles.buttonHeaderContainer}>
          {!isPreAuction ? (
            <Box className={styles.buttonHeaderLeft} id="activeAuction6">
              <Tooltip title="View">
                <IconButton
                  className={styles.iconButtonContainer}
                  onClick={() => {
                    handleExpand(vehicle.id, vehicle.auction.isExpanded.includes(user?.uid));
                  }}
                >
                  {vehicle && vehicle.auction.isExpanded.includes(user?.uid) ? (
                    <UnfoldLessIcon className={styles.iconButton} />
                  ) : (
                    <UnfoldMoreIcon className={styles.iconButton} />
                  )}
                </IconButton>
              </Tooltip>
              <Tooltip title="Pin Priority">
                <IconButton
                  className={styles.iconButtonContainer}
                  onClick={() => {
                    handleFavourite(vehicle.id, vehicle.auction.isFavourite.includes(user?.uid));
                  }}
                >
                  {vehicle && vehicle.auction.isFavourite.includes(user?.uid) ? (
                    <PushPinIcon className={styles.iconButton} />
                  ) : (
                    <PushPinOutlinedIcon className={styles.iconButton} />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
          ) : (
            <Box
              className={styles.buttonHeaderLeft}
              sx={{
                marginLeft: '10px',
                fontSize: '13px',
                fontWeight: '700',
                color: theme.palette.common.white
              }}
            >
              <AccessTimeIcon className={styles.iconButton} />
              {countdownSet && <CountDownTimer vehicle={vehicle} />}
            </Box>
          )}
          {!isPreAuction && (
            <Box className={styles.buttonHeaderRight}>
              <AccessTimeIcon className={styles.iconButton} />
              {vehicle.bidExtension ? (
                <Typography className={styles.timeRemaining} component="p">
                  {extRunTime}
                </Typography>
              ) : (
                <RunTimeCalc vehicle={vehicle} sendRunTimeToParent={sendRunTimetoRow} />
              )}
            </Box>
          )}
        </Box>
        {/* prettier-ignore */}
        <Chip
          label={vehicle.auction.isInStock ? 'In Stock' : 'Appraisal'}
          className={styles.stockChip}
          sx={{
            backgroundColor: vehicle.auction.isInStock
              ? theme.palette.success.main
              : '#FD7E14' // theme.palette.error.main
          }}
        ></Chip>
        {(vehicle.auction.currentPrice >= vehicle.auction.floorPrice ||
          vehicle.sellerMarkAsSold) && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flexStart',
              fontSize: '12px',
              width: '100%',
              height: '20px',
              // color: '#FFC107',
              color: theme.palette.success.main,
              // backgroundColor: '#FDF0CC',
              backgroundColor: theme.palette.success.light,
              position: 'absolute',
              top: '30px',
              zIndex: '99',
              fontWeight: 'bold'
            }}
          >
            <InfoIcon
              sx={{
                marginLeft: '5px',
                marginRight: '5px',
                width: '15px',
                height: '15px'
              }}
            />
            No Reserve
          </Box>
        )}
        <Box sx={{ position: 'relative' }}>
          {vehicle && vehicle.auction.isExpanded.includes(user?.uid) && !isPreAuction ? (
            <ImageCarousel
              images={images ?? []}
              bottom={'vg'}
              isMarkedAsSold={vehicle.sellerMarkAsSold}
              initialIndex={indexRef.current}
            />
          ) : (
            <LazyLoadImage
              sx={{
                objectFit: 'cover',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
              style={{ height: '137px' }}
              src={vehiclePhoto?.url}
              alt="vehicle photo"
              effect="blur"
              height={130}
              width={'100%'}
            />
            // <Box
            //   component="img"
            //   alt="vehicle photo"
            //   src={vehiclePhoto?.url}
            //   className={styles.carImage}
            // />
          )}
        </Box>
      </Box>
      {vehicle && vehicle.auction.isExpanded.includes(user?.uid) && !isPreAuction ? (
        <Box className={styles.filterChipContainer}>
          {filters.map((filter: any) => (
            <Button
              key={filter.label}
              onClick={() => selectFilter(filter)}
              sx={{
                padding: '0'
              }}
            >
              {/* prettier-ignore */}
              <Chip
                label={`${filter.label} (${filter.label === 'Damages'
                    ? dmgNum
                    : filter.label === 'Interior'
                    ? intNum
                    : filter.label === 'Exterior'
                    ? extNum
                    : allNum
                  })`}
                className={styles.filterChip}
                sx={{
                  color: filter.isSelected ? theme.palette.primary.main : '',
                  backgroundColor: filter.isSelected ? theme.palette.primary.lighter : '',
                  cursor: 'pointer'
                }}
              ></Chip>
            </Button>
          ))}
        </Box>
      ) : (
        <Box></Box>
      )}
    </Box>
  );
});

export default VehicleGallery;
