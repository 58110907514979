import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Box, Typography, Chip, Tab, Tabs, Button } from '@mui/material';
import Scrollbar from 'components/Scrollbar';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { VehicleInfo, VehiclePhotos } from 'types/vehicleInfo';
import DeclarationsTab from 'components/auction/drawer/DeclarationsTab';
import DamagesTab from 'components/auction/drawer/DamagesTab';
import EquipmentTab from 'components/auction/drawer/EquipmentTab';
import OverviewTab from 'components/auction/drawer/OverviewTab';
import { useFirestore } from 'reactfire';
import { doc, getDoc } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import { ImageCarousel } from 'components/shared/ImageCarousel';

const imageFilters = [
  {
    label: 'Damages',
    isSelected: false
  },
  {
    label: 'Exterior',
    isSelected: false
  },
  {
    label: 'Interior',
    isSelected: false
  },
  {
    label: 'All',
    isSelected: true
  }
];

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export default function VDPPage() {
  const firestore = useFirestore();
  const theme = useTheme();

  const { vehicleId } = useParams();

  const [vehicle, setVehicle] = useState<VehicleInfo>();
  const [dmgNum, setDmgNum] = useState<number>();
  const [intNum, setIntNum] = useState<number>();
  const [extNum, setExtNum] = useState<number>();
  const [allNum, setAllNum] = useState<number>();
  const [tabValue, setTabValue] = useState(0);
  const [allImages, setAllImages] = useState<VehiclePhotos[]>();

  let [filters, setFilters] = useState(imageFilters);
  let [images, setImages] = useState<VehiclePhotos[]>();
  let filteredImages: any[] = [];

  const getCurrentVehicle = async () => {
    if (vehicleId) {
      const docRef = doc(firestore, `vehicles/${vehicleId}`);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const currentVehicle = { id: docSnap.id, ...docSnap.data() } as VehicleInfo;
        const allImages = currentVehicle.photos;
        const intImages = allImages.filter((img: any) => img.categoryName === 'Interior');
        const extImages = allImages.filter((img: any) => img.categoryName === 'Exterior');
        const dmgImages = allImages.filter((img: any) => img.categoryName === 'Damages');
        setVehicle(currentVehicle);
        setImages(allImages);
        setAllImages(allImages);
        setDmgNum(dmgImages.length);
        setIntNum(intImages.length);
        setExtNum(extImages.length);
        setAllNum(dmgImages.length + intImages.length + extImages.length);
      }
    }
  };

  const a11yProps = (index: number) => {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`
    };
  };

  const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const selectFilter = (filter: any) => {
    const updatedFilters: any[] = [];
    filters.forEach((f: any) => {
      if (f.label === filter.label) {
        f.isSelected = true;
      } else {
        f.isSelected = false;
      }
      updatedFilters.push(f);
    });
    filters = updatedFilters;
    setFilters(filters);
    filteredImages = [];
    filters.forEach((updatedFilter: any) => {
      if (updatedFilter.isSelected) {
        allImages?.forEach((image: any) => {
          if (
            image.categoryName.toLowerCase() === updatedFilter.label.toLowerCase() &&
            !filteredImages.includes(image)
          ) {
            filteredImages.push(image);
          }
        });
      }
    });
    if (filteredImages.length > 0) {
      setImages(filteredImages);
    } else {
      setImages(allImages);
    }
  };

  const useStyles = makeStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.palette.divider,
      [theme.breakpoints.down(1450)]: {
        width: '100%'
      }
    },
    buttonHeaderContainer: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 10px',
      height: '40px',
      backgroundColor: theme.palette.primary.main
    },
    iconButtonContainer: {
      marginLeft: '2px'
    },
    iconButton: {
      width: '25px',
      height: '25px',
      color: 'white'
    },
    timeRemaining: {
      lineHeight: 1,
      marginRight: '5px',
      color: 'white',
      fontSize: '14px',
      fontWeight: '700'
    },
    stockChip: {
      position: 'absolute',
      top: '50px',
      right: '10px',
      fontWeight: '700',
      color: 'white',
      zIndex: '999'
    },
    imageBox: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start'
    },
    filterChipContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '10px 0px',
      width: '100%'
    },
    filterChip: {
      margin: '5px 10px',
      color:
        theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.info.lighter,
      fontWeight: '700',
      '&.active': {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.lighter
      }
    },
    slider: {
      display: 'flex',
      flexDirection: 'row',
      position: 'absolute',
      bottom: '-70px',
      width: 'calc(100% - 80px)',
      overflowX: 'scroll',
      scrollBehavior: 'smooth'
    }
  });

  useEffect(() => {
    getCurrentVehicle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const styles = useStyles();

  const content = (
    <Scrollbar
      sx={{
        height: '100%',
        backgroundColor: theme.palette.common.white,
        maxWidth: '900px',
        margin: 'auto'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: 'auto'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            padding: '10px'
          }}
        >
          <Typography component="p" variant="h5">
            {vehicle?.details.year} {vehicle?.details.make}&nbsp;
            {vehicle?.details.model} {vehicle?.details.trim}
          </Typography>
        </Box>
        <Box sx={{ position: 'relative', width: '100%' }}>
          <Box className={styles.buttonHeaderContainer}>
            <AccessTimeIcon className={styles.iconButton} />
            <Typography className={styles.timeRemaining} component="p">
              {vehicle?.auction.runTime}
            </Typography>
          </Box>
          <Box sx={{ position: 'relative' }}>
            {allImages && <ImageCarousel images={images ?? []} />}
            <Box className={styles.filterChipContainer}>
              {filters &&
                filters.map((filter: any) => (
                  <Button
                    key={filter.label}
                    onClick={() => selectFilter(filter)}
                    sx={{
                      padding: '0'
                    }}
                  >
                    {/* prettier-ignore */}
                    <Chip
                      label={`${filter.label} (${filter.label === 'Damages'
                          ? dmgNum 
                          : filter.label === 'Interior'
                          ? intNum
                          : filter.label === 'Exterior'
                          ? extNum
                          : allNum
                        })`}
                      className={styles.filterChip}
                      sx={{
                        color: filter.isSelected ? theme.palette.primary.main : '',
                        backgroundColor: filter.isSelected ? theme.palette.primary.lighter : '',
                        cursor: 'pointer'
                      }}
                    ></Chip>
                  </Button>
                ))}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: '100%',
            marginTop: '70px'
          }}
        >
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons
            indicatorColor="secondary"
            textColor="inherit"
            aria-label="vehicle details tabs"
            sx={{
              backgroundColor: theme.palette.background.paper,
              '& .MuiTabs-indicator': {
                display: 'none'
              },
              '& .Mui-selected': {
                backgroundColor: theme.palette.secondary.main,
                color: `${theme.palette.common.white} !important`
              }
            }}
          >
            <Tab
              title="Announcements/Disclosures"
              sx={{
                borderRadius: '0px',
                marginRight: '0px !important'
              }}
              {...a11yProps(0)}
              icon={<AssignmentLateIcon />}
            />

            <Tab
              title="Damages/Conditions"
              sx={{
                borderRadius: '0px',
                marginRight: '0px !important'
              }}
              {...a11yProps(1)}
              icon={<AssignmentIcon />}
            />

            <Tab
              title="Equipment"
              sx={{
                borderRadius: '0px',
                marginRight: '0px !important'
              }}
              {...a11yProps(2)}
              icon={<AssignmentTurnedInIcon />}
            />

            <Tab
              title="Vehicle Overview"
              sx={{
                borderRadius: '0px',
                marginRight: '0px !important'
              }}
              {...a11yProps(1)}
              icon={<DirectionsCarIcon />}
            />

            <Tab
              title="Seller’s Contact"
              sx={{
                borderRadius: '0px',
                marginRight: '0px !important'
              }}
              {...a11yProps(2)}
              icon={<AssignmentIndIcon />}
            />
          </Tabs>
          <TabPanel value={tabValue} index={0}>
            {vehicle && <DeclarationsTab vehicle={vehicle as VehicleInfo} />}
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            {vehicle && <DamagesTab vehicle={vehicle as VehicleInfo} />}
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            {vehicle && <EquipmentTab vehicle={vehicle as VehicleInfo} />}
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            {vehicle && <OverviewTab vehicle={vehicle as VehicleInfo} />}
          </TabPanel>
        </Box>
      </Box>
    </Scrollbar>
  );

  return <Box sx={{ backgroundColor: theme.palette.common.white }}>{content}</Box>;
}
