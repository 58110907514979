import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Dialog,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { VehicleDetailsType, VehicleInfo } from 'types/vehicleInfo';
// import { boxStyle } from './vehicleCaptureStyles';
import { loadVinDataAPI } from './loadVinDataAPI';
import { useTheme } from '@mui/material/styles';
import { loadAutodevApi } from './loadAutodevApi';
import { loadFactoryOptions } from './loadFactoryOptions';

type Props = {
  vehicle: VehicleInfo;
  updVehicle: (vehicle: VehicleInfo, flag: number) => void;
  goToNext: (flag: number) => void;
};

export function VehicleVIN({ vehicle, updVehicle, goToNext }: Props) {
  const theme = useTheme();
  const vehicleDetails = vehicle.details || ({} as VehicleDetailsType);
  // const [vinValue, setVinValue] = useState(vehicle.details?.vin ? 'manVin' : 'noVin');
  const [vinValue, setVinValue] = useState('manVin');
  const [vinNumber, setVinNumber] = useState(vehicle.details?.vin || '');
  const [openError, setOpenError] = useState(false);

  const boxStyle = {
    textAlign: 'start',
    width: 400,
    height: 60,
    backgroundColor: theme.palette.mode !== 'dark' ? theme.palette.common.white : 'unset',
    border: `1px ${theme.palette.divider} solid`,
    borderRadius: '10px',
    fontWeight: 300,
    padding: 0,
    mb: 1,
    overflow: 'hidden'
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleVinNumber = (e: any) => {
    setVinNumber(e.target.value);
    const t = { ...vehicleDetails };
    t.vin = e.target.value;
    vehicle.details = t;
  };

  const handleClick = async (event: any) => {
    if (vinValue === 'manVin') {
      const vinData = await loadVinDataAPI(vehicle);
      if (vinData) {
        console.log('vin data year', vinData.details.year);
        if (vinData.details.year) {
          vinData.details.year = parseInt(vinData.details.year?.toString());
        }
        const factoryOptions = await loadFactoryOptions(vehicle);
        vinData.equipment.factoryInfo = factoryOptions ? factoryOptions : ['No Factory Info Found'];
        updVehicle(vinData, 1);
      } else if (!vinData) {
        const autodevData = await loadAutodevApi(vehicle);
        if (autodevData) {
          const factoryOptions = await loadFactoryOptions(vehicle);
          autodevData.equipment.factoryInfo = factoryOptions
            ? factoryOptions
            : ['No Factory Info Found'];
          updVehicle(autodevData as any, 1);
        } else {
          setOpenError(true);
          return;
        }
      }
    } else {
      goToNext(1);
    }
  };

  return (
    <>
      <Dialog open={openError} onClose={() => setOpenError(!openError)}>
        <Alert severity="error" color="error">
          <AlertTitle>Error!</AlertTitle>
          VIN does not exists in the database.
        </Alert>
      </Dialog>

      {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button variant="outlined" onClick={() => updVehicle(vehicle, 1)}>
          Save and Exit
        </Button>
      </Box> */}

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          backgroundColor:
            theme.palette.mode === 'dark'
              ? theme.palette.background.neutral
              : theme.palette.grey[50],
          borderRadius: '10px',
          maxWidth: '850px',
          width: '100%',
          border: '1px solid rgba(145, 158, 171, 0.24)',
          margin: 'auto',
          marginTop: '20px',
          paddingBottom: '20px'
        }}
      >
        <Box>
          <Box sx={{ mt: 3, width: 400 }}>
            <Typography
              variant="h3"
              sx={{
                textAlign: 'center',
                color: theme.palette.text.primary
              }}
            >
              Enter VIN
            </Typography>
          </Box>
          <Box sx={{ mt: 2, width: 400 }}>
            <Typography
              variant="subtitle2"
              sx={{
                fontWeight: '500',
                textAlign: 'center',
                color: theme.palette.text.secondary
              }}
            >
              Enter VIN number located by the driver's door jamb, or on the driver's side
              windshield. To SCAN your VIN, please use the DealerGavel Mobile Version.
            </Typography>
          </Box>
          {/* prettier-ignore */}
          <Divider
            sx={{
              mt: 2,
              mb: 2,
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }} 
          />
        </Box>
        <Box sx={{ mt: 2, width: 400 }}>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={vinValue}
              name="radio-buttons-group"
              onChange={(e) => {
                setVinValue(e.target.value);
                if (e.target.value === 'noVin') {
                  const t = { ...vehicleDetails };
                  t.vin = '';
                  vehicle.details = t;
                }
              }}
            >
              <Box sx={boxStyle}>
                <FormControlLabel
                  sx={{ ml: 3, mt: 1 }}
                  value="manVin"
                  control={<Radio />}
                  label="Enter VIN Manually"
                />
              </Box>
              {vinValue === 'manVin' && (
                <Box sx={boxStyle}>
                  <TextField
                    sx={{ ml: 4, mt: 0, width: '80%' }}
                    required
                    variant="standard"
                    id="outlined-required"
                    label="VIN"
                    onChange={handleVinNumber}
                    defaultValue={vinNumber}
                  />
                </Box>
              )}
              <Box sx={boxStyle}>
                <FormControlLabel
                  sx={{ ml: 3, mt: 1 }}
                  value="noVin"
                  control={<Radio />}
                  label="I do not have a VIN"
                />
              </Box>
            </RadioGroup>
          </FormControl>
        </Box>
      </Box>
      <Divider
        sx={{
          mt: 2,
          borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
        }}
      />
      <Box sx={{ m: 2, display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          onClick={async () => {
            await handleClick(vinNumber);
          }}
        >
          Next: Vehicle Details
        </Button>
      </Box>
    </>
  );
}
