import {
  Grid,
  Typography,
  Button,
  TextField,
  Box,
  Divider,
  Tabs,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Tooltip
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { MIconButton } from 'components/@material-extend';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Dealership } from 'types/dealership';
import CloseIcon from '@mui/icons-material/Close';
import { useFirestore } from 'reactfire';
import {
  doc,
  updateDoc,
  Timestamp,
  getDoc,
  arrayRemove,
  arrayUnion,
  collection,
  CollectionReference,
  query,
  where,
  onSnapshot,
  orderBy,
  limit,
  startAfter,
  getDocs
} from 'firebase/firestore';
import { fDateTimeSuffix } from 'components/shared/dateTimeFormat';
import { dealerRating } from 'types/dealerRating';
import useAuth from 'hooks/useAuth';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Rating from '@mui/material/Rating';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DirectionsCarFilledOutlinedIcon from '@mui/icons-material/DirectionsCarFilledOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { useFirestoreByPath } from 'hooks/useFirestoreByPath';

type Props = {
  dealership: Dealership;
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`notifications-tabpanel-${index}`}
      aria-labelledby={`notifications-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0, mt: '10px' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `notifications-tab-${index}`,
    'aria-controls': `notifications-tabpanel-${index}`
  };
};

const DrawerDealerRatings = ({ dealership }: Props) => {
  const theme = useTheme();
  const firestore = useFirestore();
  const { user, currentUser } = useAuth();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [tabValue, setTabValue] = useState(0);
  const [expandedMyBuy, setExpandedMyBuy] = useState<boolean>(true);
  const [expandedBuy, setExpandedBuy] = useState<boolean>(true);
  const [expandedMySell, setExpandedMySell] = useState<boolean>(true);
  const [expandedSell, setExpandedSell] = useState<boolean>(true);
  const [currentDealership, setCurrentDealership] = useState<Dealership>();
  const [buyerRatings, setBuyerRatings] = useState<dealerRating[]>();
  const [sellerRatings, setSellerRatings] = useState<dealerRating[]>();
  const [myBuyRatings, setMyBuyRatings] = useState<dealerRating[]>();
  const [mySellRatings, setMySellRatings] = useState<dealerRating[]>();
  const [totalBuyRatings, setTotalBuyRatings] = useState<number>(0);
  const [totalSellRatings, setTotalSellRatings] = useState<number>(0);
  const [averageBuyRating, setAverageBuyRating] = useState<string>();
  const [averageSellRating, setAverageSellRating] = useState<string>();
  const [avgSellStars, setAvgSellStars] = useState<number>();
  const [avgBuyStars, setAvgBuyStars] = useState<number>();
  const [editDisabled, setEditDisabled] = useState<boolean>(false);

  const [ratingComment, setRatingComment] = useState<string>();
  const [paymentRating, setPaymentRating] = useState<number>();
  const [pickupRating, setPickupRating] = useState<number>();
  const [conditionRating, setConditionRating] = useState<number>();
  const [ownershipRating, setOwnershipRating] = useState<number>();
  const [salesRating, setSalesRating] = useState<number>();

  const [lastBuyDoc, setLastBuyDoc] = useState<any>();
  const [lastMyBuyDoc, setLastMyBuyDoc] = useState<any>();
  const [lastSellDoc, setLastSellDoc] = useState<any>();
  const [lastMySellDoc, setLastMySellDoc] = useState<any>();

  const { update: updateRating, remove: deleteRating } =
    useFirestoreByPath<dealerRating>('ratings');
  const { update: updateDealership } = useFirestoreByPath<Dealership>('dealerships');

  const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
      color: theme.palette.success.main
    },
    '& .MuiRating-iconHover': {
      color: theme.palette.success.main
    }
  });

  interface StyledTabsProps {
    children?: React.ReactNode;
    value: number;
    onChange: (event: React.SyntheticEvent, newValue: number) => void;
  }

  const StyledTabs = styled((props: StyledTabsProps) => (
    <Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />
  ))(({ theme }) => ({
    '& .MuiTab-root': {
      color:
        theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.text.secondary,
      backgroundColor:
        theme.palette.mode === 'dark'
          ? theme.palette.background.neutral
          : theme.palette.background.paper
    },
    '& .MuiTab-root:not(:last-of-type)': {
      marginRight: '10px'
    },
    '& .MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent !important',
      padding: '5px'
    },
    '& .Mui-selected': {
      backgroundColor: theme.palette.primary.lighter,
      color: `${theme.palette.primary.main} !important`
    },
    '& .MuiTabs-indicatorSpan': {
      display: 'none',
      maxWidth: 40,
      width: '100%',
      backgroundColor: theme.palette.chart.violet[2]
    }
  }));

  const handleExpandedMyBuy = () => {
    setExpandedMyBuy(!expandedMyBuy);
  };

  const handleExpandedBuy = () => {
    setExpandedBuy(!expandedBuy);
  };

  const handleExpandedMySell = () => {
    setExpandedMySell(!expandedMySell);
  };

  const handleExpandedSell = () => {
    setExpandedSell(!expandedSell);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleEditRating = async (rating: dealerRating) => {
    await updateRating(rating.id as string, { isEditing: true });
  };

  const handleSaveRating = async (rating: dealerRating) => {
    if (rating.ratingType === 'buyer') {
      const ratingString = rating.uniqueId + '+' + parseFloat(rating.overallRating).toFixed(2);
      console.log(ratingString);
      await updateDealership(currentDealership?.id as string, {
        buyerRatings: arrayRemove(ratingString)
      });
      let overallRating = 0;
      let paymentRate = 0;
      let pickupRate = 0;
      if (paymentRating) paymentRate = paymentRating;
      if (pickupRating) pickupRate = pickupRating;
      overallRating = (paymentRate + pickupRate) / 2;
      await updateRating(rating.id as string, {
        paymentRating: paymentRate ? paymentRate : rating.paymentRating,
        pickupRating: pickupRate ? pickupRate : rating.pickupRating,
        overallRating: overallRating?.toFixed(2),
        comments:
          rating.comments && rating.comments !== ''
            ? rating.comments
            : 'No comments were left by the reviewer.',
        isEditing: false
      });
      const newRatingString = rating.uniqueId + '+' + overallRating.toFixed(2);
      await updateDealership(currentDealership?.id as string, {
        buyerRatings: arrayUnion(newRatingString)
      });
      setPaymentRating(undefined);
      setPickupRating(undefined);
      setRatingComment(undefined);
    } else if (rating.ratingType === 'seller') {
      const ratingString = rating.uniqueId + '+' + parseFloat(rating.overallRating).toFixed(2);
      await updateDealership(currentDealership?.id as string, {
        sellerRatings: arrayRemove(ratingString)
      });
      let overallRating = 0;
      let conditionRate = 0;
      let ownershipRate = 0;
      let salesRate = 0;
      if (conditionRating) conditionRate = conditionRating;
      if (ownershipRating) ownershipRate = ownershipRating;
      if (salesRating) salesRate = salesRating;
      overallRating = (conditionRate + ownershipRate + salesRate) / 3;
      await updateRating(rating.id as string, {
        conditionRating: conditionRate ? conditionRate : rating.conditionRating,
        ownershipRating: ownershipRate ? ownershipRate : rating.ownershipRating,
        salesRating: salesRate ? salesRate : rating.salesRating,
        overallRating: overallRating?.toFixed(2),
        comments:
          rating.comments && rating.comments !== ''
            ? rating.comments
            : 'No comments were left by the reviewer.',
        isEditing: false
      });
      const newRatingString = rating.uniqueId + '+' + overallRating.toFixed(2);
      await updateDealership(currentDealership?.id as string, {
        sellerRatings: arrayUnion(newRatingString)
      });
      setConditionRating(undefined);
      setOwnershipRating(undefined);
      setSalesRating(undefined);
      setRatingComment(undefined);
    }
  };

  const handleDeleteRating = async (rating: dealerRating) => {
    if (rating.ratingType === 'buyer') {
      const ratingString = rating.uniqueId + '+' + rating.overallRating;
      await updateDealership(currentDealership?.id as string, {
        buyerRatings: arrayRemove(ratingString)
      });
    } else if (rating.ratingType === 'seller') {
      const ratingString = rating.uniqueId + '+' + rating.overallRating;
      await updateDealership(currentDealership?.id as string, {
        sellerRatings: arrayRemove(ratingString)
      });
    }
    await deleteRating(rating.id as string);
  };

  const getDealership = async () => {
    const docRef = doc(firestore, `dealerships/${dealership.id}`);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const currentDealership = { id: docSnap.id, ...docSnap.data() } as Dealership;
      setCurrentDealership(currentDealership);
    }
  };

  const ratingsRef = collection(firestore, 'ratings') as CollectionReference<dealerRating>;

  const countBuyerRatings = () => {
    const buyerRatingsQuery = query(
      ratingsRef,
      where('dealershipId', '==', dealership.id),
      where('ratingType', '==', 'buyer')
    );
    const unsubscribeBuy = onSnapshot(buyerRatingsQuery, (querySnapshot) => {
      setTotalBuyRatings(querySnapshot.size);

      const ratings = querySnapshot.docs.map(
        (doc) => ({ ...doc.data(), id: doc.id } as dealerRating)
      );

      let buyerRunningTotal = 0;
      let averageBuyRating;

      ratings.forEach((rating: dealerRating) => {
        buyerRunningTotal = buyerRunningTotal + parseFloat(rating.overallRating);
      });

      if (querySnapshot.size) {
        setAvgBuyStars(buyerRunningTotal / querySnapshot.size);
      } else {
        setAvgBuyStars(undefined);
      }

      averageBuyRating = querySnapshot.size
        ? (buyerRunningTotal / querySnapshot.size).toFixed(1) + '/5'
        : 'No Rating';
      setAverageBuyRating(averageBuyRating);
    });
    return unsubscribeBuy;
  };

  const countSellerRatings = () => {
    const buyerRatingsQuery = query(
      ratingsRef,
      where('dealershipId', '==', dealership.id),
      where('ratingType', '==', 'seller')
    );
    const unsubscribeSell = onSnapshot(buyerRatingsQuery, (querySnapshot) => {
      setTotalSellRatings(querySnapshot.size);

      const ratings = querySnapshot.docs.map(
        (doc) => ({ ...doc.data(), id: doc.id } as dealerRating)
      );

      let buyerRunningTotal = 0;
      let averageBuyRating;

      ratings.forEach((rating: dealerRating) => {
        buyerRunningTotal = buyerRunningTotal + parseFloat(rating.overallRating);
      });

      if (querySnapshot.size) {
        setAvgSellStars(buyerRunningTotal / querySnapshot.size);
      } else {
        setAvgSellStars(undefined);
      }

      averageBuyRating = querySnapshot.size
        ? (buyerRunningTotal / querySnapshot.size).toFixed(1) + '/5'
        : 'No Rating';
      setAverageSellRating(averageBuyRating);
    });
    return unsubscribeSell;
  };

  useEffect(() => {
    const unsubscribeBuy = countBuyerRatings();
    const unsubscribeSell = countSellerRatings();
    return () => {
      unsubscribeBuy();
      unsubscribeSell();
    };
  }, []);

  const getBuyerRatings = async () => {
    const buyerRatingsQuery = query(
      ratingsRef,
      where('dealershipId', '==', dealership.id),
      where('ratingType', '==', 'buyer'),
      orderBy('date', 'desc'),
      limit(20)
    );

    onSnapshot(buyerRatingsQuery, (querySnapshot) => {
      const ratings = querySnapshot.docs.map(
        (doc) => ({ ...doc.data(), id: doc.id } as dealerRating)
      );
      setBuyerRatings(ratings);
      const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
      setLastBuyDoc(lastDoc);
    });
  };

  const nextBuyerRatings = async () => {
    if (lastBuyDoc) {
      const buyerRatingsQuery = query(
        ratingsRef,
        where('dealershipId', '==', dealership.id),
        where('ratingType', '==', 'buyer'),
        orderBy('date', 'desc'),
        startAfter(lastBuyDoc),
        limit(20)
      );
      const nextBuyDocs = await getDocs(buyerRatingsQuery);
      const nextBuyRatings = nextBuyDocs.docs.map(
        (doc) => ({ ...doc.data(), id: doc.id } as dealerRating)
      );
      const tempBuyRatings = buyerRatings as dealerRating[];
      nextBuyRatings.forEach((rating: dealerRating) => {
        tempBuyRatings.push(rating);
      });
      setBuyerRatings(tempBuyRatings);
      const nextLastBuyDoc = nextBuyDocs.docs[nextBuyDocs.docs.length - 1];
      setLastBuyDoc(nextLastBuyDoc);
    }
  };

  const getMyBuyerRatings = async () => {
    const buyerRatingsQuery = query(
      ratingsRef,
      where('dealershipId', '==', dealership.id),
      where('ratingType', '==', 'buyer'),
      where('reviewerEmail', '==', currentUser.email),
      orderBy('date', 'desc'),
      limit(20)
    );

    onSnapshot(buyerRatingsQuery, (querySnapshot) => {
      const ratings = querySnapshot.docs.map(
        (doc) => ({ ...doc.data(), id: doc.id } as dealerRating)
      );
      setMyBuyRatings(ratings);
      const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
      setLastMyBuyDoc(lastDoc);
    });
  };

  const nextMyBuyerRatings = async () => {
    if (lastMyBuyDoc) {
      const buyerRatingsQuery = query(
        ratingsRef,
        where('dealershipId', '==', dealership.id),
        where('ratingType', '==', 'buyer'),
        where('reviewerEmail', '==', currentUser.email),
        orderBy('date', 'desc'),
        startAfter(lastMyBuyDoc),
        limit(20)
      );
      const nextBuyDocs = await getDocs(buyerRatingsQuery);
      const nextBuyRatings = nextBuyDocs.docs.map(
        (doc) => ({ ...doc.data(), id: doc.id } as dealerRating)
      );
      const tempBuyRatings = myBuyRatings as dealerRating[];
      nextBuyRatings.forEach((rating: dealerRating) => {
        tempBuyRatings.push(rating);
      });
      setMyBuyRatings(tempBuyRatings);
      const nextLastBuyDoc = nextBuyDocs.docs[nextBuyDocs.docs.length - 1];
      setLastMyBuyDoc(nextLastBuyDoc);
    }
  };

  const getSellerRatings = async () => {
    const buyerRatingsQuery = query(
      ratingsRef,
      where('dealershipId', '==', dealership.id),
      where('ratingType', '==', 'seller'),
      orderBy('date', 'desc'),
      limit(20)
    );

    onSnapshot(buyerRatingsQuery, (querySnapshot) => {
      const ratings = querySnapshot.docs.map(
        (doc) => ({ ...doc.data(), id: doc.id } as dealerRating)
      );
      setSellerRatings(ratings);
      const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
      setLastSellDoc(lastDoc);
    });
  };

  const nextSellerRatings = async () => {
    if (lastSellDoc) {
      const buyerRatingsQuery = query(
        ratingsRef,
        where('dealershipId', '==', dealership.id),
        where('ratingType', '==', 'seller'),
        orderBy('date', 'desc'),
        startAfter(lastSellDoc),
        limit(20)
      );
      const nextBuyDocs = await getDocs(buyerRatingsQuery);
      const nextBuyRatings = nextBuyDocs.docs.map(
        (doc) => ({ ...doc.data(), id: doc.id } as dealerRating)
      );
      const tempBuyRatings = sellerRatings as dealerRating[];
      nextBuyRatings.forEach((rating: dealerRating) => {
        tempBuyRatings.push(rating);
      });
      setSellerRatings(tempBuyRatings);
      const nextLastBuyDoc = nextBuyDocs.docs[nextBuyDocs.docs.length - 1];
      setLastSellDoc(nextLastBuyDoc);
    }
  };

  const getMySellerRatings = async () => {
    const buyerRatingsQuery = query(
      ratingsRef,
      where('dealershipId', '==', dealership.id),
      where('ratingType', '==', 'seller'),
      where('reviewerEmail', '==', currentUser.email),
      orderBy('date', 'desc'),
      limit(20)
    );

    onSnapshot(buyerRatingsQuery, (querySnapshot) => {
      const ratings = querySnapshot.docs.map(
        (doc) => ({ ...doc.data(), id: doc.id } as dealerRating)
      );
      setMySellRatings(ratings);
      const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
      setLastMySellDoc(lastDoc);
    });
  };

  const nextMySellerRatings = async () => {
    if (lastMySellDoc) {
      const buyerRatingsQuery = query(
        ratingsRef,
        where('dealershipId', '==', dealership.id),
        where('ratingType', '==', 'seller'),
        where('reviewerEmail', '==', currentUser.email),
        orderBy('date', 'desc'),
        startAfter(lastMySellDoc),
        limit(20)
      );
      const nextBuyDocs = await getDocs(buyerRatingsQuery);
      const nextBuyRatings = nextBuyDocs.docs.map(
        (doc) => ({ ...doc.data(), id: doc.id } as dealerRating)
      );
      const tempBuyRatings = mySellRatings as dealerRating[];
      nextBuyRatings.forEach((rating: dealerRating) => {
        tempBuyRatings.push(rating);
      });
      setMySellRatings(tempBuyRatings);
      const nextLastBuyDoc = nextBuyDocs.docs[nextBuyDocs.docs.length - 1];
      setLastMySellDoc(nextLastBuyDoc);
    }
  };

  // const getAllRatings = async () => {
  //   const ratingsQuery = query(ratingsRef, where('dealershipId', '==', dealership.id));
  //   onSnapshot(ratingsQuery, (querySnapshot) => {
  //     const ratings = querySnapshot.docs.map(
  //       (doc) => ({ ...doc.data(), id: doc.id } as dealerRating)
  //     );

  //     const tempSellerRatings: dealerRating[] = [];
  //     const tempBuyerRatings: dealerRating[] = [];
  //     const tempMySellRatings: dealerRating[] = [];
  //     const tempMyBuyRatings: dealerRating[] = [];

  //     let sellerRunningTotal = 0;
  //     let averageSellRating;
  //     // let buyerRunningTotal = 0;
  //     // let averageBuyRating;

  //     ratings.forEach((rating: dealerRating) => {
  //       if (rating.ratingType === 'seller') {
  //         sellerRunningTotal = sellerRunningTotal + parseFloat(rating.overallRating);
  //         if (rating.userId === user?.uid) {
  //           tempMySellRatings.push(rating);
  //         } else {
  //           tempSellerRatings.push(rating);
  //         }
  //       } else {
  //         // buyerRunningTotal = buyerRunningTotal + parseFloat(rating.overallRating);
  //         // if (rating.userId === user?.uid) {
  //         //   tempMyBuyRatings.push(rating);
  //         // } else {
  //         //   tempBuyerRatings.push(rating);
  //         // }
  //       }
  //     });

  //     const isEditingRating = ratings.some((rating: dealerRating) => rating.isEditing === true);
  //     if (isEditingRating) {
  //       setEditDisabled(true);
  //     } else {
  //       setEditDisabled(false);
  //     }

  //     // const totalBuyRatings = tempBuyerRatings.length + tempMyBuyRatings.length;
  //     const totalSellRatings = tempSellerRatings.length + tempMySellRatings.length;

  //     // if (totalBuyRatings) {
  //     //   setAvgBuyStars(buyerRunningTotal / totalBuyRatings);
  //     // } else {
  //     //   setAvgBuyStars(undefined);
  //     // }

  //     if (totalSellRatings) {
  //       setAvgSellStars(sellerRunningTotal / totalSellRatings);
  //     } else {
  //       setAvgSellStars(undefined);
  //     }

  //     // averageBuyRating = totalBuyRatings
  //     //   ? (buyerRunningTotal / totalBuyRatings).toFixed(1) + '/5'
  //     //   : 'No Rating';

  //     averageSellRating = totalSellRatings
  //       ? (sellerRunningTotal / totalSellRatings).toFixed(1) + '/5'
  //       : 'No Rating';

  //     // setAverageBuyRating(averageBuyRating);
  //     setAverageSellRating(averageSellRating);
  //     // setTotalBuyRatings(totalBuyRatings);
  //     setTotalSellRatings(totalSellRatings);
  //     setBuyerRatings(tempBuyerRatings);
  //     setSellerRatings(tempSellerRatings);
  //     setMyBuyRatings(tempMyBuyRatings);
  //     setMySellRatings(tempMySellRatings);
  //   });
  // };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getDealership();
      getBuyerRatings();
      getMyBuyerRatings();
      getSellerRatings();
      getMySellerRatings();
      // getAllRatings();
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealership]);

  const useStyles = makeStyles({
    tab: {
      backgroundColor: theme.palette.background.paper,
      padding: '0px 15px',
      borderRadius: '25px',
      textTransform: 'uppercase',
      fontSize: '12px',
      minHeight: '30px'
    },
    accordion: {
      boxShadow: 'none !important',
      backgroundColor: 'transparent !important',
      marginTop: '0px !important',
      marginBottom: '0px !important',
      border: 'none',
      '&:not(:last-child)': {
        borderBottom: 0
      },
      '&:before': {
        display: 'none'
      }
    },
    dividerContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%'
    },
    divider: {
      flexGrow: '3',
      margin: '0px 10px',
      borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
    },
    inputSize: {
      fontSize: '13px !important'
    }
  });

  const styles = useStyles();

  return (
    <Grid sx={{ color: theme.palette.secondary.darker, m: '19px' }} xs={12}>
      <Box sx={{ width: '100%' }}>
        <StyledTabs value={tabValue} onChange={handleChange} aria-label="Ratings Tabs">
          <Tab
            className={styles.tab}
            label={`buyer ratings (${totalBuyRatings})`}
            {...a11yProps(0)}
          />
          <Tab
            className={styles.tab}
            label={`seller ratings (${totalSellRatings})`}
            {...a11yProps(1)}
          />
        </StyledTabs>
        <TabPanel value={tabValue} index={0}>
          <Box>
            <Typography variant="h5" sx={{ color: theme.palette.text.secondary }}>
              Buyer Rating
            </Typography>
            <Typography variant="h3" sx={{ color: theme.palette.text.primary }}>
              {averageBuyRating}
            </Typography>
            {avgBuyStars && (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <StyledRating
                  name="average-buy-rating"
                  size="medium"
                  readOnly
                  defaultValue={avgBuyStars}
                  precision={0.1}
                  icon={<DirectionsCarIcon fontSize="inherit" />}
                  emptyIcon={<DirectionsCarFilledOutlinedIcon fontSize="inherit" />}
                />
                <Typography sx={{ fontSize: '12px', color: theme.palette.text.primary, ml: 1 }}>
                  ({totalBuyRatings} Ratings)
                </Typography>
              </Box>
            )}
            <Divider sx={{ color: theme.palette.text.secondary, mt: '20px' }} />
            <Typography variant="h5" sx={{ color: theme.palette.text.secondary, mt: '20px' }}>
              Buyer Reviews
            </Typography>
            <Accordion
              expanded={expandedBuy}
              onChange={handleExpandedBuy}
              className={styles.accordion}
            >
              <AccordionSummary
                sx={{ paddingLeft: '0px !important', paddingRight: '0px !important' }}
                expandIcon={
                  expandedBuy ? (
                    <RemoveCircleOutlineIcon />
                  ) : (
                    <AddCircleOutlineIcon sx={{ color: theme.palette.primary.main }} />
                  )
                }
                aria-controls="filter-content"
                id="filter-header"
              >
                <Box className={styles.dividerContainer}>
                  <Typography>Community Reviews</Typography>
                  <Divider className={styles.divider} />
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                {buyerRatings &&
                  buyerRatings.map((rating: dealerRating, index: number) => {
                    return (
                      <Box key={index} sx={{ marginBottom: '20px' }}>
                        {currentUser.currentDealershipRole === 'superAdmin' && (
                          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Tooltip title="Delete Rating" placement="left">
                              <IconButton
                                size="small"
                                sx={{
                                  backgroundColor: 'transparent',
                                  color: !editDisabled
                                    ? theme.palette.error.main
                                    : theme.palette.text.secondary
                                }}
                                onClick={() => handleDeleteRating(rating)}
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        )}
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Typography sx={{ fontSize: '13px', mr: 1 }}>
                            Payment in a Timely Manner
                          </Typography>
                          <StyledRating
                            name="payment-sell-rating"
                            size="small"
                            defaultValue={rating.paymentRating}
                            readOnly
                            precision={0.5}
                            icon={<DirectionsCarIcon fontSize="inherit" />}
                            emptyIcon={<DirectionsCarFilledOutlinedIcon fontSize="inherit" />}
                          />
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Typography sx={{ fontSize: '13px', mr: 1 }}>
                            Pickup in a Timely Manner
                          </Typography>
                          <StyledRating
                            name="payment-sell-rating"
                            size="small"
                            defaultValue={rating.pickupRating}
                            readOnly
                            precision={0.5}
                            icon={<DirectionsCarIcon fontSize="inherit" />}
                            emptyIcon={<DirectionsCarFilledOutlinedIcon fontSize="inherit" />}
                          />
                        </Box>
                        <Typography sx={{ fontSize: '13px', mt: 1 }}>{rating.comments}</Typography>
                        <Typography sx={{ fontSize: '11px', fontWeight: '100', marginTop: '5px' }}>
                          {rating.reviewerDealershipName} | {fDateTimeSuffix(rating.date.toDate())}
                        </Typography>
                        <Divider className={styles.divider} sx={{ marginTop: '10px !important' }} />
                      </Box>
                    );
                  })}
                {buyerRatings && buyerRatings.length > 0 && (
                  <Button onClick={() => nextBuyerRatings()}>Load More</Button>
                )}
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expandedMyBuy}
              onChange={handleExpandedMyBuy}
              className={styles.accordion}
            >
              <AccordionSummary
                sx={{ paddingLeft: '0px !important', paddingRight: '0px !important' }}
                expandIcon={
                  expandedMyBuy ? (
                    <RemoveCircleOutlineIcon />
                  ) : (
                    <AddCircleOutlineIcon sx={{ color: theme.palette.primary.main }} />
                  )
                }
                aria-controls="filter-content"
                id="filter-header"
              >
                <Box className={styles.dividerContainer}>
                  <Typography>My Reviews</Typography>
                  <Divider className={styles.divider} />
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                {myBuyRatings &&
                  myBuyRatings.map((rating: dealerRating, index: number) => {
                    return (
                      <Box key={index} sx={{ marginBottom: '20px' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <Tooltip title="Edit Rating" placement="left">
                            <IconButton
                              size="small"
                              disabled={editDisabled || parseFloat(rating.overallRating) >= 3}
                              sx={{
                                backgroundColor: 'transparent',
                                color: !editDisabled
                                  ? theme.palette.primary.main
                                  : theme.palette.text.secondary
                              }}
                              onClick={() => handleEditRating(rating)}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Save Changes" placement="right">
                            <IconButton
                              size="small"
                              disabled={!rating.isEditing}
                              sx={{
                                backgroundColor: 'transparent',
                                color: rating.isEditing
                                  ? theme.palette.primary.main
                                  : theme.palette.text.secondary
                              }}
                              onClick={() => handleSaveRating(rating)}
                            >
                              <DoneAllIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Typography sx={{ fontSize: '13px', mr: 1 }}>
                            Payment in a Timely Manner
                          </Typography>
                          <StyledRating
                            name="payment-sell-rating"
                            size="small"
                            defaultValue={rating.paymentRating}
                            readOnly={rating.isEditing ? false : true}
                            precision={0.5}
                            icon={<DirectionsCarIcon fontSize="inherit" />}
                            emptyIcon={<DirectionsCarFilledOutlinedIcon fontSize="inherit" />}
                            onChange={(event, newValue) => {
                              if (!newValue) newValue = 0;
                              rating.paymentRating = newValue;
                              setPaymentRating(newValue as number);
                            }}
                          />
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Typography sx={{ fontSize: '13px', mr: 1 }}>
                            Pickup in a Timely Manner
                          </Typography>
                          <StyledRating
                            name="payment-sell-rating"
                            size="small"
                            defaultValue={rating.pickupRating}
                            readOnly={rating.isEditing ? false : true}
                            precision={0.5}
                            icon={<DirectionsCarIcon fontSize="inherit" />}
                            emptyIcon={<DirectionsCarFilledOutlinedIcon fontSize="inherit" />}
                            onChange={(event, newValue) => {
                              if (!newValue) newValue = 0;
                              rating.pickupRating = newValue;
                              setPickupRating(newValue as number);
                            }}
                          />
                        </Box>
                        {rating.isEditing ? (
                          <TextField
                            InputProps={{
                              classes: {
                                input: styles.inputSize
                              }
                            }}
                            sx={{ mt: 1 }}
                            fullWidth
                            multiline
                            value={rating.comments}
                            // onChange={(e) => handleRatingCommentChange(e.target.value)}
                            onChange={(e) => {
                              rating.comments = e.target.value;
                              setRatingComment(e.target.value);
                            }}
                          />
                        ) : (
                          <Typography sx={{ fontSize: '13px', mt: 1 }}>
                            {rating.comments}
                          </Typography>
                        )}
                        <Typography sx={{ fontSize: '11px', fontWeight: '100', marginTop: '5px' }}>
                          {rating.reviewerDealershipName} | {fDateTimeSuffix(rating.date.toDate())}
                        </Typography>
                        <Divider className={styles.divider} sx={{ marginTop: '10px !important' }} />
                      </Box>
                    );
                  })}
                {myBuyRatings && myBuyRatings.length > 0 && (
                  <Button onClick={() => nextMyBuyerRatings()}>Load More</Button>
                )}
              </AccordionDetails>
            </Accordion>
          </Box>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <Box>
            <Typography variant="h5" sx={{ color: theme.palette.text.secondary }}>
              Seller Rating
            </Typography>
            <Typography variant="h3" sx={{ color: theme.palette.text.primary }}>
              {averageSellRating}
            </Typography>
            {avgSellStars && (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <StyledRating
                  name="average-sell-rating"
                  size="medium"
                  defaultValue={avgSellStars}
                  readOnly
                  precision={0.1}
                  icon={<DirectionsCarIcon fontSize="inherit" />}
                  emptyIcon={<DirectionsCarFilledOutlinedIcon fontSize="inherit" />}
                />
                <Typography sx={{ fontSize: '12px', color: theme.palette.text.primary, ml: 1 }}>
                  ({totalSellRatings} Ratings)
                </Typography>
              </Box>
            )}
            <Divider sx={{ color: theme.palette.text.secondary, mt: '20px' }} />
            <Typography variant="h5" sx={{ color: theme.palette.text.secondary, mt: '20px' }}>
              Seller Reviews
            </Typography>
            <Accordion
              expanded={expandedSell}
              onChange={handleExpandedSell}
              className={styles.accordion}
            >
              <AccordionSummary
                sx={{ paddingLeft: '0px !important', paddingRight: '0px !important' }}
                expandIcon={
                  expandedSell ? (
                    <RemoveCircleOutlineIcon />
                  ) : (
                    <AddCircleOutlineIcon sx={{ color: theme.palette.primary.main }} />
                  )
                }
                aria-controls="filter-content"
                id="filter-header"
              >
                <Box className={styles.dividerContainer}>
                  <Typography>Community Reviews</Typography>
                  <Divider className={styles.divider} />
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                {sellerRatings &&
                  sellerRatings.map((rating: dealerRating, index: number) => {
                    return (
                      <Box key={index} sx={{ marginBottom: '20px' }}>
                        {currentUser.currentDealershipRole === 'superAdmin' && (
                          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Tooltip title="Delete Rating" placement="left">
                              <IconButton
                                size="small"
                                sx={{
                                  backgroundColor: 'transparent',
                                  color: !editDisabled
                                    ? theme.palette.error.main
                                    : theme.palette.text.secondary
                                }}
                                onClick={() => handleDeleteRating(rating)}
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        )}
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Typography sx={{ fontSize: '13px', mr: 1 }}>
                            Vehicle Arrived as Described
                          </Typography>
                          <StyledRating
                            name="payment-sell-rating"
                            size="small"
                            defaultValue={rating.conditionRating}
                            readOnly
                            precision={0.5}
                            icon={<DirectionsCarIcon fontSize="inherit" />}
                            emptyIcon={<DirectionsCarFilledOutlinedIcon fontSize="inherit" />}
                          />
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Typography sx={{ fontSize: '13px', mr: 1 }}>
                            Vehicle Available at Pickup
                          </Typography>
                          <StyledRating
                            name="payment-sell-rating"
                            size="small"
                            defaultValue={rating.salesRating}
                            readOnly
                            precision={0.5}
                            icon={<DirectionsCarIcon fontSize="inherit" />}
                            emptyIcon={<DirectionsCarFilledOutlinedIcon fontSize="inherit" />}
                          />
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Typography sx={{ fontSize: '13px', mr: 1 }}>
                            Ownership Sent in Timely Manner
                          </Typography>
                          <StyledRating
                            name="payment-sell-rating"
                            size="small"
                            defaultValue={rating.ownershipRating}
                            readOnly
                            precision={0.5}
                            icon={<DirectionsCarIcon fontSize="inherit" />}
                            emptyIcon={<DirectionsCarFilledOutlinedIcon fontSize="inherit" />}
                          />
                        </Box>
                        <Typography sx={{ fontSize: '13px', mt: 1 }}>{rating.comments}</Typography>
                        <Typography sx={{ fontSize: '11px', fontWeight: '100', marginTop: '5px' }}>
                          {rating.reviewerDealershipName} | {fDateTimeSuffix(rating.date.toDate())}
                        </Typography>
                        <Divider className={styles.divider} sx={{ marginTop: '10px !important' }} />
                      </Box>
                    );
                  })}
                {sellerRatings && sellerRatings.length > 0 && (
                  <Button onClick={() => nextSellerRatings()}>Load More</Button>
                )}
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expandedMySell}
              onChange={handleExpandedMySell}
              className={styles.accordion}
            >
              <AccordionSummary
                sx={{ paddingLeft: '0px !important', paddingRight: '0px !important' }}
                expandIcon={
                  expandedMySell ? (
                    <RemoveCircleOutlineIcon />
                  ) : (
                    <AddCircleOutlineIcon sx={{ color: theme.palette.primary.main }} />
                  )
                }
                aria-controls="filter-content"
                id="filter-header"
              >
                <Box className={styles.dividerContainer}>
                  <Typography>My Reviews</Typography>
                  <Divider className={styles.divider} />
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                {mySellRatings &&
                  mySellRatings.map((rating: dealerRating, index: number) => {
                    return (
                      <Box key={index} sx={{ marginBottom: '20px' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <Tooltip title="Edit Rating" placement="left">
                            <IconButton
                              size="small"
                              disabled={editDisabled || parseFloat(rating.overallRating) >= 3}
                              sx={{
                                backgroundColor: 'transparent',
                                color: !editDisabled
                                  ? theme.palette.primary.main
                                  : theme.palette.text.secondary
                              }}
                              onClick={() => handleEditRating(rating)}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Save Changes" placement="right">
                            <IconButton
                              size="small"
                              disabled={!rating.isEditing}
                              sx={{
                                backgroundColor: 'transparent',
                                color: rating.isEditing
                                  ? theme.palette.primary.main
                                  : theme.palette.text.secondary
                              }}
                              onClick={() => handleSaveRating(rating)}
                            >
                              <DoneAllIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Typography sx={{ fontSize: '13px', mr: 1 }}>
                            Vehicle Arrived as Described
                          </Typography>
                          <StyledRating
                            name="payment-sell-rating"
                            size="small"
                            defaultValue={rating.conditionRating}
                            readOnly={rating.isEditing ? false : true}
                            precision={0.5}
                            icon={<DirectionsCarIcon fontSize="inherit" />}
                            emptyIcon={<DirectionsCarFilledOutlinedIcon fontSize="inherit" />}
                            onChange={(event, newValue) => {
                              if (!newValue) newValue = 0;
                              rating.conditionRating = newValue;
                              setConditionRating(newValue as number);
                            }}
                          />
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Typography sx={{ fontSize: '13px', mr: 1 }}>
                            Vehicle Available at Pickup
                          </Typography>
                          <StyledRating
                            name="payment-sell-rating"
                            size="small"
                            defaultValue={rating.salesRating}
                            readOnly={rating.isEditing ? false : true}
                            precision={0.5}
                            icon={<DirectionsCarIcon fontSize="inherit" />}
                            emptyIcon={<DirectionsCarFilledOutlinedIcon fontSize="inherit" />}
                            onChange={(event, newValue) => {
                              if (!newValue) newValue = 0;
                              rating.salesRating = newValue;
                              setSalesRating(newValue as number);
                            }}
                          />
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Typography sx={{ fontSize: '13px', mr: 1 }}>
                            Ownership Sent in Timely Manner
                          </Typography>
                          <StyledRating
                            name="payment-sell-rating"
                            size="small"
                            defaultValue={rating.ownershipRating}
                            readOnly={rating.isEditing ? false : true}
                            precision={0.5}
                            icon={<DirectionsCarIcon fontSize="inherit" />}
                            emptyIcon={<DirectionsCarFilledOutlinedIcon fontSize="inherit" />}
                            onChange={(event, newValue) => {
                              if (!newValue) newValue = 0;
                              rating.ownershipRating = newValue;
                              setOwnershipRating(newValue as number);
                            }}
                          />
                        </Box>
                        {rating.isEditing ? (
                          <TextField
                            InputProps={{
                              classes: {
                                input: styles.inputSize
                              }
                            }}
                            sx={{ mt: 1 }}
                            fullWidth
                            multiline
                            value={rating.comments}
                            // onChange={(e) => handleRatingCommentChange(e.target.value)}
                            onChange={(e) => {
                              rating.comments = e.target.value;
                              setRatingComment(e.target.value);
                            }}
                          />
                        ) : (
                          <Typography sx={{ fontSize: '13px', mt: 1 }}>
                            {rating.comments}
                          </Typography>
                        )}
                        <Typography sx={{ fontSize: '11px', fontWeight: '100', marginTop: '5px' }}>
                          {rating.reviewerDealershipName} | {fDateTimeSuffix(rating.date.toDate())}
                        </Typography>
                        <Divider className={styles.divider} sx={{ marginTop: '10px !important' }} />
                      </Box>
                    );
                  })}
                {mySellRatings && mySellRatings.length > 0 && (
                  <Button onClick={() => nextMySellerRatings()}>Load More</Button>
                )}
              </AccordionDetails>
            </Accordion>
          </Box>
        </TabPanel>
      </Box>
    </Grid>
  );
};

export default DrawerDealerRatings;
