import {
  PATH_ADMIN,
  PATH_BUYER,
  PATH_COMMUNITY,
  PATH_DASHBOARD,
  PATH_SELLER
} from '../../routes/paths';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import GavelIcon from '@mui/icons-material/Gavel';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import GroupIcon from '@mui/icons-material/Group';
import EmailIcon from '@mui/icons-material/Email';
import MediationIcon from '@mui/icons-material/Mediation';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import AssessmentIcon from '@mui/icons-material/Assessment';

const sidebarConfigSuperAdmin = [
  // DASHBOARD
  {
    subheader: '',
    items: [{ title: 'Dashboard', path: PATH_DASHBOARD.root, icon: <TimelapseIcon /> }]
  },
  // BUYER
  {
    subheader: 'buy',
    items: [
      {
        title: 'Pre Auction',
        path: PATH_BUYER.preAuction,
        icon: <DirectionsCarIcon />
      },
      { title: 'Active Auction', path: PATH_BUYER.activeAuctions, icon: <GavelIcon /> },
      { title: 'Post Auction', path: PATH_BUYER.postAuction, icon: <AttachMoneyIcon /> },
      {
        title: 'Bidding History',
        path: PATH_BUYER.lostVehicleHistory,
        icon: <AssessmentIcon />
      }
    ]
  },
  // SELLER
  {
    subheader: 'sell',
    items: [
      {
        title: 'Pre Auction',
        path: PATH_SELLER.preAuction,
        icon: <DirectionsCarIcon />
      },
      { title: 'Active Auction', path: PATH_SELLER.activeAuctions, icon: <GavelIcon /> },
      { title: 'Post Auction', path: PATH_SELLER.postAuction, icon: <AttachMoneyIcon /> },
      {
        title: 'Vehicle Capture',
        path: PATH_SELLER.vehicleCapture,
        icon: <CameraAltIcon />
      }
    ]
  },
  // DEALER NETWORK
  {
    subheader: 'dealergavel community',
    items: [
      {
        title: 'Selling Network',
        path: PATH_COMMUNITY.sellerNetwork,
        icon: <MediationIcon />
      },
      {
        title: 'Buying Network',
        path: PATH_COMMUNITY.buyerNetwork,
        icon: <MediationIcon sx={{ transform: 'rotate(180deg)' }} />
      },
      {
        title: 'Find a Dealership',
        path: PATH_COMMUNITY.findDealership,
        icon: <GroupWorkIcon />
      }
    ]
  },
  // ADMIN
  {
    subheader: 'admin',
    items: [
      { title: 'Manage Team', path: PATH_ADMIN.manageTeams, icon: <GroupIcon /> },
      // {
      //   title: 'My Dealer Network',
      //   path: PATH_ADMIN.dealerNetwork,
      //   icon: <EmailIcon />
      // },
      {
        title: 'Manage Dealerships',
        path: PATH_ADMIN.manageDealers,
        icon: <EmailIcon />
      },
      { title: 'Reports', path: PATH_ADMIN.reports, icon: <AssessmentIcon /> }
    ]
  }
];

export default sidebarConfigSuperAdmin;
