import { Timestamp } from '@firebase/firestore';
import { Dealership } from './dealership';
import { User } from '../constants/user';

type ReportedDamage = {
  cost: number;
  description: string;
  date?: Timestamp;
  afterSubmit?: string;
};

export type VehicleDisclosuresType = {
  mtoReg: string;
  otherDisclosures: boolean;
  soldAsIs: boolean;
  structRepairs: boolean;
  repairNotes: string;
  warrantyCancelled: boolean;
  outOfProvince: boolean;
  province: string;
  state: string;
  usVehicle: boolean;
  odometerStatus: string;
  odometerReading?: string;
  odometerReadingDate: Timestamp | null;
  odometerRadioChoice?: string;
  wasVehicle: string;
  wasDeclared: string;
  notes: string;
  drivable: boolean;
  structDamage: boolean;
  prevRepairs: boolean;
  badgesChanged: boolean;
  inopABS: boolean;
  inopAirBags: boolean;
  inopPollution: boolean;
  numberOfKeys: string;
  fifthWheel: boolean;
  salvage: boolean;
  VHRImageUrl: string;
  VHRImageFile: string;
  manuals: boolean;
  vhrDamages: ReportedDamage[];
  mechNeedRepairs: string[];
  electricNeedRepairs: string[];
};

export type VehiclePhotos = {
  id: string;
  url: string;
  fileName?: string;
  categoryName: string;
  location: string;
  damageType?: string;
  damageSize?: string;
  damageNumber?: string;
  otherLocation?: string;
};

export type VehicleEquipmentType = {
  clothLeather: string;
  roof: string;
  navigation: boolean;
  thirdRow: boolean;
  powerSeat: boolean;
  options: string[];
  afterMarketOptions: string[];
  tpms: boolean;
  aftermarketWheels: boolean;
  secondSetOfTires: boolean;
  winterTires: boolean;
  sizeTires: string;
  factoryInfo: string[]; // features list returned from VIN scan
};

export type VehicleAuctionType = {
  isAutobid: string[];
  isWatchlist: string[];
  isFavourite: string[];
  isExpanded: string[];
  isAutobidding?: boolean;
  previousBidders?: string[];
  autobids: {
    autobidPrice: number;
    autobidTime: Timestamp;
    autobidId: string;
    autobidName: string;
  }[];
  timesRun: number;
  dateExpected: Timestamp;
  dateArrived: Timestamp;
  dateSold: Timestamp; // dateWon for buyer
  datePickedUp: Timestamp;
  datePaid: Timestamp;
  dateDelivered: Timestamp;
  captureDate: Timestamp;
  pickUpLocation: {
    locationName: string;
    locationAddress: string;
    locationCity: string;
    locationProvince: string;
    locationPostalCode: string;
    locationCountry: string;
    locationPhone?: string;
    locationNotes?: string;
  };
  titleSent: Timestamp;
  gatePass: Timestamp;
  launchDate: Timestamp;
  launchTime: Timestamp;
  daysPending: number;
  endDate: Timestamp;
  endTime: Timestamp;
  nextRunDate: Timestamp;
  isInStock: boolean; // false = appraisal
  isCheckedforMove?: boolean;
  isWon: boolean; // false = "Lost"
  auctionStatus: string; // draft, launching soon, parked, active, post auction
  postAuctionStatus: string; // pending, arrived, delivered, sold, no sale
  negotiationStatusSeller: string; // action required, awaiting response, response required, complete
  negotiationStatusBuyer: string;
  captureRepName: string;
  buyerName: string;
  sellerName: string;
  lastRunPrice: number;
  floorPrice: number; // initial price set during capture
  currentPrice: number; // starts the same as floor price when auction starts, gets updated by currentBidPrice each interval during auction, gets updated by negotiation price when negotiation accepted
  negotiationPrice?: number; // price during negotiation used for offers
  damagesPrice: number; // total price of all damages
  totalPrice: number; // price after bill of sale calc
  paymentReceived: boolean;
  reasonLost?: string;
  runTime: string;
  distanceAway: number;
  timeRemaining: number;
  bidLeaderId: string;
  floorPriceMet?: boolean;
  negotiationHistory?: {
    user: string;
    dealership?: string;
    price: string;
    dateTime: Timestamp;
    type: string;
  }[];
  captureHistory: {
    captureRepName: string;
    date: Timestamp;
  }[];
  dealerRep: {
    name: string;
    email: string;
    phone: string;
    signatureUrl?: string;
    registration?: string;
  };
  // vehicleLaunchUser?: {
  //   name: string;
  //   email: string;
  //   phone: string;
  //   signatureUrl?: string;
  //   registration?: string;
  // };
  previousBids: {
    price: number;
    bidderName: string;
    dateTime: Timestamp;
    bidderId: string;
  }[];
  // previousBidIds: string[];
};

export type VehicleDetailsType = {
  vin: string;
  year?: number;
  make: string;
  model: string;
  trim: string;
  bodyStyle: string;
  package?: string;
  transmission: string;
  driveTrain: string;
  engine?: string;
  displacement: string;
  cylinders: string;
  fuelType: string;
  doors: string;
  passengers: number;
  extColor: string;
  intColor: string;
  mileage: string;
  mileageType: string;
  doorOptions?: string[];
  exteriorColorOptions?: any[];
  interiorColorOptions?: any[];
  passengerOptions?: string[];
  trimOptions?: string[];
};

// conditions subtype
export type PaintedPanelsType = {
  id: string;
  name: string;
  condition: string;
  selected: boolean;
};

// conditions subtype
export type ConditionOfTiresType = {
  id: string;
  location: string;
  size: string;
  tread: string;
  condition: string;
  selected: boolean;
};

export type VehicleConditionsType = {
  overall: string;
  paintedPanels: PaintedPanelsType[];
  conditionOfTires: ConditionOfTiresType[];
  sellersNotes: string;
};

export type VehicleHistoryType = {
  status: string;
  date: Timestamp;
};

export type VehicleInfo = {
  createdDate: Timestamp;
  dataFound?: boolean;
  hasBuyerRated?: boolean;
  id: string;
  captureProgressFlag?: number;
  captureComplete: boolean;
  dealershipId: string;
  dealershipName: string;
  bidExtension?: boolean;
  bidExtensionTime?: number;
  details: VehicleDetailsType;
  photos: VehiclePhotos[];
  equipment: VehicleEquipmentType;
  conditions: VehicleConditionsType;
  disclosures: VehicleDisclosuresType;
  auction: VehicleAuctionType;
  networkDealerships?: string[];
  billOfSaleLink?: string;
  releaseLink?: string;
  buyerEmail?: string;
  sellerEmail?: string;
  // buyerName?: string;
  // sellerName?: string;
  buyerDealership?: Dealership;
  sellerDealership?: Dealership;
  buyerUser?: User;
  sellerMarkAsSold?: boolean;
  buyerVehicleHistory: { date: Timestamp; status: string }[];
  vehicleHistory: { date: Timestamp; status: string }[];
  floorPriceMissing?: boolean;
  buyerReasonLost?: string;
};

export const initialVechicleInfo: Partial<VehicleInfo> = {
  id: ' ',
  captureComplete: false,
  details: {
    vin: '',
    // year: '' as string,
    make: '',
    model: '',
    trim: '',
    bodyStyle: '',
    transmission: '',
    driveTrain: '',
    displacement: '',
    cylinders: '',
    fuelType: '',
    doors: '',
    passengers: 5,
    extColor: '',
    intColor: '',
    mileage: '',
    mileageType: ''
  },
  photos: [],
  equipment: {
    clothLeather: ' ',
    roof: ' ',
    navigation: false,
    thirdRow: false,
    powerSeat: false,
    options: [],
    afterMarketOptions: [],
    tpms: true,
    aftermarketWheels: false,
    secondSetOfTires: false,
    winterTires: false,
    sizeTires: ' ',
    factoryInfo: [' '] // features list returned from VIN scan
  },
  disclosures: {
    mtoReg: 'None',
    otherDisclosures: false,
    odometerReadingDate: null,
    soldAsIs: false,
    structRepairs: false,
    warrantyCancelled: false,
    outOfProvince: false,
    province: ' ',
    state: ' ',
    usVehicle: false,
    odometerStatus: 'None',
    wasVehicle: 'None',
    wasDeclared: 'None',
    notes: ' ',
    repairNotes: ' ',
    drivable: true,
    structDamage: false,
    prevRepairs: false,
    badgesChanged: false,
    inopABS: false,
    inopAirBags: false,
    inopPollution: false,
    numberOfKeys: '2',
    fifthWheel: false,
    salvage: false,
    VHRImageUrl: '',
    VHRImageFile: ' ',
    manuals: true,
    vhrDamages: [],
    mechNeedRepairs: [],
    electricNeedRepairs: []
  }
};
