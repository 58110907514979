import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  List,
  Drawer,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Slider,
  SelectChangeEvent,
  Checkbox,
  ListItemText,
  Divider
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { MHidden } from 'components/@material-extend';
import Scrollbar from 'components/Scrollbar';
import { useFormik, FormikProvider, Form } from 'formik';
import { LoadingButton } from '@mui/lab';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import TuneIcon from '@mui/icons-material/Tune';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { isMobile, isMobileOnly } from 'react-device-detect';

const carBrands = [
  { name: 'Acura', checked: false },
  { name: 'Alfa Romeo', checked: false },
  { name: 'Aston Martin', checked: false },
  { name: 'Audi', checked: false },
  { name: 'Bentley', checked: false },
  { name: 'BMW', checked: false },
  { name: 'Bugatti', checked: false },
  { name: 'Buick', checked: false },
  { name: 'Cadillac', checked: false },
  { name: 'Chevrolet', checked: false },
  { name: 'Chrysler', checked: false },
  { name: 'Citroen', checked: false },
  { name: 'Dodge', checked: false },
  { name: 'Ferrari', checked: false },
  { name: 'Fiat', checked: false },
  { name: 'Ford', checked: false },
  { name: 'Genesis', checked: false },
  { name: 'GMC', checked: false },
  { name: 'Honda', checked: false },
  { name: 'Hyundai', checked: false },
  { name: 'Infiniti', checked: false },
  { name: 'Jaguar', checked: false },
  { name: 'Jeep', checked: false },
  { name: 'Kia', checked: false },
  { name: 'Lamborghini', checked: false },
  { name: 'Land Rover', checked: false },
  { name: 'Lexus', checked: false },
  { name: 'Lincoln', checked: false },
  { name: 'Lotus', checked: false },
  { name: 'Maserati', checked: false },
  { name: 'Mazda', checked: false },
  { name: 'McLaren', checked: false },
  { name: 'Mercedes-Benz', checked: false },
  { name: 'Mini', checked: false },
  { name: 'Mitsubishi', checked: false },
  { name: 'Nissan', checked: false },
  { name: 'Polestar', checked: false },
  { name: 'Porsche', checked: false },
  { name: 'Ram', checked: false },
  { name: 'Rolls-Royce', checked: false },
  { name: 'Smart', checked: false },
  { name: 'Subaru', checked: false },
  { name: 'Suzuki', checked: false },
  { name: 'Tesla', checked: false },
  { name: 'Toyota', checked: false },
  { name: 'Volkswagen', checked: false },
  { name: 'Volvo', checked: false }
];

const brandArray: string[] = [];
carBrands.forEach((brand: any) => {
  brandArray.push(brand.name);
});

const YEARS = Array.from({ length: 100 }, (_, i) => new Date().getFullYear() - i);

type InitialValues = {
  yearMin: string;
  yearMax: string;
  odometerMin: string;
  odometerMax: string;
  distanceAway: string;
  make: string;
  model: string;
  trimLevel: string;
  bodyType: string;
  driveTrain: string;
  transmission: string;
  fuelType: string;
  exteriorColour: string;
  afterSubmit?: string;
};

const DRAWER_WIDTH = 250;
const COLLAPSE_WIDTH = 0;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

type AuctionSidebarFiltersProps = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
  handleFilterChange: Function;
  handleFormReset: Function;
};

export default function AuctionSidebarFilters({
  isOpenSidebar,
  onCloseSidebar,
  handleFilterChange,
  handleFormReset
}: AuctionSidebarFiltersProps) {
  const theme = useTheme();
  const [makeName, setMakeName] = useState<string[]>([]);

  const functions = getFunctions();
  const getModelData = httpsCallable(functions, 'getModelData');
  const [modelNames, setModelNames] = useState<string[]>([]);
  const [modelsLoading, setModelsLoading] = useState<boolean>(false);

  const OnChangeMakeField = async (event: SelectChangeEvent<typeof makeName>) => {
    const {
      target: { value }
    } = event;
    setMakeName(typeof value === 'string' ? value.split(',') : value);
    formik.setFieldValue('make', typeof value === 'string' ? value.split(',') : value);
  };

  const onCloseMakeField = async () => {
    setModelsLoading(true);
    setModelNames([]);
    const valueArray = makeName as string[];
    const tempModelNames: string[] = [];
    if (makeName) {
      // prettier-ignore
      await Promise.all(valueArray.map(async (value: string) => {
        await getModelData({ make: value }).then((result: any) => {
          const data = result.data.data;
          data.forEach((modelName: any) => {
            tempModelNames.push(modelName.name);
          });
        });
      }));
      formik.setFieldValue('model', null);
      setModelNames(tempModelNames);
      setModelsLoading(false);
    } else {
      setModelsLoading(false);
    }
  };

  const formik = useFormik<InitialValues>({
    initialValues: {
      yearMin: '',
      yearMax: '',
      odometerMin: '',
      odometerMax: '',
      distanceAway: '',
      make: '',
      model: '',
      trimLevel: '',
      bodyType: '',
      driveTrain: '',
      transmission: '',
      fuelType: '',
      exteriorColour: ''
    },
    onSubmit: async (values) => {
      handleFilterChange(values);
    }
  });

  const useStyles = makeStyles({
    container: {
      padding: '12px',
      backgroundColor: 'transparent !important' // theme.palette.common.white
    },
    menuPaper: {
      maxHeight: '60vh',
      overflowY: 'auto'
    },
    innerContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: '11px'
    },
    filterIcon: {
      marginRight: '3px',
      fontSize: '20px'
    },
    filterLabel: {
      fontWeight: '700',
      marginLeft: '5px'
    },
    expandIcon: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      borderRadius: '100%',
      width: '25px',
      height: '25px',
      padding: '5px',
      transform: 'rotate(180deg)'
    },
    filterAccordion: {
      backgroundColor: 'transparent !important', // theme.palette.common.white
      boxShadow: 'none !important'
    },
    accordionSummary: {
      borderWidth: '1px',
      borderTopStyle: 'solid',
      borderColor:
        theme.palette.mode === 'dark' ? theme.palette.text.secondary : theme.palette.divider
    },
    accordionDetailsContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'row',
      aliggItems: 'center',
      justifyContent: 'space-between',
      padding: '5px',
      borderWidth: '1px',
      borderTopStyle: 'solid',
      borderColor: theme.palette.divider
    },
    resetButton: {
      marginTop: '10px',
      backgroundColor: theme.palette.divider,
      color: theme.palette.info.light,
      '&:hover': {
        color: theme.palette.common.white
      }
    },
    saveButton: {
      marginTop: '10px',
      backgroundColor: theme.palette.secondary.main
    }
  });

  const styles = useStyles();
  const { isSubmitting, handleSubmit, getFieldProps } = formik;

  const [expandedYear, setExpandedYear] = useState<boolean>(true);
  const [expandedOdo, setExpandedOdo] = useState<boolean>(true);
  const [expandedMake, setExpandedMake] = useState<boolean>(true);
  const [expandedModel, setExpandedModel] = useState<boolean>(true);
  const [expandedTrim, setExpandedTrim] = useState<boolean>(true);
  const [expandedBody, setExpandedBody] = useState<boolean>(true);
  const [expandedDrive, setExpandedDrive] = useState<boolean>(true);
  const [expandedTrans, setExpandedTrans] = useState<boolean>(true);
  const [expandedFuel, setExpandedFuel] = useState<boolean>(true);
  const [expandedExt, setExpandedExt] = useState<boolean>(true);

  const handleChangeYear = () => {
    setExpandedYear(!expandedYear);
  };

  const handleChangeOdo = () => {
    setExpandedOdo(!expandedOdo);
  };

  const handleChangeMake = () => {
    setExpandedMake(!expandedMake);
  };

  const handleChangeModel = () => {
    setExpandedModel(!expandedModel);
  };

  const handleChangeTrim = () => {
    setExpandedTrim(!expandedTrim);
  };

  const handleChangeBody = () => {
    setExpandedBody(!expandedBody);
  };

  const handleChangeDrive = () => {
    setExpandedDrive(!expandedDrive);
  };

  const handleChangeTrans = () => {
    setExpandedTrans(!expandedTrans);
  };

  const handleChangeFuel = () => {
    setExpandedFuel(!expandedFuel);
  };

  const handleChangeExt = () => {
    setExpandedExt(!expandedExt);
  };

  const filterList = (
    <Scrollbar>
      <Box id="activeAuction2">
        <Box className={styles.container}>
          <Box className={styles.innerContainer}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <TuneIcon className={styles.filterIcon} />
              <Typography component="p" className={styles.filterLabel}>
                Filter by
              </Typography>
            </Box>
          </Box>
        </Box>
        <List
          disablePadding
          sx={{
            backgroundColor: 'transparent !important',
            overflowY: 'auto',
            borderRadius: '10px',
            height: !isMobile ? 'calc(100vh - 200px)' : '100%'
          }}
        >
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              {/* Year Filter */}
              <Box>
                <Accordion
                  className={styles.filterAccordion}
                  expanded={expandedYear}
                  onChange={handleChangeYear}
                >
                  <AccordionSummary
                    expandIcon={
                      expandedYear ? (
                        <RemoveCircleOutlineIcon />
                      ) : (
                        <AddCircleOutlineIcon sx={{ color: theme.palette.primary.main }} />
                      )
                    }
                    aria-controls="filter-content"
                    id="filter-header"
                    className={styles.accordionSummary}
                  >
                    <Typography sx={{ fontSize: '0.9rem' }}>{'Year'}</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: '0px 16px' }}>
                    <Box className={styles.accordionDetailsContainer}>
                      <FormControl sx={{ mb: '10px' }} fullWidth>
                        <InputLabel id="select-province">Min</InputLabel>
                        <Select {...getFieldProps('yearMin')} label="Min">
                          {YEARS &&
                            YEARS.map((option: any, index: number) => {
                              return (
                                <MenuItem key={index} value={option}>
                                  {option}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                      <Typography
                        sx={{
                          fontSize: '15px',
                          margin: '0px 5px',
                          marginBottom: '10px'
                        }}
                        component="p"
                      >
                        to
                      </Typography>
                      <FormControl sx={{ mb: '10px' }} fullWidth>
                        <InputLabel id="select-province">Max</InputLabel>
                        <Select {...getFieldProps('yearMax')} label="Max">
                          {YEARS &&
                            YEARS.map((option: any, index: number) => {
                              return (
                                <MenuItem key={index} value={option}>
                                  {option}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>
              {/* Odo Filter */}
              <Box>
                <Accordion
                  className={styles.filterAccordion}
                  expanded={expandedOdo}
                  onChange={handleChangeOdo}
                >
                  <AccordionSummary
                    expandIcon={
                      expandedOdo ? (
                        <RemoveCircleOutlineIcon />
                      ) : (
                        <AddCircleOutlineIcon sx={{ color: theme.palette.primary.main }} />
                      )
                    }
                    aria-controls="filter-content"
                    id="filter-header"
                    className={styles.accordionSummary}
                  >
                    <Typography sx={{ fontSize: '0.9rem' }}>{'Odometer'}</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: '0px 16px' }}>
                    <Box className={styles.accordionDetailsContainer}>
                      <Slider
                        aria-label="ios slider"
                        size="small"
                        min={0}
                        max={200000}
                        step={10000}
                        marks={true}
                        valueLabelDisplay="on"
                        {...getFieldProps('odometerMax')}
                      />
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>
              {/* Make Filter */}
              <Box>
                <Accordion
                  className={styles.filterAccordion}
                  expanded={expandedMake}
                  onChange={handleChangeMake}
                >
                  <AccordionSummary
                    expandIcon={
                      expandedMake ? (
                        <RemoveCircleOutlineIcon />
                      ) : (
                        <AddCircleOutlineIcon sx={{ color: theme.palette.primary.main }} />
                      )
                    }
                    aria-controls="filter-content"
                    id="filter-header"
                    className={styles.accordionSummary}
                  >
                    <Typography sx={{ fontSize: '0.9rem' }}>{'Make'}</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: '0px 16px' }}>
                    <Box className={styles.accordionDetailsContainer}>
                      <FormControl sx={{ mb: '10px' }} fullWidth>
                        <InputLabel>Make</InputLabel>
                        <Select
                          label="Make"
                          multiple
                          value={makeName}
                          onChange={OnChangeMakeField}
                          onClose={onCloseMakeField}
                          renderValue={(selected) => selected.join(', ')}
                          MenuProps={MenuProps}
                        >
                          {brandArray.map((name: string, index: number) => (
                            <MenuItem key={index} value={name}>
                              <Checkbox checked={makeName.indexOf(name) > -1} />
                              <ListItemText primary={name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>
              {/* Model Filter */}
              <Box>
                <Accordion
                  className={styles.filterAccordion}
                  expanded={expandedModel}
                  onChange={handleChangeModel}
                >
                  <AccordionSummary
                    expandIcon={
                      expandedModel ? (
                        <RemoveCircleOutlineIcon />
                      ) : (
                        <AddCircleOutlineIcon sx={{ color: theme.palette.primary.main }} />
                      )
                    }
                    aria-controls="filter-content"
                    id="filter-header"
                    className={styles.accordionSummary}
                  >
                    <Typography sx={{ fontSize: '0.9rem' }}>{'Model'}</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: '0px 16px' }}>
                    <Box className={styles.accordionDetailsContainer}>
                      {modelNames && modelNames.length > 0 && !modelsLoading ? (
                        <FormControl sx={{ mb: '10px' }} fullWidth>
                          <InputLabel>Model</InputLabel>
                          {/* prettier-ignore */}
                          <Select
                            {...getFieldProps('model')}
                            label="Model"
                            MenuProps={{ classes: { paper: styles.menuPaper } }}
                          >
                            {modelNames &&
                              modelNames.map((option: any, index: number) => {
                                return (
                                  <MenuItem key={index} value={option}>
                                    {option}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                      ) : (
                        <Box>
                          {modelsLoading ? (
                            <Typography>Loading Models...</Typography>
                          ) : (
                            <TextField
                              sx={{
                                marginRight: '2px',
                                mb: '10px'
                              }}
                              fullWidth
                              type="text"
                              label="Type..."
                              {...getFieldProps('model')}
                            />
                          )}
                        </Box>
                      )}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>
              {/* Trim Filter */}
              <Box>
                <Accordion
                  className={styles.filterAccordion}
                  expanded={expandedTrim}
                  onChange={handleChangeTrim}
                >
                  <AccordionSummary
                    expandIcon={
                      expandedTrim ? (
                        <RemoveCircleOutlineIcon />
                      ) : (
                        <AddCircleOutlineIcon sx={{ color: theme.palette.primary.main }} />
                      )
                    }
                    aria-controls="filter-content"
                    id="filter-header"
                    className={styles.accordionSummary}
                  >
                    <Typography sx={{ fontSize: '0.9rem' }}>{'Trim'}</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: '0px 16px' }}>
                    <Box className={styles.accordionDetailsContainer}>
                      <TextField
                        sx={{
                          marginRight: '2px',
                          mb: '10px'
                        }}
                        fullWidth
                        type="text"
                        label="Type..."
                        {...getFieldProps('trimLevel')}
                      />
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>
              {/* Body Filter */}
              <Box>
                <Accordion
                  className={styles.filterAccordion}
                  expanded={expandedBody}
                  onChange={handleChangeBody}
                >
                  <AccordionSummary
                    expandIcon={
                      expandedBody ? (
                        <RemoveCircleOutlineIcon />
                      ) : (
                        <AddCircleOutlineIcon sx={{ color: theme.palette.primary.main }} />
                      )
                    }
                    aria-controls="filter-content"
                    id="filter-header"
                    className={styles.accordionSummary}
                  >
                    <Typography sx={{ fontSize: '0.9rem' }}>{'Body Type'}</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: '0px 16px' }}>
                    <Box className={styles.accordionDetailsContainer}>
                      <TextField
                        sx={{
                          marginRight: '2px',
                          mb: '10px'
                        }}
                        fullWidth
                        type="text"
                        label="Type..."
                        {...getFieldProps('bodyType')}
                      />
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>
              {/* Drive Filter */}
              <Box>
                <Accordion
                  className={styles.filterAccordion}
                  expanded={expandedDrive}
                  onChange={handleChangeDrive}
                >
                  <AccordionSummary
                    expandIcon={
                      expandedDrive ? (
                        <RemoveCircleOutlineIcon />
                      ) : (
                        <AddCircleOutlineIcon sx={{ color: theme.palette.primary.main }} />
                      )
                    }
                    aria-controls="filter-content"
                    id="filter-header"
                    className={styles.accordionSummary}
                  >
                    <Typography sx={{ fontSize: '0.9rem' }}>{'Drive Train'}</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: '0px 16px' }}>
                    <Box className={styles.accordionDetailsContainer}>
                      <FormControl sx={{ mb: '10px' }} fullWidth>
                        <InputLabel>Drive Train</InputLabel>
                        <Select {...getFieldProps('driveTrain')}>
                          <MenuItem value={'Any'}>Any</MenuItem>
                          <Divider />
                          <MenuItem value={'FWD'}>FWD</MenuItem>
                          <MenuItem value={'AWD'}>AWD</MenuItem>
                          <MenuItem value={'4WD'}>4WD</MenuItem>
                          <MenuItem value={'RWD'}>RWD</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>
              {/* Trans Filter */}
              <Box>
                <Accordion
                  className={styles.filterAccordion}
                  expanded={expandedTrans}
                  onChange={handleChangeTrans}
                >
                  <AccordionSummary
                    expandIcon={
                      expandedTrans ? (
                        <RemoveCircleOutlineIcon />
                      ) : (
                        <AddCircleOutlineIcon sx={{ color: theme.palette.primary.main }} />
                      )
                    }
                    aria-controls="filter-content"
                    id="filter-header"
                    className={styles.accordionSummary}
                  >
                    <Typography sx={{ fontSize: '0.9rem' }}>{'Transmission'}</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: '0px 16px' }}>
                    <Box className={styles.accordionDetailsContainer}>
                      <FormControl sx={{ mb: '10px' }} fullWidth>
                        <InputLabel>Transmission</InputLabel>
                        <Select
                          {...getFieldProps('transmission')}
                          label="Transmission"
                          defaultValue={'Any'}
                        >
                          <MenuItem value={'Any'}>Any</MenuItem>
                          <Divider />
                          <MenuItem value={'Automatic'}>Auto</MenuItem>
                          <MenuItem value={'Manual'}>Manual</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>
              {/* Fuel Filter */}
              <Box>
                <Accordion
                  className={styles.filterAccordion}
                  expanded={expandedFuel}
                  onChange={handleChangeFuel}
                >
                  <AccordionSummary
                    expandIcon={
                      expandedFuel ? (
                        <RemoveCircleOutlineIcon />
                      ) : (
                        <AddCircleOutlineIcon sx={{ color: theme.palette.primary.main }} />
                      )
                    }
                    aria-controls="filter-content"
                    id="filter-header"
                    className={styles.accordionSummary}
                  >
                    <Typography sx={{ fontSize: '0.9rem' }}>{'Fuel Type'}</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: '0px 16px' }}>
                    <Box className={styles.accordionDetailsContainer}>
                      <FormControl sx={{ mb: '10px' }} fullWidth>
                        <InputLabel>Fuel Type</InputLabel>
                        <Select
                          {...getFieldProps('fuelType')}
                          label="Fuel Type"
                          defaultValue={'Any'}
                        >
                          <MenuItem value={'Any'}>Any</MenuItem>
                          <Divider />
                          <MenuItem value={'Gas'}>Gas</MenuItem>
                          <MenuItem value={'Flex Fuel'}>Flex Fuel</MenuItem>
                          <MenuItem value={'Diesel'}>Diesel</MenuItem>
                          <MenuItem value={'Hybrid'}>Hybrid</MenuItem>
                          <MenuItem value={'Electric'}>Electric</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>
              {/* Ext Filter */}
              <Box>
                <Accordion
                  className={styles.filterAccordion}
                  expanded={expandedExt}
                  onChange={handleChangeExt}
                >
                  <AccordionSummary
                    expandIcon={
                      expandedExt ? (
                        <RemoveCircleOutlineIcon />
                      ) : (
                        <AddCircleOutlineIcon sx={{ color: theme.palette.primary.main }} />
                      )
                    }
                    aria-controls="filter-content"
                    id="filter-header"
                    className={styles.accordionSummary}
                  >
                    <Typography sx={{ fontSize: '0.9rem' }}>{'Exterior Colour'}</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: '0px 16px' }}>
                    <Box className={styles.accordionDetailsContainer}>
                      <FormControl sx={{ mb: '10px' }} fullWidth>
                        <InputLabel>Exterior Colour</InputLabel>
                        <Select
                          {...getFieldProps('exteriorColour')}
                          label="Exterior Colour"
                          defaultValue={'Any'}
                        >
                          <MenuItem value={'Any'}>Any</MenuItem>
                          <Divider />
                          <MenuItem value={'black'}>Black</MenuItem>
                          <MenuItem value={'gray'}>Gray</MenuItem>
                          <MenuItem value={'blue'}>Blue</MenuItem>
                          <MenuItem value={'red'}>Red</MenuItem>
                          <MenuItem value={'green'}>Green</MenuItem>
                          <MenuItem value={'beige'}>Beige</MenuItem>
                          <MenuItem value={'white'}>White</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>
              <Box className={styles.buttonContainer}>
                <LoadingButton
                  className={styles.resetButton}
                  variant="contained"
                  loading={isSubmitting}
                  onClick={(e) => {
                    formik.resetForm();
                    setMakeName([]);
                    handleFormReset();
                    setModelNames([]);
                  }}
                >
                  Reset Filters
                </LoadingButton>
                <LoadingButton
                  className={styles.saveButton}
                  type="submit"
                  variant="contained"
                  loading={isSubmitting || modelsLoading}
                >
                  Apply
                </LoadingButton>
              </Box>
            </Form>
          </FormikProvider>
        </List>
      </Box>
    </Scrollbar>
  );

  return (
    <Box
      sx={{
        backgroundColor: !isMobile ? 'transparent !important' : '',
        [theme.breakpoints.down('lg')]: {
          paddingLeft: !isMobile ? '40px' : ''
        }
      }}
    >
      <MHidden width="mdUp">
        <Drawer
          sx={{
            borderRightStyle: 'none !important',
            zIndex: '99999',
            backgroundColor: 'transparent !important'
          }}
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: {
              width: isOpenSidebar ? DRAWER_WIDTH : COLLAPSE_WIDTH,
              position: 'relative',
              border: 'none',
              backgroundColor: !isMobile ? 'transparent !important' : ''
            }
          }}
        >
          {filterList}
        </Drawer>
      </MHidden>
      <MHidden width="mdDown">
        <Drawer
          sx={{
            borderRightStyle: 'none !important',
            position: 'fixed'
          }}
          variant="persistent"
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: {
              width: isOpenSidebar ? DRAWER_WIDTH : COLLAPSE_WIDTH,
              position: 'relative',
              border: 'none',
              backgroundColor: !isMobile ? 'transparent !important' : ''
            }
          }}
        >
          {filterList}
        </Drawer>
      </MHidden>
    </Box>
  );
}
