import { Box, Typography, BoxProps, IconButton, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ListItemDatePicker } from 'pages/auction/seller/components/CommonComponents';
import { FC, useState } from 'react';
import { Timestamp } from 'firebase/firestore';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
interface Props extends BoxProps {
  label: string;
  value: string | Timestamp;
  isBold?: boolean;
  isEditable?: boolean;
  handleSetDate?: any;
  isOutbid?: boolean;
}

const ListItemPersonDate: FC<Props> = ({
  label,
  value,
  isBold,
  isEditable,
  handleSetDate,
  isOutbid
}: Props) => {
  const theme = useTheme();
  const [dateValue, setDateValue] = useState<Timestamp>();
  const [isDisabled, setIsDisabled] = useState<boolean>(isEditable && value ? false : true);
  let valueDate;

  if (isEditable) {
    valueDate = value as Timestamp;
  }

  return (
    <Box sx={{ padding: '5px' }}>
      {isEditable ? (
        <Box sx={{ display: 'flex' }}>
          <ListItemDatePicker
            fieldValue={valueDate ? valueDate?.toDate() : ''}
            customWidth={'40%'}
            label={'Enter Date'}
            updateValue={(v) => {
              if (v && v instanceof Date && v.toString() !== 'Invalid Date') {
                const date = Timestamp.fromDate(v);
                setDateValue(date);
                setIsDisabled(false);
              } else {
                setDateValue(undefined);
                setIsDisabled(true);
              }
            }}
          />
          <Tooltip title="Click To Save Date">
            <IconButton
              onClick={() => handleSetDate(dateValue)}
              disabled={isDisabled}
              sx={{
                backgroundColor: 'transparent', // theme.palette.common.white,
                color: !isDisabled ? theme.palette.success.main : theme.palette.text.secondary,
                padding: '5px',
                borderRadius: '5px',
                marginLeft: '2px'
              }}
            >
              <CheckCircleOutlineIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ) : (
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: isBold ? 900 : 300,
            color:
              isOutbid && typeof value === 'string' && value.toLowerCase() === 'another buyer'
                ? theme.palette.error.main
                : isOutbid && typeof value === 'string' && value.toLowerCase() === 'you'
                ? theme.palette.success.main
                : isOutbid &&
                  typeof value === 'string' &&
                  label.toLowerCase() === 'winning dealership'
                ? theme.palette.primary.main
                : isOutbid &&
                  typeof value === 'string' &&
                  label.toLowerCase() === 'current status' &&
                  value.toLowerCase() !== 'no sale'
                ? theme.palette.success.main
                : isOutbid &&
                  typeof value === 'string' &&
                  label.toLowerCase() === 'current status' &&
                  value.toLowerCase() === 'no sale'
                ? theme.palette.error.main
                : ''
          }}
        >
          {value}
        </Typography>
      )}
      <Typography
        sx={{
          fontSize: '12px',
          color: theme.palette.text.secondary,
          textTransform: 'capitalize'
        }}
      >
        {label}
      </Typography>
    </Box>
  );
};

export default ListItemPersonDate;
