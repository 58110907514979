import { Grid, Typography, Button } from '@mui/material';
import AuctionStatus from '../auction/AuctionStatus';
import { makeStyles } from '@mui/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Dealership } from 'types/dealership';
import useAuth from 'hooks/useAuth';
import { useFirestoreByPath } from 'hooks/useFirestoreByPath';
import { Timestamp, where } from 'firebase/firestore';
import { useSnackbar } from 'notistack';
import { useTheme } from '@mui/material/styles';

type Props = {
  dealership: Dealership;
};

const DrawerChecklist = ({ dealership }: Props) => {
  const { user } = useAuth();
  const theme = useTheme();
  const invitePath = `users/${user?.uid}/invites`;
  const { enqueueSnackbar } = useSnackbar();
  const { update: updateInvite } = useFirestoreByPath<any>(
    invitePath,
    where('inviteType', '==', 'dealership')
  );

  const handleUpdateInvite = async () => {
    if (dealership.inviteId) {
      await updateInvite(dealership.inviteId, {
        inviteDateSent: Timestamp.now(),
        isExpired: false
      });
      enqueueSnackbar('New email sent', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        }
      });
    }
  };

  const useStyles = makeStyles({
    checklist: {
      padding: '14px',
      border: `1px solid ${theme.palette.grey[100]}`,
      borderRadius: '5px',
      marginBottom: '10px',
      cursor: 'pointer'
    }
  });

  const styles = useStyles();

  return (
    <Grid sx={{ color: theme.palette.secondary.darker, m: '19px' }} xs={12}>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid xs={6}>
          <Typography
            variant="subtitle2"
            component="h2"
            paragraph
            sx={{
              fontWeight: 700,
              mb: 0,
              color:
                theme.palette.mode === 'dark'
                  ? theme.palette.common.white
                  : theme.palette.text.primary
            }}
          >
            Registration Checklist
          </Typography>
        </Grid>
        <Grid xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          {dealership.status === 'incomplete' && (
            <Button
              variant="contained"
              sx={{ backgroundColor: theme.palette.primary.main }}
              onClick={handleUpdateInvite}
            >
              Resend Invite
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid sx={{ mt: '16px', mb: '32px' }}>
        <AuctionStatus status={dealership.status} />
      </Grid>
      <Grid
        className={styles.checklist}
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid sx={{ mr: '10px' }}>
          <CheckCircleIcon
            sx={{
              color:
                dealership.status === 'registered' ||
                dealership.status === 'suspended' ||
                dealership.status === 'disabled'
                  ? theme.palette.primary.main
                  : theme.palette.info.light
            }}
          />
        </Grid>
        <Grid>
          <Typography
            sx={{
              mt: '-6px',
              fontSize: '14px',
              color:
                theme.palette.mode === 'dark'
                  ? theme.palette.common.white
                  : theme.palette.text.primary
            }}
            component="h4"
          >
            Profile Complete
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DrawerChecklist;
