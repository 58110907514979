import { VehicleInfo } from 'types/vehicleInfo';

export const loadModelDetails = async (vehicle: VehicleInfo) => {
  const tempVehicle = { ...vehicle };
  const axios = require('axios');
  const url = `https://${process.env.REACT_APP_FIREBASE_BASE_FUNCTION_URL}.cloudfunctions.net/getModelDetails`;
  // const url = `https://us-central1-dealer-gavel-19afd.cloudfunctions.net/getModelDetails`;

  const options = {
    method: 'GET',
    url: url,
    params: {
      year: tempVehicle.details.year,
      make: tempVehicle.details.make,
      model: tempVehicle.details.model,
      vehicleId: tempVehicle.id
    },
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Content-Type'
    },
    returnBody: true,
    encodeBodyUtf8: false,
    decodeUtf8: false,
    cache: false
  };

  let response: any;
  try {
    response = await axios.request(options);
  } catch (error) {
    return false;
  }

  if (response.data.Status !== 'SUCCESS') return response;
  const tempOptions = response.data;
  return tempOptions;
};
