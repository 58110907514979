import { VehicleEquipmentType, VehicleInfo } from 'types/vehicleInfo';
import { VehicleDetailsType } from 'types/vehicleInfo';

export const loadFactoryOptions = async (vehicle: VehicleInfo) => {
  const tmpVehicle = { ...vehicle };
  const tmpVehDetails: VehicleDetailsType = tmpVehicle.details;
  const tmpVehEquipment: VehicleEquipmentType = tmpVehicle.equipment;
  const axios = require('axios');

  const url = 'https://vindecoder.p.rapidapi.com/v2.0/decode_vin';
  const apiKey = 'JYRYXYXqk1mshQKx3K8mhgya9XS4p13mO03jsnLLt7XiGjEAL5'; // '862bdfe7c9mshed5118d4dd15e27p1b9008jsn1a93cc484d9b';

  const options = {
    method: 'GET',
    url: url,
    params: { vin: tmpVehDetails.vin },
    headers: { 'X-RapidApi-Key': apiKey },
    returnBody: true,
    encodeBodyUtf8: false,
    decodeUtf8: false,
    cache: false
  };

  let response: any;
  try {
    response = await axios.request(options);
  } catch (error) {
    return false;
  }

  if (response.data.success !== true) return false;

  const tmpSpec = response.data;
  const factoryInfo = tmpSpec.specification.features;

  return factoryInfo;
};
