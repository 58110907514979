import { useMemo, ReactNode } from 'react';
import { CssBaseline } from '@mui/material';
import {
  createTheme,
  ThemeOptions,
  ThemeProvider,
  StyledEngineProvider
} from '@mui/material/styles';
import useSettings from '../hooks/useSettings';
import shape from './shape';
import palette from './palette';
import typography from './typography';
import breakpoints from './breakpoints';
import componentsOverride from './overrides';
import shadows, { customShadows } from './shadows';
import useAuth from 'hooks/useAuth';

type ThemeConfigProps = {
  children: ReactNode;
};

export default function ThemeConfig({ children }: ThemeConfigProps) {
  const { themeDirection } = useSettings();
  const { currentUser } = useAuth();

  const themeOptions: ThemeOptions = useMemo(
    () => ({
      palette:
        currentUser && currentUser.isLightMode
          ? { ...palette.dealerGavel, mode: 'light' }
          : { ...palette.dark, mode: 'dark' },
      shape,
      typography,
      breakpoints,
      direction: themeDirection,
      shadows: currentUser && currentUser.isLightMode ? shadows.light : shadows.dark,
      customShadows:
        currentUser && currentUser.isLightMode ? customShadows.light : customShadows.dark
    }),
    [themeDirection, currentUser]
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
