import { FC, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Box, Typography, IconButton, Dialog, TextField, Button } from '@mui/material';
import Scrollbar from 'components/Scrollbar';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { LoadingButton } from '@mui/lab';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { VehicleInfo, VehiclePhotos } from 'types/vehicleInfo';
import { format } from 'date-fns';
import { arrayUnion, doc, getDoc, Timestamp, updateDoc } from 'firebase/firestore';
import { useFirestore } from 'reactfire';
import useAuth from 'hooks/useAuth';
import { Dealership } from 'types/dealership';
import { NumericFormat } from 'react-number-format';

type Props = {
  vehicle: VehicleInfo;
  onClose: any;
  isBuyer: boolean;
};

const NegotiationDrawerContent: FC<Props> = (props: Props) => {
  const theme = useTheme();
  const firestore = useFirestore();
  const { currentUser } = useAuth();
  const { onClose, vehicle, isBuyer } = props;
  const [selectedAction, setSelectedAction] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [counterOfferAmount, setCounterOfferAmount] = useState<number>();
  const [formattedCounterOfferAmount, setFormattedCounterOfferAmount] = useState<number | string>();
  const [dealership, setDealership] = useState<Dealership>();
  const [negotiationHistory, setNegotiationHistory] = useState<any[]>();
  const [userDealership, setUserDealership] = useState<Dealership>();

  const appendNegotiationHistory = () => {
    const tempHistory = vehicle.auction.negotiationHistory;
    const previousBids = vehicle.auction.previousBids.sort((a, b) => {
      return b.dateTime.toMillis() - a.dateTime.toMillis();
    });
    const winningBid = {
      user: vehicle.buyerUser?.firstName + ' ' + vehicle.buyerUser?.lastName, // previousBids[0].bidderName,
      price: previousBids[0].price.toString(),
      dateTime: previousBids[0].dateTime,
      type: 'Closing Bid'
    };
    const hasWinningBid = tempHistory?.find((bid: any) => bid.type === 'Closing Bid');
    if (!hasWinningBid) {
      tempHistory?.unshift(winningBid);
    }
    setNegotiationHistory(tempHistory);
  };

  const getUserDealership = async () => {
    const docRef = doc(firestore, `dealerships/${currentUser.currentDealershipId}`);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const currentDealership = { id: docSnap.id, ...docSnap.data() } as Dealership;
      setUserDealership(currentDealership);
    }
  };

  const getDealership = async () => {
    const docRef = doc(firestore, `dealerships/${vehicle.dealershipId}`);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const currentDealership = { id: docSnap.id, ...docSnap.data() } as Dealership;
      setDealership(currentDealership);
    }
  };

  let vehiclePhoto;
  if (vehicle.photos) {
    const photos = vehicle.photos.filter(
      (v: any) => v.url && v.location && v.location.toLowerCase().includes('3/4 shot')
    );
    if (photos[0]) {
      vehiclePhoto = photos[0];
    }
  }

  useEffect(() => {
    appendNegotiationHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicle.auction.negotiationHistory]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getDealership();
      getUserDealership();
      appendNegotiationHistory();
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseModal = () => {
    setOpenModal(!openModal);
  };

  const handleOpenModal = (action: string) => {
    setOpenModal(!openModal);
    setSelectedAction(action);
  };

  const handleAcceptOffer = async () => {
    const docRef = doc(firestore, `vehicles/${vehicle.id}`);
    await updateDoc(docRef, {
      'auction.postAuctionStatus': vehicle.auction.isInStock ? 'arrived' : 'sold',
      'auction.currentPrice': vehicle.auction.negotiationPrice,
      'auction.dateSold': Timestamp.now()
    });
    handleCloseModal();
  };

  const handleDeclineOffer = async () => {
    const docRef = doc(firestore, `vehicles/${vehicle.id}`);
    await updateDoc(docRef, {
      'auction.postAuctionStatus': 'no sale',
      buyerReasonLost: 'Offer Declined'
    });
    handleCloseModal();
  };

  // const handleCounterOfferChange = (event: any) => {
  //   setCounterOfferAmount(event.target.value);
  // };

  const handleCounterOffer = async () => {
    const docRef = doc(firestore, `vehicles/${vehicle.id}`);
    await updateDoc(docRef, {
      'auction.negotiationStatusBuyer': isBuyer ? 'awaiting response' : 'response required',
      'auction.negotiationStatusSeller': isBuyer ? 'response required' : 'awaiting response',
      'auction.negotiationPrice': counterOfferAmount,
      'auction.negotiationHistory': arrayUnion({
        user: `${currentUser.firstName} ${currentUser.lastName}`,
        dealership: userDealership?.dealershipName,
        price: counterOfferAmount,
        dateTime: Timestamp.now(),
        type: 'Counter'
      })
    });
    setFormattedCounterOfferAmount(undefined);
    handleCloseModal();
  };

  const useStyles = makeStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.palette.divider,
      [theme.breakpoints.down(1450)]: {
        width: '100%'
      }
    },
    buttonHeaderContainer: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 10px',
      height: '40px',
      backgroundColor: theme.palette.primary.main
    },
    iconButtonContainer: {
      marginLeft: '2px'
    },
    iconButton: {
      width: '25px',
      height: '25px',
      color: 'white'
    },
    timeRemaining: {
      lineHeight: 1,
      marginRight: '5px',
      color: 'white',
      fontSize: '14px',
      fontWeight: '700'
    },
    stockChip: {
      position: 'absolute',
      top: '50px',
      right: '10px',
      fontWeight: '700',
      color: 'white',
      zIndex: '999'
    },
    imageBox: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start'
    },
    filterChipContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      alignItems: 'center',
      margin: '10px 0px',
      width: '100%'
    },
    filterChip: {
      margin: '5px 10px',
      color:
        theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.info.lighter,
      fontWeight: '700',
      '&.active': {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.lighter
      }
    },
    slider: {
      display: 'flex',
      flexDirection: 'row',
      position: 'absolute',
      bottom: '-70px',
      width: 'calc(100% - 80px)',
      overflowX: 'scroll',
      scrollBehavior: 'smooth'
    },
    declineButton: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,
      marginBottom: '10px',
      '&:hover': {
        backgroundColor: theme.palette.error.dark
      }
    },
    counterButton: {
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.common.black,
      marginBottom: '10px',
      '&:hover': {
        backgroundColor: theme.palette.grey[300]
      }
    },
    cancelButton: {
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.info.light,
      '&:hover': {
        backgroundColor: theme.palette.grey[300]
      }
    }
  });

  const styles = useStyles();

  const currencyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });

  return (
    <Scrollbar
      sx={{
        height: '100%',
        backgroundColor: 'transparent' // theme.palette.common.white
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: 'auto'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            padding: '10px'
          }}
        >
          <Typography component="p" variant="h5">
            {vehicle?.details.year} {vehicle?.details.make}&nbsp;
            {vehicle?.details.model} {vehicle?.details.trim}
          </Typography>
          <IconButton onClick={onClose} sx={{ display: 'flex' }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box component="img" alt="vehicle photo" src={vehiclePhoto?.url} />
        <Box sx={{ padding: '0 20px' }}>
          <Box
            sx={{
              width: '100%',
              borderRadius: '5px',
              margin: '20px auto',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              padding: '15px 5px',
              backgroundColor: theme.palette.primary.lighter
            }}
          >
            <InfoOutlinedIcon sx={{ marginLeft: '10px' }} />
            {isBuyer ? (
              <Typography sx={{ marginLeft: '10px' }}>
                In Negotation: {vehicle.auction.negotiationStatusBuyer}
              </Typography>
            ) : (
              <Typography sx={{ marginLeft: '10px' }}>
                In Negotation: {vehicle.auction.negotiationStatusSeller}
              </Typography>
            )}
          </Box>
          <Box>
            <Typography
              sx={{
                fontWeight: '700',
                fontSize: '16px',
                color: theme.palette.text.secondary
              }}
            >
              Current Offer
            </Typography>
            <Box
              sx={{
                // margin: '15px 0px 0px',
                padding: '10px 0px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start'
              }}
            >
              {dealership?.logoUrl && (
                <Box
                  component="img"
                  alt="dealership logo"
                  src={dealership?.logoUrl}
                  sx={{ width: 'auto', height: '40px', marginRight: '5px', borderRadius: '5px' }}
                ></Box>
              )}
              <Box>
                <Typography component="p" sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                  {vehicle?.details.make} {vehicle?.details.model}
                </Typography>
                {negotiationHistory && negotiationHistory.length > 0 ? (
                  <Typography
                    component="p"
                    sx={{
                      lineHeight: 1.5,
                      marginLeft: '3px',
                      fontSize: '14px',
                      color: theme.palette.info.lighter
                    }}
                  >
                    {negotiationHistory[0].user}
                  </Typography>
                ) : (
                  <>
                    {vehicle.buyerUser && (
                      <Typography
                        component="p"
                        sx={{
                          lineHeight: 1.5,
                          marginLeft: '3px',
                          fontSize: '14px',
                          color: theme.palette.info.lighter
                        }}
                      >
                        {vehicle.buyerUser.firstName + ' ' + vehicle.buyerUser.lastName}
                      </Typography>
                    )}
                  </>
                )}
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%'
            }}
          >
            <Typography sx={{ color: theme.palette.text.secondary }}>Offer Price</Typography>
            {vehicle.auction.negotiationPrice ? (
              <Typography variant="h3">
                {currencyFormat.format(vehicle.auction.negotiationPrice)}
              </Typography>
            ) : (
              <Typography variant="h3">
                {currencyFormat.format(vehicle.auction.currentPrice)}
              </Typography>
            )}
          </Box>
          <Box /* sx={{ marginTop: '20px' }} */>
            <LoadingButton
              variant="contained"
              size="large"
              fullWidth
              onClick={() => handleOpenModal('accept')}
              disabled={
                (isBuyer &&
                  vehicle.auction.negotiationStatusBuyer.toLowerCase() === 'awaiting response') ||
                (!isBuyer &&
                  vehicle.auction.negotiationStatusSeller.toLowerCase() === 'awaiting response')
              }
            >
              Accept
            </LoadingButton>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                margin: '10px 0px',
                flexDirection: 'row',
                flexWrap: 'wrap'
              }}
            >
              <LoadingButton
                variant="contained"
                size="large"
                className={styles.declineButton}
                onClick={() => handleOpenModal('decline')}
                disabled={
                  (isBuyer &&
                    vehicle.auction.negotiationStatusBuyer.toLowerCase() === 'awaiting response') ||
                  (!isBuyer &&
                    vehicle.auction.negotiationStatusSeller.toLowerCase() === 'awaiting response')
                }
              >
                Decline
              </LoadingButton>
              <LoadingButton
                variant="contained"
                size="large"
                className={styles.counterButton}
                onClick={() => handleOpenModal('counter')}
                disabled={
                  (isBuyer &&
                    vehicle.auction.negotiationStatusBuyer.toLowerCase() === 'awaiting response') ||
                  (!isBuyer &&
                    vehicle.auction.negotiationStatusSeller.toLowerCase() === 'awaiting response')
                }
              >
                Counter
              </LoadingButton>
            </Box>
          </Box>
          <Box sx={{ marginTop: '10px' }}>
            <Typography
              sx={{
                fontWeight: 'bold',
                color: theme.palette.text.secondary
                // marginBottom: '20px'
              }}
            >
              Negotiation Timeline
            </Typography>
            {/* prettier-ignore */}
            <Timeline>
              {negotiationHistory &&
                negotiationHistory.length > 0 &&
                negotiationHistory.sort((a, b) => b.dateTime.toMillis() - a.dateTime.toMillis())
                .map((item, index) => {
                  return (
                    <TimelineItem key={index}>
                      <TimelineOppositeContent
                        sx={{ margin: 'auto 0', color: theme.palette.text.secondary }}
                        align="right"
                      >
                        {format(item.dateTime.toDate(), 'dd MMM yyyy hh:mm a')}
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot></TimelineDot>
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent sx={{ py: '6px', px: 2 }}>
                        <Typography sx={{ color: theme.palette.text.secondary }}>
                          {item.user}
                        </Typography>
                        <Box sx={{ display: 'flex' }}>
                          <Typography
                            sx={{
                              fontSize: '12px',
                              backgroundColor: `${currentUser.firstName} ${currentUser.lastName}` === item.user ? '#21BA45' : theme.palette.text.secondary,
                              padding: '0px 15px',
                              width: 'fit-content',
                              borderRadius: '15px',
                              color: theme.palette.common.white,
                              fontWeight: '700',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              marginRight: '3px'
                            }}
                          >
                            {item.type}
                          </Typography>
                          <Typography variant="h6">
                            {currencyFormat.format(parseInt(item.price))}
                          </Typography>
                        </Box>
                      </TimelineContent>
                    </TimelineItem>
                  );
                })}
            </Timeline>
          </Box>
        </Box>
      </Box>
      <Dialog open={openModal} onClose={handleCloseModal} sx={{ zIndex: '9999999' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '20px',
            position: 'relative',
            minWidth: '500px',
            borderBottom: `1px solid ${theme.palette.divider}`
          }}
        >
          {selectedAction.toLowerCase() === 'accept' && (
            <Typography component="p" variant="h5">
              Accept
            </Typography>
          )}
          {selectedAction.toLowerCase() === 'decline' && (
            <Typography component="p" variant="h5">
              Decline
            </Typography>
          )}
          {selectedAction.toLowerCase() === 'counter' && (
            <Typography component="p" variant="h5">
              Counter Offer
            </Typography>
          )}
          <CloseIcon
            onClick={handleCloseModal}
            sx={{
              position: 'absolute',
              right: '10px',
              cursor: 'pointer'
            }}
          />
        </Box>
        <Box sx={{ padding: '40px' }}>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: '10px'
            }}
          ></Box>
          {selectedAction.toLowerCase() === 'accept' && vehicle.buyerUser && (
            <Box sx={{ textAlign: 'center' }}>
              {vehicle.auction.negotiationPrice ? (
                <>
                  {/* prettier-ignore */}
                  <Typography>
                    Are you sure you want to ACCEPT {currencyFormat.format(vehicle.auction.negotiationPrice)} from {vehicle.auction.negotiationStatusBuyer.toLowerCase() === 'awaiting response' ? vehicle.buyerUser.firstName + ' ' + vehicle.buyerUser.lastName : vehicle.auction.sellerName}?
                  </Typography>
                </>
              ) : (
                <>
                  {/* prettier-ignore */}
                  <Typography>
                    Are you sure you want to ACCEPT {currencyFormat.format(vehicle.auction.currentPrice)} from {vehicle.auction.negotiationStatusBuyer.toLowerCase() === 'awaiting response' ? vehicle.buyerUser.firstName + ' ' + vehicle.buyerUser.lastName : vehicle.auction.sellerName}?
                  </Typography>
                </>
              )}
              <Typography>
                {/* prettier-ignore */}
                {vehicle.auction.negotiationStatusBuyer.toLowerCase() === 'awaiting response'
                  ? `Accepting an offer will move this vehicle to Sold or Arrived if it is in stock.`
                  : `Accepting an offer will move this vehicle to Won or Arrived if it is in stock.`}
              </Typography>
            </Box>
          )}
          {selectedAction.toLowerCase() === 'counter' && (
            <NumericFormat
              value={formattedCounterOfferAmount}
              prefix="$"
              decimalScale={0}
              thousandSeparator={true}
              allowNegative={false}
              label="Counter Offer Amount"
              fullWidth
              customInput={TextField}
              onValueChange={(values: any) => {
                setFormattedCounterOfferAmount(values.formattedValue);
                setCounterOfferAmount(values.value);
              }}
            />
          )}
          {selectedAction.toLowerCase() === 'decline' && vehicle.buyerUser && (
            <Box sx={{ textAlign: 'center' }}>
              {vehicle.auction.negotiationPrice ? (
                <>
                  {/* prettier-ignore */}
                  <Typography>
                    Are you sure you want to DECLINE {currencyFormat.format(vehicle.auction.negotiationPrice)} from {vehicle.auction.negotiationStatusBuyer.toLowerCase() === 'awaiting response' ? vehicle.buyerUser.firstName + ' ' + vehicle.buyerUser.lastName : vehicle.auction.sellerName}?
                  </Typography>
                </>
              ) : (
                <>
                  {/* prettier-ignore */}
                  <Typography>
                    Are you sure you want to DECLINE {currencyFormat.format(vehicle.auction.currentPrice)} from {vehicle.auction.negotiationStatusBuyer.toLowerCase() === 'awaiting response' ? vehicle.buyerUser.firstName + ' ' + vehicle.buyerUser.lastName : vehicle.auction.sellerName}?
                  </Typography>
                </>
              )}
              <Typography>
                {/* prettier-ignore */}
                {vehicle.auction.negotiationStatusBuyer.toLowerCase() === 'awaiting response'
                  ? `Declining an offer will move this vehicle to No Sale.`
                  : `Declining an offer will move this vehicle to Lost.`}
              </Typography>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0px 40px 20px 40px'
          }}
        >
          <Button
            size="large"
            variant="contained"
            className={styles.cancelButton}
            onClick={handleCloseModal}
          >
            Cancel
          </Button>
          {selectedAction.toLowerCase() === 'accept' && (
            <LoadingButton
              size="large"
              type="submit"
              variant="contained"
              onClick={() => handleAcceptOffer()}
            >
              Accept
            </LoadingButton>
          )}
          {selectedAction.toLowerCase() === 'counter' && (
            <LoadingButton
              size="large"
              type="submit"
              variant="contained"
              className={styles.counterButton}
              onClick={handleCounterOffer}
            >
              Send Counter Offer
            </LoadingButton>
          )}
          {selectedAction.toLowerCase() === 'decline' && (
            <LoadingButton
              size="large"
              type="submit"
              variant="contained"
              className={styles.declineButton}
              onClick={handleDeclineOffer}
            >
              Decline
            </LoadingButton>
          )}
        </Box>
      </Dialog>
    </Scrollbar>
  );
};

export default NegotiationDrawerContent;
