import { IconButton, Icon, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { useRef, useState } from 'react';
import Consts from './Consts';
import Square from './Square';
import { useTheme } from '@mui/material/styles';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Carousel from 'react-elastic-carousel';
import { VehiclePhotos } from 'types/vehicleInfo';
import SettingsOverscanIcon from '@mui/icons-material/SettingsOverscan';
import AspectRatioTwoToneIcon from '@mui/icons-material/AspectRatioTwoTone';

type ImageCarouselType = {
  images: VehiclePhotos[];
  bottom?: string;
  isVDP?: boolean;
  isMarkedAsSold?: boolean;
  initialIndex?: number;
};
export const ImageCarousel = (props: ImageCarouselType) => {
  const theme = useTheme();
  const carouselRef = useRef<any>();
  const sliderRef = useRef<any>();
  const { images, bottom, isVDP, isMarkedAsSold, initialIndex } = props;

  const handleSlideChange = (direction: any) => {
    if (carouselRef.current && direction === 'NEXT') {
      carouselRef.current.slideNext();
      sliderRef.current.scrollLeft += 50;
    } else if (carouselRef.current && direction === 'PREV') {
      carouselRef.current.slidePrev();
      sliderRef.current.scrollLeft -= 50;
    }
  };

  const useStyles = makeStyles({
    '.rec.rec-slider-container': {
      maxHeight: '195px !Important'
    },
    slider: {
      display: 'flex',
      flexDirection: 'row',
      position: 'absolute',
      bottom: bottom === 'vg' ? '-45px' : '-100px',
      width: 'calc(100% - 80px)',
      //overflowX: 'scroll',
      overflow: 'hidden',
      scrollBehavior: 'smooth',
      maxHeight: '195px'
    }
  });

  const styles = useStyles();

  return (
    <Box sx={{ position: 'relative' }}>
      <Carousel
        key={initialIndex}
        initialActiveIndex={initialIndex}
        ref={carouselRef}
        isRTL={false}
        renderArrow={({ type, onClick, isEdge }) => {
          const pointer = type === Consts.PREV ? 'back' : 'forward';
          return (
            <IconButton
              sx={{
                position: 'absolute',
                bottom: bottom === 'vg' ? '-35px' : '-90px',
                zIndex: '999',
                backgroundColor: `${theme.palette.primary.main} !important`,
                left: `${pointer === 'back' ? '10px' : ''}`,
                right: `${pointer === 'forward' ? '10px' : ''}`
              }}
              onClick={() => handleSlideChange(type)}
              disabled={isEdge}
            >
              <Icon
                sx={{
                  color: 'white',
                  fontSize: 15
                }}
              >
                {pointer === 'back' ? (
                  <ArrowBackIosNewIcon sx={{ fontSize: 15 }} />
                ) : (
                  <ArrowForwardIosIcon sx={{ fontSize: 15 }} />
                )}
              </Icon>
            </IconButton>
          );
        }}
        renderPagination={({ pages, activePage, onClick }) => {
          return (
            <Box ref={sliderRef} className={styles.slider}>
              <Box sx={{ display: 'flex' }}>
                {pages &&
                  images &&
                  pages.map((key, index) => {
                    const isActivePage = activePage === key;
                    const thumb = images && images[index] ? `url(${images[index].url})` : '';
                    const redBorder =
                      images &&
                      images[index] &&
                      images[index].categoryName &&
                      images[index].categoryName === 'Damages'
                        ? true
                        : false;
                    return (
                      <Square
                        thumb={thumb}
                        redBorder={redBorder}
                        key={key}
                        onClick={() => onClick(key.toString())}
                        active={isActivePage}
                      />
                    );
                  })}
              </Box>
            </Box>
          );
        }}
      >
        {images &&
          images.map((image, index) => (
            <div
              key={index}
              style={{
                margin: '0',
                width: '100%',
                height: isVDP ? '250px' : '195px',
                backgroundImage: `url(${images[index].url})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover', // contain
                backgroundRepeat: 'no-repeat',
                border:
                  image.categoryName && image.categoryName === 'Damages' ? '5px solid red' : 'none',
                borderStyle:
                  image.categoryName && image.categoryName === 'Damages'
                    ? 'none solid none solid'
                    : 'none'
              }}
            >
              <Tooltip title="Expand Picture">
                <IconButton
                  sx={{ marginTop: isMarkedAsSold ? '25px' : '5px' }}
                  onClick={() => {
                    window.open(image.url, '_blank');
                  }}
                >
                  <AspectRatioTwoToneIcon sx={{ color: theme.palette.primary.main }} />
                </IconButton>
              </Tooltip>
              {isVDP && image.categoryName && image.categoryName === 'Damages' && (
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: '0px',
                    marginLeft: '7px',
                    marginBottom: '7px',
                    padding: '5px',
                    border: '2px solid black',
                    borderRadius: '8px',
                    backgroundColor: 'rgba(0, 0, 0, .5)',
                    color: theme.palette.common.white
                  }}
                >
                  {/* prettier-ignore */}
                  <Typography sx={{ fontSize: '13px', fontWeight: '700' }}>
                    {image.location} : {` ${image.damageType ? image.damageType : 'No Dmg Type'} | ${image.damageSize ? image.damageSize : 'No Dmg Size'} | ${image.damageNumber ? image.damageNumber : 'No Dmg Number'}`}
                  </Typography>
                </Box>
              )}
            </div>
          ))}
      </Carousel>
    </Box>
  );
};
