function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_BUYER = '/buy';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_SELLER = '/sell';
const ROOTS_PROFILE = '/profile';
const ROOTS_ADMIN = '/admin';
const ROOTS_COMMUNITY = '/community';
const ROOTS_VDP = '/vdp';

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
  selectAccount: path(ROOTS_AUTH, '/select-account')
};

export const PATH_BUYER = {
  root: ROOTS_BUYER,
  dashboard: path(ROOTS_BUYER, '/buy'),
  preAuction: path(ROOTS_BUYER, '/pre-auction'),
  activeAuctions: path(ROOTS_BUYER, '/active-auctions'),
  postAuction: path(ROOTS_BUYER, '/post-auction'),
  lostVehicleHistory: path(ROOTS_BUYER, '/vehicle-bidding-history'),
  account: {
    manageAccount: path(ROOTS_BUYER, '/manage-account')
  }
};

export const PATH_SELLER = {
  root: ROOTS_SELLER,
  dashboard: path(ROOTS_SELLER, '/sell'),
  activeAuctions: path(ROOTS_SELLER, '/active-auctions'),
  preAuction: path(ROOTS_SELLER, '/pre-auction'),
  postAuction: path(ROOTS_SELLER, '/post-auction'),
  vehicleCapture: path(ROOTS_SELLER, '/vehicle-capture'),
  captureWorkflow: path(ROOTS_SELLER, '/capture-workflow')
};

export const PATH_ADMIN = {
  root: ROOTS_ADMIN,
  manageTeams: path(ROOTS_ADMIN, '/manage-teams'),
  // dealerNetwork: path(ROOTS_ADMIN, '/dealer-network'),
  manageDealers: path(ROOTS_ADMIN, '/manage-dealers'),
  reports: path(ROOTS_ADMIN, '/reports'),
  arrivedVehicles: path(ROOTS_ADMIN, '/reports/arrived-vehicles'),
  deliveredVehicles: path(ROOTS_ADMIN, '/reports/delivered-vehicles')
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD
};

export const PATH_PROFILE = {
  root: ROOTS_PROFILE,
  accountInformation: path(ROOTS_PROFILE, '/account-information'),
  security: path(ROOTS_PROFILE, '/security'),
  notifications: path(ROOTS_PROFILE, '/notifications'),
  manageAccount: path(ROOTS_PROFILE, '/manage-account')
};

export const PATH_COMMUNITY = {
  root: ROOTS_COMMUNITY,
  buyerNetwork: path(ROOTS_COMMUNITY, '/buyer-network'),
  sellerNetwork: path(ROOTS_COMMUNITY, '/seller-network'),
  findDealership: path(ROOTS_COMMUNITY, '/find-dealership')
};

export const PATH_VDP = {
  root: ROOTS_VDP,
  vehicleDetails: path(ROOTS_VDP, '/vehicle-details')
};
