import { Grid, IconButton, Popover, MenuItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { fDateTimeSuffix } from 'components/shared/dateTimeFormat';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { VehicleInfo } from 'types/vehicleInfo';
import ListItemDetails from 'components/shared/ListItemDetails';
import ListItemBidPrice from 'components/shared/ListItemPriceBid';
import ListItemPersonDate from 'components/shared/ListItemPersonDate';
import { Link as RouterLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useFirestore } from 'reactfire';
import useAuth from 'hooks/useAuth';
import moment from 'moment';

interface Props {
  vehicle: VehicleInfo;
  isDelivered?: boolean;
  isOutbid?: boolean;
  sendVehicleToParent: (index: number, vehicle: VehicleInfo) => void;
}

const ARRIVED_MENU_OPTIONS = [{ label: 'Vehicle VDP', linkTo: '#' }];

const MENU_OPTIONS = [
  { label: 'Vehicle VDP', linkTo: '#' },
  { label: 'Bill of Sale', linkTo: '#' }
];

const BID_HISTORY_OPTIONS = [
  { label: 'Vehicle VDP', linkTo: '#' },
  { label: 'Auction History', linkTo: '#' }
];

export default function ReportListItem({
  vehicle,
  isDelivered,
  isOutbid,
  sendVehicleToParent
}: Props) {
  const theme = useTheme();
  const [anchorPopup, setAnchorPopup] = useState<HTMLButtonElement | null>(null);
  const [, setPopupOpened] = useState(false);
  const [, setSelectedIndex] = useState(0);
  const [daysInArrived, setDaysInArrived] = useState<number>(0);
  const { user } = useAuth();

  const currencyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });

  const open = Boolean(anchorPopup);
  const id = open ? 'simple-popover' : undefined;

  const useStyles = makeStyles({
    menuPopover: {
      boxShadow: '0 0 2px 0 rgba(139, 148, 156, 0.24),0 10px 20px -4px rgba(139, 148, 156, 0.24)',
      // backgroundColor: theme.palette.primary.contrastText,
      paddingTop: '20px',
      paddingBottom: '20px',
      '@global': {
        '.MuiMenuItem-root': {
          paddingBottom: '6px'
        }
      }
    },
    cancelButton: {
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.common.black,
      '&:hover': {
        backgroundColor: theme.palette.grey[300]
      }
    },
    declineButton: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.error.dark
      }
    }
  });

  const styles = useStyles();

  let logoImage;
  if (vehicle.photos) {
    const photos = vehicle.photos.filter(
      (v: any) => v.url && v.location && v.location.toLowerCase().includes('3/4 shot')
    );
    if (photos[0]) {
      logoImage = photos[0].url;
    }
  }

  useEffect(() => {
    if (!isDelivered && vehicle && !isOutbid) {
      const days = moment().diff(moment(vehicle.auction.dateArrived.toDate()), 'days');
      setDaysInArrived(days);
    }
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorPopup(event.currentTarget);
    setPopupOpened(true);
  };

  const handleMenuItemClick = async (event: React.MouseEvent<HTMLButtonElement>, index: number) => {
    setSelectedIndex(index);
    sendVehicleToParent(index, vehicle);
  };

  const handleClose = () => {
    setAnchorPopup(null);
    setPopupOpened(false);
  };

  return (
    <>
      <Grid
        xs={12}
        sx={{
          minWidth: '1475px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          border:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`,
          borderRadius: '10px',
          padding: '6px !important',
          '&:not(first-of-type)': {
            marginTop: '5px'
          }
        }}
      >
        {/* prettier-ignore */}
        <Grid>
          <ListItemDetails
            vehicle={vehicle}
            isSellerName={false}
            isCaptureItem={true}
            imageUrl={logoImage}
          ></ListItemDetails>
        </Grid>
        <Grid
          item
          xs={2}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '6px 20px',
            minWidth: vehicle.floorPriceMissing ? '280px' : '150px'
          }}
        >
          <ListItemBidPrice
            label={'top bid'}
            value={currencyFormat.format(vehicle.auction.currentPrice)}
            color={theme.palette.primary.main}
          ></ListItemBidPrice>
        </Grid>
        <Grid
          item
          xs={3}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '6px 20px',
            minWidth: '250px'
          }}
        >
          {isDelivered && (
            <ListItemPersonDate
              label={'date delivered'}
              value={fDateTimeSuffix(vehicle.auction.dateDelivered.toDate())}
            ></ListItemPersonDate>
          )}
          {isOutbid && (
            <ListItemPersonDate
              label={'date ended'}
              value={
                moment(vehicle.auction.endDate.toDate()).format('ll') +
                ' ' +
                moment(vehicle.auction.endTime.toDate()).format('hh:mm a')
              }
              // value={fDateTimeSuffix(vehicle.auction.endDate.toDate())}
            ></ListItemPersonDate>
          )}
          {!isOutbid && !isDelivered && (
            <ListItemPersonDate
              label={'date arrived'}
              value={fDateTimeSuffix(vehicle.auction.dateArrived.toDate())}
            ></ListItemPersonDate>
          )}
        </Grid>
        {!isDelivered && !isOutbid && (
          <Grid
            item
            xs={3}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '6px 20px',
              minWidth: '125px'
            }}
          >
            <ListItemPersonDate
              label={'days in arrived'}
              value={daysInArrived?.toString()}
            ></ListItemPersonDate>
          </Grid>
        )}
        {isOutbid && vehicle.auction.bidLeaderId !== user?.uid && (
          <Grid
            item
            xs={3}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '6px 20px',
              minWidth: '125px'
            }}
          >
            <ListItemPersonDate
              isOutbid={true}
              label={'reason lost'}
              value={'Another Buyer'}
            ></ListItemPersonDate>
          </Grid>
        )}
        {isOutbid && vehicle.auction.bidLeaderId === user?.uid && (
          <Grid
            item
            xs={3}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '6px 20px',
              minWidth: '125px'
            }}
          >
            <ListItemPersonDate
              isOutbid={true}
              label={'winning bidder'}
              value={'You'}
            ></ListItemPersonDate>
          </Grid>
        )}
        {isOutbid && vehicle.auction.bidLeaderId !== user?.uid && (
          <Grid
            item
            xs={3}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '6px 20px',
              minWidth: '250px'
            }}
          >
            <ListItemPersonDate
              isOutbid={true}
              label={'Winning Dealership'}
              value={vehicle.buyerDealership?.dealershipName as string}
            ></ListItemPersonDate>
          </Grid>
        )}
        {isOutbid && vehicle.auction.bidLeaderId === user?.uid && (
          <Grid
            item
            xs={3}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '6px 20px',
              minWidth: '250px'
            }}
          >
            <ListItemPersonDate
              isOutbid={true}
              label={'current status'}
              value={vehicle.auction.postAuctionStatus.toUpperCase()}
            ></ListItemPersonDate>
          </Grid>
        )}
        {!isOutbid && (
          <Grid
            item
            xs={3}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '6px 20px',
              minWidth: '250px'
            }}
          >
            <ListItemPersonDate
              label={'Primary Contact (Buyer)'}
              value={vehicle.buyerDealership?.primaryContactEmail as string}
            ></ListItemPersonDate>
          </Grid>
        )}
        {!isOutbid && (
          <Grid
            item
            xs={3}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '6px 20px',
              minWidth: '250px'
            }}
          >
            <ListItemPersonDate
              label={'Primary Contact (Seller)'}
              value={vehicle.sellerDealership?.primaryContactEmail as string}
            ></ListItemPersonDate>
          </Grid>
        )}
        <Grid
          item
          xs={1}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            minWidth: '50px',
            padding: '6px'
          }}
        >
          <IconButton
            aria-describedby={'icon-button'}
            onClick={handleClick}
            sx={{
              backgroundColor:
                theme.palette.mode === 'dark' ? '#2D2F3D' : theme.palette.background.paper,
              padding: '5px',
              borderRadius: '5px',
              marginLeft: '10px'
            }}
          >
            <MoreVertIcon sx={{ color: theme.palette.mode === 'dark' ? '#989DB1' : '' }} />
          </IconButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorPopup}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            sx={{ width: 220 }}
            PaperProps={{ classes: { root: styles.menuPopover } }}
          >
            <>
              {isDelivered ? (
                <>
                  {MENU_OPTIONS.map((option, index) => (
                    <MenuItem
                      key={option.label}
                      to={option.linkTo}
                      component={RouterLink}
                      onClick={(event: any) => handleMenuItemClick(event, index)}
                      sx={{ typography: 'body2', py: 1, px: 2.5 }}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </>
              ) : (
                <>
                  {isOutbid ? (
                    <>
                      {BID_HISTORY_OPTIONS.map((option, index) => (
                        <MenuItem
                          key={option.label}
                          to={option.linkTo}
                          component={RouterLink}
                          onClick={(event: any) => handleMenuItemClick(event, index)}
                          sx={{ typography: 'body2', py: 1, px: 2.5 }}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </>
                  ) : (
                    <>
                      {ARRIVED_MENU_OPTIONS.map((option, index) => (
                        <MenuItem
                          key={option.label}
                          to={option.linkTo}
                          component={RouterLink}
                          onClick={(event: any) => handleMenuItemClick(event, index)}
                          sx={{ typography: 'body2', py: 1, px: 2.5 }}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </>
                  )}
                </>
              )}
            </>
          </Popover>
        </Grid>
      </Grid>
    </>
  );
}
