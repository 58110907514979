import 'firebase/firestore';
import { collection, doc, getFirestore, writeBatch } from 'firebase/firestore';
import { app } from '../index';

import { getFunctions, httpsCallable } from 'firebase/functions';

export const batchUpdateAddFront = async (
  collectionName: string,
  arrayOfIds: string[] = [],
  arrayOfData: any[]
) => {
  let selectedIds: string[] = [];
  if (arrayOfIds.length) {
    selectedIds = arrayOfIds;
  } else {
    for (let i = 0; i < arrayOfData.length; i++) {
      selectedIds.push(arrayOfData[i].id as string);
    }
  }

  // Batch operation cannot have more than 500 iterations at a time
  const chunkSize = 499;
  const batches = [];
  const db = getFirestore(app);

  for (let i = 0; i < selectedIds.length; i += chunkSize) {
    const chunkIds = selectedIds.slice(i, i + chunkSize);
    const chunkFields = arrayOfData.slice(i, i + chunkSize);

    const batch = writeBatch(db);

    chunkIds.forEach((id, index) => {
      const docRef = doc(collection(db, collectionName), id);
      batch.set(docRef, chunkFields[index], { merge: true });
    });

    batches.push(batch);
  }

  // Execute all batches
  await Promise.all(batches.map((batch) => batch.commit()));
};

// export const batchUpdateAdd = async (
//   collection: string,
//   arrayOfIds: string[] = [],
//   arrayOfData: any[]
// ) => {
//   let selectedIds: string[] = [];
//   if (arrayOfIds.length) {
//     selectedIds = arrayOfIds;
//   } else {
//     for (let i = 0; i < arrayOfData.length; i++) {
//       selectedIds.push(arrayOfData[i].id as string);
//     }
//   }
//   // Batch operation cannot have more than 500 iterations at the time
//   const chunkSize = 499;
//   for (let i = 0; i < selectedIds.length; i += chunkSize) {
//     const chunkIds = selectedIds.slice(i, i + chunkSize);
//     const chunkFields = arrayOfData.slice(i, i + chunkSize);

//     const rawData = {
//       collection,
//       idsData: chunkIds,
//       rawData: chunkFields
//     };

//     const functions = getFunctions();
//     const batchSet = httpsCallable(functions, 'batchSet');
//     await batchSet(rawData);
//   }
// };
