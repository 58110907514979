import styled from 'styled-components';

export default styled.div`
  cursor: pointer;
  transition: all 250ms ease-in;
  background-image: ${({ thumb }) => (thumb ? thumb : 'transparent !important')};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transform: scale(${({ active }) => (!active ? 1 : 1.25)});
  box-shadow: 0 0 2px 1px #555;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  margin: 8px 8px;
  border: ${({ redBorder }) => (redBorder ? '3px solid red' : 'none')};
  border-style: ${({ redBorder }) => (redBorder ? 'none solid none solid' : 'none')};
`;
