/* eslint-disable import/no-duplicates */
import { createContext, ReactNode, useEffect, useReducer, useState } from 'react';
import * as firebase from 'firebase/auth';
// @types
import { ActionMap, AuthState, AuthUser, FirebaseContextType } from '../@types/authentication';
import 'firebase/firestore';
import { User } from 'constants/user';
import { getFirestore, getDoc, doc, Timestamp, updateDoc } from 'firebase/firestore';
// import { getFunctions, httpsCallable } from 'firebase/functions';
// ----------------------------------------------------------------------

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  currentUser: undefined,
  claims: undefined
};

enum Types {
  Initial = 'INITIALISE'
}

type FirebaseAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
    currentUser: User | undefined;
    claims: firebase.ParsedToken | undefined;
  };
};

type FirebaseActions = ActionMap<FirebaseAuthPayload>[keyof ActionMap<FirebaseAuthPayload>];

const reducer = (state: AuthState, action: FirebaseActions) => {
  if (action.type === 'INITIALISE') {
    const { isAuthenticated, user, claims, currentUser } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      claims,
      user,
      currentUser
    };
  }

  return state;
};

const AuthContext = createContext<FirebaseContextType | null>(null);
// let userSub: Function | undefined = undefined;

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [currentUser, setCurrentUser] = useState<any>();

  useEffect(
    () =>
      firebase.onAuthStateChanged(firebase.getAuth(), async (user) => {
        if (user) {
          const { claims } = await user.getIdTokenResult();
          const firestore = getFirestore();
          const userRef = await getDoc(doc(firestore, `users/${user.uid}`));
          if (userRef.exists()) {
            const currentUser = (await getDoc(doc(firestore, `users/${user.uid}`))).data() as User;
            const docRef = doc(firestore, `users/${user.uid}`);
            await updateDoc(docRef, { lastLogin: Timestamp.now() });
            setCurrentUser(currentUser);
            const payload = { isAuthenticated: true, user, claims, currentUser };
            dispatch({
              type: Types.Initial,
              payload
            });
          }
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null,
              claims: undefined,
              currentUser: undefined
            }
          });
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const login = (email: string, password: string) =>
    firebase.signInWithEmailAndPassword(firebase.getAuth(), email, password);

  const register = async (email: string, password: string) => {
    const res: firebase.UserCredential | void = await firebase
      .createUserWithEmailAndPassword(firebase.getAuth(), email, password)
      .catch((error: Error) => {
        console.log(error.message);
      });

    return res?.user.uid;
  };

  const logout = async () => {
    await firebase.getAuth().signOut();
  };
  const resetPassword = async (email: string) => {
    await firebase.sendPasswordResetEmail(firebase.getAuth(), email);
  };

  const { user, claims } = { ...state };
  return (
    <AuthContext.Provider
      value={{
        ...state,
        user,
        currentUser,
        claims,
        method: 'firebase',
        login,
        register,
        logout,
        resetPassword,
        updateProfile: () => {}
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
