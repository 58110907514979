import Stack from '@mui/material/Stack';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useEffect, useState } from 'react';
import { Box, Button, FormHelperText, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { doc, updateDoc, Timestamp } from 'firebase/firestore';
import { useFirestore } from 'reactfire';
import moment from 'moment';
import { VehicleCalendar, VehicleTime } from 'pages/auction/seller/components/CommonComponents';
import useAuth from 'hooks/useAuth';
import { batchUpdateAddFront } from 'utils/batchSet';
import { getMilliSeconds, getSeconds, subtractSeconds } from 'utils/formatAuctionEndTime';

interface Props {
  vehicleId?: string;
  vehicleIds?: string[];
  handleClose: any;
  isNoSale?: boolean;
}

export default function LaunchSoonContent({ vehicleId, handleClose, vehicleIds, isNoSale }: Props) {
  const theme = useTheme();
  const firestore = useFirestore();
  const { currentUser, user } = useAuth();

  const [launchTimeVal, setLaunchTimeVal] = useState<Timestamp>();
  const [launchDateVal, setLaunchDateVal] = useState<Timestamp>();
  const [endTimeVal, setEndTimeVal] = useState<Timestamp>();
  const [endDateVal, setEndDateVal] = useState<Timestamp>();

  const [launchTimeValid, setLaunchTimeValid] = useState<boolean>(false);
  const [endTimeValid, setEndTimeValid] = useState<boolean>(false);
  const [launchDateValid, setLaunchDateValid] = useState<boolean>(false);
  const [endDateValid, setEndDateValid] = useState<boolean>(false);

  const [endMinTime, setEndMinTime] = useState<Date>();
  const [launchMinTime, setLaunchMinTime] = useState<Date>();

  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [showValidateError, setShowValidateError] = useState<boolean>(false);
  const [pickerKey, setPickerKey] = useState<number>(0);

  const handleValidDate = (value: any) => {
    return !isNaN(value) && value instanceof Date && !moment(value).isBefore(new Date(), 'day');
  };

  const handleValidStartDate = (value: any) => {
    if (endDateVal) {
      // prettier-ignore
      return !isNaN(value) && value instanceof Date && moment(value).isSameOrBefore(endDateVal.toDate(), 'day');
    } else {
      return !isNaN(value) && value instanceof Date && !moment(value).isBefore(new Date(), 'day');
    }
  };

  const handleValidEndDate = (value: any) => {
    if (launchDateVal) {
      // prettier-ignore
      return !isNaN(value) && value instanceof Date && moment(value).isSameOrAfter(launchDateVal.toDate(), 'day');
    } else {
      return !isNaN(value) && value instanceof Date && !moment(value).isBefore(new Date(), 'day');
    }
  };

  const handleValidEndTime = (value: any) => {
    if (launchTimeVal && launchDateVal && endDateVal) {
      if (sameDay(launchDateVal.toDate(), endDateVal.toDate())) {
        const endMinTime = new Date(launchTimeVal.toDate().getTime() + 20 * 60 * 1000);
        return !isNaN(value) && value instanceof Date && value >= endMinTime;
      } else {
        return !isNaN(value) && value instanceof Date && !moment(value).isBefore(new Date(), 'day');
      }
    }
  };

  const sameDay = (date: Date, nextDate: Date) => {
    /* prettier-ignore */
    return date.getDate() === nextDate.getDate() &&
      date.getMonth() === nextDate.getMonth() &&
      date.getFullYear() === nextDate.getFullYear();
  };

  useEffect(() => {
    if (launchTimeVal || endTimeVal) {
      setLaunchTimeVal(undefined);
      setEndTimeVal(undefined);
      setLaunchTimeValid(false);
      setEndTimeValid(false);
      setShowValidateError(false);
    }
    if (launchDateVal && endDateVal && sameDay(launchDateVal.toDate(), new Date())) {
      const launchMinTime = new Date();
      launchMinTime.setTime(launchMinTime.getTime() + 5 * 60 * 1000);
      setLaunchMinTime(launchMinTime);
    }
  }, [endDateVal, launchDateVal]);

  useEffect(() => {
    if (endTimeVal) {
      setEndTimeVal(undefined);
      setEndTimeValid(false);
      setShowValidateError(false);
    }
    if (
      launchDateVal &&
      endDateVal &&
      launchTimeVal &&
      sameDay(launchDateVal.toDate(), endDateVal.toDate()) &&
      sameDay(launchDateVal.toDate(), new Date())
    ) {
      const endMinTime = new Date(launchTimeVal.toDate().getTime() + 20 * 60 * 1000);
      setEndMinTime(endMinTime);
    } else if (
      launchDateVal &&
      endDateVal &&
      launchTimeVal &&
      sameDay(launchDateVal.toDate(), endDateVal.toDate()) &&
      !sameDay(launchDateVal.toDate(), new Date())
    ) {
      const endMinTime = new Date(launchTimeVal.toDate().getTime() + 20 * 60 * 1000);
      setEndMinTime(endMinTime);
    } else if (
      launchDateVal &&
      endDateVal &&
      !sameDay(launchDateVal.toDate(), endDateVal.toDate())
    ) {
      setEndMinTime(undefined);
    }
    if (!endTimeVal && !endTimeValid) {
      setPickerKey(pickerKey + 1);
      setShowValidateError(false);
    }
  }, [launchTimeVal]);

  const validateInputs = () => {
    if (launchDateVal && launchTimeVal && endDateVal && endTimeVal) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };

  useEffect(() => {
    validateInputs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [launchDateVal, launchTimeVal, endDateVal, endTimeVal]);

  const moveToLaunching = async () => {
    if (vehicleId) {
      const docRef = doc(firestore, `vehicles/${vehicleId}`);
      if (isNoSale) {
        await updateDoc(docRef, {
          billOfSalelink: null,
          buyerVehicleHistory: [],
          sellerMarkAsSold: false,
          bidExtension: false,
          bidExtensionTime: null,
          'auction.auctionStatus': 'launching soon',
          'auction.launchDate': launchDateVal,
          'auction.launchTime': subtractSeconds(
            launchTimeVal?.toDate() as Date,
            getSeconds(launchTimeVal?.toDate() as Date),
            getMilliSeconds(launchTimeVal?.toDate() as Date)
          ),
          'auction.endDate': endDateVal,
          'auction.endTime': subtractSeconds(
            endTimeVal?.toDate() as Date,
            getSeconds(endTimeVal?.toDate() as Date),
            getMilliSeconds(endTimeVal?.toDate() as Date)
          ),
          'auction.timesRun': 0,
          'auction.isAutobid': [],
          'auction.isWatchlist': [],
          'auction.isFavourite': [],
          'auction.isExpanded': [],
          'auction.autobids': [],
          'auction.postAuctionStatus': null,
          'auction.negotiationStatusSeller': 'action required',
          'auction.negotiationStatusBuyer': 'awaiting response',
          'auction.buyerName': null,
          'auction.currentPrice': null,
          'auction.negotiationPrice': null,
          'auction.bidLeaderId': null,
          'auction.negotiationHistory': [],
          'auction.previousBids': [],
          'auction.previousBidders': [],
          'auction.runTime': null,
          buyerDealership: null,
          sellerDealership: null,
          buyerUser: null,
          'auction.dealerRep': {
            name: currentUser.firstName + ' ' + currentUser.lastName,
            id: user?.uid,
            email: currentUser.email,
            phone: currentUser.phone,
            signatureUrl: currentUser.signatureUrl,
            registration: currentUser.registration
          },
          'auction.sellerName': currentUser.firstName + ' ' + currentUser.lastName
          // 'auction.vehicleLaunchUser': {
          //   name: currentUser.firstName + ' ' + currentUser.lastName,
          //   email: currentUser.email,
          //   phone: currentUser.phone
          // }
        });
      } else {
        await updateDoc(docRef, {
          'auction.auctionStatus': 'launching soon',
          'auction.launchDate': launchDateVal,
          'auction.launchTime': subtractSeconds(
            launchTimeVal?.toDate() as Date,
            getSeconds(launchTimeVal?.toDate() as Date),
            getMilliSeconds(launchTimeVal?.toDate() as Date)
          ),
          'auction.endDate': endDateVal,
          'auction.endTime': subtractSeconds(
            endTimeVal?.toDate() as Date,
            getSeconds(endTimeVal?.toDate() as Date),
            getMilliSeconds(endTimeVal?.toDate() as Date)
          ),
          'auction.dealerRep': {
            name: currentUser.firstName + ' ' + currentUser.lastName,
            id: user?.uid,
            email: currentUser.email,
            phone: currentUser.phone,
            signatureUrl: currentUser.signatureUrl,
            registration: currentUser.registration
          },
          'auction.sellerName': currentUser.firstName + ' ' + currentUser.lastName
          // 'auction.vehicleLaunchUser': {
          //   name: currentUser.firstName + ' ' + currentUser.lastName,
          //   email: currentUser.email,
          //   phone: currentUser.phone
          // }
        });
      }
    }
    if (vehicleIds) {
      if (isNoSale) {
        const vehiclesToUpdate = vehicleIds.map((id) => ({
          billOfSalelink: null,
          buyerVehicleHistory: [],
          sellerMarkAsSold: false,
          bidExtension: false,
          bidExtensionTime: null,
          buyerDealership: null,
          sellerDealership: null,
          buyerUser: null,
          auction: {
            runTime: null,
            auctionStatus: 'launching soon',
            launchDate: launchDateVal,
            launchTime: subtractSeconds(
              launchTimeVal?.toDate() as Date,
              getSeconds(launchTimeVal?.toDate() as Date),
              getMilliSeconds(launchTimeVal?.toDate() as Date)
            ),
            endDate: endDateVal,
            endTime: subtractSeconds(
              endTimeVal?.toDate() as Date,
              getSeconds(endTimeVal?.toDate() as Date),
              getMilliSeconds(endTimeVal?.toDate() as Date)
            ),
            timesRun: 0,
            isAutobid: [],
            isWatchlist: [],
            isFavourite: [],
            isExpanded: [],
            autobids: [],
            postAuctionStatus: null,
            negotiationStatusSeller: 'action required',
            negotiationStatusBuyer: 'awaiting response',
            buyerName: null,
            currentPrice: null,
            negotiationPrice: null,
            bidLeaderId: null,
            negotiationHistory: [],
            previousBids: [],
            previousBidders: [],
            dealerRep: {
              name: currentUser.firstName + ' ' + currentUser.lastName,
              id: user?.uid,
              email: currentUser.email,
              phone: currentUser.phone,
              signatureUrl: currentUser.signatureUrl,
              registration: currentUser.registration
            }
            // vehicleLaunchUser: {
            //   name: currentUser.firstName + ' ' + currentUser.lastName,
            //   email: currentUser.email,
            //   phone: currentUser.phone
            // }
          }
        }));
        await batchUpdateAddFront('vehicles', vehicleIds, vehiclesToUpdate);
      } else {
        const vehiclesToUpdate = vehicleIds.map((id) => ({
          auction: {
            auctionStatus: 'launching soon',
            launchDate: launchDateVal,
            launchTime: subtractSeconds(
              launchTimeVal?.toDate() as Date,
              getSeconds(launchTimeVal?.toDate() as Date),
              getMilliSeconds(launchTimeVal?.toDate() as Date)
            ),
            endDate: endDateVal,
            endTime: subtractSeconds(
              endTimeVal?.toDate() as Date,
              getSeconds(endTimeVal?.toDate() as Date),
              getMilliSeconds(endTimeVal?.toDate() as Date)
            ),
            dealerRep: {
              name: currentUser.firstName + ' ' + currentUser.lastName,
              id: user?.uid,
              email: currentUser.email,
              phone: currentUser.phone,
              signatureUrl: currentUser.signatureUrl,
              registration: currentUser.registration
            }
            // vehicleLaunchUser: {
            //   name: currentUser.firstName + ' ' + currentUser.lastName,
            //   email: currentUser.email,
            //   phone: currentUser.phone
            // }
          }
        }));
        await batchUpdateAddFront('vehicles', vehicleIds, vehiclesToUpdate);
      }
    }
    handleClose();
  };

  const useStyles = makeStyles({
    cancelButton: {
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.common.black,
      '&:hover': {
        backgroundColor: theme.palette.grey[300]
      }
    }
  });

  const boxStyle = {
    textAlign: 'start',
    width: '100%',
    height: 60,
    backgroundColor: theme.palette.mode !== 'dark' ? theme.palette.common.white : 'unset',
    border: `1px rgba(139, 148, 156, 0.32) solid`,
    borderRadius: '10px',
    fontWeight: 300,
    padding: 0,
    mb: 1,
    overflow: 'hidden',
    '&:hover': {
      border: `1px rgb(192, 192, 192) solid`
    }
  };

  const styles = useStyles();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack spacing={3} sx={{ padding: '30px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
            Move vehicle to Launching Soon
          </Typography>
          {/* prettier-ignore */}
          <Typography variant="body1" sx={{ marginTop: '10px' }}>
            Vehicle will remain in a Launching Soon status until the Launch Date you've selected below.
          </Typography>
        </Box>
        {/* prettier-ignore */}
        <Typography sx={{ fontWeight: 'bold', marginTop: '10px' }}>
          1. Choose the launch date and end date for your active auction. <span style={{ color: '#47A0F4' }}>(Set Dates First)</span>
        </Typography>
        <Box>
          <VehicleCalendar
            auctionMinDate={new Date()}
            auctionMaxDate={endDateVal ? endDateVal.toDate() : undefined}
            dropBoxValue={launchDateVal?.toDate() || ''}
            caption={'Auction Start Date'}
            updateDropValue={(v) => {
              if (!v) {
                setLaunchDateVal(undefined);
                setLaunchDateValid(false);
              } else {
                const isValid = handleValidStartDate(v);
                if (isValid) {
                  const launchDate = Timestamp.fromDate(v);
                  setLaunchDateVal(launchDate);
                  setLaunchDateValid(true);
                } else {
                  setLaunchDateVal(undefined);
                  setLaunchDateValid(false);
                }
              }
            }}
          />
        </Box>
        {!launchDateValid && showValidateError && (
          <FormHelperText sx={{ ml: 1, mb: 2 }} error={Boolean(!launchDateValid)}>
            Valid date format is mm/dd/yyyy and cannot be in the past or after the end date.
          </FormHelperText>
        )}
        <Box>
          <VehicleCalendar
            dropBoxValue={endDateVal ? endDateVal?.toDate() : undefined}
            auctionMinDate={launchDateVal ? launchDateVal.toDate() : undefined}
            caption={'Auction End Date'}
            updateDropValue={(v) => {
              if (!v) {
                setEndDateVal(undefined);
                setEndDateValid(false);
              } else {
                const isValid = handleValidEndDate(v);
                if (isValid) {
                  const endDate = Timestamp.fromDate(v);
                  setEndDateVal(endDate);
                  setEndDateValid(true);
                } else {
                  setEndDateVal(undefined);
                  setEndDateValid(false);
                }
              }
            }}
          />
        </Box>
        {!endDateValid && showValidateError && (
          <FormHelperText sx={{ ml: 1, mb: 2 }} error={Boolean(!endDateValid)}>
            Valid date format is mm/dd/yyyy and cannot be in the past or before the start date.
          </FormHelperText>
        )}
        {/* prettier-ignore */}
        <Typography sx={{ fontWeight: 'bold' }}>
          2. Set the launch time and end time for your active auction <span style={{ color: '#47A0F4' }}>(Set Start Time First)</span>.
        </Typography>
        <Box>
          {/* prettier-ignore */}
          <VehicleTime
            auctionMinTime={launchDateVal && launchDateVal > Timestamp.now() ? undefined :launchMinTime as Date }
            dropBoxValue={launchTimeVal?.toDate() || ''}
            isDisabled={!launchDateVal || !endDateVal}
            caption={'Auction Start Time'}
            updateDropValue={(v) => {
              if (!v) {
                setLaunchTimeVal(undefined);
                setLaunchTimeValid(false);
              } else {
                const isValid = endDateVal && endDateVal > Timestamp.now() ? true : handleValidDate(v);
                if (isValid) {
                  setLaunchTimeValid(true);
                  const launchTime = Timestamp.fromDate(v);
                  setLaunchTimeVal(launchTime);
                } else {
                  setLaunchTimeVal(undefined);
                  setLaunchTimeValid(false);
                }
              }
            }}
          />
        </Box>
        {!launchTimeValid && showValidateError && (
          <FormHelperText sx={{ ml: 1, mb: 2 }} error={Boolean(!launchTimeValid)}>
            Enter a valid time with format of hh:mm (a/p)m
          </FormHelperText>
        )}
        <Box key={pickerKey}>
          {/* prettier-ignore */}
          <VehicleTime
            auctionMinTime={endMinTime ? endMinTime as Date : undefined}
            dropBoxValue={endTimeVal?.toDate() || ''}
            isDisabled={!launchDateVal || !endDateVal || !launchTimeVal}
            caption={'Auction End Time'}
            updateDropValue={(v) => {
              if (!v) {
                setEndTimeValid(false);
                setEndTimeVal(undefined);
              } else {
                const isValid = handleValidEndTime(v);
                if (isValid) {
                  setEndTimeValid(true);
                  const endTime = Timestamp.fromDate(v);
                  setEndTimeVal(endTime);
                } else {
                  setEndTimeValid(false);
                  setEndTimeVal(undefined);
                }
              }
            }}
          />
        </Box>
        {!endTimeValid && showValidateError && (
          <FormHelperText sx={{ ml: 1, mb: 2 }} error={Boolean(!endTimeValid)}>
            Enter a valid time with format of hh:mm (a/p)m. End time must be at least 20 minutes
            after launch time if launch and end day are the same.
          </FormHelperText>
        )}
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          <Button
            // size="large"
            variant="contained"
            className={styles.cancelButton}
            onClick={() => handleClose()}
          >
            Cancel
          </Button>
          <LoadingButton
            // size="large"
            type="submit"
            variant="contained"
            sx={{ marginLeft: '10px' }}
            onClick={() => {
              if (isDisabled) {
                setShowValidateError(true);
              } else {
                moveToLaunching();
              }
            }}
          >
            Move: Launching Soon
          </LoadingButton>
        </Box>
      </Stack>
    </LocalizationProvider>
  );
}
