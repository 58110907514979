import { Document, Page, Text, View, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';
import { Timestamp, doc, getDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useFirestore } from 'reactfire';
import { Dealership } from 'types/dealership';
import { VehicleInfo } from 'types/vehicleInfo';
import moment from 'moment';
import Logo from '../../assets/LogoText-FullColour.png';

interface Props {
  vehicle: VehicleInfo;
}

const styles = StyleSheet.create({
  image: {
    width: 200
  },
  signature: {
    width: 50,
    height: 'auto',
    marginTop: 10,
    marginLeft: '30px'
  },
  viewer: {
    width: '800px',
    height: 'auto',
    minHeight: '100vh'
  },
  page: {
    backgroundColor: 'white',
    color: 'black',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: 20
  },
  title: {
    marginTop: 15,
    marginBottom: 10,
    fontSize: '16px',
    fontWeight: 'ultrabold',
    color: 'black'
  },
  subtitle: {
    marginTop: 15,
    marginLeft: '10px',
    fontSize: '14px',
    fontWeight: 'ultrabold',
    color: 'black'
  },
  subTitle: {
    fontSize: '14px',
    fontWeight: 'ultrabold',
    color: 'black'
  },
  container: {
    flexDirection: 'column',
    width: '100%',
    border: '1px solid black'
  },
  row: {
    flexDirection: 'row',
    width: '100%'
  },
  topBorderRow: {
    flexDirection: 'row',
    width: '100%',
    borderTop: '1px solid black'
  },
  columnLeft: {
    flexDirection: 'column',
    width: '50%',
    borderRight: '1px solid black',
    paddingBottom: '5px'
  },
  columnRight: {
    flexDirection: 'column',
    width: '50%',
    paddingBottom: '5px'
  },
  header: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    borderBottom: '1px solid black',
    padding: '2px'
  },
  vhrHeader: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    borderBottom: '1px solid black',
    borderTop: '1px solid black',
    padding: '2px'
  },
  headerText: {
    fontSize: '12px',
    fontWeight: 'ultrabold'
  },
  body: {
    fontSize: '10px',
    fontWeight: 'ultralight',
    marginLeft: '-10px !important',
    marginRight: '10px',
    marginTop: '5px'
  },
  bodyText: {
    fontSize: '10px',
    fontWeight: 'ultralight',
    marginLeft: '10px',
    marginRight: '10px',
    marginTop: '5px'
  },
  sellerBuyerText: {
    fontSize: '10px',
    fontWeight: 'ultralight',
    marginLeft: '10px',
    marginRight: '10px',
    marginTop: '5px',
    textTransform: 'capitalize'
  },
  postalCode: {
    fontSize: '10px',
    fontWeight: 'ultralight',
    marginLeft: '10px',
    marginRight: '10px',
    marginTop: '5px',
    textTransform: 'uppercase'
  },
  repText: {
    marginTop: '15px',
    fontSize: '12px',
    fontWeight: 'ultrabold',
    marginLeft: '10px'
  },
  signatureBox: {
    width: '90%',
    height: '5px',
    borderBottom: '3px solid black',
    marginLeft: '10px'
  },
  underSignature: {
    marginLeft: '10px',
    fontSize: '10px',
    fontWeight: 'ultrabold',
    marginTop: '5px'
  },
  innerTitle: {
    borderTop: '1px solid black',
    borderBottom: '1px solid black',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '5px',
    marginBottom: '5px'
  },
  listItem: {
    width: '95%',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  boldBodyText: {
    fontSize: '10px',
    fontWeight: 900,
    marginTop: '25px',
    marginLeft: '10px',
    marginRight: '10px'
  },
  spaceBox: {
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  bottomBox: {
    position: 'absolute',
    bottom: '5px'
  },
  paragraphRow: {
    marginBottom: '10px'
  },
  logoHeader: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  addressContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end'
  }
});

export default function BillofSale({ vehicle }: Props) {
  const firestore = useFirestore();
  const [buyerDealership, setBuyerDealership] = useState<Dealership>();
  const [sellerDealership, setSellerDealership] = useState<Dealership>();
  const [buyingUser, setBuyingUser] = useState<any>();
  const [announcements, setAnnouncements] = useState<any[]>();
  const [disclosures, setDisclosures] = useState<any[]>();
  const [odometerData, setOdometerData] = useState<any[]>();
  const [repairs, setRepairs] = useState<any[]>();
  const [vhrDamages, setVhrDamages] = useState<any[]>();
  const [sellerSignature, setSellerSignature] = useState<any>();
  const [buyerSignature, setBuyerSignature] = useState<any>();
  const [dealerRepRegistration, setDealerRepRegistration] = useState<any>();
  const [buyerReg, setBuyerReg] = useState<any>();
  const [buyerFee, setBuyerFee] = useState<number>();
  const [captureFee, setCaptureFee] = useState<number>(49);
  const [subTotalPrice, setSubTotalPrice] = useState<number>(0);
  const [totalPrice, setTotalPrice] = useState<number>();

  const currencyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  const announcementKeys = [
    'Mto Reg',
    'Drivable',
    'Prev Repairs',
    'Badges Changed',
    'InOp ABS',
    'InOp Air Bags',
    'InOp Pollution',
    'Manuals',
    'Fifth Wheel',
    'Salvage',
    'Struct Damage',
    'Struct Repairs',
    'Repair Notes',
    'Sold As Is',
    'Out Of Province',
    'US Vehicle',
    'Warranty Cancelled',
    'Was Vehicle',
    'Was Declared',
    'Notes',
    'Odometer Status',
    'Odometer Reading',
    'Odometer Reading Date',
    'Odometer Radio Choice'
  ];

  const disclosureKeys = [
    'Drivable',
    'Struct Damage',
    'Prev Repairs',
    'Badges Changed',
    'InOp ABS',
    'InOp Air Bags',
    'InOp Pollution',
    'Number of Keys',
    'Manuals',
    'Fifth Wheel',
    'Salvage',
    'Sold As Is',
    'Electric Needs Repair'
  ];

  const getDamages = async () => {
    const tempDamages: any[] = [];
    const damages = vehicle.disclosures.vhrDamages ? vehicle.disclosures.vhrDamages : null;
    if (damages) {
      damages.forEach((damage: any) => {
        tempDamages.push({
          label: 'VHR Damage',
          cost: damage.cost,
          date: moment(damage.date.toDate()).format('MM-DD-YYYY'),
          desc: damage.description
        });
      });
      setVhrDamages(tempDamages);
    }
  };

  const getRepairs = async () => {
    const tempRepairs: any[] = [];
    // prettier-ignore
    const mechRepairs = vehicle.disclosures.mechNeedRepairs ? vehicle.disclosures.mechNeedRepairs : null;
    if (mechRepairs) {
      mechRepairs.forEach((repair: string) => {
        tempRepairs.push('Needs Repair' + ': ' + repair);
      });
    }
    // prettier-ignore
    const elecRepairs = vehicle.disclosures.electricNeedRepairs ? vehicle.disclosures.electricNeedRepairs : null;
    if (elecRepairs) {
      elecRepairs.forEach((repair: string) => {
        tempRepairs.push('Needs Repair' + ': ' + repair);
      });
    }
    setRepairs(tempRepairs);
  };

  const getAnnouncements = async () => {
    const tempAnnouncements: any[] = [];
    const tempDisclosures: any[] = [];
    const tempOdometerData: any[] = [];
    // eslint-disable-next-line array-callback-return
    Object.entries(vehicle.disclosures).map(([key, value]) => {
      announcementKeys.forEach((announcementKey: string) => {
        if (
          announcementKey.replace(/\s/g, '').toLowerCase() === key.toString().toLowerCase() &&
          (announcementKey === 'Drivable' || announcementKey === 'Manuals')
        ) {
          if (value?.toString().toLowerCase() === 'false') {
            tempAnnouncements.push(announcementKey + ': ' + 'No');
          }
        }
        if (
          announcementKey.replace(/\s/g, '').toLowerCase() === key.toString().toLowerCase() &&
          (announcementKey === 'Repair Notes' || announcementKey === 'Notes') &&
          value &&
          value !== '' &&
          value !== ' '
        ) {
          tempAnnouncements.push(announcementKey + ': ' + value?.toString());
        }
        if (
          announcementKey.replace(/\s/g, '').toLowerCase() === key.toString().toLowerCase() &&
          (announcementKey === 'Out Of Province' || announcementKey === 'US Vehicle')
        ) {
          let displayValue;
          if (value?.toString().toLowerCase() === 'true') {
            if (announcementKey === 'Out Of Province') {
              displayValue = vehicle.disclosures.province;
            } else {
              displayValue = vehicle.disclosures.state;
            }
            tempAnnouncements.push(announcementKey + ': ' + displayValue?.toString());
          }
        }
        if (
          announcementKey.replace(/\s/g, '').toLowerCase() === key.toString().toLowerCase() &&
          value !== false &&
          value?.toString().toLowerCase() !== 'none'
        ) {
          if (
            announcementKey !== 'Odometer Reading' &&
            announcementKey !== 'Odometer Reading Date' &&
            announcementKey !== 'Odometer Radio Choice' &&
            announcementKey !== 'US Vehicle' &&
            announcementKey !== 'Out Of Province' &&
            announcementKey !== 'Drivable' &&
            announcementKey !== 'Manuals' &&
            announcementKey !== 'Repair Notes' &&
            announcementKey !== 'Notes'
          ) {
            let displayValue;
            if (value?.toString().toLowerCase() === 'false') {
              displayValue = 'No';
            } else if (value?.toString().toLowerCase() === 'true') {
              displayValue = 'Yes';
            } else {
              displayValue = value?.toString();
            }
            let announceKey;
            if (announcementKey === 'Struct Repairs') {
              announceKey = 'Structural Repairs/Replacements/Alterations';
            } else if (announcementKey === 'Prev Repairs') {
              announceKey = 'Damages Exceeding $3,000';
            } else if (announcementKey === 'Struct Damage') {
              announceKey = 'Structual Damage';
            } else {
              announceKey = announcementKey;
            }
            tempAnnouncements.push(announceKey + ': ' + displayValue?.toString());
          }
          if (
            announcementKey === 'Odometer Reading Date' &&
            vehicle.disclosures.odometerReadingDate &&
            vehicle.disclosures.odometerReadingDate !== undefined
          ) {
            let val = value as Timestamp;
            tempOdometerData.push(
              announcementKey + ': ' + moment(val?.toDate()).format('MM-DD-YYYY')
            );
          } else if (announcementKey === 'Odometer Reading') {
            if (vehicle.disclosures.odometerReading !== '') {
              tempOdometerData.push(announcementKey + ': ' + value?.toString() + ' KM');
            }
          } else if (announcementKey === 'Odometer Radio Choice') {
            if (vehicle.disclosures.odometerRadioChoice) {
              if (vehicle.disclosures.odometerRadioChoice === 'Option 1') {
                tempOdometerData.push(
                  'Odometer Note' +
                    ': ' +
                    `The dealer can not determine the total distance that the vehicle has been driven but can determine the distance that the vehicle has been driven as of some past date. The total distance that the vehicle has been driven is believed to be higher than the previously recorded distance.`
                );
              } else {
                tempOdometerData.push(
                  'Odometer Note' +
                    ': ' +
                    `True Kilometers/Mileage Unknown: The dealer can determine neither the total distance that the vehicle has been driven, nor the distance that the vehicle has been driven as of some past date. The total distance that the vehicle has been driven is unknown and may be substantially higher that the reading shown on the odometer.`
                );
              }
            }
          }
        }
      });
      disclosureKeys.forEach((disclosureKey: string) => {
        if (disclosureKey.replace(/\s/g, '').toLowerCase() === key.toString().toLowerCase()) {
          let displayValue;
          if (value?.toString().toLowerCase() === 'false') {
            displayValue = 'No';
          } else if (value?.toString().toLowerCase() === 'true') {
            displayValue = 'Yes';
          } else {
            displayValue = value?.toString();
          }
          let discloseKey;
          if (disclosureKey === 'Prev Repairs') {
            discloseKey = 'Damages Exceeding $3,000';
          } else if (disclosureKey === 'Struct Damage') {
            discloseKey = 'Structural Damage';
          } else if (disclosureKey === 'Struct Repairs') {
            discloseKey = 'Structural Repairs/Replacements/Alterations';
          } else {
            discloseKey = disclosureKey;
          }
          tempDisclosures.push({ label: discloseKey, value: displayValue });
        }
      });
    });
    setOdometerData(tempOdometerData);
    setDisclosures(tempDisclosures);
    setAnnouncements(tempAnnouncements);
  };

  const calculateTotal = (dealership: Dealership) => {
    let subPrice = 0;
    let totalPrice;
    let buyerFee;
    const currentPrice = parseInt(vehicle.auction.currentPrice.toString(), 10);
    // add scaling buyer fee
    if (currentPrice >= 0 && currentPrice < 10000) {
      buyerFee = 99;
    } else if (currentPrice >= 10000 && currentPrice < 20000) {
      buyerFee = 139;
    } else if (currentPrice >= 20000 && currentPrice < 30000) {
      buyerFee = 179;
    } else if (currentPrice >= 30000 && currentPrice < 40000) {
      buyerFee = 209;
    } else if (currentPrice >= 40000 && currentPrice < 50000) {
      buyerFee = 239;
    } else if (currentPrice >= 50000 && currentPrice < 60000) {
      buyerFee = 269;
    } else if (currentPrice >= 60000 && currentPrice < 70000) {
      buyerFee = 299;
    } else if (currentPrice >= 70000 && currentPrice < 80000) {
      buyerFee = 329;
    } else if (currentPrice >= 80000 && currentPrice < 90000) {
      buyerFee = 359;
    } else if (currentPrice >= 90000) {
      buyerFee = 389;
    }

    setBuyerFee(buyerFee);

    if (dealership.captureFee && buyerFee) {
      subPrice = currentPrice + captureFee + buyerFee;
    } else if (buyerFee) {
      subPrice = currentPrice + buyerFee;
    }

    setSubTotalPrice(subPrice as number);

    // calc taxes based on province
    if (
      dealership.province === 'Newfoundland Labrador' ||
      dealership.province === 'New Brunswick' ||
      dealership.province === 'Nova Scotia' ||
      dealership.province === 'Prince Edward Island'
    ) {
      totalPrice = subPrice + subPrice * 0.15;
      setTotalPrice(totalPrice);
    } else if (dealership.province === 'Ontario') {
      totalPrice = subPrice * 0.13 + subPrice;
      setTotalPrice(totalPrice);
    } else if (
      dealership.province === 'Alberta' ||
      dealership.province === 'Northwest Territories' ||
      dealership.province === 'Yukon Territory' ||
      dealership.province === 'Nunavut'
    ) {
      totalPrice = subPrice + subPrice * 0.05;
      setTotalPrice(totalPrice);
    } else if (
      dealership.province === 'British Columbia' ||
      dealership.province === 'Manitoba' ||
      dealership.province === 'Saskatchewan'
    ) {
      const gstPrice = subPrice * 0.05;
      const pstPrice = subPrice * 0.07;
      totalPrice = gstPrice + pstPrice + subPrice;
    } else if (dealership.province === 'Quebec') {
      const gstPrice = subPrice * 0.05;
      const pstPrice = subPrice * 0.09975;
      totalPrice = gstPrice + pstPrice + subPrice;
    }

    setTotalPrice(totalPrice as number);
  };

  const getSellerDealership = async () => {
    if (vehicle.sellerDealership) {
      setSellerDealership(vehicle.sellerDealership);
      calculateTotal(vehicle.sellerDealership);
    } else {
      const docRef = doc(firestore, `dealerships/${vehicle.dealershipId}`);
      const docSnap = await getDoc(docRef);
      const sellerDealership = { id: docSnap.id, ...docSnap.data() } as Dealership;
      setSellerDealership(sellerDealership);
      calculateTotal(sellerDealership);
    }
    setSellerSignature(vehicle.auction.dealerRep.signatureUrl);
  };

  const getBuyerDealership = async () => {
    if (vehicle.buyerDealership && vehicle.buyerUser) {
      setBuyingUser(vehicle.buyerUser);
      setBuyerDealership(vehicle.buyerDealership);
      setBuyerSignature(vehicle.buyerUser.signatureUrl);
    } else {
      const userDocRef = doc(firestore, `users/${vehicle.auction.bidLeaderId}`);
      const userDocSnap = await getDoc(userDocRef);
      const buyingUser = { id: userDocSnap.id, ...userDocSnap.data() } as any;
      setBuyingUser(buyingUser);

      setBuyerSignature(buyingUser.signatureUrl);

      const buyingDealerRef = doc(firestore, `dealerships/${buyingUser.currentDealershipId}`);
      const buyingDealerSnap = await getDoc(buyingDealerRef);
      const buyerDealership = { id: buyingDealerSnap.id, ...buyingDealerSnap.data() } as Dealership;
      setBuyerDealership(buyerDealership);
    }
  };

  const setRegistrations = () => {
    if (vehicle.auction.dealerRep) {
      setDealerRepRegistration(
        vehicle.auction.dealerRep.registration ? vehicle.auction.dealerRep.registration : 'N/A'
      );
    }
    if (vehicle.buyerUser) {
      setBuyerReg(vehicle.buyerUser.registration ? vehicle.buyerUser.registration : 'N/A');
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getBuyerDealership();
      getSellerDealership();
      getAnnouncements();
      setRegistrations();
      getRepairs();
      getDamages();
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PDFViewer style={styles.viewer}>
      <Document>
        <Page wrap style={styles.page}>
          <View style={styles.logoHeader}>
            <Image style={styles.image} src={Logo} />
            <View style={styles.addressContainer}>
              <Text style={styles.bodyText}>1-888-778-1711</Text>
              <Text style={styles.bodyText}>192 Dundas St. Suite 207, London, ON. N6A 1G7</Text>
              <Text style={styles.bodyText}>GST/HST#: 897497616 PST/TVQ#: 88947505</Text>
            </View>
          </View>
          <Text style={styles.title}>WHOLESALE BILL OF SALE</Text>
          <View style={styles.container}>
            {/* title row */}
            <View style={styles.row}>
              <View style={styles.columnLeft}>
                <View style={styles.header}>
                  <Text style={styles.headerText}>SELLER INFORMATION</Text>
                </View>
              </View>
              <View style={styles.columnRight}>
                <View style={styles.header}>
                  <Text style={styles.headerText}>BUYER INFORMATION</Text>
                </View>
              </View>
            </View>
            {/* body row */}
            <View style={styles.row}>
              <View style={styles.columnLeft}>
                {/* prettier-ignore */}
                <Text style={styles.sellerBuyerText}>Dealer: {sellerDealership?.dealershipName}</Text>
                {/* prettier-ignore */}
                <Text style={styles.sellerBuyerText}>Legal Name: {sellerDealership?.legalName}</Text>
                {/* prettier-ignore */}
                <Text style={styles.sellerBuyerText}>Primary Contact: {sellerDealership?.primaryContactEmail}</Text>
                {/* prettier-ignore */}
                <Text style={styles.sellerBuyerText}>Street: {sellerDealership?.streetAddress}</Text>
                <Text style={styles.sellerBuyerText}>City: {sellerDealership?.city}</Text>
                <Text style={styles.sellerBuyerText}>Province: {sellerDealership?.province}</Text>
                <Text style={styles.postalCode}>Postal Code: {sellerDealership?.postalCode}</Text>
                <Text style={styles.sellerBuyerText}>Country: {sellerDealership?.country}</Text>
                <Text style={styles.sellerBuyerText}>Phone: {sellerDealership?.phone}</Text>
                <Text style={styles.sellerBuyerText}>OMVIC: {sellerDealership?.omvicNumber}</Text>
                {/* prettier-ignore */}
                <Text style={styles.sellerBuyerText}>HST: {sellerDealership?.hstNumber || 'N/A'}</Text>
                <Text style={styles.sellerBuyerText}>TVQ: {sellerDealership?.tvqNumber}</Text>
                <Text style={styles.sellerBuyerText}>
                  RIN: {sellerDealership?.rinNumber || 'N/A'}
                </Text>
                <Text style={styles.repText}>EFT INFORMATION</Text>
                <Text style={styles.sellerBuyerText}>
                  Legal Company Name: {sellerDealership?.legalCompanyName || 'N/A'}
                </Text>
                <Text style={styles.sellerBuyerText}>
                  Bank Street Address: {sellerDealership?.eftBankStreet || 'N/A'}
                </Text>
                <Text style={styles.sellerBuyerText}>
                  Bank City: {sellerDealership?.eftBankCity || 'N/A'}
                </Text>
                <Text style={styles.sellerBuyerText}>
                  Bank Province: {sellerDealership?.eftBankProvince || 'N/A'}
                </Text>
                <Text style={styles.sellerBuyerText}>
                  Bank Country: {sellerDealership?.eftBankCountry || 'N/A'}
                </Text>
                <Text style={styles.sellerBuyerText}>
                  Bank Postal Code: {sellerDealership?.eftBankPostalCode || 'N/A'}
                </Text>
                <Text style={styles.sellerBuyerText}>
                  Bank Suite #: {sellerDealership?.eftBankSuite || 'N/A'}
                </Text>
                <Text style={styles.sellerBuyerText}>
                  Institution Number: {sellerDealership?.institutionNumber || 'N/A'}
                </Text>
                <Text style={styles.sellerBuyerText}>
                  Transit Number: {sellerDealership?.transitNumber || 'N/A'}
                </Text>
                <Text style={styles.sellerBuyerText}>
                  Account Number: {sellerDealership?.accountNumber || 'N/A'}
                </Text>
                <Text style={styles.sellerBuyerText}>
                  Notes: {sellerDealership?.eftNotes || 'No Notes'}
                </Text>
                <Text style={styles.repText}>SELLER REPRESENTATIVE</Text>
                <Text style={styles.sellerBuyerText}>Email: {vehicle.auction.dealerRep.email}</Text>
                <Text style={styles.sellerBuyerText}>Phone: {vehicle.auction.dealerRep.phone}</Text>
                {sellerSignature && <Image style={styles.signature} src={sellerSignature} />}
                <View style={{ ...styles.signatureBox, height: sellerSignature ? 5 : 50 }}></View>
                {/* prettier-ignore */}
                <Text style={styles.underSignature}>{vehicle.auction.dealerRep.name} | Salesperson Reg # {dealerRepRegistration}</Text>
              </View>
              <View style={styles.columnRight}>
                {/* prettier-ignore */}
                <Text style={styles.sellerBuyerText}>Dealer: {buyerDealership?.dealershipName}</Text>
                <Text style={styles.sellerBuyerText}>Legal Name: {buyerDealership?.legalName}</Text>
                {/* prettier-ignore */}
                <Text style={styles.sellerBuyerText}>Primary Contact: {buyerDealership?.primaryContactEmail}</Text>
                <Text style={styles.sellerBuyerText}>Street: {buyerDealership?.streetAddress}</Text>
                <Text style={styles.sellerBuyerText}>City: {buyerDealership?.city}</Text>
                <Text style={styles.sellerBuyerText}>Province: {buyerDealership?.province}</Text>
                <Text style={styles.postalCode}>Postal Code: {buyerDealership?.postalCode}</Text>
                <Text style={styles.sellerBuyerText}>Country: {buyerDealership?.country}</Text>
                <Text style={styles.sellerBuyerText}>Phone: {buyerDealership?.phone}</Text>
                <Text style={styles.sellerBuyerText}>OMVIC: {buyerDealership?.omvicNumber}</Text>
                {/* prettier-ignore */}
                <Text style={styles.sellerBuyerText}>HST: {buyerDealership?.hstNumber || 'N/A'}</Text>
                {/* prettier-ignore */}
                <Text style={styles.sellerBuyerText}>TVQ: {buyerDealership?.tvqNumber || 'N/A'}</Text>
                <Text style={styles.sellerBuyerText}>
                  RIN: {buyerDealership?.rinNumber || 'N/A'}
                </Text>
                <Text style={styles.repText}>EFT INFORMATION</Text>
                <Text style={styles.sellerBuyerText}>
                  Legal Company Name: {buyerDealership?.legalCompanyName || 'N/A'}
                </Text>
                <Text style={styles.sellerBuyerText}>
                  Bank Street Address: {buyerDealership?.eftBankStreet || 'N/A'}
                </Text>
                <Text style={styles.sellerBuyerText}>
                  Bank City: {buyerDealership?.eftBankCity || 'N/A'}
                </Text>
                <Text style={styles.sellerBuyerText}>
                  Bank Province: {buyerDealership?.eftBankProvince || 'N/A'}
                </Text>
                <Text style={styles.sellerBuyerText}>
                  Bank Country: {buyerDealership?.eftBankCountry || 'N/A'}
                </Text>
                <Text style={styles.sellerBuyerText}>
                  Bank Postal Code: {buyerDealership?.eftBankPostalCode || 'N/A'}
                </Text>
                <Text style={styles.sellerBuyerText}>
                  Bank Suite #: {buyerDealership?.eftBankSuite || 'N/A'}
                </Text>
                <Text style={styles.sellerBuyerText}>
                  Institution Number: {buyerDealership?.institutionNumber || 'N/A'}
                </Text>
                <Text style={styles.sellerBuyerText}>
                  Transit Number: {buyerDealership?.transitNumber || 'N/A'}
                </Text>
                <Text style={styles.sellerBuyerText}>
                  Account Number: {buyerDealership?.accountNumber || 'N/A'}
                </Text>
                <Text style={styles.sellerBuyerText}>
                  Notes: {buyerDealership?.eftNotes || 'No Notes'}
                </Text>
                {/* {buyerDealership && buyerDealership.legalCompanyName && (
                  <Text style={styles.sellerBuyerText}>
                    Legal Company Name: {buyerDealership?.legalCompanyName}
                  </Text>
                )}
                {buyerDealership && buyerDealership.institutionNumber && (
                  <Text style={styles.sellerBuyerText}>
                    Institution Number: {buyerDealership?.institutionNumber}
                  </Text>
                )}
                {buyerDealership && buyerDealership.transitNumber && (
                  <Text style={styles.sellerBuyerText}>
                    Transit Number: {buyerDealership?.transitNumber}
                  </Text>
                )}
                {buyerDealership && buyerDealership.accountNumber && (
                  <Text style={styles.sellerBuyerText}>
                    Institution Number: {buyerDealership?.accountNumber}
                  </Text>
                )}
                {buyerDealership && buyerDealership.eftNotes && (
                  <Text style={styles.sellerBuyerText}>Notes: {buyerDealership?.eftNotes}</Text>
                )} */}
                <Text style={styles.repText}>PURCHASER REPRESENTATIVE</Text>
                <Text style={styles.sellerBuyerText}>Email: {buyingUser?.email}</Text>
                <Text style={styles.sellerBuyerText}>Phone: {buyingUser?.phone}</Text>
                {buyerSignature && <Image style={styles.signature} src={buyerSignature} />}
                <View style={{ ...styles.signatureBox, height: buyerSignature ? 5 : 50 }}></View>
                {/* prettier-ignore */}
                {buyingUser && buyerReg && (
                  <Text style={styles.underSignature}>
                    {buyingUser.firstName} {buyingUser.lastName} | Salesperson Reg # {buyerReg}
                  </Text>
                )}
              </View>
            </View>
            {/* vehicle info row */}
            <View style={styles.topBorderRow}>
              <View style={styles.columnLeft}>
                <View style={styles.header}>
                  <Text style={styles.headerText}>VEHICLE INFORMATION</Text>
                </View>
              </View>
              <View style={styles.columnRight}>
                <View style={styles.header}>
                  <Text style={styles.headerText}>VEHICLE OPTIONS</Text>
                </View>
              </View>
            </View>
            {/* body row */}
            <View style={styles.row}>
              <View style={styles.columnLeft}>
                {/* prettier-ignore */}
                <Text style={styles.bodyText}>
                  VIN: {vehicle.details.vin} | {vehicle.details.mileage} {vehicle.details.mileageType}
                </Text>
                {/* prettier-ignore */}
                <Text style={styles.bodyText}>
                  {vehicle.details.year} {vehicle.details.make} {vehicle.details.model} {vehicle.details.trim} {vehicle.details.extColor} {vehicle.details.fuelType}
                </Text>
                {/* Stock # */}
                {/* prettier-ignore */}
                <Text style={styles.bodyText}>
                  Sale information: {moment(vehicle.auction.dateSold.toDate()).format('MM-DD-YYYY')}
                </Text>
                {/* DealerGavel Unit #*/}
              </View>
              <View style={styles.columnRight}>
                {/* prettier-ignore */}
                <Text style={styles.bodyText}>
                  Transmission: {vehicle.details.transmission} 
                </Text>
                {/* prettier-ignore */}
                <Text style={styles.bodyText}>
                  Cloth/Leather: {vehicle.equipment.clothLeather} 
                </Text>
                {/* prettier-ignore */}
                <Text style={styles.bodyText}>
                  Roof: {vehicle.equipment.roof} 
                </Text>
                {/* prettier-ignore */}
                <Text style={styles.bodyText}>
                  Drive Train: {vehicle.details.driveTrain} 
                </Text>
                {/* prettier-ignore */}
                <Text style={styles.bodyText}>
                  Tire Size: {vehicle.equipment.sizeTires} 
                </Text>
                {/* prettier-ignore */}
                {vehicle.equipment.navigation && (
                  <Text style={styles.bodyText}>Navigation: Yes</Text>
                )}
                {/* prettier-ignore */}
                <Text style={styles.bodyText}>
                  Fuel Type: {vehicle.details.fuelType} 
                </Text>
                {/* prettier-ignore */}
                {vehicle.equipment.tpms && <Text style={styles.bodyText}>TPMS: Yes</Text>}
                {/* prettier-ignore */}
                {vehicle.equipment.thirdRow && <Text style={styles.bodyText}>Third Row: Yes</Text>}
                {/* prettier-ignore */}
                {vehicle.equipment.powerSeat && (
                  <Text style={styles.bodyText}>Power Seats: Yes</Text>
                )}
                {/* {vehicle.equipment.options &&
                  vehicle.equipment.options.length > 0 &&
                  vehicle.equipment.options.map((option: any, index: number) => {
                    return (
                      <Text key={index} style={styles.bodyText}>
                        {option}
                      </Text>
                    );
                  })} */}
              </View>
            </View>
            {/* title info row */}
            <View style={styles.topBorderRow}>
              <View style={styles.columnLeft}>
                <View style={styles.header}>
                  <Text style={styles.headerText}>TITLE INFORMATION</Text>
                </View>
              </View>
              <View style={styles.columnRight}>
                <View style={styles.header}>
                  <Text style={styles.headerText}>VEHICLE PURCHASE</Text>
                </View>
              </View>
            </View>
            {/* body row */}
            <View style={styles.row}>
              <View style={styles.columnLeft}>
                <Text style={styles.bodyText}>Seller Location: {sellerDealership?.province}</Text>
                <Text style={styles.bodyText}></Text>
                <Text style={styles.bodyText}></Text>
                <View style={styles.innerTitle}>
                  <Text style={styles.headerText}>ANNOUNCEMENTS</Text>
                </View>
                {announcements &&
                  announcements.map((announcement: string, index: number) => {
                    return (
                      <Text key={index} style={styles.bodyText}>
                        {announcement}
                      </Text>
                    );
                  })}
                {repairs &&
                  repairs.map((repair: string, index: number) => {
                    return (
                      <Text key={index} style={styles.bodyText}>
                        {repair}
                      </Text>
                    );
                  })}
              </View>
              <View style={styles.columnRight}>
                <View style={styles.listItem}>
                  <Text style={styles.bodyText}>Sale Price</Text>
                  <Text style={styles.bodyText}>
                    {currencyFormat.format(vehicle.auction.currentPrice)}
                  </Text>
                </View>
                {buyerFee && (
                  <View style={styles.listItem}>
                    <Text style={styles.bodyText}>Buy Fee</Text>
                    <Text style={styles.bodyText}>{currencyFormat.format(buyerFee)}</Text>
                  </View>
                )}
                {sellerDealership?.captureFee && (
                  <View style={styles.listItem}>
                    <Text style={styles.bodyText}>Capture Fee</Text>
                    <Text style={styles.bodyText}>{currencyFormat.format(captureFee)}</Text>
                  </View>
                )}
                {subTotalPrice && (
                  <View style={styles.listItem}>
                    <Text style={styles.boldBodyText}>SubTotal</Text>
                    <Text style={styles.boldBodyText}>{currencyFormat.format(subTotalPrice)}</Text>
                  </View>
                )}
                {sellerDealership &&
                  (sellerDealership.province === 'Newfoundland Labrador' ||
                    sellerDealership.province === 'New Brunswick' ||
                    sellerDealership.province === 'Nova Scotia' ||
                    sellerDealership.province === 'Nova Scotia' ||
                    sellerDealership.province === 'Prince Edward Island') && (
                    <View style={styles.listItem}>
                      <Text style={styles.bodyText}>HST</Text>
                      <Text style={styles.bodyText}>
                        {currencyFormat.format(subTotalPrice * 0.15)}
                      </Text>
                    </View>
                  )}
                {sellerDealership && sellerDealership.province === 'Ontario' && (
                  <View style={styles.listItem}>
                    <Text style={styles.bodyText}>HST</Text>
                    <Text style={styles.bodyText}>
                      {currencyFormat.format(subTotalPrice * 0.13)}
                    </Text>
                  </View>
                )}
                {sellerDealership &&
                  (sellerDealership.province === 'Alberta' ||
                    sellerDealership.province === 'Northwest Territories' ||
                    sellerDealership.province === 'Yukon Territory' ||
                    sellerDealership.province === 'Nunavut') && (
                    <View style={styles.listItem}>
                      <Text style={styles.bodyText}>GST</Text>
                      <Text style={styles.bodyText}>
                        {currencyFormat.format(subTotalPrice * 0.05)}
                      </Text>
                    </View>
                  )}
                {sellerDealership &&
                  (sellerDealership.province === 'British Columbia' ||
                    sellerDealership.province === 'Manitoba' ||
                    sellerDealership.province === 'Saskatchewan') && (
                    <>
                      <View style={styles.listItem}>
                        <Text style={styles.bodyText}>GST</Text>
                        <Text style={styles.bodyText}>
                          {currencyFormat.format(subTotalPrice * 0.05)}
                        </Text>
                      </View>
                      <View style={styles.listItem}>
                        <Text style={styles.bodyText}>PST</Text>
                        <Text style={styles.bodyText}>
                          {currencyFormat.format(subTotalPrice * 0.07)}
                        </Text>
                      </View>
                    </>
                  )}
                {sellerDealership && sellerDealership.province === 'Quebec' && (
                  <>
                    <View style={styles.listItem}>
                      <Text style={styles.bodyText}>GST</Text>
                      <Text style={styles.bodyText}>
                        {currencyFormat.format(subTotalPrice * 0.05)}
                      </Text>
                    </View>
                    <View style={styles.listItem}>
                      <Text style={styles.bodyText}>QST</Text>
                      <Text style={styles.bodyText}>
                        {currencyFormat.format(subTotalPrice * 0.09975)}
                      </Text>
                    </View>
                  </>
                )}
                {totalPrice && (
                  <View style={styles.listItem}>
                    <Text style={styles.boldBodyText}>Total Due (Taxes Included)</Text>
                    <Text style={styles.boldBodyText}>{currencyFormat.format(totalPrice)}</Text>
                  </View>
                )}
                <View>
                  <Text style={styles.boldBodyText}>* All Prices Listed In CAD Funds *</Text>
                </View>
                <View style={{ marginTop: '50px' }}>
                  <View style={styles.vhrHeader}>
                    <Text style={styles.headerText}>VHR DAMAGES</Text>
                  </View>
                  {vhrDamages &&
                    vhrDamages.map((damage: any, index: number) => {
                      return (
                        <View key={index} style={{ marginBottom: '20px' }}>
                          <View style={styles.listItem}>
                            <Text style={styles.bodyText}>Damaged Item: </Text>
                          </View>
                          <View style={styles.listItem}>
                            <Text style={styles.bodyText}>
                              Cost: {currencyFormat.format(damage.cost)}
                            </Text>
                          </View>
                          <View style={styles.listItem}>
                            <Text style={styles.bodyText}>Date: {damage.date}</Text>
                          </View>
                          <View style={styles.listItem}>
                            <Text style={styles.bodyText}>Description: {damage.desc}</Text>
                          </View>
                        </View>
                      );
                    })}
                </View>
              </View>
            </View>
            {/* disclosures title row */}
            <View style={styles.topBorderRow}>
              <View style={styles.columnLeft}>
                <View style={styles.header}>
                  <Text style={styles.headerText}>INSPECTION SUMMARY</Text>
                </View>
                {disclosures &&
                  disclosures.map((disclosure: any, index: number) => {
                    return (
                      <View key={index} style={styles.listItem}>
                        <Text style={styles.bodyText}>{disclosure.label}</Text>
                        <Text style={styles.bodyText}>{disclosure.value}</Text>
                      </View>
                    );
                  })}
              </View>
              <View style={styles.columnRight}>
                <View style={styles.header}>
                  <Text style={styles.headerText}>ODOMETER DISCLOSURE - Canada</Text>
                </View>
                <View>
                  <View>
                    {/* prettier-ignore */}
                    <Text style={styles.bodyText}>
                      Federal law and/or Provincial law requires that you state the
                      kilometers/mileage upon transfer of ownership. Failure to
                      complete or providing a false statement may result in fines and /or
                      imprisonment.
                    </Text>
                    <Text style={styles.bodyText}>The Seller states that:</Text>
                    {/* prettier-ignore */}
                    <Text style={styles.bodyText}>
                      I hereby certify that the odometer of the vehicle described
                      herein was not altered, set back, or disconnected while in my
                      possession, and I have no knowledge of anyone else doing so.
                    </Text>
                    {odometerData &&
                      odometerData.map((announcement: string, index: number) => {
                        return (
                          <Text key={index} style={styles.bodyText}>
                            {announcement}
                          </Text>
                        );
                      })}
                  </View>
                </View>
              </View>
            </View>
          </View>
          {/* upper paragraph row */}
          <Text style={styles.title}>LEGAL TERMS AND CONDITIONS</Text>
          <View style={styles.paragraphRow}>
            {/* prettier-ignore */}
            <Text style={styles.bodyText}>
              I, or we by use of our authorised signature, hereby agree to pay the indicated sale price for the vehicle described in
              this Bill of Sale.
            </Text>
          </View>
          <View style={styles.paragraphRow}>
            {/* prettier-ignore */}
            <Text style={styles.bodyText}>
              I/we have purchased the described vehicle in accordance with the terms of this contract. Payment shall be made
              within 7 days and before the unit leaves the premises. In the event of default in the payment of the purchase price,
              then the seller may with or without legal process, take immediate possession of the unit complete as sold by
              entering the premises of the buyer, or his agent, or bailee for the purpose of the repossessing.
            </Text>
          </View>
          <View style={styles.paragraphRow}>
            {/* prettier-ignore */}
            <Text style={styles.bodyText}>
              Buyer confirms that it is now licensed under the appropriate motor vehicle dealer act for its jurisdiction and other
              applicable legislation.
            </Text>
          </View>
          <View style={styles.paragraphRow}>
            {/* prettier-ignore */}
            <Text style={styles.bodyText}>
              While the vehicle is on the premises of the seller it remains at the owner’s risk, whether the owner be the seller or
              the buyer.
            </Text>
          </View>
          <View style={styles.paragraphRow}>
            {/* prettier-ignore */}
            <Text style={styles.bodyText}>
              In addition to the disclosure information and announcements on the first page of this bill of sale, the seller makes
              the disclosures set out below which are hereby incorporated as terms and conditions of this Bill of Sale. Duplicate
              or inconsistent data may be due to disclosure information originating from multiple sources.
            </Text>
          </View>
          <View style={styles.paragraphRow}>
            <Text style={styles.subtitle}>Warranties and Conditions</Text>
            {/* prettier-ignore */}
            <Text style={styles.bodyText}>
              In addition to the disclosure information and announcements on the first page of this bill of sale, the seller makes
              the disclosures set out below which are hereby incorporated as terms and conditions of this Bill of Sale. Duplicate
              or inconsistent data may be due to disclosure information originating from multiple sources.
            </Text>
          </View>
          <View style={styles.paragraphRow}>
            <Text style={styles.subtitle}>Liens</Text>
            {/* prettier-ignore */}
            <Text style={styles.bodyText}>
              The seller warrants that the vehicle described in this agreement is free of any registered or unregistered liens,
              security interests, judgements, chattel mortgages or encumbrances of any kind.
            </Text>
          </View>
          <View style={styles.paragraphRow}>
            <Text style={styles.subtitle}>Seller Warrants Title</Text>
            {/* prettier-ignore */}
            <Text style={styles.bodyText}>
              The seller warrants to the buyer that the seller has good title to the vehicle, is the legal owner of the vehicle and has
              the right to sell the vehicle.
            </Text>
          </View>
          <View style={styles.paragraphRow}>
            <Text style={styles.subtitle}>Legal Ownership</Text>
            {/* prettier-ignore */}
            <Text style={styles.bodyText}>
              Legal Ownership of the vehicle shall not pass to the buyer until the entire purchase has been paid in full. This process can be initiated from the 
              Post-Auction Section of the application by the seller of the vehicle.
            </Text>
          </View>
          <View style={styles.paragraphRow}>
            <Text style={styles.subtitle}>Right to Repossess</Text>
            {/* prettier-ignore */}
            <Text style={styles.bodyText}>
              Should the total purchase price as shown on the front of this agreement not be paid in full within the three days of
              the buyer taking delivery, then the seller shall have the right to repossess the vehicle from the buyer without notice.
              The seller may sell any vehicle repossessed and maintains all legal rights to recover any resulting loss.
            </Text>
          </View>
          <View style={styles.paragraphRow}>
            <Text style={styles.subtitle}>Condition Reports</Text>
            {/* prettier-ignore */}
            <Text style={styles.bodyText}>
              Sellers will be held responsible for the accuracy of their condition reports. All significant visible defects must be
              disclosed in the condition report. All risk of damage to the vehicle is the responsibility of the buyer once delivery has
              been taken.
            </Text>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
}
