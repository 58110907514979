import {
  Container,
  Box,
  useTheme,
  IconButton,
  Paper,
  Typography,
  SelectChangeEvent,
  Divider,
  Tabs,
  Tab,
  Alert,
  AlertTitle,
  Tooltip,
  TablePagination,
  Button
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { makeStyles } from '@mui/styles';
import Page from '../../../components/Page';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import AuctionFilters from 'components/auction/AuctionSidebarFilters';
import useSettings from 'hooks/useSettings';
import { DebounceInput } from 'react-debounce-input';
import { styled } from '@mui/material/styles';
import { VehicleInfo } from 'types/vehicleInfo';
import {
  collection,
  CollectionReference,
  doc,
  DocumentReference,
  limit,
  onSnapshot,
  getDoc,
  orderBy,
  query,
  where
} from 'firebase/firestore';
import { useFirestore } from 'reactfire';
import useAuth from 'hooks/useAuth';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import Tour from 'reactour';
import { User } from 'constants/user';
import { useFirestoreByPath } from 'hooks/useFirestoreByPath';
import EmptyStateMessage from 'components/shared/EmptyStateMessage';
import { isMobile } from 'react-device-detect';
import TabsPageSell from './components/TabsPagesSell';
import AuctionDrawer from 'components/auction/drawer/AuctionDrawer';
import { useNavigate } from 'react-router-dom';
import { useVehicleBatch } from 'utils/vehicleQuery';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface StyledTabsProps {
  id?: string;
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`activeAuction-tabpanel-${index}`}
      aria-labelledby={`activeAuction-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `activeAuction-tab-${index}`,
    'aria-controls': `activeAuction-tabpanel-${index}`
  };
};

export default function AuctionPageSell() {
  const theme = useTheme();
  const firestore = useFirestore();
  const { currentUser, user, logout } = useAuth();
  const { themeStretch } = useSettings();
  const [openSidebar, setOpenSidebar] = useState(false); // useState(isMobile ? false : true);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [isFiltered, setIsFiltered] = useState<boolean>(false);
  const [drawerTab, setDrawerTab] = useState<number>(0);
  let [filters, setFilters] = useState<any>([{}]);
  const [mediaQuery, setMediaQuery] = useState(1450);
  const [tabValue, setTabValue] = useState(0);
  const [drawerVehicle, setDrawerVehicle] = useState<VehicleInfo>();

  const [vehicles, setVehicles] = useState<VehicleInfo[]>();
  const [instockVehicles, setInstockVehicles] = useState<VehicleInfo[]>();
  const [appraisalVehicles, setAppraisalVehicles] = useState<VehicleInfo[]>();
  const [watchlistVehicles, setWatchlistVehicles] = useState<VehicleInfo[]>();
  const [floorMetVehicles, setFloorMetVehicles] = useState<VehicleInfo[]>();
  const [floorNotMetVehicles, setFloorNotMetVehicles] = useState<VehicleInfo[]>();

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      snapshotUser();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  const allPinnedRef = useRef(null);
  const allUnpinnedRef = useRef(null);

  const instockPinnedRef = useRef(null);
  const instockUnpinnedRef = useRef(null);

  const appraisalPinnedRef = useRef(null);
  const appraisalUnpinnedRef = useRef(null);

  const watchlistPinnedRef = useRef(null);
  const watchlistUnpinnedRef = useRef(null);

  const floormetPinnedRef = useRef(null);
  const floormetUnpinnedRef = useRef(null);

  const floornotmetPinnedRef = useRef(null);
  const floornotmetUnpinnedRef = useRef(null);

  const [tourOpen, setTourOpen] = useState(false);
  const { update: updateUser } = useFirestoreByPath<User>('users');

  const [userDeleted, setUserDeleted] = useState<boolean>(false);
  const [, setCurUser] = useState<User>();

  const snapshotUser = () => {
    const userDoc = doc(firestore, `users/${user?.uid}`) as DocumentReference<User>;
    onSnapshot(userDoc, (doc: any) => {
      const userObj = { ...doc.data(), id: doc.id } as User;
      setCurUser(userObj);
      if (!userObj.email) {
        logout?.().then(() => {
          navigate('.');
          window.location.reload();
        });
      }
      if (userObj.showActiveAuctionSellTour && !tourOpen) {
        setTourOpen(true);
      }
      if (userObj && userObj.status.toLowerCase() === 'suspended') {
        setUserSuspended(true);
      } else {
        setUserSuspended(false);
      }
      if (userObj && userObj.status.toLowerCase() === 'disabled') {
        setUserDisabled(true);
      } else {
        setUserDisabled(false);
      }
      if (userObj && userObj.status === 'buyerOnly') {
        setBuyerOnly(true);
      } else {
        setBuyerOnly(false);
      }
    });
  };

  const [userDisabled, setUserDisabled] = useState<boolean>(false);
  const [userSuspended, setUserSuspended] = useState<boolean>(false);
  const [buyerOnly, setBuyerOnly] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/dashboard');
  };

  const handleCloseTour = async () => {
    await updateUser(user?.uid as string, { showActiveAuctionSellTour: false });
    setTourOpen(false);
  };

  const steps = [
    {
      selector: '',
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Sell: Active Auction</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
              Active Auction is where you can observe the live bidding action of your units. You also edit your floors on the fly. These vehicles will only be shown to those dealers that are members of your Selling Network.
            </Typography>
          </Box>
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
              * Floor Prices cannot be edited once they have been met.
            </Typography>
          </Box>
        </Box>
      )
    },
    {
      selector: '#activeAuction2',
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Sell: Active Auction</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          {/* prettier-ignore */}
          <Typography sx={{ marginTop: '10px', color: theme.palette.text.secondary }}>
            Use a number of filtering options to narrow down the vehicle list.
          </Typography>
        </Box>
      )
    },
    {
      selector: '#activeAuctionSell3',
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Sell: Active Auction</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />

          {/* prettier-ignore */}
          <Typography sx={{ marginTop: '10px', color: theme.palette.text.secondary }}>
            Quick sort your active vehicles.
          </Typography>
        </Box>
      )
    },
    {
      selector: '#activeAuctionSell4', //todo
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Sell: Active Auction</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          {/* prettier-ignore */}
          <Typography sx={{ marginTop: '10px', color: theme.palette.text.secondary }}>
            You can open and close the filter options to give yourself more room.
          </Typography>
        </Box>
      )
    },
    {
      selector: '#activeAuctionSell5',
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Sell: Active Auction</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
              <span style={{ fontWeight: '900' }}>VEHICLES</span>
            </Typography>
          </Box>
          {/* prettier-ignore */}
          <Typography sx={{ marginTop: '10px', color: theme.palette.text.secondary }}>
            A vehicle displayed in the Selling Active Auction is very similar to how it will appear in the Buying Active auction, with the exception of the bidding options being replaced by Floor Price and the ability to mark a vehicle as Sold, making the vehicle 'unreserved' and guaranteed to sell to the highest bidder at the end of bidding.
          </Typography>
        </Box>
      )
    },
    {
      selector: '#activeAuction6',
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Sell: Active Auction</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          {/* prettier-ignore */}
          <Typography sx={{ marginTop: '10px', color: theme.palette.text.secondary }}>
            Expand and condense the vehicle item using the arrows.
          </Typography>
          {/* prettier-ignore */}
          <Typography sx={{ marginTop: '10px', color: theme.palette.text.secondary }}>
            You can also pin priority vehicles during an auction so you don't have to scroll the entire list to find them.
          </Typography>
        </Box>
      )
    },
    {
      selector: '#activeAuction8',
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Sell: Active Auction</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          {/* prettier-ignore */}
          <Typography sx={{ marginTop: '10px', color: theme.palette.text.secondary }}>
            To review the vehicle, click these individual icons to access information drawers for all car details. These drawers are an exact mirror to what buyers will see. Important vehicle information such as equipment, damages and disclosure will be found here.
          </Typography>
        </Box>
      )
    },
    {
      selector: '#activeAuction10',
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Sell: Active Auction</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          {/* prettier-ignore */}
          <Typography sx={{ marginTop: '10px', color: theme.palette.text.secondary }}>
            Not enough room? You can close the main menu with the menu button.
          </Typography>
        </Box>
      )
    }
  ];

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleFilterChange = (values: any) => {
    const filtersToUpdate: any[] = [];
    Object.keys(values).forEach((key: any) => {
      if (values[key]) {
        filtersToUpdate.push({ name: key, value: values[key] });
      }
    });
    setFilters(filtersToUpdate);
    setIsFiltered(true);
  };

  const handleToggleSidebar = (value: boolean) => {
    setOpenSidebar(!value);
    handleChangeMediaQueryValue();
  };

  const handleChangeMediaQueryValue = () => {
    if (!openSidebar) {
      setMediaQuery(1450);
    } else {
      setMediaQuery(1150);
    }
  };

  const handleToggleDrawer = (value: number, v: VehicleInfo) => {
    setDrawerTab(value);
    setDrawerVehicle(v);
    setOpenDrawer(!openDrawer);
  };

  const handleFormReset = () => {
    setIsFiltered(false);
    setFilters([{}]);
    // if (allVehicles) {
    //   let filteredVehicles = allVehicles;
    //   setVehicles(filteredVehicles);
    //   sortVehiclesforTabs(filteredVehicles);
    //   setIsFiltered(false);
    // }
  };

  // ALL ACTIVE
  //---------------------------------------------------------------------------------------
  const [expandedPinned, setExpandedPinned] = useState(true);
  const [expandedAll, setExpandedAll] = useState(true);
  const [order, setOrder] = useState('timeAsc');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [lastDocArray, setLastDocArray] = useState<any[]>([]);
  const [firstDocArray, setFirstDocArray] = useState<any[]>([]);
  const [backPage, setBackPage] = useState<boolean>(false);
  const [lastDataVehicleRef, setLastDataVehicleRef] = useState<DocumentReference>();
  const [collectionSize, setCollectionSize] = useState<number>(0);

  const queryConstraints = [
    where('dealershipId', '==', currentUser.currentDealershipId),
    where('auction.auctionStatus', '==', 'active'),
    orderBy('auction.endDate', 'asc'),
    orderBy('auction.endTime', 'asc'),
    limit(rowsPerPage)
  ];

  const { data } = useVehicleBatch(
    page,
    queryConstraints,
    lastDocArray[page - 1],
    backPage,
    firstDocArray[page]
  );

  const allVehicles = useMemo(() => {
    const lastDoc = data?.docs[data?.docs?.length - 1];
    if (lastDoc) {
      const lastDocRef = data?.docs[data?.docs?.length - 1].ref as DocumentReference;
      setLastDataVehicleRef(lastDocRef);
    }
    // prettier-ignore
    return data?.docs?.map((doc) => ({ ...doc.data(), id: doc.id }));
  }, [data]);

  useEffect(() => {
    if (isFiltered) {
      const filteredVehicles = filterVehicles(allVehicles);
      setVehicles(filteredVehicles);
    } else {
      setVehicles(allVehicles);
    }
  }, [allVehicles, isFiltered]);

  const countVehicleCollection = () => {
    const vehicleRef = collection(firestore, 'vehicles') as CollectionReference;
    const vehicleQuery = query(
      vehicleRef,
      where('dealershipId', '==', currentUser.currentDealershipId),
      where('auction.auctionStatus', '==', 'active')
    );
    const unsubsribe = onSnapshot(vehicleQuery, (querySnapshot) => {
      setCollectionSize(querySnapshot.size);
    });
    return unsubsribe;
  };

  useEffect(() => {
    const unsubscribe = countVehicleCollection();
    return () => {
      unsubscribe();
    };
  }, []);

  const handleChangePage = async (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      const firstDoc = data?.docs[0];
      const firstDocRef = firstDoc.ref as DocumentReference;
      const docRef = doc(firestore, `vehicles/${firstDocRef.id}`);
      const docSnap = await getDoc(docRef);
      const tempFirstDocs = firstDocArray;
      if (docSnap.exists()) {
        tempFirstDocs.push(docSnap);
        setFirstDocArray(tempFirstDocs);
      }
      if (lastDataVehicleRef) {
        const docRef = doc(firestore, `vehicles/${lastDataVehicleRef.id}`);
        const docSnap = await getDoc(docRef);
        const tempLastDocs = lastDocArray;
        if (docSnap.exists()) {
          tempLastDocs.push(docSnap);
          setLastDocArray(tempLastDocs);
          setPage(newPage);
          setBackPage(false);
        }
      }
    } else {
      setPage(newPage);
      setBackPage(true);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setFirstDocArray([]);
    setLastDocArray([]);
    setBackPage(false);
  };

  const handleOrderByChange = (event: SelectChangeEvent) => {
    setOrder(event.target.value);
  };

  const handleExpandedPinnedChange = () => {
    setExpandedPinned(!expandedPinned);
  };

  const handleExpandedAllChange = () => {
    setExpandedAll(!expandedAll);
  };

  // IN STOCK
  //---------------------------------------------------------------------------------------
  const [orderInStock, setOrderInStock] = useState('timeAsc');
  const [pageInStock, setPageInStock] = useState(0);
  const [rowsPerPageInStock, setRowsPerPageInStock] = useState(25);
  const [expandedPinnedInStock, setExpandedPinnedInStock] = useState(true);
  const [expandedAllInStock, setExpandedAllInStock] = useState(true);
  const [lastDocArrayInStock, setLastDocArrayInStock] = useState<any[]>([]);
  const [firstDocArrayInStock, setFirstDocArrayInStock] = useState<any[]>([]);
  const [backPageInStock, setBackPageInStock] = useState<boolean>(false);
  const [lastDataVehicleRefInStock, setLastDataVehicleRefInStock] = useState<DocumentReference>();
  const [collectionSizeInStock, setCollectionSizeInStock] = useState<number>(0);

  const queryConstraintsInStock = [
    where('dealershipId', '==', currentUser.currentDealershipId),
    where('auction.auctionStatus', '==', 'active'),
    where('auction.isInStock', '==', true),
    orderBy('auction.endDate', 'asc'),
    orderBy('auction.endTime', 'asc'),
    limit(rowsPerPageInStock)
  ];

  const inStockData = useVehicleBatch(
    pageInStock,
    queryConstraintsInStock,
    lastDocArrayInStock[pageInStock - 1],
    backPageInStock,
    firstDocArrayInStock[pageInStock]
  );

  const allInstockVehicles = useMemo(() => {
    const lastDoc = inStockData?.data?.docs[inStockData?.data?.docs?.length - 1];
    if (lastDoc) {
      // prettier-ignore
      const lastDocRef = inStockData?.data?.docs[inStockData?.data?.docs?.length - 1].ref as DocumentReference;
      setLastDataVehicleRefInStock(lastDocRef);
    }
    // prettier-ignore
    return inStockData?.data?.docs?.map((doc) => ({ ...doc.data(), id: doc.id }));
  }, [inStockData.data]);

  useEffect(() => {
    if (isFiltered) {
      const filteredInstockVehicles = filterVehicles(allInstockVehicles);
      setInstockVehicles(filteredInstockVehicles);
    } else {
      setInstockVehicles(allInstockVehicles);
    }
  }, [allInstockVehicles, isFiltered]);

  const countVehicleCollectionInStock = () => {
    const vehicleRef = collection(firestore, 'vehicles') as CollectionReference;
    const vehicleQuery = query(
      vehicleRef,
      where('dealershipId', '==', currentUser.currentDealershipId),
      where('auction.auctionStatus', '==', 'active'),
      where('auction.isInStock', '==', true)
    );
    const unsubsribe = onSnapshot(vehicleQuery, (querySnapshot) => {
      setCollectionSizeInStock(querySnapshot.size);
    });
    return unsubsribe;
  };

  useEffect(() => {
    const unsubscribe = countVehicleCollectionInStock();
    return () => {
      unsubscribe();
    };
  }, []);

  const handleChangePageInStock = async (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > pageInStock) {
      const firstDoc = inStockData?.data?.docs[0];
      const firstDocRef = firstDoc.ref as DocumentReference;
      const docRef = doc(firestore, `vehicles/${firstDocRef.id}`);
      const docSnap = await getDoc(docRef);
      const tempFirstDocs = firstDocArrayInStock;
      if (docSnap.exists()) {
        tempFirstDocs.push(docSnap);
        setFirstDocArrayInStock(tempFirstDocs);
      }
      if (lastDataVehicleRefInStock) {
        const docRef = doc(firestore, `vehicles/${lastDataVehicleRefInStock.id}`);
        const docSnap = await getDoc(docRef);
        const tempLastDocs = lastDocArrayInStock;
        if (docSnap.exists()) {
          tempLastDocs.push(docSnap);
          setLastDocArrayInStock(tempLastDocs);
          setPageInStock(newPage);
          setBackPageInStock(false);
        }
      }
    } else {
      setPageInStock(newPage);
      setBackPageInStock(true);
    }
  };

  const handleChangeRowsPerPageInStock = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPageInStock(parseInt(event.target.value, 10));
    setPageInStock(0);
    setFirstDocArrayInStock([]);
    setLastDocArrayInStock([]);
    setBackPageInStock(false);
  };

  const handleOrderByChangeInStock = (event: SelectChangeEvent) => {
    setOrderInStock(event.target.value);
  };

  const handleExpandedPinnedChangeInStock = () => {
    setExpandedPinnedInStock(!expandedPinnedInStock);
  };

  const handleExpandedAllChangeInStock = () => {
    setExpandedAllInStock(!expandedAllInStock);
  };

  // APPRAISALS
  //---------------------------------------------------------------------------------------
  const [orderAppraisals, setOrderAppraisals] = useState('timeAsc');
  const [pageAppraisals, setPageAppraisals] = useState(0);
  const [rowsPerPageAppraisals, setRowsPerPageAppraisals] = useState(25);
  const [expandedPinnedAppraisals, setExpandedPinnedAppraisals] = useState(true);
  const [expandedAllAppraisals, setExpandedAllAppraisals] = useState(true);
  const [lastDocArrayAppraisals, setLastDocArrayAppraisals] = useState<any[]>([]);
  const [firstDocArrayAppraisals, setFirstDocArrayAppraisals] = useState<any[]>([]);
  const [backPageAppraisals, setBackPageAppraisals] = useState<boolean>(false);
  // prettier-ignore
  const [lastDataVehicleRefAppraisals, setLastDataVehicleRefAppraisals] = useState<DocumentReference>();
  const [collectionSizeAppraisals, setCollectionSizeAppraisals] = useState<number>(0);

  const queryConstraintsAppraisals = [
    where('dealershipId', '==', currentUser.currentDealershipId),
    where('auction.auctionStatus', '==', 'active'),
    where('auction.isInStock', '==', false),
    orderBy('auction.endDate', 'asc'),
    orderBy('auction.endTime', 'asc'),
    limit(rowsPerPageAppraisals)
  ];

  const appraisalsData = useVehicleBatch(
    pageAppraisals,
    queryConstraintsAppraisals,
    lastDocArrayAppraisals[pageAppraisals - 1],
    backPageAppraisals,
    firstDocArrayAppraisals[pageAppraisals]
  );

  const allAppraisalVehicles = useMemo(() => {
    const lastDoc = appraisalsData?.data?.docs[appraisalsData?.data?.docs?.length - 1];
    if (lastDoc) {
      // prettier-ignore
      const lastDocRef = appraisalsData?.data?.docs[appraisalsData?.data?.docs?.length - 1].ref as DocumentReference;
      setLastDataVehicleRefAppraisals(lastDocRef);
    }
    // prettier-ignore
    return appraisalsData?.data?.docs?.map((doc) => ({ ...doc.data(), id: doc.id }));
  }, [appraisalsData.data]);

  useEffect(() => {
    if (isFiltered) {
      const filteredAppraisalVehicles = filterVehicles(allAppraisalVehicles);
      setAppraisalVehicles(filteredAppraisalVehicles);
    } else {
      setAppraisalVehicles(allAppraisalVehicles);
    }
  }, [allAppraisalVehicles, isFiltered]);

  const countVehicleCollectionAppraisals = () => {
    const vehicleRef = collection(firestore, 'vehicles') as CollectionReference;
    const vehicleQuery = query(
      vehicleRef,
      where('dealershipId', '==', currentUser.currentDealershipId),
      where('auction.auctionStatus', '==', 'active'),
      where('auction.isInStock', '==', false)
    );
    const unsubsribe = onSnapshot(vehicleQuery, (querySnapshot) => {
      setCollectionSizeAppraisals(querySnapshot.size);
    });
    return unsubsribe;
  };

  useEffect(() => {
    const unsubscribe = countVehicleCollectionAppraisals();
    return () => {
      unsubscribe();
    };
  }, []);

  const handleChangePageAppraisals = async (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > pageAppraisals) {
      const firstDoc = appraisalsData?.data?.docs[0];
      const firstDocRef = firstDoc.ref as DocumentReference;
      const docRef = doc(firestore, `vehicles/${firstDocRef.id}`);
      const docSnap = await getDoc(docRef);
      const tempFirstDocs = firstDocArrayAppraisals;
      if (docSnap.exists()) {
        tempFirstDocs.push(docSnap);
        setFirstDocArrayAppraisals(tempFirstDocs);
      }
      if (lastDataVehicleRefAppraisals) {
        const docRef = doc(firestore, `vehicles/${lastDataVehicleRefAppraisals.id}`);
        const docSnap = await getDoc(docRef);
        const tempLastDocs = lastDocArrayAppraisals;
        if (docSnap.exists()) {
          tempLastDocs.push(docSnap);
          setLastDocArrayAppraisals(tempLastDocs);
          setPageAppraisals(newPage);
          setBackPageAppraisals(false);
        }
      }
    } else {
      setPageAppraisals(newPage);
      setBackPageAppraisals(true);
    }
  };

  const handleChangeRowsPerPageAppraisals = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPageAppraisals(parseInt(event.target.value, 10));
    setPageAppraisals(0);
    setFirstDocArrayAppraisals([]);
    setLastDocArrayAppraisals([]);
    setBackPageAppraisals(false);
  };

  const handleOrderByChangeAppraisals = (event: SelectChangeEvent) => {
    setOrderAppraisals(event.target.value);
  };

  const handleExpandedPinnedChangeAppraisals = () => {
    setExpandedPinnedAppraisals(!expandedPinnedAppraisals);
  };

  const handleExpandedAllChangeAppraisals = () => {
    setExpandedAllAppraisals(!expandedAllAppraisals);
  };

  // WATCHIST
  //---------------------------------------------------------------------------------------
  const [orderWatchlist, setOrderWatchlist] = useState('timeAsc');
  const [pageWatchlist, setPageWatchlist] = useState(0);
  const [rowsPerPageWatchlist, setRowsPerPageWatchlist] = useState(25);
  const [expandedPinnedWatchlist, setExpandedPinnedWatchlist] = useState(true);
  const [expandedAllWatchlist, setExpandedAllWatchlist] = useState(true);
  const [lastDocArrayWatchlist, setLastDocArrayWatchlist] = useState<any[]>([]);
  const [firstDocArrayWatchlist, setFirstDocArrayWatchlist] = useState<any[]>([]);
  const [backPageWatchlist, setBackPageWatchlist] = useState<boolean>(false);
  // prettier-ignore
  const [lastDataVehicleRefWatchlist, setLastDataVehicleRefWatchlist] = useState<DocumentReference>();
  const [collectionSizeWatchlist, setCollectionSizeWatchlist] = useState<number>(0);

  const queryConstraintsWatchlist = [
    where('dealershipId', '==', currentUser.currentDealershipId),
    where('auction.auctionStatus', '==', 'active'),
    where('auction.isWatchlist', 'array-contains', user?.uid),
    orderBy('auction.endDate', 'asc'),
    orderBy('auction.endTime', 'asc'),
    limit(rowsPerPageWatchlist)
  ];

  const watchlistData = useVehicleBatch(
    pageWatchlist,
    queryConstraintsWatchlist,
    lastDocArrayWatchlist[pageWatchlist - 1],
    backPageWatchlist,
    firstDocArrayWatchlist[pageWatchlist]
  );

  const allWatchlistVehicles = useMemo(() => {
    const lastDoc = watchlistData?.data?.docs[watchlistData?.data?.docs?.length - 1];
    if (lastDoc) {
      // prettier-ignore
      const lastDocRef = watchlistData?.data?.docs[watchlistData?.data?.docs?.length - 1].ref as DocumentReference;
      setLastDataVehicleRefWatchlist(lastDocRef);
    }
    // prettier-ignore
    return watchlistData?.data?.docs?.map((doc) => ({ ...doc.data(), id: doc.id }));
  }, [watchlistData.data]);

  useEffect(() => {
    if (isFiltered) {
      const filteredWatchlistVehicles = filterVehicles(allWatchlistVehicles);
      setWatchlistVehicles(filteredWatchlistVehicles);
    } else {
      setWatchlistVehicles(allWatchlistVehicles);
    }
  }, [allWatchlistVehicles, isFiltered]);

  const countVehicleCollectionWatchlist = () => {
    const vehicleRef = collection(firestore, 'vehicles') as CollectionReference;
    const vehicleQuery = query(
      vehicleRef,
      where('dealershipId', '==', currentUser.currentDealershipId),
      where('auction.auctionStatus', '==', 'active'),
      where('auction.isWatchlist', 'array-contains', user?.uid)
    );
    const unsubsribe = onSnapshot(vehicleQuery, (querySnapshot) => {
      setCollectionSizeWatchlist(querySnapshot.size);
    });
    return unsubsribe;
  };

  useEffect(() => {
    const unsubscribe = countVehicleCollectionWatchlist();
    return () => {
      unsubscribe();
    };
  }, []);

  const handleChangePageWatchlist = async (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > pageWatchlist) {
      const firstDoc = watchlistData?.data?.docs[0];
      const firstDocRef = firstDoc.ref as DocumentReference;
      const docRef = doc(firestore, `vehicles/${firstDocRef.id}`);
      const docSnap = await getDoc(docRef);
      const tempFirstDocs = firstDocArrayWatchlist;
      if (docSnap.exists()) {
        tempFirstDocs.push(docSnap);
        setFirstDocArrayWatchlist(tempFirstDocs);
      }
      if (lastDataVehicleRefWatchlist) {
        const docRef = doc(firestore, `vehicles/${lastDataVehicleRefWatchlist.id}`);
        const docSnap = await getDoc(docRef);
        const tempLastDocs = lastDocArrayWatchlist;
        if (docSnap.exists()) {
          tempLastDocs.push(docSnap);
          setLastDocArrayWatchlist(tempLastDocs);
          setPageWatchlist(newPage);
          setBackPageWatchlist(false);
        }
      }
    } else {
      setPageWatchlist(newPage);
      setBackPageWatchlist(true);
    }
  };

  const handleChangeRowsPerPageWatchlist = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPageWatchlist(parseInt(event.target.value, 10));
    setPageWatchlist(0);
    setFirstDocArrayWatchlist([]);
    setLastDocArrayWatchlist([]);
    setBackPageWatchlist(false);
  };

  const handleOrderByChangeWatchlist = (event: SelectChangeEvent) => {
    setOrderWatchlist(event.target.value);
  };

  const handleExpandedPinnedChangeWatchlist = () => {
    setExpandedPinnedWatchlist(!expandedPinnedWatchlist);
  };

  const handleExpandedAllChangeWatchlist = () => {
    setExpandedAllWatchlist(!expandedAllWatchlist);
  };

  // FLOOR MET
  //---------------------------------------------------------------------------------------
  const [orderFloorMet, setOrderFloorMet] = useState('timeAsc');
  const [pageFloorMet, setPageFloorMet] = useState(0);
  const [rowsPerPageFloorMet, setRowsPerPageFloorMet] = useState(25);
  const [expandedPinnedFloorMet, setExpandedPinnedFloorMet] = useState(true);
  const [expandedAllFloorMet, setExpandedAllFloorMet] = useState(true);
  const [lastDocArrayFloorMet, setLastDocArrayFloorMet] = useState<any[]>([]);
  const [firstDocArrayFloorMet, setFirstDocArrayFloorMet] = useState<any[]>([]);
  const [backPageFloorMet, setBackPageFloorMet] = useState<boolean>(false);
  // prettier-ignore
  const [lastDataVehicleRefFloorMet, setLastDataVehicleRefFloorMet] = useState<DocumentReference>();
  const [collectionSizeFloorMet, setCollectionSizeFloorMet] = useState<number>(0);

  const queryConstraintsFloorMet = [
    where('dealershipId', '==', currentUser.currentDealershipId),
    where('auction.auctionStatus', '==', 'active'),
    where('auction.floorPriceMet', '==', true),
    orderBy('auction.endDate', 'asc'),
    orderBy('auction.endTime', 'asc'),
    limit(rowsPerPageFloorMet)
  ];

  const floorMetData = useVehicleBatch(
    pageFloorMet,
    queryConstraintsFloorMet,
    lastDocArrayFloorMet[pageFloorMet - 1],
    backPageFloorMet,
    firstDocArrayFloorMet[pageFloorMet]
  );

  const allFloorMetVehicles = useMemo(() => {
    const lastDoc = floorMetData?.data?.docs[floorMetData?.data?.docs?.length - 1];
    if (lastDoc) {
      // prettier-ignore
      const lastDocRef = floorMetData?.data?.docs[floorMetData?.data?.docs?.length - 1].ref as DocumentReference;
      setLastDataVehicleRefFloorMet(lastDocRef);
    }
    // prettier-ignore
    return floorMetData?.data?.docs?.map((doc) => ({ ...doc.data(), id: doc.id }));
  }, [floorMetData.data]);

  useEffect(() => {
    console.log('all floor met vehicles =>', allFloorMetVehicles);
    if (isFiltered) {
      const filteredFloorMetVehicles = filterVehicles(allFloorMetVehicles);
      setFloorMetVehicles(filteredFloorMetVehicles);
    } else {
      setFloorMetVehicles(allFloorMetVehicles);
    }
  }, [allFloorMetVehicles, isFiltered]);

  const countVehicleCollectionFloorMet = () => {
    const vehicleRef = collection(firestore, 'vehicles') as CollectionReference;
    const vehicleQuery = query(
      vehicleRef,
      where('dealershipId', '==', currentUser.currentDealershipId),
      where('auction.auctionStatus', '==', 'active'),
      where('auction.floorPriceMet', '==', true)
    );
    const unsubsribe = onSnapshot(vehicleQuery, (querySnapshot) => {
      setCollectionSizeFloorMet(querySnapshot.size);
    });
    return unsubsribe;
  };

  useEffect(() => {
    const unsubscribe = countVehicleCollectionFloorMet();
    return () => {
      unsubscribe();
    };
  }, []);

  const handleChangePageFloorMet = async (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > pageFloorMet) {
      const firstDoc = floorMetData?.data?.docs[0];
      const firstDocRef = firstDoc.ref as DocumentReference;
      const docRef = doc(firestore, `vehicles/${firstDocRef.id}`);
      const docSnap = await getDoc(docRef);
      const tempFirstDocs = firstDocArrayFloorMet;
      if (docSnap.exists()) {
        tempFirstDocs.push(docSnap);
        setFirstDocArrayFloorMet(tempFirstDocs);
      }
      if (lastDataVehicleRefFloorMet) {
        const docRef = doc(firestore, `vehicles/${lastDataVehicleRefFloorMet.id}`);
        const docSnap = await getDoc(docRef);
        const tempLastDocs = lastDocArrayFloorMet;
        if (docSnap.exists()) {
          tempLastDocs.push(docSnap);
          setLastDocArrayFloorMet(tempLastDocs);
          setPageFloorMet(newPage);
          setBackPageFloorMet(false);
        }
      }
    } else {
      setPageFloorMet(newPage);
      setBackPageFloorMet(true);
    }
  };

  const handleChangeRowsPerPageFloorMet = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPageFloorMet(parseInt(event.target.value, 10));
    setPageFloorMet(0);
    setFirstDocArrayFloorMet([]);
    setLastDocArrayFloorMet([]);
    setBackPageFloorMet(false);
  };

  const handleOrderByChangeFloorMet = (event: SelectChangeEvent) => {
    setOrderFloorMet(event.target.value);
  };

  const handleExpandedPinnedChangeFloorMet = () => {
    setExpandedPinnedFloorMet(!expandedPinnedFloorMet);
  };

  const handleExpandedAllChangeFloorMet = () => {
    setExpandedAllFloorMet(!expandedAllFloorMet);
  };

  // FLOOR NOT MET
  //---------------------------------------------------------------------------------------
  const [orderFloorNotMet, setOrderFloorNotMet] = useState('timeAsc');
  const [pageFloorNotMet, setPageFloorNotMet] = useState(0);
  const [rowsPerPageFloorNotMet, setRowsPerPageFloorNotMet] = useState(25);
  const [expandedPinnedFloorNotMet, setExpandedPinnedFloorNotMet] = useState(true);
  const [expandedAllFloorNotMet, setExpandedAllFloorNotMet] = useState(true);
  const [lastDocArrayFloorNotMet, setLastDocArrayFloorNotMet] = useState<any[]>([]);
  const [firstDocArrayFloorNotMet, setFirstDocArrayFloorNotMet] = useState<any[]>([]);
  const [backPageFloorNotMet, setBackPageFloorNotMet] = useState<boolean>(false);
  // prettier-ignore
  const [lastDataVehicleRefFloorNotMet, setLastDataVehicleRefFloorNotMet] = useState<DocumentReference>();
  const [collectionSizeFloorNotMet, setCollectionSizeFloorNotMet] = useState<number>(0);

  const queryConstraintsFloorNotMet = [
    where('dealershipId', '==', currentUser.currentDealershipId),
    where('auction.auctionStatus', '==', 'active'),
    where('auction.floorPriceMet', '==', false),
    orderBy('auction.endDate', 'asc'),
    orderBy('auction.endTime', 'asc'),
    limit(rowsPerPageFloorMet)
  ];

  const floorNotMetData = useVehicleBatch(
    pageFloorNotMet,
    queryConstraintsFloorNotMet,
    lastDocArrayFloorNotMet[pageFloorNotMet - 1],
    backPageFloorNotMet,
    firstDocArrayFloorNotMet[pageFloorNotMet]
  );

  const allFloorNotMetVehicles = useMemo(() => {
    const lastDoc = floorNotMetData?.data?.docs[floorNotMetData?.data?.docs?.length - 1];
    if (lastDoc) {
      // prettier-ignore
      const lastDocRef = floorNotMetData?.data?.docs[floorNotMetData?.data?.docs?.length - 1].ref as DocumentReference;
      setLastDataVehicleRefFloorNotMet(lastDocRef);
    }
    // prettier-ignore
    return floorNotMetData?.data?.docs?.map((doc) => ({ ...doc.data(), id: doc.id }));
  }, [floorNotMetData.data]);

  useEffect(() => {
    if (isFiltered) {
      const filteredFloorNotMetVehicles = filterVehicles(allFloorNotMetVehicles);
      setFloorNotMetVehicles(filteredFloorNotMetVehicles);
    } else {
      setFloorNotMetVehicles(allFloorNotMetVehicles);
    }
  }, [allFloorNotMetVehicles, isFiltered]);

  const countVehicleCollectionFloorNotMet = () => {
    const vehicleRef = collection(firestore, 'vehicles') as CollectionReference;
    const vehicleQuery = query(
      vehicleRef,
      where('dealershipId', '==', currentUser.currentDealershipId),
      where('auction.auctionStatus', '==', 'active'),
      where('auction.floorPriceMet', '==', false)
    );
    const unsubsribe = onSnapshot(vehicleQuery, (querySnapshot) => {
      setCollectionSizeFloorNotMet(querySnapshot.size);
    });
    return unsubsribe;
  };

  useEffect(() => {
    const unsubscribe = countVehicleCollectionFloorNotMet();
    return () => {
      unsubscribe();
    };
  }, []);

  const handleChangePageFloorNotMet = async (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > pageFloorNotMet) {
      const firstDoc = floorNotMetData?.data?.docs[0];
      const firstDocRef = firstDoc.ref as DocumentReference;
      const docRef = doc(firestore, `vehicles/${firstDocRef.id}`);
      const docSnap = await getDoc(docRef);
      const tempFirstDocs = firstDocArrayFloorNotMet;
      if (docSnap.exists()) {
        tempFirstDocs.push(docSnap);
        setFirstDocArrayFloorNotMet(tempFirstDocs);
      }
      if (lastDataVehicleRefFloorNotMet) {
        const docRef = doc(firestore, `vehicles/${lastDataVehicleRefFloorNotMet.id}`);
        const docSnap = await getDoc(docRef);
        const tempLastDocs = lastDocArrayFloorNotMet;
        if (docSnap.exists()) {
          tempLastDocs.push(docSnap);
          setLastDocArrayFloorNotMet(tempLastDocs);
          setPageFloorNotMet(newPage);
          setBackPageFloorNotMet(false);
        }
      }
    } else {
      setPageFloorNotMet(newPage);
      setBackPageFloorNotMet(true);
    }
  };

  const handleChangeRowsPerPageFloorNotMet = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPageFloorNotMet(parseInt(event.target.value, 10));
    setPageFloorNotMet(0);
    setFirstDocArrayFloorNotMet([]);
    setLastDocArrayFloorNotMet([]);
    setBackPageFloorNotMet(false);
  };

  const handleOrderByChangeFloorNotMet = (event: SelectChangeEvent) => {
    setOrderFloorNotMet(event.target.value);
  };

  const handleExpandedPinnedChangeFloorNotMet = () => {
    setExpandedPinnedFloorNotMet(!expandedPinnedFloorNotMet);
  };

  const handleExpandedAllChangeFloorNotMet = () => {
    setExpandedAllFloorNotMet(!expandedAllFloorNotMet);
  };

  //---------------------------------------------------------------------------------------
  // const sortVehiclesforTabs = (vehicles: VehicleInfo[]) => {
  //   const instockVehicles = vehicles?.filter((v: VehicleInfo) => v.auction.isInStock);
  //   setInstockVehicles(instockVehicles);

  //   const appraisalVehicles = vehicles?.filter((v: VehicleInfo) => !v.auction.isInStock);
  //   setAppraisalVehicles(appraisalVehicles);

  //   const watchlistVehicles = vehicles?.filter(
  //     (v: VehicleInfo) => v.auction.isWatchlist && v.auction.isWatchlist.includes(user?.uid)
  //   );
  //   setWatchlistVehicles(watchlistVehicles);

  //   const floorMetVehicles = vehicles?.filter(
  //     (v: VehicleInfo) => v.auction.currentPrice >= v.auction.floorPrice
  //   );
  //   setFloorMetVehicles(floorMetVehicles);

  //   const floorNotMetVehicles = vehicles?.filter(
  //     (v: VehicleInfo) => v.auction.currentPrice < v.auction.floorPrice || !v.auction.currentPrice
  //   );
  //   setFloorNotMetVehicles(floorNotMetVehicles);
  // };

  const filterVehicles = (vehicles: VehicleInfo[]) => {
    let filteredVehicles = vehicles;
    filters.forEach((filter: any) => {
      switch (filter.name) {
        case 'make':
          const fields = filter.value;
          filteredVehicles = filteredVehicles.filter((vehicle) =>
            fields.some((field: string) =>
              field.toLowerCase().includes(vehicle.details.make.toLowerCase())
            )
          );
          break;
        case 'model':
          filteredVehicles = filteredVehicles.filter((vehicle) =>
            vehicle.details.model.toLowerCase().includes(filter.value.toLowerCase())
          );
          break;
        case 'trimLevel':
          filteredVehicles = filteredVehicles.filter((vehicle) =>
            vehicle.details.trim.toLowerCase().includes(filter.value.toLowerCase())
          );
          break;
        case 'driveTrain':
          if (filter.value.toLowerCase() !== 'any') {
            filteredVehicles = filteredVehicles.filter((vehicle) =>
              vehicle.details.driveTrain.toLowerCase().includes(filter.value.toLowerCase())
            );
            break;
          } else {
            break;
          }
        case 'transmission':
          if (filter.value.toLowerCase() !== 'any') {
            filteredVehicles = filteredVehicles.filter((vehicle) =>
              vehicle.details.transmission.toLowerCase().includes(filter.value.toLowerCase())
            );
            break;
          } else {
            break;
          }
        case 'fuelType':
          if (filter.value.toLowerCase() !== 'any') {
            filteredVehicles = filteredVehicles.filter((vehicle) =>
              vehicle.details.fuelType.toLowerCase().includes(filter.value.toLowerCase())
            );
            break;
          } else {
            break;
          }
        case 'exteriorColour':
          if (filter.value.toLowerCase() !== 'any') {
            filteredVehicles = filteredVehicles.filter((vehicle) =>
              vehicle.details.extColor.toLowerCase().includes(filter.value.toLowerCase())
            );
            break;
          } else {
            break;
          }
        case 'yearMin':
          filteredVehicles = filteredVehicles.filter(
            (vehicle) => (vehicle.details.year as number) >= parseInt(filter.value)
          );
          break;
        case 'yearMax':
          filteredVehicles = filteredVehicles.filter(
            (vehicle) => (vehicle.details.year as number) <= parseInt(filter.value)
          );
          break;
        case 'odometerMin':
          filteredVehicles = filteredVehicles.filter(
            (vehicle) => parseInt(vehicle.details.mileage) >= parseInt(filter.value)
          );
          break;
        case 'odometerMax':
          filteredVehicles = filteredVehicles.filter(
            (vehicle) => parseInt(vehicle.details.mileage) <= parseInt(filter.value)
          );
          break;
      }
    });
    return filteredVehicles;
  };

  // useEffect(() => {
  //   if (allVehicles && !isFiltered) {
  //     setVehicles(allVehicles);
  //     sortVehiclesforTabs(allVehicles);
  //   } else if (allVehicles && isFiltered) {
  //     let filteredVehicles = allVehicles;
  //     filters.forEach((filter: any) => {
  //       switch (filter.name) {
  //         case 'make':
  //           const fields = filter.value;
  //           filteredVehicles = filteredVehicles.filter((vehicle) =>
  //             fields.some((field: string) =>
  //               field.toLowerCase().includes(vehicle.details.make.toLowerCase())
  //             )
  //           );
  //           break;
  //         case 'model':
  //           filteredVehicles = filteredVehicles.filter((vehicle) =>
  //             vehicle.details.model.toLowerCase().includes(filter.value.toLowerCase())
  //           );
  //           break;
  //         case 'trimLevel':
  //           filteredVehicles = filteredVehicles.filter((vehicle) =>
  //             vehicle.details.trim.toLowerCase().includes(filter.value.toLowerCase())
  //           );
  //           break;
  //         case 'driveTrain':
  //           if (filter.value.toLowerCase() !== 'any') {
  //             filteredVehicles = filteredVehicles.filter((vehicle) =>
  //               vehicle.details.driveTrain.toLowerCase().includes(filter.value.toLowerCase())
  //             );
  //             break;
  //           } else {
  //             break;
  //           }
  //         case 'transmission':
  //           if (filter.value.toLowerCase() !== 'any') {
  //             filteredVehicles = filteredVehicles.filter((vehicle) =>
  //               vehicle.details.transmission.toLowerCase().includes(filter.value.toLowerCase())
  //             );
  //             break;
  //           } else {
  //             break;
  //           }
  //         case 'fuelType':
  //           if (filter.value.toLowerCase() !== 'any') {
  //             filteredVehicles = filteredVehicles.filter((vehicle) =>
  //               vehicle.details.fuelType.toLowerCase().includes(filter.value.toLowerCase())
  //             );
  //             break;
  //           } else {
  //             break;
  //           }
  //         case 'exteriorColour':
  //           if (filter.value.toLowerCase() !== 'any') {
  //             filteredVehicles = filteredVehicles.filter((vehicle) =>
  //               vehicle.details.extColor.toLowerCase().includes(filter.value.toLowerCase())
  //             );
  //             break;
  //           } else {
  //             break;
  //           }
  //         case 'yearMin':
  //           filteredVehicles = filteredVehicles.filter(
  //             (vehicle) => (vehicle.details.year as number) >= parseInt(filter.value)
  //           );
  //           break;
  //         case 'yearMax':
  //           filteredVehicles = filteredVehicles.filter(
  //             (vehicle) => (vehicle.details.year as number) <= parseInt(filter.value)
  //           );
  //           break;
  //         case 'odometerMin':
  //           filteredVehicles = filteredVehicles.filter(
  //             (vehicle) => parseInt(vehicle.details.mileage) >= parseInt(filter.value)
  //           );
  //           break;
  //         case 'odometerMax':
  //           filteredVehicles = filteredVehicles.filter(
  //             (vehicle) => parseInt(vehicle.details.mileage) <= parseInt(filter.value)
  //           );
  //           break;
  //       }
  //     });
  //     setVehicles(filteredVehicles);
  //     sortVehiclesforTabs(allVehicles);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [allVehicles, filterChange]);

  const StyledTabs = styled((props: StyledTabsProps) => (
    <Tabs
      {...props}
      variant="scrollable"
      sx={{
        minHeight: 'unset !important'
      }}
      // scrollButtons
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />
      }}
    />
  ))(({ theme }) => ({
    '& .MuiTab-root': {
      color:
        theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.text.secondary,
      backgroundColor:
        theme.palette.mode === 'dark'
          ? theme.palette.background.neutral
          : theme.palette.background.paper
    },
    '& .MuiTab-root:not(:last-of-type)': {
      marginRight: '10px'
    },
    '& .MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent !important',
      padding: '5px'
    },
    '& .Mui-selected': {
      backgroundColor: theme.palette.primary.lighter,
      color: `${theme.palette.primary.main} !important`
    },
    '& .MuiTabs-indicatorSpan': {
      display: 'none',
      maxWidth: 40,
      width: '100%',
      backgroundColor: theme.palette.chart.violet[2]
    }
  }));

  const useStyles = makeStyles({
    headerText: {
      fontSize: '14px',
      marginRight: '18px'
      // marginLeft: '0px'
    },
    pagination: {
      borderTopStyle: 'none',
      fontWeight: '700',
      borderTop: 'none'
    },
    tourContainer: {
      maxWidth: '500px !important',
      borderRadius: '10px !important'
    },
    page: {
      width: '100%',
      display: 'flex',
      position: 'relative'
      // [theme.breakpoints.down(1200)]: {
      //   paddingRight: '40px'
      // }
    },
    container: {
      display: 'flex',
      paddingRight: '0 !important'
    },
    collapseContainer: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    innerContainer: {
      display: 'flex',
      width: '100%',
      height: 'auto',
      [theme.breakpoints.down(mediaQuery)]: {
        padding: '0px 40px'
      },
      [theme.breakpoints.down('md')]: {
        padding: '0px 0px'
      }
    },
    expandIcon: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      borderRadius: '100%',
      width: '25px',
      height: '25px',
      padding: '5px'
    },
    bodyContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: 'auto',
      paddingTop: '0px'
    },
    searchContainer: {
      width: '100%',
      maxWidth: '400px',
      height: 'auto',
      padding: '5px 0px',
      marginBottom: '0px',
      marginRight: '20px'
    },
    search: {
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.background.neutral : 'transparent !important',
      border: theme.palette.mode === 'dark' ? 'unset' : `1px solid ${theme.palette.divider}`,
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: '100%'
    },
    searchInput: {
      fontSize: '14px',
      width: '100%',
      height: '34px',
      margin: '0 10px',
      border: 'none',
      padding: '5px 10px',
      outline: 'none !important',
      marginLeft: 2,
      flex: 1,
      backgroundColor: 'transparent !important',
      color:
        theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.text.secondary,
      '&::placeholder': {
        color:
          theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.text.secondary,
        fontSize: '14px'
      }
    },
    searchIcon: {
      fill: theme.palette.primary.main,
      marginRight: '5px'
    }
  });

  const styles = useStyles();

  const [vehicleEmptyState, setVehicleEmptyState] = useState<boolean>(false);
  const [instockEmptyState, setInstockEmptyState] = useState<boolean>(false);
  const [appraisalEmptyState, setAppraisalEmptyState] = useState<boolean>(false);
  const [watchlistEmptyState, setWatchlistEmptyState] = useState<boolean>(false);
  const [floorPriceMetEmptyState, setFloorPriceMetEmptyState] = useState<boolean>(false);
  const [floorPriceNotMetEmptyState, setFloorPriceNotMetEmptyState] = useState<boolean>(false);

  useEffect(() => {
    if (allVehicles && (!vehicles || vehicles.length < 1)) {
      setVehicleEmptyState(true);
    } else {
      setVehicleEmptyState(false);
    }
    if (allVehicles && (!instockVehicles || instockVehicles.length < 1)) {
      setInstockEmptyState(true);
    } else {
      setInstockEmptyState(false);
    }
    if (allVehicles && (!appraisalVehicles || appraisalVehicles.length < 1)) {
      setAppraisalEmptyState(true);
    } else {
      setAppraisalEmptyState(false);
    }
    if (allVehicles && (!watchlistVehicles || watchlistVehicles.length < 1)) {
      setWatchlistEmptyState(true);
    } else {
      setWatchlistEmptyState(false);
    }
    if (allVehicles && (!floorMetVehicles || floorMetVehicles.length < 1)) {
      setFloorPriceMetEmptyState(true);
    } else {
      setFloorPriceMetEmptyState(false);
    }
    if (allVehicles && (!floorNotMetVehicles || floorNotMetVehicles.length < 1)) {
      setFloorPriceNotMetEmptyState(true);
    } else {
      setFloorPriceNotMetEmptyState(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    vehicles,
    instockVehicles,
    appraisalVehicles,
    watchlistVehicles,
    floorMetVehicles,
    floorNotMetVehicles
  ]);

  return (
    <>
      {!userDeleted && !userSuspended && !userDisabled && !buyerOnly ? (
        <Page title="Active Auctions | Seller" className={styles.page}>
          <Box
            sx={{
              position: 'fixed',
              zIndex: '999',
              // top: isMobile ? '-20px' : '-17px',
              top: isMobile ? '85px' : '88px',
              left: isMobile ? '10px' : ''
            }}
          >
            <Tooltip title="Hide/Show">
              <IconButton onClick={() => handleToggleSidebar(openSidebar)} id="activeAuctionSell4">
                <MenuOpenIcon className={styles.expandIcon} />
              </IconButton>
            </Tooltip>
          </Box>
          <AuctionFilters
            handleFilterChange={handleFilterChange}
            handleFormReset={handleFormReset}
            isOpenSidebar={openSidebar}
            onCloseSidebar={() => handleToggleSidebar(openSidebar)}
          />
          <Container
            maxWidth={themeStretch ? false : 'xl'}
            sx={{
              width: openSidebar ? `calc(100% - 250px)` : '100%',
              marginRight: openSidebar ? 0 : 'auto',
              marginTop: isMobile ? '20px' : ''
            }}
            className={styles.container}
          >
            <Box className={styles.innerContainer}>
              <Box className={styles.bodyContainer}>
                <Box className={styles.collapseContainer}>
                  <Box>
                    <Typography
                      variant="h4"
                      component="h1"
                      sx={{
                        ml: 1,
                        mb: 1,
                        fontSize: '1rem !important',
                        color:
                          theme.palette.mode === 'dark'
                            ? theme.palette.common.white
                            : theme.palette.text.primary
                      }}
                    >
                      Active Auction: Selling
                    </Typography>
                    {/* {allVehicles && (
                      <>
                        <Typography
                          component="p"
                          className={styles.headerText}
                          sx={{
                            textTransform: 'uppercase',
                            fontWeight: '700',
                            color: theme.palette.info.light,
                            ml: 1
                          }}
                        >
                          {collectionSize} total vehicles found
                        </Typography>
                      </>
                    )} */}
                  </Box>
                  {/* {allVehicles && (
                    <TablePagination
                      className={styles.pagination}
                      count={collectionSize}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      rowsPerPageOptions={[5, 10, 25]}
                    />
                  )} */}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexWrap: 'wrap'
                  }}
                >
                  <StyledTabs
                    id="activeAuctionSell3"
                    value={tabValue}
                    onChange={handleChangeTab}
                    aria-label="Post Auction Tabs"
                  >
                    <Tab
                      sx={{
                        padding: '0px 10px',
                        borderRadius: '5px',
                        minHeight: '40px',
                        height: '40px'
                      }}
                      label={
                        <Typography sx={{ fontSize: '12px' }}>
                          ALL ACTIVE ({collectionSize})
                        </Typography>
                      }
                      {...a11yProps(0)}
                    />
                    <Tab
                      sx={{
                        padding: '0px 10px',
                        borderRadius: '5px',
                        minHeight: '40px',
                        height: '40px'
                      }}
                      label={
                        <Typography sx={{ fontSize: '12px' }}>
                          IN-STOCK ({collectionSizeInStock})
                        </Typography>
                      }
                      {...a11yProps(1)}
                    />
                    <Tab
                      sx={{
                        padding: '0px 10px',
                        borderRadius: '5px',
                        minHeight: '40px',
                        height: '40px'
                      }}
                      label={
                        <Typography sx={{ fontSize: '12px' }}>
                          APPRAISALS ({collectionSizeAppraisals})
                        </Typography>
                      }
                      {...a11yProps(2)}
                    />
                    <Tab
                      sx={{
                        padding: '0px 10px',
                        borderRadius: '5px',
                        minHeight: '40px',
                        height: '40px'
                      }}
                      label={
                        <Typography sx={{ fontSize: '12px' }}>
                          WATCH LIST ({collectionSizeWatchlist})
                        </Typography>
                      }
                      {...a11yProps(3)}
                    />
                    <Tab
                      sx={{
                        padding: '0px 10px',
                        borderRadius: '5px',
                        minHeight: '40px',
                        height: '40px'
                      }}
                      label={
                        <Typography sx={{ fontSize: '12px' }}>
                          FLOOR PRICE MET ({collectionSizeFloorMet})
                        </Typography>
                      }
                      {...a11yProps(4)}
                    />
                    <Tab
                      sx={{
                        padding: '0px 10px',
                        borderRadius: '5px',
                        minHeight: '40px',
                        height: '40px'
                      }}
                      label={
                        <Typography sx={{ fontSize: '12px' }}>
                          FLOOR PRICE NOT MET ({collectionSizeFloorNotMet})
                        </Typography>
                      }
                      {...a11yProps(5)}
                    />
                  </StyledTabs>
                  <Box className={styles.searchContainer}>
                    <Paper component="form" className={styles.search}>
                      <DebounceInput
                        className={styles.searchInput}
                        placeholder="Search Vehicles by Make, Model, Year, etc."
                        minLength={2}
                        debounceTimeout={300}
                        onChange={(e) => setSearchValue(e.target.value)}
                      />
                      <SearchIcon className={styles.searchIcon} sx={{ width: '20px' }} />
                    </Paper>
                  </Box>
                </Box>
                {/* ALL VEHICLES */}
                <TabPanel value={tabValue} index={0}>
                  <TabsPageSell
                    pinnedRef={allPinnedRef}
                    unpinnedRef={allUnpinnedRef}
                    vehicles={vehicles}
                    collectionSize={collectionSize}
                    searchValue={searchValue}
                    handleOpenDrawer={handleToggleDrawer}
                    mediaQuery={mediaQuery}
                    order={order}
                    expandedPinned={expandedPinned}
                    expandedAll={expandedAll}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    handleOrderByChange={handleOrderByChange}
                    handleExpandedPinnedChange={handleExpandedPinnedChange}
                    handleExpandedAllChange={handleExpandedAllChange}
                  />
                  {vehicleEmptyState && (
                    <EmptyStateMessage
                      boldMessage="You have no vehicles in your active auction."
                      message="Move vehicles from pre-auction or add them using the vehicle capture to add vehicles to your active auctions."
                    />
                  )}
                </TabPanel>
                {/* IN STOCK */}
                <TabPanel value={tabValue} index={1}>
                  <TabsPageSell
                    collectionSize={collectionSizeInStock}
                    pinnedRef={instockPinnedRef}
                    unpinnedRef={instockUnpinnedRef}
                    vehicles={instockVehicles}
                    searchValue={searchValue}
                    handleOpenDrawer={handleToggleDrawer}
                    mediaQuery={mediaQuery}
                    order={orderInStock}
                    page={pageInStock}
                    rowsPerPage={rowsPerPageInStock}
                    expandedPinned={expandedPinnedInStock}
                    expandedAll={expandedAllInStock}
                    handleChangePage={handleChangePageInStock}
                    handleChangeRowsPerPage={handleChangeRowsPerPageInStock}
                    handleOrderByChange={handleOrderByChangeInStock}
                    handleExpandedPinnedChange={handleExpandedPinnedChangeInStock}
                    handleExpandedAllChange={handleExpandedAllChangeInStock}
                  />
                  {instockEmptyState && (
                    <EmptyStateMessage boldMessage="There are no vehicles in stock." message="" />
                  )}
                </TabPanel>
                {/* APPRAISALS */}
                <TabPanel value={tabValue} index={2}>
                  <TabsPageSell
                    collectionSize={collectionSizeAppraisals}
                    pinnedRef={appraisalPinnedRef}
                    unpinnedRef={appraisalUnpinnedRef}
                    vehicles={appraisalVehicles}
                    searchValue={searchValue}
                    handleOpenDrawer={handleToggleDrawer}
                    mediaQuery={mediaQuery}
                    order={orderAppraisals}
                    page={pageAppraisals}
                    rowsPerPage={rowsPerPageAppraisals}
                    expandedPinned={expandedPinnedAppraisals}
                    expandedAll={expandedAllAppraisals}
                    handleChangePage={handleChangePageAppraisals}
                    handleChangeRowsPerPage={handleChangeRowsPerPageAppraisals}
                    handleOrderByChange={handleOrderByChangeAppraisals}
                    handleExpandedPinnedChange={handleExpandedPinnedChangeAppraisals}
                    handleExpandedAllChange={handleExpandedAllChangeAppraisals}
                  />
                  {appraisalEmptyState && (
                    <EmptyStateMessage
                      boldMessage="There are no vehicles in appraisals."
                      message=""
                    />
                  )}
                </TabPanel>
                {/* WATCHLIST */}
                <TabPanel value={tabValue} index={3}>
                  <TabsPageSell
                    collectionSize={collectionSizeWatchlist}
                    pinnedRef={watchlistPinnedRef}
                    unpinnedRef={watchlistUnpinnedRef}
                    vehicles={watchlistVehicles}
                    searchValue={searchValue}
                    handleOpenDrawer={handleToggleDrawer}
                    mediaQuery={mediaQuery}
                    order={orderWatchlist}
                    page={pageWatchlist}
                    rowsPerPage={rowsPerPageWatchlist}
                    expandedPinned={expandedPinnedWatchlist}
                    expandedAll={expandedAllWatchlist}
                    handleChangePage={handleChangePageWatchlist}
                    handleChangeRowsPerPage={handleChangeRowsPerPageWatchlist}
                    handleOrderByChange={handleOrderByChangeWatchlist}
                    handleExpandedPinnedChange={handleExpandedPinnedChangeWatchlist}
                    handleExpandedAllChange={handleExpandedAllChangeWatchlist}
                  />
                  {watchlistEmptyState && (
                    <EmptyStateMessage
                      boldMessage="There are no vehicles in your watchlist."
                      message="You can keep track of vehicles you have saved on your watchlist here."
                    />
                  )}
                </TabPanel>
                {/* FLOOR PRICE MET */}
                <TabPanel value={tabValue} index={4}>
                  <TabsPageSell
                    collectionSize={collectionSizeFloorMet}
                    pinnedRef={floormetPinnedRef}
                    unpinnedRef={floormetUnpinnedRef}
                    vehicles={floorMetVehicles}
                    searchValue={searchValue}
                    handleOpenDrawer={handleToggleDrawer}
                    mediaQuery={mediaQuery}
                    order={orderFloorMet}
                    page={pageFloorMet}
                    rowsPerPage={rowsPerPageFloorMet}
                    expandedPinned={expandedPinnedFloorMet}
                    expandedAll={expandedAllFloorMet}
                    handleChangePage={handleChangePageFloorMet}
                    handleChangeRowsPerPage={handleChangeRowsPerPageFloorMet}
                    handleOrderByChange={handleOrderByChangeFloorMet}
                    handleExpandedPinnedChange={handleExpandedPinnedChangeFloorMet}
                    handleExpandedAllChange={handleExpandedAllChangeFloorMet}
                  />
                  {floorPriceMetEmptyState && (
                    <EmptyStateMessage
                      boldMessage="No vehicles have had their floor price met."
                      message="sample message goes here"
                    />
                  )}
                </TabPanel>
                {/* FLOOR PRICE NOT MET */}
                <TabPanel value={tabValue} index={5}>
                  <TabsPageSell
                    collectionSize={collectionSizeFloorNotMet}
                    pinnedRef={floornotmetPinnedRef}
                    unpinnedRef={floornotmetUnpinnedRef}
                    vehicles={floorNotMetVehicles}
                    searchValue={searchValue}
                    handleOpenDrawer={handleToggleDrawer}
                    mediaQuery={mediaQuery}
                    order={orderFloorNotMet}
                    page={pageFloorNotMet}
                    rowsPerPage={rowsPerPageFloorNotMet}
                    expandedPinned={expandedPinnedFloorNotMet}
                    expandedAll={expandedAllFloorNotMet}
                    handleChangePage={handleChangePageFloorNotMet}
                    handleChangeRowsPerPage={handleChangeRowsPerPageFloorNotMet}
                    handleOrderByChange={handleOrderByChangeFloorNotMet}
                    handleExpandedPinnedChange={handleExpandedPinnedChangeFloorNotMet}
                    handleExpandedAllChange={handleExpandedAllChangeFloorNotMet}
                  />
                  {floorPriceNotMetEmptyState && (
                    <EmptyStateMessage
                      boldMessage="There are no vehicles in floor price not met."
                      message="You can keep track of vehicles that haven't reached their floor price here."
                    />
                  )}
                </TabPanel>
              </Box>
            </Box>
          </Container>
          {vehicles && vehicles.length > 0 && (
            <Tour
              steps={steps}
              isOpen={tourOpen}
              onRequestClose={() => handleCloseTour()}
              className={styles.tourContainer}
            />
          )}
          {drawerVehicle && (
            <AuctionDrawer
              vehicle={drawerVehicle as VehicleInfo}
              tabIndex={drawerTab}
              isOpenSidebar={openDrawer}
              onCloseSidebar={() => setOpenDrawer(!openDrawer)}
            />
          )}
        </Page>
      ) : (
        <>
          {userDeleted && (
            <Alert severity="error">
              <AlertTitle>Permission Denied</AlertTitle>
              Your account has been disabled.
            </Alert>
          )}
          {userSuspended && (
            <>
              <Alert severity="error">
                <AlertTitle>Thank you for joining DealerGavel</AlertTitle>
                Your account information is being reviewed by DealerGavel team, and will be
                &nbsp;authorized shortly. For now, you can return to the dashboard and await
                &nbsp;authorization.
              </Alert>
              <Box sx={{ mt: '20px' }}>
                <Button onClick={handleNavigate} variant="contained">
                  Back to Dashboard
                </Button>
              </Box>
            </>
          )}
          {userDisabled && (
            <>
              <Alert severity="error">
                <AlertTitle>Permission Denied</AlertTitle>
                Your account has been disabled.
              </Alert>
              <Box sx={{ mt: '20px' }}>
                <Button onClick={handleNavigate} variant="contained">
                  Back to Dashboard
                </Button>
              </Box>
            </>
          )}
          {buyerOnly && (
            <>
              <Alert severity="error">
                <AlertTitle>Permission Denied</AlertTitle>
                You do not have permissions for this page.
              </Alert>
              <Box sx={{ mt: '20px' }}>
                <Button onClick={handleNavigate} variant="contained">
                  Back to Dashboard
                </Button>
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
}
