// material
import { useTheme } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

export default function Logo({ sx }: BoxProps) {
  const theme = useTheme();
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const GREY_100 = theme.palette.grey[100];
  const GREY_300 = theme.palette.grey[300];
  const GREY_800 = theme.palette.grey[800];

  return (
    <Box sx={{ width: 98, height: 35, ...sx }}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 216 78"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M99.6734 21.6938C99.6734 28.5188 95.0204 32.9063 87.6734 32.9063H79.8367V10.2375H87.6734C95.0204 10.2375 99.6734 14.8688 99.6734 21.6938ZM87.4285 29.0063C92.0816 29.0063 94.7755 26.325 94.7755 21.6938C94.7755 17.0625 92.0816 14.1375 87.4285 14.1375H84.2449V29.0063H87.4285Z"
          fill={GREY_800}
        />
        <path
          d="M110.939 33.15C105.796 33.15 101.878 29.4938 101.878 23.8875C101.878 18.2813 105.551 14.625 110.939 14.625C116.082 14.625 119.755 18.0375 119.755 23.4C119.755 23.8875 119.755 24.6188 119.51 25.1063H106.286C106.531 27.7875 108.245 29.25 110.694 29.25C112.653 29.25 113.633 28.275 114.367 27.0563H119.265C118.286 30.4688 115.347 33.15 110.939 33.15ZM106.531 22.1813H115.102C115.102 19.7438 113.143 18.2812 110.939 18.2812C108.49 18.2812 106.775 19.7438 106.531 22.1813Z"
          fill={GREY_800}
        />
        <path
          d="M130.041 14.625C132.98 14.625 134.939 16.0875 135.918 17.55V15.1125H140.571V32.9062H135.918V30.225C134.939 31.6875 132.735 33.15 130.041 33.15C125.633 33.15 121.959 29.4938 121.959 23.8875C121.714 18.2813 125.388 14.625 130.041 14.625ZM131.265 18.525C128.816 18.525 126.612 20.2312 126.612 23.6437C126.612 27.0562 128.816 29.0062 131.265 29.0062C133.714 29.0062 135.918 27.0562 135.918 23.6437C135.918 20.2312 133.714 18.525 131.265 18.525Z"
          fill={GREY_800}
        />
        <path d="M144.98 9.0188H149.388V32.9063H144.98V9.0188Z" fill={GREY_800} />
        <path
          d="M161.633 33.15C156.49 33.15 152.571 29.4938 152.571 23.8875C152.816 18.2813 156.49 14.625 161.633 14.625C166.776 14.625 170.449 18.0375 170.449 23.4C170.449 23.8875 170.449 24.6188 170.204 25.1063H156.98C157.224 27.7875 158.939 29.25 161.388 29.25C163.347 29.25 164.327 28.275 165.061 27.0563H169.959C169.224 30.4688 166.041 33.15 161.633 33.15ZM157.469 22.1813H166.041C166.041 19.7438 164.082 18.2812 161.878 18.2812C159.429 18.2812 157.714 19.7438 157.469 22.1813Z"
          fill={GREY_800}
        />
        <path
          d="M178.286 32.9062H173.878V14.8687H178.286V17.55C179.51 15.8437 181.469 14.625 183.918 14.625V19.5H182.694C180 19.5 178.286 20.475 178.286 23.8875V32.9062Z"
          fill={GREY_800}
        />
        <path
          d="M110.204 51.675H102.367C100.898 48.75 97.9591 47.2875 94.5306 47.2875C88.4081 47.2875 84.2449 51.675 84.2449 58.2562C84.2449 65.0812 88.4081 69.225 94.7755 69.225C99.9183 69.225 103.347 66.3 104.326 61.425H92.8163V56.3062H111.184V62.1562C109.714 68.9812 103.592 75.3187 94.7755 75.3187C84.9796 75.3187 77.3877 68.25 77.3877 58.2562C77.3877 48.2625 84.7347 40.95 94.5306 40.95C102.122 40.95 108.245 44.85 110.204 51.675Z"
          fill={GREY_800}
        />
        <path
          d="M126.612 48.0188C131.02 48.0188 133.959 49.9688 135.429 52.1625V48.2625H142.286V74.8313H135.429V70.9313C133.714 73.125 130.775 75.3188 126.612 75.3188C119.755 75.3188 114.367 69.7125 114.367 61.425C114.367 53.3813 119.755 48.0188 126.612 48.0188ZM128.326 53.8688C124.653 53.8688 121.224 56.55 121.224 61.6688C121.224 66.7875 124.653 69.7125 128.326 69.7125C132 69.7125 135.429 67.0313 135.429 61.9125C135.429 56.7938 132.245 53.8688 128.326 53.8688Z"
          fill={GREY_800}
        />
        <path
          d="M153.306 48.5063L160.163 68.9813L167.02 48.5063H174.122L164.082 75.0751H155.755L145.959 48.5063H153.306Z"
          fill={GREY_800}
        />
        <path
          d="M189.551 75.5626C181.714 75.5626 176.082 70.2001 176.082 61.6689C176.082 53.1376 181.714 47.7751 189.551 47.7751C197.388 47.7751 202.775 53.1376 202.775 60.9376C202.775 61.9126 202.775 62.6439 202.531 63.6189H182.939C183.184 67.5189 185.878 69.7126 189.306 69.7126C192.245 69.7126 193.714 68.2501 194.694 66.5439H202.041C200.816 71.6626 196.408 75.5626 189.551 75.5626ZM183.184 59.2314H195.918C195.918 55.8189 192.98 53.3814 189.551 53.3814C186.122 53.6251 183.673 55.5751 183.184 59.2314Z"
          fill={GREY_800}
        />
        <path d="M207.918 39.4875H214.775V75.075H207.918V39.4875Z" fill={GREY_800} />
        <path
          d="M27.4286 4.87517C18.3673 5.36267 10.7755 6.58142 1.46937 9.50642C0.979576 9.75017 0.734678 10.2377 0.734678 10.7252C0.734678 11.7002 0.734678 13.1627 0.48978 15.8439C-0.244914 40.2189 10.5306 64.1064 30.3673 77.5127C30.8571 77.7564 31.3469 77.7564 31.8367 77.5127C53.8775 63.3752 63.6735 36.0752 61.7143 10.7252C61.7143 10.2377 61.2245 9.75017 60.7347 9.50642C49.9592 6.33767 36.7347 4.38767 27.4286 4.87517Z"
          fill={GREY_800}
        />
        <path
          d="M27.4286 10.4813C20.0816 10.9688 13.9592 11.9438 6.36736 14.3813C5.87756 14.3813 5.63266 14.625 5.63266 15.1125C5.63266 15.8438 5.63266 17.3063 5.63266 19.2563C4.89797 39.4875 13.7143 58.9875 29.8776 70.2C30.1225 70.2 30.1225 70.4438 30.3674 70.4438C30.6123 70.4438 30.8572 70.4438 30.8572 70.2C48.7347 58.7438 56.8163 36.075 55.347 15.1125C55.347 14.625 55.1021 14.3813 54.6123 14.1375C47.0204 11.9438 37.7143 10.2375 30.6123 10.2375C29.3878 10.2375 28.4082 10.2375 27.4286 10.4813Z"
          fill={PRIMARY_MAIN}
        />
        <path
          d="M30.3674 70.4438C30.6123 70.4438 30.8572 70.4438 30.8572 70.2C48.7347 58.7438 56.8163 36.075 55.347 15.1125C55.347 14.625 55.1021 14.3813 54.6123 14.1375C47.0204 11.9438 37.7143 10.2375 30.6123 10.2375L30.3674 70.4438Z"
          fill={PRIMARY_DARK}
        />
        <path
          d="M32.8163 56.0626C33.3061 50.4564 32.8163 40.2189 32.3265 30.7126C33.0612 30.7126 33.551 29.4939 33.0612 29.0064C33.7959 28.7626 34.0408 28.2751 34.0408 27.5439H35.2653C35.2653 27.5439 35.5102 28.5189 36 28.5189C36.4898 28.5189 36.4898 27.7876 36.4898 27.7876V19.2564C36.4898 19.2564 36.4898 18.5251 36 18.5251C35.5102 18.5251 35.2653 19.2564 35.2653 19.2564H30.3674H24.9796C24.9796 19.2564 24.7347 18.5251 24.2449 18.5251C23.7551 18.5251 24 19.2564 24 19.2564V28.0314C24 28.0314 24 28.7626 24.4898 28.7626C24.9796 28.7626 25.2245 27.7876 25.2245 27.7876H26.449C26.449 28.5189 26.6939 29.0064 27.4286 29.2501C26.9388 29.7376 27.4286 30.9564 28.1633 30.9564C27.6735 40.4626 27.1837 50.7001 27.6735 56.3064C27.6735 58.0126 28.6531 58.9876 30.1225 58.9876C31.8367 58.7439 32.5714 57.7689 32.8163 56.0626Z"
          fill={GREY_100}
        />
        <path
          d="M30.3673 58.7439C31.5918 58.7439 32.5714 57.5251 32.8163 56.0626C33.3061 50.4564 32.8163 40.2189 32.3265 30.7126C33.0612 30.7126 33.551 29.4939 33.0612 29.0064C33.7959 28.7626 34.0408 28.2751 34.0408 27.5439H35.2653C35.2653 27.5439 35.5102 28.5189 36 28.5189C36.4898 28.5189 36.4898 27.7876 36.4898 27.7876V19.2564C36.4898 19.2564 36.4898 18.5251 36 18.5251C35.5102 18.5251 35.2653 19.2564 35.2653 19.2564H30.3673V58.7439Z"
          fill={GREY_300}
        />
        <path d="M42.6122 17.3064H37.9591V29.7376H42.6122V17.3064Z" fill={GREY_300} />
        <path d="M22.7755 17.3064H18.1224V29.7376H22.7755V17.3064Z" fill={GREY_100} />
      </svg>
    </Box>
  );
}
