import {
  CollectionReference,
  collection,
  getFirestore,
  query,
  getDocs,
  DocumentSnapshot,
  startAfter,
  where,
  limit,
  orderBy,
  startAt
} from 'firebase/firestore';
import { app } from '../index';
import { useFirestoreCollection } from 'reactfire';
import { useState, useEffect } from 'react';
import { Dealership } from 'types/dealership';

// export function useDealershipBatch(page: number, constraints: any[]) {
//   console.log('page', page);
//   const db = getFirestore(app);
//   const collectionRef = collection(db, 'dealerships') as CollectionReference<Dealership>;

//   // State to hold the total number of documents
//   const [totalDocuments, setTotalDocuments] = useState<number>(0);

//   // Determine the starting document for pagination
//   let dealershipQuery = query(collectionRef, ...constraints);
//   // (Optionally) calculate the initial lastDoc for the first page
//   const [lastDoc, setLastDoc] = useState<DocumentSnapshot | null>(null);

//   useEffect(() => {
//     // Get the total number of documents in the collection matching the constraints
//     const allDocsPromise = getDocs(collectionRef);
//     allDocsPromise.then((allDocsQuerySnapshot) => {
//       setTotalDocuments(allDocsQuerySnapshot.size);
//       // (Optionally) calculate the initial lastDoc for the first page
//       if (page === 1 && allDocsQuerySnapshot.size > 0) {
//         const documents = allDocsQuerySnapshot.docs;
//         setLastDoc(documents[documents.length - 1]);
//       }
//     });
//   }, [collectionRef, page]);

//   if (page > 1 && lastDoc) {
//     dealershipQuery = query(collectionRef, ...constraints, startAfter(lastDoc));
//   }

//   // Fetch the data using reactfire's useFirestoreCollection
//   const { data } = useFirestoreCollection(dealershipQuery, { idField: 'id' });
//   if (data && data.docs) {
//     const snapshot = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
//     console.log('snapshot', snapshot);
//     return { snapshot, totalDocuments };
//   }
//   return { undefined, totalDocuments };
// }

export async function getDealershipBatchFunc(page: number, rowsPerPage: number) {
  const db = getFirestore(app);
  const collectionRef = collection(db, 'dealerships') as CollectionReference<Dealership>;
  const allQuery = query(collectionRef, where('status', '==', 'registered'));

  // Get the total number of documents in the collection matching the constraints
  const allDocsQuerySnapshot = await getDocs(allQuery);
  const totalDocuments = allDocsQuerySnapshot.size;

  // Calculate the starting index of the current page (pageIndex)
  const pageIndex = page - 1;
  const startIndex = pageIndex * rowsPerPage;

  // Fetch the data for the current page
  const constraints = [
    where('status', '==', 'registered'),
    orderBy('dealershipNameLowerCase', 'asc'),
    limit(rowsPerPage)
  ];

  // If there are more pages and we have not reached the last document
  if (pageIndex > 0 && startIndex < totalDocuments) {
    const startAfterDocSnapshot = allDocsQuerySnapshot.docs[startIndex - 1];
    constraints.push(startAfter(startAfterDocSnapshot));
  }

  const dealershipQuery = query(collectionRef, ...constraints);
  const dataQuerySnapshot = await getDocs(dealershipQuery);
  const snapshot = dataQuerySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
  console.log(snapshot);
  return { snapshot, totalDocuments };
}

export async function useDealershipCollection(constraints: any[]) {
  const db = getFirestore(app);
  const collectionRef = collection(db, 'dealerships') as CollectionReference<Dealership>;
  const dealershipQuery = query(collectionRef, ...constraints);
  const collectionSize = (await getDocs(dealershipQuery)).size;
  return collectionSize;
}

export function useDealershipBatch(
  cursor: number,
  constraints: any[],
  lastDoc?: DocumentSnapshot,
  isBackPage?: boolean,
  firstDoc?: DocumentSnapshot
) {
  const db = getFirestore(app);
  const collectionRef = collection(db, 'dealerships') as CollectionReference<Dealership>;

  if (cursor > 1) {
    if (isBackPage) {
      constraints.push(startAt(firstDoc));
    } else {
      constraints.push(startAfter(lastDoc));
    }
  }

  const dealershipQuery = query(collectionRef, ...constraints);
  return useFirestoreCollection(dealershipQuery, { idField: 'id' });
}
