import { ReactNode } from 'react';
import { Container, Alert, AlertTitle } from '@mui/material';
import useAuth from 'hooks/useAuth';

type CaptureRepGuardProps = {
  children: ReactNode | string;
};

export default function CaptureRepGuard({ children }: CaptureRepGuardProps) {
  const { currentUser } = useAuth();

  if (currentUser.currentDealershipRole.toLowerCase() === 'capturerep') {
    return (
      <Container>
        <Alert severity="error">
          <AlertTitle>Permission Denied</AlertTitle>
          You do not have permission to access this page
        </Alert>
      </Container>
    );
  }

  return <>{children}</>;
}
