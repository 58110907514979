import { Typography, Divider, Box, useTheme, Collapse } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

const useStyles = makeStyles({
  accordion: {
    boxShadow: 'none !important',
    backgroundColor: 'transparent', // theme.palette.common.white,
    border: 'none',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    padding: '1px 10px'
  }
});

type CustomAccordionType = {
  title: string;
  expanded: boolean;
  handleExpanded: () => void;
  children?: React.ReactNode;
};
export const CondensedAccordion = (props: CustomAccordionType) => {
  const { title, expanded, handleExpanded, children } = props;
  const theme = useTheme();

  const styles = useStyles();
  return (
    <Box className={styles.accordion}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%'
        }}
      >
        <Typography>{title}</Typography>
        <Divider
          sx={{
            flexGrow: '3',
            margin: '0px 10px',
            borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
          }}
        />
        <Box onClick={handleExpanded} sx={{ cursor: 'pointer' }}>
          {expanded ? (
            <RemoveCircleOutlineIcon />
          ) : (
            <AddCircleOutlineIcon sx={{ color: theme.palette.primary.main }} />
          )}
        </Box>
      </Box>
      <Collapse in={expanded}>{children}</Collapse>
    </Box>
  );
};
