import { Box, Typography, BoxProps, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FC, useState } from 'react';

interface Props extends BoxProps {
  label: string;
  value: number | string;
  color: string;
  toggleFloorPriceModal: () => void;
}

const ListItemBidPriceEdit: FC<Props> = ({ label, value, color, toggleFloorPriceModal }: Props) => {
  const theme = useTheme();

  return (
    <Tooltip title="Click To Edit Floor Price">
      <Box sx={{ padding: '5px', cursor: 'pointer' }} onClick={() => toggleFloorPriceModal()}>
        <Typography
          sx={{
            color: `${color}`,
            fontWeight: '700',
            fontSize: '14px'
          }}
        >
          {value}
        </Typography>
        <Typography
          sx={{
            fontSize: '12px',
            textTransform: 'capitalize',
            color: theme.palette.text.secondary
          }}
        >
          {label}
        </Typography>
      </Box>
    </Tooltip>
  );
};

export default ListItemBidPriceEdit;
