import { Box, Typography, BoxProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FC } from 'react';

interface Props extends BoxProps {
  label: string;
  value: string;
  color: string;
}

const ListItemNoPrice: FC<Props> = ({ label, value, color }: Props) => {
  const theme = useTheme();

  return (
    <Box sx={{ padding: '5px' }}>
      <Typography
        sx={{
          color: `${color}`,
          fontWeight: '700',
          fontSize: '14px'
        }}
      >
        {value}
      </Typography>
      <Typography
        sx={{
          fontSize: '12px',
          textTransform: 'capitalize',
          color: theme.palette.text.secondary
        }}
      >
        {label}
      </Typography>
    </Box>
  );
};

export default ListItemNoPrice;
