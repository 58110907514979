import { Box, Typography, BoxProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FC } from 'react';

interface Props extends BoxProps {
  label: string;
  value: string;
  color: string;
}

const ListItemStatusPill: FC<Props> = ({ label, value, color }: Props) => {
  const theme = useTheme();

  return (
    <Box sx={{ padding: '0px' }}>
      <Typography
        sx={{
          fontSize: '12px',
          backgroundColor: `${color}`,
          padding: '0px 15px',
          width: 'fit-content',
          borderRadius: '15px',
          color: theme.palette.common.white,
          fontWeight: '700',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {value}
      </Typography>
      <Typography
        sx={{
          fontSize: '12px',
          color: theme.palette.text.secondary,
          textTransform: 'capitalize'
        }}
      >
        {label}
      </Typography>
    </Box>
  );
};

export default ListItemStatusPill;
