import {
  Grid,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell
} from '@mui/material';
import { makeStyles, withStyles, createStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import clsx from 'clsx';
import { Dealership } from 'types/dealership';
import useAuth from 'hooks/useAuth';
import { User } from 'constants/user';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { CollectionReference, collection, query, where } from 'firebase/firestore';

type Props = {
  dealership: Dealership;
};

const DrawerDealerInformation = ({ dealership }: Props) => {
  const theme = useTheme();
  const { currentUser } = useAuth();

  const firestore = useFirestore();
  const usersRef = collection(firestore, 'users') as CollectionReference<User>;
  const userQuery = query(usersRef, where('email', '==', dealership.primaryContactEmail));
  const { data: users } = useFirestoreCollectionData(userQuery, { idField: 'id' });

  const StyledTableRow = withStyles(() =>
    createStyles({
      root: {
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.background.paper
        },
        '&:nth-of-type(even)': {
          backgroundColor: 'transparent !important'
        }
      }
    })
  )(TableRow);

  const useStyles = makeStyles({
    subtitle: {
      fontWeight: 400,
      color: theme.palette.text.secondary,
      textTransform: 'capitalize'
    },
    tableCell: {
      padding: '10px !important',
      color: theme.palette.info.light,
      fontSize: '14px'
    },
    tableCellSmall: {
      fontSize: '12px',
      fontWeight: 700,
      textAlign: 'right',
      textTransform: 'capitalize'
    }
  });

  const styles = useStyles();

  return (
    <Grid
      container
      sx={{
        color: theme.palette.secondary.darker,
        height: '100%'
        // minHeight: '800px'
      }}
    >
      <Grid
        sx={{
          m: '19px'
          // height: '130px'
        }}
      >
        <Typography
          variant="subtitle1"
          component="h2"
          paragraph
          sx={{
            fontWeight: 700,
            textTransform: 'capitalize',
            color:
              theme.palette.mode === 'dark'
                ? theme.palette.common.white
                : theme.palette.text.primary
          }}
        >
          {dealership.dealershipName}
        </Typography>
        <Typography
          variant="subtitle2"
          component="h3"
          sx={{
            fontWeight: 700,
            mb: '10px',
            color:
              theme.palette.mode === 'dark'
                ? theme.palette.common.white
                : theme.palette.text.primary
          }}
        >
          Primary Contact
        </Typography>
        <Grid xs={12}>
          <Typography variant="subtitle2" component="h4" className={styles.subtitle}>
            Name: {dealership.primaryContact}
          </Typography>
        </Grid>
        {dealership.primaryContactEmail && (
          <Grid xs={12}>
            <Typography variant="subtitle2" component="h4" className={styles.subtitle}>
              Email: {dealership.primaryContactEmail}
            </Typography>
          </Grid>
        )}
        <Grid xs={12}>
          <Typography variant="subtitle2" component="h4" className={styles.subtitle}>
            Mobile: {dealership.phone}
          </Typography>
        </Grid>
        <Grid xs={12}>
          {/* prettier-ignore */}
          <Typography variant="subtitle2" component="h4" className={styles.subtitle}>
            Individual OMVIC License: {dealership.primaryContactOmvic ? dealership.primaryContactOmvic : users && users.length > 0 ? users[0].omvicNumber : `No OMVIC License`}
          </Typography>
        </Grid>
        {/* <Grid xs={12}>
          <Typography variant="subtitle2" component="h4" className={styles.subtitle}>
            Signature: ??
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Typography variant="subtitle2" component="h4" className={styles.subtitle}>
            Language: English
          </Typography>
        </Grid> */}
        <Typography
          variant="subtitle2"
          component="h3"
          sx={{
            fontWeight: 700,
            mt: '15px',
            color:
              theme.palette.mode === 'dark'
                ? theme.palette.common.white
                : theme.palette.text.primary
          }}
        >
          Dealership Information
        </Typography>
      </Grid>
      <TableContainer>
        <Table>
          <TableBody>
            <StyledTableRow>
              <TableCell className={styles.tableCell}>Legal Name of Dealership</TableCell>
              <TableCell className={clsx(styles.tableCell, styles.tableCellSmall)}>
                {dealership.legalName}
              </TableCell>
            </StyledTableRow>
            <StyledTableRow>
              <TableCell className={styles.tableCell}>Dealership Name or O/A</TableCell>
              <TableCell className={clsx(styles.tableCell, styles.tableCellSmall)}>
                {dealership.dealershipName}
              </TableCell>
            </StyledTableRow>
            <StyledTableRow>
              <TableCell className={styles.tableCell}>Street Address</TableCell>
              <TableCell className={clsx(styles.tableCell, styles.tableCellSmall)}>
                {dealership.streetAddress}
              </TableCell>
            </StyledTableRow>
            <StyledTableRow>
              <TableCell className={styles.tableCell}>City</TableCell>
              <TableCell className={clsx(styles.tableCell, styles.tableCellSmall)}>
                {dealership.city}
              </TableCell>
            </StyledTableRow>
            <StyledTableRow>
              <TableCell className={styles.tableCell}>Province/State</TableCell>
              <TableCell className={clsx(styles.tableCell, styles.tableCellSmall)}>
                {dealership.province}
              </TableCell>
            </StyledTableRow>
            <StyledTableRow>
              <TableCell className={styles.tableCell}>Postal/Zip</TableCell>
              <TableCell className={clsx(styles.tableCell, styles.tableCellSmall)}>
                {dealership.postalCode}
              </TableCell>
            </StyledTableRow>
            <StyledTableRow>
              <TableCell className={styles.tableCell}>Country</TableCell>
              <TableCell className={clsx(styles.tableCell, styles.tableCellSmall)}>
                {dealership.country}
              </TableCell>
            </StyledTableRow>
            <StyledTableRow>
              <TableCell className={styles.tableCell}>Dealer Primary Number</TableCell>
              <TableCell className={clsx(styles.tableCell, styles.tableCellSmall)}>
                {dealership.phone}
              </TableCell>
            </StyledTableRow>
            <StyledTableRow>
              <TableCell className={styles.tableCell}>RIN #</TableCell>
              <TableCell className={clsx(styles.tableCell, styles.tableCellSmall)}>
                {dealership.rinNumber ? dealership.rinNumber : 'N/A'}
              </TableCell>
            </StyledTableRow>
            {currentUser && currentUser.currentDealershipRole === 'superAdmin' && (
              <>
                <StyledTableRow>
                  <TableCell className={styles.tableCell}>HST #</TableCell>
                  <TableCell className={clsx(styles.tableCell, styles.tableCellSmall)}>
                    {dealership.hstNumber ? dealership.hstNumber : 'N/A'}
                  </TableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <TableCell className={styles.tableCell}>Dealer/OMVIC/MVDA #</TableCell>
                  <TableCell className={clsx(styles.tableCell, styles.tableCellSmall)}>
                    {dealership.omvicNumber}
                  </TableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <TableCell className={styles.tableCell}>Auction Access: Registration #</TableCell>
                  <TableCell className={clsx(styles.tableCell, styles.tableCellSmall)}>
                    {dealership.dealershipRegistration ? dealership.dealershipRegistration : 'N/A'}
                  </TableCell>
                </StyledTableRow>
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default DrawerDealerInformation;
