import {
  Container,
  Box,
  useTheme,
  IconButton,
  Paper,
  Typography,
  SelectChangeEvent,
  Divider,
  Tabs,
  Tab,
  Alert,
  AlertTitle,
  Tooltip,
  Button
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { makeStyles } from '@mui/styles';
import Page from '../../../components/Page';
import React, { useEffect, useRef, useState, useMemo } from 'react';
import AuctionFilters from 'components/auction/AuctionSidebarFilters';
import useSettings from 'hooks/useSettings';
import { DebounceInput } from 'react-debounce-input';
import AuctionDrawer from 'components/auction/drawer/AuctionDrawer';
import { styled } from '@mui/material/styles';
import { VehicleInfo } from 'types/vehicleInfo';
import {
  doc,
  DocumentReference,
  getDoc,
  onSnapshot,
  orderBy,
  where,
  limit,
  collection,
  CollectionReference,
  query
} from 'firebase/firestore';
import { useFirestore } from 'reactfire';
import useAuth from 'hooks/useAuth';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import Tour from 'reactour';
import { User } from 'constants/user';
import { useFirestoreByPath } from 'hooks/useFirestoreByPath';
import EmptyStateMessage from 'components/shared/EmptyStateMessage';
import { Dealership } from 'types/dealership';
import { isMobile } from 'react-device-detect';
import TabsPageBuy from './components/TabsPagesBuy';
import { useNavigate } from 'react-router-dom';
import { useVehicleBatch } from 'utils/vehicleQuery';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface StyledTabsProps {
  id?: string;
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`activeAuction-tabpanel-${index}`}
      aria-labelledby={`activeAuction-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `activeAuction-tab-${index}`,
    'aria-controls': `activeAuction-tabpanel-${index}`
  };
};

export default function AuctionPageBuy() {
  const theme = useTheme();
  const firestore = useFirestore();
  const { currentUser, user, logout } = useAuth();
  const { themeStretch } = useSettings();
  const [openSidebar, setOpenSidebar] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [isFiltered, setIsFiltered] = useState<boolean>(false);
  const [filterChange, setFilterChange] = useState<boolean>(false);
  const [drawerTab, setDrawerTab] = useState<number>(0);
  const [vehicle, setVehicle] = useState<VehicleInfo>();
  let [filters, setFilters] = useState<any>([{}]);
  const [mediaQuery, setMediaQuery] = useState(1450);
  const [tabValue, setTabValue] = useState(0);
  const [tourOpen, setTourOpen] = useState(false);
  const { update: updateUser } = useFirestoreByPath<User>('users');
  const [currentDealership, setCurrentDealership] = useState<Dealership>();

  const [vehicles, setVehicles] = useState<VehicleInfo[]>();
  const [instockVehicles, setInstockVehicles] = useState<VehicleInfo[]>();
  const [appraisalVehicles, setAppraisalVehicles] = useState<VehicleInfo[]>();
  const [winningVehicles, setWinningVehicles] = useState<VehicleInfo[]>();
  const [losingVehicles, setLosingVehicles] = useState<VehicleInfo[]>();
  const [autobidVehicles, setAutobidVehicles] = useState<VehicleInfo[]>();
  const [watchlistVehicles, setWatchlistVehicles] = useState<VehicleInfo[]>();

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      snapshotUser();
      getCurrentDealership();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  // Refs to tabs
  const vehiclesViewRef = useRef(null);
  const vehiclesPinViewRef = useRef(null);
  const instockViewRef = useRef(null);
  const instockPinViewRef = useRef(null);
  const appraisalsViewRef = useRef(null);
  const appraisalsPinViewRef = useRef(null);
  const winningViewRef = useRef(null);
  const winningPinViewRef = useRef(null);
  const losingViewRef = useRef(null);
  const losingPinViewRef = useRef(null);
  const autobidViewRef = useRef(null);
  const autobidPinViewRef = useRef(null);
  const watchlistViewRef = useRef(null);
  const watchlistPinViewRef = useRef(null);
  const filteredViewRef = useRef(null);
  const filteredPinViewRef = useRef(null);

  //----------------------

  const getCurrentDealership = async () => {
    const userDealershipRef = doc(firestore, `dealerships/${currentUser.currentDealershipId}`);
    const userDealerSnap = await getDoc(userDealershipRef);
    if (userDealerSnap.exists()) {
      const data = userDealerSnap.data();
      setCurrentDealership({ ...data, id: userDealerSnap.id } as Dealership);
    }
  };

  const [userDeleted, setUserDeleted] = useState<boolean>(false);
  const [userSuspended, setUserSuspended] = useState<boolean>(false);
  const [userDisabled, setUserDisabled] = useState<boolean>(false);
  const [curUser, setCurUser] = useState<User>();

  const snapshotUser = () => {
    const userDoc = doc(firestore, `users/${user?.uid}`) as DocumentReference<User>;
    onSnapshot(userDoc, (doc: any) => {
      const userObj = { ...doc.data(), id: doc.id } as User;
      setCurUser(userObj);
      if (!userObj.email) {
        logout?.().then(() => {
          navigate('.');
          window.location.reload();
        });
      }
      if (userObj.showActiveAuctionBuyTour && !tourOpen) {
        setTourOpen(true);
      }
      if (userObj && userObj.status.toLowerCase() === 'suspended') {
        setUserSuspended(true);
      } else {
        setUserSuspended(false);
      }
      if (userObj && userObj.status.toLowerCase() === 'disabled') {
        setUserDisabled(true);
      } else {
        setUserDisabled(false);
      }
    });
  };

  const handleCloseTour = async () => {
    await updateUser(user?.uid as string, { showActiveAuctionBuyTour: false });
    setTourOpen(false);
  };

  const steps = [
    {
      selector: '',
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Buy: Active Auction</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
              Active Auctions is where you will bid on live vehicles.
            </Typography>
          </Box>
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
              Remember, only vehicles from sellers in your 'buying network' will appear in your active auctions. 
            </Typography>
          </Box>
        </Box>
      )
    },
    {
      selector: '#activeAuction2',
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Buy: Active Auction</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
              <span style={{ fontWeight: '900' }}>FILTERS</span>
            </Typography>
          </Box>
          {/* prettier-ignore */}
          <Typography sx={{ marginTop: '10px', color: theme.palette.text.secondary }}>
            Filter the list of vehicles to find exactly what you are looking for.
          </Typography>
        </Box>
      )
    },
    {
      selector: '#activeAuctionBuy3',
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Buy: Active Auction</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
              <span style={{ fontWeight: '900' }}>AUCTION TABS</span>
            </Typography>
          </Box>
          {/* prettier-ignore */}
          <Typography sx={{ marginTop: '10px', color: theme.palette.text.secondary }}>
            The auction tabs keep the vehicles you have access to organized into helpful categories.
          </Typography>
        </Box>
      )
    },
    {
      selector: '#activeAuctionBuy4',
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Buy: Active Auction</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
              <span style={{ fontWeight: '900' }}>SEARCH</span>
            </Typography>
          </Box>
          {/* prettier-ignore */}
          <Typography sx={{ marginTop: '10px', color: theme.palette.text.secondary }}>
            Use the versatile search feature to quickly find the vehicle you need.
          </Typography>
        </Box>
      )
    },
    {
      selector: '#activeAuctionBuy9',
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Buy: Active Auction</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          {/* prettier-ignore */}
          <Typography sx={{ marginTop: '10px', color: theme.palette.text.secondary }}>
            Not enough room? You can close the filter menu using this button.
          </Typography>
        </Box>
      )
    },
    {
      selector: '#activeAuctionBuy7',
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Buy: Active Auction</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
              <span style={{ fontWeight: '900' }}>VEHICLES</span>
            </Typography>
          </Box>
          {/* prettier-ignore */}
          <Typography sx={{ marginTop: '10px', color: theme.palette.text.secondary }}>
            The Bid Box is extremely versatile. 
          </Typography>
          {/* prettier-ignore */}
          <Typography sx={{ marginTop: '10px', color: theme.palette.text.secondary }}>
            Enter a starting bid.
          </Typography>
          {/* prettier-ignore */}
          <Typography sx={{ marginTop: '10px', color: theme.palette.text.secondary }}>
            Use our Bid button  which is defaulted at 200$ increments.
          </Typography>
          <Typography sx={{ marginTop: '10px', color: theme.palette.text.secondary }}>
            Or enter manual bids by clicking on the + icon.
          </Typography>
          <Typography sx={{ marginTop: '10px', color: theme.palette.text.secondary }}>
            Autobids can be set, edited and deleted during both Pre-Auction and Active Auction.
          </Typography>
        </Box>
      )
    },
    {
      selector: '#activeAuction6',
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Buy: Active Auction</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          {/* prettier-ignore */}
          <Typography sx={{ marginTop: '10px', color: theme.palette.text.secondary }}>
            Expand and condense individual vehicle boxes using the arrows.
          </Typography>
          {/* prettier-ignore */}
          <Typography sx={{ marginTop: '10px', color: theme.palette.text.secondary }}>
            You can also pin priority vehicles during an auction so you don't have to scroll the entire list to find them. The unpinned vehicles will remain scrollable in the unpinned section underneath.
          </Typography>
        </Box>
      )
    },
    // {
    //   selector: '#activeAuctionBuy7',
    //   content: () => (
    //     <Box>
    //       <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
    //         <Typography variant="h5">Buy: Active Auction</Typography>
    //       </Box>
    //       <Divider
    //         sx={{
    //           borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
    //         }}
    //       />
    //       {/* prettier-ignore */}
    //       <Typography sx={{ marginTop: '10px', color: theme.palette.text.secondary }}>
    //         The bidding box is extremely versatile. It has starting bid functionality which defaults to $200.00 over the last bid, manual bids can alos be submitted by clicking on the + icon.
    //       </Typography>
    //       {/* prettier-ignore */}
    //       <Typography sx={{ marginTop: '10px', color: theme.palette.text.secondary }}>
    //         Autobids can be set on the fly as well as deleted in order to move to manual bid capability for a higher level of control.
    //       </Typography>
    //     </Box>
    //   )
    // },
    {
      selector: '#activeAuction8',
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Buy: Active Auction</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          {/* prettier-ignore */}
          <Typography sx={{ marginTop: '10px', color: theme.palette.text.secondary }}>
            Click these individual icons to access information drawers for all car details. Important vehicle information such as equipment, damages, and disclosure will be found here. 
          </Typography>
        </Box>
      )
    }
    // {
    //   selector: '#activeAuction10',
    //   content: () => (
    //     <Box>
    //       <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
    //         <Typography variant="h5">Buy: Active Auction</Typography>
    //       </Box>
    //       <Divider
    //         sx={{
    //           borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
    //         }}
    //       />
    //       {/* prettier-ignore */}
    //       <Typography sx={{ marginTop: '10px', color: theme.palette.text.secondary }}>
    //         You can also collapse and expand this menu to create additional space to work.
    //       </Typography>
    //     </Box>
    //   )
    // }
  ];

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleFilterChange = (values: any) => {
    const filtersToUpdate: any[] = [];
    Object.keys(values).forEach((key: any) => {
      if (values[key]) {
        filtersToUpdate.push({ name: key, value: values[key] });
      }
    });
    setFilters(filtersToUpdate);
    setIsFiltered(true);
    setFilterChange(!filterChange);
  };

  const handleToggleSidebar = (value: boolean) => {
    setOpenSidebar(!value);
    handleChangeMediaQueryValue();
  };

  const handleChangeMediaQueryValue = () => {
    if (!openSidebar) {
      setMediaQuery(1450);
    } else {
      setMediaQuery(1150);
    }
  };

  const handleToggleDrawer = (value: number, vehicle: VehicleInfo) => {
    setDrawerTab(value);
    setVehicle(vehicle);
    setOpenDrawer(!openDrawer);
  };

  const handleFormReset = () => {
    setIsFiltered(false);
    setFilters([{}]);
    setFilteredVehicles([]);
  };

  // ALL ACTIVE
  //---------------------------------------------------------------------------------------
  const [order, setOrder] = useState('timeAsc');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [expandedPinned, setExpandedPinned] = useState(true);
  const [expandedAll, setExpandedAll] = useState(true);
  const [lastDocArray, setLastDocArray] = useState<any[]>([]);
  const [firstDocArray, setFirstDocArray] = useState<any[]>([]);
  const [backPage, setBackPage] = useState<boolean>(false);
  const [lastDataVehicleRef, setLastDataVehicleRef] = useState<DocumentReference>();
  const [collectionSize, setCollectionSize] = useState<number>(0);

  const queryConstraints = [
    where('networkDealerships', 'array-contains', currentUser.currentDealershipId),
    where('auction.auctionStatus', '==', 'active'),
    orderBy('auction.endDate', 'asc'),
    orderBy('auction.endTime', 'asc'),
    limit(rowsPerPage)
  ];

  const { data } = useVehicleBatch(
    page,
    queryConstraints,
    lastDocArray[page - 1],
    backPage,
    firstDocArray[page]
  );

  const allVehicles = useMemo(() => {
    const lastDoc = data?.docs[data?.docs?.length - 1];
    if (lastDoc) {
      const lastDocRef = data?.docs[data?.docs?.length - 1].ref as DocumentReference;
      setLastDataVehicleRef(lastDocRef);
    }
    // prettier-ignore
    return data?.docs?.map((doc) => ({ ...doc.data(), id: doc.id }));
  }, [data]);

  // useEffect(() => {
  //   setVehicles(allVehicles);
  // }, [allVehicles]);

  useEffect(() => {
    if (isFiltered) {
      const filteredVehicles = filterVehicles(allVehicles);
      setVehicles(filteredVehicles);
    } else {
      setVehicles(allVehicles);
    }
  }, [allVehicles, isFiltered]);

  const countVehicleCollection = () => {
    const vehicleRef = collection(firestore, 'vehicles') as CollectionReference;
    const vehicleQuery = query(
      vehicleRef,
      where('networkDealerships', 'array-contains', currentUser.currentDealershipId),
      where('auction.auctionStatus', '==', 'active')
    );
    const unsubsribe = onSnapshot(vehicleQuery, (querySnapshot) => {
      setCollectionSize(querySnapshot.size);
    });
    return unsubsribe;
  };

  useEffect(() => {
    const unsubscribe = countVehicleCollection();
    return () => {
      unsubscribe();
    };
  }, []);

  const handleChangePage = async (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      const firstDoc = data?.docs[0];
      const firstDocRef = firstDoc.ref as DocumentReference;
      const docRef = doc(firestore, `vehicles/${firstDocRef.id}`);
      const docSnap = await getDoc(docRef);
      const tempFirstDocs = firstDocArray;
      if (docSnap.exists()) {
        tempFirstDocs.push(docSnap);
        setFirstDocArray(tempFirstDocs);
      }
      if (lastDataVehicleRef) {
        const docRef = doc(firestore, `vehicles/${lastDataVehicleRef.id}`);
        const docSnap = await getDoc(docRef);
        const tempLastDocs = lastDocArray;
        if (docSnap.exists()) {
          tempLastDocs.push(docSnap);
          setLastDocArray(tempLastDocs);
          setPage(newPage);
          setBackPage(false);
        }
      }
    } else {
      setPage(newPage);
      setBackPage(true);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setFirstDocArray([]);
    setLastDocArray([]);
    setBackPage(false);
  };

  const handleOrderByChange = (event: SelectChangeEvent) => {
    setOrder(event.target.value);
  };

  const handleExpandedPinnedChange = () => {
    setExpandedPinned(!expandedPinned);
  };

  const handleExpandedAllChange = () => {
    setExpandedAll(!expandedAll);
  };

  // IN STOCK
  //---------------------------------------------------------------------------------------
  const [orderInStock, setOrderInStock] = useState('timeAsc');
  const [pageInStock, setPageInStock] = useState(0);
  const [rowsPerPageInStock, setRowsPerPageInStock] = useState(25);
  const [expandedPinnedInStock, setExpandedPinnedInStock] = useState(true);
  const [expandedAllInStock, setExpandedAllInStock] = useState(true);
  const [lastDocArrayInStock, setLastDocArrayInStock] = useState<any[]>([]);
  const [firstDocArrayInStock, setFirstDocArrayInStock] = useState<any[]>([]);
  const [backPageInStock, setBackPageInStock] = useState<boolean>(false);
  const [lastDataVehicleRefInStock, setLastDataVehicleRefInStock] = useState<DocumentReference>();
  const [collectionSizeInStock, setCollectionSizeInStock] = useState<number>(0);

  const queryConstraintsInStock = [
    where('networkDealerships', 'array-contains', currentUser.currentDealershipId),
    where('auction.auctionStatus', '==', 'active'),
    where('auction.isInStock', '==', true),
    orderBy('auction.endDate', 'asc'),
    orderBy('auction.endTime', 'asc'),
    limit(rowsPerPageInStock)
  ];

  const inStockData = useVehicleBatch(
    pageInStock,
    queryConstraintsInStock,
    lastDocArrayInStock[pageInStock - 1],
    backPageInStock,
    firstDocArrayInStock[pageInStock]
  );

  const allInstockVehicles = useMemo(() => {
    const lastDoc = inStockData?.data?.docs[inStockData?.data?.docs?.length - 1];
    if (lastDoc) {
      // prettier-ignore
      const lastDocRef = inStockData?.data?.docs[inStockData?.data?.docs?.length - 1].ref as DocumentReference;
      setLastDataVehicleRefInStock(lastDocRef);
    }
    // prettier-ignore
    return inStockData?.data?.docs?.map((doc) => ({ ...doc.data(), id: doc.id }));
  }, [inStockData.data]);

  // useEffect(() => {
  //   setInstockVehicles(allInstockVehicles);
  // }, [allInstockVehicles]);

  useEffect(() => {
    if (isFiltered) {
      const filteredInstockVehicles = filterVehicles(allInstockVehicles);
      setInstockVehicles(filteredInstockVehicles);
    } else {
      setInstockVehicles(allInstockVehicles);
    }
  }, [allInstockVehicles, isFiltered]);

  const countVehicleCollectionInStock = () => {
    const vehicleRef = collection(firestore, 'vehicles') as CollectionReference;
    const vehicleQuery = query(
      vehicleRef,
      where('networkDealerships', 'array-contains', currentUser.currentDealershipId),
      where('auction.auctionStatus', '==', 'active'),
      where('auction.isInStock', '==', true)
    );
    const unsubsribe = onSnapshot(vehicleQuery, (querySnapshot) => {
      setCollectionSizeInStock(querySnapshot.size);
    });
    return unsubsribe;
  };

  useEffect(() => {
    const unsubscribe = countVehicleCollectionInStock();
    return () => {
      unsubscribe();
    };
  }, []);

  const handleChangePageInStock = async (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > pageInStock) {
      const firstDoc = inStockData?.data?.docs[0];
      const firstDocRef = firstDoc.ref as DocumentReference;
      const docRef = doc(firestore, `vehicles/${firstDocRef.id}`);
      const docSnap = await getDoc(docRef);
      const tempFirstDocs = firstDocArrayInStock;
      if (docSnap.exists()) {
        tempFirstDocs.push(docSnap);
        setFirstDocArrayInStock(tempFirstDocs);
      }
      if (lastDataVehicleRefInStock) {
        const docRef = doc(firestore, `vehicles/${lastDataVehicleRefInStock.id}`);
        const docSnap = await getDoc(docRef);
        const tempLastDocs = lastDocArrayInStock;
        if (docSnap.exists()) {
          tempLastDocs.push(docSnap);
          setLastDocArrayInStock(tempLastDocs);
          setPageInStock(newPage);
          setBackPageInStock(false);
        }
      }
    } else {
      setPageInStock(newPage);
      setBackPageInStock(true);
    }
  };

  const handleChangeRowsPerPageInStock = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPageInStock(parseInt(event.target.value, 10));
    setPageInStock(0);
    setFirstDocArrayInStock([]);
    setLastDocArrayInStock([]);
    setBackPageInStock(false);
  };

  const handleOrderByChangeInStock = (event: SelectChangeEvent) => {
    setOrderInStock(event.target.value);
  };

  const handleExpandedPinnedChangeInStock = () => {
    setExpandedPinnedInStock(!expandedPinnedInStock);
  };

  const handleExpandedAllChangeInStock = () => {
    setExpandedAllInStock(!expandedAllInStock);
  };

  // APPRAISALS
  //---------------------------------------------------------------------------------------
  const [orderAppraisals, setOrderAppraisals] = useState('timeAsc');
  const [pageAppraisals, setPageAppraisals] = useState(0);
  const [rowsPerPageAppraisals, setRowsPerPageAppraisals] = useState(25);
  const [expandedPinnedAppraisals, setExpandedPinnedAppraisals] = useState(true);
  const [expandedAllAppraisals, setExpandedAllAppraisals] = useState(true);
  const [lastDocArrayAppraisals, setLastDocArrayAppraisals] = useState<any[]>([]);
  const [firstDocArrayAppraisals, setFirstDocArrayAppraisals] = useState<any[]>([]);
  const [backPageAppraisals, setBackPageAppraisals] = useState<boolean>(false);
  // prettier-ignore
  const [lastDataVehicleRefAppraisals, setLastDataVehicleRefAppraisals] = useState<DocumentReference>();
  const [collectionSizeAppraisals, setCollectionSizeAppraisals] = useState<number>(0);

  const queryConstraintsAppraisals = [
    where('networkDealerships', 'array-contains', currentUser.currentDealershipId),
    where('auction.auctionStatus', '==', 'active'),
    where('auction.isInStock', '==', false),
    orderBy('auction.endDate', 'asc'),
    orderBy('auction.endTime', 'asc'),
    limit(rowsPerPageAppraisals)
  ];

  const appraisalsData = useVehicleBatch(
    pageAppraisals,
    queryConstraintsAppraisals,
    lastDocArrayAppraisals[pageAppraisals - 1],
    backPageAppraisals,
    firstDocArrayAppraisals[pageAppraisals]
  );

  const allAppraisalVehicles = useMemo(() => {
    const lastDoc = appraisalsData?.data?.docs[appraisalsData?.data?.docs?.length - 1];
    if (lastDoc) {
      // prettier-ignore
      const lastDocRef = appraisalsData?.data?.docs[appraisalsData?.data?.docs?.length - 1].ref as DocumentReference;
      setLastDataVehicleRefAppraisals(lastDocRef);
    }
    // prettier-ignore
    return appraisalsData?.data?.docs?.map((doc) => ({ ...doc.data(), id: doc.id }));
  }, [appraisalsData.data]);

  // useEffect(() => {
  //   setAppraisalVehicles(allAppraisalVehicles);
  // }, [allAppraisalVehicles]);

  useEffect(() => {
    if (isFiltered) {
      const filteredAppraisalVehicles = filterVehicles(allAppraisalVehicles);
      setAppraisalVehicles(filteredAppraisalVehicles);
    } else {
      setAppraisalVehicles(allAppraisalVehicles);
    }
  }, [allAppraisalVehicles, isFiltered]);

  const countVehicleCollectionAppraisals = () => {
    const vehicleRef = collection(firestore, 'vehicles') as CollectionReference;
    const vehicleQuery = query(
      vehicleRef,
      where('networkDealerships', 'array-contains', currentUser.currentDealershipId),
      where('auction.auctionStatus', '==', 'active'),
      where('auction.isInStock', '==', false)
    );
    const unsubsribe = onSnapshot(vehicleQuery, (querySnapshot) => {
      setCollectionSizeAppraisals(querySnapshot.size);
    });
    return unsubsribe;
  };

  useEffect(() => {
    const unsubscribe = countVehicleCollectionAppraisals();
    return () => {
      unsubscribe();
    };
  }, []);

  const handleChangePageAppraisals = async (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > pageAppraisals) {
      const firstDoc = appraisalsData?.data?.docs[0];
      const firstDocRef = firstDoc.ref as DocumentReference;
      const docRef = doc(firestore, `vehicles/${firstDocRef.id}`);
      const docSnap = await getDoc(docRef);
      const tempFirstDocs = firstDocArrayAppraisals;
      if (docSnap.exists()) {
        tempFirstDocs.push(docSnap);
        setFirstDocArrayAppraisals(tempFirstDocs);
      }
      if (lastDataVehicleRefAppraisals) {
        const docRef = doc(firestore, `vehicles/${lastDataVehicleRefAppraisals.id}`);
        const docSnap = await getDoc(docRef);
        const tempLastDocs = lastDocArrayAppraisals;
        if (docSnap.exists()) {
          tempLastDocs.push(docSnap);
          setLastDocArrayAppraisals(tempLastDocs);
          setPageAppraisals(newPage);
          setBackPageAppraisals(false);
        }
      }
    } else {
      setPageAppraisals(newPage);
      setBackPageAppraisals(true);
    }
  };

  const handleChangeRowsPerPageAppraisals = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPageAppraisals(parseInt(event.target.value, 10));
    setPageAppraisals(0);
    setFirstDocArrayAppraisals([]);
    setLastDocArrayAppraisals([]);
    setBackPageAppraisals(false);
  };

  const handleOrderByChangeAppraisals = (event: SelectChangeEvent) => {
    setOrderAppraisals(event.target.value);
  };

  const handleExpandedPinnedChangeAppraisals = () => {
    setExpandedPinnedAppraisals(!expandedPinnedAppraisals);
  };

  const handleExpandedAllChangeAppraisals = () => {
    setExpandedAllAppraisals(!expandedAllAppraisals);
  };

  // WINNING
  //---------------------------------------------------------------------------------------
  const [orderWinning, setOrderWinning] = useState('timeAsc');
  const [pageWinning, setPageWinning] = useState(0);
  const [rowsPerPageWinning, setRowsPerPageWinning] = useState(25);
  const [expandedPinnedWinning, setExpandedPinnedWinning] = useState(true);
  const [expandedAllWinning, setExpandedAllWinning] = useState(true);
  const [lastDocArrayWinning, setLastDocArrayWinning] = useState<any[]>([]);
  const [firstDocArrayWinning, setFirstDocArrayWinning] = useState<any[]>([]);
  const [backPageWinning, setBackPageWinning] = useState<boolean>(false);
  // prettier-ignore
  const [lastDataVehicleRefWinning, setLastDataVehicleRefWinning] = useState<DocumentReference>();
  const [collectionSizeWinning, setCollectionSizeWinning] = useState<number>(0);

  const queryConstraintsWinning = [
    where('networkDealerships', 'array-contains', currentUser.currentDealershipId),
    where('auction.auctionStatus', '==', 'active'),
    where('auction.bidLeaderId', '==', user?.uid),
    orderBy('auction.endDate', 'asc'),
    orderBy('auction.endTime', 'asc'),
    limit(rowsPerPageWinning)
  ];

  const winningData = useVehicleBatch(
    pageWinning,
    queryConstraintsWinning,
    lastDocArrayWinning[pageWinning - 1],
    backPageWinning,
    firstDocArrayWinning[pageWinning]
  );

  const allWinningVehicles = useMemo(() => {
    const lastDoc = winningData?.data?.docs[winningData?.data?.docs?.length - 1];
    if (lastDoc) {
      // prettier-ignore
      const lastDocRef = winningData?.data?.docs[winningData?.data?.docs?.length - 1].ref as DocumentReference;
      setLastDataVehicleRefWinning(lastDocRef);
    }
    // prettier-ignore
    return winningData?.data?.docs?.map((doc) => ({ ...doc.data(), id: doc.id }));
  }, [winningData.data]);

  // useEffect(() => {
  //   setWinningVehicles(allWinningVehicles);
  // }, [allWinningVehicles]);

  useEffect(() => {
    if (isFiltered) {
      const filteredWinningVehicles = filterVehicles(allWinningVehicles);
      setWinningVehicles(filteredWinningVehicles);
    } else {
      setWinningVehicles(allWinningVehicles);
    }
  }, [allWinningVehicles, isFiltered]);

  const countVehicleCollectionWinning = () => {
    const vehicleRef = collection(firestore, 'vehicles') as CollectionReference;
    const vehicleQuery = query(
      vehicleRef,
      where('networkDealerships', 'array-contains', currentUser.currentDealershipId),
      where('auction.auctionStatus', '==', 'active'),
      where('auction.bidLeaderId', '==', user?.uid)
    );
    const unsubsribe = onSnapshot(vehicleQuery, (querySnapshot) => {
      setCollectionSizeWinning(querySnapshot.size);
    });
    return unsubsribe;
  };

  useEffect(() => {
    const unsubscribe = countVehicleCollectionWinning();
    return () => {
      unsubscribe();
    };
  }, []);

  const handleChangePageWinning = async (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > pageWinning) {
      const firstDoc = winningData?.data?.docs[0];
      const firstDocRef = firstDoc.ref as DocumentReference;
      const docRef = doc(firestore, `vehicles/${firstDocRef.id}`);
      const docSnap = await getDoc(docRef);
      const tempFirstDocs = firstDocArrayWinning;
      if (docSnap.exists()) {
        tempFirstDocs.push(docSnap);
        setFirstDocArrayWinning(tempFirstDocs);
      }
      if (lastDataVehicleRefWinning) {
        const docRef = doc(firestore, `vehicles/${lastDataVehicleRefWinning.id}`);
        const docSnap = await getDoc(docRef);
        const tempLastDocs = lastDocArrayWinning;
        if (docSnap.exists()) {
          tempLastDocs.push(docSnap);
          setLastDocArrayWinning(tempLastDocs);
          setPageWinning(newPage);
          setBackPageWinning(false);
        }
      }
    } else {
      setPageWinning(newPage);
      setBackPageWinning(true);
    }
  };

  const handleChangeRowsPerPageWinning = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPageWinning(parseInt(event.target.value, 10));
    setPageWinning(0);
    setFirstDocArrayWinning([]);
    setLastDocArrayWinning([]);
    setBackPageWinning(false);
  };

  const handleOrderByChangeWinning = (event: SelectChangeEvent) => {
    setOrderWinning(event.target.value);
  };

  const handleExpandedPinnedChangeWinning = () => {
    setExpandedPinnedWinning(!expandedPinnedWinning);
  };

  const handleExpandedAllChangeWinning = () => {
    setExpandedAllWinning(!expandedAllWinning);
  };

  // LOSING
  //---------------------------------------------------------------------------------------
  const [orderLosing, setOrderLosing] = useState('timeAsc');
  const [pageLosing, setPageLosing] = useState(0);
  const [rowsPerPageLosing, setRowsPerPageLosing] = useState(25);
  const [expandedPinnedLosing, setExpandedPinnedLosing] = useState(true);
  const [expandedAllLosing, setExpandedAllLosing] = useState(true);
  const [lastDocArrayLosing, setLastDocArrayLosing] = useState<any[]>([]);
  const [firstDocArrayLosing, setFirstDocArrayLosing] = useState<any[]>([]);
  const [backPageLosing, setBackPageLosing] = useState<boolean>(false);
  // prettier-ignore
  const [lastDataVehicleRefLosing, setLastDataVehicleRefLosing] = useState<DocumentReference>();
  const [collectionSizeLosing, setCollectionSizeLosing] = useState<number>(0);

  const queryConstraintsLosing = [
    // where('networkDealerships', 'array-contains', currentUser.currentDealershipId),
    where('auction.previousBidders', 'array-contains', user?.uid),
    where('auction.auctionStatus', '==', 'active'),
    where('auction.bidLeaderId', '!=', user?.uid),
    orderBy('auction.bidLeaderId'),
    orderBy('auction.endDate', 'asc'),
    orderBy('auction.endTime', 'asc'),
    limit(rowsPerPageLosing)
  ];

  const losingData = useVehicleBatch(
    pageLosing,
    queryConstraintsLosing,
    lastDocArrayLosing[pageLosing - 1],
    backPageLosing,
    firstDocArrayLosing[pageLosing]
  );

  const allLosingVehicles = useMemo(() => {
    const lastDoc = losingData?.data?.docs[losingData?.data?.docs?.length - 1];
    if (lastDoc) {
      // prettier-ignore
      const lastDocRef = losingData?.data?.docs[losingData?.data?.docs?.length - 1].ref as DocumentReference;
      setLastDataVehicleRefLosing(lastDocRef);
    }
    // prettier-ignore
    return losingData?.data?.docs?.map((doc) => ({ ...doc.data(), id: doc.id }));
  }, [losingData.data]);

  // useEffect(() => {
  //   setLosingVehicles(allLosingVehicles);
  // }, [allLosingVehicles]);

  useEffect(() => {
    if (isFiltered) {
      const filteredLosingVehicles = filterVehicles(allLosingVehicles);
      setLosingVehicles(filteredLosingVehicles);
    } else {
      setLosingVehicles(allLosingVehicles);
    }
  }, [allLosingVehicles, isFiltered]);

  const countVehicleCollectionLosing = () => {
    const vehicleRef = collection(firestore, 'vehicles') as CollectionReference;
    const vehicleQuery = query(
      vehicleRef,
      // where('networkDealerships', 'array-contains', currentUser.currentDealershipId),
      where('auction.previousBidders', 'array-contains', user?.uid),
      where('auction.auctionStatus', '==', 'active'),
      where('auction.bidLeaderId', '!=', user?.uid),
      orderBy('auction.bidLeaderId')
    );
    const unsubsribe = onSnapshot(vehicleQuery, (querySnapshot) => {
      setCollectionSizeLosing(querySnapshot.size);
    });
    return unsubsribe;
  };

  useEffect(() => {
    const unsubscribe = countVehicleCollectionLosing();
    return () => {
      unsubscribe();
    };
  }, []);

  const handleChangePageLosing = async (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > pageLosing) {
      const firstDoc = losingData?.data?.docs[0];
      const firstDocRef = firstDoc.ref as DocumentReference;
      const docRef = doc(firestore, `vehicles/${firstDocRef.id}`);
      const docSnap = await getDoc(docRef);
      const tempFirstDocs = firstDocArrayLosing;
      if (docSnap.exists()) {
        tempFirstDocs.push(docSnap);
        setFirstDocArrayLosing(tempFirstDocs);
      }
      if (lastDataVehicleRefLosing) {
        const docRef = doc(firestore, `vehicles/${lastDataVehicleRefLosing.id}`);
        const docSnap = await getDoc(docRef);
        const tempLastDocs = lastDocArrayLosing;
        if (docSnap.exists()) {
          tempLastDocs.push(docSnap);
          setLastDocArrayLosing(tempLastDocs);
          setPageLosing(newPage);
          setBackPageLosing(false);
        }
      }
    } else {
      setPageLosing(newPage);
      setBackPageLosing(true);
    }
  };

  const handleChangeRowsPerPageLosing = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPageLosing(parseInt(event.target.value, 10));
    setPageLosing(0);
    setFirstDocArrayLosing([]);
    setLastDocArrayLosing([]);
    setBackPageLosing(false);
  };

  const handleOrderByChangeLosing = (event: SelectChangeEvent) => {
    setOrderLosing(event.target.value);
  };

  const handleExpandedPinnedChangeLosing = () => {
    setExpandedPinnedLosing(!expandedPinnedLosing);
  };

  const handleExpandedAllChangeLosing = () => {
    setExpandedAllLosing(!expandedAllLosing);
  };

  // AUTOBID
  //---------------------------------------------------------------------------------------
  const [orderAutobid, setOrderAutobid] = useState('timeAsc');
  const [pageAutobid, setPageAutobid] = useState(0);
  const [rowsPerPageAutobid, setRowsPerPageAutobid] = useState(25);
  const [expandedPinnedAutobid, setExpandedPinnedAutobid] = useState(true);
  const [expandedAllAutobid, setExpandedAllAutobid] = useState(true);
  const [lastDocArrayAutobid, setLastDocArrayAutobid] = useState<any[]>([]);
  const [firstDocArrayAutobid, setFirstDocArrayAutobid] = useState<any[]>([]);
  const [backPageAutobid, setBackPageAutobid] = useState<boolean>(false);
  // prettier-ignore
  const [lastDataVehicleRefAutobid, setLastDataVehicleRefAutobid] = useState<DocumentReference>();
  const [collectionSizeAutobid, setCollectionSizeAutobid] = useState<number>(0);

  const queryConstraintsAutobid = [
    where('auction.auctionStatus', '==', 'active'),
    where('auction.isAutobid', 'array-contains', user?.uid),
    orderBy('auction.endDate', 'asc'),
    orderBy('auction.endTime', 'asc'),
    limit(rowsPerPageAutobid)
  ];

  const autobidData = useVehicleBatch(
    pageAutobid,
    queryConstraintsAutobid,
    lastDocArrayAutobid[pageAutobid - 1],
    backPageAutobid,
    firstDocArrayAutobid[pageAutobid]
  );

  const allAutobidVehicles = useMemo(() => {
    const lastDoc = autobidData?.data?.docs[autobidData?.data?.docs?.length - 1];
    if (lastDoc) {
      // prettier-ignore
      const lastDocRef = autobidData?.data?.docs[autobidData?.data?.docs?.length - 1].ref as DocumentReference;
      setLastDataVehicleRefAutobid(lastDocRef);
    }
    // prettier-ignore
    return autobidData?.data?.docs?.map((doc) => ({ ...doc.data(), id: doc.id }));
  }, [autobidData.data]);

  // useEffect(() => {
  //   setAutobidVehicles(allAutobidVehicles);
  // }, [allAutobidVehicles]);

  useEffect(() => {
    if (isFiltered) {
      const filteredAutobidVehicles = filterVehicles(allAutobidVehicles);
      setAutobidVehicles(filteredAutobidVehicles);
    } else {
      setAutobidVehicles(allAutobidVehicles);
    }
  }, [allAutobidVehicles, isFiltered]);

  const countVehicleCollectionAutobid = () => {
    const vehicleRef = collection(firestore, 'vehicles') as CollectionReference;
    const vehicleQuery = query(
      vehicleRef,
      where('auction.auctionStatus', '==', 'active'),
      where('auction.isAutobid', 'array-contains', user?.uid)
    );
    const unsubsribe = onSnapshot(vehicleQuery, (querySnapshot) => {
      setCollectionSizeAutobid(querySnapshot.size);
    });
    return unsubsribe;
  };

  useEffect(() => {
    const unsubscribe = countVehicleCollectionAutobid();
    return () => {
      unsubscribe();
    };
  }, []);

  const handleChangePageAutobid = async (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > pageAutobid) {
      const firstDoc = autobidData?.data?.docs[0];
      const firstDocRef = firstDoc.ref as DocumentReference;
      const docRef = doc(firestore, `vehicles/${firstDocRef.id}`);
      const docSnap = await getDoc(docRef);
      const tempFirstDocs = firstDocArrayAutobid;
      if (docSnap.exists()) {
        tempFirstDocs.push(docSnap);
        setFirstDocArrayAutobid(tempFirstDocs);
      }
      if (lastDataVehicleRefAutobid) {
        const docRef = doc(firestore, `vehicles/${lastDataVehicleRefAutobid.id}`);
        const docSnap = await getDoc(docRef);
        const tempLastDocs = lastDocArrayAutobid;
        if (docSnap.exists()) {
          tempLastDocs.push(docSnap);
          setLastDocArrayAutobid(tempLastDocs);
          setPageAutobid(newPage);
          setBackPageAutobid(false);
        }
      }
    } else {
      setPageAutobid(newPage);
      setBackPageAutobid(true);
    }
  };

  const handleChangeRowsPerPageAutobid = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPageAutobid(parseInt(event.target.value, 10));
    setPageAutobid(0);
    setFirstDocArrayAutobid([]);
    setLastDocArrayAutobid([]);
    setBackPageAutobid(false);
  };

  const handleOrderByChangeAutobid = (event: SelectChangeEvent) => {
    setOrderAutobid(event.target.value);
  };

  const handleExpandedPinnedChangeAutobid = () => {
    setExpandedPinnedAutobid(!expandedPinnedAutobid);
  };

  const handleExpandedAllChangeAutobid = () => {
    setExpandedAllAutobid(!expandedAllAutobid);
  };

  // WATCHLIST
  //---------------------------------------------------------------------------------------
  const [orderWatchlist, setOrderWatchlist] = useState('timeAsc');
  const [pageWatchlist, setPageWatchlist] = useState(0);
  const [rowsPerPageWatchlist, setRowsPerPageWatchlist] = useState(25);
  const [expandedPinnedWatchlist, setExpandedPinnedWatchlist] = useState(true);
  const [expandedAllWatchlist, setExpandedAllWatchlist] = useState(true);
  const [lastDocArrayWatchlist, setLastDocArrayWatchlist] = useState<any[]>([]);
  const [firstDocArrayWatchlist, setFirstDocArrayWatchlist] = useState<any[]>([]);
  const [backPageWatchlist, setBackPageWatchlist] = useState<boolean>(false);
  // prettier-ignore
  const [lastDataVehicleRefWatchlist, setLastDataVehicleRefWatchlist] = useState<DocumentReference>();
  const [collectionSizeWatchlist, setCollectionSizeWatchlist] = useState<number>(0);

  const queryConstraintsWatchlist = [
    where('auction.auctionStatus', '==', 'active'),
    where('auction.isWatchlist', 'array-contains', user?.uid),
    orderBy('auction.endDate', 'asc'),
    orderBy('auction.endTime', 'asc'),
    limit(rowsPerPageWatchlist)
  ];

  const watchlistData = useVehicleBatch(
    pageWatchlist,
    queryConstraintsWatchlist,
    lastDocArrayWatchlist[pageWatchlist - 1],
    backPageWatchlist,
    firstDocArrayWatchlist[pageWatchlist]
  );

  const allWatchlistVehicles = useMemo(() => {
    const lastDoc = watchlistData?.data?.docs[watchlistData?.data?.docs?.length - 1];
    if (lastDoc) {
      // prettier-ignore
      const lastDocRef = watchlistData?.data?.docs[watchlistData?.data?.docs?.length - 1].ref as DocumentReference;
      setLastDataVehicleRefWatchlist(lastDocRef);
    }
    // prettier-ignore
    return watchlistData?.data?.docs?.map((doc) => ({ ...doc.data(), id: doc.id }));
  }, [watchlistData.data]);

  // useEffect(() => {
  //   setWatchlistVehicles(allWatchlistVehicles);
  // }, [allWatchlistVehicles]);

  useEffect(() => {
    if (isFiltered) {
      const filteredWatchlistVehicles = filterVehicles(allWatchlistVehicles);
      setWatchlistVehicles(filteredWatchlistVehicles);
    } else {
      setWatchlistVehicles(allWatchlistVehicles);
    }
  }, [allWatchlistVehicles, isFiltered]);

  const countVehicleCollectionWatchlist = () => {
    const vehicleRef = collection(firestore, 'vehicles') as CollectionReference;
    const vehicleQuery = query(
      vehicleRef,
      where('auction.auctionStatus', '==', 'active'),
      where('auction.isWatchlist', 'array-contains', user?.uid)
    );
    const unsubsribe = onSnapshot(vehicleQuery, (querySnapshot) => {
      setCollectionSizeWatchlist(querySnapshot.size);
    });
    return unsubsribe;
  };

  useEffect(() => {
    const unsubscribe = countVehicleCollectionWatchlist();
    return () => {
      unsubscribe();
    };
  }, []);

  const handleChangePageWatchlist = async (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > pageWatchlist) {
      const firstDoc = watchlistData?.data?.docs[0];
      const firstDocRef = firstDoc.ref as DocumentReference;
      const docRef = doc(firestore, `vehicles/${firstDocRef.id}`);
      const docSnap = await getDoc(docRef);
      const tempFirstDocs = firstDocArrayWatchlist;
      if (docSnap.exists()) {
        tempFirstDocs.push(docSnap);
        setFirstDocArrayWatchlist(tempFirstDocs);
      }
      if (lastDataVehicleRefWatchlist) {
        const docRef = doc(firestore, `vehicles/${lastDataVehicleRefWatchlist.id}`);
        const docSnap = await getDoc(docRef);
        const tempLastDocs = lastDocArrayWatchlist;
        if (docSnap.exists()) {
          tempLastDocs.push(docSnap);
          setLastDocArrayWatchlist(tempLastDocs);
          setPageWatchlist(newPage);
          setBackPageWatchlist(false);
        }
      }
    } else {
      setPageWatchlist(newPage);
      setBackPageWatchlist(true);
    }
  };

  const handleChangeRowsPerPageWatchlist = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPageWatchlist(parseInt(event.target.value, 10));
    setPageWatchlist(0);
    setFirstDocArrayWatchlist([]);
    setLastDocArrayWatchlist([]);
    setBackPageWatchlist(false);
  };

  const handleOrderByChangeWatchlist = (event: SelectChangeEvent) => {
    setOrderWatchlist(event.target.value);
  };

  const handleExpandedPinnedChangeWatchlist = () => {
    setExpandedPinnedWatchlist(!expandedPinnedWatchlist);
  };

  const handleExpandedAllChangeWatchlist = () => {
    setExpandedAllWatchlist(!expandedAllWatchlist);
  };

  // FILTERED
  //---------------------------------------------------------------------------------------
  const [filteredVehicles, setFilteredVehicles] = useState<VehicleInfo[]>([]);
  const [collectionSizeFiltered, setCollectionSizeFiltered] = useState<number>(0);
  const [orderFiltered, setOrderFiltered] = useState('timeAsc');
  const [pageFiltered, setPageFiltered] = useState(0);
  const [rowsPerPageFiltered, setRowsPerPageFiltered] = useState(25);
  const [expandedPinnedFiltered, setExpandedPinnedFiltered] = useState(true);
  const [expandedAllFiltered, setExpandedAllFiltered] = useState(true);

  // useEffect(() => {
  //   console.log('is filtered', isFiltered);
  //   if (isFiltered) {
  //     const vehiclesToFilter = filterVehicles(allVehicles);
  //     vehiclesToFilter.forEach((v: VehicleInfo) => {
  //       let vehicleFound = filteredVehicles.find((veh: VehicleInfo) => veh.id === v.id);
  //       if (!vehicleFound) {
  //         filteredVehicles.push(v);
  //       }
  //     });
  //     setFilteredVehicles(filteredVehicles);
  //     setCollectionSizeFiltered(filteredVehicles.length);
  //   } else {
  //     setFilteredVehicles([]);
  //     setCollectionSizeFiltered(0);
  //   }
  // }, [filterChange]);

  const filterVehicles = (vehicles: VehicleInfo[]) => {
    let filteredVehicles = vehicles;
    filters.forEach((filter: any) => {
      switch (filter.name) {
        case 'make':
          const fields = filter.value;
          filteredVehicles = filteredVehicles.filter((vehicle) =>
            fields.some((field: string) =>
              field.toLowerCase().includes(vehicle.details.make.toLowerCase())
            )
          );
          break;
        case 'model':
          filteredVehicles = filteredVehicles.filter((vehicle) =>
            vehicle.details.model.toLowerCase().includes(filter.value.toLowerCase())
          );
          break;
        case 'trimLevel':
          filteredVehicles = filteredVehicles.filter((vehicle) =>
            vehicle.details.trim.toLowerCase().includes(filter.value.toLowerCase())
          );
          break;
        case 'driveTrain':
          if (filter.value.toLowerCase() !== 'any') {
            filteredVehicles = filteredVehicles.filter((vehicle) =>
              vehicle.details.driveTrain.toLowerCase().includes(filter.value.toLowerCase())
            );
            break;
          } else {
            break;
          }
        case 'transmission':
          if (filter.value.toLowerCase() !== 'any') {
            filteredVehicles = filteredVehicles.filter((vehicle) =>
              vehicle.details.transmission.toLowerCase().includes(filter.value.toLowerCase())
            );
            break;
          } else {
            break;
          }
        case 'fuelType':
          if (filter.value.toLowerCase() !== 'any') {
            filteredVehicles = filteredVehicles.filter((vehicle) =>
              vehicle.details.fuelType.toLowerCase().includes(filter.value.toLowerCase())
            );
            break;
          } else {
            break;
          }
        case 'exteriorColour':
          if (filter.value.toLowerCase() !== 'any') {
            filteredVehicles = filteredVehicles.filter((vehicle) =>
              vehicle.details.extColor.toLowerCase().includes(filter.value.toLowerCase())
            );
            break;
          } else {
            break;
          }
        case 'yearMin':
          filteredVehicles = filteredVehicles.filter(
            (vehicle) => (vehicle.details.year as number) >= parseInt(filter.value)
          );
          break;
        case 'yearMax':
          filteredVehicles = filteredVehicles.filter(
            (vehicle) => (vehicle.details.year as number) <= parseInt(filter.value)
          );
          break;
        case 'odometerMin':
          filteredVehicles = filteredVehicles.filter(
            (vehicle) => parseInt(vehicle.details.mileage) >= parseInt(filter.value)
          );
          break;
        case 'odometerMax':
          filteredVehicles = filteredVehicles.filter(
            (vehicle) => parseInt(vehicle.details.mileage) <= parseInt(filter.value)
          );
          break;
      }
    });
    return filteredVehicles;
  };

  const handleChangePageFiltered = async (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPageFiltered(newPage);
  };

  const handleChangeRowsPerPageFiltered = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPageFiltered(parseInt(event.target.value, 10));
    setPageFiltered(0);
  };

  const handleOrderByChangeFiltered = (event: SelectChangeEvent) => {
    setOrderFiltered(event.target.value);
  };

  const handleExpandedPinnedChangeFiltered = () => {
    setExpandedPinnedFiltered(!expandedPinnedFiltered);
  };

  const handleExpandedAllChangeFiltered = () => {
    setExpandedAllFiltered(!expandedAllFiltered);
  };

  const [vehicleEmptyState, setVehicleEmptyState] = useState<boolean>(false);
  const [instockEmptyState, setInstockEmptyState] = useState<boolean>(false);
  const [appraisalEmptyState, setAppraisalEmptyState] = useState<boolean>(false);
  const [winningEmptyState, setWinningEmptyState] = useState<boolean>(false);
  const [losingEmptyState, setLosingEmptyState] = useState<boolean>(false);
  const [autobidEmptyState, setAutobidEmptyState] = useState<boolean>(false);
  const [watchlistEmptyState, setWatchlistEmptyState] = useState<boolean>(false);

  useEffect(() => {
    if (allVehicles && (!vehicles || vehicles.length < 1)) {
      setVehicleEmptyState(true);
    } else {
      setVehicleEmptyState(false);
    }
    if (allVehicles && (!instockVehicles || instockVehicles.length < 1)) {
      setInstockEmptyState(true);
    } else {
      setInstockEmptyState(false);
    }
    if (allVehicles && (!appraisalVehicles || appraisalVehicles.length < 1)) {
      setAppraisalEmptyState(true);
    } else {
      setAppraisalEmptyState(false);
    }
    if (allVehicles && (!winningVehicles || winningVehicles.length < 1)) {
      setWinningEmptyState(true);
    } else {
      setWinningEmptyState(false);
    }
    if (allVehicles && (!losingVehicles || losingVehicles.length < 1)) {
      setLosingEmptyState(true);
    } else {
      setLosingEmptyState(false);
    }
    if (allVehicles && (!autobidVehicles || autobidVehicles.length < 1)) {
      setAutobidEmptyState(true);
    } else {
      setAutobidEmptyState(false);
    }
    if (allVehicles && (!watchlistVehicles || watchlistVehicles.length < 1)) {
      setWatchlistEmptyState(true);
    } else {
      setWatchlistEmptyState(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    vehicles,
    instockVehicles,
    appraisalVehicles,
    winningVehicles,
    losingVehicles,
    autobidVehicles,
    watchlistVehicles
  ]);

  const StyledTabs = styled((props: StyledTabsProps) => (
    <Tabs
      {...props}
      sx={{
        minHeight: 'unset !important'
      }}
      variant="scrollable"
      // scrollButtons
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />
      }}
    />
  ))(({ theme }) => ({
    '& .MuiTab-root': {
      color:
        theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.text.secondary,
      backgroundColor:
        theme.palette.mode === 'dark'
          ? theme.palette.background.neutral
          : theme.palette.background.paper
    },
    '& .MuiTab-root:not(:last-of-type)': {
      marginRight: '10px'
    },
    '& .MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent !important',
      padding: '5px'
    },
    '& .Mui-selected': {
      backgroundColor: theme.palette.primary.lighter,
      color: `${theme.palette.primary.main} !important`
    },
    '& .MuiTabs-indicatorSpan': {
      display: 'none',
      maxWidth: 40,
      width: '100%',
      backgroundColor: theme.palette.chart.violet[2]
    }
  }));

  const useStyles = makeStyles({
    headerText: {
      fontSize: '14px',
      marginRight: '18px'
    },
    pagination: {
      borderTopStyle: 'none',
      fontWeight: '700',
      borderTop: 'none'
    },
    tourContainer: {
      maxWidth: '500px !important',
      borderRadius: '10px !important'
    },
    page: {
      width: '100%',
      display: 'flex',
      position: 'relative'
    },
    container: {
      display: 'flex',
      paddingRight: '0 !important'
    },
    collapseContainer: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    innerContainer: {
      display: 'flex',
      width: '100%',
      height: 'auto',
      [theme.breakpoints.down(mediaQuery)]: {
        padding: '0px 40px'
      },
      [theme.breakpoints.down('md')]: {
        padding: '0px 0px'
      }
    },
    expandIcon: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      borderRadius: '100%',
      width: '25px',
      height: '25px',
      padding: '5px'
    },
    bodyContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: 'auto',
      paddingTop: '0px'
    },
    searchContainer: {
      width: '100%',
      maxWidth: '400px',
      height: 'auto',
      padding: '5px 0px',
      marginBottom: '0px',
      marginRight: '20px'
    },
    search: {
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.background.neutral : 'transparent !important',
      border: theme.palette.mode === 'dark' ? 'unset' : `1px solid ${theme.palette.divider}`,
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: '100%'
    },
    searchInput: {
      fontSize: '14px',
      width: '100%',
      height: '34px',
      margin: '0 10px',
      border: 'none',
      padding: '5px 10px',
      outline: 'none !important',
      marginLeft: 2,
      flex: 1,
      backgroundColor: 'transparent !important',
      color:
        theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.text.secondary,
      '&::placeholder': {
        color:
          theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.text.secondary,
        fontSize: '14px'
      }
    },
    searchIcon: {
      fill: theme.palette.primary.main,
      marginRight: '5px'
    }
  });

  const styles = useStyles();

  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate('/dashboard');
  };

  return (
    <>
      {!userDeleted && !userSuspended && !userDisabled ? (
        <Page title="Active Auctions | Buyer" className={styles.page}>
          <Box
            sx={{
              position: 'absolute',
              zIndex: '99999',
              top: isMobile ? '-20px' : '-17px',
              left: isMobile ? '10px' : ''
            }}
          >
            <Tooltip title="Hide/Show">
              <IconButton onClick={() => handleToggleSidebar(openSidebar)} id="activeAuctionBuy9">
                <MenuOpenIcon className={styles.expandIcon} />
              </IconButton>
            </Tooltip>
          </Box>
          <AuctionFilters
            handleFilterChange={handleFilterChange}
            handleFormReset={handleFormReset}
            isOpenSidebar={openSidebar}
            onCloseSidebar={() => handleToggleSidebar(openSidebar)}
          />
          {vehicle && (
            <AuctionDrawer
              tabIndex={drawerTab}
              vehicle={vehicle as VehicleInfo}
              isOpenSidebar={openDrawer}
              onCloseSidebar={() => setOpenDrawer(!openDrawer)}
            />
          )}
          <Container
            maxWidth={themeStretch ? false : 'xl'}
            sx={{
              width: openSidebar ? `calc(100% - 250px)` : '100%',
              marginRight: openSidebar ? 0 : 'auto',
              marginTop: isMobile ? '20px' : ''
            }}
            className={styles.container}
          >
            <Box className={styles.innerContainer}>
              <Box className={styles.bodyContainer}>
                <Box className={styles.collapseContainer}>
                  <Box>
                    <Typography
                      variant="h4"
                      component="h1"
                      sx={{
                        ml: 1,
                        mb: 1,
                        fontSize: '1rem !important',
                        color:
                          theme.palette.mode === 'dark'
                            ? theme.palette.common.white
                            : theme.palette.text.primary
                      }}
                    >
                      Active Auction: Buying
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexWrap: 'wrap'
                  }}
                >
                  <StyledTabs
                    id="activeAuctionBuy3"
                    value={tabValue}
                    onChange={handleChangeTab}
                    aria-label="Post Auction Tabs"
                  >
                    <Tab
                      sx={{
                        padding: '0px 10px',
                        borderRadius: '5px',
                        minHeight: '40px',
                        height: '40px'
                      }}
                      label={
                        <Typography sx={{ fontSize: '12px' }}>
                          ALL ACTIVE ({collectionSize})
                        </Typography>
                      }
                      {...a11yProps(0)}
                    />
                    <Tab
                      sx={{
                        padding: '0px 10px',
                        borderRadius: '5px',
                        minHeight: '40px',
                        height: '40px'
                      }}
                      label={
                        <Typography sx={{ fontSize: '12px' }}>
                          IN-STOCK ({collectionSizeInStock})
                        </Typography>
                      }
                      {...a11yProps(1)}
                    />
                    <Tab
                      sx={{
                        padding: '0px 10px',
                        borderRadius: '5px',
                        minHeight: '40px',
                        height: '40px'
                      }}
                      label={
                        <Typography sx={{ fontSize: '12px' }}>
                          APPRAISALS ({collectionSizeAppraisals})
                        </Typography>
                      }
                      {...a11yProps(2)}
                    />
                    <Tab
                      sx={{
                        padding: '0px 10px',
                        borderRadius: '5px',
                        minHeight: '40px',
                        height: '40px'
                      }}
                      label={
                        <Typography sx={{ fontSize: '12px' }}>
                          WINNING ({collectionSizeWinning})
                        </Typography>
                      }
                      {...a11yProps(3)}
                    />
                    <Tab
                      sx={{
                        padding: '0px 10px',
                        borderRadius: '5px',
                        minHeight: '40px',
                        height: '40px'
                      }}
                      label={
                        <Typography sx={{ fontSize: '12px' }}>
                          LOSING ({collectionSizeLosing})
                        </Typography>
                      }
                      {...a11yProps(4)}
                    />
                    <Tab
                      sx={{
                        padding: '0px 10px',
                        borderRadius: '5px',
                        minHeight: '40px',
                        height: '40px'
                      }}
                      label={
                        <Typography sx={{ fontSize: '12px' }}>
                          AUTOBID ({collectionSizeAutobid})
                        </Typography>
                      }
                      {...a11yProps(5)}
                    />
                    <Tab
                      sx={{
                        padding: '0px 10px',
                        borderRadius: '5px',
                        minHeight: '40px',
                        height: '40px'
                      }}
                      label={
                        <Typography sx={{ fontSize: '12px' }}>
                          WATCHLIST ({collectionSizeWatchlist})
                        </Typography>
                      }
                      {...a11yProps(6)}
                    />
                    {/* <Tab
                      sx={{
                        padding: '0px 10px',
                        borderRadius: '5px',
                        minHeight: '40px',
                        height: '40px'
                      }}
                      label={
                        <Typography sx={{ fontSize: '12px' }}>
                          FILTERED ({filteredVehicles?.length})
                        </Typography>
                      }
                      {...a11yProps(7)}
                    /> */}
                  </StyledTabs>
                  <Box className={styles.searchContainer} id="activeAuctionBuy4">
                    <Paper component="form" className={styles.search}>
                      <DebounceInput
                        className={styles.searchInput}
                        placeholder="Search Vehicles by Make, Model, Year, etc."
                        minLength={2}
                        debounceTimeout={300}
                        onChange={(e) => setSearchValue(e.target.value)}
                      />
                      <SearchIcon className={styles.searchIcon} sx={{ width: '20px' }} />
                    </Paper>
                  </Box>
                </Box>
                {/* ALL VEHICLES */}
                <TabPanel value={tabValue} index={0}>
                  <TabsPageBuy
                    vehicles={vehicles}
                    curUser={curUser}
                    collectionSize={collectionSize}
                    searchValue={searchValue}
                    handleToggleDrawer={handleToggleDrawer}
                    mediaQuery={mediaQuery}
                    currentDealership={currentDealership}
                    order={order}
                    expandedPinned={expandedPinned}
                    expandedAll={expandedAll}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    handleOrderByChange={handleOrderByChange}
                    handleExpandedPinnedChange={handleExpandedPinnedChange}
                    handleExpandedAllChange={handleExpandedAllChange}
                    ref2GridUnpin={vehiclesViewRef}
                    ref2GridPin={vehiclesPinViewRef}
                  />
                  {vehicleEmptyState && (
                    <EmptyStateMessage
                      boldMessage="There are no vehicles to bid on."
                      message="Build your Buying Network through the DealerGavel Community or see what vehicles are launching soon"
                    />
                  )}
                </TabPanel>
                {/* IN STOCK */}
                <TabPanel value={tabValue} index={1}>
                  <TabsPageBuy
                    vehicles={instockVehicles}
                    curUser={curUser}
                    collectionSize={collectionSizeInStock}
                    searchValue={searchValue}
                    handleToggleDrawer={handleToggleDrawer}
                    mediaQuery={mediaQuery}
                    currentDealership={currentDealership}
                    order={orderInStock}
                    page={pageInStock}
                    rowsPerPage={rowsPerPageInStock}
                    handleChangePage={handleChangePageInStock}
                    handleChangeRowsPerPage={handleChangeRowsPerPageInStock}
                    expandedPinned={expandedPinnedInStock}
                    expandedAll={expandedAllInStock}
                    handleOrderByChange={handleOrderByChangeInStock}
                    handleExpandedPinnedChange={handleExpandedPinnedChangeInStock}
                    handleExpandedAllChange={handleExpandedAllChangeInStock}
                    ref2GridUnpin={instockViewRef}
                    ref2GridPin={instockPinViewRef}
                  />
                  {instockEmptyState && (
                    <EmptyStateMessage boldMessage="There are no vehicles in stock." message="" />
                  )}
                </TabPanel>
                {/* APPRAISALS */}
                <TabPanel value={tabValue} index={2}>
                  <TabsPageBuy
                    vehicles={appraisalVehicles}
                    curUser={curUser}
                    collectionSize={collectionSizeAppraisals}
                    searchValue={searchValue}
                    handleToggleDrawer={handleToggleDrawer}
                    mediaQuery={mediaQuery}
                    currentDealership={currentDealership}
                    order={orderAppraisals}
                    page={pageAppraisals}
                    rowsPerPage={rowsPerPageAppraisals}
                    handleChangePage={handleChangePageAppraisals}
                    handleChangeRowsPerPage={handleChangeRowsPerPageAppraisals}
                    expandedPinned={expandedPinnedAppraisals}
                    expandedAll={expandedAllAppraisals}
                    handleOrderByChange={handleOrderByChangeAppraisals}
                    handleExpandedPinnedChange={handleExpandedPinnedChangeAppraisals}
                    handleExpandedAllChange={handleExpandedAllChangeAppraisals}
                    ref2GridUnpin={appraisalsViewRef}
                    ref2GridPin={appraisalsPinViewRef}
                  />
                  {appraisalEmptyState && (
                    <EmptyStateMessage
                      boldMessage="There are no vehicles in appraisals."
                      message=""
                    />
                  )}
                </TabPanel>
                {/* WINNING */}
                <TabPanel value={tabValue} index={3}>
                  <TabsPageBuy
                    vehicles={winningVehicles}
                    curUser={curUser}
                    collectionSize={collectionSizeWinning}
                    searchValue={searchValue}
                    handleToggleDrawer={handleToggleDrawer}
                    mediaQuery={mediaQuery}
                    currentDealership={currentDealership}
                    order={orderWinning}
                    page={pageWinning}
                    rowsPerPage={rowsPerPageWinning}
                    handleChangePage={handleChangePageWinning}
                    handleChangeRowsPerPage={handleChangeRowsPerPageWinning}
                    expandedPinned={expandedPinnedWinning}
                    expandedAll={expandedAllWinning}
                    handleOrderByChange={handleOrderByChangeWinning}
                    handleExpandedPinnedChange={handleExpandedPinnedChangeWinning}
                    handleExpandedAllChange={handleExpandedAllChangeWinning}
                    ref2GridUnpin={winningViewRef}
                    ref2GridPin={winningPinViewRef}
                  />
                  {winningEmptyState && (
                    <EmptyStateMessage
                      boldMessage="You are currently not winning any vehicles"
                      message=""
                    />
                  )}
                </TabPanel>
                {/* LOSING */}
                <TabPanel value={tabValue} index={4}>
                  <TabsPageBuy
                    vehicles={losingVehicles}
                    curUser={curUser}
                    collectionSize={collectionSizeLosing}
                    searchValue={searchValue}
                    handleToggleDrawer={handleToggleDrawer}
                    mediaQuery={mediaQuery}
                    currentDealership={currentDealership}
                    order={orderLosing}
                    page={pageLosing}
                    rowsPerPage={rowsPerPageLosing}
                    handleChangePage={handleChangePageLosing}
                    handleChangeRowsPerPage={handleChangeRowsPerPageLosing}
                    expandedPinned={expandedPinnedLosing}
                    expandedAll={expandedAllLosing}
                    handleOrderByChange={handleOrderByChangeLosing}
                    handleExpandedPinnedChange={handleExpandedPinnedChangeLosing}
                    handleExpandedAllChange={handleExpandedAllChangeLosing}
                    ref2GridUnpin={losingViewRef}
                    ref2GridPin={losingPinViewRef}
                  />
                  {losingEmptyState && (
                    <EmptyStateMessage
                      boldMessage="You are currently not losing any vehicles."
                      message=""
                    />
                  )}
                </TabPanel>
                {/* AUTOBID */}
                <TabPanel value={tabValue} index={5}>
                  <TabsPageBuy
                    vehicles={autobidVehicles}
                    curUser={curUser}
                    collectionSize={collectionSizeAutobid}
                    searchValue={searchValue}
                    handleToggleDrawer={handleToggleDrawer}
                    mediaQuery={mediaQuery}
                    currentDealership={currentDealership}
                    order={orderAutobid}
                    page={pageAutobid}
                    rowsPerPage={rowsPerPageAutobid}
                    handleChangePage={handleChangePageAutobid}
                    handleChangeRowsPerPage={handleChangeRowsPerPageAutobid}
                    expandedPinned={expandedPinnedAutobid}
                    expandedAll={expandedAllAutobid}
                    handleOrderByChange={handleOrderByChangeAutobid}
                    handleExpandedPinnedChange={handleExpandedPinnedChangeAutobid}
                    handleExpandedAllChange={handleExpandedAllChangeAutobid}
                    ref2GridUnpin={autobidViewRef}
                    ref2GridPin={autobidPinViewRef}
                  />
                  {autobidEmptyState && (
                    <EmptyStateMessage
                      boldMessage="You have not set any autobids."
                      message="You can keep track of vehicles you have set an Autobid on here."
                    />
                  )}
                </TabPanel>
                {/* WATCHLIST */}
                <TabPanel value={tabValue} index={6}>
                  <TabsPageBuy
                    vehicles={watchlistVehicles}
                    curUser={curUser}
                    collectionSize={collectionSizeWatchlist}
                    searchValue={searchValue}
                    handleToggleDrawer={handleToggleDrawer}
                    mediaQuery={mediaQuery}
                    currentDealership={currentDealership}
                    order={orderWatchlist}
                    page={pageWatchlist}
                    rowsPerPage={rowsPerPageWatchlist}
                    handleChangePage={handleChangePageWatchlist}
                    handleChangeRowsPerPage={handleChangeRowsPerPageWatchlist}
                    expandedPinned={expandedPinnedWatchlist}
                    expandedAll={expandedAllWatchlist}
                    handleOrderByChange={handleOrderByChangeWatchlist}
                    handleExpandedPinnedChange={handleExpandedPinnedChangeWatchlist}
                    handleExpandedAllChange={handleExpandedAllChangeWatchlist}
                    ref2GridUnpin={watchlistViewRef}
                    ref2GridPin={watchlistPinViewRef}
                  />
                  {watchlistEmptyState && (
                    <EmptyStateMessage
                      boldMessage="There are no vehicles in your watchlist."
                      message="You can keep track of vehicles you have saved on your watchlist here."
                    />
                  )}
                </TabPanel>
                {/* FILTERED */}
                {/* <TabPanel value={tabValue} index={7}>
                  <TabsPageBuy
                    isFilteredList={true}
                    curUser={curUser}
                    vehicles={filteredVehicles}
                    collectionSize={collectionSizeFiltered}
                    searchValue={searchValue}
                    handleToggleDrawer={handleToggleDrawer}
                    mediaQuery={mediaQuery}
                    currentDealership={currentDealership}
                    order={orderFiltered}
                    page={pageFiltered}
                    rowsPerPage={rowsPerPageFiltered}
                    handleChangePage={handleChangePageFiltered}
                    handleChangeRowsPerPage={handleChangeRowsPerPageFiltered}
                    expandedPinned={expandedPinnedFiltered}
                    expandedAll={expandedAllFiltered}
                    handleOrderByChange={handleOrderByChangeFiltered}
                    handleExpandedPinnedChange={handleExpandedPinnedChangeFiltered}
                    handleExpandedAllChange={handleExpandedAllChangeFiltered}
                    ref2GridUnpin={filteredViewRef}
                    ref2GridPin={filteredPinViewRef}
                  />
                  {watchlistEmptyState && (
                    <EmptyStateMessage
                      boldMessage="There are no vehicles in your watchlist."
                      message="You can keep track of vehicles you have saved on your watchlist here."
                    />
                  )}
                </TabPanel> */}
              </Box>
            </Box>
          </Container>
          {vehicles && vehicles.length > 0 && (
            <Tour
              steps={steps}
              isOpen={tourOpen}
              onRequestClose={() => handleCloseTour()}
              className={styles.tourContainer}
            />
          )}
        </Page>
      ) : (
        <>
          {userDeleted && (
            <Alert severity="error">
              <AlertTitle>Permission Denied</AlertTitle>
              Your account has been disabled.
            </Alert>
          )}
          {userSuspended && (
            <>
              <Alert severity="error">
                <AlertTitle>Thank you for joining DealerGavel</AlertTitle>
                Your account information is being reviewed by DealerGavel team, and will be
                &nbsp;authorized shortly. For now, you can return to the dashboard and await
                &nbsp;authorization.
              </Alert>
              <Box sx={{ mt: '20px' }}>
                <Button onClick={handleNavigate} variant="contained">
                  Back to Dashboard
                </Button>
              </Box>
            </>
          )}
          {userDisabled && (
            <>
              <Alert severity="error">
                <AlertTitle>Permission Denied</AlertTitle>
                Your account has been disabled.
              </Alert>
              <Box sx={{ mt: '20px' }}>
                <Button onClick={handleNavigate} variant="contained">
                  Back to Dashboard
                </Button>
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
}
