import { AddAPhoto } from '@mui/icons-material';
import FileDropzone from 'components/shared/FileDropzone';
import { Box, Button, Divider, FormHelperText, TextField, useTheme } from '@mui/material';
// import { boxStyle } from './vehicleCaptureStyles';
import { VehiclePhotos } from 'types/vehicleInfo';
import { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable
} from 'firebase/storage';
import { useStorage } from 'reactfire';
import { VehicleDropBox, VehicleSubDropBox } from './CommonComponents';
import { uniqueSuffixId } from './suffixUID';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';

type DialogPhotoProps = {
  isDamages?: boolean;
  vehiclePhoto: VehiclePhotos;
  vin: string;
  isNewPhoto?: boolean;
  category?: string;
  updVehiclePhoto: (vehiclePhoto: VehiclePhotos, flag: number) => void;
  handleCloseModal: () => void;
};

export const photoLocationExt = [
  '3/4 Shot',
  'Driver Side',
  'Back',
  'Passenger Side',
  'Front',
  'Tire'
];

// export const photoLocationInt = ['Across Front Seats', 'Odometer', 'Center Stack', 'Header Roof'];
export const photoLocationInt = [
  'Drivers Seat Cross',
  'Odometer with Mileage',
  'From Back Seat: Centre Console',
  'From Back Seat: Roof with Mirror'
];

export const dmgLocations = ['Body Panel', 'Misc Body', 'Lights', 'Wheel Rims', 'Interior'];

export const extDmgLocation = [
  '3/4 Shot',
  'Driver Side',
  'Back',
  'Passenger Side',
  'Front',
  'Tire',
  'Body Panel',
  'Misc Body',
  'Lights',
  'Wheel Rims',
  'Custom Locations'
];

export const intDmgLocation = [
  // 'Across Front Seats',
  // 'Odometer',
  // 'Center Stack',
  // 'Header Roof',
  'Drivers Seat Cross',
  'Odometer with Mileage',
  'From Back Seat: Centre Console',
  'From Back Seat: Roof with Mirror',
  'Interior',
  'Custom Locations'
];

// locations
export const bodyPanels = [
  'LF Fender',
  'LF Door',
  'LR Door',
  'LR Qtr Panel',
  'L Cab Side',
  'L Rocker',
  'L Bed Side',
  'Front Bumper',
  'Rear Bumper',
  'RF Fender',
  'RF Door',
  'RR Door',
  'RR Qtr Panel',
  'R Cab Side Door',
  'R Rocker',
  'R Bed Side',
  'Grill',
  'Hood',
  'Roof',
  'Trunk',
  'Inside Bed',
  'Tailgate'
];

export const miscBody = [
  'R Running Board',
  'L Running Board',
  '5th Wheel Hitch',
  'Rear Hitch',
  'Exhaust',
  'L Mirror',
  'R Mirror',
  'Windshield',
  'Overall Vehicle',
  'Rear Window',
  'RF Window',
  'RR Window',
  'LF Window',
  'LR Window'
];

export const lights = [
  'LF Fog Light',
  'RF Fog Light',
  'LF Headlight',
  'RF Headlight',
  'LR Tail Lamp',
  'RR Tail Lamp'
];

export const wheelRims = [
  'LF Wheel',
  'LR Wheel',
  'RF Wheel',
  'RR Wheel',
  'Spare',
  'LF Tire',
  'LR Tire',
  'RF Tire',
  'RR Tire'
];

export const interior = [
  'LF Seat',
  'RF Seat',
  'LR Seat',
  'RR Seat',
  'Int LF Door Panel',
  'Int RF Door Panel',
  'Int LR Door Panel',
  'Int RR Door Panel',
  'Dash',
  'Steering Wheel',
  'Radio',
  'Navigation',
  'Odometer Cluster',
  'Glove Box',
  'Center Console Front',
  'Center Console Rear',
  'Headliner',
  'Head Rest'
];

// types
export const dmgTypes = ['General', 'Scratch', 'Scuff'];

export const general = [
  'Chip',
  'Ding',
  'Dent',
  'Cracked',
  'Star',
  'Hole',
  'After Market',
  'Missing',
  'Prev Repair',
  'Curb Rash',
  'Corroded',
  'Peeling',
  'Rust',
  'Broken',
  'Missing',
  'Cut',
  'Torn',
  'Worn',
  'Misaligned',
  'Environmental Damage',
  'Hail Damage'
];

export const scratch = [
  'Light Scratch - No Paint Dmg',
  'Light Scratch - Paint Dmg',
  'Heavy Scratch - Paint Dmg',
  'Heavy Scratch w Dent - No Paint Dmg',
  'Heavy Scratch w Dent - Paint Dmg'
];

export const scuff = [
  'Light Scuff - No Paint Dmg',
  'Heavy Scuff - Paint Dmg',
  'Heavy Scuff w Dent - No Paint Dmg',
  'Heavy Scuff w Dent - Paint Dmg'
];

export const sizes = [
  '<⅛”',
  '⅛” to ½”',
  '½” to 1”',
  '1” to 2”',
  '2” to 3”',
  '3” to 4”',
  '4” to 5”',
  '5” to 6”',
  '6” to 8”',
  '8” to 10”',
  '10” to 12”',
  'Greater than 12”'
];

export function AddPhotoDialog({
  vin,
  vehiclePhoto,
  updVehiclePhoto,
  handleCloseModal,
  isDamages,
  isNewPhoto,
  category
}: DialogPhotoProps) {
  const theme = useTheme();
  const storage = useStorage();
  const { enqueueSnackbar } = useSnackbar();
  const [photoPreview, setPhotoPreview] = useState({ file: '' });
  const [photoFile, setPhotoFile] = useState<any>('');
  const [vehiclePhotoInfo, setVehiclePhotoInfo] = useState(vehiclePhoto || ({} as VehiclePhotos));
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
  const [showValidateError, setShowValidateError] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [locationVal, setLocationVal] = useState<string>(vehiclePhotoInfo.location);
  const [otherLocation, setOtherLocation] = useState<string>(vehiclePhotoInfo.otherLocation ?? '');
  const [typeVal, setTypeVal] = useState<string>(
    vehiclePhotoInfo.damageType ? vehiclePhotoInfo.damageType : ''
  );
  const [sizeVal, setSizeVal] = useState<string>(
    vehiclePhotoInfo.damageSize ? vehiclePhotoInfo.damageSize : ''
  );
  const [numberVal, setNumberVal] = useState<string>(
    vehiclePhotoInfo.damageNumber ? vehiclePhotoInfo.damageNumber : ''
  );

  const boxStyle = {
    textAlign: 'start',
    width: 400,
    height: 60,
    backgroundColor: theme.palette.mode !== 'dark' ? theme.palette.common.white : 'unset',
    border: `1px ${theme.palette.divider} solid`,
    borderRadius: '10px',
    fontWeight: 300,
    padding: 0,
    mb: 1,
    overflow: 'hidden'
  };

  const deleteFileFromStorage = useCallback(
    async (Path2File: any) => {
      console.log(Path2File);
      const storage = getStorage();
      const deleteRef = ref(storage, Path2File);
      await deleteObject(deleteRef)
        .then(() => {
          enqueueSnackbar('Photo Deleted', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right'
            }
          });
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar('Error removing file', {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right'
            }
          });
        });
    },
    [enqueueSnackbar]
  );

  const saveFileToStorage = async (
    file: any,
    path2File: string
  ): Promise<{ url: string; fileName: string }> => {
    const storageRef = ref(storage, path2File);
    // const fileName = file.name;
    const fileName = uniqueSuffixId(`-${file.name}`);
    const imageRef = ref(storageRef, fileName);
    const uploadImage = uploadBytesResumable(imageRef, file);
    return new Promise((resolve, reject) => {
      uploadImage.on(
        'state_changed',
        () => {},
        () => {},
        async () => {
          const url = await getDownloadURL(imageRef);
          resolve({ url: url, fileName: fileName });
        }
      );
    });
  };

  const updatePhotoUrl = async (file: any) => {
    // if (vehiclePhotoInfo.fileName) {
    //   deleteFileFromStorage(
    //     `cars/${vin}/${vehiclePhotoInfo.categoryName}/${vehiclePhotoInfo.fileName}`
    //   );
    // }
    const { url, fileName } = await saveFileToStorage(
      file,
      `cars/${vin}/${vehiclePhotoInfo.categoryName}`
    );
    return { url, fileName };
  };

  const ShowPhoto = () => {
    let imageFile = vehiclePhoto.url || photoPreview.file || '';

    if (photoPreview.file !== '') {
      imageFile = photoPreview.file;
    }

    return (
      <>
        {imageFile && (
          <Box
            component="div"
            sx={{
              width: '400px',
              height: 'auto',
              m: 'auto',
              position: 'relative'
            }}
          >
            <img style={{ width: '400px', display: 'block' }} src={imageFile} alt="aa" />
            <Box
              component="span"
              sx={{
                backgroundColor: theme.palette.primary.main,
                borderRadius: '8px',
                width: '40px',
                height: '40px',
                float: 'right',
                cursor: 'pointer',
                position: 'absolute',
                top: '5%',
                left: '88%'
              }}
            >
              <AddAPhoto sx={{ color: 'white', ml: 1, mt: 1 }} />
            </Box>
          </Box>
        )}
      </>
    );
  };

  const validateInputs = () => {
    if (isDamages) {
      // if (locationVal && typeVal && sizeVal && numberVal) {
      //   setIsDisabled(false);
      // } else {
      //   setIsDisabled(true);
      // }
    } else {
      if (locationVal) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    }
  };

  useEffect(() => {
    if (category === 'Damages') {
      const tmp = { ...vehiclePhotoInfo };
      tmp.categoryName = 'Damages';
      setVehiclePhotoInfo(tmp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    validateInputs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationVal, typeVal, sizeVal, numberVal]);

  return (
    <>
      <Box sx={{ mt: 2, width: 450, zIndex: '9996' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'flex-end',
            paddingRight: '20px',
            marginBottom: '10px'
          }}
        >
          <CloseIcon
            onClick={() => {
              setVehiclePhotoInfo({} as VehiclePhotos);
              handleCloseModal();
            }}
            sx={{ cursor: 'pointer' }}
          />
        </Box>
        <FileDropzone
          accept="image/jpeg,image/jpg,image/png,image/bmp"
          onUpload={(file: any) => {
            setPhotoFile(file);
            setPhotoPreview({
              file: URL.createObjectURL(file)
            });
            if (isDamages) {
              const tmp = { ...vehiclePhotoInfo };
              tmp.categoryName = 'Damages';
              setVehiclePhotoInfo(tmp);
            }
          }}
        >
          <Box
            sx={{
              borderRadius: '5px',
              width: '450px'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <ShowPhoto />
              {!photoPreview.file && !vehiclePhotoInfo.url && (
                <>
                  <Box
                    sx={{
                      background: theme.palette.grey[200],
                      width: '200px',
                      height: '150px',
                      backgroundClip: 'content-box',
                      padding: '4px',
                      border: `1px dashed ${theme.palette.divider}`,
                      textAlign: 'center',
                      marginBottom: '8px'
                    }}
                  >
                    <AddAPhoto sx={{ marginTop: '30px', color: theme.palette.grey[600] }} />
                  </Box>
                  <Button
                    sx={{
                      marginTop: '65px',
                      position: 'absolute'
                    }}
                    variant="contained"
                  >
                    Add Image
                  </Button>
                </>
              )}
            </Box>
          </Box>
        </FileDropzone>
      </Box>
      <Box sx={{ ml: 3, mt: 3, width: 400 }}>
        <Box sx={boxStyle}>
          <VehicleDropBox
            disabled={true}
            dropBoxValue={category as string}
            caption={'Category'}
            arrayOfItems={[category as string]}
            updateDropValue={(v) => {
              if (!v) return;
              const tmp = { ...vehiclePhotoInfo };
              tmp.categoryName = v as string;
              setVehiclePhotoInfo(tmp);
            }}
          />
        </Box>
        <Box sx={boxStyle}>
          {isDamages ? (
            <VehicleSubDropBox
              dropBoxValue={vehiclePhotoInfo.location}
              caption={'Location'}
              topArray={dmgLocations}
              subArrayOne={bodyPanels}
              subArrayTwo={miscBody}
              subArrayThree={lights}
              subArrayFour={wheelRims}
              subArrayFive={interior}
              updateDropValue={(v) => {
                if (!v) return;
                const tmp = { ...vehiclePhotoInfo };
                tmp.location = v;
                tmp.categoryName = category as string;
                setVehiclePhotoInfo(tmp);
                setLocationVal(v);
              }}
            />
          ) : (
            <>
              {category === 'Exterior' && (
                <TextField
                  label="Location"
                  variant="standard"
                  sx={{
                    fontSize: '12px!important',
                    width: 400,
                    mt: 1,
                    // padding: '8px',
                    marginLeft: '15px',
                    borderRadius: '10px',
                    border: 'none !important',
                    backgroundColor:
                      theme.palette.mode === 'dark' ? 'unset' : theme.palette.common.white
                  }}
                  InputProps={{
                    disableUnderline: true
                  }}
                  inputProps={{
                    style: {
                      fontSize: 12,
                      border: 'none !important',
                      borderStyle: 'none !important'
                    }
                  }}
                  value={locationVal}
                  onChange={(event) => {
                    const v = event.target.value;
                    const tmp = { ...vehiclePhotoInfo };
                    tmp.location = v;
                    tmp.categoryName = category as string;
                    setVehiclePhotoInfo(tmp);
                    setLocationVal(v);
                  }}
                />
                // <VehicleSubDropBox
                //   dropBoxValue={vehiclePhotoInfo.location}
                //   caption={'Location'}
                //   isExtDmg={true}
                //   topArray={extDmgLocation}
                //   subArrayOne={bodyPanels}
                //   subArrayTwo={miscBody}
                //   subArrayThree={lights}
                //   subArrayFour={wheelRims}
                //   updateDropValue={(v) => {
                //     if (!v) return;
                //     const tmp = { ...vehiclePhotoInfo };
                //     tmp.location = v;
                //     tmp.categoryName = category as string;
                //     setVehiclePhotoInfo(tmp);
                //     setLocationVal(v);
                //   }}
                // />
              )}
              {category === 'Interior' && (
                <TextField
                  variant="standard"
                  label="Location"
                  sx={{
                    fontSize: '12px!important',
                    width: 400,
                    mt: 1,
                    borderRadius: '10px',
                    marginLeft: '15px',
                    border: 'none !important',
                    backgroundColor:
                      theme.palette.mode === 'dark' ? 'unset' : theme.palette.common.white
                  }}
                  InputProps={{
                    disableUnderline: true
                  }}
                  inputProps={{
                    style: {
                      fontSize: 12,
                      border: 'none !important',
                      borderStyle: 'none !important'
                    }
                  }}
                  value={locationVal}
                  onChange={(event) => {
                    const v = event.target.value;
                    const tmp = { ...vehiclePhotoInfo };
                    tmp.location = v;
                    tmp.categoryName = category as string;
                    setVehiclePhotoInfo(tmp);
                    setLocationVal(v);
                  }}
                />
                // <VehicleSubDropBox
                //   dropBoxValue={vehiclePhotoInfo.location}
                //   caption={'Location'}
                //   isIntDmg={true}
                //   topArray={intDmgLocation}
                //   subArrayOne={interior}
                //   updateDropValue={(v) => {
                //     if (!v) return;
                //     const tmp = { ...vehiclePhotoInfo };
                //     tmp.location = v;
                //     tmp.categoryName = category as string;
                //     setVehiclePhotoInfo(tmp);
                //     setLocationVal(v);
                //   }}
                // />
              )}
            </>
          )}
        </Box>
        {(!locationVal || locationVal === '') && showValidateError && (
          <FormHelperText sx={{ ml: 1, mb: 2 }} error={Boolean(!locationVal || locationVal === '')}>
            You must enter a location.
          </FormHelperText>
        )}
        {isDamages && (
          <>
            <Box sx={boxStyle}>
              <VehicleSubDropBox
                dropBoxValue={vehiclePhotoInfo.damageType}
                caption={'Type'}
                topArray={dmgTypes}
                subArrayOne={general}
                subArrayTwo={scratch}
                subArrayThree={scuff}
                updateDropValue={(v) => {
                  if (!v) return;
                  const tmp = { ...vehiclePhotoInfo };
                  tmp.damageType = v;
                  setVehiclePhotoInfo(tmp);
                  setTypeVal(v);
                }}
              />
            </Box>
            {(!typeVal || typeVal === '') && showValidateError && (
              <FormHelperText sx={{ ml: 1, mb: 2 }} error={Boolean(!typeVal || typeVal === '')}>
                You must enter a type.
              </FormHelperText>
            )}
            <Box sx={boxStyle}>
              {/* <VehicleSubDropBox
                dropBoxValue={vehiclePhotoInfo.damageSize}
                caption={'Size'}
                topArray={['Length', 'Chips', 'Number']}
                subArrayOne={sizes}
                subArrayTwo={['1 Chip', '2 Chips', '<5 Chips', '5-10 Chips', '>10 Chips']}
                subArrayThree={['1', '2', '3', '<5', '5-10', '>10']}
                updateDropValue={(v) => {
                  if (!v) return;
                  const tmp = { ...vehiclePhotoInfo };
                  tmp.damageSize = v;
                  setVehiclePhotoInfo(tmp);
                  setSizeVal(v);
                }}
              /> */}
              <VehicleDropBox
                dropBoxValue={vehiclePhotoInfo.damageSize}
                caption={'Size'}
                arrayOfItems={sizes}
                // topArray={['Length', 'Chips', 'Number']}
                // subArrayOne={sizes}
                // subArrayTwo={['1 Chip', '2 Chips', '<5 Chips', '5-10 Chips', '>10 Chips']}
                // subArrayThree={['1', '2', '3', '<5', '5-10', '>10']}
                updateDropValue={(v) => {
                  if (!v) return;
                  const tmp = { ...vehiclePhotoInfo };
                  tmp.damageSize = v;
                  setVehiclePhotoInfo(tmp);
                  setSizeVal(v);
                }}
              />
            </Box>
            {(!sizeVal || sizeVal === '') && showValidateError && (
              <FormHelperText sx={{ ml: 1, mb: 2 }} error={Boolean(!sizeVal || sizeVal === '')}>
                You must enter a size.
              </FormHelperText>
            )}
            <Box sx={boxStyle}>
              <VehicleDropBox
                dropBoxValue={vehiclePhotoInfo.damageNumber}
                caption={'Number'}
                arrayOfItems={['1', '2', '3', '<5', '5-10', '>10']}
                updateDropValue={(v) => {
                  if (!v) return;
                  const tmp = { ...vehiclePhotoInfo };
                  tmp.damageNumber = v as string;
                  setVehiclePhotoInfo(tmp);
                  setNumberVal(v as string);
                }}
              />
            </Box>
            {(!numberVal || numberVal === '') && showValidateError && (
              <FormHelperText sx={{ ml: 1, mb: 2 }} error={Boolean(!numberVal || numberVal === '')}>
                You must enter a number.
              </FormHelperText>
            )}
          </>
        )}
        {locationVal === 'Custom Locations' && (
          <TextField
            sx={{
              fontSize: '12px!important',
              width: 400,
              mt: 1,
              borderRadius: '10px',
              border: `1px ${theme.palette.divider} solid`,
              backgroundColor: theme.palette.mode === 'dark' ? 'unset' : theme.palette.common.white
            }}
            inputProps={{ style: { fontSize: 12 } }}
            value={otherLocation}
            onChange={(event) => {
              const v = event.target.value;
              const tmp = { ...vehiclePhotoInfo };
              tmp.otherLocation = v;
              setVehiclePhotoInfo(tmp);
              setOtherLocation(v);
            }}
          />
        )}
      </Box>
      <Divider
        sx={{
          mt: 2,
          borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
        }}
      />
      <Box sx={{ m: 2, display: 'flex', justifyContent: 'space-between' }}>
        <LoadingButton
          disabled={isNewPhoto}
          loading={isLoadingDelete}
          variant="contained"
          color="error"
          onClick={() => {
            setIsLoadingDelete(true);
            if (vehiclePhotoInfo.fileName) {
              deleteFileFromStorage(
                `cars/${vin}/${vehiclePhotoInfo.categoryName}/${vehiclePhotoInfo.fileName}`
              );
            }
            vehiclePhotoInfo.url = '';
            vehiclePhotoInfo.fileName = '';
            updVehiclePhoto(vehiclePhotoInfo, -1);
            // vehiclePhotoInfo.location = '';
            setVehiclePhotoInfo({} as VehiclePhotos);
            setIsLoadingDelete(false);
            setPhotoPreview({ file: '' });
          }}
        >
          Delete
        </LoadingButton>
        <LoadingButton
          loading={isLoading}
          // disabled={!photoPreview.file}
          // disabled={
          //   isDamages
          //     ? !photoPreview.file || !vehiclePhotoInfo.location
          //     : !photoPreview.file ||
          //       !vehiclePhotoInfo.location ||
          //       !vehiclePhotoInfo.damageType ||
          //       !vehiclePhotoInfo.damageSize ||
          //       !vehiclePhotoInfo.damageNumber
          // }
          variant="contained"
          onClick={async () => {
            if (isDisabled) {
              setShowValidateError(true);
            } else {
              setIsLoading(true);
              if (photoFile) {
                const { url, fileName } = await updatePhotoUrl(photoFile);
                if (fileName !== undefined) {
                  vehiclePhotoInfo.url = url;
                  vehiclePhotoInfo.fileName = fileName;
                }
              }
              updVehiclePhoto(vehiclePhotoInfo, 1);
              // vehiclePhotoInfo.location = '';
              setVehiclePhotoInfo({} as VehiclePhotos);
              setIsLoading(false);
              setPhotoPreview({ file: '' });
            }
          }}
        >
          Update
        </LoadingButton>
      </Box>
    </>
  );
}
