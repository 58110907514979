import {
  Box,
  Container,
  Dialog,
  Grid,
  InputBase,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TablePagination,
  Link,
  Typography,
  useTheme
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DebounceInput } from 'react-debounce-input';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  CollectionReference,
  DocumentReference,
  DocumentSnapshot,
  OrderByDirection,
  QueryDocumentSnapshot,
  collection,
  doc,
  getDoc,
  limit,
  onSnapshot,
  orderBy,
  query,
  where
} from 'firebase/firestore';
import { useFirestore } from 'reactfire';
import { useVehicleBatch } from 'utils/vehicleQuery';
import { ViewportList } from 'react-viewport-list';
import { VehicleInfo } from 'types/vehicleInfo';
import { fDateTimeSuffix } from 'components/shared/dateTimeFormat';
import AuctionDrawer from 'components/auction/drawer/AuctionDrawer';
import BillofSale from 'components/admin/BillofSale';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Link as RouterLink } from 'react-router-dom';
import useSettings from 'hooks/useSettings';
import Page from 'components/Page';
import ReportListItem from 'pages/admin/reportListItem';
import useAuth from 'hooks/useAuth';
import SharedDrawer from 'components/shared/SharedDrawer';
import HistoryDrawerContent from 'components/shared/HistoryDrawerContent';

export default function LostVehicleHistory() {
  const { themeStretch } = useSettings();
  const theme = useTheme();
  const firestore = useFirestore();

  const viewRef = useRef(null);

  const [searchValue, setSearchValue] = useState('');
  const [order, setOrder] = useState('newest');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [queryOrderBy, setQueryOrderBy] = useState<string>('auction.endDate');
  const [queryOrderByTime, setQueryOrderByTime] = useState<string>('auction.endTime');
  const [queryOrderDirection, setQueryOrderDirection] = useState<OrderByDirection>('desc');
  const [lastDocArray, setLastDocArray] = useState<any[]>([]);
  const [firstDocArray, setFirstDocArray] = useState<any[]>([]);
  const [backPage, setBackPage] = useState<boolean>(false);
  const [lastDataVehicleRef, setLastDataVehicleRef] = useState<DocumentReference>();
  const { user } = useAuth();

  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [openBillofSale, setOpenBillofSale] = useState(false);
  const [currentVehicle, setCurrentVehicle] = useState<VehicleInfo>();
  const [openBidDrawer, setOpenBidDrawer] = useState(false);

  const [collectionSize, setCollectionSize] = useState<number>(0);
  const countVehicleCollection = () => {
    const vehicleRef = collection(firestore, 'vehicles') as CollectionReference;
    const vehicleQuery = query(
      vehicleRef,
      where('auction.auctionStatus', '==', 'post auction'),
      where('auction.previousBidders', 'array-contains', user?.uid),
      orderBy(queryOrderBy, queryOrderDirection),
      orderBy(queryOrderByTime, queryOrderDirection)
    );
    const unsubsribe = onSnapshot(vehicleQuery, (querySnapshot) => {
      setCollectionSize(querySnapshot.size);
    });
    return unsubsribe;
  };

  useEffect(() => {
    const unsubscribe = countVehicleCollection();
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (order === 'newest') {
      setQueryOrderBy('auction.endDate');
      setQueryOrderDirection('desc');
    } else if (order === 'oldest') {
      setQueryOrderBy('auction.endDate');
      setQueryOrderDirection('asc');
    } else {
      setQueryOrderBy('details.make');
      setQueryOrderDirection('asc');
    }
  }, [order]);

  const queryConstraints = [
    where('auction.auctionStatus', '==', 'post auction'),
    where('auction.previousBidders', 'array-contains', user?.uid),
    orderBy(queryOrderBy, queryOrderDirection),
    orderBy(queryOrderByTime, queryOrderDirection),
    limit(rowsPerPage)
  ];

  const { data } = useVehicleBatch(
    page,
    queryConstraints,
    lastDocArray[page - 1],
    backPage,
    firstDocArray[page]
  );

  const allVehicles = useMemo(() => {
    const lastDoc = data?.docs[data?.docs?.length - 1];
    if (lastDoc) {
      const lastDocRef = data?.docs[data?.docs?.length - 1].ref as DocumentReference;
      setLastDataVehicleRef(lastDocRef);
    }
    // prettier-ignore
    return data?.docs?.map((doc) => ({ ...doc.data(), id: doc.id }));
  }, [data]);

  const handleChangePage = async (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      const firstDoc = data?.docs[0];
      const firstDocRef = firstDoc.ref as DocumentReference;
      const docRef = doc(firestore, `vehicles/${firstDocRef.id}`);
      const docSnap = await getDoc(docRef);
      const tempFirstDocs = firstDocArray;
      if (docSnap.exists()) {
        tempFirstDocs.push(docSnap);
        setFirstDocArray(tempFirstDocs);
      }
      if (lastDataVehicleRef) {
        const docRef = doc(firestore, `vehicles/${lastDataVehicleRef.id}`);
        const docSnap = await getDoc(docRef);
        const tempLastDocs = lastDocArray;
        if (docSnap.exists()) {
          tempLastDocs.push(docSnap);
          setLastDocArray(tempLastDocs);
          setPage(newPage);
          setBackPage(false);
        }
      }
    } else {
      setPage(newPage);
      setBackPage(true);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setFirstDocArray([]);
    setLastDocArray([]);
    setBackPage(false);
  };

  const handleOrderByChange = (event: SelectChangeEvent) => {
    setOrder(event.target.value);
  };

  const handleCloseBillofSale = () => {
    setOpenBillofSale(!openBillofSale);
  };

  const SetDrawerVehicle = (index: number, vehicle: VehicleInfo) => {
    setCurrentVehicle(vehicle);
    if (index === 0) {
      setOpenDrawer(!openDrawer);
    } else {
      handleCloseBidDrawer();
      // setOpenBillofSale(!openBillofSale);
    }
  };

  const handleCloseBidDrawer = () => {
    setOpenBidDrawer(!openBidDrawer);
  };

  const useStyles = makeStyles({
    backLink: {
      display: 'flex',
      marginBottom: '10px',
      width: '165px'
    },
    paper: {
      minWidth: '800px'
    },
    pagination: {
      borderTopStyle: 'none',
      fontWeight: '700',
      borderTop: 'none'
    },
    inviteButton: {
      backgroundColor: theme.palette.primary.main
    },
    search: {
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.background.neutral : 'transparent !important',
      border: theme.palette.mode === 'dark' ? 'unset' : `1px solid ${theme.palette.divider}`,
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: '100%'
    },
    searchInput: {
      fontSize: '14px',
      width: '100%',
      height: '34px',
      margin: '0 10px',
      border: 'none',
      padding: '5px 10px',
      outline: 'none !important',
      marginLeft: 2,
      flex: 1,
      backgroundColor: 'transparent !important',
      color:
        theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.text.secondary,
      '&::placeholder': {
        color:
          theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.text.secondary,
        fontSize: '14px'
      }
    },
    searchIcon: {
      fill: theme.palette.primary.main,
      marginRight: '5px'
    },
    secondaryButton: {
      backgroundColor: theme.palette.grey[50],
      color: theme.palette.info.light
    },
    subtitle: {
      fontWeight: 'bold',
      color: theme.palette.secondary.darker
    },
    subtitle2: {
      color: theme.palette.info.light,
      fontWeight: 'normal'
    },
    list: {
      '& li:first-child, & li:last-child': {
        '& button': {
          border: `1px solid ${theme.palette.grey[100]}`,
          color: theme.palette.info.light,
          width: '100px'
        }
      },
      '& li': {
        paddingLeft: 3,
        paddingRight: 3,
        '& button': {
          color: theme.palette.info.main,
          minWidth: '40px'
        }
      }
    },
    forwardIcon: {
      fontSize: '12px !important'
    },
    backwardIcon: {
      fontSize: '12px !important'
    },
    divider: {
      width: '93%',
      [theme.breakpoints.down(1524)]: {
        width: '90%'
      },
      [theme.breakpoints.down(863)]: {
        width: '87%'
      }
    },
    gridAvatarTitle: {
      [theme.breakpoints.down(1486)]: {
        flexBasis: '40%',
        maxWidth: '40%'
      },
      [theme.breakpoints.down(1299)]: {
        flexBasis: '45%',
        maxWidth: '45%'
      }
    },
    gridDateTitle: {
      marginRight: '64px',
      [theme.breakpoints.down(1330)]: {
        marginRight: '24px'
      }
    },
    menuPopover: {
      boxShadow: '0 0 2px 0 rgba(139, 148, 156, 0.24),0 10px 20px -4px rgba(139, 148, 156, 0.24)',
      // backgroundColor: theme.palette.primary.contrastText,
      paddingTop: '20px',
      paddingBottom: '20px',
      '@global': {
        '.MuiMenuItem-root': {
          paddingBottom: '6px'
        },
        '.MuiMenuItem-root:last-child': {
          color: theme.palette.error.dark
        }
      }
    },
    dataGrid: {
      '@global': {
        '.MuiDataGrid-columnHeaders': {
          visibility: 'hidden'
        },
        '.MuiDataGrid-virtualScroller': {
          marginTop: '0 !important'
        },
        '.flexEndCell': {
          justifyContent: 'flex-end',
          padding: '0'
        },
        '.flexEndCellPopup': {
          justifyContent: 'flex-end',
          padding: '0',
          '& .MuiTouchRipple-root': {
            display: 'none'
          }
        },
        '.MuiDataGrid-row': {
          minHeight: '88px !important',
          maxHeight: '88px !important',
          '&:hover': {
            backgroundColor: 'transparent !important'
          }
        },
        '.MuiDataGrid-cell': {
          minHeight: '88px !important',
          maxHeight: '88px !important',
          borderBottom: '0'
        },
        '.imageCell': {
          padding: '0'
        }
      }
    },
    headerTextContainer: {
      width: '100%',
      height: 'auto',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '0px'
    },
    controlsContainer: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'flex-end'
    },
    headerText: {
      fontSize: '14px',
      marginRight: '18px',
      marginLeft: '5px'
    },
    inputText: {
      fontSize: '14px'
    },
    dialogContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      width: 450,
      position: 'relative'
    }
  });

  const styles = useStyles();

  return (
    <Page title="Vehicle Bidding History | DealerGavel">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 0 }}
        >
          <Typography
            variant="h4"
            component="h1"
            paragraph
            sx={{
              fontSize: '1rem !important',
              color:
                theme.palette.mode === 'dark'
                  ? theme.palette.common.white
                  : theme.palette.text.primary
            }}
          >
            Previous Vehicles You Bid On
          </Typography>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 0 }}
        >
          <Paper component="form" className={styles.search}>
            <DebounceInput
              className={styles.searchInput}
              placeholder="Search Vehicles by Make, Model, Year, etc."
              minLength={2}
              debounceTimeout={300}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <SearchIcon className={styles.searchIcon} sx={{ width: '20px' }} />
          </Paper>
        </Grid>
        <Box sx={{ flexGrow: 1 }}>
          <Box className={styles.headerTextContainer}>
            {allVehicles && (
              <Typography
                component="p"
                className={styles.headerText}
                sx={{
                  textTransform: 'uppercase',
                  fontWeight: '700',
                  color: theme.palette.info.light
                }}
              >
                {collectionSize} total vehicles found
              </Typography>
            )}
            <Box className={styles.controlsContainer}>
              <Typography component="p" className={styles.headerText}>
                Sort By:
              </Typography>
              <Select
                label="Sort By"
                value={order}
                onChange={handleOrderByChange}
                className={styles.inputText}
                inputProps={{ 'aria-label': 'order vehicles' }}
                input={<InputBase />}
              >
                <MenuItem value={'oldest'}>Oldest</MenuItem>
                <MenuItem value={'newest'}>Newest</MenuItem>
                <MenuItem value={'vehicleName'}>Name</MenuItem>
              </Select>
              {allVehicles && (
                <TablePagination
                  className={styles.pagination}
                  count={collectionSize}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  rowsPerPageOptions={[5, 10, 25]}
                />
              )}
            </Box>
          </Box>
          <Grid
            sx={{
              overflowX: 'auto',
              height: '600px',
              overflowY: 'auto'
            }}
            ref={viewRef}
          >
            {allVehicles && (
              <ViewportList
                viewportRef={viewRef}
                initialPrerender={8}
                items={allVehicles
                  .sort((a: VehicleInfo, b: VehicleInfo) => {
                    if (order === 'oldest') {
                      return a.auction.endDate > b.auction.endDate ? 1 : -1;
                    } else if (order === 'newest') {
                      return a.auction.endDate > b.auction.endDate ? -1 : 1;
                    } else {
                      return a.details.make > b.details.make ? 1 : -1;
                    }
                  })
                  .filter(
                    (v: VehicleInfo) =>
                      /* prettier-ignore */
                      (v.details.make ? v.details.make.toLowerCase().includes(searchValue.toLowerCase()) : true) ||
                      v.details.model && v.details.model.toLowerCase().includes(searchValue.toLowerCase()) ||
                      v.auction.currentPrice && v.auction.currentPrice
                        .toString()
                        .toLowerCase()
                        .includes(searchValue.toLowerCase()) ||
                      v.details.year && v.details.year?.toString().toLowerCase().includes(searchValue.toLowerCase()) ||
                      v.details.trim && v.details.trim.toLowerCase().includes(searchValue.toLowerCase())
                  )}
              >
                {(vehicle: VehicleInfo) => (
                  <Box sx={{ mt: '0px', mb: '0px', width: '100%' }} key={vehicle.id}>
                    <ReportListItem
                      vehicle={vehicle}
                      isOutbid={true}
                      sendVehicleToParent={SetDrawerVehicle}
                    />
                  </Box>
                )}
              </ViewportList>
            )}
          </Grid>
        </Box>
      </Container>
      {currentVehicle && (
        <SharedDrawer
          isOpenSidebar={openBidDrawer}
          onCloseSidebar={handleCloseBidDrawer}
          drawerContent={
            <HistoryDrawerContent
              isSeller={false}
              onClose={handleCloseBidDrawer}
              vehicle={currentVehicle as VehicleInfo}
              index={1}
            />
          }
        />
      )}
      {currentVehicle && (
        <AuctionDrawer
          vehicle={currentVehicle}
          tabIndex={0}
          isOpenSidebar={openDrawer}
          onCloseSidebar={() => setOpenDrawer(!openDrawer)}
        />
      )}
      {currentVehicle && (
        <Dialog
          classes={{ paper: styles.paper }}
          open={openBillofSale}
          onClose={handleCloseBillofSale}
          sx={{
            zIndex: '9999999'
          }}
        >
          <BillofSale vehicle={currentVehicle} />
        </Dialog>
      )}
    </Page>
  );
}
