import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  IconButton,
  Menu,
  MenuItem,
  Slider,
  TextField,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Formik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { PaintedPanelsType, ConditionOfTiresType } from 'types/vehicleInfo';
import { dt_condition, ListPanels, ListTires } from './AllPanels';
import { VehicleConditionsType } from 'types/vehicleInfo';
import { VehicleDropBox } from './CommonComponents';
// import { boxStyle } from './vehicleCaptureStyles';
import { uniqueId } from './genUID';
import { useNavigate } from 'react-router-dom';
import { PATH_SELLER } from 'routes/paths';
import MoreItemsIndicator from './MoreItemsIndicator';

const VehicleSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.palette.primary.main,
  height: 2,
  padding: '15px 0',
  '& .MuiSlider-thumb': {
    height: 20,
    width: 20,
    backgroundColor: 'white',
    border: '1px solid grey',
    borderRadius: '50%',
    boxShadow: sliderShadow,
    '&:focus, &:hover, &.Mui-active': {
      boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        boxShadow: sliderShadow
      }
    }
  },
  '& .MuiSlider-valueLabel': {
    fontSize: 14,
    fontWeight: 'normal',
    top: 0,
    backgroundColor: 'unset',
    color: theme.palette.text.primary,
    '&:before': {
      display: 'none'
    },
    '& *': {
      background: 'transparent !important',
      color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black
    }
  },
  '& .MuiSlider-track': {
    border: 'none'
  },
  '& .MuiSlider-rail': {
    opacity: 0.5,
    backgroundColor: theme.palette.grey[300]
  },
  '& .MuiSlider-mark': {
    backgroundColor: theme.palette.grey[300],
    height: 10,
    width: 2,
    '&.MuiSlider-markActive': {
      opacity: 1,
      backgroundColor: 'currentColor'
    }
  }
}));

const sliderShadow = '1 3px 1px rgb(0,0,0),1 4px 8px rgb(0,0,0),1 1 1 1px rgba(0,0,0)';

const d_panels = [
  'Hood',
  'Front Bumper',
  'Left Front Fender',
  'Left Front Door',
  'Left Rear Door',
  'Left Rear Quarter/Bedside',
  'Trunk/Tailgate',
  'Rear Bumper',
  'Right Rear Quarter',
  'Right Rear Door',
  'Right Front Door',
  'Right Front Quarter',
  'Roof'
];
const d_condition = ['Acceptable', 'Requires Minor Attention/Work', 'Requires Major Repair/Redo'];
const d_tires = ['Left Front', 'Right Front', 'Left Rear', 'Right Rear'];

const modalTires = [
  'Left Front',
  'Right Front',
  'Left Rear',
  'Right Rear',
  'Dual Left Rear',
  'Dual Right Rear'
];

type Props = {
  conditions: VehicleConditionsType;
  updVehicleCon: (vehicle: VehicleConditionsType, flag: number) => void;
};

export function VehicleCondition({ conditions, updVehicleCon }: Props) {
  const navigate = useNavigate();
  const theme = useTheme();
  const [conditionsInfo, setConditionsInfo] = useState(conditions || ({} as VehicleConditionsType));
  // const [value, setValue] = useState(+conditions?.overall || 0); // overall condition value
  const [value, setValue] = useState<any>(conditions?.overall || '0'); // overall condition value
  const [paintedPanels, setPaintedPanels] = useState<PaintedPanelsType[]>(
    conditions?.paintedPanels || ([] as PaintedPanelsType[])
  );
  const [tires, setTires] = useState<ConditionOfTiresType[]>(
    /*  conditions?.conditionOfTires ||  */ [] as ConditionOfTiresType[]
  );
  const [sellersNotes, setSellersNotes] = useState(conditions?.sellersNotes || '');

  const boxStyle = {
    textAlign: 'start',
    width: 400,
    height: 60,
    backgroundColor: theme.palette.mode !== 'dark' ? theme.palette.common.white : 'unset',
    border: `1px ${theme.palette.divider} solid`,
    borderRadius: '10px',
    fontWeight: 300,
    padding: 0,
    mb: 1,
    overflow: 'hidden'
  };

  // Create empty cards for tires
  useEffect(() => {
    let tt: ConditionOfTiresType[] = [];
    d_tires.forEach((d) => {
      const t: ConditionOfTiresType = {} as ConditionOfTiresType;
      t.location = d;
      t.selected = true;
      t.condition = 'Good';
      tt.push(t);
    });
    if (conditionsInfo.conditionOfTires) {
      for (let d_t of d_tires)
        if (conditionsInfo.conditionOfTires.filter((e) => e.location === d_t).length < 1) {
          const t: ConditionOfTiresType = {} as ConditionOfTiresType;
          t.location = d_t;
          t.selected = true;
          t.condition = 'Good';
          conditionsInfo.conditionOfTires.push(t);
        }
    } else {
      conditionsInfo.conditionOfTires = [...tt];
    }
    setTires(conditionsInfo.conditionOfTires);
  }, [conditionsInfo]);
  // -----------------------------------------------------------

  useEffect(() => {
    window.scrollTo(0, 0);
    const t = { ...conditionsInfo };
    if (t.paintedPanels && t.paintedPanels.length > 0) {
      const tempPanels: PaintedPanelsType[] = [];
      t.paintedPanels.forEach((panel: PaintedPanelsType) => {
        tempPanels.push(panel);
      });
      setPaintedPanels(tempPanels);
    }
    t.overall = value as any;
    setConditionsInfo(t);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [showAddPanels, setShowAddPanels] = useState(false);
  const [showAddTires, setShowAddTires] = useState(false);
  const [selectedPanel, setSelectedPanel] = useState<PaintedPanelsType>();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e: any) => {
    const value = e.target.innerText;
    setAnchorEl(null);
    if (!value) return;
    const tmpC = { ...conditionsInfo };
    tmpC.conditionOfTires.forEach((t) => (t.condition = value));
    setConditionsInfo(tmpC);
    setTires(tmpC.conditionOfTires);
  };

  const handleChange = (event: Event, newValue: any) => {
    const t = { ...conditionsInfo };
    t.overall = newValue.toString();
    setConditionsInfo(t);
    setValue(newValue as string);
  };

  const handleCloseAddPanels = () => {
    setSelectedPanel(undefined);
    setShowAddPanels(false);
  };
  const handleCloseAddTires = () => {
    setShowAddTires(false);
  };
  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="outlined"
          onClick={() => {
            // updVehicleCon(conditionsInfo, 5);
            updVehicleCon(conditionsInfo, 4);
            navigate(PATH_SELLER.vehicleCapture);
          }}
        >
          Save and Exit
        </Button>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          backgroundColor:
            theme.palette.mode === 'dark'
              ? theme.palette.background.neutral
              : theme.palette.grey[50],
          borderRadius: '10px',
          maxWidth: '850px',
          width: '100%',
          border: '1px solid rgba(145, 158, 171, 0.24)',
          margin: 'auto',
          marginTop: '20px',
          paddingBottom: '20px'
        }}
      >
        <Box>
          <Box sx={{ mt: 3, width: 400 }}>
            <Typography
              variant="h3"
              sx={{ textAlign: 'center', color: theme.palette.text.primary }}
            >
              Condition
            </Typography>
          </Box>

          <Box sx={{ mt: 3, width: 400 }}>
            <Typography variant="subtitle2" sx={{ fontWeight: '700' }}>
              OVERALL CONDITION
            </Typography>

            <VehicleSlider
              sx={{ mt: 3, mb: 2 }}
              aria-label="ios slider"
              value={parseInt(value.toString())}
              min={0}
              step={0.5}
              max={10}
              marks={true}
              valueLabelDisplay="on"
              onChange={handleChange}
              aria-labelledby="non-linear-slider"
            />
            <Divider
              sx={{
                borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
              }}
            />
          </Box>
        </Box>

        {/* <Box sx={{ mt: 3, width: 400 }}>
          <Box
            sx={{
              width: '100%',
              mt: 3,
              mb: 1,
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Typography variant="subtitle2" sx={{ fontWeight: '700' }}>
              PAINTED PANELS
            </Typography>
            <Button variant="contained" sx={{ mt: -1 }} onClick={() => setShowAddPanels(true)}>
              Add
            </Button>
          </Box>
          <ListPanels
            paintedPanels={paintedPanels}
            updatePanels={(v) => {
              setPaintedPanels(v);
              const tmpC = { ...conditionsInfo };
              tmpC.paintedPanels = [...v];
              setConditionsInfo(tmpC);
            }}
            handleEdit={(v) => {
              setSelectedPanel(v);
              setShowAddPanels(true);
            }}
          />
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
        </Box> */}

        <Box sx={{ mt: 3, width: 400 }}>
          <Box
            sx={{
              width: '100%',
              mt: 3,
              mb: 1,
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Typography variant="subtitle2" sx={{ fontWeight: '700' }}>
              CONDITION OF TIRES
            </Typography>

            <Typography sx={{ mt: -1, fontSize: '12px' }} variant="button" color="primary">
              Apply to All
              <IconButton
                id="icon-button"
                color="inherit"
                aria-controls={open ? 'icon-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                <KeyboardArrowDownIcon />
              </IconButton>
            </Typography>

            <Menu
              id="icon-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button'
              }}
              sx={{ zIndex: 9999 }}
            >
              {dt_condition.map((ye, index) => (
                <MenuItem sx={{ fontSize: '12px' }} key={index} value={ye} onClick={handleClose}>
                  {ye}
                </MenuItem>
              ))}
            </Menu>

            <Button variant="contained" sx={{ mt: -1 }} onClick={() => setShowAddTires(true)}>
              Add
            </Button>
          </Box>
          <ListTires
            tires={tires}
            updateTires={(v) => {
              setTires(v);
              const tmpC = { ...conditionsInfo };
              tmpC.conditionOfTires = [...v];
              setConditionsInfo(tmpC);
            }}
          />
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
        </Box>
        <Box sx={{ mt: 3, width: 400 }}>
          <Box
            sx={{
              width: '100%',
              mt: 3,
              mb: 1,
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: '700' }}>
              SELLER'S NOTES
            </Typography>
            <TextField
              sx={{
                fontSize: '12px',
                width: 400,
                mt: 1,
                borderRadius: '10px',
                border: `1px ${theme.palette.divider} solid`,
                backgroundColor:
                  theme.palette.mode === 'dark' ? 'unset' : theme.palette.common.white
              }}
              inputProps={{ style: { fontSize: 12 } }}
              InputLabelProps={{ style: { fontSize: 12, color: 'grey' } }}
              id="outlined-multiline-flexible"
              // label="Enter Notes"
              multiline
              rows={5}
              value={sellersNotes}
              onChange={(event) => {
                setSellersNotes(event.target.value);
                const tmpC = { ...conditionsInfo };
                tmpC.sellersNotes = event.target.value;
                setConditionsInfo(tmpC);
              }}
              margin="normal"
              // variant="outlined"
            />
          </Box>
        </Box>
      </Box>

      <Divider
        sx={{
          mt: 2,
          borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
        }}
      />
      <Box sx={{ m: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Button variant="text" color="inherit" onClick={() => updVehicleCon(conditionsInfo, 3)}>
          Back
        </Button>
        <Button variant="contained" onClick={() => updVehicleCon(conditionsInfo, 5)}>
          Next: Equipment
        </Button>
      </Box>
      <MoreItemsIndicator />
      <Dialog open={showAddPanels} onClose={handleCloseAddPanels}>
        <Formik
          initialValues={
            {
              id: selectedPanel?.id || '',
              name: selectedPanel?.name || '',
              condition: selectedPanel?.condition || '',
              selected: false
            } as PaintedPanelsType
          }
          onSubmit={async (values, { resetForm, setSubmitting }): Promise<void> => {
            if (values.name !== '') {
              values.selected = true;
              const tmpC = { ...conditionsInfo };
              const tmpPanels = [...paintedPanels];
              const ind = tmpPanels.findIndex((p) => p.name === values.name);
              if (ind !== -1) {
                tmpPanels[ind].selected = true;
                tmpPanels[ind].condition = values.condition;
                tmpC.paintedPanels = tmpPanels;
                setPaintedPanels(tmpPanels);
              } else {
                values.selected = true;
                values.id = uniqueId('pp-');
                tmpC.paintedPanels = [...tmpPanels, values];
                setPaintedPanels([...tmpPanels, values]);
              }
              setConditionsInfo(tmpC);
            }
            setSelectedPanel(undefined);
            setShowAddPanels(false);
          }}
        >
          {({
            handleSubmit,
            setFieldValue,
            isSubmitting,
            isValid,
            dirty,
            getFieldProps,
            values,
            errors,
            touched
          }): JSX.Element => (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Box
                  sx={{
                    width: 400,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-evenly'
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      mb: 2,
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between'
                    }}
                  >
                    <div></div>
                    <Typography variant="h6"> Add Painted Panels </Typography>
                    <CloseIcon
                      sx={{ fontSize: '18px', cursor: 'pointer' }}
                      onClick={handleCloseAddPanels}
                    />
                  </Box>
                  <Divider
                    sx={{
                      width: '100%',
                      borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
                    }}
                  />
                  <FormControl sx={{ mt: 2 }}>
                    <Box sx={boxStyle}>
                      <VehicleDropBox
                        dropBoxValue={values.name}
                        caption={'Panel Type'}
                        arrayOfItems={d_panels}
                        updateDropValue={(v) => {
                          if (!v) return;
                          setFieldValue('name', v);
                        }}
                      />
                    </Box>
                  </FormControl>

                  <FormControl sx={{ mt: 0.5 }}>
                    <Box sx={boxStyle}>
                      <VehicleDropBox
                        dropBoxValue={values.condition}
                        caption={'Paint Condition'}
                        arrayOfItems={d_condition}
                        updateDropValue={(v) => {
                          if (!v) return;
                          setFieldValue('condition', v);
                        }}
                      />
                    </Box>
                  </FormControl>
                </Box>
              </DialogContent>

              <DialogActions
                sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
              >
                <Button
                  variant="contained"
                  color="error"
                  sx={{ width: '30%' }}
                  autoFocus
                  onClick={handleCloseAddPanels}
                >
                  Cancel
                </Button>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  sx={{ width: '30%' }}
                  disabled={!isValid}
                  loading={isSubmitting}
                >
                  {selectedPanel ? 'Update' : 'Add'}
                </LoadingButton>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>

      <Dialog open={showAddTires} onClose={handleCloseAddTires}>
        <Formik
          initialValues={
            {
              id: '',
              location: '',
              size: '',
              tread: '',
              condition: 'Good',
              selected: false
            } as ConditionOfTiresType
          }
          onSubmit={async (values, { resetForm, setSubmitting }): Promise<void> => {
            if (values.location !== '') {
              values.selected = true;
              const tmpC = { ...conditionsInfo };
              const tmpTires = [...tires];
              const ind = tmpTires.findIndex((p) => p.location === values.location);
              if (ind !== -1) {
                tmpTires[ind].selected = true;
                tmpTires[ind].condition = values.condition;
                tmpC.conditionOfTires = tmpTires;
                setTires(tmpTires);
              } else {
                values.selected = true;
                values.id = uniqueId('tt-');
                setTires([...tmpTires, values]);
                tmpC.conditionOfTires = [...tmpTires, values];
              }
              setConditionsInfo(tmpC);
            }
            setShowAddTires(false);
          }}
        >
          {({
            handleSubmit,
            setFieldValue,
            isSubmitting,
            isValid,
            dirty,
            getFieldProps,
            values,
            errors,
            touched
          }): JSX.Element => (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Box
                  sx={{
                    width: 400,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-evenly'
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      mb: 2,
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between'
                    }}
                  >
                    <div></div>
                    <Typography variant="h6"> Tire Condition </Typography>
                    <CloseIcon
                      sx={{ fontSize: '18px', cursor: 'pointer' }}
                      onClick={handleCloseAddTires}
                    />
                  </Box>
                  <Divider
                    sx={{
                      width: '100%',
                      borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
                    }}
                  />
                  <FormControl sx={{ mt: 2 }}>
                    <Box sx={boxStyle}>
                      <VehicleDropBox
                        dropBoxValue={values.location}
                        caption={'Location'}
                        arrayOfItems={modalTires}
                        updateDropValue={(v) => {
                          if (!v) return;
                          setFieldValue('location', v);
                        }}
                      />
                    </Box>
                  </FormControl>

                  <FormControl sx={{ mt: 2 }}>
                    <Box sx={boxStyle}>
                      <VehicleDropBox
                        dropBoxValue={values.condition}
                        caption={'Condition'}
                        arrayOfItems={dt_condition}
                        updateDropValue={(v) => {
                          if (!v) return;
                          setFieldValue('condition', v);
                        }}
                      />
                    </Box>
                  </FormControl>
                </Box>
              </DialogContent>
              <Divider
                sx={{
                  borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
                }}
              />
              <DialogActions
                sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
              >
                <Button
                  variant="contained"
                  color="error"
                  sx={{ width: '30%' }}
                  autoFocus
                  onClick={handleCloseAddTires}
                >
                  Cancel
                </Button>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  sx={{ width: '30%' }}
                  disabled={!isValid}
                  loading={isSubmitting}
                >
                  Add
                </LoadingButton>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}
